import React from "react";
import GeneAnalysisListItem from "./GeneAnalysisListItem";

export const GeneAnalysisList = ({ traits, expandAll, filter = "Show All", t ,psStatus}) => {
  return (
    <div>
      {traits.length > 0 ? traits.map((trait, i) => {
        return (
          <GeneAnalysisListItem trait={trait} expandAll={expandAll} key={i} filter={filter} index={i} psStatus={psStatus}/>
        );
      }) :
        <div className="pg-text text-center">
          {t('pracpl_line_5')}
          <div className="my-5 py-5"></div>
        </div>
      }
    </div>
  );
};

export default GeneAnalysisList;
