import React, { useState, useEffect, useMemo } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ActionImage from "../../ActionImage";
import warningIcon from "../../../assets/warning.png";
import PgRatings from "../../../components/PgRatings";
import { useNavigate } from "react-router-dom";

function GeneAnalysisTable({ traitContent, error, filter, psStatus }) {
  const navigate = useNavigate();
  const currentReportId = useSelector(
    (state) => state.patientReport.currentReportId
  );
  const goToPRSPage = () => {
    navigate(`/practice/patient/report/ps_list/${currentReportId}`);
  };
  const { t, i18n } = useTranslation();
  useEffect(() => {
    setPageSize(100);
  }, []);
  const column = [
    {
      Header: t("gene_analysis_th_1"),
      accessor: "traits_and_snps",
      Cell: (props) => {
        return (
          <div className="pg-text">
            <div className="pg-text__bold mb-1">{props.value.trait}</div>
            {((typeof psStatus !== "undefined" &&
              psStatus !== "pending" &&
              props.value.trait === "Vitamin D") ||
              props.value.trait === "Vitamin B12 (Cobalamin)" ||
              props.value.trait === "Methylation") && (
              <h6 className="link-to-prs" onClick={goToPRSPage}>
                {t("gene_analysis_poly_score_txt")}
              </h6>
            )}
            {props?.cell?.row?.original?.variants_and_result?.map((snp) => {
              return (
                <>
                  <div>{snp?.title?.split(" ")[0]}</div>
                  <div>
                    {snp?.title?.split(" ")[1]}
                    {/* s */}
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </>
              );
            })}
          </div>
        );
      },
    },
    {
      Header: (
        <div dangerouslySetInnerHTML={{ __html: t("gene_analysis_th_2") }} />
      ),
      accessor: "variants_and_result",
      Cell: (v) => {
        const { value } = v;
        if (!_.isArray(value))
          return <b className=" text-danger">{t("gene_analysis_td_1")}</b>;
        else
          return value.length < 1 ? (
            <div className="pg-text">
              <br />
              {t("pracpl_line_12") /*N/A*/}
            </div>
          ) : (
            value.map((rv) => (
              <div className="pg-text">
                <br />
                <div>
                  {t("gene_analysis_td_2")}: {rv["risk_variants"].join(",")}
                </div>
                <div>
                  {t("gene_analysis_td_3")}: {rv["patient_variant"]}
                </div>
                <div>
                  {t("gene_analysis_td_4") /* Result*/}:
                  <div className="pg-text text-capitalize">
                    {" "}
                    {t(_.lowerCase(rv["result"]))}
                    <ActionImage action={rv["result"]} />
                  </div>
                </div>
                {t("gene_analysis_td_5")}:{" "}
                <PgRatings rated={rv["scientific_Rating"]} />
              </div>
            ))
          );
      },
    },
    {
      Header: t("gene_analysis_th_3"),
      accessor: "implications",
      Cell: (v) => {
        const { value } = v;
        if (!_.isArray(value))
          return <b className="text-danger">{t("gene_analysis_td_1")}</b>;
        else
          return value.length < 1 ? (
            <div className="pg-text">
              <br />
              {t("gene_analysis_td_6")}
            </div>
          ) : (
            <div>
              <br />
              {value.map((imp) => (
                <div className="pg-text">{imp}</div>
              ))}
            </div>
          );
      },
    },
    {
      Header: t("gene_analysis_th_4"),
      accessor: "assessment_recommendations",
      Cell: (v) => {
        let value = v.value;
        if (!_.isArray(value))
          return <b className="text-danger">{t("gene_analysis_td_1")}</b>;
        else {
          let finalOut = (
            <div className="pg-text">
              {value.length === 0 ? (
                <div className="pg-text">
                  <br />
                  {t("gene_analysis_td_6")}
                </div>
              ) : (
                value.map((data) => (
                  <div>
                    <br />
                    <div className="pg-text">{data.title}</div>
                    <div dangerouslySetInnerHTML={{ __html: data.ranges }} />
                    {data.patient_result && (
                      <div className="pg-link">
                        {t("gene_analysis_td_7")} :{" "}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: data.patient_result,
                          }}
                        />
                      </div>
                    )}
                    <br />
                  </div>
                ))
              )}
            </div>
          );
          return finalOut;
        }
      },
    },
    {
      Header: t("gene_analysis_th_5"),
      accessor: "diet_and_lifestyle_recommendations",
      Cell: (v) => {
        const { value } = v;
        if (!_.isArray(value))
          return <b className="text-danger">{t("gene_analysis_td_1")}</b>;
        else
          return value == "" ? (
            <div className="pg-text">
              <br /> {t("gene_analysis_td_6")}
            </div>
          ) : (
            <div className="">
              <br />
              {value.map((dalr) => (
                <div className="pg-text">{dalr}</div>
              ))}
            </div>
          );
      },
    },
    {
      Header: t("gene_analysis_th_6"),
      accessor: "supplement_recommendations",
      Cell: (v) => {
        const { value } = v;
        const { alternative_recommendations } = v.row.original;
        if (!_.isArray(value))
          return <b className="text-danger">{t("gene_analysis_td_1")}</b>;
        else
          return value.length < 1 ? (
            <div className="pg-text">
              <br />
              {t("gene_analysis_td_6")}
            </div>
          ) : (
            <div clas>
              <br />
              {value.map((suplmnts) => (
                <div
                  className="pg-text__bold"
                  dangerouslySetInnerHTML={{
                    __html: _.isArray(suplmnts) ? suplmnts[0] : suplmnts,
                  }}
                />
              ))}
              {!_.isEmpty(alternative_recommendations) && (
                <div>
                  <div className="fw-bold text-secondary my-1">
                    {t("gene_analysis_td_8")}:
                  </div>
                  {alternative_recommendations.map((ar) => (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: _.isArray(ar) ? ar[0] : ar,
                      }}
                    />
                  ))}
                </div>
              )}
            </div>
          );
      },
    },
  ];

  const columns = useMemo(() => column, []);
  const data = useMemo(() => traitContent, [traitContent]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    setPageSize,
    state: { pageIndex, pageSize },
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    usePagination
  );

  return (
    <table {...getTableProps()} className="pg-table analysis-table">
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render("Header")}
                <span className="fs-4">
                  {column.isSorted ? (column.isSortedDesc ? "↓" : "↑") : ""}{" "}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      {error && (
        <tbody>
          <tr>
            <td colSpan="6">
              <div className="w-100 text-center">{t("pracpl_error")}</div>
            </td>
          </tr>
        </tbody>
      )}
      {traitContent.length < 1 && !error && (
        <tbody>
          <tr>
            <td colSpan="6">
              <div className="w-100 text-center">
                {filter === "Show Actionable"
                  ? t('gene_analysis_txt1')
                  : t('gene_analysis_txt2')}
              </div>
            </td>
          </tr>
        </tbody>
      )}
      {traitContent.length > 0 && !error && (
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      )}
    </table>
  );
}

export default GeneAnalysisTable;
