import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ReactComponent as PgLogo } from "../assets/pg_logo_1.svg";
import { ReactComponent as PiLogo } from "../assets/PureInsight_Logo_KO_u2.svg";
import { ReactComponent as Hamburger } from "../assets/hamburger.svg";
import Pg3LogoWhite from "../assets/PG_Logo.svg";
import { ReactComponent as PgLogoBlue } from "../assets/PG_Logo.svg";
import Pi3LogoWhite from "../assets/PureInsight_Logo_RGB_u1.svg";
import { ReactComponent as PiLogoBlue } from "../assets/PureInsight_Logo_RGB_u1.svg";
import { toggleSidebar } from "../methods/sidenav";
import NavButtons from "./NavButtons";
import { clearCookie } from "../methods";
import { logoutAction } from "../methods/auth_methods";
import { NavLink } from "react-router-dom";
import "../styles/header.css";

export default function Header() {
  const { t, i18n } = useTranslation();
  const isUK = window.location.hostname.endsWith('.co.uk')
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  // local state
  const isSmallDevice = window.innerWidth < 986;
  const [inHomePage, setInHomePage] = useState(true);
  const [navBarWidth, setnavBarWidth] = useState(
    isSmallDevice ? "100%" : "75vw"
  );
  const [header, setHeader] = useState(
    isSmallDevice ? "header-white" : "header-transparent"
  );

  // redux state
  const isAuth = useSelector((state) => state.auth.mfaEnabled);
  const mfaEnabled = useSelector((state) => state.auth.mfaEnabled);
  const userType = useSelector((state) => state.auth.userType);
  const token = useSelector((state) => state.auth.token);
  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const currentPracticeID = useSelector(
    (state) => state.auth.currentPracticeID
  );
  const currentPG2UserId = useSelector((state) => state.auth.currentPG2UserId);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (location.pathname !== "/") {
      setInHomePage(false);
      setnavBarWidth(isSmallDevice ? "100%" : "100vw");
    } else {
      if (!isSmallDevice) setnavBarWidth("75vw");
      setInHomePage(true);
    }
  }, [location]);

  const handleScroll = (e) => {
    let d = window.pageYOffset;

    // if (window.location.pathname === "/" && window.innerWidth > 986)
    //   if (d > 40) {
    //     if (inHomePage) setnavBarWidth("100vw");
    //   } else {
    //     setnavBarWidth(isSmallDevice ? "100%" : "75vw");
    //   }

    if (window.innerWidth > 986)
      if (window.scrollY < 22) {
        return setHeader("header-transparent");
      } else if (window.scrollY > 20) {
        return setHeader("header-white");
      }
  };

  const goToDashboard = () => {
    navigate(
      `/${
        userType === "AdminUser"
          ? "admin"
          : userType === "User"
          ? "practice"
          : "patient"
      }/${userType === "Patient" ? "dashboard" : "dashboard"}`
    );
  };

  return (
    <nav
      id={header}
      className="bgc-primary position-fixed w-100 p-1 bg-white"
      style={{ zIndex: 10, top: 0 }}
    >
      <div className="container-fluid p-md-3 p-1 px-3 mx-lg-1">
        <div className="container-xxl d-flex justify-content-between justify-content-between">
          {/* menu button */}
          <div className="d-md-none my-auto">
            <Hamburger onClick={() => toggleSidebar()}></Hamburger>
            <div className="icon-one d-md-none">
              <div
                className="hamburger hamburger-one "
                onClick={() => toggleSidebar()}
              ></div>
            </div>
          </div>

          {/* Logo */}
          <div
            className="m-auto m-md-0 pg-logo cursor-pointer"
            onClick={() => navigate("/")}
          >
            <div className="d-none d-md-block">
              {header === "header-transparent" &&
              window.location.pathname === "/" ? (
                i18n.language == "en_gb" ? (
                  <PgLogo fontSize="100px" />
                ) : (
                  <PiLogoBlue style={{ width: "250px" }} fontSize="100px" />
                )
              ) : i18n.language == "en_gb" ? (
                <PgLogoBlue fontSize="100px" />
              ) : (
                <PiLogoBlue style={{ width: "250px" }} fontSize="100px" />
              )}
            </div>
            <div className="d-block d-md-none">
              {i18n.language == "en_gb" ? (
                <img src={Pg3LogoWhite} alt="logo" style={{ height: "50px" }} />
              ) : (
                <PiLogoBlue />
              )}
            </div>
          </div>

          <div className="pl-auto d-none d-md-flex align-items-center">
            {isAuth && mfaEnabled ? (
              <div
                onClick={logoutAction}
                className="pg-text textc-primary d-inline-block me-3 cursor-pointer fw-bold my-atuo"
                id="provider-registration"
              >
                {t("header_btn_1")}
              </div>
            ) : (
              <div className="d-inline-block text-end me-3">
                <div className="d-flex flex-column align-items-end">
                  {/* {i18n.language === "en" && <a
                    href="https://www.pureencapsulationspro.com/"
                    target="_blank"
                    className="fw-bold textc-primary"
                    style={{ textDecoration: 'underline', textDecorationThickness: '2px' }}
                  >
                    Pure Encapsulations Pro
                  </a>} */}
                  <NavLink
                    to="/hcp/registration"
                    className="fw-bold textc-primary text-decoration-none"
                    activeClassName="text-decoration-underline"
                  >
                    {t("header_btn_2")}
                  </NavLink>
                </div>
              </div>
            )}
            {isAuth && mfaEnabled ? (
              <button
                className="btn btn-primary px-5 py-2 bgc-primary"
                onClick={goToDashboard}
                id="sign-in-btn__homepage"
              >
                {t("dashboard")}
              </button>
            ) : (
              <button
                className="btn btn-primary px-5 py-2"
                onClick={() => {
                  navigate("/users/login");
                }}
                id="sign-in-btn__homepage"
              >
                {t("prac_register_sign_in_text")}
              </button>
            )}
          </div>
        </div>
      </div>
      <div
        className="bg-transparent position-absolute w-100"
        style={{
          // marginTop: 5,
          left: isSmallDevice ? "0%" : "50%",
          transform: isSmallDevice ? "unset" : "translateX(-50%)",
        }}
      >
        <NavButtons show="all" />
      </div>
    </nav>
  );
}
