import * as admin_user_constant from "../actions/adminUser";

const initialState = {
    loading_admin_users: true,
    admin_users: [],
    total_count: null,
    loading: true,
    loading_country_list: true,
    country_list: []
}

const adminUser = (state = initialState, action) => {
    switch (action.type) {
        case admin_user_constant.GET_ADMIN_USERS_REQUEST: {
            return { ...state, loading_admin_users: true }
        }
        case admin_user_constant.GET_ADMIN_USERS_SUCCESS: {
            return { ...state, loading_admin_users: false, admin_users: action.payload.admins, total_count: action.payload.total }
        }
        case admin_user_constant.GET_ADMIN_USERS_FAILURE: {
            return { ...state, loading_admin_users: false }
        }
        case admin_user_constant.ADD_ADMIN_USER_REQUEST: {
            return { ...state, loading: true }
        }
        case admin_user_constant.ADD_ADMIN_USER_SUCCESS: {
            return { ...state, loading: false }
        }
        case admin_user_constant.ADD_ADMIN_USER_FAILURE: {
            return { ...state, loading: true }
        }
        case admin_user_constant.GET_COUNTRIES_REQUEST: {
            return { ...state, loading_country_list: true }
        }
        case admin_user_constant.GET_COUNTRIES_SUCCESS: {
            return { ...state, loading_country_list: false, country_list: action.payload.countries }
        }
        case admin_user_constant.GET_COUNTRIES_FAILURE: {
            return { ...state, loading_country_list: false }
        }
        default:
            return state;
    }
}

export default adminUser;