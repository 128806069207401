import React, { useRef, useEffect } from "react";
import QRCode from "qrcode";
import FileSaver, { saveAs } from "file-saver";
import { ReImg } from "reimg";

const QRCodeGenerator = ({
  btnText,
  qrText,
  qrColor,
  headerText,
  paragraph,
  logoUrl,
}) => {
  console.log({ ReImg });
  const canvasRef = useRef(null);
  const downloadLinkRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    canvas.width = 400;
    canvas.height = 550;
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.fillStyle = "#ffffff";
    context.fillRect(0, 0, canvas.width, canvas.height);

    const padding = 50;
    context.font = "bold 24px Arial";
    context.textAlign = "center";
    context.fillStyle = "#14328c";
    context.fillText(headerText, canvas.width / 2, padding + 24);

    context.font = "12px Arial";
    context.fillStyle = "#000000";
    context.textAlign = "center";

    context.fillText(
      "Scan this unique QR code from your",
      canvas.width / 2,
      padding + 54
    );
    context.fillText(
      "healthcare professional to easily register for",
      canvas.width / 2,
      padding + 74
    );
    context.fillText(
      "your PureInsight account.",
      canvas.width / 2,
      padding + 94
    );
    const initialYPosition = padding + 50;

    const qrCodeSize = 250;
    const qrCodeXPosition = (canvas.width - qrCodeSize) / 2;
    const qrCodeYPosition = initialYPosition + 100;

    const offscreenCanvas = document.createElement("canvas");
    offscreenCanvas.width = qrCodeSize;
    offscreenCanvas.height = qrCodeSize;

    QRCode.toCanvas(
      offscreenCanvas,
      qrText || "https://pureinsight.pureencapsulationspro.com",
      {
        width: qrCodeSize,
        margin: 0,
        color: {
          dark: qrColor || "#14328c",
          light: "#ffffff",
        },
        errorCorrectionLevel: "H",
      },
      (error) => {
        if (error) console.error(error);
        context.drawImage(offscreenCanvas, qrCodeXPosition, qrCodeYPosition);

        if (logoUrl) {
          const logoImage = new Image();
          logoImage.onload = () => {
            const logoWidth = 100;
            const logoHeight = 20;
            const logoXPosition = canvas.width - logoWidth - padding;
            const logoYPosition = 480;
            context.drawImage(
              logoImage,
              logoXPosition,
              logoYPosition,
              logoWidth,
              logoHeight
            );

            const dataUrl = canvas.toDataURL("image/png");
            const downloadLink = downloadLinkRef.current;
            downloadLink.href = dataUrl;
            downloadLink.download = "practice-qr-code.png";
          };
          logoImage.src = logoUrl;
        } else {
          const dataUrl = canvas.toBlob("image/png");
          const downloadLink = downloadLinkRef.current;
          downloadLink.href = dataUrl;
          downloadLink.download = "practice-qr-code.png";
        }
      }
    );
  }, [qrText, qrColor, headerText, paragraph, logoUrl]);

  const onDownload = () => {
    canvasRef.current.toBlob((blob) => {
      if (window.saveAs) {
        window.saveAs(blob, "practice-qr-code.png");
      } else {
        FileSaver.saveAs(blob, "practice-qr-code.png");
      }
    });
  };

  return (
    <div>
      <canvas ref={canvasRef} style={{ display: "none" }} />
      <a
        // onClick={onDownload}
        className="pg-link"
        ref={downloadLinkRef}
        style={{ display: "block" }}
      >
        <i className="fa fa-download"></i> {btnText}
      </a>
    </div>
  );
};

export default QRCodeGenerator;
