import { createAction } from "redux-api-middleware";

export const UPDATE_PURE_NUMBER_REQUEST = "UPDATE_PURE_NUMBER_REQUEST";
export const UPDATE_PURE_NUMBER_SUCCESS = "UPDATE_PURE_NUMBER_SUCCESS";
export const UPDATE_PURE_NUMBER_FAILURE = "UPDATE_PURE_NUMBER_FAILURE";

export const UPDATE_DISCOUNT_REQUEST = "UPDATE_DISCOUNT_REQUEST";
export const UPDATE_DISCOUNT_SUCCESS = "UPDATE_DISCOUNT_SUCCESS";
export const UPDATE_DISCOUNT_FAILURE = "UPDATE_DISCOUNT_FAILURE";

export const GET_MANAGEMENT_DATA_REQUEST = "GET_MANAGEMENT_DATA_REQUEST";
export const GET_MANAGEMENT_DATA_SUCCESS = "GET_MANAGEMENT_DATA_SUCCESS";
export const GET_MANAGEMENT_DATA_FAILURE = "GET_MANAGEMENT_DATA_FAILURE";

export const REVOKE_STRIPE_REQUEST = "REVOKE_STRIPE_REQUEST";
export const REVOKE_STRIPE_SUCCESS = "REVOKE_STRIPE_SUCCESS";
export const REVOKE_STRIPE_FAILURE = "REVOKE_STRIPE_FAILURE";

export const CANCEL_REVOKE_STRIPE_REQUEST = "CANCEL_REVOKE_STRIPE_REQUEST";
export const CANCEL_REVOKE_STRIPE_SUCCESS = "CANCEL_REVOKE_STRIPE_SUCCESS";
export const CANCEL_REVOKE_STRIPE_FAILURE = "CANCEL_REVOKE_STRIPE_FAILURE";

export const STRIPE_AUTO_CONNECT_REQUEST = "STRIPE_AUTO_CONNECT_REQUEST";
export const STRIPE_AUTO_CONNECT_SUCCESS = "STRIPE_AUTO_CONNECT_SUCCESS";
export const STRIPE_AUTO_CONNECT_FAILURE = "STRIPE_AUTO_CONNECT_FAILURE";

export const TAX_CERT_REQUEST = "TAX_CERT_REQUEST";
export const TAX_CERT_SUCCESS = "TAX_CERT_SUCCESS";
export const TAX_CERT_FAILURE = "TAX_CERT_FAILURE";


export const UPLOAD_TAX_REQUEST = "UPLOAD_TAX_REQUEST";
export const UPLOAD_TAX_SUCCESS = "UPLOAD_TAX_SUCCESS";
export const UPLOAD_TAX_FAILURE = "UPLOAD_TAX_FAILURE";

export const PAN_REQUEST = "PAN_REQUEST";
export const PAN_SUCCESS = "PAN_SUCCESS";
export const PAN_FAILURE = "PAN_FAILURE";


export const updatePureNumber = (
  token,
  currentPracticeID,
  currentPractice,
  data
) => {
  const protocol = process.env.REACT_APP_PROTOCOL;
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/rialto/update_pure_configuration`,
    body: JSON.stringify(data),
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      UPDATE_PURE_NUMBER_REQUEST,
      UPDATE_PURE_NUMBER_SUCCESS,
      UPDATE_PURE_NUMBER_FAILURE,
    ],
  });
};

export const fetchTaxCertificate = (
  token,
  currentPracticeID,
  currentPractice,
  data
) => {
  const protocol = window.location.protocol;
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/fetch_tax_certificate`,
    body: JSON.stringify(data),
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [TAX_CERT_REQUEST, TAX_CERT_SUCCESS, TAX_CERT_FAILURE],
  });
};

export const submitTaxCertificate = (
  token,
  currentPracticeID,
  data
) => {
  const RIALTO_BASE_URL = process.env.REACT_APP_RIALTO_BASE_URL;
  return createAction({
    endpoint: `${RIALTO_BASE_URL}/practice_tax_certificate`,
    body: data,
    method: "POST",
    headers: {
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [UPLOAD_TAX_REQUEST, UPLOAD_TAX_SUCCESS, UPLOAD_TAX_FAILURE],
  });
};

export const pureAccountCall = (data, token, currentPracticeID, currentPractice ) => {
  const protocol = process.env.REACT_APP_PROTOCOL;
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;

  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/check_pure_account`,
    method: "POST",
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json", Authorization: token, "Current-Practice": currentPracticeID, },
    types: [PAN_REQUEST, PAN_SUCCESS, PAN_FAILURE],
  });
};

export const updateDiscount = (
  token,
  currentPracticeID,
  currentPractice,
  data
) => {
  const protocol = process.env.REACT_APP_PROTOCOL;
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/rialto/update_discount`,
    body: JSON.stringify(data),
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      UPDATE_DISCOUNT_REQUEST,
      UPDATE_DISCOUNT_SUCCESS,
      UPDATE_DISCOUNT_FAILURE,
    ],
  });
};

export const revokeStripeConnect = (
  token,
  currentPracticeID,
  currentPractice,
  data
) => {
  const protocol = process.env.REACT_APP_PROTOCOL;
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/revoke_stripe_connect`,
    body: JSON.stringify(data),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      REVOKE_STRIPE_REQUEST,
      REVOKE_STRIPE_SUCCESS,
      REVOKE_STRIPE_FAILURE,
    ],
  });
};

export const stripeAutoConnectAPI = (
  token,
  currentPracticeID,
  currentPractice,
  data
) => {
  const protocol = process.env.REACT_APP_PROTOCOL;
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/stripe_auto_connect`,
    body: JSON.stringify(data),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      STRIPE_AUTO_CONNECT_REQUEST,
      STRIPE_AUTO_CONNECT_SUCCESS,
      STRIPE_AUTO_CONNECT_FAILURE,
    ],
  });
};

export const cancelRevokeStripeConnect = (
  token,
  currentPracticeID,
  currentPractice,
  data
) => {
  const protocol = process.env.REACT_APP_PROTOCOL;
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/cancel_revoke_stripe`,
    body: JSON.stringify(data),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      CANCEL_REVOKE_STRIPE_REQUEST,
      CANCEL_REVOKE_STRIPE_SUCCESS,
      CANCEL_REVOKE_STRIPE_FAILURE,
    ],
  });
};


export const getManagementData = (
  token,
  currentPracticeID,
  currentPractice,
  url
) => {
  const protocol = process.env.REACT_APP_PROTOCOL;
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;

  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/rialto_management_data`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      GET_MANAGEMENT_DATA_REQUEST,
      GET_MANAGEMENT_DATA_SUCCESS,
      GET_MANAGEMENT_DATA_FAILURE,
    ],
  });
};
