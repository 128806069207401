import axios from "axios";
import { createAction } from "redux-api-middleware";
const protocol = process.env.REACT_APP_PROTOCOL;
const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;

export const PATIENT_LIST_REQUEST = "PATIENT_LIST_REQUEST";
export const PATIENT_LIST_SUCCESS = "PATIENT_LIST_SUCCESS";
export const PATIENT_LIST_FAILURE = "PATIENT_LIST_FAILURE";

export const GET_PATIENT_PAST_REPORT_REQUEST =
  "GET_PATIENT_PAST_REPORT_REQUEST";
export const GET_PATIENT_PAST_REPORT_SUCCESS =
  "GET_PATIENT_PAST_REPORT_SUCCESS";
export const GET_PATIENT_PAST_REPORT_FAILURE =
  "GET_PATIENT_PAST_REPORT_FAILURE";

export const PATIENT_LIST_FOR_CATALOG_REQUEST =
  "PATIENT_LIST_FOR_CATALOG_REQUEST";
export const PATIENT_LIST_FOR_CATALOG_SUCCESS =
  "PATIENT_LIST_FOR_CATALOG_SUCCESS";
export const PATIENT_LIST_FOR_CATALOG_FAILURE =
  "PATIENT_LIST_FOR_CATALOG_FAILURE";

export const RESEND_INVITE_REQUEST = "RESEND_INVITE_REQUEST";
export const RESEND_INVITE_SUCCESS = "RESEND_INVITE_SUCCESS";
export const RESEND_INVITE_FAILURE = "RESEND_INVITE_FAILURE";

export const RESEND_INVITE_QUESTIONNAIRE_REQUEST =
  "RESEND_INVITE_QUESTIONNAIRE_REQUEST";
export const RESEND_INVITE_QUESTIONNAIRE_SUCCESS =
  "RESEND_INVITE_QUESTIONNAIRE_SUCCESS";
export const RESEND_INVITE_QUESTIONNAIRE_FAILURE =
  "RESEND_INVITE_QUESTIONNAIRE_FAILURE";

export const GET_PATIENT_DETAILS_REQUEST =
  "GET_PATIENT_DETAILS_REQUEST";
export const GET_PATIENT_DETAILS_SUCCESS =
  "GET_PATIENT_DETAILS_SUCCESS";
export const GET_PATIENT_DETAILS_FAILURE =
  "GET_PATIENT_DETAILS_FAILURE";

export const EDIT_PATIENT_REQUEST =
  "EDIT_PATIENT_REQUEST";
export const EDIT_PATIENT_SUCCESS =
  "EDIT_PATIENT_SUCCESS";
export const EDIT_PATIENT_FAILURE =
  "EDIT_PATIENT_FAILURE";


export const UPDATE_REPORT_WITHPRS_REQUEST =
  "UPDATE_REPORT_WITHPRS_REQUEST";
export const UPDATE_REPORT_WITHPRS_SUCCESS =
  "UPDATE_REPORT_WITHPRS_SUCCESS";
export const UPDATE_REPORT_WITHPRS_FAILURE =
  "GENERATE_REPORT_FAILURE";

// this constant should be removed once the functionality is no more needed  tag:pg2__migration
export const GENERATE_REPORT_REQUEST =
  "GENERATE_REPORT_REQUEST";
export const GENERATE_REPORT_SUCCESS =
  "GENERATE_REPORT_SUCCESS";
export const GENERATE_REPORT_FAILURE =
  "GENERATE_REPORT_FAILURE";

export const SET_PAGE_SIZE = "SET_PAGE_SIZE";
export const UPDATE_PATIENT_LIST = "UPDATE_PATIENT_LIST";

export const updatePatientList = (data) => ({
  type: UPDATE_PATIENT_LIST,
  payload: data,
});

// export const getPatientList = (
//   token,
//   currentPracticeID,
//   currentPractice,
//   url
// ) => {

//   return createAction({
//     endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/patients?${url}`,
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: token,
//       "Current-Practice": currentPracticeID,
//     },
//     types: [PATIENT_LIST_REQUEST,
//       {
//         type: PATIENT_LIST_SUCCESS,
//         payload: (action, state, res) => {
//           return res.json().then((json) => {
//             return { ...json };
//           });
//         },
//       }, PATIENT_LIST_FAILURE],
//   });
// };

export const getPatientList = (
  token,
  currentPracticeID,
  currentPractice,
  url) => {
  return axios.get(`${protocol}//${currentPractice}${PG3_BASE_URL}/practice/patients?${url}`, {
    headers: {
      Authorization: token,
      "Current-Practice": currentPracticeID,
    }
  })
}

export const resendAllPendingInvites = (
  currentPracticeID,
  currentPractice,
  token,
  id
) => {
  console.log(currentPractice)
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/resend_email/${id}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      RESEND_INVITE_REQUEST,
      RESEND_INVITE_SUCCESS,
      RESEND_INVITE_FAILURE,
    ],
  });
};


export const passwordSetupReminder = (
  currentPracticeID,
  currentPractice,
  token,
  id
) => {
  console.log(currentPractice)
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/patient_action_reminder/${id}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      RESEND_INVITE_REQUEST,
      RESEND_INVITE_SUCCESS,
      RESEND_INVITE_FAILURE,
    ],
  });
};

export const getPatientListForCatalog = (
  token,
  currentPracticeID,
  currentPractice,
  url
) => {

  return createAction({
    // endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/patients?${url}`,
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/patients/genetic_uploaded_patients?${url}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      PATIENT_LIST_FOR_CATALOG_REQUEST,
      PATIENT_LIST_FOR_CATALOG_SUCCESS,
      PATIENT_LIST_FOR_CATALOG_FAILURE,
    ],
  });
};

export const getPatientPastReport = (
  token,
  currentPracticeID,
  currentPractice,
  id
) => {

  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/patient_past_reports_data/${id}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      GET_PATIENT_PAST_REPORT_REQUEST,
      GET_PATIENT_PAST_REPORT_SUCCESS,
      GET_PATIENT_PAST_REPORT_FAILURE,
    ],
  });
};

export const getPatientDetails = (
  token,
  currentPracticeID,
  currentPractice,
  id
) => {
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/patients/${id}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      GET_PATIENT_DETAILS_REQUEST,
      GET_PATIENT_DETAILS_SUCCESS,
      GET_PATIENT_DETAILS_FAILURE,
    ],
  });
};

export const editPatient = (token,
  currentPracticeID,
  currentPractice,
  id, data) => {

  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/patients/${id}`,
    body: JSON.stringify(data),
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      EDIT_PATIENT_REQUEST,
      EDIT_PATIENT_SUCCESS,
      EDIT_PATIENT_FAILURE,
    ],
  });
};

export const sendInvites = (currentPracticeID, currentPractice, token, id) => {
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/questionnaire_reminder/${id}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      RESEND_INVITE_QUESTIONNAIRE_REQUEST,
      RESEND_INVITE_QUESTIONNAIRE_SUCCESS,
      RESEND_INVITE_QUESTIONNAIRE_FAILURE,
    ],
  });
};

export const sendReminderInvites = (currentPracticeID, currentPractice, token, id) => {
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/invite_snp_upload/${id}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      RESEND_INVITE_QUESTIONNAIRE_REQUEST,
      RESEND_INVITE_QUESTIONNAIRE_SUCCESS,
      RESEND_INVITE_QUESTIONNAIRE_FAILURE,
    ],
  });
};


// this is for pg2 patient migration  tag:pg2__migration
export const generateReport = (currentPracticeID, currentPractice, token, id) => {
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/web/api/generate_report/${id}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      GENERATE_REPORT_REQUEST,
      GENERATE_REPORT_SUCCESS,
      GENERATE_REPORT_FAILURE,
    ],
  });
};


export const gdprManageDataRequest = (token, currentPracticeID, currentPractice, pld) => {
  const body = JSON.stringify(pld);
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/user/patient/gdpr_user_request`,
    method: "POST",
    body,
    headers: { "Content-Type": "application/json", Authorization: token, 'Current-Practice': currentPracticeID },
    types: [
      EDIT_PATIENT_REQUEST,
      EDIT_PATIENT_SUCCESS,
      EDIT_PATIENT_FAILURE,
    ],
  });
}

export const updateReportWITHPRS = ({ token, currentPracticeID, currentPractice, pld }) => {
  const body = JSON.stringify(pld);
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/web/api/generate_ps_score`,
    method: "POST",
    body,
    headers: { "Content-Type": "application/json", Authorization: token, 'Current-Practice': currentPracticeID },
    types: [
      UPDATE_REPORT_WITHPRS_REQUEST,
      UPDATE_REPORT_WITHPRS_SUCCESS,
      UPDATE_REPORT_WITHPRS_FAILURE,
    ],
  });
}