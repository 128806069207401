import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import TextInput from "../components/TextInput";
import { Loading } from "../components/Loading";
import { Tabs, Tab, Button, Dropdown, Form } from "react-bootstrap";
import toast from "react-hot-toast";
import PgSelect from "../components/PgSelect";
import TaxUpload from "../components/Practice/TaxUpload";
import redX from "../assets/red-x.svg";
import greenTick from "../assets/green-tick.svg";
import { useTranslation } from "react-i18next";

import {
  updatePureNumber,
  updateDiscount,
  revokeStripeConnect,
  getManagementData,
  fetchTaxCertificate,
  cancelRevokeStripeConnect,
  stripeAutoConnectAPI,
} from "../store/actions/rialtoManagement";
import { acceptTermsConditions } from "../store/actions/auth";
import TermsAndConditions from "./TermsAndConditions";
import DiscountVd from "../components/DiscountVd";
import TaxModelVd from "../components/TaxModelVd";
import { isArray } from "lodash";
import PureAccountModelVd from "../components/PureAccountModelVd";

export const RialtoManagement = ({ history }) => {
  const token = useSelector((state) => state.auth.token);
  const currentPracticeID = useSelector(
    (state) => state.auth.currentPracticeID
  );
  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const STRIPE_CLIENT_ID = process.env.REACT_APP_STRIPE_CLIENT_ID;
  const REACT_APP_PG3_APP_DOMAIN = process.env.REACT_APP_PG3_APP_DOMAIN;
  const navigate = useNavigate();
  const [enableECommerce, setEnableECommerce] = useState(false);
  const [pureAccountNumber, setPureAccountNumber] = useState("");
  const [pureDiscount, setPureDiscount] = useState("");
  const [discount, setDiscount] = useState("");
  const [onSelDiscount, setonSelDiscount] = useState("");
  const [stripeAccount, setStripeAccount] = useState("");
  const [accountNumberRequired, setAccountNumberRequired] = useState(false);
  const [discountError, setDiscountError] = useState(false);
  const [pureDiscountError, setPureDiscountError] = useState(false);
  const [disableTabs, setDisableTabs] = useState(true);
  const [showRialtoTermModal, setShowRialtoTermModal] = useState(false);
  const [rialtoTerms, setRialtoTerms] = useState("");
  const [enableRevokeStripe, setEnableRevokeStripe] = useState(false);
  const [discountModal, setDiscountModal] = useState(false);
  const [taxModal, setTaxModal] = useState(false);
  const [practiceState, setPracticeState] = useState("");
  const [stripeStatus, setStripeStatus] = useState("");
  const [stripeAction, setStripeAction] = useState("");
  const [pureAccountModal, setPureAccountModal] = useState(false);
  const [sharedStripe, setSharedStripe] = useState(false);
  const language = useSelector((state) => state.auth.language);

  const [loading, setLoading] = useState(true);

  const stripeUrl = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${STRIPE_CLIENT_ID}&redirect_uri=${REACT_APP_PG3_APP_DOMAIN}/practice/stripe_redirect&scope=read_write&stripe_user[country]=US&stripe_user[currency]=usd&state=${currentPracticeID}`;

  const tax_data = useSelector(
    (state) => state.rialtoManagement.tax_cert_response
  );

  const pureAccountUpdateCall = (response) => {
    setShowRialtoTermModal(response?.agree_rialto_term);
    setRialtoTerms(response?.rialto_terms?.content);
    setEnableECommerce(true);
    setPureAccountNumber(response?.pan);
    if (
      response?.stripe_account_id &&
      (response?.stripe_account_id !== null ||
        response?.stripe_account_id !== "")
    ) {
      setStripeStatus(response?.stripe_status);
      setStripeAction(response?.stripe_action);
    }
  };

  const submitPureForm = (e) => {
    if (pureAccountNumber.length === 0) return setAccountNumberRequired(true);
    else setAccountNumberRequired(false);
    const data = {
      account_number: pureAccountNumber,
      discount: pureDiscount,
    };
    dispatch(
      updatePureNumber(token, currentPracticeID, currentPractice, data)
    ).then((response) => {
      setPureAccountNumber(response.payload?.pure_account_number);
      setPureDiscount(response.payload?.pure_discount);
      toast.success("You’ve updated Pure Account Details");
      if (response.payload.agree_rialto_term) {
        setRialtoTerms(response?.payload?.rialto_terms?.content);
        setShowRialtoTermModal(true);
      }
    });
  };

  const submitDiscountForm = (e) => {
    const data = {
      discount: discount,
    };
    dispatch(
      updateDiscount(token, currentPracticeID, currentPractice, data)
    ).then((response) => {
      setPureDiscount(response.payload?.pure_discount);
      setonSelDiscount(response.payload?.discount);
      toast.success("You’ve updated the Discount");
    });
  };

  const checkTaxCertMarker = () => {
    const tax_marker =
      Array.isArray(tax_data) &&
      tax_data.find(
        (item) =>
          // item.state === practiceState &&
          item.status.toLowerCase() === "processing" ||
          item.status.toLowerCase() === "accepted" ||
          item.status.toLowerCase() === "uploaded"
      );
    return tax_marker;
  };

  useEffect(() => {
    dispatch(getManagementData(token, currentPracticeID, currentPractice)).then(
      (response) => {
        setEnableECommerce(response.payload?.enable_e_commerce);
        setPureAccountNumber(response.payload?.pure_account_number);
        setPureDiscount(response.payload?.pure_discount);
        setDiscount(response.payload?.discount);
        setonSelDiscount(response.payload?.discount);
        setStripeAccount(response.payload?.stripe_account_id);
        setDisableTabs(!response.payload?.terms_agreed);
        setEnableRevokeStripe(response.payload?.revoke_stripe);
        setLoading(false);
        setPracticeState(response.payload?.practice_state);
        setStripeStatus(response.payload?.stripe_status);
        setStripeAction(response.payload?.stripe_action);
        setSharedStripe(response.payload?.shared_stripe);
        setShowRialtoTermModal(response.payload?.agree_rialto_term);
        setRialtoTerms(response.payload?.rialto_terms?.content);
      }
    );
  }, []);

  useEffect(() => {
    dispatch(fetchTaxCertificate(token, currentPracticeID, currentPractice));
  }, []);

  const revokeStripe = () => {
    const data = {
      current_practice_id: currentPracticeID,
    };
    dispatch(
      revokeStripeConnect(token, currentPracticeID, currentPractice, data)
    ).then((response) => {
      console.log(response);
      console.log("response ----------------------");
      setStripeStatus(response.payload.stripe_status);
      setStripeAction(response.payload.stripe_action);
      setStripeAccount(response.payload.stripe_account_id);
      setSharedStripe(sharedStripe);
      toast.success("Revoked Stripe Account");
    });
  };

  const stripeAutoConnect = (sharedStripe) => {
    const data = {
      shared_stripe: sharedStripe,
    };
    dispatch(
      stripeAutoConnectAPI(token, currentPracticeID, currentPractice, data)
    ).then((response) => {
      setStripeStatus(response.payload.stripe_status);
      setStripeAction(response.payload.stripe_action);
      // stripeStatus === "completed" && stripeAction === "revoked"
      // toast.success("Stripe Account Connected Successfully !!!");
    });
  };

  const cancelRevokeStripe = () => {
    const data = {
      current_practice_id: currentPracticeID,
    };
    dispatch(
      cancelRevokeStripeConnect(token, currentPracticeID, currentPractice, data)
    ).then((response) => {
      setStripeStatus(response.payload.stripe_status);
      setStripeAction(response.payload.stripe_action);
      toast.success("Stripe Account Updated Successfully !!!");
    });
  };

  const handleAcceptTerms = () => {
    dispatch(
      acceptTermsConditions(token, currentPracticeID, currentPractice, "rialto")
    ).then((response) => {
      if (!response.error) {
        setShowRialtoTermModal(false);
        setDisableTabs(false);
        dispatch(
          fetchTaxCertificate(token, currentPracticeID, currentPractice)
        );
      }
    });
  };

  return language === "en" ? (
    <div className="px-3 px-md-5 vd-setup-bg  py-5 ">
      <div className="row mt-5">
        <div className="position-relative pl-15p">
          <span
            className="d-inline-block cursor-pointer textc-primary pg-link"
            onClick={() => navigate("/practice/dashboard")}
          >
            <i className="fas fa-chevron-left "></i> {t("dashboard")}
          </span>
        </div>

        <div className="col-lg-12 col-xxl-12 col-xl-12 text-center mt-5">
          <h2 className="vd-subheading vd-text-primary mb-2">
            Virtual Dispensary Setup
          </h2>
          <div style={{margin: '0 20%', textAlign: 'left'}}>
            <p className="my-auto vd-text__lg mb-2">
              Already completed the virtual dispensary setup in Pure Patient
              Direct? Connect your Pure account and your virtual dispensary
              settings (including stripe information) will sync automatically.
              If you don't have a Pure Patient Direct dispensary, please
              complete the mandatory steps below.
            </p>
          </div>
        </div>
      </div>
      <div className="row col-lg-12 col-xl-12 col-xxl-12 mt-3 justify-content-center">
        <div className="col-lg-11 col-xl-10 col-xxl-8">
          <div className="d-flex gap-4 mt-2">
            <div className="col-lg-8 col-xl-8 col-xxl-8 d-flex justify-content-start">
              <span className="vd-circle">1</span>
              <div>
                <b className="vd-text__md pg-text">
                  Connect Your Pure Account (mandatory)
                </b>
                <br />
                <p className="vd-text__xx">
                  Connect your Pure account and your virtual dispensary settings
                  will sync automatically. If you don't have a Pure Patient
                  Direct dispensary, complete all mandatory steps to enable your
                  virtual dispensary. If you don’t have a Pure Encapsulations
                  Pro account,{" "}
                  <a href="https://www.pureencapsulationspro.com/">
                    click here
                  </a>{" "}
                  to register.
                </p>
              </div>
            </div>
            <div className="row col-lg-4 col-xl-4 col-xxl-4 text-end">
              <div className="col-lg-10 col-xl-10 col-xxl-10 align-self-center">
                <button
                  type="button"
                  disabled={enableECommerce && pureAccountNumber}
                  className={
                    enableECommerce && pureAccountNumber
                      ? "vd-vd-grey"
                      : "vd-vd-btn vd-bg-primary"
                  }
                  onClick={() => setPureAccountModal(true)}
                >
                  VERIFY PURE ACCOUNT{" "}
                  {pureAccountNumber && `(${pureAccountNumber})`}
                </button>
              </div>
            </div>
          </div>
          <div className="d-flex gap-4 mt-2">
            <div className="col-lg-8 col-xl-8 col-xxl-8 d-flex justify-content-start">
              <span className="vd-circle">2</span>
              <div>
                <b className="vd-text__md pg-text">
                  Complete your payment information (mandatory){" "}
                </b>
                <br />
                {stripeStatus != "completed" && stripeAction != "connected" ? (
                  <p className="vd-text__xx">
                    We have selected Stripe as our third-party payment service
                    which ensures that you will receive payment for any orders
                    placed by patients through your virtual dispensary.
                  </p>
                ) : (
                  <p className="vd-text__xx">
                    Stripe is our third-party payment service that ensures you
                    will receive payment for any orders placed by patients
                    through your virtual dispensary.
                  </p>
                )}
              </div>
            </div>
            <div className="row col-lg-4 col-xl-4 col-xxl-4 text-end">
              <div className="col-lg-10 align-self-center">
                {/* <%= link_to 'REVOKE STRIPE', practice_revoke_stripe_connect_path, className: "vd-vd-btn vd-bg-primary#{@revoke_stripe_flag ? '' : ' disabled'}", remote: true, data: { controller: 'practice', action: 'click -> practice#scrollPage' } %> */}
                {/* <button className="">REVOKE STRIPE</button><br /> */}

                {((stripeStatus === "completed" &&
                  stripeAction === "revoked") ||
                  !(stripeStatus && stripeAction)) &&
                  (sharedStripe ? (
                    <button
                      className={
                        !enableECommerce || !pureAccountNumber
                          ? "vd-vd-grey"
                          : "vd-vd-btn vd-bg-primary"
                      }
                      onClick={() => stripeAutoConnect(sharedStripe)}
                      disabled={!enableECommerce || !pureAccountNumber}
                    >
                      <i className="fas mx-1"></i> Confirm Information
                    </button>
                  ) : (
                    <button
                      className={
                        !enableECommerce || !pureAccountNumber
                          ? "vd-vd-grey"
                          : "vd-vd-btn vd-bg-primary"
                      }
                      onClick={() => (window.location.href = stripeUrl)}
                      disabled={!enableECommerce || !pureAccountNumber}
                    >
                      <i className="fas mx-1"></i> Confirm Information
                    </button>
                  ))}
                {stripeStatus == "completed" && stripeAction == "connected" && (
                  <>
                    <button
                      className={
                        !enableECommerce || !pureAccountNumber
                          ? "vd-vd-grey"
                          : "vd-vd-btn vd-bg-primary"
                      }
                      onClick={revokeStripe}
                      disabled={!enableECommerce || !pureAccountNumber}
                    >
                      <i className="fas mx-1"></i> Revoke Stripe
                    </button>
                  </>
                )}
                {stripeStatus === "pending" && stripeAction === "revoked" && (
                  <>
                    <button
                      className={
                        !enableECommerce || !pureAccountNumber
                          ? "vd-vd-grey"
                          : "vd-vd-btn vd-bg-primary"
                      }
                      onClick={cancelRevokeStripe}
                      disabled={!enableECommerce || !pureAccountNumber}
                    >
                      <i className="fas mx-1"></i> Cancel Revoke Stripe
                    </button>
                    <p
                      className="pg-text"
                      style={{ marginLeft: "10px", marginTop: "10px" }}
                    >
                      {" "}
                      This Stripe account cannot be revoked because recent
                      patient orders are still in “processing” status.
                    </p>
                  </>
                )}
              </div>
              <div className="col-lg-2 align-self-center mt-3 mt-lg-0">
                {!loading && (
                  <img
                    src={
                      stripeStatus == "completed" && stripeAction == "connected"
                        ? greenTick
                        : redX
                    }
                    alt="green-red-tick"
                    className="status-icon"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="d-flex gap-4 mt-2">
            <div className="col-lg-8 col-xl-8 col-xxl-8 d-flex justify-content-start">
              <span className="vd-circle">3</span>
              <div>
                <b className="vd-text__md pg-text">{`Select your patient's discount (optional)`}</b>
                <br />
                <p className="vd-text__xx">
                  If desired, you may choose to offer your patients a discount
                  from 5-35% on virtual dispensary products which will be
                  deducted from your profit margin.
                </p>
              </div>
            </div>
            <div className="row col-lg-4 col-xl-4 col-xxl-4 text-end">
              <div className="col-lg-10 mt-3">
                <button
                  className={
                    !enableECommerce || !pureAccountNumber
                      ? "vd-vd-grey"
                      : "vd-vd-btn vd-bg-primary"
                  }
                  onClick={() => setDiscountModal(true)}
                  disabled={!enableECommerce || !pureAccountNumber}
                >
                  {onSelDiscount != 0
                    ? onSelDiscount.toString().includes("%")
                      ? onSelDiscount
                      : onSelDiscount + "%"
                    : "SELECT"}{" "}
                  DISCOUNT
                </button>
                <br />
              </div>
              {onSelDiscount != 0 && (
                <div className="col-lg-2 align-self-center mt-3 mt-lg-0 mb-3">
                  <img
                    src={greenTick}
                    alt="green-tick"
                    className="status-icon"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="d-flex gap-4 mt-2">
            <div className="col-lg-8 col-xl-8 col-xxl-8 d-flex justify-content-start">
              <span className="vd-circle">4</span>
              <div>
                <b className="vd-text__md pg-text">
                  Provide your resale certificate (mandatory)
                </b>
                <br />
                <p className="vd-text__xx">
                  If your resale certificate is uploaded, you will avoid the
                  collection of state taxes on the transaction between your
                  patient and you. If your resale certificate is available
                  through your Pure account, it does not need to be re-uploaded.
                </p>
              </div>
            </div>
            <div className="row col-lg-4 col-xl-4 col-xxl-4 text-end">
              <div className="col-lg-10 col-xl-10 col-xxl-10 align-self-center">
                {/* <% is_uploaded_for_own_state = @practice_tax_certificates.map {| key, status | status[0]["status"]}.find {| status | status.downcase == "processing" || status.downcase == "accepted" || status.downcase == "uploaded"}.present? %> */}
                <button
                  type="button"
                  className={
                    !enableECommerce || !pureAccountNumber
                      ? "vd-vd-grey"
                      : "vd-vd-btn vd-bg-primary"
                  }
                  onClick={() => setTaxModal(true)}
                  disabled={!enableECommerce || !pureAccountNumber}
                >
                  UPLOAD CERTIFICATE
                </button>
              </div>
              {checkTaxCertMarker() && (
                <div className="col-lg-2 align-self-center mt-3 mt-lg-0">
                  <img
                    src={greenTick}
                    alt="green-tick"
                    className="status-icon"
                  />

                  <br />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <DiscountVd
        showModal={discountModal}
        onCancel={() => setDiscountModal(false)}
        setDiscount={setDiscount}
        discount={discount}
        discountError={discountError}
        submitDiscountForm={submitDiscountForm}
      />
      <TaxModelVd
        showModal={taxModal}
        onCancel={() => setTaxModal(false)}
        token={token}
        currentPracticeID={currentPracticeID}
        currentPractice={currentPractice}
      />
      <PureAccountModelVd
        showModal={pureAccountModal}
        onCancel={() => setPureAccountModal(false)}
        token={token}
        currentPracticeID={currentPracticeID}
        currentPractice={currentPractice}
        isLogin={true}
        onRefresh={(response) => pureAccountUpdateCall(response)}
        enableClose={false}
        // setShowRialtoTermModal={setShowRialtoTermModal}
        // setRialtoTerms={setRialtoTerms}
      />
      <TermsAndConditions
        showModal={showRialtoTermModal}
        content={rialtoTerms}
        showClose={() => showRialtoTermModal(false)}
        showButton={true}
        acceptTerms={() => handleAcceptTerms("rialto")}
        title={"VIRTUAL DISPENSARY TERMS OF USE"}
      />
    </div>
  ) : (
    <div className="px-3 px-md-5 vd-setup-bg  py-5 mb-5 mt-5 ">
      <p className="text-center">
        You do not have permission to see this page.
      </p>
    </div>
  );
};
export default RialtoManagement;
