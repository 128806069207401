import { t } from "i18next";
import _ from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const RadioSelection = ({ question, onInput }) => {
  const { t } = useTranslation();
  const answers = useSelector((state) => state.pg4.q_answers);
  const errors = useSelector((state) => state.pg4.q_errors);

  return (
    <div className="pg4-card" id={question.question_id}>
      <div className="pg-heading__sub textc-primary__p">
        {t(question?.question_text)}
      </div>
      <div className="pg-text">{t(question?.placeholder)}</div>

      <div className="d-flex mt-3 flex-wrap">
        {_.isArray(question?.choices) &&
          question?.choices.map((choice, index) => (
            <div
              key={choice + index}
              className={`cursor-pointer me-2 my-2 msq-radio ${
                choice == answers?.[String(question.question_id)]?.answer[0]
                  ? "msq-radio__selected"
                  : ""
              }`}
              onClick={() => {
                onInput({
                  [question.question_id]: {
                    question_id: question.question_id,
                    answer: [choice],
                  },
                });
              }}
            >
              {t(choice)}
            </div>
          ))}
      </div>
      <div
        className="text-danger transition-all"
        style={{ opacity: errors[question?.question_id] ? 1 : 0 }}
      >
        <i className="fas fa-info-circle"></i> {t("pracand_genetic_error")}
      </div>
    </div>
  );
};

export default RadioSelection;
