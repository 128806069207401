import CustomBadgePill from "../CustomBadgePill";
import PgCheckbox from "../PgCheckbox";

export default function PatientFilterComponent({
  filterPending,
  filterActive,
  filterArchived,
  filterNoData,
  filterReportArchived,
  filterReportUploaded,
  setFilterPending,
  setFilterActive,
  setFilterArchived,
  setFilterNoData,
  setFilterReportArchived,
  setFilterReportUploaded,
  userStatus,
  setUserStatus,
  reportStatus,
  setReportStatus
}) {

  const setStatusFilter = (status) => {
    if(userStatus.indexOf(status) == -1){
      setUserStatus([...userStatus, status])
    }
    else{
      userStatus.splice(userStatus.indexOf(status), 1);
    }
    switch(status) {
      case "pending":
        setFilterPending(!filterPending)
        break;
      case "active":
        setFilterActive(!filterActive)
        break;
      case "archived":
        setFilterArchived(!filterArchived)
        break;
      default:
        console.log("untracked status")
    }
  }
  
  const setReportStatusFilter = (status) => {
    if(reportStatus.indexOf(status) == -1){
      setReportStatus([...reportStatus, status])
    }
    else{
      reportStatus.splice(reportStatus.indexOf(status), 1);
    }
    switch(status) {
      case "uploaded":
        setFilterReportUploaded(!filterReportUploaded)
        break;
      case "no_data":
        setFilterNoData(!filterNoData)
        break;
      case "archived":
        setFilterReportArchived(!filterReportArchived)
        break;
      default:
        console.log("untracked report status")
    }
  }
  return (
    <div className="row px-3 py-1">
      <div className="col-12">
        <div className="d-inline-block">
          <PgCheckbox
            title="Pending"
            id="f-pending__patientlist"
            checked={filterPending}
            onCheck={() => setStatusFilter("pending")}
          />
        </div>
        <br />
        <div className="d-inline-block">
          <PgCheckbox
            title="Active"
            id="f-active__patientlist"
            checked={filterActive}
            onCheck={() => setStatusFilter("active")}
          />
        </div>
        <br />
        <div className="d-inline-block">
          <PgCheckbox
            title="No Data"
            id="f-nodata__patientlist"
            checked={filterNoData}
            onCheck={() => setReportStatusFilter("no_data")}
          />
        </div>
        <br />
        <div className="d-inline-block">
          <PgCheckbox
            title="Report Uploaded"
            id="f-report-uploaded__patientlist"
            checked={filterReportUploaded}
            onCheck={() => setReportStatusFilter("uploaded")}
          />
        </div>
        <br />
        <div className="d-inline-block">
          <PgCheckbox
            title="Patient Archived"
            id="f-archived__patientlist"
            checked={filterArchived}
            onCheck={() => setStatusFilter("archived")}
          />
        </div>
        <div className="d-inline-block">
          <PgCheckbox
            title="Report Archived"
            id="f-report-archived__patientlist"
            checked={filterReportArchived}
            onCheck={() => setReportStatusFilter("archived")}
          />
        </div>
        <br />
      </div>
    </div>
  );
}
