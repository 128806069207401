import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useSelector} from 'react-redux';
import {Loading} from '../components/Loading';
import toast from 'react-hot-toast';


export const StripeRedirect = ({ history}) => {

  const protocol = process.env.REACT_APP_PROTOCOL;
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;
  const PG3_BASE_WEB_URL = process.env.REACT_APP_BASE_DOMAIN;


  // redux state variable


  const currentPracticeID = useSelector(state => state.auth.currentPracticeID);
  const currentPractice = useSelector(state => state.auth.currentPractice);
  const token = useSelector(state => state.auth.token);




  useEffect(() => {
    var url_string = window.location.href
    var url = new URL(url_string);
    var code = url.searchParams.get("code");

    axios.post(
      `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/add_stripe_connect`,
      {code: code},
      {
        headers: {
          Authorization: token,
          "Current-Practice": currentPracticeID,
        },
      },
    ).then(response=>{
      toast.success(
        "",
        "You’ve connected stripe account"
      );
      window.location.href = `${protocol}//${currentPractice}${PG3_BASE_WEB_URL}/practice/rialto_management`
    }).catch(err=>{
      toast.error(err.response.statusText)
    });
  }, []);

  return (
    <Loading title="Redirecting"/>
  )
}

export default StripeRedirect;