import React from 'react'
import ContentLoader from 'react-content-loader'

function L_GeneAnalysisContent(props) {
    return (
        <ContentLoader
        speed={2}
        width="100%"
        height="20%"
        viewBox="0 0 600 140"
        backgroundColor="#9e9e9e52"
      foregroundColor="#18368f80"
        {...props}
      >  
        {/* Diagram title */}
        <rect x="5" y="1" rx="3" ry="3" width="585" height="30" />
        <rect x="5" y="40" rx="3" ry="3" width="585" height="6" />
        <rect x="5" y="55" rx="3" ry="3" width="585" height="6" />
        <rect x="5" y="70" rx="3" ry="3" width="585" height="6" />
        <rect x="5" y="85" rx="3" ry="3" width="585" height="6" />
        <rect x="5" y="100" rx="3" ry="3" width="585" height="6" />
        <rect x="5" y="115" rx="3" ry="3" width="585" height="6" />
        <rect x="5" y="130" rx="3" ry="3" width="585" height="6" />
      </ContentLoader>
    )
}

export default L_GeneAnalysisContent
