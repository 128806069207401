import React, { useState, useEffect } from "react";
import i_blue from "../assets/i_blue.png";
import _i from "../assets/i.png";
import Popup from "reactjs-popup";
import { t } from "i18next";

function PgGroupButton({ buttonNames, activeButton, onButtonClick }) {
  const style = {
    btnContainer: {
      margin: 0,
      padding: 0,
    },
  };
  return (
    <div
      className="br d-flex pg-text mx-0 px-0  transition-all"
      style={style.btnContainer}
    >
      <Popup
        on="hover"
        trigger={(open) => (
          <div
            onClick={() => onButtonClick("Show All")}
            className="cp px-3 mx-0 transition-all"
            style={{
              borderRadius: `4px 0 0 4px`,
              borderTop: "2px solid var(--pg_primary)",
              borderBottom: "2px solid var(--pg_primary)",
              borderLeft: "2px solid var(--pg_primary)", // comment this for more than 2 buttons
              // borderLeft: (i === 0) ? '2px solid var(--pg_primary)':"", // uncomment this for more than 2 buttons
              borderRight: "2px solid var(--pg_primary)",
              color: activeButton === "Show All" ? "#fff" : "var(--pg_primary)",
              background:
                activeButton === "Show All" ? "var(--pg_primary)" : "",
            }}
          >
            {buttonNames[0]}
          </div>
        )}
        position="top center"
        closeOnDocumentClick
        className="cp"
      >
        <div style={{ padding: "12px" }}>{t("action_btn_txt_2")}</div>
      </Popup>
      <Popup
        on="hover"
        trigger={(open) => (
          <div
            onClick={() => onButtonClick("Show Actionable")}
            className="cp px-3 mx-0 transition-all"
            style={{
              borderRadius: "0 4px 4px 0",
              borderTop: "2px solid var(--pg_primary)",
              borderBottom: "2px solid var(--pg_primary)",
              borderLeft: "2px solid var(--pg_primary)", // comment this for more than 2 buttons
              // borderLeft: (i === 0) ? '2px solid var(--pg_primary)':"", // uncomment this for more than 2 buttons
              borderRight: "2px solid var(--pg_primary)",
              color:
                activeButton === "Show Actionable"
                  ? "#fff"
                  : "var(--pg_primary)",
              background:
                activeButton === "Show Actionable" ? "var(--pg_primary)" : "",
            }}
          >
            {buttonNames[1]}
          </div>
        )}
        position="top center"
        closeOnDocumentClick
        className="cp"
        contentStyle={{ width: "auto" }}
      >
        <div style={{ padding: "12px" }}>
          <div dangerouslySetInnerHTML={{ __html: t("action_btn_txt_1") }} />
        </div>
      </Popup>
    </div>
  );
}

export default PgGroupButton;

const ActionButtonTooltip = ({ children }) => {
  return (
    <Popup
      on="hover"
      trigger={(open) => <>{children}</>}
      position="top center"
      closeOnDocumentClick
      className="cp"
    >
      <div style={{ padding: "12px" }}>
        Show traits with ‘Consider Action’ <br />
        or 'Enhanced Benefit’.
      </div>
    </Popup>
  );
};
