import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function SkipQuestionnrie({ onAccept, onCancel, showModal }) {

  const { t, i18n } = useTranslation();
  
  return (
    <Modal 
    show={showModal} 
    size="lg" 
    aria-labelledby="contained-modal-title-vcenter" 
    onHide={onCancel}
    contentClassName="border-radius"
    >
      {/* <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
        </Modal.Title>
      </Modal.Header> */}
      <Modal.Body>
        <span dangerouslySetInnerHTML={{ __html: t('patient_dashboard_skip1') }} />
        {/* <p>This will send email to all the registered patient ....</p> */}
      </Modal.Body>
      <Modal.Footer className="skip-q-footer">
        <button className="btn bg-grey text-dark px-5 custom-width" onClick={onCancel}>{ t('cancel') }</button>
        <button className="btn bgc-primary text-light px-5 custom-width" style={{color: '#2470c2 !important', backgroungColor: '#2470c2 !important'}} onClick={onAccept}>{ t('patient_dashboard_p5') }</button>
      </Modal.Footer>
    </Modal>
  );
}

export default SkipQuestionnrie;