import * as pr_constant from "../actions/patientReport";

const initialState = {
  currentPatientId: null,
  currentReportId: null,
  isGettingPatientDetail: true,
  isGettingTraitCategories: true,
  isGettingSupplementSuggestions: true,
  isGettingLifeStyleSuggestion: true,
  isGettingGeneAnanlsisReport: true,
  isGettingGeneAnalysisList: true,
  isGettingGeneAnalysisContent: true,
  isGettingReportDiagram: true,
  isGettingGenotype: true,
  isGettingGenotypeFilters: true,
  patientDetail: {
    genetic_vendor: null,
    lab_result: [],
    patient: {},
  },
  traitCategories: [],
  supplementSuggestions: {
    supplements: [],
    script_available: false,
  },
  lifeStyleSuggestion: {},
  geneAnalysisList: [],
  geneAnalysisContent: [],
  reportDiagrams: [],
  isUrl: false,
  genotypeReport: [],
  genotypeFilters: [],
  error: false,
  pdf: '',
};

const patientReport = (state = initialState, action) => {
  switch (action.type) {
    case pr_constant.UPDATE_PATIENT_REPORT: {
      return { ...state, ...action.payload };
    }
    // patient detail
    case pr_constant.GET_PATIENT_DETAIL_REQUEST: {
      return {
        ...state, isGettingPatientDetail: true, patientDetail: {
          genetic_vendor: null,
          lab_result: [],
          patient: {},
        },
      };
    }
    case pr_constant.GET_PATIENT_DETAIL_SUCCESS: {
      const patient = action.payload;
      return {
        ...state,
        isGettingPatientDetail: false,
        patientDetail: action.payload,
      };
    }
    case pr_constant.GET_PATIENT_DETAIL_FAILURE: {
      return { ...state, isGettingPatientDetail: false };
    }

    // trait category
    case pr_constant.GET_TRAIT_CATEGORIES_REQUEST: {
      return { ...state, isGettingTraitCategories: true, traitCategories: [] };
    }
    case pr_constant.GET_TRAIT_CATEGORIES_SUCCESS: {
      return {
        ...state,
        isGettingTraitCategories: false,
        traitCategories: action.payload,
      };
    }
    case pr_constant.GET_TRAIT_CATEGORIES_FAILURE: {
      return { ...state, isGettingTraitCategories: false };
    }

    // supplement suggestion
    case pr_constant.GET_SUPPLEMENT_SUGGESTION_REQUEST: {
      return { ...state, isGettingSupplementSuggestions: true, supplementSuggestions: [] };
    }
    case pr_constant.GET_SUPPLEMENT_SUGGESTION_SUCCESS: {
      return {
        ...state,
        isGettingSupplementSuggestions: false,
        supplementSuggestions: action.payload,
      };
    }
    case pr_constant.GET_SUPPLEMENT_SUGGESTION_FAILURE: {
      return { ...state, isGettingSupplementSuggestions: false };
    }

    // lifestyle suggestion
    case pr_constant.GET_LIFESTYLE_SUGGESTIONS_REQUEST: {
      return { ...state, isGettingLifeStyleSuggestion: true, lifeStyleSuggestion: {} };
    }
    case pr_constant.GET_LIFESTYLE_SUGGESTIONS_SUCCESS: {
      return {
        ...state,
        isGettingLifeStyleSuggestion: false,
        lifeStyleSuggestion: action.payload,
      };
    }
    case pr_constant.GET_LIFESTYLE_SUGGESTIONS_FAILURE: {
      return { ...state, isGettingLifeStyleSuggestion: false };
    }

    // gene analysis
    case pr_constant.GET_GENE_ANALYSIS_LIST_REQUEST: {
      return { ...state, isGettingGeneAnalysisList: true };
    }
    case pr_constant.GET_GENE_ANALYSIS_LIST_SUCCESS: {
      return {
        ...state,
        isGettingGeneAnalysisList: false,
        geneAnalysisList: action.payload,
      };
    }
    case pr_constant.GET_GENE_ANALYSIS_LIST_FAILURE: {
      return { ...state, isGettingGeneAnalysisList: false };
    }

    // gene analysis report
    case pr_constant.GET_GENE_ANALYSIS_CONTENT_REQUEST: {
      return { ...state };
    }
    case pr_constant.GET_GENE_ANALYSIS_CONTENT_SUCCESS: {
      return { ...state };
    }
    case pr_constant.GET_GENE_ANALYSIS_CONTENT_FAILURE: {
      return { ...state };
    }

    // report diagram
    case pr_constant.GET_REPORT_DIAGRAMS_REQUEST: {
      return { ...state, isGettingReportDiagram: true, reportDiagrams: [] };
    }
    case pr_constant.GET_REPORT_DIAGRAMS_SUCCESS: { 
      var isUrl = (action.payload?.is_url && action.payload?.is_url == true) ? true: false 
      return {
        ...state,
        isGettingReportDiagram: false,
        reportDiagrams: action.payload.diagrams,
        isUrl: isUrl
      };
    }
    case pr_constant.GET_REPORT_DIAGRAMS_FAILURE: {
      return { ...state, isGettingReportDiagram: false };
    }

    // supplemental genotype filter
    case pr_constant.GET_GENOTYPE_FILTER_REQUEST: {
      return { ...state, isGettingGenotypeFilters: true };
    }
    case pr_constant.GET_GENOTYPE_FILTER_SUCCESS: {
      return {
        ...state,

        isGettingGenotypeFilters: false,
      };
    }
    case pr_constant.GET_GENOTYPE_FILTER_FAILURE: {
      return { ...state, isGettingGenotypeFilters: false };
    }
    // supplemental genotype
    case pr_constant.GET_SUPPLEMENTAL_GENOTYPE_REQUEST: {
      return { ...state, isGettingGenotype: true, genotypeReport: [] };
    }
    case pr_constant.GET_SUPPLEMENTAL_GENOTYPE_SUCCESS: {
      return {
        ...state,
        isGettingGenotype: false,
        genotypeReport: action.payload,
      };
    }
    case pr_constant.GET_SUPPLEMENTAL_GENOTYPE_FAILURE: {
      return { ...state, isGettingGenotype: false, genotypeReport: [] };
    }
    case pr_constant.DOWNLOAD_REPORT_SUCCESS: {
      return { ...state, pdf: Response.payload };
    }
    case pr_constant.DOWNLOAD_REPORT_REQUEST: {
      return { ...state };
    }
    case pr_constant.DOWNLOAD_REPORT_FAILURE: {
      return { ...state };
    }
    default:
      return state;
  }
};

export default patientReport;
