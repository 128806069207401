import ContentLoader from "react-content-loader";
function L_PatientList() {
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height="20%"
      viewBox="0 0 600 400"
      backgroundColor="#9e9e9e52"
      foregroundColor="#18368f80"
      // {...props}
    >
      {/* Diagram title */}
      <rect x="0" y="1" rx="3" ry="3" width="600" height="30" />
      <rect x="0" y="40" rx="3" ry="3" width="600" height="25" />
      <rect x="0" y="70" rx="3" ry="3" width="600" height="25" />
      <rect x="0" y="100" rx="3" ry="3" width="600" height="25" />
      <rect x="0" y="130" rx="3" ry="3" width="600" height="25" />
      <rect x="0" y="160" rx="3" ry="3" width="600" height="25" />
      <rect x="0" y="190" rx="3" ry="3" width="600" height="25" />
      <rect x="0" y="220" rx="3" ry="3" width="600" height="25" />
      <rect x="0" y="250" rx="3" ry="3" width="600" height="25" />
      <rect x="0" y="280" rx="3" ry="3" width="600" height="25" />
      <rect x="0" y="310" rx="3" ry="3" width="600" height="25" />
      {/* table footer */}
      <rect x="0" y="350" rx="3" ry="3" width="60" height="25" />
      <rect x="500" y="355" rx="3" ry="3" width="60" height="8" />
      <rect x="570" y="355" rx="3" ry="3" width="10" height="8" />
      <rect x="585" y="355" rx="3" ry="3" width="10" height="8" />
    </ContentLoader>
  );
}

export default L_PatientList;
