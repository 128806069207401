import { ProgressBar } from "react-bootstrap";

export function QuestionnaireProgressBar({ status, color }) {
  let _status = status === "Processing" ? 'Analysing' : status;
  return (
    <div className="">
      <div className="mt-2 mt-lg-2">
        <ProgressBar
          animated
          variant={color}

          now={
            _status === "analyzing"
              ? 50
              : _status === "complete"
                ? 100
                : 50
          }
          style={{ height: 30, borderRadius: "0px" }}
        />
      </div>
      {/* <div className="d-flex justify-content-between pg-text__bold__small blurry-grey mt-2">
        <div
          className={
            status === "uploading" ? (error ? "text-danger" : "text-dark") : ""
          }
          style={error ? { color: "red !important" } : { color: "" }}
        >
          uploading
        </div>
        <div
          className={
            status === "importing" ? (error ? "text-danger" : "text-dark") : ""
          }
          style={error ? { color: "red !important" } : { color: "" }}
        >
          importing
        </div>
        <div
          className={
            status === "analyzing" ? (error ? "text-danger" : "text-dark") : ""
          }
          style={error ? { color: "red !important" } : { color: "" }}
        >
          analyzing
        </div>
        <div
          className={
            status === "complete" ? (error ? "text-danger" : "text-dark") : ""
          }
        >
          complete
        </div>
      </div> */}
    </div>
  );
}
