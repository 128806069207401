import * as su_constant from "../actions/snpUpload";

const initialState = {
  error: false,
  uploadError: false,
  loading: false,
  fileStatus: [],
};

const snpUploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case su_constant.SNP_UPLOAD_REQUEST: {
      return { ...state, loading: true };
    }
    case su_constant.SNP_UPLOAD_SUCCESS: {
      return { ...state, loading: false };
    }
    case su_constant.SNP_UPLOAD_FAILURE: {
      return { ...state, loading: false, uploadError: true };
    }
    case su_constant.UPDATE_SNP_UPLOAD: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

export default snpUploadReducer;
