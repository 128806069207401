import React from "react";
import { Link } from "react-router-dom";
import SampleImage from "../../../assets/iris-large.svg";
import Hero from "../../../assets/static/pg.png";
import videoIconWhite from "../../../assets/static/videoIconWhite.svg";
import Report from "../../../assets/01_icon_report.svg";
import Science from "../../../assets/02_icon_science.svg";
import Education from "../../../assets/03_icon_education.svg";
import Supplements from "../../../assets/04_icon_supplements.svg";
import DPGImage_9 from "../../../assets/static/dpg_9.png";

function EducationalScience({ history }) {
  return (
    <div className="my-5">
      <div className="container-xxl px-0">
        {/* section 1 */}
        <div className="row justify-content-lg-around justify-content-center my-2 my-lg-3">
          <div className="col-11 col-lg-5 order-last order-lg-first">
            <div className="d-none d-lg-block">
              <div className="pgs-title__xl my-1">
                <span className="pgs-title">Welcome to the</span>
                <span className="pgs-title pgs-title__v textc-primary">
                  Future of Health and Wellness
                </span>
              </div>
            </div>
            <br />
            <div className="pgs-text">
              <div className="my-2">
                At PureInsight<sup>&trade;</sup>, DNA-based lifestyle and
                supplement recommendations are complemented with optional
                questionnaire and lab data, which are easy and efficient to make
                for a truly personalised approach. Because when we’re closer to
                our healthiest selves, we’re one step closer to the goodness
                within.
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 my-auto px-0">
            <div className="d-lg-none my-4 px-4">
              <div className="pgs-title pgs-title__xl my-1">
                Welcome to the{" "}
                <div className="ff-v">Future of Health and Wellness</div>
              </div>
            </div>
            <div className="react-player cursor-pointer">
              <div>
                <img
                  src={Hero}
                  alt="puregenomics video"
                  className="img-fluid w-100"
                />
              </div>

              <div className="text-center react-player-control">
                <a href="https://youtu.be/0Dk04H0k9sc" target="_blank">
                  <img src={videoIconWhite} alt="videoIcon" />
                </a>
                <a href="#" className="text-decoration-none">
                  <div className="pgs-text text-white mt-3">Learn More</div>
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* section 2 */}
        <div className="row m-2 my-lg-3 mx-0">
          <div className="my-5">
            <div className="pgs-heading fw-bold textc-primary text-center my-3">
              We’re here to help you get started.
            </div>
            <div className="pgs-text text-center">
              Integrating PureInsight<sup>&trade;</sup> into your practice
              doesn’t have to be a huge time commitment or significant financial
              or educational investment. We’ve listened to our customers over
              the years and have put together an onboarding plan that’s
              seamless. We’ve done the heavy lifting so you don’t have to. When
              you become a PureInsight<sup>&trade;</sup> practitioner you’ll get
              access to tools and education to help you get started, including:
            </div>
          </div>

          <div className="row justify-content-between justify-content-lg-around my-1 my-lg-4 px-0">
            <div className="col-11 col-lg-5 my-lg-5 my-4">
              <img
                alt="PureInsight™ Overview Image"
                src={Science}
                className="pb-2"
              />
              <div className="pgs-heading__sub ff-m textc-primary fw-bold pb-2 d-block">
                PureInsight<sup>&trade;</sup> Overview
              </div>
              <div className="pgs-text">
                Access tools that address everything from data and privacy to
                instructions to help you get started, including:{" "}
                <div className="fw-bold textc-primary cp">
                  PureInsight<sup>&trade;</sup> for Practitioners
                </div>
                <div className="fw-bold textc-primary cp">
                  PureInsight<sup>&trade;</sup> for Patients
                </div>
                <Link className="fw-bold textc-primary" to="/hcp/faqs">
                  FAQs
                </Link>
              </div>
            </div>

            <div className="col-11 col-lg-5 my-lg-5 my-4">
              <img
                alt={" Further Your Education Image"}
                src={Report}
                className="pb-2"
              />
              <div className="pgs-heading__sub ff-m textc-primary fw-bold pb-2 d-block">
                Further Your Education
              </div>
              <div className="pgs-text">
                We offer a wide range of educational videos to enchance your
                knowledge on topics ranging from 1-minute tutorials to a
                90-minute Nutrugenomics Course. Get an Introduction to
                Nutrugenomics <a href="#">here</a>
              </div>
            </div>
          </div>

          <div className="row justify-content-between justify-content-lg-around my-1 my-lg-4 px-0">
            <div className="col-11 col-lg-5 my-lg-5 my-4">
              <img alt={Education} src={Education} className="pb-2" />
              <div className="pgs-heading__sub ff-m textc-primary fw-bold pb-2 d-block">
                Resources and Education
              </div>
              <div className="pgs-text">
                As the science of nutrigenomics evolves, our understanding and
                interpretation of the insights it provides does too. Beyond
                PureInsight<sup>&trade;</sup> reports, we're here to support you
                and your practice with tools and ongoing education.
              </div>
            </div>

            <div className="col-11 col-lg-5 my-lg-5 my-4">
              <img alt={Supplements} src={Supplements} className="pb-2" />
              <div className="pgs-heading__sub ff-m textc-primary fw-bold pb-2 d-block">
                Even More Resources Available:
              </div>
              <div className="pgs-text">
                Support for you and your patients is continuously evolving, by
                creating an PureInsight<sup>&trade;</sup> account you will have
                access to:
                <ul>
                  <li>
                    Research abstracts for the science behind the SNPs included
                    in PureInsight<sup>&trade;</sup>
                  </li>
                  <li>
                    On-Demand webinars filled with the latest science, case
                    studies and applications for PureInsight<sup>&trade;</sup> in
                    practice.
                  </li>
                  <li>
                    Access to 1 on 1 coaching from our business integration
                    specialist, Kara Ware.{" "}
                    <a href="#" className="fw-bold">
                      Here is how
                    </a>{" "}
                    Kara applies PureInsight<sup>&trade;</sup> into a practice.
                    {/* (Link to video #3 of Clarity in Clinic) */}
                  </li>
                </ul>
                <Link
                  to="/hcp/getting-started"
                  className="fw-bold text-decoration-underline textc-primary"
                >
                  GET STARTED AS A HEALTHCARE PROVIDER and get access to more
                  educational content and assets.
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* section 3 */}
        {/* <div className="row justify-content-lg-around justify-content-center my-5">
        <div className="col-12 col-lg-6 order-lg-last p-0">
          <img src={DPGImage_9} alt="Ipad Image" className="w-100" />
        </div>
        <div className="col-11 col-lg-5 my-auto">
          <div className="pgs-title my-3">
            Ready to <div className="pgs-title__v">Get Started?</div>
          </div>
          <div className="pgs-text my-lg-2 my-4">
            Once you're ready to put the power of PureInsight<sup>&trade;</sup> to work in your
            practice, you can get started in <b>2 simple steps.</b>
          </div>
          <button
            className="btn btn-primary"
            onClick={() => navigate("/hcp/getting-started")}
          >
            Get Started As A Healthcare Provider
          </button>
        </div>
      </div>
     */}
      </div>
    </div>
  );
}

export default EducationalScience;
