import ContentLoader from "react-content-loader";
function L_PG4ReportSummary(props) {
    return (
        <ContentLoader
            speed={2}
            width="100%"
            height="20%"
            viewBox="0 0 600 1100"
            backgroundColor="#f5f5f5"
            foregroundColor="#2470c210"
            {...props}
        >
            {/* trait header */}
            <rect x="0" y="10" rx="3" ry="3" width="160" height="20" />
            <rect x="290" y="0" rx="3" ry="3" width="150" height="40" />
            <rect x="450" y="0" rx="3" ry="3" width="150" height="40" />

            {/* patient info card */}
            <rect x="0" y="60" rx="3" ry="3" width="150" height="30" />
            <rect x="0" y="100" rx="3" ry="3" width="600" height="300" />

            {/* old report card */}
            <rect x="0" y="420" rx="3" ry="3" width="600" height="80" />

            <rect x="0" y="540" rx="3" ry="3" width="150" height="40" />
            <rect x="565" y="550" rx="3" ry="3" width="30" height="20" />
            <rect x="0" y="590" rx="3" ry="3" width="195" height="200" />
            <rect x="200" y="590" rx="3" ry="3" width="195" height="200" />
            <rect x="400" y="590" rx="3" ry="3" width="195" height="200" />
            <rect x="400" y="810" rx="3" ry="3" width="195" height="40" />
            <rect x="0" y="880" rx="3" ry="3" width="140" height="140" />
            <rect x="150" y="880" rx="3" ry="3" width="140" height="140" />
            <rect x="300" y="880" rx="3" ry="3" width="140" height="140" />
            <rect x="460" y="880" rx="3" ry="3" width="140" height="140" />

        </ContentLoader>
    );
}

export default L_PG4ReportSummary;
