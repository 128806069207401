import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import AdminPatientLogTable from '../../../components/Admin/GDPR/AdminPatientLogTable';
import PracticePatientLogTable from '../../../components/Practice/GDPR/PracticePatientLogTable'
import { getGDPRPatientListForAdmin } from '../../../store/actions/adminGDPR';

function AdminPatientGDPRList() {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [totalData, setTotalData] = useState(0)
  const [data, setData] = useState([])
  const dispath = useDispatch()
  const token = useSelector(state => state.auth.token)
  const getGDPRPatientListForAdminFn = (url) => dispath(getGDPRPatientListForAdmin(token, url))
  const navigate = useNavigate();
  // useEffect(() => {

  // }, [])

  const updatePatientTable = (url) => {
    setLoading(true)
    getGDPRPatientListForAdminFn(url).then(response => {
      setLoading(false)
      if (!response.error) {
        setData(response.payload.gdpr);
        setTotalData(response.payload.total)
        // setpatientData([]);
        // setTotalData(response.payload.total)
      } else setError(true);
    });
  };



  return (
    <div>
      <div className="container-xl py-5 px-md-2 px-3">
        {/* <div className="mt-3 my-2 pd-pg-title ">Dashboard</div> */}
        <div className="container-xl mt-5 px-0">
          <div
            className="cursor-pointer textc-primary pg-link"
            onClick={() =>navigate(-1)}
          >
            <i className="fas fa-chevron-left mr-2"></i> {" "}
            Dashboard
          </div>

          <AdminPatientLogTable
            patient={data}
            totalData={totalData}
            loading={loading}
            error={error}
            updatePatientTable={updatePatientTable}
          />
        </div></div>
    </div>
  )
}

export default AdminPatientGDPRList;