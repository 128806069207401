import React, { useState, useEffect } from "react";
import { Accordion } from "react-bootstrap";

function PgAccordion({ question, answer, children, userType = "User" }) {
  const [show, setShow] = useState(false);

  return (
    <div
      className="my-3 pb-2"
      style={{ borderBottom: "1px solid rgba(0,0,0,0.2)" }}
    >
      <Accordion>
        <Accordion.Toggle eventKey="0" className="br cp" as={div}>
          <div
            className="d-flex justify-content-between w-100 cp"
            onClick={() => setShow(!show)}
          >
            <div
              className={`pgs-text fw-bold textc-primary${userType === "User" ? "" : "__p"
                }`}
              dangerouslySetInnerHTML={{ __html: question }}
            />
            <div className="my-auto">
              {" "}
              <i
                className={`fas fa-chevron-down transition-all my-auto ms-5 mx-2 textc-primary${userType === "User" ? "" : "__p"
                  }`}
                style={{
                  transform: show ? "rotate(0deg)" : "rotate(-90deg)",
                }}
              ></i>{" "}
            </div>
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <div className="pgs-text my-2">{answer || children}</div>
        </Accordion.Collapse>
      </Accordion>
    </div>
  );
}

export default PgAccordion;
const div = ({ children, onClick }) => <div onClick={onClick}>{children}</div>;