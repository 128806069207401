import { createAction } from "redux-api-middleware";

export const INVITATION_REQUEST = "INVITATION_REQUEST";
export const INVITATION_SUCCESS = "INVITATION_SUCCESS";
export const INVITATION_FAILURE = "INVITATION_FAILURE";

export const USER_TOKEN_REQUEST = "USER_TOKEN_REQUEST";
export const USER_TOKEN_SUCCESS = "USER_TOKEN_SUCCESS";
export const USER_TOKEN_FAILURE = "USER_TOKEN_FAILURE";

export const updatePassword = (data, url) => {

    const protocol = process.env.REACT_APP_PROTOCOL;
    const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_DOMAIN;
    return createAction({
        endpoint: `${PG3_BASE_URL}${url}`,
        body: JSON.stringify(data),
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        types: [
            INVITATION_REQUEST,
            {
                type: INVITATION_SUCCESS, payload: (action, state, res) => {
                    const authToken = res.headers.get('Authorization');
                    return res.json().then(json => { return { ...json, token: authToken } });
                }
            },
            INVITATION_FAILURE
        ],
    });
};

export const checkPg2UserToken = (data) => {

    const protocol = process.env.REACT_APP_PROTOCOL;
    const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_DOMAIN;
    return createAction({
        endpoint: PG3_BASE_URL + `/checkPg2UserToken`,
        body: JSON.stringify(data),
        method: "POST",
        headers: { "Content-Type": "application/json" },
        types: [
            USER_TOKEN_REQUEST,

            USER_TOKEN_SUCCESS,
            USER_TOKEN_FAILURE
        ],
    });
};
