import React, { Component, useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import toastr from "react-hot-toast";
import _ from "lodash";

import PgSelect from "../../../components/PgSelect";
import TextInput from "../../../components/TextInput";
import { validateEmail } from "../../../methods";
import {
  addPractitioner,
  getPractitionerTypes,
} from "../../../store/actions/adminPractitioner";
import { PgRadioButton } from "../../../components/PgRadioButton";
import { getCountries } from "../../../store/actions/adminUser";
import { licenseNotMandatoryTypes, licenseNotNeededTypes, stateList, caStateList } from "../../../constant";
import { useTranslation } from "react-i18next";

function AddPractitioner({ showModal, onCancel, updatePatientTable, context, selectedRegion }) {
  const { t, i18n } = useTranslation();

  // redux state variable
  const token = useSelector(state => state.auth.token);
  const practitionersTypeList = useSelector(state => state.adminPractitioner.practitioner_types);
  const regions = useSelector(state => state.auth.region)

  const dispatch = useDispatch();

  // redux actions
  const addPractitionerAction = (token, data) => dispatch(addPractitioner(token, data));
  const getPractitionerTypesAction = (token) => dispatch(getPractitionerTypes(token));


  const [countryList, setCountryList] = useState([]);

  const [isValidForm, setIsValidForm] = useState(false);
  const [showState, setShowState] = useState(true);
  //form fields
  const [fName, setfName] = useState("");
  const [lName, setlName] = useState("");
  const [email, setEmail] = useState("");
  const [showHealthcareProviderType, setShowHealthcareProviderType] = useState(true);
  const [healthcareProviderType, setHealthcareProviderType] = useState("");
  const [otherHealthcareProviderType, setOtherHealthcareProviderType] =
    useState("");
  const [license, setLicense] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [userState, setUserState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [phone, setPhone] = useState("");
  const [fax, setFax] = useState("");
  const [enableECommerce, setEnableECommerce] = useState(false);
  const [practiceName, setPracticeName] = useState("");
  const [pureAccountNumber, setPureAccountNumber] = useState("")
  const [accountType, setAccountType] = useState("");

  //for validation
  const [fNameError, setfNameError] = useState(false);
  const [lNameError, setlNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [healthcareProviderTypeError, setHealthcareProviderTypeError] =
    useState("");
  const [
    otherHealthcareProviderTypeError,
    setOtherHealthcareProviderTypeError,
  ] = useState("");
  const [licenseError, setLicenseError] = useState(false);
  const [address1Error, setAddress1Error] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [zipcodeError, setZipcodeError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [disableLicense, setDisableLicense] = useState(false);
  const [requireLicense, setRequireLicense] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accountTypeError, setAccountTypeError] = useState('');

  const [practititionerTypesList, setPractititionerTypesList] = useState([]);
  const [usPractititionerTypesList, setUsPractititionerTypesList] = useState([]);
  const [ukPractititionerTypesList, setUkPractititionerTypesList] = useState([]);
  const [caPractititionerTypesList, setCaPractititionerTypesList] = useState([]);
  const [practitionerTypeId, setPractitionerTypeId] = useState("");
  const [accountTypeList, setAccountTypeList] = useState([]);

  useEffect(() => {
    getPractitionerTypesAction(token).then((response) => {
      if (!response.error) {
        const practitionerTypes = response.payload.practitioner_types;
        const accountTypes = response.payload.practice_account_types;
        setAccountTypeList(accountTypes)
        setUkPractititionerTypesList(_.filter(practitionerTypes, ['regions', ["UK/EU"]]).map((a) => a.practitioner_type))
        setUsPractititionerTypesList(_.filter(practitionerTypes, ['regions', ["US"]]).map((a) => a.practitioner_type))
        setCaPractititionerTypesList(_.filter(practitionerTypes, ['regions', ["CA"]]).map((a) => a.practitioner_type))

        if (regions.includes("UK/EU") || (context === "practitioner-list" && selectedRegion !== "US" && selectedRegion !== "CA")) {
          loadRegionPractitionerTypes(practitionerTypes, ["UK/EU"]);
        } else if (regions.includes("CA") || (context === "practitioner-list" && selectedRegion === "CA")) {
          loadRegionPractitionerTypes(practitionerTypes, ["CA"]);
        } else if (context !== "practitioner-list") {
          if (regions.length > 1) {
            setPractititionerTypesList(practitionerTypes.map(a => a.practitioner_type));
          } else {
            const region = regions[0];
            loadRegionPractitionerTypes(practitionerTypes, region === "US" ? ["US"] : region === "CA" ? ["CA"] : ["UK/EU"]);
          }
        } else {
          loadRegionPractitionerTypes(practitionerTypes, ["US"]);
        }

        if (context === "practitioner-list") {
          setShowHealthcareProviderType(true)
        }
        else {
          if (regions.length > 1) setShowHealthcareProviderType(false)
        }

      }
    });
    dispatch(getCountries(token)).then(response => {
      if (!response.error) {
        var availableCountries = response.payload?.countries        
        if ((regions.includes("UK/EU")) || (context === "practitioner-list" && selectedRegion !== "US" && selectedRegion !== "CA")) {
          setCountry("");
          _.remove(availableCountries, function (n) {
            return n === "United States";
          });
          _.remove(availableCountries, function (n) {
            return n === "Canada";
          });
        } else if ((regions.includes("CA")) || (context === "practitioner-list" && selectedRegion === "CA")){
          setCountry("");
          setCountry("Canada");
        } else if (context !== "practitioner-list") {
          setCountry("");
          if (regions.length > 1) {
            setShowState(false);
          } else {
            const region = regions[0];
            if (region === "US") {
              setCountry("United States");
            } else if (region === "CA") {
              setCountry("Canada");
            }
          }
        } else {
          setCountry("United States");
        }
        setCountryList(availableCountries);
      }
    });
  }, []);

  const loadRegionPractitionerTypes = (types, region) => {
    setPractititionerTypesList(
      _.filter(types, ['regions', region]).map((a) => a.practitioner_type)
    );
  }

  useEffect(() => {
    if (country !== "") {
      setUserState("")
      setShowState(true)
      if (context !== "practitioner-list") {
        setHealthcareProviderType("")
        setOtherHealthcareProviderType("")
      }
      if (country === "United States") {
        setPractititionerTypesList(usPractititionerTypesList)
      } else if (country === "Canada"){
        setPractititionerTypesList(caPractititionerTypesList)
      }
      else {
        setPractititionerTypesList(ukPractititionerTypesList)
      }
      setShowHealthcareProviderType(true)
    }
  }, [country]);

  const onDone = () => {
    setfNameError(false);
    setlNameError(false);
    setEmailError(false);
    setHealthcareProviderTypeError(false);
    setLicenseError(false);
    setAddress1Error(false);
    setCityError(false);
    setStateError(false);
    setZipcodeError(false);
    setPhoneError(false);
    setCountryError(false)
    setOtherHealthcareProviderTypeError(false)
    setAccountTypeError(false);

    if (email.length === 0 || !validateEmail(email)) setEmailError(true);
    if (fName.length === 0) setfNameError(true);
    if (lName.length === 0) setlNameError(true);
    if (healthcareProviderType.length === 0)
      setHealthcareProviderTypeError("Health Care Provider is Required");
    if (address1.length === 0) setAddress1Error(true);
    if (city.length === 0) setCityError(true);
    if (country.length === 0) setCountryError("Country is Required");
    if (userState.length === 0 && (regions.includes("US") || selectedRegion === "US" || country === "United States" )) setStateError("State is Required");
    if (userState.length === 0 && (regions.includes("CA") || selectedRegion === "CA" || country === "Canada" )) setStateError("Province/Territory is Required");
    if (zipcode.length === 0) setZipcodeError(true);
    if (phone.length === 0) setPhoneError(true);
    if (
      healthcareProviderType.length !== 0 &&
      otherHealthcareProviderType.length === 0
    )
      setOtherHealthcareProviderTypeError(true);
    if (requireLicense) if (license.length === 0) setLicenseError(true);
    if (accountType.length === 0) setAccountTypeError('Account Type is Required ');
    if (
      healthcareProviderType === "Other"
        ? otherHealthcareProviderType !== ""
        : true &&
        email.length !== 0 &&
        validateEmail(email) &&
        fName.length !== 0 &&
        lName.length !== 0 &&
        healthcareProviderType.length !== 0 &&
        (requireLicense ? license.length !== 0 : true) &&
        address1.length !== 0 &&
        city.length !== 0 &&
        country.length !== 0 &&
        (userState.length !== 0 || !(regions.includes("US") || selectedRegion === "US" || country === "United States" || regions.includes("CA") || selectedRegion === "CA" || country === "Canada")) &&
        zipcode.length !== 0 &&
        phone.length !== 0 && accountType.length !== 0
    ) {
      setIsValidForm(true);
    }
  };

  useEffect(() => {
    if (isValidForm) {
      handleDone();
    }
  }, [isValidForm]);

  const handleDone = async () => {
    let data = {
      user: {
        first_name: _.startCase(fName),
        last_name: lName,
        full_name: `${_.startCase(fName)} ${lName}`,
        email: email.toLowerCase(),
        healthcare_provider: healthcareProviderType,
        other_healthcare_provider: otherHealthcareProviderType,
        license: license,
        address_1: address1,
        address_2: address2,
        address_3: address3,
        city: city,
        country: country,
        state: userState,
        zipcode: zipcode,
        phone_number: phone,
        fax: fax,
        practitioner_type_id: practitionerTypeId,
      },
      practice_attributes: [{
        name: practiceName,
        account_type: accountType,
        rialto_configuration_attributes: {
          enable_e_commerce: false,
          pure_account_number: pureAccountNumber
        }
      }]
    };
    setLoading(true);
    const response = await addPractitionerAction(token, data);
    setLoading(false);
    if (response.error) {
      //toastr.error("Email already exists in system. Please choose another");
      setIsValidForm(false);
    } else {
      if (context === "practitioner-list") updatePatientTable();
      onCancel();
      toastr.success("New Practitioner Created");
    }
  };

  const getPractitionerTypeId = (type) => {
    const practitionerType = _.find(practitionersTypeList, [
      "practitioner_type",
      type,
    ]);
    setPractitionerTypeId(practitionerType.id);
  };

  const updateProviderType = (option) => {
    getPractitionerTypeId(option);
    setHealthcareProviderType(option);
    if (licenseNotNeededTypes.includes(option)) {
      setRequireLicense(false);
      setDisableLicense(true);
    } else if (licenseNotMandatoryTypes.includes(option)) {
      setRequireLicense(false);
      setDisableLicense(false);
    } else {
      setRequireLicense(true);
      setDisableLicense(false);
    }
  };

  const updateAccountType = (option) => {
    setAccountType(option);
  };

  return (
    <div>
      <Modal
        show={showModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={onCancel}
        contentClassName="border-radius"
        backdrop="static"
        className="add-practitioner-modal"
      >
        <Modal.Body>
          <div
            style={{
              position: "absolute",
              right: 25,
              top: 15,
              fontSize: 20,
              cursor: "pointer",
              color: "var(--pg_primary)",
            }}
            onClick={onCancel}
          >
            <div className="fas fa-times"></div>
          </div>
          <div className="container">
            <div className="text-center pg-title">Add Practitioner</div>
            <div className="row">
              <div className="col-12">
                <TextInput
                  label="First Name"
                  showLabel={true}
                  required={true}
                  id="f-name__addpractitioner"
                  placeholder="Enter Practitioner First Name"
                  value={fName}
                  error={fNameError}
                  type="text"
                  errorMessage="First Name is required"
                  inputHandler={(e) => {
                    setfName(e.target.value);
                  }}
                />

                <TextInput
                  label="Last Name"
                  showLabel={true}
                  required={true}
                  id="l-name__addpractitioner"
                  placeholder="Enter Practitioner Last Name"
                  value={lName}
                  error={lNameError}
                  errorMessage="Last Name is required"
                  inputHandler={(e) => {
                    setlName(e.target.value);
                  }}
                />

                <TextInput
                  label="Email"
                  showLabel={true}
                  required={true}
                  emailField={true}
                  id="email-input__addpractitioner"
                  placeholder="Enter Practitioner Email"
                  value={email}
                  error={emailError}
                  errorMessage="Enter valid Email"
                  inputHandler={(e) => {
                    if (
                      e.target.value.length < 0 &&
                      !validateEmail(e.target.value)
                    ) {
                      setEmailError(true);
                    } else {
                      setEmailError(false);
                      setEmail(e.target.value);
                    }
                  }}
                />
                <PgSelect
                  label="Account Type"
                  showLabel={true}
                  required={true}
                  id="account_type__addpractitioner"
                  setSelectedItem={updateAccountType}
                  selectedItem={accountType}
                  placeholder="Enter Account Type"
                  options={accountTypeList}
                  errorMessage="Account Type is required"
                  error={
                    accountType !== ""
                      ? ""
                      : accountTypeError
                  }
                />
                <TextInput
                  label="Atrium Account ID"
                  showLabel={true}
                  optional={true}
                  id="puraccountnumber__addpractitioner"
                  placeholder="Enter Atrium Account ID"
                  value={pureAccountNumber}
                  isDisable={true}
                  inputHandler={(e) => {
                    setPureAccountNumber(e.target.value);
                  }}
                />
                {                  
                  (regions.includes("US") || selectedRegion === "US" || regions.includes("CA") || selectedRegion === "CA") ?
                    <TextInput
                      label="Country"
                      showLabel={true}
                      id="l-country__addpractitioner"
                      placeholder="Enter Country"
                      value={(regions.includes("US") || selectedRegion === "US") ? "United States" : "Canada"}
                      isDisable={true}
                      error={countryError}
                      inputHandler={(e) => {
                        setCountry(e.target.value);
                      }}
                    />
                    :
                    <PgSelect
                      label="Country"
                      showLabel={true}
                      required={true}
                      optional={false}
                      id="country__addpatient"
                      setSelectedItem={setCountry}
                      selectedItem={country}
                      placeholder="Enter Practitioner Country"
                      options={countryList}
                      error={countryError}
                    />
                }
                {showState ?
                  ((regions.includes("US") || selectedRegion === "US" || country === "United States" || regions.includes("CA") || selectedRegion === "CA" || country === "Canada") ?
                    <PgSelect
                      label={country === "Canada" ? "Province/Territory" : "State"}
                      showLabel={true}
                      required={true}
                      id="state__addpractitioner"
                      setSelectedItem={setUserState}
                      selectedItem={userState}
                      placeholder={country === "Canada" ? "Select Province/Territory" : "Enter State"}
                      options={country === "Canada" ? caStateList : stateList}
                      error={userState !== "" ? "" : stateError}
                    />
                    :
                    <TextInput
                      label="County"
                      showLabel={true}
                      optional={true}
                      id="state__addpractitioner"
                      placeholder="Enter County"
                      value={userState}
                      inputHandler={(e) => {
                        setUserState(e.target.value);
                      }}
                    />)
                  :
                  ""
                }

                {
                  showHealthcareProviderType &&
                  <PgSelect
                    label="Healthcare Provider Type"
                    showLabel={true}
                    required={true}
                    id="providerType__addpractitioner"
                    setSelectedItem={updateProviderType}
                    selectedItem={healthcareProviderType}
                    placeholder="Enter Healthcare Provider Type"
                    options={practititionerTypesList}
                    error={
                      healthcareProviderType !== ""
                        ? ""
                        : healthcareProviderTypeError
                    }
                  />
                }
                {healthcareProviderType === "Other" && (
                  <TextInput
                    label="Other Healthcare Provider Type"
                    showLabel={true}
                    required={healthcareProviderType === "Other"}
                    id="other-type__addpractitioner"
                    placeholder="Enter Other Healthcare Provider Type"
                    value={otherHealthcareProviderType}
                    error={otherHealthcareProviderTypeError}
                    errorMessage="Other Healthcare Provider Type is Required"
                    inputHandler={(e) => {
                      setOtherHealthcareProviderType(e.target.value);
                    }}
                  />
                )}

                <TextInput
                  label="License"
                  showLabel={true}
                  required={requireLicense}
                  optional={!requireLicense}
                  isDisable={disableLicense}
                  id="l-license__addpractitioner"
                  placeholder="Enter Practitioner License"
                  errorMessage="Practitioner License is Required"
                  value={license}
                  error={licenseError}
                  inputHandler={(e) => {
                    setLicense(e.target.value);
                  }}
                />

                <TextInput
                  label="Practice Name"
                  showLabel={true}
                  optional={true}
                  id="practice-name__addpractitioner"
                  placeholder="Enter Practice Name"
                  value={practiceName}
                  inputHandler={(e) => {
                    setPracticeName(e.target.value);
                  }}
                />

                <TextInput
                  label="Street Address 1"
                  showLabel={true}
                  required={true}
                  id="l-address1__addpractitioner"
                  placeholder="Enter Address Line 1"
                  errorMessage="Address Line 1 is Required"
                  value={address1}
                  error={address1Error}
                  inputHandler={(e) => {
                    setAddress1(e.target.value);
                  }}
                />
                <TextInput
                  label="Street Address 2"
                  showLabel={true}
                  optional={true}
                  id="l-address1__addpractitioner"
                  placeholder="Enter Address Line 2"
                  value={address2}
                  inputHandler={(e) => {
                    setAddress2(e.target.value);
                  }}
                />
                <TextInput
                  label="Street Address 3"
                  showLabel={true}
                  optional={true}
                  id="l-address1__addpractitioner"
                  placeholder="Enter Address Line 3"
                  value={address3}
                  inputHandler={(e) => {
                    setAddress3(e.target.value);
                  }}
                />
                <TextInput
                  label="City"
                  showLabel={true}
                  required={true}
                  id="l-city__addpractitioner"
                  placeholder="Enter City"
                  errorMessage="City is Required"
                  value={city}
                  error={cityError}
                  inputHandler={(e) => {
                    setCity(e.target.value);
                  }}
                />
                <TextInput
                  label={country === "Canada" ? "Postal Code" : "Zipcode"}
                  showLabel={true}
                  required={true}
                  id="zipcode__addpractitioner"
                  placeholder={country === "Canada" ? "Enter Postal Code" : "Enter Zipcode"}
                  errorMessage={country === "Canada" ? "Postal Code is required" : "Zipcode is required"}
                  value={zipcode}
                  error={zipcodeError}
                  inputHandler={(e) => {
                    setZipcode(e.target.value);
                  }}
                />
                <TextInput
                  label="Phone Number"
                  showLabel={true}
                  required={true}
                  id="phone__addpractitioner"
                  placeholder="Enter Phone Number"
                  error={phoneError}
                  errorMessage="Phone Number is Required"
                  value={phone}
                  inputHandler={(e) => {
                    setPhone(e.target.value);
                  }}
                />
                <TextInput
                  label="Fax"
                  showLabel={true}
                  optional={true}
                  id="fax__addpractitioner"
                  placeholder="Enter Fax"
                  value={fax}
                  inputHandler={(e) => {
                    setFax(e.target.value);
                  }}
                />
                {/* <div className="d-inline-block pg-text__bold textc-primary">
                  Enable/Disabe E-Commerce
                </div>
                <div className="mx-4">
                  <PgRadioButton
                    name={"Enable"}
                    title={"Enable"}
                    checked={enableECommerce}
                    onCheck={() => {
                      setEnableECommerce(true);
                    }}
                  />
                  <PgRadioButton
                    name={"Disable"}
                    title={"Disable"}
                    checked={!enableECommerce}
                    onCheck={() => {
                      setEnableECommerce(false);
                    }}
                  />
                </div> */}
                <button
                  className="btn btn-primary text-light px-5 my-1 mx-1  w-100"
                  onClick={onDone}
                >
                  SAVE
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}


export default AddPractitioner;
