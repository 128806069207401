import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux';
import _ from "lodash";
import {
  getQuestionnaireProcessStatus,
  updateQuestionnaireProcess,
} from "../../store/actions/questions";
import AfterQuestionnaireConfirmed from "../../components/Patient/AfterQuestionnaireConfirmed";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";


function ThankYouQuestionnaire({ history, location }) {
  // redux state variable
  const currentPractice = useSelector(state => state.auth.currentPractice);
  const currentPracticeID = useSelector(state => state.auth.currentPracticeID);
  const currentUserId = useSelector(state => state.auth.currentUserId);
  const token = useSelector(state => state.auth.token);
  const recommReportId = useSelector(state => state.question.recomm_report_id);
  const questionnaireProcessStatus = useSelector(state => state.question.questionnaire_status);
  // redux actions
  const dispatch = useDispatch();
  const getQuestionnaireProcessStatusAction = (token, currentPracticeID, currentPractice, data) => dispatch(getQuestionnaireProcessStatus(token, currentPracticeID, currentPractice, data));
  const updateQuestionnaireProcessAction = (data) => dispatch(updateQuestionnaireProcess(data));

  //local state variables
  let intervalTracker;
  console.log(questionnaireProcessStatus, 'questionnaireProcessStatus')
  const navigate = useNavigate();

  useEffect(() => {
    if (recommReportId && !_.isUndefined(recommReportId)) {
      updateLoadingStatus();
    }
    return () => {
      clearInterval(intervalTracker);
    };
  }, [recommReportId]);

  const updateLoadingStatus = () => {
    _updateLoadingStatus();
    intervalTracker = setInterval(() => {
      console.log("Polling for questionnaire status..");
      _updateLoadingStatus();
    }, 5000);
  };

  const _updateLoadingStatus = () => {
    let data = {
      type: "questionnaire",
      recommendation_input_id: recommReportId,
    };
    getQuestionnaireProcessStatusAction(
      token,
      currentPracticeID,
      currentPractice,
      data
    ).then((response) => {
      console.log(response);
      if (response.payload.current_state === "Complete") {
        clearInterval(intervalTracker);
        navigate("/patient/dashboard")
      }
    });
  };

  const { t, i18n } = useTranslation();

  return (
    <div>
      <div className="container-fluid">
        <div className="container-xxll pt-1 pt-lg-5 px-lg-5 px-2">
          <div className="row mt-4 justify-content-center justify-content-lg-between">
            <div className="col-12 col-lg-6 order-lg-0 order-1">
              <hr className="my-5 d-block d-lg-none" />
              <div className="pg-title textc-primary__p"> {t('questionnaire')}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center my-5">
        <div className="col-12 col-lg-6 text-center">
          <div className="pg-heading text-color__p">{t('patient_thx_text1')}</div>
          <div className="pg-text__bold text-center my-4 text-color__p">
            <p>
              {t('patient_thx_text2')}
            </p>
            {recommReportId && <p>
              {t('patient_thx_text3')}
            </p>}
          </div>

          <div className="container-fluid px-0">
            {recommReportId && <AfterQuestionnaireConfirmed
              status={questionnaireProcessStatus}
              isInvited={true}
              error={""}
              errorText={""}
              onTryAgain={() => {
                updateQuestionnaireProcessAction({
                  recomm_report_id: recommReportId,
                  questionnaire_status: "Analyzing",
                })
              }}
              onCompleteQuest={() => navigate("/patient/dashboard")}
            />}
            <div className="col-12">

              <div className="pg-text__small text-center mt-5">
                <button className="btn btn-outline__p px-3" onClick={() =>
                  navigate("/patient/dashboard")
                }>{t('patient_thx_text4')}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYouQuestionnaire;
