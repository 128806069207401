import { useState, useEffect } from "react";
import L_SingleLine from "../LoadingEffectComponent/L_SingleLine";
import { Loading } from "../../components/Loading";
import toastr from "react-hot-toast";

const protocol = process.env.REACT_APP_PROTOCOL;
const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;
const PDFReportOptions = ({
  userType,
  token,
  currentPracticeID,
  currentPatientId,
  currentReportId,
  currentPractice,
  t,
}) => {
  const fetchAndPrintPDF = async (userType) => {
    let __wt = window.open("/loading");
    window.focus();
    fetch(
      `${protocol}//${currentPractice}${PG3_BASE_URL}/${userType}/reports/${currentReportId}/${currentPatientId}/pdf_view.pdf`,
      {
        headers: {
          "Content-Type": "application/pdf",
          Authorization: token,
          "Current-Type": userType,
          "Current-Practice": currentPracticeID,
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.blob();
        } else {
          __wt.close();
          toastr.error(t("pg4_report_txt_5"));
        }
      })
      .then((text) => {
        // creating a location for pdf file in memory
        var url = window.URL.createObjectURL(
          new Blob([text], { type: "application/pdf" })
        );
        if (__wt.document.location) {
          __wt.document.location.href = url;
          // setTimeout(() => __wt.document.title = "PureGenomics®", 2000);
        }
      })
      .catch((err) => {
        if (__wt) __wt.close();
        toastr.error(t("pg4_report_txt_5"));
        console.log(err, "err while loading PDF");
      });
  };
  return (
    <div>
      {userType === "User" && (
        <div className="pg-link" onClick={() => fetchAndPrintPDF("User")}>
          {t("pracrs_btn_1")}
        </div>
      )}
      <div className="pg-link" onClick={() => fetchAndPrintPDF("Patient")}>
        {userType === "User" ? t("pracrs_btn_2") : t("pracrs_btn_3")}
      </div>
    </div>
  );
};

export default PDFReportOptions;
