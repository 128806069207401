import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { getPatientListForCatalog } from "../../store/actions/patientList";
import { useNavigate } from "react-router-dom";
import TextInputWithIcon from "../../components/TextInputWithIcon";
import { PatientTableForCatalog } from "../../components/Practice/Catalog/PatientTableForCatalog";
import { updateRialtoState } from "../../store/actions/ui";

export const Catalog = ({ history, url }) => {

  // redux state variable
  const currentPracticeID = useSelector(state => state.auth.currentPracticeID);
  const currentPractice = useSelector(state => state.auth.currentPractice);
  const token = useSelector(state => state.auth.token);
  const patients = useSelector(state => state.patientList.patientListForCatalog);
  const loading = useSelector(state => state.patientList.isGettingPatientListForCatalog);
  const totalData = useSelector(state => state.patientList.totalCatalogData);
  const rilatoState = useSelector(state => state.ui.rialtoState);

  const dispatch = useDispatch();

  // redux actions
  const getPatientListForCatalogAction = (token, currentPracticeID, currentPractice, url) => dispatch(getPatientListForCatalog(token, currentPracticeID, currentPractice, url));
  const updateRialtoStateAction = (payload => dispatch(updateRialtoState(payload)));

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [selectedPatient, setSelectedPatient] = useState("");

  const updatePatientTable = (url) => {
    getPatientListForCatalogAction(
      token,
      currentPracticeID,
      currentPractice,
      url
    );
  };

  const redirectToCatalog = () => {
    const state = {
      path: `/tenants/${currentPracticeID}/patients/${selectedPatient}/catalog`,
      browseCatalog: true,
    };
    updateRialtoStateAction({
      ...rilatoState,
      ...state
    })
    navigate("/practice/supplement_catalog", { state });
  };

  return (
    <div className="container-fluid">
      <Helmet>
        <title>
          {t('puregenomics_1')}
        </title>
      </Helmet>
      <div className="container-xxl py-5 px-lg-0">
        <div
          className="cursor-pointer textc-primary pg-link"
          onClick={() => navigate("/practice/dashboard")}
        >
          <i className="fas fa-chevron-left mr-2"></i> {t('dashboard')}
        </div>
        <div className=" my-4 pg-title">{t('catalog_line_1')}</div>
        <div className="pg-text">{t('catalog_line_2')} </div>
        <div>
          <PatientTableForCatalog
            patient={patients}
            updatePatientTable={updatePatientTable}
            totalData={totalData}
            selectedPatient={selectedPatient}
            setSelectedPatient={setSelectedPatient}
            t={t}
          />
        </div>
      </div>
      <div className="bgc-secondary text-center py-3">
        <button
          className="btn btn-primary text-light px-5 py-2 w-25"
          disabled={!selectedPatient}
          onClick={redirectToCatalog}
        >
          {t('ok')}
        </button>
      </div>
    </div>
  );
};

export default Catalog;
