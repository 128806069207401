import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useForm, Controller, useWatch } from "react-hook-form";
import { useQuery, useMutation, useQueryClient } from "react-query";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import toastr from "react-hot-toast";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  getPatientDetails,
  editPatient,
  getPractitionersList,
  getCountries,
} from "../../methods/myaccount-apis";

import { fr, enGB, enUS } from "date-fns/locale";

import L_EditPractitioner from "../../components/LoadingEffectComponent/Admin/L_EditPractitioner";
import PgSelect from "../../components/common/PgSelect";
import TextInput from "../../components/common/TextInput";
import SetNewPasswordModal from "../NewPasswordModal";
import { validateEmail } from "../../methods";
import Cookies from "universal-cookie";
import useLangSpecificConst from "../../hooks/useLangSpecificConst";
import useLanguageConditions from "../../hooks/useLanguageConditions";
import { label_value } from "../../constant";
import { format } from "date-fns";
const cookies = new Cookies();

const years = Array.from(new Array(100), (val, index) => index + 1920);

registerLocale("fr", fr);
registerLocale("en", enUS);
registerLocale("enGB", enGB);

function Account() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { languageOptions, genderOptions, skinOptions } =
    useLangSpecificConst();

  const { isCA, isUK, isUS } = useLanguageConditions();

  const token = useSelector((state) => state.auth.token);
  const currentPracticeID = useSelector(
    (state) => state.auth.currentPracticeID
  );
  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const currentUserId = useSelector((state) => state.auth.currentUserId);
  const patientCountry = useSelector((state) => state.auth.country);
  const [showNewPasswordModal, setShowNewPasswordModal] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [practitionerList, setPractitionerList] = useState([]);
  const queryClient = useQueryClient();
  const currentLocale = i18n.language === "fr" ? fr : enUS;
  const months = Array.from({ length: 12 }, (_, i) =>
    format(new Date(0, i), "MMMM", { locale: currentLocale })
  );

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    watch,
  } = useForm();

  const {
    data: patientDetails,
    isLoading: isLoadingPatientDetails,
    refetch,
  } = useQuery(
    "patientDetails",
    () =>
      getPatientDetails({
        token,
        currentPracticeID,
        currentPractice,
        id: currentUserId,
      }),
    {
      onSuccess: (data) => {
        setValue("email", data.email || "");
        setValue("firstName", data.first_name || "");
        setValue("lastName", data.last_name || "");
        setValue("dob", data.dob ? new Date(data.dob) : null);
        setValue(
          "gender",
          _.find(genderOptions, {
            value: data.gender,
          }) || label_value
        );
        setValue(
          "skinColor",
          _.find(skinOptions, {
            value: data.skin_color,
          }) || label_value
        );
        setValue("country", data.country);
        setValue(
          "default_language",
          _.find(languageOptions, {
            value: data.default_language,
          }) || label_value
        );
        setValue("practitioner", {
          id: data.created_by,
          full_name: data.assigned_practitioner,
        });
      },
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  const { data: countriesData } = useQuery(
    "countriesList",
    async () => {
      const data = await getCountries({
        token,
        currentPracticeID,
        currentPractice,
      });
      if (watch("country") !== "US" || watch("country") !== "United States") {
        _.remove(data.countries, (n) => n === "United States");
      }
      if (watch("country") !== "CA" || watch("country") !== "Canada") {
        _.remove(data.countries, (n) => n === "Canada");
      }
      return data.countries;
    },
    {
      onSuccess: (data) => {
        console.log("countriesData", data);
        setCountryList(data);
      },
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  const { data: practitionersData } = useQuery(
    "practitionersList",
    () =>
      getPractitionersList({
        token,
        currentPracticeID,
        currentPractice,
      }),
    {
      onSuccess: (data) => {
        setPractitionerList(data.practitioners);
      },
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  const mutation = useMutation(
    (data) =>
      editPatient({
        token,
        currentPracticeID,
        currentPractice,
        id: currentUserId,
        data,
      }),
    {
      onSuccess: (pld) => {
        const langToSet = pld.language;
        console.log("account detail saved:", pld);
        i18n
          .changeLanguage(langToSet)
          .then(() => {
            refetch();
            cookies.set("i18next", langToSet);
            console.log(`Language changed to ${langToSet}`);
          })
          .catch((error) => {
            console.error(`Error changing language to ${langToSet}`, error);
          });
        toastr.success(t("patient_suc1"));
      },
      onError: () => {
        toastr.error(t("patient_err1"));
      },
    }
  );

  const onSubmit = (data) => {
    mutation.mutate({
      patient: {
        first_name: _.startCase(data.firstName),
        last_name: data.lastName,
        full_name: `${_.startCase(data.firstName)} ${data.lastName}`,
        email: data.email.toLowerCase(),
        dob: data.dob ? data.dob.toISOString() : null,
        gender: data.gender?.value,
        skin_color: data.skinColor?.value,
        country: data.country,
        default_language: data.default_language?.value,
      },
    });
  };

  const handleNewPasswordClick = (e) => {
    e.preventDefault();
    setShowNewPasswordModal(true);
  };

  return (
    <div className="container" id="edit-patient-modal">
      <Helmet>
        <title>{t("pat_account_h1")}</title>
      </Helmet>
      <div
        className="cursor-pointer textc-primary pg-link"
        onClick={() => navigate("/patient/dashboard")}
        style={{ float: "left", width: "100%" }}
      >
        <i className="fas fa-chevron-left mr-2"></i> {t("dashboard")}
      </div>
      <div className="text-center pg-title mt-5 text_primary">
        {t("patient_account_txt1")}
      </div>
      <div className="text-center pg-text__bold text_primary">
        {t("patient_account_txt2")}
      </div>
      {isLoadingPatientDetails ? (
        <L_EditPractitioner />
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="row p-5 justify-content-md-center account-form"
        >
          <div className="col-md-6">
            <TextInput
              label={t("prac_register_first_name")}
              showLabel={true}
              required={true}
              id="firstName"
              placeholder={t("pracand_fname_ph")}
              error={!!errors.firstName}
              errorMessage={t("pracand_fname_error")}
              {...register("firstName", { required: true })}
            />
            <TextInput
              label={t("pracand_lname")}
              showLabel={true}
              required={true}
              id="lastName"
              placeholder={t("pracand_lname_ph")}
              error={!!errors.lastName}
              errorMessage={t("pracand_lname_error")}
              {...register("lastName", { required: true })}
            />
            <TextInput
              label={t("pracand_email")}
              showLabel={true}
              required={true}
              emailField={true}
              id="email"
              placeholder={t("pracand_email_ph")}
              error={!!errors.email}
              errorMessage={t("prac_reg_email_req")}
              {...register("email", {
                required: true,
                validate: validateEmail,
              })}
            />
            <div className="mb-4 dateVal">
              <div>
                <div className="d-inline-block pg-text__bold text_primary">
                  {t("pracand_dob")}
                </div>
                <span className="text_primary fst-italic">
                  {" "}
                  ({t("required")})
                </span>
              </div>
              <Controller
                name="dob"
                control={control}
                defaultValue={null}
                rules={{
                  required: t("pracand_dob_error"),
                  validate: (value) =>
                    (value && !isNaN(value.getTime())) || "Invalid date",
                }}
                render={({ field }) => (
                  <div>
                    <ReactDatePicker
                      locale={currentLocale}
                      selected={field.value}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      onChange={(date) => {
                        if (date) {
                          const adjustedDate = new Date(date);
                          adjustedDate.setHours(12, 0, 0, 0);
                          field.onChange(adjustedDate);
                        }
                      }}
                      onChangeRaw={() => {
                        field.onChange("");
                      }}
                      renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                      }) => (
                        <div
                          style={{
                            margin: 10,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <select
                            value={date.getFullYear()}
                            onChange={({ target: { value } }) =>
                              changeYear(value)
                            }
                          >
                            {years.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          <select
                            value={months[date.getMonth()]}
                            onChange={({ target: { value } }) =>
                              changeMonth(months.indexOf(value))
                            }
                          >
                            {months.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                      dateFormat="MM/dd/yyyy"
                      placeholderText={t("pracand_dob_ph")}
                      className={`dateFld w-100`}
                    />
                    {errors.dob && (
                      <div
                        className="my-1 transition-all"
                        style={
                          errors.dob
                            ? { opacity: 1, color: "#f00" }
                            : { opacity: 0, maxHeight: "24px" }
                        }
                      >
                        <i class="fas fa-info-circle me-1"></i>{" "}
                        {errors.dob.message}
                      </div>
                    )}
                  </div>
                )}
              />
            </div>
            <PgSelect
              label={t("pracand_gender")}
              showLabel={true}
              required={true}
              id="gender"
              placeholder={t("pracand_gender_ph")}
              options={genderOptions}
              error={!!errors.gender}
              errorMessage={t("pracand_gender_error")}
              setSelectedItem={(value) => {
                console.log({ value });
                setValue("gender", value);
              }}
              selectedItem={watch("gender")}
              {...register("gender", {
                required: true,
                validate: (value) => value && value.value !== "",
              })}
            />
            <PgSelect
              label={t("pat_self_skin")}
              showLabel={true}
              required={true}
              id="skinColor"
              placeholder={t("pat_self_skin")}
              options={skinOptions}
              error={!!errors.skinColor}
              errorMessage={t("pracand_genetic_error")}
              setSelectedItem={(value) => setValue("skinColor", value)}
              selectedItem={watch("skinColor")}
              {...register("skinColor", {
                required: true,
                validate: (value) => value && value.value !== "",
              })}
            />
            {!(
              watch("country") === "US" ||
              watch("country") === "CA" ||
              watch("country") === "United States" ||
              watch("country") === "Canada"
            ) && (
              <Controller
                name="country"
                control={control}
                defaultValue=""
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <PgSelect
                    label={t("prac_register_country")}
                    showLabel={true}
                    required={true}
                    id="country"
                    placeholder={t("prac_reg_country")}
                    options={countryList} // Correct options list
                    error={!!errors.country}
                    errorMessage={t("pracand_country_error")}
                    setSelectedItem={field.onChange}
                    selectedItem={field.value}
                  />
                )}
              />
            )}
            {isCA && (
              <div className="">
                <Controller
                  name="default_language"
                  control={control}
                  rules={{
                    required: true,
                    validate: (value) => value && value?.value !== "",
                  }}
                  render={({ field }) => (
                    <PgSelect
                      label={t("language")}
                      showLabel={true}
                      required={false}
                      id="default_language"
                      placeholder={t("select_language")}
                      displayKey="label"
                      valueKey="value"
                      options={languageOptions}
                      error={!!errors.default_language}
                      errorMessage={t("pracand_genetic_error")}
                      setSelectedItem={(value) => {
                        field.onChange(value);
                      }}
                      selectedItem={field.value}
                    />
                  )}
                />
              </div>
            )}
            <TextInput
              label={t("practitioner")}
              showLabel={true}
              id="practitioner"
              isDisable={true}
              value={watch("practitioner.full_name")}
            />
            <button
              className="btn btn-outline px-1 my-3 w-100"
              onClick={handleNewPasswordClick}
            >
              {t("patient_account_txt3")}
            </button>
            {showNewPasswordModal && (
              <SetNewPasswordModal
                showModal={showNewPasswordModal}
                onCancel={() => setShowNewPasswordModal(false)}
                currentPractice={currentPractice}
                currentPracticeID={currentPracticeID}
                currentUserId={currentUserId}
                userType="Patient"
              />
            )}
            <button
              className="btn btn-primary text-light px-5 my-3 w-100"
              type="submit"
            >
              {t("patient_account_txt4")}
            </button>
          </div>
        </form>
      )}
    </div>
  );
}

export default Account;
