import { createAction, RSAA } from "redux-api-middleware";

const protocol = process.env.REACT_APP_PROTOCOL;
const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;

export const GDPR_PATIENT_REQUEST = "GDPR_PATIENT_REQUEST";
export const GDPR_PATIENT_SUCCESS = "GDPR_PATIENT_SUCCESS";
export const GDPR_PATIENT_FAILURE = "GDPR_PATIENT_FAILURE";

export const CHECK_FOR_AVQ_REQUEST = "CHECK_FOR_AVQ_REQUEST";
export const CHECK_FOR_AVQ_SUCCESS = "CHECK_FOR_AVQ_SUCCESS";
export const CHECK_FOR_AVQ_FAILURE = "CHECK_FOR_AVQ_FAILURE";

export const MSQ_REQUEST = "MSQ_REQUEST";
export const MSQ_SUCCESS = "MSQ_SUCCESS";
export const MSQ_FAILURE = "MSQ_FAILURE";

export const RETAKE_QUESTIONNAIRE_REQUEST = "RETAKE_QUESTIONNAIRE_REQUEST";
export const RETAKE_QUESTIONNAIRE_SUCCESS = "RETAKE_QUESTIONNAIRE_SUCCESS";
export const RETAKE_QUESTIONNAIRE_FAILURE = "RETAKE_QUESTIONNAIRE_FAILURE";

export const MSQ_ANSWER_REQUEST = "MSQ_ANSWER_REQUEST";
export const MSQ_ANSWER_SUCCESS = "MSQ_ANSWER_SUCCESS";
export const MSQ_ANSWER_FAILURE = "MSQ_ANSWER_FAILURE";

export const MSQ_ANSWER_SAVE_REQUEST = "MSQ_ANSWER_SAVE_REQUEST";
export const MSQ_ANSWER_SAVE_SUCCESS = "MSQ_ANSWER_SAVE_SUCCESS";
export const MSQ_ANSWER_SAVE_FAILURE = "MSQ_ANSWER_SAVE_FAILURE";

export const UPDATE_ANSWERS = "UPDATE_ANSWERS";
export const UPDATE_SECTIONS = "UPDATE_SECTIONS";
export const UPDATE_ERRORS = "UPDATE_ERRORS";

export const CLEAR_STATE = "CLEAR_STATE";
export const CLEAR_ANSWERS = "CLEAR_ANSWERS";


const Proxy = "https://cors.mdbgo.io/";
// const Proxy = "";
// const API = Proxy + "https://9288-113-193-25-170.ngrok-free.app";
const API = process.env.REACT_APP_QUESTIONNAIRE_DOMAIN

export const getPatientDashboard = ({ currentPractice, token }) => {
    return createAction({
        // endpoint: `https://dev-azesytopghwzplj.api.raw-labs.com/patient/pg4/dashboard`,
        endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/patient/patient_dashboard_data`,
        method: "GET",
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
        types: [
            GDPR_PATIENT_REQUEST,
            GDPR_PATIENT_SUCCESS,
            GDPR_PATIENT_FAILURE,
        ],
    });
};



export const getMSQSessionDetails = ({ patientID, sessionNO }) => {

    return createAction({
        endpoint: `${API}/v1/patient/${patientID}/questionnaire/${sessionNO}`,
        method: "GET",
        headers: {
            // Authorization: token,
            "Content-Type": "application/json",
        },
        types: [
            GDPR_PATIENT_REQUEST,
            GDPR_PATIENT_SUCCESS,
            GDPR_PATIENT_FAILURE,
        ],
    });
};

export const getMSQQuestions = ({ patientID, sessionNO, questionnaireID, userGender, userCountry }) => {
    return createAction({
        endpoint: `${API}/v1/patient/${patientID}/questionnaire/${questionnaireID}/session/${sessionNO}?country=${userCountry}&${userGender ? 'gender=' + userGender : ''}`,
        method: "GET",
        headers: {
            // Authorization: token,
            "Content-Type": "application/json",
        },
        types: [
            MSQ_REQUEST,
            MSQ_SUCCESS,
            MSQ_FAILURE,
        ],
    });
};


export const postMSQAnswers = (data) => {
    const body = JSON.stringify(data);
    return createAction({
        // endpoint: `${API}/v1/patient/${patientID}/questionnaire/${questionnaireID}/session/${sessionNO}`,
        endpoint: `${API}/v1/patient/${data?.patient_id}/questionnaire/${data?.questionnaire_id}/session/${data?.questionnaire_session_id}/`,
        method: "POST",
        body,
        headers: {
            "Content-Type": "application/json"
        },
        types: [
            MSQ_ANSWER_SAVE_REQUEST,
            MSQ_ANSWER_SAVE_SUCCESS,
            MSQ_ANSWER_SAVE_FAILURE,
        ],
    });
};

export const getMSQAnswers = ({ patientID, sessionNO }) => {
    return createAction({
        endpoint: `${API}/v1/patient/${patientID}/questionnaire_session_data/${sessionNO}`,
        method: "GET",
        headers: {
            // Authorization: token,
            "Content-Type": "application/json"
        },
        types: [
            MSQ_ANSWER_REQUEST,
            MSQ_ANSWER_SUCCESS,
            MSQ_ANSWER_FAILURE,
        ],
    });
};

export const completeQHook = ({ session_id, token, currentPractice }) => {
    const body = JSON.stringify({
        session_id
    });
    return createAction({
        endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/complete_questionnaire`,
        method: "POST",
        body,
        headers: {
            Authorization: token,
            "Content-Type": "application/json"
        },
        types: [
            MSQ_ANSWER_REQUEST,
            MSQ_ANSWER_SUCCESS,
            MSQ_ANSWER_FAILURE,
        ],
    });

}

export const questionnaireStarted = ({ token, patient_id, currentPractice, session_id }) => {
    return createAction({
        endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/update_session_to_inprogress`,
        body: JSON.stringify({
            session_id
        }),
        method: "POST",
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
        types: [
            RETAKE_QUESTIONNAIRE_REQUEST,
            RETAKE_QUESTIONNAIRE_SUCCESS,
            RETAKE_QUESTIONNAIRE_FAILURE,
        ],
    });
};

export const retakeQuestionnaire = ({ token, patient_id, currentPractice, questionnaire_id }) => {
    return createAction({
        endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/retake_questionnaire?patient_id=${patient_id}&questionnaire_id=${questionnaire_id}`,
        method: "GET",
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
        },
        types: [
            RETAKE_QUESTIONNAIRE_REQUEST,
            RETAKE_QUESTIONNAIRE_SUCCESS,
            RETAKE_QUESTIONNAIRE_FAILURE,
        ],
    });
};




export const getAVQRecommendation = ({ patientID, sessionNO }) => {
    return createAction({
        endpoint: `${API}/v1/patient/${patientID}/questionnaire/${sessionNO}`,
        method: "GET",
        headers: {
            // Authorization: token,
            "Content-Type": "application/json"
        },
        types: [
            CHECK_FOR_AVQ_REQUEST,
            CHECK_FOR_AVQ_SUCCESS,
            CHECK_FOR_AVQ_FAILURE,
        ],
    });
};

export const updateAnswers = (payload) => {
    return {
        type: UPDATE_ANSWERS,
        payload
    }
}

export const updateErrors = (payload) => {
    return {
        type: UPDATE_ERRORS,
        payload
    }
}

export const updateSections = (payload) => {
    return {
        type: UPDATE_SECTIONS,
        payload
    }
}

export const clearMSQState = (payload) => {
    return {
        type: CLEAR_STATE,
        payload
    }
}

export const clearAnswers = (payload) => {
    return {
        type: CLEAR_ANSWERS,
        payload
    }
}