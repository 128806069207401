import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
function DeleteConfirnationModal({ message, onAccept, onCancel, showModal }) {
    const { t, i18n } = useTranslation();
    return (
        <Modal
            show={showModal}
            size="md"
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={onCancel}
            contentClassName="border-radius"
        >
            <Modal.Body>
                <div className="pg-text">
                    <div className="pg-heading">{t("txt_remove")}</div>
                    <div className="d-flex align-items-center fw-bold">{message}</div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex justify-content-between">
                    <div>
                        <button className="btn btn-sm btn-outline text-light px-3 mx-1" onClick={onCancel}>
                            {t("no")}
                        </button>
                    </div>
                    <div>
                        <button className="btn btn-sm bgc-primary text-light px-3" onClick={onAccept}>
                            {t("yes")}
                        </button>
                    </div>

                </div>
            </Modal.Footer>
        </Modal>
    );
}
export default DeleteConfirnationModal;
