import { useEffect, useState } from "react";
import { ColorsCircle } from "../ColorsCircle";
import { motion } from "framer-motion";

export const SupplementRecommendationItems = ({
  item,
  focusAreas,
  data,
  isEditing,
  selectedItem,
  onSelect,
}) => {
  const onSelectionChange = (id) => {
    console.log({ id });
    if (selectedItem.includes(id)) {
      onSelect(selectedItem.filter((item) => item !== id));
    } else {
      onSelect((os) => {
        return [...os, id];
      });
    }
  };

  return (
    <li
      id={item.recommendation_id}
      className="d-flex justify-content-between align-items-center rounded"
    >
      <label
        htmlFor={`checkbox-${data.recommendation_id}`}
        className="w-100 d-flex justify-content-between align-items-center"
      >
        {isEditing && (
          <motion.div
            className="mx-1"
            initial={{ scaleY: 0 }}
            animate={{ scaleY: isEditing ? 1 : 0 }}
            transition={{ duration: 0.1, type: "easeIn", delay: 0.2 }}
          >
            <input
              type="checkbox"
              id={`checkbox-${data.recommendation_id}`}
              defaultChecked={selectedItem.includes(data.recommendation_id)}
              onChange={() => onSelectionChange(data.recommendation_id)}
              aria-checked={selectedItem.includes(data.recommendation_id)}
              className="pg-checkbox"
            />
          </motion.div>
        )}
        <div className="rounded p-1 p-lg-2 bg_tertiary my-2 user-select-none d-flex justify-content-between align-items-center w-100">
          <div>
            <div>
              <ColorsCircle
                focusAreas={focusAreas}
                data={data.associated_focus_areas_key}
              />
            </div>
            <div>
              <b dangerouslySetInnerHTML={{ __html: item?.supplement_name }} />
              <p dangerouslySetInnerHTML={{ __html: item?.desc }} />
            </div>
          </div>
        </div>
      </label>
    </li>
  );
};
