import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { PgProgressBar } from "../../components/Patient/PgProgressBar";
import { parse } from "query-string";
import toaster from "react-hot-toast";
import { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  getGeneticUploadStatus,
  getMSQRecommendationStatus,
} from "../../store/actions/genetics";
import { ProgressBar } from "react-bootstrap";

const MSQStatus = () => {
  // const _status = "uploading";
  const error = false;
  const { t } = useTranslation();
  const go = useNavigate();
  const dispatch = useDispatch();

  const url = parse(window.location.search);
  const currentPracticeID = useSelector(
    (state) => state.auth.currentPracticeID
  );
  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const token = useSelector((state) => state.auth.token);
  const getMSQRecommendationStatusAction = (data) =>
    dispatch(
      getMSQRecommendationStatus(
        token,
        currentPracticeID,
        currentPractice,
        data
      )
    );
  const intervalController = useRef(null);
  console.log({ url });
  const [_status, setStatus] = useState("uploading");

  useEffect(() => {
    if (!_.isNumber(parseInt(url?.report_id))) {
      toaster.error("Invlid URL!");
      go("/patient/dashboard", { replace: true });
    } else {
      pollMSQRecommendationAPI(url?.report_id);
    }
    return () => clearInterval(intervalController.current);
  }, []);

  const pollMSQRecommendationAPI = (report_id) => {
    // console.log({ report_id })
    intervalController.current = setInterval(async () => {
      const response = await getMSQRecommendationStatusAction({
        type: "pg4_recommendation",
        id: report_id,
      });
      if (!response.error) {
        if (!response.payload.success) {
          clearInterval(intervalController.current);
          toaster.error(
            "Something went wrong! \n\n Please retake questionnaire."
          );
          go("/patient/dashboard", { replace: true });
        }
        if (response.payload.current_state === "Complete") {
          clearInterval(intervalController.current);
          toaster.success(t("msq_res_2"));
          setTimeout(() => {
            go("/patient/dashboard", { replace: true });
          }, 1000);
        }
        setStatus(response?.payload?.current_state?.toLowerCase());
      } else {
        clearInterval(intervalController.current);
        toaster.error(t("msq_alert_1"));
        go("/patient/dashboard", { replace: true });
      }
    }, 2000);
  };

  return (
    <div className="container py-5">
      <>
        <div className="d-flex justify-content-between my-2 items-center  flex-wrap">
          <Link className="pg4-btn pg4-btn_link" to={"/practice/patients"}>
            {" "}
            <i className="fas fa-chevron-left me-2"></i>
            {t("patient_thx_text4")}
          </Link>
        </div>
        {/* <div className="pg4-text__title text_primary my-4">
                    MSQ Recommendation
                </div>
                <hr /> */}
        <div className="py-5">
          <div className="text-center text_primary mb-5">
            <div
              className="pg4-text__title"
              dangerouslySetInnerHTML={{ __html: t("pg4_msq_status_title_1") }}
            />
            <div
              className="pg4-text my-3 fw-bold"
              dangerouslySetInnerHTML={{
                __html: t("pg4_msq_status_sub_header_1"),
              }}
            />
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8 border progress-status-wrapper">
              <div className="row pg-heading__sub text-center textc-primary progress-status-title">
                <div
                  className="col-6 border py-2"
                  style={{
                    background: _status !== "complete" ? "#2470C2" : "#e9f1f9",
                    color: _status !== "complete" ? "#fff" : "#2470C2",
                  }}
                >
                  {t("pg4_msq_status_view_status_1")}
                </div>
                <div
                  className="col-6 border py-2"
                  style={{
                    background: _status === "complete" ? "#2470C2" : "#fff",
                    color: _status === "complete" ? "#fff" : "#2470C2",
                  }}
                >
                  {t("pg4_msq_status_view_status_2")}
                </div>
              </div>
              {error ? (
                <div className="text-center">
                  <div className="pg-text__bold text-center text-color__p my-lg-4 my-1 mt-lg-5">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t("patient_afstart_txt5"),
                      }}
                    />
                    <div className="my-5" />
                  </div>
                  {/* <button className="btn btn-primary__p w-50 mt-lg-4 mb-5" onClick={onTryAgain}>{t('try_again')}</button> */}
                  <span
                    dangerouslySetInnerHTML={{ __html: t("support_line") }}
                  />
                </div>
              ) : (
                <div className="row p-3">
                  {/* <div className="pg-text__bold my-2"> */}
                  {/* Your recommendation is processing */}
                  {/* </div> */}
                  <div className="col-12 my-4">
                    <div className="">
                      <div className="mt-2 mt-lg-2">
                        <ProgressBar
                          animated
                          variant="progress_bar__patient"
                          now={
                            _status === "uploading"
                              ? 10
                              : _status === "importing"
                              ? 50
                              : _status === "analyzing"
                              ? 75
                              : _status === "complete"
                              ? 100
                              : 100
                          }
                          style={{ height: 30, borderRadius: "0px" }}
                        />
                      </div>
                    </div>
                    {/* <PgProgressBar status={_status} color='progress_bar__patient' /> */}
                    <div className="pg-text__small my-2 text-center">
                      {t("pg4_msq_status_sub_header_2")}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default MSQStatus;
