import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import GeneAnalysisTable from "./GeneAnalysisTable";
import { getGeneAnalysisContent } from "../../../store/actions/patientReport";
import L_GeneAnalysisContent from "../../LoadingEffectComponent/Report/L_GeneAnalysisContent";

export const GeneAnalysisContent = ({traitContent,filter,psStatus}) => {

  // redux state variable
  const currentPracticeID = useSelector(state => state.auth.currentPracticeID);
  const currentPractice = useSelector(state => state.auth.currentPractice);
  const token = useSelector(state => state.auth.token);
  // const loading = useSelector(state => state.patientReport.isGettingGeneAnalysisContent);
  const currentReportId = useSelector(state => state.patientReport.currentReportId);

  // redux actions
  const getGeneAnalysisContentAction = (token,currentPracticeID,currentPractice,id,filter) => dispatch(getGeneAnalysisContent(token,currentPracticeID,currentPractice,id,filter));

  const dispatch= useDispatch();

  const [loading, setLoading] = useState(true)
  const [content, setContent] = useState([]);
  useEffect(() => {
    let data = {
      reportId: currentReportId,
      traitSubId: traitContent.trait_category_id,
    };

    getGeneAnalysisContentAction(
      token,
      currentPracticeID,
      currentPractice,
      data,
      filter
    ).then((response) => {
      setLoading(false)
      if (!response.error)
        if (response.payload.gene_analysis_summary || response?.payload?.gene_analysis_summary?.length < 1) {
          setContent(response.payload.gene_analysis_summary);
        } else setContent([])
    });
  }, [filter]);
  return (
    <div>
      {loading ? (
        <L_GeneAnalysisContent />
      ) : (
        <GeneAnalysisTable traitContent={content} error={false} filter={filter} psStatus={psStatus}/>
      )}
    </div>
  );
};



export default GeneAnalysisContent;
