import { Modal } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
function PgPrompt({ show, onClose, onAccept, onCancel }) {
    const { t } = useTranslation();
    return (
        <Modal show={show} onHide={onClose} bsPrefix="border-radius modal" contentClassName="border-radius" style={{borderRadius:8}}>
            <div className="p-3">
                <div className="pg-text__bold">{t('pat_upload_modal_prompt_header')}</div>
                <div className="text-danger">{t('pat_upload_modal_prompt_content_1')}</div>
                <div className="d-flex justify-content-end mt-4">
                    <button className="btn bg-sky-blue px-4 mx-1" onClick={onCancel}>{t('cancel')}</button>
                    <button className="btn bgc-primary text-light px-5" onClick={onAccept}>{t('yes')}</button>
                </div>
            </div>
        </Modal>
    )
}

export default PgPrompt
