//  For PSS
import PgInfoTooltip from "../../PgInfoTooltip";

export default function MSQToolTipText2() {
  return (
    <PgInfoTooltip position="left center" stroke="#000" height={30} width={30}>
      {/* <div style={{ padding: "12px" }}> */}
    
      {/* </div> */}
    </PgInfoTooltip>
  );
}
