import React from "react";
import { useTranslation } from "react-i18next";

function TermsAndUse() {
  const { t, i18n } = useTranslation();
  return (
    <div className="my-5 py-5 ">
      <div className="container-xxl">
        <div className="pgs-title ff-v my-5">
          PureInsight<sup>&trade;</sup> Terms of Service
        </div>
        <div>
          <div className="pgs-heading fst-italic my-3">
            Effective Date: December 21, 2023
          </div>
          <div className="pgs-text">
            PureInsight<sup>&trade;</sup> IS UPDATING ITS TERMS OF SERVICE.  PLEASE READ THESE REVISED TERMS CAREFULLY. BY CONTINUING TO USE PureInsight<sup>&trade;</sup>’ SERVICE AFTER [EFFECTIVE DATE], YOU AGREE TO THESE CHANGES.
          </div>
          {/* 1 */}
          <div className="pgs-text mt-5">
            These Terms of Service (“Terms”) govern your use of the PureInsight<sup>&trade;</sup> website, genetic testing & analysis services, reports, software, and associated documentation (collectively, the “Services”) operated by Pure Encapsulations, LLC (“Pure Encapsulations”), provider of the PureInsight<sup>&trade;</sup> online platform and service (“PureInsight<sup>&trade;</sup>”). These Terms permit you to access information available on the PureInsight<sup>&trade;</sup> website at <a href="https://www.puregenomics.com/" target="_blank">https://www.puregenomics.com</a> and to use the Services, and sets forth the Terms under which the Services will be delivered to you as a user of the Services (“User”). Our <a href="https://www.puregenomics.com/privacy" target="_blank">Privacy Policy</a> describes how we process personal information in connection with providing the Services. By accessing the website, using the Services, or registering with Pure Encapsulations, you automatically agree to these Terms and to our Privacy Policy. If you do not agree to these Terms or our Privacy Policy, please do not use the Services.
          </div>
        </div>
        {/* 1 */}
        <div>
          <div className="pgs-heading__sub ff-m fw-bold my-2 mt-5 text-dark">
            1. TESTING & ANALYSIS SERVICES
          </div>
          <div className="pgs-text">
            <div className="">
              Pure Encapsulations offers testing and analysis services to provide nutrition and wellness recommendation to its Users. To participate, you must be eighteen (18) years of age or older. You may choose to submit digitized DNA data, responses to our Multi-Symptom Questionnaire, Blood Lab Data, and other information (“Your Data”) to Pure Encapsulations. If you submit Your Data to Pure Encapsulations, you represent that the data is your own or you have legal authorization to submit the Your Data.
            </div>
            <div className="my-3">
              By submitting Your Data to Pure Encapsulations, you authorize Pure Encapsulations to use or analyze Your Data to create reports about your individual traits, characteristics, predispositions, or health conditions in order to offer nutritional supplement, dietary, and lifestyle recommendations; to share Your Data and associated reports or recommendations with any health care provider and/or authorized representative you expressly designate; and to use Your Data to improve Pure Encapsulations’ product features and services, create new product features and services, and to comply with law enforcement requests. You will not acquire rights to any research or products developed by Pure Encapsulations through the use of Your Data by virtue of submission of  Your Data to Pure Encapsulations.
            </div>
            <div className="my-3">
              Notwithstanding any other provision in these Terms, you retain an ownership right over Your Data, subject to the rights granted to Pure Encapsulations by these Terms. You may request the destruction of Your Data at any time. To do so, simply contact Pure Encapsulations at {" "}
              <a href="mailto:pureinsight@pureencapsulations.com">
                pureinsight@pureencapsulations.com
              </a>{" "}
              or request deletion via your account portal.
            </div>
            <div className="my-3">
              Pure Encapsulations offers registered healthcare practitioners the ability to review patient data and reports through the platform. If you are a healthcare practitioner using the Services, you represent that you (1) are duly licensed and authorized in the jurisdiction in which you practice to offer genetic counseling and nutritional supplement counseling; and (2) hold any legally required authorization or consent to upload data to the Services on behalf of your patients.
            </div>
          </div>
        </div>
        {/* 2 */}
        <div>
          <div className="pgs-heading__sub ff-m fw-bold my-2 mt-5 text-dark">
            2. USER ACCOUNT
          </div>
          <div className="pgs-text">
            <div className="">
              To use the Services, you must create an account and specify a password. Only registered healthcare practitioners and their patients may create an account. You must provide truthful and accurate information during the account registration process. Do not impersonate someone else when you create your account. If your information changes at any time, please update your account to reflect those changes.
            </div>
            <div className="my-3">
              You may not share your account with anyone else. Healthcare practitioners may not access patient accounts on the patient’s behalf. Please keep your password confidential, and try not to use it on other websites. If you believe that your account has been compromised, please contact us by emailing{" "}
              <a href="mailto:pureinsight@pureencapsulations.com">
                pureinsight@pureencapsulations.com
              </a>
            </div>
            <div className="my-3">
              Pure Encapsulations is not responsible for any loss resulting from the unauthorized use of your password, with or without your knowledge. You agree to indemnify and hold harmless Pure Encapsulations for losses Pure Encapsulations or another party incur due to someone else using your account because you failed to use reasonable care to safeguard your password.
            </div>
            <div className="my-3">
              You have the right to close your account at any time. When you close your account, Pure Encapsulations will delete all data in your account within thirty (30) days, after which no account data will be recoverable, except that Pure Encapsulations will own and may continue to use any aggregated results from the analysis of Your Data prior to deletion. To delete your account, please contact Pure Encapsulations at{" "}
              <a href="mailto:pureinsight@pureencapsulations.com">
                pureinsight@pureencapsulations.com
              </a>{" "}
              or request deletion via your account portal.
            </div>
          </div>
        </div>
        {/* 3 */}
        <div>
          <div className="pgs-heading__sub ff-m fw-bold my-2 mt-5 text-dark">
            3. USER OF SERVICE
          </div>
          <div className="pgs-text">
            <div className="">
              You may not use the Services in a way that violates any laws, infringes anyone’s rights, or interferes with the Services or any features on the Services (including any technological measure we use to enforce these Terms). You may not make unauthorized copies or downloads of information accessible on and delivered through the Services (“Content”) or make any attempts to affect the functionality of the Services. You may not use any Content or any feature on the Services in any manner that is or could be interpreted as competitive with Pure Encapsulations. You may not use the Services if you represent an insurance company or employer seeking information about the health status of a person or employee. If we (in our sole discretion) determine that you have acted inappropriately, we reserve the right to investigate, terminate your account without refund, prohibit you from using the Services, and take appropriate legal actions.
            </div>
          </div>
        </div>
        {/* 4 */}
        <div>
          <div className="pgs-heading__sub ff-m fw-bold my-2 mt-5 text-dark">
            4. COMMUNICATIONS ABOUT THE SERVICES
          </div>
          <div className="pgs-text">
            <div className="">
              We may contact you for the purpose of providing you with account information, legal notices, or information about our products and services. By opening an account, you agree that you accept electronic communications as an acceptable form of notice. You have the right to opt-out of marketing communications by changing your marketing preferences in your account. If you no longer wish to receive account information by email, you must close your account as detailed in these Terms.
            </div>
          </div>
        </div>
        {/* 5 */}
        <div>
          <div className="pgs-heading__sub ff-m fw-bold my-2 mt-5 text-dark">
            5. USE OF CONTENT; INTELLECTUAL PROPERTY
          </div>
          <div className="pgs-text">
            <div className="">
              Pure Encapsulations owns and operates the Services, including all Content. Using our Services does not give you ownership of any intellectual property rights to the Content you access. Except as otherwise permitted by these Terms, no Content available through the Services may be modified, copied, reproduced, republished, uploaded, posted, transmitted, resold or distributed in any way, except with the prior written consent of Pure Encapsulations. All Content included on the Services is the exclusive property of Pure Encapsulations, its parent company, or its suppliers and is protected by U.S. and other copyright laws and international treaties.
            </div>
            <div className="my-3">
              Any other use of the Content provided through the Services is strictly prohibited. You may not copy, decompile, reverse engineer, disassemble, or attempt to derive the source code of, modify, or create derivative works of the Services, any updates, or any part thereof. Except as expressly stated in this legal notice, you do not have any right or license to the website or Content, or any portion of the Content.
            </div>
            <div className="my-3">
              All trademarks, logos, and service marks displayed on the Services are registered and unregistered trademarks of Pure Encapsulations, its parent company, and/or third parties who have authorized their use (collectively the “Trademarks”). You may not use, copy, reproduce, republish, upload, post, transmit, distribute, or modify these Trademarks in any way. All of the materials on the Services are copyrighted, except where explicitly noted otherwise. We will enforce our intellectual property rights to the fullest extent of the law, including the seeking of criminal prosecution, if necessary.
            </div>
            <div className="my-3">
              If you have questions concerning the legal notices stated above,
              you may contact us at
            </div>
            <div className="">
              <a href="mailto:pureinsight@pureencapsulations.com">
                pureinsight@pureencapsulations.com
              </a>
            </div>
          </div>
        </div>
        {/* 6 */}
        <div>
          <div className="pgs-heading__sub ff-m fw-bold my-2 mt-5 text-dark">
            6. THIRD-PARTY COPYRIGHTS
          </div>
          <div className="pgs-text">
            <div className="">
              Just as Pure Encapsulations requires users to respect our copyrights, and those of our affiliates and partners, we respect the copyrights of others. Pure Encapsulations, pursuant to 17 U.S.C. § 512, the Digital Millennium Copyright Act (the “DMCA”), has implemented the following procedures for receiving written notification of claimed infringements and for processing such claims in accordance with the DMCA. Pure Encapsulations accommodates and does not interfere with standard technical measures used by copyright owners to protect their materials.
            </div>
            <div className="my-3">
              If you believe any Content on the Services infringes your copyright, you may request removal of such Content (or access thereto) from the Services by contacting Pure Encapsulations as set forth below and providing the following information:
            </div>
            <ul>
              <li>
                Identification of the copyrighted work that you believe to be
                infringed. Please describe the work and, where possible, include
                a copy or the location (e.g., page within the Services) of an
                authorized version of the work.
              </li>
              <li>
                Identification of the material that you believe to be infringing
                and its location. Please describe the material, and provide us
                with its URL or any other pertinent information that will allow
                us to locate the material.
              </li>
              <li>Your name, address, telephone number, and e-mail address.</li>
              <li>
                A statement that you have a good faith belief that the
                complained of use of the materials is not authorized by the
                copyright owner, its agent, or the law.
              </li>
              <li>
                A statement that the information that you have supplied is
                accurate, and indicating that "under penalty of perjury," you
                are the copyright owner or are authorized to act on the
                copyright owner's behalf.
              </li>
              <li>
                A signature or the electronic equivalent from the copyright
                holder or authorized representative.
              </li>
            </ul>
            <div className="my-3">
              Send this information by email to:{" "}
              <a href="mailto:pureinsight@pureencapsulations.com">
                pureinsight@pureencapsulations.com
              </a>
            </div>
            <div className="my-3">
              Once notification in accordance with the above is received by the
              Designated Agent, it is Pure Encapsulations policy to: (a) remove
              or disable access to the Content; and (b) notify the provider of
              the Content or user of the Services that it has removed or
              disabled access to such Content.
            </div>
          </div>
        </div>
        {/* 7 */}
        <div>
          <div className="pgs-heading__sub ff-m fw-bold my-2 mt-5 text-dark">
            7. WARRANTY DISCLAIMER
          </div>
          <div className="pgs-text">
            <div className="">
              We provide the Services using a commercially reasonable level of
              care, but there are certain things that we cannot guarantee. Pure
              Encapsulations does not make any representations as to warrant or
              guarantee the accuracy, quality, completeness, currency, or
              validity of any Content available through the Services.
            </div>
            <div className="my-3">
              OTHER THAN EXPRESSLY SET OUT IN THESE TERMS, PURE ENCAPSULATIONS
              DOES NOT MAKE ANY SPECIFIC PROMISES ABOUT THE SERVICES. FOR
              EXAMPLE, WE DON’T MAKE ANY COMMITMENTS ABOUT THE CONTENT OR THE
              SERVICES, THE SPECIFIC FUNCTIONS OF THE SERVICES, OR THEIR
              RELIABILITY, AVAILABILITY, OR ABILITY TO MEET YOUR NEEDS. WE
              PROVIDE THE SERVICES “AS IS.”
            </div>
            <div className="my-3">
              SOME JURISDICTIONS PROVIDE FOR CERTAIN WARRANTIES, LIKE THE
              IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
              PURPOSE, AND NON- INFRINGEMENT. TO THE EXTENT PERMITTED BY LAW, WE
              DISCLAIM ALL WARRANTIES.
            </div>
          </div>
        </div>
        {/* 8 */}
        <div>
          <div className="pgs-heading__sub ff-m fw-bold my-2 mt-5 text-dark">
            8. FORCE MAJEURE
          </div>
          <div className="pgs-text">
            <div className="">
              Pure Encapsulations will have no liability whatsoever for delays or interruptions in delivery or accessibility of any of the Services, interruptions or service or other breach of these Terms due to fire, explosion, lightening, power surge or failure, water, acts of God, war, civil disturbance, terrorism, acts or omissions or communications carriers, pandemics, or other causes beyond Pure Encapsulations’ reasonable control.
            </div>
          </div>
        </div>
        {/* 9 */}
        <div>
          <div className="pgs-heading__sub ff-m fw-bold my-2 mt-5 text-dark">
            9. LIMITATION OF LIABILITY
          </div>
          <div className="pgs-text">
            <div className="">
              Except where prohibited, under no circumstances will Pure
              Encapsulations nor any of its officers, directors, employees,
              representatives, agents, licensors or suppliers be liable for any
              direct, indirect, punitive, incidental, compensatory, special or consequential damages that result from the use of or the inability to use the Services, even if Pure Encapsulations or authorized representatives of Pure Encapsulations have been advised of the possibility of such damages. YOU EXPLICITLY RELEASE PURE ENCAPSULATIONS FROM ANY LIABILITY WHATSOEVER ARISING FROM ITS ANALYSIS OF YOUR DATA, INCLUDING, WITHOUT LIMITATION, ERRORS, OMISSIONS, CLAIMS FOR DEFAMATION, INVASION OF PRIVACY, RIGHT OF PUBLICITY, EMOTIONAL DISTRESS OR ECONOMIC LOSS. In no event shall Pure Encapsulations’ total liability to you for damages, losses and causes of action (whether in contract, negligence or otherwise) exceed the amount paid by you, if any, for accessing the Services within the 12-month period immediately preceding your claims. This limitation of liability shall survive these Terms and your use of the Service.
            </div>
          </div>
        </div>
        {/* 10 */}
        <div>
          <div className="pgs-heading__sub ff-m fw-bold my-2 mt-5 text-dark">
            10. SERVICE DISCLAIMER
          </div>
          <div className="pgs-text">
            <div className="">
              As a condition of your use of the Services, you agree that the{" "}
              <span className="fw-bold text-decoration-underline">
                {" "}
                PureInsight<sup>&trade;</sup> website and the Services are
                provided for informational purposes only and are not meant to
                substitute for the advice provided by a physician or other
                medical professional. You should not use the information
                contained herein for diagnosing or treating a health problem or
                disease, or prescribing any medication. You should read all
                product packaging carefully.{" "}
              </span>{" "}
              If you have or suspect that you have a medical problem, promptly
              contact your health care provider. In an emergency, dial 911.
            </div>
            <div className="my-3">
              Information on the PureInsight<sup>&trade;</sup> website is based
              on scientific studies (human, animal, or in vitro), clinical
              experience, or traditional usage as cited in each article. The
              results reported may not necessarily occur in all individuals. For
              many of the conditions discussed, treatment with prescription or
              over-the-counter medication is also available. Consult your
              physician, nutritionally oriented health care practitioner, and/or
              pharmacist for any health problem and before using any supplements
              or before making any changes in prescribed medications.
            </div>
          </div>
        </div>
        {/* 11 */}
        <div>
          <div className="pgs-heading__sub ff-m fw-bold my-2 mt-5 text-dark">
            11. INDEMNIFICATION
          </div>
          <div className="pgs-text">
            <div className="">
              As a condition of your use of the Services, you agree to
              indemnify, defend, and hold harmless Pure Encapsulations, its
              officers, directors, employees, representatives, agents, licensors
              and suppliers from and against any and all losses, expenses,
              damages and costs, including reasonable attorneys' fees, resulting
              from your use of the Services, including any claims alleging facts
              that if true would constitute a violation of these Terms or any
              activity related to your account (including negligent or wrongful
              conduct) by you or any other person accessing the Services using
              your account. This indemnification shall survive these Terms and
              your use of the Service.
            </div>
          </div>
        </div>
        {/* 12 */}
        <div>
          <div className="pgs-heading__sub ff-m fw-bold my-2 mt-5 text-dark">
            12. INTERPRETATION
          </div>
          <div className="pgs-text">
            <div className="">
              These Terms constitute the entire agreement between Pure
              Encapsulations and You with respect to the subject matter of these
              Terms, and supersede all prior agreements, understandings, or
              representations between you and Pure Encapsulations or an
              affiliated entity with respect to the subject matter of these
              Terms. These Terms do not supersede or alter any other agreement,
              and you must comply with the terms of these Terms and each such
              other agreement to which you have agreed or may agree in the
              future. In the event of a conflict between these Terms and any
              such other agreement to which have agreed, these terms shall
              control as it relates to the subject matter herein. These terms
              may not be modified except in writing and as permitted herein.
            </div>
          </div>
        </div>
        {/* 13 */}
        <div>
          <div className="pgs-heading__sub ff-m fw-bold my-2 mt-5 text-dark">
            13. MODIFICATIONS AND TERMINATION
          </div>
          <div className="pgs-text">
            <div className="">
              We reserve the right to modify the Services at any time, with or
              without notice to you. For example, we may add or remove
              functionality or features, and we may suspend or stop a particular
              feature altogether. We may modify these Terms at any time, so be
              sure to check back regularly. By continuing to use the Services
              after these Terms have changed, you indicate your agreement to the
              revised Terms. If you do not agree to the changes, you should stop
              using the Services and instruct Pure Encapsulations to close your
              account by following the instructions in these Terms. If we modify
              the Services in a way that constitutes a material change, we will
              give you appropriate notice.
            </div>
          </div>
        </div>
        {/* 14 */}
        <div>
          <div className="pgs-heading__sub ff-m fw-bold my-2 mt-5 text-dark">
            14. ADDITIONAL DETAILS
          </div>
          <div className="pgs-text">
            <div className="">
              These terms are governed by and construed in accordance with the
              laws of the Commonwealth of Massachusetts, U.S.A., without giving
              effect to any principles of conflicts of law. You agree that any
              action at law or in equity arising out of or relating to these
              terms shall be filed only in the state or federal courts located
              in Middlesex County, Massachusetts and you hereby consent and
              submit to the personal jurisdiction of such courts.
            </div>
            <div className="my-3">
              If any provision of these terms shall be unlawful, void, or for
              any reason unenforceable, that provision shall be deemed severable
              from these Terms and shall not affect the validity and
              enforceability of any remaining provisions. Pure Encapsulations’
              failure to insist upon or enforce strict performance of any
              provision of these terms shall not be construed as a waiver of any
              provision or right. This is the entire agreement between us
              relating to the subject matter herein and shall not be modified
              except as described herein.
            </div>
          </div>
        </div>
        {/* 15 */}
        <div>
          <div className="pgs-heading__sub ff-m fw-bold my-2 mt-5 text-dark">
            15. CONTACT US
          </div>
          <div className="pgs-text">
            <div className="">
              If you have questions about the Services,{" "}
              <a href="mailto:pureinsight@pureencapsulations.com">
                pureinsight@pureencapsulations.com
              </a>{" "}
              please contact call us at 877-760-0323, or send correspondence to:
            </div>
            <div className="pgs-text__sm ff-m">
              Pure Encapsulations <br />
              490 Boston Post Road <br />
              Sudbury, MA 01776 USA <br />
              Phone: 877-760-0323
            </div>
            <div className="my-5">Last Update: December 21, 2023</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsAndUse;
