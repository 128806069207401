import React, { Component, useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import Scroll from "react-scroll";
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import ValidationError from "./QuestionTypes/ValidationError";
import TextQuestion from "./QuestionTypes/TextQuestion";
import DateQuestion from "./QuestionTypes/DateQuestion";
import NumberQuestion from "./QuestionTypes/NumberQuestion";
import RadioQuestion from "./QuestionTypes/RadioQuestion";
import CheckboxRadioQuestion from "./QuestionTypes/CheckboxRadioQuestion";
import "../../styles/questionnaire.css";
import "../../styles/chatTyper.css";
import { getQuestion, submitAnswer } from "../../store/actions/questions";
import ChatTyper from "./ChatTyper";
import EditTerminateAlertModal from './editTerminateAlertModal'
import { useTranslation } from "react-i18next";

var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;
var scroller = Scroll.scroller;

function EditAnswer({history,showModal,onCancel,revertAnswer,questionId,editAnswerText,patientExistingAnswers}) {



// redux state variable
const currentPractice = useSelector(state => state.auth.currentPractice);
const currentPracticeID = useSelector(state => state.auth.currentPracticeID);
const currentUserId = useSelector(state => state.auth.currentUserId);
const token = useSelector(state => state.auth.token);


// redux actions
const dispatch= useDispatch();

const getQuestionAction = (question_id) => dispatch(getQuestion(question_id));
const submitAnswerAction = (data) => dispatch(submitAnswer(data));


  const [conversationItems, setConversationItems] = useState([]);
  const [existingAnswers, setExistingAnswers] = useState(patientExistingAnswers)
  const [showQuestions, setShowQuestions] = useState(true);
  const [patientAnswer, setPatientAnswer] = useState("");
  const [currentQuestionId, setCurrentQuestionId] = useState(questionId);
  const [editQuestionId, setEditQuestionId] = useState("");
  const [showTyper, setShowTyper] = useState(false);
  const [currentAnswerText, setCurrentAnswerText] = useState(editAnswerText)
  const [showAlertModal, setShowAlertModal] = useState(false);
  const language = useSelector((state) => state.auth.language)
  const { t, i18n } = useTranslation();

  const addAnswer = (arrayIndex, question, answer) => {
    question.answer = answer;
    if(
      (question.id === "42" && language === "en" && question.answer > 96) ||
      (question.id === "42" && language === "ca" && question.answer > 245) ||
      (question.id === "43" && language === "en" && question.answer > 1000) ||
      (question.id === "43" && language === "ca" && question.answer > 450)
      ){
        var validationError = {
          question_id: 999,
          question_type: "validation-error",
          question: t('patient_q_msg1')
          };
        const { answer, ...newQuestion } = question;
        setConversationItems([...conversationItems, validationError, newQuestion])
        setTimeout(() => {
          var questionElements = document.querySelectorAll(".question-class:last-child")
          var nextElement = questionElements[questionElements.length-1]
          if (nextElement != null) {
            nextElement.scrollIntoView({ behavior: "smooth" , block: "nearest" });
          }
        }, 1000);
      }
    else {
      if (
        question.question_type === "checkbox-radio" ||
        question.question_type === "radio"
      ) {
        var data = {
          patient_id: currentUserId.toString(),
          question_id: currentQuestionId,
          text: "",
          selections: answer,
          edit: true
        };
      } else {
        var data = {
          patient_id: currentUserId.toString(),
          question_id: currentQuestionId,
          text: answer,
          selections: [],
          edit: true
      };
    }

    submitAnswerAction(data).then((res) => {
      if(res.error){
        toast.error(('patient_q_msg2') ,
        {
          style: {
          fontSize: '16px',
          },
          duration: 15000
        })
        onCancel()
      }
      else{
        setCurrentAnswerText("")
        if (res.payload.next_question_id === null) {
          toast.success(t('patient_eq_msg1') );
          onCancel()
        } else {
          setCurrentQuestionId(res.payload.next_question_id);
          setTimeout(() => {
            var nextElement = document.getElementById(
              `${res.payload.next_question_id}Container`
            );
            if (nextElement != null) {
              nextElement.scrollIntoView({ behavior: "smooth" });
            }
          }, 3200);
        }
      }
    });
    }
  };

  useEffect(() => {
    Events.scrollEvent.register("begin", function () {
      console.log("begin", arguments);
    });
    Events.scrollEvent.register("end", function () {
      console.log("end", arguments);
    });
    scrollSpy.update();
    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };
  }, []);

  const submitExistingAnswers = (existingAnswers, conversationItems) => {
    conversationItems.forEach(function (answerItem) {
      var oldAnswer = existingAnswers.filter(x => x.id === conversationItems[0].id)[0]
      if (oldAnswer.answer_text.length !== 0) {
        if (
          oldAnswer.question_type === 'checkbox-radio' ||
          oldAnswer.question_type === 'radio'
        ) {
          var data = {
            patient_id: currentUserId.toString(),
            question_id: parseInt(oldAnswer.id),
            text: '',
            selections: oldAnswer.answer_text,
            edit: false,
          }
        } else {
          var data = {
            patient_id: currentUserId.toString(),
            question_id: parseInt(oldAnswer.id),
            text: oldAnswer.answer_text.toString(),
            selections: [],
            edit: false,
          }
        }
        submitAnswerAction(data).then((res) => {
          if(res.error){
            toast.error(t('patient_q_msg2'),
            {
              style: {
              fontSize: '16px',
              },
              duration: 15000
            })
            revertAnswer()
          }
        });
        setShowAlertModal(false)
        revertAnswer();
      }
      else {
        setShowAlertModal(false)
        onCancel();
      }
    })
    toast.success(t('patient_eq_msg2'));
  }

  useEffect(() => {
    getQuestionAction(currentQuestionId).then((res) => {
      if(res.error){
        toast.error(t('patient_q_msg3'),
        {
          style: {
          fontSize: '16px',
          },
          duration: 15000
        })
        onCancel();
      }
      else{
        setShowTyper(true);
        setTimeout(() => {
          setShowTyper(false);
          setConversationItems([...conversationItems, res.payload]);
        }, 2500);
      }
    });
  }, [currentQuestionId]);

  return (
    <div>
    <Modal
      show={showModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={onCancel}
      contentClassName="border-radius"
      className="edit-question-model"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header className="edit-question-modal-header">
      <div style={style.closeButton} onClick={() => setShowAlertModal(true) }>
        <div className="fas fa-times"></div>
      </div>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Element
            name="test7"
            className="element"
            id="containerElement"
          >
            <div>
              {showAlertModal && <EditTerminateAlertModal
                showModal={showAlertModal}
                onCancel={() => setShowAlertModal(false)}
                onYes={() => submitExistingAnswers(existingAnswers, conversationItems)}
                question={conversationItems[conversationItems.length - 1]}
              />}
              {showQuestions && (
                <div className="conversation-wrapper edit-conversation-wrapper">
                  {conversationItems.map((item, index) => (
                    <div key={index}>
                      <Element
                        name={item.id + "Container"}
                        //style={{ marginBottom: "100vh" }}
                        id={item.id + "Container"}
                        className="question-bubble  question-class"
                      >

                        {item.question_type === "validation-error" && (
                          <ValidationError
                            question={item}
                            index={index}
                          />
                        )}
                        {item.question_type === "date" && (
                          <DateQuestion
                            question={item}
                            addtextAnswer={addAnswer}
                            index={index}
                            isEdit={true}
                            editAnswerText={currentAnswerText}
                          />
                        )}
                        {item.question_type === "number" && (
                          <NumberQuestion
                            question={item}
                            addtextAnswer={addAnswer}
                            index={index}
                            isEdit={true}
                            editAnswerText={currentAnswerText}
                          />
                        )}
                        {item.question_type === "text" && (
                          <TextQuestion
                            question={item}
                            addtextAnswer={addAnswer}
                            index={index}
                            isEdit={true}
                            editAnswerText={currentAnswerText}
                          />
                        )}
                        {item.question_type === "radio" && (
                          <RadioQuestion
                            question={item}
                            addtextAnswer={addAnswer}
                            index={index}
                            isEdit={true}
                            editAnswerText={currentAnswerText}
                          />
                        )}
                        {item.question_type === "checkbox-radio" && (
                          <CheckboxRadioQuestion
                            question={item}
                            addtextAnswer={addAnswer}
                            index={index}
                            isEdit={true}
                            editAnswerText={currentAnswerText}
                          />
                        )}
                      </Element>
                    </div>
                  ))}
                  {showTyper && <ChatTyper />}
                </div>
              )}
            </div>
          </Element>
        </div>
      </Modal.Body>
    </Modal>
    </div>
  );
}


export default EditAnswer;

const style = {
  closeButton: {
    position: 'absolute',
    right: 30,
    top: 12,
    fontSize: 24,
    cursor: "pointer",
    color: '#14328c'
  }
}