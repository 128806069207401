import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from "react-i18next";

function ExternalSitePrompt({
    externalSiteLink,
    userType = "User",
    showModal,
    onCancel }) {
    const { t, i18n } = useTranslation();
    return (
        <div>
            <Modal
                show={showModal}
                size="lg"
                centered
                aria-labelledby="contained-modal-title-vcenter"
                onHide={onCancel}
                contentClassName="border-radius"
                className="external-site-prompt-modal"

            >
                <Modal.Body>
                    <div style={{
                        position: 'absolute',
                        right: 25,
                        top: 15,
                        fontSize: 20,
                        cursor: "pointer",
                        color: `var(--pg_primary${userType === "Patient" && '__p'})`
                    }} onClick={onCancel}>
                        <div className="fas fa-times "></div>
                    </div>
                    <div className="p-2">
                        <div className={`lh-base pg-title my-4 ${userType === "Patient" ? 'textc-primary__p' : 'textc-primary'}`}>
                            <span dangerouslySetInnerHTML={{ __html: t('pre_login_pop_text_1') }} />
                        </div>
                        <div className="pg-text my-3">
                            {i18n.language !== "en_gb"
                                ? <div>
                                    <p>You are leaving the PureInsight<sup>&trade;</sup> website and will be transferred to the {externalSiteLink} website.</p>
                                    <p>You are leaving our site and we cannot be held responsible for the content or services provided on external websites. External sites may send their own cookies to users, collect data, or solicit personal information.</p>
                                    <p>The Privacy Policy and Terms of Service of PureInsight<sup>&trade;</sup> do not apply to external links.</p>
                                </div>
                                : <span dangerouslySetInnerHTML={{ __html: t('pre_login_pop_text_2') }} />}
                        </div>
                        <div className="row justify-content-around my-2 mt-4">
                            <div className="col-lg-6 col-md-10 col-12">
                                <button
                                    onClick={() => {
                                        window.open(t(externalSiteLink))
                                        onCancel();
                                    }}
                                    className={`w-100 my-1 px-5 btn btn-primary${userType === "Patient" ? "__p" : ""}`}>{t('pre_login_pop_text_3')}</button>
                            </div>
                            {/* <div className="col-lg-6 col-md-10 col-12">
                                <button
                                    onClick={onCancel}
                                    className={`w-100 my-1 px-5 btn btn-outline${userType === "Patient" ? "__p" : ""}`}>{t('pre_login_pop_text_4')}</button>

                            </div> */}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ExternalSitePrompt
