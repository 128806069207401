import React from 'react'
import { useNavigate } from "react-router-dom";

function PatientReport() {
   const navigate = useNavigate();
 return (
        <div className='container py-5'>
            <div
                className="cursor-pointer textc-primary pg-link"
                onClick={() => navigate("/admin/dashboard")}
            >
                <i className="fas fa-chevron-left mr-2"></i> Dashboard
            </div>
            <div className="pg-heading">
                Patient  Report
            </div>
            Patient Report
        </div>
    )
}

export default PatientReport