import React from "react";
import { Spinner } from "react-bootstrap";

const ButtonWithLoader = (props) => {
  const { children, loading, spinnerClass, ...btn } = props;
  return (
    <button {...btn} disabled={loading} style={{ minWidth: 200, width: 'fit-content' }}>
      {loading ? (
        <>
          <Spinner animation="border" className={`${spinnerClass}`} role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </>
      ) : (
        children
      )}
    </button>
  );
};

export default ButtonWithLoader;
