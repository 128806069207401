import ContentLoader from "react-content-loader";
function L_SingleLine() {
    return (
        <ContentLoader
            speed={2}
            width="100%"
            height="30px"
            backgroundColor="#9e9e9e52"
            foregroundColor="#18368f80"
        // {...props}
        >
            <rect x="0" y="1" rx="3" ry="3" width="300" height="30" />
        </ContentLoader>
    )
}

export default L_SingleLine
