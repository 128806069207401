import React, { useState } from "react";
import Hero from "../../../assets/hero.jpg";
import Report from "../../../assets/01_icon_report.svg";
import Science from "../../../assets/02_icon_science.svg";
import Education from "../../../assets/03_icon_education.svg";
import Supplements from "../../../assets/04_icon_supplements.svg";
import DPGImage_9 from "../../../assets/static/dpg_9.png";
import ExternalSitePrompt from "../ExternalSitePrompt";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function EnhanceYourPractice() {
  const { t, i18n } = useTranslation()
  const [showExternalSiteModal, setShowExternalSiteModal] = useState(false);
  const [externalSiteURL, setExternalSiteURL] = useState("");
  const navigate = useNavigate();
  const lng_data = i18n.language == "en_gb" ? "PureGenomics®" : "PureInsight™"

  return (
    <div className="my-5">
      <div className="container-xxl px-0">
        {showExternalSiteModal && (
          <ExternalSitePrompt
            showModal={showExternalSiteModal}
            externalSiteLink={externalSiteURL}
            onCancel={() => setShowExternalSiteModal(false)}
          />
        )}
        {/* section 1 */}
        <div className="row justify-content-lg-around justify-content-center my-2 my-lg-3">
          <div className="col-11 col-lg-5 order-last order-lg-first">
            <div className="d-none d-lg-block">
              <div className="pgs-heading__sm textc-primary">
                Enhance Your Practice
              </div>
              <div className="pgs-title__xl my-1">
                <span className="pgs-title">Welcome to the</span>
                <span className="pgs-title pgs-title__v textc-primary">
                  Future of Health and Wellness
                </span>
              </div>
            </div>
            <br />
            <div className="pgs-text">
              <div className="my-2">
                At {lng_data}, we partner with healthcare
                providers because we believe that you play a crucial role in
                safely guiding and maximizing outcomes for patients.
              </div>
              {lng_data} empowers you with tools, products,
              information, and education to help you create a comprehensive plan
              of care that is customized to your patients' unique genetic
              makeup. Because when we're closer to our healthiest selves, we're
              one step closer to the greater good.
            </div>
          </div>
          <div className="col-12 col-lg-6 my-auto px-0">
            <div className="d-lg-none my-4 px-4">
              <div className="pgs-heading ff-m textc-primary">
                Enhance Your Practice
              </div>
              <div className="pgs-title pgs-title__xl my-1">
                Welcome to the{" "}
                <div className="ff-v">Future of Health and Wellness</div>
              </div>
            </div>
            <img src={Hero} alt="" className="w-100" />
          </div>
        </div>

        {/* section 2 */}
        <div className="row m-2 my-lg-3 mx-0">
          <div className="my-5">
            <div className="pgs-heading textc-primary text-center my-5">
              {lng_data} puts the power of nutrigenomics in
              your hands.
            </div>
          </div>

          <div className="row justify-content-between lg-justify-content-center my-1 my-lg-4 px-0">
            <div className="col-11 col-lg-5 my-lg-5 my-4">
              <img alt={Report} src={Report} className="pb-2" />
              <div className="pgs-heading__sub ff-m textc-primary fw-bold pb-2 d-block">
                A Powerful Tool
              </div>
              <div className="pgs-text">
                To add value to a person's health, genetic information needs to
                be translated into actionable insights. PureInsight™ delivers an easy-to-understand report, along
                with evidence-based recommendations, to help equip healthcare
                providers and patients with a personalised roadmap to better
                health.{" "}
                <a
                  href="https://pg3-abstract.s3.amazonaws.com/abstract/Sample%20Practitioner%20Report%20PLACEHOLDER.pdf"
                  className="fw-bold  text-decoration-underline text-dark"
                  download
                >
                  View Sample Report
                </a>
              </div>
            </div>

            <div className="col-11 col-lg-5 my-lg-5 my-4">
              <img alt={Science} src={Science} className="pb-2" />
              <div className="pgs-heading__sub ff-m textc-primary fw-bold pb-2 d-block">
                Grounded in Reliable Science
              </div>
              <div className="pgs-text">
                We empower healthcare providers with clinically credible tools,
                insights, and guidance to help patients achieve optimal health.
                We translate the most up-to-date body of evidence on clinically
                relevant genetic variations (SNPs) to generate reliable reports
                for the benefit of both patients and healthcare providers.
              </div>
            </div>
          </div>

          <div className="row justify-content-between my-1 my-lg-4 px-0">
            <div className="col-11 col-lg-5 my-lg-5 my-4">
              <img alt={Education} src={Education} className="pb-2" />
              <div className="pgs-heading__sub ff-m textc-primary fw-bold pb-2 d-block">
                Resources and Education
              </div>
              <div className="pgs-text">
                As the science of nutrigenomics evolves, our understanding and
                interpretation of the insights it provides does too. Beyond
                {lng_data} reports, we're here to support you
                and your practice with tools and ongoing education.
              </div>
            </div>

            <div className="col-11 col-lg-5 my-lg-5 my-4">
              <img alt={Supplements} src={Supplements} className="pb-2" />
              <div className="pgs-heading__sub ff-m textc-primary fw-bold pb-2 d-block">
                Quality Nutritional Supplements
              </div>
              <div className="pgs-text">
                To help ensure that your plan of care unfolds as intended,{" "}
                <span
                  className="text-decoration-underline text-primary cursor-pointer text-dark fw-bold"
                  onClick={() => {
                    setExternalSiteURL("https://www.pureencapsulations.com/");
                    setShowExternalSiteModal(true);
                  }}
                >
                  Pure Encapsulations
                </span>
                <sup>&reg;</sup> our line of nutritional supplements is
                available to meet a wide range of nutritional needs.
              </div>
            </div>
          </div>
        </div>

        {/* section 3 */}
        <div className="row justify-content-lg-between justify-content-center my-5">
          <div className="col-12 col-lg-6 order-lg-last p-0">
            <img src={DPGImage_9} alt="Ipad Image" className="w-100" />
          </div>
          <div className="col-11 col-lg-5 my-auto">
            <div className="pgs-title my-3">
              Ready to <div className="pgs-title__v">Get Started?</div>
            </div>
            <div className="pgs-text my-lg-2 my-4">
              Once you're ready to put the power of {lng_data}{" "}
              to work in your practice, you can get started in{" "}
              <b>2 simple steps.</b>
            </div>
            <button
              className="btn btn-primary"
              onClick={() => navigate("/hcp/getting-started")}
            >
              Get Started As A Healthcare Provider
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EnhanceYourPractice;
