import * as ui from "../actions/ui";

const intialState = {
  canShowAddPatientDialog: false,
  loading: false,
  to: "",
  inRialto: false,
  rialtoState: {
    path: "",
    script: "",
    scriptId: "",
    orderId: "",
    browseCatalog: false,
    reportId: ""
  }
};
const uiReducer = (state = intialState, actions) => {
  switch (actions.type) {
    case ui.HIDE_ADD_PATIENT_DIALOG:
      return {
        ...state,
        canShowAddPatientDialog: false,
      };
    case ui.CHANGE_ROUTE: {
      return { ...state, to: actions.payload }
    }
    case ui.UPDATE_RIALTO_STATE: {
      return { ...state, rialtoState: actions.payload }
    }
    case ui.SHOW_ADD_PATIENT_DIALOG: {
      return {
        ...state,
        canShowAddPatientDialog: true,
      };
    }
    case ui.TOGGLE_LOADING: {
      return {
        ...state,
        loading: actions.payload,
      };
    }
    case ui.TOGGLE_RIALTO: {
      return {
        ...state,
        inRialto: actions.payload,
      };
    }
    default:
      return state;
  }
};

export default uiReducer;
