import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import toastr from "react-hot-toast";
import { parse } from "query-string";
import _ from "lodash";

import ActionButtons from "../StaffActionButtons";
import EditStaffModal from "../../Practice/StaffList/EditStaffModal";
import StaffBatchFnModal from "./StaffBatchFnModal";

import {
  archiveStaffs,
  restoreStaffs,
  resetPasswordStaffs,
  sendRegistrationReminder,
} from "../../../store/actions/practiceDashboard";
import { toggleLoading } from "../../../store/actions/ui";
import { isAllArrayElementHas, isAnyArrayElementHas } from "../../../methods";
import { t } from "i18next";

function StaffBatchFn({
  filter = [],
  setSelectedPractitioner,
  selectedPractitioner,
  practitioners,
  updatePractitionerTable,
}) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const masterAdmin = useSelector((state) => state.auth.masterAdmin);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);
  const [showLockoutModal, setShowLockoutModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [showRestoreModal, setShowRestoreModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showRegistrationReminderModal, setShowRegistrationReminderModal] =
    useState(false);

  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const currentPracticeID = useSelector(
    (state) => state.auth.currentPracticeID
  );

  const onRestore = () => {
    if (
      isAllArrayElementHas(
        practitioners,
        selectedPractitioner,
        "aasm_state",
        "archived"
      )
    ) {
      dispatch(toggleLoading(true));
      dispatch(
        restoreStaffs(
          token,
          selectedPractitioner,
          currentPractice,
          currentPracticeID
        )
      ).then((response) => {
        dispatch(toggleLoading(false));
        if (!response.error) {
          updatePractitionerTable();
          setSelectedPractitioner([]);
          toastr.success(response.payload.message);
        }
        setShowRestoreModal(false);
      });
    } else {
      setShowRestoreModal(false);
      toastr.error(t("staff_alert_1"));
    }
  };
  const onArchive = () => {
    if (
      isAnyArrayElementHas(
        practitioners,
        selectedPractitioner,
        "aasm_state",
        "archived"
      )
    ) {
      dispatch(toggleLoading(true));
      dispatch(
        archiveStaffs(
          token,
          selectedPractitioner,
          currentPractice,
          currentPracticeID
        )
      ).then((response) => {
        dispatch(toggleLoading(false));
        if (!response.error) {
          toastr.success(response.payload.message);
          setSelectedPractitioner([]);
          updatePractitionerTable();
        }
        setShowArchiveModal(false);
      });
    } else {
      setShowArchiveModal(false);
      toastr.error(t("staff_alert_2"));
    }
  };

  const onReset = () => {
    dispatch(toggleLoading(true));
    dispatch(
      resetPasswordStaffs(
        token,
        selectedPractitioner,
        currentPractice,
        currentPracticeID
      )
    ).then((response) => {
      dispatch(toggleLoading(false));
      if (!response.error) {
        toastr.success(response.payload.message);
        setSelectedPractitioner([]);
        updatePractitionerTable();
      }
      setShowResetModal(false);
    });
  };

  const onRegistrationReminder = () => {
    if (
      isAllArrayElementHas(
        practitioners,
        selectedPractitioner,
        "aasm_state",
        "pending"
      )
    ) {
      dispatch(toggleLoading(true));
      dispatch(
        sendRegistrationReminder(
          token,
          selectedPractitioner,
          currentPractice,
          currentPracticeID
        )
      ).then((response) => {
        dispatch(toggleLoading(false));
        if (!response.error) {
          toastr.success(response.payload.message);
          setSelectedPractitioner([]);
          updatePractitionerTable();
        }
        setShowRegistrationReminderModal(false);
      });
    } else {
      setShowRegistrationReminderModal(false);
      toastr.error(t("staff_alert_3"));
    }
  };

  return (
    <div className="my-2 mt-4">
      {showEditModal && (
        <EditStaffModal
          showModal={showEditModal}
          onCancel={() => setShowEditModal(false)}
          updatePractitionerTable={updatePractitionerTable}
          practitioner={
            practitioners[
              _.findIndex(practitioners, { id: selectedPractitioner[0] })
            ] || {}
          }
        />
      )}
      {showResetModal && (
        <StaffBatchFnModal
          onCancel={() => setShowResetModal(false)}
          showModal={showResetModal}
          onYes={onReset}
        >
          <div className="pg-text__bold my-4">{t("staff_alert_4")}</div>
        </StaffBatchFnModal>
      )}
      {showArchiveModal && (
        <StaffBatchFnModal
          showModal={showArchiveModal}
          onCancel={() => setShowArchiveModal(false)}
          onYes={onArchive}
        >
          <div className="pg-text__bold my-4">{t("staff_alert_5")}</div>
        </StaffBatchFnModal>
      )}
      {showRestoreModal && (
        <StaffBatchFnModal
          showModal={showRestoreModal}
          onCancel={() => setShowRestoreModal(false)}
          onYes={onRestore}
        >
          <div className="pg-text__bold my-4">{t("staff_alert_6")}</div>
        </StaffBatchFnModal>
      )}
      {showRegistrationReminderModal && (
        <StaffBatchFnModal
          showModal={showRegistrationReminderModal}
          onCancel={() => setShowRegistrationReminderModal(false)}
          onYes={onRegistrationReminder}
        >
          <div className="pg-text__bold my-4">{t("staff_alert_7")}</div>
        </StaffBatchFnModal>
      )}
      <ActionButtons
        onArchive={() => setShowArchiveModal(true)}
        onEdit={() => setShowEditModal(true)}
        onReset={() => setShowResetModal(true)}
        onRestore={() => setShowRestoreModal(true)}
        onRegistrationReminder={() => setShowRegistrationReminderModal(true)}
        disableEdit={selectedPractitioner.length !== 1}
        disableArchive={
          selectedPractitioner.length === 0 ||
          !isAnyArrayElementHas(
            practitioners,
            selectedPractitioner,
            "aasm_state",
            "archived"
          )
        }
        disableReset={
          selectedPractitioner.length === 0 ||
          !isAnyArrayElementHas(
            practitioners,
            selectedPractitioner,
            "aasm_state",
            "pending"
          ) ||
          !isAnyArrayElementHas(
            practitioners,
            selectedPractitioner,
            "aasm_state",
            "awaiting"
          ) ||
          !isAnyArrayElementHas(
            practitioners,
            selectedPractitioner,
            "aasm_state",
            "archived"
          )
        }
        disableLockout={
          selectedPractitioner.length === 0 ||
          !isAllArrayElementHas(
            practitioners,
            selectedPractitioner,
            "aasm_state",
            "locked"
          )
        }
        disableRestore={
          selectedPractitioner.length === 0 ||
          !isAllArrayElementHas(
            practitioners,
            selectedPractitioner,
            "aasm_state",
            "archived"
          )
        }
        disableApprove={
          selectedPractitioner.length === 0 ||
          !isAllArrayElementHas(
            practitioners,
            selectedPractitioner,
            "aasm_state",
            "pending"
          )
        }
        disableRegistrationReminder={
          selectedPractitioner.length === 0 ||
          !isAllArrayElementHas(
            practitioners,
            selectedPractitioner,
            "aasm_state",
            "pending"
          )
        }
      />
    </div>
  );
}

export default StaffBatchFn;
