import React, { useState, forwardRef } from "react";
import PropTypes from "prop-types";
import { Dropdown } from "react-bootstrap";
import styled from "styled-components";
import { Controller } from "react-hook-form";
import _ from "lodash";
import { useTranslation } from "react-i18next";

const ErrorMessage = styled.div`
  opacity: ${(props) => (props.error ? 1 : 0)};
  transition: opacity 0.3s;
  color: red;
  margin: 5px 0;
`;

const Label = styled.label`
  font-weight: bold;
  font-size: 18px;
  color: var(--primary);
`;

const RequiredText = styled.span`
  font-style: italic;
  color: var(--primary);
`;

const PgSelect = forwardRef(
  (
    {
      setSelectedItem,
      selectedItem,
      placeholder,
      options = [],
      error,
      errorMessage,
      id,
      label,
      required,
      showLabel,
      disclaimer,
      practiceAddPatient,
      skinInput,
      displayKey = "label",
      valueKey = "value",
      control,
      name,
      rules,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const default_class = "";

    // const handleSelect = (option) => {
    //   const selectedValue = option[valueKey] || option;
    //   if (setSelectedItem) {
    //     setSelectedItem(selectedValue);
    //   }
    // };

    const handleSelect = (option) => {
      if (setSelectedItem) {
        setSelectedItem(option); // Store full object, not just the value
      }
    };

    const renderDropdown = (field) => (
      <>
        <Dropdown
          className="d-inline-block position-relative w-100"
          onToggle={() => setIsPopupOpen(!isPopupOpen)}
          id={id}
        >
          <Dropdown.Toggle
            as="div"
            bsPrefix="border-radius py-auto pg-text d-flex justify-content-between pg-select__new"
            style={{
              border:
                field?.state?.invalid || error
                  ? "2px solid #f00"
                  : "3px solid #acd4ff",
              borderRadius: "8px",
              height: 50,
              padding: "6px 10px",
              width: "100%",
            }}
            aria-haspopup="listbox"
            aria-expanded={isPopupOpen}
            aria-labelledby={`${id}-label`}
            tabIndex="0"
            ref={ref}
          >
            <span
              id={`${id}-label`}
              className="my-auto"
              style={{
                color:
                  (_.isObject(selectedItem) && selectedItem[displayKey]) ||
                  selectedItem
                    ? "#000"
                    : "grey",
              }}
            >
              {/* {JSON.stringify(selectedItem)} */}
              {_.isObject(selectedItem)
                ? selectedItem[displayKey]
                : selectedItem
                ? selectedItem
                : placeholder}
            </span>
            <i
              className="fa fa-chevron-down my-auto transition-all fs-6 text_primary"
              aria-hidden="true"
              style={{
                transform: isPopupOpen ? "rotate(-180deg)" : "rotate(0deg)",
              }}
            ></i>
          </Dropdown.Toggle>
          <Dropdown.Menu
            bsPrefix="dropdown-menu w-100 border-radius"
            style={{
              borderRadius: 8,
              background: "white",
              zIndex: 9999,
              opacity: 1,
              maxHeight: "300px",
              overflowY: "auto",
            }}
            role="listbox"
          >
            {options.map((option, i) => (
              <Dropdown.Item
                eventKey={i}
                onClick={() => {
                  handleSelect(option);
                  if (field) {
                    field.onChange(option[valueKey] || option);
                  }
                }}
                key={i}
                className="pg-text text-dark"
                id={`select-option__${option[valueKey] || option}`}
                role="option"
                aria-selected={
                  selectedItem === option[valueKey] || selectedItem === option
                }
                tabIndex="0"
              >
                {option[displayKey] || option}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        {disclaimer && (
          <p className="pg-text" style={{ marginTop: "15px" }}>
            {disclaimer}
          </p>
        )}
        <ErrorMessage error={field?.state?.invalid || error}>
          <i className="fas fa-info-circle"></i>{" "}
          {field?.state?.error?.message || errorMessage}
        </ErrorMessage>
      </>
    );

    return (
      <div className={default_class}>
        {showLabel && (
          <div>
            <Label htmlFor={id}>{label}</Label>
            {required && <RequiredText> ({t("required")})</RequiredText>}
          </div>
        )}
        <div className="cursor-pointer">
          {control ? (
            <Controller
              name={name}
              control={control}
              rules={rules}
              render={({ field, fieldState }) =>
                renderDropdown({ ...field, state: fieldState })
              }
            />
          ) : (
            renderDropdown()
          )}
        </div>
      </div>
    );
  }
);

PgSelect.propTypes = {
  setSelectedItem: PropTypes.func,
  selectedItem: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ),
  error: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  showLabel: PropTypes.bool,
  disclaimer: PropTypes.string,
  practiceAddPatient: PropTypes.bool,
  skinInput: PropTypes.bool,
  displayKey: PropTypes.string,
  valueKey: PropTypes.string,
  control: PropTypes.object,
  name: PropTypes.string,
  rules: PropTypes.object,
};

export default PgSelect;
