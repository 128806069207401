import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getPG4Report } from "../../store/actions/pg4report";
import PatientDetailsCard from "../../components/Report/PG4/PatientDetailsCard";
import FocusAreasContainer from "../../components/Report/PG4/FocusAreasContainer";
import L_PG4ReportSummary from "../../components/LoadingEffectComponent/PG4/L_PG4ReportSummary";
import toast from "react-hot-toast";
import {
  updatePatientReport,
  updatePolygenicScore,
} from "../../store/actions/patientReport";
import PDFReportOptions from "../../components/Report/PG4/PDFReportOptions";
import FurtherAssessmentCard from "../../components/Report/PG4/PersonalizedRecommendations/FurtherAssessmentCard";
import RecommendationContainer from "../../components/Report/PG4/PersonalizedRecommendations/RecommendationContainer";
import "../../styles/PG4/slider.scss";

function ReportSummaryPage() {
  const { report_id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  // redux states
  const token = useSelector((state) => state.auth.token);
  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const currentPracticeID = useSelector(
    (state) => state.auth.currentPracticeID
  );
  const reportData = useSelector((state) => state.pg4Report?.reportData);
  const isError = useSelector((state) => state.pg4Report?.reportError);
  const errorMessage = useSelector((state) => state.pg4Report?.error);
  const isLoading = useSelector((state) => state.pg4Report.reportLoading);
  const updatePolygenicScoreAction = (data) =>
    dispatch(
      updatePolygenicScore({ currentPracticeID, currentPractice, token, data })
    );
  const psStatus = reportData?.genetic?.ps_status;
  const currentPatientId = reportData?.patient_id;
  const userType = useSelector((state) => state.auth.userType);
  const updatePatientReportAction = (data) =>
    dispatch(updatePatientReport(data));

  const getReportDataAction = () =>
    dispatch(
      getPG4Report(currentPracticeID, currentPractice, token, report_id)
    );
  useEffect(() => {
    getReportDataAction();
  }, []);

  const gotoPG3Report = (pg3_report_id) => {
    updatePatientReportAction({
      currentPatientId: reportData.patient_id,
      currentReportId: pg3_report_id,
    });
    navigate(
      userType === "User" ? "/practice/patient/report" : "/patient/report",
      { state: { fromPG4Report: true } }
    );
  };

  const gotoPolygenicScore = (report_id) => {
    navigate(`/practice/patient/report/ps_list/${report_id}`);
  };

  const redirectToDashboard = () => {
    location?.state?.fromPastReport
      ? navigate(-1)
      : navigate("/practice/patients", { replace: true });
  };

  const generatePolygenicScore = async () => {
    const response = await updatePolygenicScoreAction({
      currentPatientId: currentPatientId,
    });
    if (!response.error) {
      window.location.reload();
      toast.success(response.payload.success, { duration: 6000 });
    } else {
      toast.error(t("alert_swr"));
    }
  };

  return (
    <div className="container py-5">
      {isLoading ? (
        <L_PG4ReportSummary />
      ) : isError ? (
        <div className="text-center">
          <h4 className="text-danger">{t("alert_swr")}</h4>
          <b>Errors:</b>
          <pre>{JSON.stringify(errorMessage, null, 4)}</pre>
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-between my-2 items-center  flex-wrap">
            <a
              className="pg4-btn pg4-btn_link"
              onClick={redirectToDashboard}
              style={{ lineHeight: 3.5 }}
            >
              {" "}
              <i className="fas fa-chevron-left me-2"></i>
              {userType === "User" ? t("patients") : t("return_to_db")}
            </a>
            <div className="d-flex justify-content-end flex-wrap w-sm-100">
              <PDFReportOptions
                currentPractice={currentPractice}
                currentPracticeID={currentPracticeID}
                token={token}
                userType={userType}
                currentReportId={report_id}
                currentPatientId={reportData?.patient_id}
                t={t}
              />
            </div>
          </div>
          <div className="pg4-text__title text_primary my-4">
            {t("pracrs_title")}
          </div>
          <PatientDetailsCard data={reportData} />
          <FurtherAssessmentCard
            currentPractice={currentPractice}
            currentPracticeID={currentPracticeID}
            token={token}
            userType={userType}
            currentReportId={report_id}
            currentPatientId={reportData?.patient_id}
            reportData={reportData}
            t={t}
          />
          <div className="bg_secondary p-2 p-lg-3 my-4 rounded">
            <div className="d-flex justify-content-between flex-wrap  align-items-center">
              <div>
                <b className="pg4-text__subheading">{t("pg4_report_txt_9")}</b>
                <p>{t("pg4_report_txt_10")}</p>
              </div>
              <div className="w-sm-100">
                {reportData?.genetic?.status === "Complete" ? (
                  <button
                    className="pg4-btn pg4-btn_outline sm-full px-3"
                    onClick={() =>
                      gotoPG3Report(reportData?.genetic?.report_id)
                    }
                  >
                    <i className="far fa-clipboard fs-5"></i>{" "}
                    {t("pat_dashboard_btn_1")}
                  </button>
                ) : (
                  <div>
                    <b>{t("prac_status")} :</b>{" "}
                    {reportData?.genetic?.status || t("not_uploaded")}
                  </div>
                )}
                {userType === "User" && (
                  <>
                    <br />
                    {reportData?.genetic?.status === "Complete" &&
                      typeof psStatus !== "undefined" &&
                      psStatus !== "pending" && (
                        <button
                          className="pg4-btn pg4-btn_outline mt-1 sm-full"
                          onClick={() =>
                            gotoPolygenicScore(reportData?.genetic?.report_id)
                          }
                        >
                          <i className="far fa-clipboard fs-5"></i>{" "}
                          {t("pg4_report_txt_3")}
                        </button>
                      )}
                    {typeof psStatus !== "undefined" &&
                      psStatus === "pending" && (
                        <button
                          className="pg4-btn pg4-btn_outline mt-1 sm-full"
                          onClick={() => generatePolygenicScore()}
                        >
                          <i className="far fa-clipboard fs-5"></i>
                          {t("pg4_report_txt_4")}
                        </button>
                      )}
                  </>
                )}
              </div>
            </div>
          </div>
          <FocusAreasContainer data={reportData?.focus_areas} />
          <RecommendationContainer />

          {reportData?.genetic_file_present && (
            <div className="row my-4 g-2 g-lg-4">
              <div className="col-12 col-md-6 col-lg-3 my-2 ">
                <div className="report_nav-card bg_secondary rounded p-3 d-flex flex-column justify-content-between">
                  <div className="pg4-text__heading text_primary">
                    {t("pracrs_line_6")}
                  </div>
                  <p>{t("new_report_nav_txt_1")}</p>
                  <div className="pg-link fw-semibold">
                    {t("prelogin_btn_1")}{" "}
                    <i className="fas fa-chevron-right ms-2"></i>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3 my-2">
                <div className="report_nav-card bg_secondary rounded p-3 d-flex flex-column justify-content-between">
                  <div className="pg4-text__heading text_primary">
                    {t("diagrams")}
                  </div>
                  <p>{t("new_report_nav_txt_2")}</p>
                  <div className="pg-link fw-semibold">
                    {t("prelogin_btn_1")}{" "}
                    <i className="fas fa-chevron-right ms-2"></i>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3 my-2">
                <div className="report_nav-card bg_secondary rounded p-3 d-flex flex-column justify-content-between">
                  <div className="pg4-text__heading text_primary">
                    {t("pracrs_line_9")}
                  </div>
                  <p>{t("new_report_nav_txt_3")}</p>
                  <div className="pg-link fw-semibold">
                    {t("prelogin_btn_1")}{" "}
                    <i className="fas fa-chevron-right ms-2"></i>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3 my-2">
                <div className="report_nav-card bg_secondary rounded p-3 d-flex flex-column justify-content-between">
                  <div className="pg4-text__heading text_primary">
                    {t("poly_score")}
                  </div>
                  <p>{t("new_report_nav_txt_4")}</p>
                  <div className="pg-link fw-semibold">
                    {t("prelogin_btn_1")}{" "}
                    <i className="fas fa-chevron-right ms-2"></i>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default ReportSummaryPage;
