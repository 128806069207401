import React from "react";
import { useTranslation } from "react-i18next";
export default function FilePicker({
  label,
  onChange,
  type,
  isDisabled,
  multiple = false,
  userType,
  acceptFiles,
}) {
  let buttonStyle = "btn file-picker-container w-100";
  buttonStyle +=
    type === 1
      ? userType === "Patient"
        ? " btn-primary__p text-light"
        : " btn-primary"
      : userType === "Patient"
      ? " btn-outline__p"
      : " btn-outline";
  let disabledStyle = "btn file-picker-container w-100 bg-secondary text-light";
  const { t, i18n } = useTranslation();
  return (
    <label
      htmlFor="snp-file"
      className={isDisabled ? disabledStyle : buttonStyle}
      style={style}
      title={isDisabled ? t("txt_please_select_your_lab_vendor") : label}
    >
      {label}
      <input
        id="snp-file"
        type="file"
        multiple={multiple}
        onChange={onChange}
        disabled={isDisabled}
        accept={acceptFiles}
      />
    </label>
  );
}

const style = {
  fontFamily: "Mulish",
  fontSize: 18,
  fontWeight: "700 !important",
  lineHeight: "40px",
};
