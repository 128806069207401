import { createAction } from "redux-api-middleware";

export const GENETIC_UPLOAD_REQUEST = "GENETIC_UPLOAD_REQUEST";
export const GENETIC_UPLOAD_SUCCESS = "GENETIC_UPLOAD_SUCCESS";
export const GENETIC_UPLOAD_FAILURE = "GENETIC_UPLOAD_FAILURE";

export const UPDATE_GENETIC_UPLOAD = "UPDATE_GENETIC_UPLOAD";

export const GENETIC_UPLOAD_STATUS_REQUEST = "GENETIC_UPLOAD_STATUS_REQUEST";
export const GENETIC_UPLOAD_STATUS_SUCCESS = "GENETIC_UPLOAD_STATUS_SUCCESS";
export const GENETIC_UPLOAD_STATUS_FAILURE = "GENETIC_UPLOAD_STATUS_FAILURE";

export const CLEAR_GENETIC_STATE = "CLEAR_GENETIC_STATE";

export const clearGeneticState = () => ({ type: CLEAR_GENETIC_STATE });

export const updateGeneticUpload = (data) => ({
  type: UPDATE_GENETIC_UPLOAD,
  payload: data,
});

export const geneticUpload = (
  token,
  currentPracticeID,
  currentPractice,
  data
) => {
  const protocol = process.env.REACT_APP_PROTOCOL;
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;
  // const baseUrl = process.env.REACT_APP_PG3_APP_DOMAIN;
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/web/api/send_genetic_file`,
    // endpoint: `${baseUrl}/web/api/send_genetic_file`,
    method: "POST",
    body: data,
    headers: { Authorization: token, "Current-Practice": currentPracticeID },
    types: [
      GENETIC_UPLOAD_REQUEST,
      GENETIC_UPLOAD_SUCCESS,
      GENETIC_UPLOAD_FAILURE,
    ],
  });
};

export const getGeneticUploadStatus = (
  token,
  currentPracticeID,
  currentPractice,
  data
) => {
  const baseUrl = process.env.REACT_APP_PG3_APP_DOMAIN;
  const type = data.type;
  const document_id = data.document_id;
  return createAction({
    endpoint: `${baseUrl}/web/api/get_analyze_status?type=${type}&document_id=${document_id}`,
    method: "GET",
    headers: { Authorization: token, "Current-Practice": currentPracticeID },
    types: [
      GENETIC_UPLOAD_STATUS_REQUEST,
      GENETIC_UPLOAD_STATUS_SUCCESS,
      GENETIC_UPLOAD_STATUS_FAILURE,
    ],
  });
};

export const getMSQRecommendationStatus = (
  token,
  currentPracticeID,
  currentPractice,
  data
) => {
  const baseUrl = process.env.REACT_APP_PG3_APP_DOMAIN;
  const type = data.type;
  const id = data.id;
  return createAction({
    endpoint: `${baseUrl}/web/api/get_analyze_status?type=${type}&id=${id}`,
    method: "GET",
    headers: { Authorization: token, "Current-Practice": currentPracticeID },
    types: [
      GENETIC_UPLOAD_STATUS_REQUEST,
      GENETIC_UPLOAD_STATUS_SUCCESS,
      GENETIC_UPLOAD_STATUS_FAILURE,
    ],
  });
};
