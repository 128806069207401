import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { HistoryRouter } from "redux-first-history/rr6";
import { Toaster } from 'react-hot-toast';
// import ReactGA from 'react-ga';

import App from "./App";
import history from "./history";
import configureStore from "./store";
import ScrollToTop from "./components/ScrollToTop";
import { Loading } from "./components/Loading"
import { createRoot } from 'react-dom/client';
import "@fortawesome/fontawesome-free/css/all.css";
import "./fonts/_fonts.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "reactjs-popup/dist/index.css";
import "./styles/main.css";
import "./styles/tablestyle.css";
import "./index.css";
import "./styles/PG4/variable.scss"
import "./translation/i18n"

export const store = configureStore(history);

const container = document.getElementById('root');
const root = createRoot(container);

const _AppComponent = <Suspense fallback={<Loading />}>
  <Provider store={store}>
    <HistoryRouter history={history}>
      <ScrollToTop />
      <div>
        <Toaster
          position="top-right"
          toastOptions={{ duration: 6000 }}
          reverseOrder={true}
        />
        <App />
      </div>
    </HistoryRouter>
  </Provider>
</Suspense>;


root.render(_AppComponent);