import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import ViewTypeChanger from "./ViewTypeChanger";
import { getTraitCategories } from "../../../store/actions/patientReport";
import TraitViewContainer from "./TraitViewContainer";
import L_TraitCategories from "../../LoadingEffectComponent/Report/L_TraitCategories";
import _ from "lodash";
import { updateTrait } from "../../../store/actions/traits";

function TraitCategoryContainer({ patientId, viewType, setViewType, psStatus }) {

  // redux state variable
  const currentPracticeID = useSelector(state => state.auth.currentPracticeID);
  const currentPractice = useSelector(state => state.auth.currentPractice);
  const token = useSelector(state => state.auth.token);
  const traits = useSelector(state => state.patientReport.traitCategories);
  const loading = useSelector(state => state.patientReport.isGettingTraitCategories);
  const userType = useSelector(state => state.auth.userType);



  const dispatch = useDispatch();

  // redux actions
  const updateTraitAction = (data) => dispatch(updateTrait(data));
  const getTraitCategoriesAction = (token, currentPracticeID, currentPractice, id) => dispatch(getTraitCategories(token, currentPracticeID, currentPractice, id));


  const { t, i18n } = useTranslation();
  const patientTextColor = { color: userType === "Patient" ? "var(--pg_primary__p)" : 'var(--pg_primary)' }
  // const [viewType, setViewType] = useState(_viewType);
  const [collapsible, setCollapsible] = useState(false)
  const [tableFilter, setTableFilter] = useState(t('pracrs_btn_4'))


  useEffect(() => {
    console.log('viewtype changed:', viewType)
  }, [viewType])


  useEffect(() => {
    getTraitCategoriesAction(
      token,
      currentPracticeID,
      currentPractice,
      patientId
    ).then(response => {
      if (!response.error) {
        let arrayOfArray = response.payload.map(trait => {
          return _.flatten(trait?.traits?.categorization ? Object.values(trait?.traits?.items) : trait?.traits?.items)
        })
        updateTraitAction({
          allTraits: _.flatten(arrayOfArray)
        })
      }
    });
  }, []);

  return (
    <div id="trait-content">
      {loading ? (
        <L_TraitCategories />
      ) : (
        <div className="">
          <div className="d-flex justify-content-between my-3">
            <div className="pg-title my-2" style={patientTextColor}>{t('pracrs_line_3')}</div>{" "}
            <div className="mt-auto pg-text cursor-pointer">
              <ViewTypeChanger
                viewType={viewType}
                collapsible={collapsible}
                tableFilter={tableFilter}
                setViewType={setViewType}
                userType={userType}
                setTableFilter={setTableFilter}
                setCollapsible={setCollapsible}
              />
            </div>
          </div>

          {/* trait section */}
          <div className="row g-2 g-md-4 justify-content-center justify-content-md-start">
            <TraitViewContainer
              collapsible={collapsible}
              tableFilter={tableFilter}
              traits={traits}
              viewType={viewType}
              t={t}
              psStatus={psStatus}
            />
          </div>
        </div>
      )}
    </div>
  );
}
export default TraitCategoryContainer;
