import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchTaxCertificate,
  submitTaxCertificate,
} from "../../store/actions/rialtoManagement";
import "../../styles/taxupload.css";
import toastr from "react-hot-toast";
import { useTranslation } from "react-i18next";

const TaxUpload = ({ token, currentPracticeID, currentPractice }) => {
  const [selectTaxUpload, setSelectTaxUpload] = useState({});
  const appToken = useSelector((state) => state.auth.appToken);
  const inputFile = useRef(null);

  const tax_data = useSelector(
    (state) => state.rialtoManagement.tax_cert_response
  );

  const dispatch = useDispatch();

  const taxCertificate = () => {
    dispatch(fetchTaxCertificate(token, currentPracticeID, currentPractice));
  };

  const onTaxUpload = (item) => {
    setSelectTaxUpload(item);
    taxCertificate();
    inputFile.current.click();
  };

  const catchTaxFile = (e) => {
    const formData = new FormData();
    formData.append("practice_tax_certificate[certificate]", e.target.files[0]);
    formData.append("practice_tax_certificate[state]", selectTaxUpload?.state);
    formData.append("practice_tax_certificate[rialto_token]", appToken);
    formData.append("practice_tax_certificate[tenant_id]", currentPracticeID);
    dispatch(submitTaxCertificate(token, currentPracticeID, formData)).then(
      (response) => {
        if (!response.error) {
          toastr.success(response.payload.message);
          taxCertificate();
        } else {
          toastr.error("Something went wrong!!!");
        }
      }
    );
  };
  const { t, i18n } = useTranslation();
  return (
    <div className="">
      {Array.isArray(tax_data) && tax_data.length > 0 ? (
        <div>
          <div className="head-vd">
            <h6 className="head-line">
            {t('prac_tax_upload_content')}
            </h6>
            <br />
          </div>
          <div className="tablediv" style={{ margin: "25px" }}>
            <table className="table-borderless table-size tablevd">
              <thead className="text-left darkblue">
                <tr>
                  <th>{t('state')}</th>
                  <th>{t('prac_status')}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {tax_data
                  .slice()
                  .sort((a, b) => a.state.localeCompare(b.state))
                  .map((item) => (
                    <tr key={item.id}>
                      <td className="darkblue align-middle fs-6">
                        {item.state}
                      </td>
                      <td
                        className={`${
                          item.status === "Accepted" ? "green" : "red"
                        } align-middle fs-6`}
                      >
                        <span>
                          {item.status != "None" &&
                            item.status != "Deleted" &&
                            item.status}
                        </span>
                      </td>
                      <td class="text-end">
                        <button
                          disabled={
                            item.status === "Processing" ||
                            item.status === "Accepted"
                          }
                          onClick={() => onTaxUpload(item)}
                          type="button"
                          className="btn btn-vd btn-primary"
                        >
                          {item.status === "Expired" ? "Edit" : "Upload"}
                        </button>
                        <input
                          type="file"
                          id="file"
                          accept="image/*,application/pdf"
                          onChange={(e) => catchTaxFile(e)}
                          ref={inputFile}
                          style={{ display: "none" }}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="head-vd">
          <h5 className="head-line text-center">
             {t('prac_tax_upload_txt_1')}
          </h5>
        </div>
      )}
    </div>
  );
};

export default TaxUpload;
