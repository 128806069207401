import { createAction } from "redux-api-middleware";
export const PRACTICE_DASHBOARD_REQUEST = "PRACTICE_DASHBOARD_REQUEST";
export const PRACTICE_DASHBOARD_SUCCESS = "PRACTICE_DASHBOARD_SUCCESS";
export const PRACTICE_DASHBOARD_FAILURE = "PRACTICE_DASHBOARD_FAILURE";

export const PRACTICE_ACTIVITY_REQUEST = "PRACTICE_ACTIVITY_REQUEST";
export const PRACTICE_ACTIVITY_SUCCESS = "PRACTICE_ACTIVITY_SUCCESS";
export const PRACTICE_ACTIVITY_FAILURE = "PRACTICE_ACTIVITY_FAILURE";

export const GDPR_LOG_REQUEST = "GDPR_LOG_REQUEST";
export const GDPR_LOG_SUCCESS = "GDPR_LOG_SUCCESS";
export const GDPR_LOG_FAILURE = "GDPR_LOG_FAILURE";

export const PRACTICE_WHATSNEW_SUCCESS = "PRACTICE_WHATSNEW_SUCCESS";

const protocol = window.location.protocol;
const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;

export const PRACTICE_MANAGE_DATA_REQUEST = "PRACTICE_MANAGE_DATA_REQUEST";
export const PRACTICE_MANAGE_DATA_SUCCESS = "PRACTICE_MANAGE_DATA_SUCCESS";
export const PRACTICE_MANAGE_DATA_FAILURE = "PRACTICE_MANAGE_DATA_FAILURE";

export const PRACTICE_GDPR_REQUEST_LOG_REQUEST = "PRACTICE_GDPR_REQUEST_LOG_REQUEST";
export const PRACTICE_GDPR_REQUEST_LOG_SUCCESS = "PRACTICE_GDPR_REQUEST_LOG_SUCCESS";
export const PRACTICE_GDPR_REQUEST_LOG_FAILURE = "PRACTICE_GDPR_REQUEST_LOG_FAILURE";

export const PRACTICE_GET_PATIENT_LIST_REQUEST = "PRACTICE_GET_PATIENT_LIST_REQUEST";
export const PRACTICE_GET_PATIENT_LIST_SUCCESS = "PRACTICE_GET_PATIENT_LIST_SUCCESS";
export const PRACTICE_GET_PATIENT_LIST_FAILURE = "PRACTICE_GET_PATIENT_LIST_FAILURE";

export const PRACTICE_DELETE_PATIENTS_REQUEST = "PRACTICE_DELETE_PATIENTS_REQUEST";
export const PRACTICE_DELETE_PATIENTS_SUCCESS = "PRACTICE_DELETE_PATIENTS_SUCCESS";
export const PRACTICE_DELETE_PATIENTS_FAILURE = "PRACTICE_DELETE_PATIENTS_FAILURE";

export const PRACTICE_ADMIN_GDPR_REQUEST = "PRACTICE_ADMIN_GDPR_REQUEST";
export const PRACTICE_ADMIN_GDPR_SUCCESS = "PRACTICE_ADMIN_GDPR_SUCCESS";
export const PRACTICE_ADMIN_GDPR_FAILURE = "PRACTICE_ADMIN_GDPR_FAILURE";

export const PRACTICE_SET_ADMIN_GDPR_REQUEST = "PRACTICE_SET_ADMIN_GDPR_REQUEST";
export const PRACTICE_SET_ADMIN_GDPR_SUCCESS = "PRACTICE_SET_ADMIN_GDPR_SUCCESS";
export const PRACTICE_SET_ADMIN_GDPR_FAILURE = "PRACTICE_SET_ADMIN_GDPR_FAILURE";

export const ADD_PRACTITIONER_REQUEST = "ADD_PRACTITIONER_REQUEST";
export const ADD_PRACTITIONER_SUCCESS = "ADD_PRACTITIONER_SUCCESS";
export const ADD_PRACTITIONER_FAILURE = "ADD_PRACTITIONER_FAILURE";

export const BATCH_FUNCTION_REQUEST = "BATCH_FUNCTION_REQUEST";
export const BATCH_FUNCTION_SUCCESS = "BATCH_FUNCTION_SUCCESS";
export const BATCH_FUNCTION_FAILURE = "BATCH_FUNCTION_FAILURE";

export const PRACTICE_PURE_ACCOUNT_REQUEST = "PRACTICE_PURE_ACCOUNT_REQUEST";
export const PRACTICE_PURE_ACCOUNT_SUCCESS = "PRACTICE_PURE_ACCOUNT_SUCCESS";
export const PRACTICE_PURE_ACCOUNT_FAILURE = "PRACTICE_PURE_ACCOUNT_FAILURE";

export const RIALTO_TERMS_REQUEST = "RIALTO_TERMS_REQUEST";
export const RIALTO_TERMS_SUCCESS = "RIALTO_TERMS_SUCCESS";
export const RIALTO_TERMS_FAILURE = "RIALTO_TERMS_FAILURE";
 
export const getDashboardContent = (token, currentPracticeID, currentPractice) => {

  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/dashboard`,
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: token, 'Current-Practice': currentPracticeID },
    types: [
      PRACTICE_DASHBOARD_REQUEST,
      PRACTICE_DASHBOARD_SUCCESS,
      PRACTICE_DASHBOARD_FAILURE,
    ],
  });
};


export const getDashboardPatientActivity = (appToken, currentPracticeID, currentPractice, practitioner_id) => {
  const RIALTO_BASE_URL = process.env.REACT_APP_RIALTO_BASE_URL;
  let params = {
    rialto_token: appToken,
    tenant_id: currentPracticeID,
    practitioner_id: practitioner_id,
    practice: currentPractice,
    
  };
  return createAction({
    endpoint: `${RIALTO_BASE_URL}/rialto/api/recommendation_recent_activities`,
    method: "POST",
    body: JSON.stringify(params),
    headers: { "Content-Type": "application/json" },
    types: [
      PRACTICE_ACTIVITY_REQUEST,
      PRACTICE_ACTIVITY_SUCCESS,
      PRACTICE_ACTIVITY_FAILURE,
    ],
  });
};

export const closeWhatsNew = (token, currentPracticeID, currentPractice, closeType) => {
  let params = {
    id: closeType
  }
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/hide_whats_new`,
    method: "POST",
    body: JSON.stringify(params),
    headers: { "Content-Type": "application/json", Authorization: token, 'Current-Practice': currentPracticeID },
    types: [
      PRACTICE_DASHBOARD_REQUEST,
      PRACTICE_WHATSNEW_SUCCESS,
      PRACTICE_DASHBOARD_FAILURE,
    ],
  });
};

export const getGDPRRequestLogForPractitioner = (token, currentPracticeID, currentPractice) => {
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/dashboard_gdpr_list`,
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: token, 'Current-Practice': currentPracticeID },
    types: [
      GDPR_LOG_REQUEST,
      GDPR_LOG_SUCCESS,
      GDPR_LOG_FAILURE,
    ],
  });
}

export const gdprManageDataRequest = (token, currentPracticeID, currentPractice, requestType) => {
  const protocol = window.location.protocol;
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;
  const body = JSON.stringify({ request_type: requestType });
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/user/gdpr_user_request`,
    method: "POST",
    body,
    headers: { "Content-Type": "application/json", Authorization: token, 'Current-Practice': currentPracticeID },
    types: [
      PRACTICE_MANAGE_DATA_REQUEST,
      PRACTICE_MANAGE_DATA_SUCCESS,
      PRACTICE_MANAGE_DATA_FAILURE
    ],
  });
}

export const getPracticeGDPRRequestLog = (token, currentPracticeID, currentPractice) => {
  const protocol = window.location.protocol;
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/user/user_gdpr_list`,
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: token, 'Current-Practice': currentPracticeID },
    types: [
      PRACTICE_GDPR_REQUEST_LOG_REQUEST,
      PRACTICE_GDPR_REQUEST_LOG_SUCCESS,
      PRACTICE_GDPR_REQUEST_LOG_FAILURE
    ],
  });
}

export const getPatientList = (token, currentPracticeID, currentPractice) => {
  const protocol = window.location.protocol;
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/patient_list`,
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: token, 'Current-Practice': currentPracticeID },
    types: [
      PRACTICE_GET_PATIENT_LIST_REQUEST,
      PRACTICE_GET_PATIENT_LIST_SUCCESS,
      PRACTICE_GET_PATIENT_LIST_FAILURE
    ],
  });
}

export const deletePatients = (token, currentPracticeID, currentPractice, data) => {
  const protocol = window.location.protocol;
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/user/patient/gdpr_user_request`,
    method: "POST",
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json", Authorization: token, 'Current-Practice': currentPracticeID },
    types: [
      PRACTICE_DELETE_PATIENTS_REQUEST,
      PRACTICE_DELETE_PATIENTS_SUCCESS,
      PRACTICE_DELETE_PATIENTS_FAILURE
    ],
  });
}

export const getAdminMadeRequestStatus = (token, currentPracticeID, currentPractice) => {
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/admin_request_status?user_type=user`,
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: token, 'Current-Practice': currentPracticeID },
    types: [
      PRACTICE_ADMIN_GDPR_REQUEST,
      PRACTICE_ADMIN_GDPR_SUCCESS,
      PRACTICE_ADMIN_GDPR_FAILURE
    ],
  });
}


export const setAdminMadeRequestStatus = (token, currentPracticeID, currentPractice, pld, accept) => {
  const body = JSON.stringify(pld);
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/gdpr/practice/${accept ? 'approve' : 'reject'}/user_request`,
    method: "POST",
    body,
    headers: { "Content-Type": "application/json", Authorization: token, 'Current-Practice': currentPracticeID },
    types: [
      PRACTICE_SET_ADMIN_GDPR_REQUEST,
      PRACTICE_SET_ADMIN_GDPR_SUCCESS,
      PRACTICE_SET_ADMIN_GDPR_FAILURE
    ],
  });
}

export const getStaffList = (url, token, currentPracticeID, currentPractice) => {

  const protocol = process.env.REACT_APP_PROTOCOL;
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;
  return createAction({
    endpoint: `${protocol}//${currentPracticeID}${PG3_BASE_URL}/practice/staffs/?${url}`,
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: token, 'Current-Practice': currentPractice },
    types: [
      PRACTICE_GET_PATIENT_LIST_REQUEST,
      PRACTICE_GET_PATIENT_LIST_SUCCESS,
      PRACTICE_GET_PATIENT_LIST_FAILURE
    ],
  });
}

export const addStaff = (token, data, currentPractice, currentPracticeID) => {
  const protocol = process.env.REACT_APP_PROTOCOL;
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/staffs`,
    body: JSON.stringify(data),
    method: "POST",
    headers: { Authorization: token, "User-Type": "User", "Content-Type": "application/json", 'Current-Practice': currentPracticeID },
    types: [
      ADD_PRACTITIONER_REQUEST,
      ADD_PRACTITIONER_SUCCESS,
      ADD_PRACTITIONER_FAILURE,
    ],
  });
};

export const getStaff = (token, id, currentPractice, currentPracticeID) => {
  const protocol = process.env.REACT_APP_PROTOCOL;
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/staffs/${id}`,
    method: "GET",
    headers: { Authorization: token, "User-Type": "User", "Content-Type": "application/json", 'Current-Practice': currentPracticeID },
    types: [
      ADD_PRACTITIONER_REQUEST,
      ADD_PRACTITIONER_SUCCESS,
      ADD_PRACTITIONER_FAILURE,
    ],
  });
};

export const getStaffAccount = (token, id, currentPractice, currentPracticeID) => {
  console.log("Here qwdfagsgdshdfjdf ---->");
  const protocol = process.env.REACT_APP_PROTOCOL;
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/staffs/${currentPracticeID}`,
    method: "GET",
    headers: { Authorization: token, "Current-Type": "User", "Content-Type": "application/json", 'Current-Practice': id },
    types: [
      ADD_PRACTITIONER_REQUEST,
      ADD_PRACTITIONER_SUCCESS,
      ADD_PRACTITIONER_FAILURE,
    ],
  });
};

export const editStaff = (token, id, data, currentPractice, currentPracticeID) => {
  const protocol = process.env.REACT_APP_PROTOCOL;

  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/staffs/${id}`,
    body: JSON.stringify(data),
    method: "PATCH",
    headers: { Authorization: token, "User-Type": "User", "Content-Type": "application/json", 'Current-Practice': currentPracticeID },
    types: [
      BATCH_FUNCTION_REQUEST,
      BATCH_FUNCTION_SUCCESS,
      BATCH_FUNCTION_FAILURE,
    ],
  });
};

export const editStaffAccount = (token, currentPracticeID, currentPractice, id, data) => {
  const protocol = process.env.REACT_APP_PROTOCOL;
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/staffs/${id}`,
    body: JSON.stringify(data),
    method: "PATCH",
    headers: { Authorization: token, "User-Type": "User", "Content-Type": "application/json", 'Current-Practice': currentPracticeID },
    types: [
      BATCH_FUNCTION_REQUEST,
      BATCH_FUNCTION_SUCCESS,
      BATCH_FUNCTION_FAILURE,
    ],
  });
};

export const getStaffPermissions = (token, currentPractice, currentPracticeID) => {
  const protocol = process.env.REACT_APP_PROTOCOL;
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/staffs/optional_permissions`,
    method: "GET",
    headers: { Authorization: token, "User-Type": "User", "Content-Type": "application/json", 'Current-Practice': currentPracticeID },
    types: [
      ADD_PRACTITIONER_REQUEST,
      ADD_PRACTITIONER_SUCCESS,
      ADD_PRACTITIONER_FAILURE,
    ],
  });
};

export const archiveStaffs = (token, ids, currentPractice, currentPracticeID) => {
  const data = {
    ids
  };
  const protocol = process.env.REACT_APP_PROTOCOL;
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/staffs/archive`,
    body: JSON.stringify(data),
    method: "PATCH",
    headers: { Authorization: token, "User-Type": "User", "Content-Type": "application/json", 'Current-Practice': currentPracticeID },
    types: [
      BATCH_FUNCTION_REQUEST,
      BATCH_FUNCTION_SUCCESS,
      BATCH_FUNCTION_FAILURE,
    ],
  });
};

export const restoreStaffs = (token, ids, currentPractice, currentPracticeID) => {
  const data = {
    ids
  };
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/staffs/restore`,
    body: JSON.stringify(data),
    method: "PATCH",
    headers: { Authorization: token, "User-Type": "User", "Content-Type": "application/json", 'Current-Practice': currentPracticeID },
    types: [
      BATCH_FUNCTION_REQUEST,
      BATCH_FUNCTION_SUCCESS,
      BATCH_FUNCTION_FAILURE,
    ],
  });
};

export const resetPasswordStaffs = (token, ids, currentPractice, currentPracticeID) => {
  const data = {
    ids
  };
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/staffs/reset_password`,
    body: JSON.stringify(data),
    method: "PATCH",
    headers: { Authorization: token, "User-Type": "User", "Content-Type": "application/json", 'Current-Practice': currentPracticeID },
    types: [
      BATCH_FUNCTION_REQUEST,
      BATCH_FUNCTION_SUCCESS,
      BATCH_FUNCTION_FAILURE,
    ],
  });
};

export const sendRegistrationReminder = (token, ids, currentPractice, currentPracticeID) => {
  const data = {
    ids
  };
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/staffs/send_registration_reminder`,
    body: JSON.stringify(data),
    method: "POST",
    headers: { Authorization: token, "User-Type": "User", "Content-Type": "application/json", 'Current-Practice': currentPracticeID },
    types: [
      BATCH_FUNCTION_REQUEST,
      BATCH_FUNCTION_SUCCESS,
      BATCH_FUNCTION_FAILURE,
    ],
  });
};


export const getPracticePureAccount = (token, currentPracticeID, currentPractice) => {

  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/fetch_practice_pure_account/${currentPracticeID}`,
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: token, 'Current-Practice': currentPracticeID },
    types: [
      PRACTICE_PURE_ACCOUNT_REQUEST,
      PRACTICE_PURE_ACCOUNT_SUCCESS,
      PRACTICE_PURE_ACCOUNT_FAILURE,
    ],
  });
};

export const getRialtoTermsAndCondition = (token, currentPracticeID, currentPractice) => {

  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/fetch_rialto_terms/${currentPracticeID}`,
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: token, 'Current-Practice': currentPracticeID },
    types: [
      RIALTO_TERMS_REQUEST,
      RIALTO_TERMS_SUCCESS,
      RIALTO_TERMS_FAILURE,
    ],
  });
};
