import { useEffect, useRef, useState } from "react";
import PgAccordion from "../FAQPgAccordion";
import { useNavigate } from "react-router-dom";
import ExternalSitePrompt from "../ExternalSitePrompt";
import { providerFAQDatas } from "./faq_data";
import TextInput from "../../TextInput";
function ProviderFAQ({ history }) {
  const inputRef = useRef();
  const navigate = useNavigate();
  const [showExternalSiteModal, setShowExternalSiteModal] = useState(false);
  const [externalSiteURL, setExternalSiteURL] = useState("");
  const [faqDataState, setfaqDataState] = useState(providerFAQDatas);
  const [searchQuery, setsearchQuery] = useState();

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.ctrlKey && event.key === "f") {
        event.preventDefault();
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const handleSearchChange = (event) => {
    const searchQuery = event.target.value.trim();
    setsearchQuery(searchQuery);
    const filteredFAQs = providerFAQDatas.reduce((acc, faqData) => {
      const filteredQAs = faqData.questions_and_answers.filter(qa =>
        qa.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
        qa.answer.toLowerCase().includes(searchQuery.toLowerCase())
      );
      if (filteredQAs?.length > 0) {
        acc.push({ category: faqData.category, questions_and_answers: filteredQAs });
      }
      return acc;
    }, []);

    setfaqDataState(filteredFAQs)
  };
  const ourSites = ['lmapss', 'pureinsight', 'pureencapsulationspro'];

  const handleFAQParentClick = (event) => {
    const target = event.target;
    if (target.tagName === 'A' && target.getAttribute('href').startsWith('http')) {
      const href = target.getAttribute('href');
      if (!ourSites.some(site => href.includes(site))) {
        console.log('yes')
        event.preventDefault();
        setExternalSiteURL(target.getAttribute('href'));
        setShowExternalSiteModal(true);
      }
    }
  }

  return (
    <div className="my-5">
      {showExternalSiteModal && (
        <ExternalSitePrompt
          showModal={showExternalSiteModal}
          externalSiteLink={externalSiteURL}
          onCancel={() => setShowExternalSiteModal(false)}
        />
      )}
      <div className="container-xxl  mt-3 mt-xl-0">
        <div className="text-center">
          <TextInput
            type="text"
            inputHandler={handleSearchChange}
            placeholder="Search"
            ref={inputRef}
          />
        </div>
        <div className="pgs-title my-5">
          PureInsight<sup>&trade;</sup> Healthcare Provider FAQs
        </div>
        {faqDataState.map(faqData => {
          return (
            <div>
              {/* section 1 */}
              <div className="pgs-heading mt-5 mb-2 textc-primary" dangerouslySetInnerHTML={{ __html: faqData.category }} />
              <div onClick={handleFAQParentClick}>
                {
                  faqData.questions_and_answers.map(qa => <PgAccordion
                    searchedText={searchQuery}
                    question={qa.question}
                  >
                    {qa.answer}
                  </PgAccordion>)
                }
              </div>
            </div>
          )
        })}
        {faqDataState.length === 0 && <div className="text-center fw-bold">
          No results.
        </div>}
      </div>
    </div>
  );
}

export default ProviderFAQ;
