import React, { useMemo } from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
} from "react-table";
import FilterIcon from "../../../assets/filterIcon.png";
import TextInputWithIcon from "../../../components/TextInputWithIcon";

export default function GenotypeTable({
  error,
  genotype,
  setShowGenotypeFilter,
  t
}) {
  const column = [
    { Header: t('genotype_th_1'), accessor: "gene" },
    { Header: t('genotype_th_2'), accessor: "snp" },
    { Header: t('genotype_th_3'), accessor: "rs_number" },
    { Header: t('genotype_th_4'), accessor: "alle" },
    {
      Header: t('genotype_th_5'),
      accessor: "result",
      Cell: (data) => {
        return <div className="text-center fw-bold">
          {data.value}
        </div>
      }
    },
  ];

  const columns = useMemo(() => column, []);
  const data = useMemo(() => genotype, [genotype]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
    prepareRow,

  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy
  );

  const getCellProps = cellInfo => {
    console.log(cellInfo.value, 'ocf')
    return ({
      style: {
        color: cellInfo.value === "+/+" ? "#d1d2d4ff" : null,
        backgroundColor: cellInfo.value === "+/+" ? "var(--pg_primary)" :
          cellInfo.value === "-/-" ? "var(--pg_light_grey)" :
            (cellInfo.value === "-/+" || cellInfo.value === "+/-") ? "var(--pg_primary__p)" : null,
      },
    })
  }


  return (
    <>
      <table {...getTableProps()} className="pg-table genotype-table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span className="fs-4">
                    {column.isSorted ? (column.isSortedDesc ? "↓" : "↑") : ""}{" "}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {error && (
          <tbody>
            <tr>
              <td colSpan="5">
                <div className="w-100 text-center">
                  {t('genotype_no_data')}
                </div>
              </td>
            </tr>
          </tbody>
        )}
        {genotype.length < 1 && !error && (
          <tbody>
            <tr>
              <td colSpan="5">
                <div className="w-100 text-center">{t('genotype_no_data')}</div>
              </td>
            </tr>
          </tbody>
        )}
        {genotype.length > 0 && !error && (
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps([
                        {
                          style: cell.column.style,
                        },
                        getCellProps(cell),
                      ])}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
    </>
  );
}
