import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import RequestLogTable from '../../components/Patient/GDPR/RequestLogTable';
import { gdprManageDataRequest, getPracticeGDPRRequestLog, inactivateAccount } from '../../store/actions/practiceDashboard'
import DeletePatientModal from './DeletePatientModal';

function ManagePracticeData() {
    const [showActionPrompt, setshowActionPrompt] = useState(false);
    const [showDeletePatientModal, setShowDeletePatientModal] = useState(false);
    const [actionPromptMessage, setactionPromptMessage] = useState("");
    const [currentActionType, setCurrentActionType] = useState(null);
    const [logData, setlogData] = useState([]);
    const [gettingLogData, setGettingLogData] = useState(true);
    const [error, setError] = useState(false);

    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.token);
    const currentPractice = useSelector((state) => state.auth.currentPractice);
    const currentPracticeID = useSelector((state) => state.auth.currentPracticeID);
    const gdprRequest = (requestType) => dispatch(gdprManageDataRequest(token, currentPracticeID, currentPractice, requestType))
    const gdprRequestLog = () => dispatch(getPracticeGDPRRequestLog(token, currentPracticeID, currentPractice,))

    const { t, i18n } = useTranslation();

    const gdprRequestFn = (message, actionType) => {
        setactionPromptMessage(message);
        setCurrentActionType(actionType);
        setshowActionPrompt(true)
    }

    const gdprRequestAPIFn = () => {
        setshowActionPrompt(false);
        gdprRequest(currentActionType).then(response => {
            setshowActionPrompt(false);
            if (!response.error)
                toast.success('Request sent successfully.')
        })
    }

    useEffect(() => {
        gdprRequestLog().then(response => {
            if (!response.error) {
                setlogData(response.payload);
            }
        })
    }, []);


    return <div className='my-5'>
        {showActionPrompt && <ActionPrompt
            show={showActionPrompt}
            onReject={() => setshowActionPrompt(false)}
            message={actionPromptMessage} b
            onAccept={gdprRequestAPIFn}
        />}
        {showDeletePatientModal && <DeletePatientModal
            showModal={showDeletePatientModal}
            onReject={() => setShowDeletePatientModal(false)}
        />}
        <div className="container-xxl textc-primary py-4">
            <Link className='text-decoration-none textc-primary' to="/practice/dashboard">
                <i className="fas fa-chevron-left "></i> {t('dashboard')}
            </Link>
            <div className="row justify-content-center">
                <div className="col-12 col-md-12 col-lg-10 col-xl-8">
                    <div className="text-center pg-heading  textc-primary mt-2">
                    {t('header_btn_18')}
                    </div>
                    <div className='textc-primary fw-bold my-3 mb-5'>
                    {t('prac_manage_data_content_1')}
                    </div>
                    <div className="row my-3 mt-5 mt-md-3">
                        <div className="col-12 col-md-6 my-auto">
                            <button
                                onClick={() => gdprRequestFn(
                                    'Clicking the confirm button will inactivate your account and stop all further processing of data for your account as well as your patient accounts. You will retain the option to re-activate the account at a later date by contacting customer support: emailto:support@pure-encapsulations.co.uk',
                                    'inactivate_account')}
                                className='btn btn-primary w-100'
                            >{t('prac_manage_data_content_2')}</button>
                        </div>
                        <div className="col-12 col-md-6 fw-bold mt-2 mt-md-0">
                        {t('prac_manage_data_content_3')}
                        </div>
                    </div>
                    <div className="row my-3 mt-5 mt-md-3">
                        <div className="col-12 col-md-6 my-auto">
                            <button
                                onClick={() => gdprRequestFn(
                                    'Clicking the confirm button will delete your practitioner account and ALL associated patient accounts. We will send an email to patients with instructions on downloading their information and give them 30 days to complete this, after which your account and ALL associate patient accounts will be deleted.  This is not reversible. If you need patient reports for your records, please download the reports prior to clicking confirm. If you are not sure which option to select, please contact customer support: emailto:support@pure-encapsulations.co.uk',
                                    'delete_data')}
                                className='btn btn-primary w-100'
                            >{t('prac_manage_data_content_4')}</button>
                        </div>
                        <div className="col-12 col-md-6 fw-bold mt-2 mt-md-0">
                        {t('prac_manage_data_content_5')}
                        </div>
                    </div>
                    <div className="row my-3 mt-5 mt-md-3">
                        <div className="col-12 col-md-6 my-auto">
                            <button
                                onClick={() => setShowDeletePatientModal(true)}
                                className='btn btn-primary w-100'>{t('prac_manage_data_content_6')}</button>
                        </div>
                        <div className="col-12 col-md-6 fw-bold mt-2 mt-md-0">
                        {t('prac_manage_data_content_7')}
                        </div>
                    </div>
                    <div className="mt-5">
                        <div className='text-center pg-heading textc-primary mb-4'>
                        {t('prac_manage_data_content_8')}
                        </div>
                        <RequestLogTable
                            logData={logData}
                            error={error}
                            loading={gettingLogData}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

export default ManagePracticeData;


export const ActionPrompt = ({ show, onAccept, onReject, message, acceptLabel = 'Confirm', rejectLabel = 'Go Back' }) => {
    return (
        <Modal
            centered
            show={show}
            size="lg"
            onHide={onReject} bsPrefix="border-radius modal" contentClassName="border-radius" style={{ borderRadius: 8 }}>
            <div className="p-3">
                <div className="pg-text__bold">{message}</div>
                <div className="d-flex justify-content-end mt-4">
                    <button className="btn bg-sky-blue px-4 mx-1" onClick={onReject}>{rejectLabel}</button>
                    <button className="btn bgc-primary text-light px-5" onClick={onAccept}>{acceptLabel}</button>
                </div>
            </div>
        </Modal>)
}
