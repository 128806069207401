import React, { useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

// Action
import {
  getStaffAccount,
  editStaffAccount,
} from "../../store/actions/practiceDashboard";
import { getCountries } from "../../store/actions/addPatient";
import { Loading } from "../../components/Loading";
import toastr from "react-hot-toast";

import { validateEmail } from "../../methods";
import L_EditPractitioner from "../../components/LoadingEffectComponent/Admin/L_EditPractitioner";
import { PgRadioButton } from "../../components/PgRadioButton";
import PgSelect from "../../components/PgSelect";
import PgSelectNew from "../../components/common/PgSelectOldUI";
import PgSelectTooltip from "../../components/PgSelectTooltip";
import TextInput from "../../components/TextInput";
import SetNewPasswordModal from "../NewPasswordModal";
import TermsAndConditions from "../TermsAndConditions";
import PracticeTermsTable from "./PracticeTermsTable";
import {
  caStateList,
  label_value,
  licenseNotMandatoryTypes,
  licenseNotNeededTypes,
  stateList,
} from "../../constant";
import Cookies from "universal-cookie";
import useLangSpecificConst from "../../hooks/useLangSpecificConst";
import useLanguageConditions from "../../hooks/useLanguageConditions";
const cookies = new Cookies();

function Account({}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const practitionerCountry = useSelector((state) => state.auth.country);
  const currentPracticeID = useSelector(
    (state) => state.auth.currentPracticeID
  );
  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const currentUserId = useSelector((state) => state.auth.currentUserId);
  const practitionersTypeList = useSelector(
    (state) => state.adminPractitioner.practitioner_types
  );
  const [showNewPasswordModal, setShowNewPasswordModal] = useState(false);
  const [showTermsAndConditionsModal, setShowTermsAndConditionsModal] =
    useState(false);

  const [gettingPracInfo, setgettingPracInfo] = useState(true);
  const [countryList, setCountryList] = useState([]);
  //form fields
  const [practitionerId, setPractitionerId] = useState("");
  const [fName, setfName] = useState("");
  const [lName, setlName] = useState("");
  const [email, setEmail] = useState("");
  const [healthcareProviderType, setHealthcareProviderType] = useState("");
  const [otherHealthcareProviderType, setOtherHealthcareProviderType] =
    useState("");
  const [license, setLicense] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("US");
  const [userState, setUserState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [phone, setPhone] = useState("");
  const [fax, setFax] = useState("");
  const [status, setStatus] = useState("");
  const [practiceName, setPracticeName] = useState("");
  const [practiceLanguage, setPracticeLanguage] = useState(label_value);

  //for validation
  const [fNameError, setfNameError] = useState(false);
  const [lNameError, setlNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [healthcareProviderTypeError, setHealthcareProviderTypeError] =
    useState("");
  const [
    otherHealthcareProviderTypeError,
    setOtherHealthcareProviderTypeError,
  ] = useState("");
  const [address1Error, setAddress1Error] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [zipcodeError, setZipcodeError] = useState(false);
  const [disableLicense, setDisableLicense] = useState(false);
  const [requireLicense, setRequireLicense] = useState(false);
  const [licenseError, setLicenseError] = useState(false);

  const [practititionerTypesList, setPractititionerTypesList] = useState([]);
  const [practitionerTypeId, setPractitionerTypeId] = useState("");
  const [healthcareTypes, setHealthcareTypes] = useState([]);
  const [practititionerTermsList, setPractititionerTermsList] = useState([]);
  const { t, i18n } = useTranslation();
  const { languageOptions } = useLangSpecificConst();
  const { isCA } = useLanguageConditions();

  const getStaffDetail = () => {
    dispatch(
      getStaffAccount(token, currentPracticeID, currentPractice, currentUserId)
    ).then((response) => {
      if (!response.error) {
        const data = response.payload;
        setgettingPracInfo(false);
        setPractitionerId(data.id);
        setEmail(data.email || "");
        setfName(data.first_name || "");
        setlName(data.last_name || "");
        setPhone(data.phone_number || "");
        setCountry(data.country || "US");
        setUserState(data.state || "");
        setStatus(data.aasm_state || "");
        setPracticeName(data.practice_name || "");
        setPracticeLanguage(
          _.find(languageOptions, { value: data.default_language }) || ""
        );
        // setPracticeLanguage(data.language || "ca");
      }
    });
  };

  useEffect(() => {
    getStaffDetail();
  }, []);

  useEffect(() => {
    console.log(practiceLanguage, languageOptions);
  }, [practiceLanguage]);

  const onDone = () => {
    setfNameError(false);
    setlNameError(false);
    setEmailError(false);
    setStateError(false);
    setCountryError(false);

    if (email.length === 0 || !validateEmail(email)) setEmailError(true);
    if (fName.length === 0) setfNameError(true);
    if (lName.length === 0) setlNameError(true);
    if (country.length === 0) setCountryError(true);
    if (userState.length === 0 && practitionerCountry === "US")
      setStateError(true);
    if (
      email.length !== 0 &&
      validateEmail(email) &&
      fName.length !== 0 &&
      lName.length !== 0 &&
      country.length != 0 &&
      (userState.length !== 0 || practitionerCountry !== "US")
    ) {
      handleDone();
    } else {
      window.scrollTo({ top: 300, behavior: "smooth" });
    }
  };

  const handleDone = async () => {
    let data = {
      staff: {
        first_name: _.startCase(fName),
        last_name: lName,
        full_name: `${_.startCase(fName)} ${lName}`,
        email: email.toLowerCase(),
        city: city,
        country: country,
        state: userState,
        phone_number: phone,
        default_language: practiceLanguage.value,
        language: practiceLanguage.value,
      },
    };
    dispatch(
      editStaffAccount(
        token,
        currentPracticeID,
        currentPractice,
        currentUserId,
        data
      )
    ).then((response) => {
      if (response.error) {
        toastr.error(t("patient_err1"));
      } else {
        getStaffDetail();
        const selecteLang = practiceLanguage.value;
        i18n
          .changeLanguage(selecteLang)
          .then(() => {
            cookies.set("i18next", selecteLang, { path: "/" });
            console.log(`Language changed to ${selecteLang}`);
          })
          .catch((error) => {
            console.error(`Error changing language to ${selecteLang}`, error);
          });
        window.scrollTo({ top: 0, behavior: "smooth" });
        toastr.success(t("patient_suc1"));
      }
    });
  };

  const getPractitionerTypeId = (type) => {
    const practitionerType = _.find(healthcareTypes, [
      "practitioner_type",
      type,
    ]);
    setPractitionerTypeId(practitionerType?.id);
  };

  const updateProviderType = (option) => {
    getPractitionerTypeId(option);
    setHealthcareProviderType(option);
    if (licenseNotNeededTypes.includes(option)) {
      setRequireLicense(false);
      setDisableLicense(true);
      setLicense("");
    } else if (licenseNotMandatoryTypes.includes(option)) {
      setRequireLicense(false);
      setDisableLicense(false);
    } else {
      setRequireLicense(true);
      setDisableLicense(false);
    }
  };

  return (
    <div className="container" id="edit-practitioner-modal">
      <Helmet>
        <title>{t("pract_account_h1")}</title>
      </Helmet>
      <div
        className="cursor-pointer textc-primary pg-link"
        onClick={() => navigate("/practice/dashboard")}
        style={{ marginTop: "20px", float: "left", width: "100%" }}
      >
        <i className="fas fa-chevron-left mr-2"></i> {t("dashboard")}
      </div>
      <div className="text-center pg-title mt-5">
        {t("patient_account_txt1")}
      </div>
      <div className="text-center pg-text__bold textc-primary">
        {t("staff_txt_1")}
      </div>
      {gettingPracInfo ? (
        <L_EditPractitioner />
      ) : (
        <div className="row p-5 justify-content-md-center account-form">
          <div className="col-md-6">
            <TextInput
              label={t("prac_register_first_name")}
              showLabel={true}
              required={true}
              id="f-name__addpractitioner"
              placeholder={t("prac_reg_fname")}
              value={fName}
              error={fName?.trim() === ""}
              type="text"
              errorMessage={t("prac_reg_fname_req")}
              inputHandler={(e) => {
                setfName(e.target.value);
              }}
            />

            <TextInput
              label={t("prac_register_last_name")}
              showLabel={true}
              required={true}
              id="l-name__addpractitioner"
              placeholder={t("prac_reg_lname")}
              value={lName}
              error={lName?.trim() === ""}
              errorMessage={t("prac_reg_lname_req")}
              inputHandler={(e) => {
                setlName(e.target.value);
              }}
            />

            <TextInput
              label={t("prac_register_email")}
              showLabel={true}
              required={true}
              emailField={true}
              isDisable={status === "awaiting" || status === "pending"}
              id="email-input__addpractitioner"
              placeholder={t("prac_reg_email")}
              value={email}
              error={email === "" || !validateEmail(email)}
              errorMessage={t("prac_reg_email_req")}
              inputHandler={(e) => {
                setEmail(e.target.value);
              }}
            />

            <TextInput
              label={t("prac_register_name")}
              showLabel={true}
              optional={true}
              id="practice-name__addpractitioner"
              placeholder={t("prac_reg_pn")}
              value={practiceName}
              inputHandler={(e) => {
                setPracticeName(e.target.value);
              }}
            />
            {practitionerCountry === "US" || practitionerCountry === "CA" ? (
              <TextInput
                label={t("prac_register_country")}
                showLabel={true}
                id="l-country__addpractitioner"
                placeholder={t("prac_reg_country")}
                value={
                  practitionerCountry === "US" ? "United States" : "Canada"
                }
                isDisable={true}
                error={countryError}
                inputHandler={(e) => {
                  setCountry(e.target.value);
                }}
              />
            ) : (
              <PgSelect
                label={t("prac_register_country")}
                showLabel={true}
                required={true}
                optional={false}
                id="country__addpatient"
                setSelectedItem={setCountry}
                selectedItem={country}
                placeholder={t("prac_reg_country")}
                options={countryList}
                error={countryError}
              />
            )}
            {practitionerCountry === "US" || practitionerCountry === "CA" ? (
              <PgSelect
                label={t("state")}
                showLabel={true}
                required={true}
                id="state__addpractitioner"
                setSelectedItem={setUserState}
                selectedItem={userState}
                placeholder={t("prac_reg_state")}
                options={practitionerCountry === "US" ? stateList : caStateList}
                error={stateError ? t("prac_reg_state_req_msg") : ""}
              />
            ) : (
              <TextInput
                label={t("state")}
                showLabel={true}
                optional={true}
                id="state__addpractitioner"
                placeholder={t("prac_reg_state")}
                value={userState}
                inputHandler={(e) => {
                  setUserState(e.target.value);
                }}
              />
            )}
            <TextInput
              label={t("prac_register_phone")}
              showLabel={true}
              id="phone__addpractitioner"
              placeholder={t("prac_reg_phone")}
              value={phone}
              inputHandler={(e) => {
                setPhone(e.target.value);
              }}
            />

            {isCA && (
              <PgSelectNew
                label={t("language")}
                showLabel={true}
                required={false}
                id="default_language"
                placeholder={t("select_language")}
                displayKey="label"
                valueKey="value"
                options={languageOptions}
                error={false}
                setSelectedItem={(value) => setPracticeLanguage(value)}
                selectedItem={practiceLanguage}
              />
            )}

            <button
              className="btn btn-outline px-5 my-3 w-100"
              onClick={() => setShowNewPasswordModal(true)}
            >
              {t("patient_account_txt3")}
            </button>

            {showNewPasswordModal && (
              <SetNewPasswordModal
                showModal={showNewPasswordModal}
                onCancel={() => setShowNewPasswordModal(false)}
                currentPractice={currentPractice}
                currentPracticeID={currentPracticeID}
                currentUserId={currentUserId}
                userType="Practitioner"
              />
            )}
            <button
              className="btn btn-primary text-light px-5 my-3 w-100"
              onClick={onDone}
            >
              {t("patient_account_txt4")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
export default Account;
