export const SHOW_ADD_PATIENT_DIALOG = "SHOW_ADD_PATIENT_DIALOG";
export const HIDE_ADD_PATIENT_DIALOG = "HIDE_ADD_PATIENT_DIALOG";

export const TOGGLE_LOADING = "TOGGLE_LOADING";
export const CHANGE_ROUTE = "CHANGE_ROUTE";
export const TOGGLE_RIALTO = "TOGGLE_RIALTO";
export const UPDATE_RIALTO_STATE = "UPDATE_RIALTO_STATE";

export const toggleLoading = (state) => {
  return { type: TOGGLE_LOADING, payload: state };
};

export const changeRoute = (state) => {
  return { type: TOGGLE_LOADING, payload: state };
};

export const toggleRialtoMode = (state) => {
  return { type: TOGGLE_RIALTO, payload: state };
};

export const updateRialtoState = (state) => {
  return { type: UPDATE_RIALTO_STATE, payload: state };
};




