import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";

function PgSelectSearch({
  options = [],
  objectKey,
  objectKey2,
  label,
  placeholder,
  id,
  required,
  showLabel,
  optional,
  isDisable = false,
  onSelect,
  selectedItem,
  errorMessage,
}) {
  console.log("selected", selectedItem);
  const { t } = useTranslation();
  const [inputText, setInputText] = useState(
    _.isObject(selectedItem) ? selectedItem[objectKey] : selectedItem
  );
  const [optionState, setOptionState] = useState(options);
  const [focused, setFocused] = React.useState(false);
  const [showSuggestion, setShowSuggestion] = useState(false);

  const onFocus = () => setFocused(true);
  const onBlur = () => setShowSuggestion(false);
  let inputRef = useRef(null);

  const onInput = (e) => {
    onSelect({});
    const value = /^[a-zA-Z0-9 _]*$/.test(e.target.value)
      ? e.target.value
      : inputText;
    setInputText(value);
    const regex = new RegExp(`${value}`, `i`);
    setOptionState((os) => {
      return options
        .sort()
        .filter((v) => regex.test(_.isObject(v) ? v[objectKey] : v));
    });
  };

  const __onSelect = (e) => {
    setFocused(false);
    setInputText(_.isObject(e) ? e[objectKey] : e);
    setShowSuggestion(false);
    onSelect(e);
  };
  const style = {
    input: {
      border: errorMessage ? "2px solid #f00" : "3px solid #acd4ff",
    },
    close: {
      position: "absolute",
      right: 10,
      top: 15,
      // fontSize:12,
    },
  };

  useEffect(() => {
    setOptionState(options);
  }, [options]);

  return (
    <div className="pg-ss" id={`${id}-pg-ss`}>
      {showLabel && (
        <div>
          <div className="d-inline-block pg-text__bold textc-primary">
            {label}
          </div>
          {required && (
            <span className="textc-primary fst-italic"> (Required)</span>
          )}
          {optional && !isDisable && (
            <span className="textc-primary fst-italic"> (Optional)</span>
          )}
        </div>
      )}
      <div className="pg-ss__container" id={`${id}-pg-ss__container`}>
        <input
          style={style.input}
          id={`${id}-pg-ss__input`}
          disabled={isDisable}
          autoComplete={"alaban"}
          placeholder={placeholder}
          type="search"
          value={
            _.isObject(selectedItem) ? selectedItem[objectKey] : selectedItem
          }
          className="pg-ss__input"
          type="text"
          onInput={onInput}
          ref={inputRef}
          onFocus={onFocus}
        />
        {(focused || showSuggestion) && (
          <div
            className="cp fas fa-times fs-5"
            style={style.close}
            onClick={() => {
              setShowSuggestion(false);
              setFocused(false);
            }}
          ></div>
        )}
        {(focused || showSuggestion) && (
          <ul className="pg-ss__list" id={`${id}-pg-ss__list`}>
            {optionState.length > 0 ? (
              optionState.map((option) => (
                <li
                  className="pg-ss__list_item"
                  id={`${id}-pg-ss__list_item`}
                  onClick={() => __onSelect(option)}
                >
                  {_.isObject(option) ? (
                    <div>
                      {option[objectKey]}
                      {objectKey2 && <span> | {option[objectKey2]}</span>}
                    </div>
                  ) : (
                    option
                  )}
                </li>
              ))
            ) : (
              <div className="text-center py-2">
                {inputText} {t("not_found")}
              </div>
            )}
          </ul>
        )}
      </div>
      <div
        className="text-danger mx-2 my-1 transition-all"
        style={errorMessage ? { opacity: 1 } : { opacity: 0 }}
      >
        <i className="fas fa-info-circle"></i> {errorMessage}
      </div>
    </div>
  );
}

export default PgSelectSearch;
