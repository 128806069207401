import { createAction } from "redux-api-middleware";

const protocol = process.env.REACT_APP_PROTOCOL;
const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;
const baseUrl = process.env.REACT_APP_PG3_APP_DOMAIN;

export const PATIENT_REPORT_DASHBOARD_REQUEST = "PATIENT_REPORT_DASHBOARD_REQUEST";
export const PATIENT_REPORT_DASHBOARD_SUCCESS = "PATIENT_REPORT_DASHBOARD_SUCCESS";
export const PATIENT_REPORT_DASHBOARD_FAILURE = "PATIENT_REPORT_DASHBOARD_FAILURE";

export const PATIENT_SKIP_QUESTIONNAIRE_REQUEST = "PATIENT_SKIP_QUESTIONNAIRE_REQUEST";
export const PATIENT_SKIP_QUESTIONNAIRE_SUCCESS = "PATIENT_SKIP_QUESTIONNAIRE_SUCCESS";
export const PATIENT_SKIP_QUESTIONNAIRE_FAILURE = "PATIENT_SKIP_QUESTIONNAIRE_FAILURE";

export const PATIENT_PAST_REPORT_REQUEST = "PATIENT_PAST_REPORT_REQUEST";
export const PATIENT_PAST_REPORT_SUCCESS = "PATIENT_PAST_REPORT_SUCCESS";
export const PATIENT_PAST_REPORT_FAILURE = "PATIENT_PAST_REPORT_FAILURE";

export const GET_SCRIPT_STATUS_REQUEST = "GET_SCRIPT_STATUS_REQUEST";
export const GET_SCRIPT_STATUS_SUCCESS = "GET_SCRIPT_STATUS_SUCCESS";
export const GET_SCRIPT_STATUS_FAILURE = "GET_SCRIPT_STATUS_FAILURE";

export const PATIENT_ADMIN_GDPR_REQUEST = "PATIENT_ADMIN_GDPR_REQUEST";
export const PATIENT_ADMIN_GDPR_SUCCESS = "PATIENT_ADMIN_GDPR_SUCCESS";
export const PATIENT_ADMIN_GDPR_FAILURE = "PATIENT_ADMIN_GDPR_FAILURE";

export const getPatientReportDashboardData = (token, currentPracticeID, currentPractice) => {
    return createAction({
        endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/patient/patient_report_data`,
        method: "GET",
        headers: { "Content-Type": "application/json", Authorization: token, 'Current-Practice': currentPracticeID },
        types: [
            PATIENT_REPORT_DASHBOARD_REQUEST,
            PATIENT_REPORT_DASHBOARD_SUCCESS,
            PATIENT_REPORT_DASHBOARD_FAILURE,
        ],
    });
};

export const updatePatientSkipQuestionnaire = (token, currentPracticeID, currentPractice) => {

    const protocol = process.env.REACT_APP_PROTOCOL;
    const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;

    return createAction({
        endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/patient/skip_patient_questionnarie`,
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: token, 'Current-Practice': currentPracticeID },
        types: [
            PATIENT_SKIP_QUESTIONNAIRE_REQUEST,
            PATIENT_SKIP_QUESTIONNAIRE_SUCCESS,
            PATIENT_SKIP_QUESTIONNAIRE_FAILURE,
        ],
    });
};

export const getPatientPastReportData = (token, currentPracticeID, currentPractice, currentUserId) => {
    return createAction({
        endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/patient_past_reports_data/${currentUserId}`,
        method: "GET",
        headers: { "Content-Type": "application/json", Authorization: token, 'Current-Practice': currentPracticeID },
        types: [
            PATIENT_PAST_REPORT_REQUEST,
            PATIENT_PAST_REPORT_SUCCESS,
            PATIENT_PAST_REPORT_FAILURE,
        ],
    });
};

export const closeWhatsNew = (token, currentPracticeID, currentPractice) => {

    const protocol = process.env.REACT_APP_PROTOCOL;
    const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;

    return createAction({
        endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/patient/hide_whats_new_text`,
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: token, 'Current-Practice': currentPracticeID },
        types: [
            PATIENT_REPORT_DASHBOARD_REQUEST,
            PATIENT_REPORT_DASHBOARD_REQUEST,
            PATIENT_REPORT_DASHBOARD_REQUEST
        ],
    });
};

export const getPatientScriptStatus = (
    patientId,
    profileId,
    tenantId,
    appToken
) => {
    const RIALTO_BASE_URL = process.env.REACT_APP_RIALTO_BASE_URL;

    return createAction({
        endpoint: `${RIALTO_BASE_URL}/rialto/api/patient_has_script?patient_id=${patientId}&profile_id=&tenant_id=${tenantId}&rialto_token=${appToken}`,
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        types: [
            GET_SCRIPT_STATUS_REQUEST,
            GET_SCRIPT_STATUS_SUCCESS,
            GET_SCRIPT_STATUS_FAILURE,
        ],
    });
};


export const gdprManageDataRequest = (token, currentPracticeID, currentPractice, requestType) => {
    const body = JSON.stringify({ request_type: requestType });
    return createAction({
        endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/patient/gdpr_patient_request`,
        method: "POST",
        body,
        headers: { "Content-Type": "application/json", Authorization: token, 'Current-Practice': currentPracticeID },
        types: [
            PATIENT_REPORT_DASHBOARD_REQUEST,
            PATIENT_REPORT_DASHBOARD_REQUEST,
            PATIENT_REPORT_DASHBOARD_REQUEST
        ],
    });
}

export const getPatientGDPRRequestLog = (pID, token, currentPracticeID, currentPractice) => {

    return pID
        ? createAction({
            endpoint: `${baseUrl}/admin/patient_gdpr_list?id=${pID}`,
            method: "GET",
            // body: JSON.stringify({ patient_id: pID }),
            headers: { "Content-Type": "application/json", Authorization: token, },
            types: [
                PATIENT_REPORT_DASHBOARD_REQUEST,
                PATIENT_REPORT_DASHBOARD_REQUEST,
                PATIENT_REPORT_DASHBOARD_REQUEST
            ],
        })
        : createAction({
            endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/patient/patient_gdpr_list`,
            method: "POST",
            headers: { "Content-Type": "application/json", Authorization: token, 'Current-Practice': currentPracticeID },
            types: [
                PATIENT_REPORT_DASHBOARD_REQUEST,
                PATIENT_REPORT_DASHBOARD_REQUEST,
                PATIENT_REPORT_DASHBOARD_REQUEST
            ],
        });
}

export const getPractitionerMadeRequestStatus = (token, currentPracticeID, currentPractice) => {
    return createAction({
        endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/patient/practitioner_request_status`,
        method: "GET",
        headers: { "Content-Type": "application/json", Authorization: token, 'Current-Practice': currentPracticeID },
        types: [
            PATIENT_REPORT_DASHBOARD_REQUEST,
            PATIENT_REPORT_DASHBOARD_REQUEST,
            PATIENT_REPORT_DASHBOARD_REQUEST
        ],
    });
}

export const getAdminMadeRequestStatus = (token, currentPracticeID, currentPractice) => {
    return createAction({
        endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/admin_request_status?user_type=patient`,
        method: "GET",
        headers: { "Content-Type": "application/json", Authorization: token, 'Current-Practice': currentPracticeID },
        types: [
            PATIENT_ADMIN_GDPR_REQUEST,
            PATIENT_ADMIN_GDPR_SUCCESS,
            PATIENT_ADMIN_GDPR_FAILURE
        ],
    });
}


export const setPractitionerMadeRequestStatus = (token, currentPracticeID, currentPractice, pld, accept) => {
    const body = JSON.stringify(pld);
    return createAction({
        endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/gdpr/patient/${accept ? 'approve' : 'reject'}/user_request`,
        method: "POST",
        body,
        headers: { "Content-Type": "application/json", Authorization: token, 'Current-Practice': currentPracticeID },
        types: [
            PATIENT_REPORT_DASHBOARD_REQUEST,
            PATIENT_REPORT_DASHBOARD_REQUEST,
            PATIENT_REPORT_DASHBOARD_REQUEST
        ],
    });
}