import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const MultiRadioSelection = ({ question, onInput }) => {
  const { t } = useTranslation();
  const choiceWrapper = question.sub_questions || [];
  const choiceGuide = question?.sub_questions?.[0]?.choices || [
    "Never",
    "",
    "Occasionally",
    "",
    "Frequently",
  ];
  const [selectedChoices, setselectedChoices] = useState({});

  const answers = useSelector((state) => state.pg4.q_answers);
  const errors = useSelector((state) => state.pg4.q_errors);

  useEffect(() => {
    console.log(selectedChoices, "sele");
  }, [selectedChoices]);

  return (
    <div className="pg4-card">
      <div className="pg4-text__subheading textc-primary__p">
        {t(question?.question_header)}
      </div>
      <div className="my-2 mb-5">
        <div className="pg4-text"></div>
        <div className="d-flex my-2">
          {choiceGuide?.map((choice, idx) => (
            <div className="d-flex flex-column align-items-center me-1">
              <span className="msq-multi_radio my-2 disabled" disabled>
                {idx}
              </span>
              <div className="text-center text-muted pg4-text__xs px-0 px-xl-4 fw-bold text-capitalize">
                {t(choice)}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="pg4-text">{question?.placeholder_en}</div>

      {choiceWrapper.map((choiceContainer, index) => (
        <div
          className="my-2 row justify-content-lg-between my-3"
          id={choiceContainer.question_id}
        >
          <div className="col-12 col-md-4 col-lg-5 pg-text fw-bold  my-4 my-lg-auto">
            {t(choiceContainer?.question_text)}
          </div>
          <div className="col-12 col-md-8 col-lg-6 d-flex justify-content-between align-items-center">
            {_.isArray(choiceContainer?.choices) &&
              choiceContainer?.choices.map((choice, cindex) => (
                <div
                  key={choice + index}
                  className={`cursor-pointer my-lg-2 msq-multi_radio ${
                    answers?.[choiceContainer.question_id]?.answer[0] === choice
                      ? "msq-radio__selected"
                      : ""
                  }`}
                  onClick={() =>
                    onInput({
                      [choiceContainer.question_id]: {
                        question_id: choiceContainer.question_id,
                        answer: [choice],
                      },
                    })
                  }
                >
                  <span className="my-auto">{cindex}</span>
                </div>
              ))}
          </div>
          <div
            className="col-12 text-danger transition-all mt-2 "
            style={{ opacity: errors[choiceContainer?.question_id] ? 1 : 0 }}
          >
            <i className="fas fa-info-circle"></i> {t("pracand_genetic_error")}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MultiRadioSelection;
