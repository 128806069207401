import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";

const ExampleRow = ({}) => {
    const { t, i18n } = useTranslation();
    return(
        <div className="row">
            <div className="col-3 my-auto">
                <TextInput
                    name="result"
                    value={t("lab_test_name_serumfastingglucose")}
                    isDisable="true"
                />
        </div>
        <div className="col-3 pe-lg-5">
            <TextInput
                name="result"
                value="90"
                isDisable="true"
            />
        </div>
        <div className="col-3 pe-lg-5">
        <TextInput
            name="result"
            value="mg/dL"
            isDisable="true"
        />
        </div>
            <div className="col-3 d-flex justify-content-between">
                <div>    
                    <TextInput
                        name="result"
                        value="65-99"
                        isDisable="true"
                    />
                </div>
                <div className="align-self-center">
                    <span className="fw-bold"> {t("txt_example")} </span>
                </div>
            </div>
        </div>
    )
}

export default ExampleRow;

function TextInput({
    type,
    name,
    inputHandler,
    placeholder,
    error,
    errorMessage,
    value,
    isDisable,
}) {
    const style = {
        padding: 15,
        width: "100%",
        outline: "none",
        border: error ? "1.2px solid #f00" : "1px solid #888888",
        borderRadius: "8px",
        height: 50,
        transition: "all .3s ease-in-out",
    };

    const disabledStyle = {
        backgroundColor: "#e9ecef",
        opacity: 1,
        border: "1px solid #ced4da",
        padding: 15,
        width: "100%",
        outline: "none",
        borderRadius: "8px",
        height: 50,
        transition: "all .3s ease-in-out",
    };

    return (
        <div className="w-90">
            <input
                placeholder={placeholder}
                name={name}
                type={type}
                onInput={inputHandler}
                defaultValue={value}
                style={isDisable? disabledStyle : style}
                disabled={isDisable}
            />
            {/* <div
          className="text-danger mx-2 my-1 transition-all"
          style={error ? { opacity: 1 } : { opacity: 0 }}
        >
          <i className="fas fa-info-circle"></i> {errorMessage}
        </div> */}
        </div>
    );
}