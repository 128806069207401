import { useEffect, useRef, useState } from "react";
import { Reorder, useDragControls } from "framer-motion";
import { RecommendationItems } from "./RecommendationItems";
import AddRecommendation from "./AddRecommendation";
import NoteForPatient from "./NoteForPatient";
import RecommendationCardHeader from "./RecommendationCardHeader";
import { useTranslation } from "react-i18next";

function DietRecommendation({
  focusAreas,
  data,
  onSavePatientNotes,
  onDeleteRecommendation,
  onReorderRecommendation,
  onClickShowAddRecommendation,
}) {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const containerRef = useRef(null);
  const shadowRef = useRef(null);
  const dragControls = useDragControls();
  const [recommendations, setRecommendations] = useState(
    data?.recommendations || []
  );

  useEffect(() => {
    setRecommendations(data?.recommendations || []);
    setSelectedItem([]);
  }, [data]);

  const handleScroll = () => {
    const container = containerRef.current;
    const shadow = shadowRef.current;
    if (container.scrollHeight > container.clientHeight) {
      const isScrolledToBottom =
        container.scrollHeight - container.scrollTop === container.clientHeight;
      shadow.style.opacity = isScrolledToBottom ? 0 : 1;
    } else {
      shadow.style.opacity = 0;
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    container.addEventListener("scroll", handleScroll);
    handleScroll(); // Check scroll position initially
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const onReorderItems = (newOrder) => {
    setRecommendations(newOrder);
  };
  const onReorderEnd = (e) => {
    onReorderRecommendation({
      recommendation_category: "diet",
      recommendation_ids: recommendations.map((x) => x.recommendation_id),
    });
  };

  return (
    <div className="pg4-card rounded p-3 py-3 position-relative">
      <RecommendationCardHeader
        //  context ="Diet" // default
        focusAreas={focusAreas}
        is_shared={data?.is_shared}
        recommendationLength={recommendations?.length}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        selectedItemLength={selectedItem?.length}
        setSelectedItem={setSelectedItem}
        onDeleteRecommendation={() =>
          onDeleteRecommendation(selectedItem, "diet")
        }
        title={t("diet")}
        isEditable={recommendations?.length > 0 && !data?.is_shared}
      />

      <b className="pg4-text text_primary">{t("recommendations")}</b>
      <Reorder.Group
        layoutScroll
        axis="y"
        onReorder={(ordered) => onReorderItems(ordered)}
        values={recommendations}
        ref={containerRef}
        className="r-item__container"
      >
        {recommendations?.map((item, idx) => (
          <RecommendationItems
            index={idx}
            key={item.recommendation_id}
            item={item}
            focusAreas={focusAreas}
            data={item}
            isEditing={isEditing}
            dragControls={dragControls}
            selectedItem={selectedItem}
            onSelect={setSelectedItem}
            onDragEnd={onReorderEnd}
            onReorder={onReorderItems}
            recommendations={recommendations}
          />
        ))}
      </Reorder.Group>
      <div ref={shadowRef} className="scroll-shadow"></div>

      {!data?.is_shared && (
        <button
          style={{ marginTop: -20 }}
          className="pg4-btn position-relative pg4-btn_outline  w-100 text-secondary border-secondary"
          onClick={() => onClickShowAddRecommendation("diet")}
        >
          + {t("pg4_report_txt_15")}
        </button>
      )}
      <NoteForPatient
        onSavePatientNotes={(notes) => onSavePatientNotes({ diet: notes })}
        notes={data?.notes}
        is_shared={data?.is_shared}
      />
    </div>
  );
}

export default DietRecommendation;
