import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function MaintainanceBanner({
  showModal,
  onCancel,
  startTime,
  endTime
}) {
  const { t, i18n } = useTranslation();
  return (
    <Modal
      show={showModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      contentClassName="border-radius"
      backdrop="static"
      className="edit-patient-modal"
      align="center"
      centered
      style={{ padding: "25px" }}
    >
      <Modal.Body>
        <div className="banner-text d-lg-block">
          <p>
          {t('maintenance_banner_content')} {startTime} to {endTime}.
          </p>
          <p>
          {t('maintenance_banner_content_2')} <span style={{ textAlign: "justify", wordBreak: "break-word" }}>puregenomics@pureencapsulations.com</span>
          </p>
          <div className="text-center">
            <button
              type="button"
              className="btn btn-primary agree_btn_style"
              onClick={() => onCancel()}
            >
              {t('ok')}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default MaintainanceBanner;

