import * as pws_constant from "../actions/patientWelcome.js";

const initialState = {
    loading: true,
    error: false,
    genetic_upload_by: "",
    invite_questionnaire: false,
    lab_upload_by: "",
    patient_name: null,
    practice_name: null,
    questionnaire_data: null,
};


const patientWelcomeScreenReducer = (state = initialState, action) => {
    switch (action.type) {
        case pws_constant.PATIENT_WS_DATA_REQUEST: {
            return { ...state, loading: true };
        }
        case pws_constant.PATIENT_WS_DATA_SUCCESS: {
            return { ...state, loading: false, ...action.payload.dashboard_data};
        }
        case pws_constant.PATIENT_WS_DATA_FAILURE: {
            return { ...state, loading: false, error: true, };
        }
        default:
            return state;
    }
};

export default patientWelcomeScreenReducer;
