import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import psListReducer from "./psList";
import authReducer from "./auth";
import uiReducer from "./uiReducers";
import patientListReducer from "./patientList";
import practiceDashboardReducer from "./practiceDashboard";
import addPatientReducer from "./addPatient";
import geneticUploadReducer from "./genetics";
import patientWelcomeScreenReducer from "./patientWelcome";
import patientReport from "./patientReport";
import labUploadReducer from "./labUpload";
import snpUploadReducer from "./snpUpload";
import traitInfoReducer from "./trait";
import patientDashboardScreenReducer from './patientDashboard'
import QuestionReducer from './question'
import adminPractitioner from "./adminPractitioner";
import adminPatient from "./adminPatient";
import adminUser from "./adminUser";
import rialtoManagement from "./rialtoManagement"
import practitionerAccount from "./practitionerAccount"
import patientAccount from "./patientAccount"
import pg4Features from "./pg4dashboard"
import pg4Report from "./pg4report"

const reducer = (history) => combineReducers({
  router: connectRouter(history),
  auth: authReducer,
  ui: uiReducer,
  patientList: patientListReducer,
  practiceDashboard: practiceDashboardReducer,
  addPatient: addPatientReducer,
  genetics: geneticUploadReducer,
  welcomeScreen: patientWelcomeScreenReducer,
  patientReport: patientReport,
  labUpload: labUploadReducer,
  snpUpload: snpUploadReducer,
  trait: traitInfoReducer,
  question: QuestionReducer,
  patientDashboard: patientDashboardScreenReducer,
  adminPractitioner: adminPractitioner,
  adminPatient: adminPatient,
  adminUser: adminUser,
  rialtoManagement: rialtoManagement,
  practitionerAccount: practitionerAccount,
  patientAccount: patientAccount,
  psList: psListReducer,
  pg4: pg4Features,
  pg4Report: pg4Report
});

export default reducer;
