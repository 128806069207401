import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const ScrollToTopFAB = () => {
  const { t } = useTranslation();
  const [showButton, setshowButton] = useState(false);
  useEffect(() => {
    let options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    };
    console.log("dom loadd");
    let observer = new IntersectionObserver((option1, option2) => {
      console.log("viewed", option1[0]?.isIntersecting);
      if (!option1[0]?.isIntersecting) {
        setshowButton(true);
      } else setshowButton(false);
    }, options);

    let target = document.querySelector("#stt-ref");
    observer.observe(target);
  }, []);

  const scrollTotop = useCallback(() => {
    window.scroll({ behavior: "smooth", top: 0 });
  }, []);
  return (
    <>
      {showButton && (
        <div className="stt-fab d-flex flex-column" onClick={scrollTotop}>
          <i className="fas fa-arrow-up mb-1"></i>
          <span dangerouslySetInnerHTML={{ __html: t("stt_fab") }} />
        </div>
      )}
    </>
  );
};

export default ScrollToTopFAB;
