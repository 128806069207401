import {Modal} from "react-bootstrap"
import ReviewLabResult from "./ReviewLabResult"

function LabReviewModal({ showModal, onCancel, modalData }){
  return (
    <Modal
    show={showModal}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    onHide={onCancel}
    contentClassName="border-radius p-2"
    className="lab-review-modal"
    >
    <div 
      style={{
                position: 'absolute',
                right: 25,
                top: 15,
                fontSize: 20,
                cursor: "pointer",
                color: 'var(--pg_primary)'
              }} 
      onClick={onCancel}
    >
      <div className="fas fa-times"></div>
    </div>
      <ReviewLabResult context="modal" modalData={modalData}/>
    </Modal>
  )
}

export default LabReviewModal