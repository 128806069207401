import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import Popup from 'reactjs-popup'

function PgSelectTooltip({
  setSelectedItem,
  selectedItem,
  placeholder,
  options = [],
  error, id, label, required, showLabel, tooltipText, practiceAddPatient
}) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const default_class = practiceAddPatient? "my-2": "my-4"
  return (
    <div className={default_class}>
      {
        showLabel &&
        <div>

          <div className="d-inline-block pg-text__bold textc-primary">{label}</div> {required && <span className="textc-primary fst-italic">(Required)</span>}

        </div>

      }
      <div className="cursor-pointer">
        <Dropdown
          className="d-inline-block position-relative w-100"
          onToggle={() => setIsPopupOpen(!isPopupOpen)}
          id={id}
        >
          <Dropdown.Toggle
            as="div"
            bsPrefix="border-radius py-auto pg-text d-flex justify-content-between"
            style={{
              border: error ? "2px solid #f00" : "3px solid #acd4ff",
              borderRadius: "8px",
              height: 50,
              padding: "6px 10px",
              width: "100%",
            }}
          >
            <span
              id="selected-item"
              className="my-auto"
              style={{ color: selectedItem ? "#000" : "grey" }}
            >
              {selectedItem || placeholder}
            </span>{" "}
            <i
              className="fa fa-chevron-down my-auto transition-all fs-4"
              aria-hidden="true"
              style={{
                transform: isPopupOpen ? "rotate(-180deg)" : "rotate(0deg)",
              }}
            ></i>
          </Dropdown.Toggle>
          <Dropdown.Menu
            bsPrefix="dropdown-menu w-100 border-radius"
            style={{
              borderRadius: 8,
              background: "white",
              zIndex: 9999,
              opacity: 1,
              maxHeight: "300px",
              overflowY: "auto"
            }}
          >
            {options.map((option, i) => {
              return (
                <Dropdown.Item
                  eventKey="1"
                  onClick={() => setSelectedItem(option)}
                  key={i}
                  className="pg-text text-dark"
                  id={`select-option__${option}`}
                >
                  {option}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
        <Popup
          on='hover'
          trigger={(open) => (
            <i className="fa fa-question-circle select-tooltip" aria-hidden="true"></i>
          )}
          position="top center"
          closeOnDocumentClick
        >
          <div className="p-" style={{ padding: '12px' }}>
            {tooltipText}
          </div>
        </Popup>
        <div
          className="text-danger mx-2 my-1 transition-all"
          style={error ? { opacity: 1 } : { opacity: 0 }}
        >
          <i className="fas fa-info-circle"></i> {error}
        </div>
      </div>
    </div>
  );
  //   </div>;
}

export default PgSelectTooltip;
