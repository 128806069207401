import ContentLoader from "react-content-loader";
function L_PatientActivityTile() {
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height="20%"
      viewBox="0 0 600 710"
      backgroundColor="#9e9e9e52"
      foregroundColor="#18368f80"
    >
      {/* trait header */}
      <rect x="180" y="5" rx="3" ry="3" width="180" height="50" />

      <rect x="0" y="80" rx="3" ry="3" width="180" height="20" />

      <rect x="0" y="140" rx="3" ry="3" width="320" height="15" />
      <rect x="0" y="190" rx="3" ry="3" width="320" height="15" />
      <rect x="0" y="240" rx="3" ry="3" width="320" height="15" />

      <rect x="500" y="140" rx="3" ry="3" width="100" height="15" />
      <rect x="500" y="190" rx="3" ry="3" width="100" height="15" />
      <rect x="500" y="240" rx="3" ry="3" width="100" height="15" />

      <rect x="0" y="300" rx="3" ry="3" width="160" height="20" />

      <rect x="160" y="350" rx="3" ry="3" width="60" height="20" />
      <rect x="160" y="400" rx="3" ry="3" width="60" height="20" />
      <rect x="160" y="450" rx="3" ry="3" width="60" height="20" />
      <rect x="160" y="500" rx="3" ry="3" width="60" height="20" />

      <rect x="260" y="350" rx="3" ry="3" width="260" height="20" />
      <rect x="260" y="400" rx="3" ry="3" width="260" height="20" />
      <rect x="260" y="450" rx="3" ry="3" width="260" height="20" />
      <rect x="260" y="500" rx="3" ry="3" width="260" height="20" />


      {/* <rect x="180" y="580" rx="3" ry="3" width="230" height="50" />
      <rect x="180" y="650" rx="3" ry="3" width="230" height="50" /> */}

    </ContentLoader>
  );
}

export default L_PatientActivityTile;
