import React from "react";
import { useTranslation } from "react-i18next";

function StaffActionButtons({
    context = "staff",
    disableEdit, disableReset, disableArchive, disableRestore,  
    onEdit, onReset, onArchive, onRestore, normalAdmin, disableRegistrationReminder, onRegistrationReminder
}) {
    const { t, i18n } = useTranslation();
    const style = {
        batchFnBtnActive: {
            // color: "red", cursor: 'pointer'
            // filter:"brightness(1.2)"
        },
        batchFnBtnDisabled: {
            color: "var(--pg_primary)", cursor: 'not-allowed',opacity:0.6
        }
    }
    return (
        <div className="d-flex justify-content-between ">
            <div className="d-flex justify-content-evenly" >
                <div className="mx-2 pg-link batch-fn-btn"
                    onClick={(disableEdit || normalAdmin) ? undefined : onEdit}
                    style={(disableEdit || normalAdmin)? style.batchFnBtnDisabled : style.batchFnBtnActive}>
                    <i className="mx-1 fas fa-pen"></i>{t('staff_members_action_btn_1')}
                </div>
                <div className="mx-2 pg-link"
                    onClick={disableReset ? undefined : onReset}
                    style={disableReset ? style.batchFnBtnDisabled : style.batchFnBtnActive}>
                    <i className="mx-1 fas fa-lock"></i>{t('staff_members_action_btn_2')}
                </div>
                <div
                    onClick={disableArchive ? undefined : onArchive}
                    className="mx-2 pg-link" style={(disableArchive || normalAdmin) ? style.batchFnBtnDisabled : style.batchFnBtnActive}>
                    <i className="mx-1 fas fa-archive"></i>{t('staff_members_action_btn_3')} 
                </div>
                <div
                    onClick={disableRestore ? undefined : onRestore}
                    className="mx-2 pg-link" style={(disableRestore || normalAdmin)? style.batchFnBtnDisabled : style.batchFnBtnActive}>
                    <i className="mx-1 fas fa-trash-restore"></i>{t('staff_members_action_btn_4')}
                </div>
                <div className="mx-2 pg-link"
                  onClick={disableRegistrationReminder ? undefined : onRegistrationReminder}
                  style={disableRegistrationReminder ? style.batchFnBtnDisabled : style.batchFnBtnActive}>
                  <i className="mx-1 fas fa-bell"></i> {t('staff_members_action_btn_5')}
                </div>
      </div>
    </div>
  );
}

export default StaffActionButtons;
