import ContentLoader from "react-content-loader";
function L_MSQProgressLoader(props) {
    return (
        <ContentLoader
            speed={2}
            width="100%"
            height="20%"
            viewBox="0 0 600 40"
            backgroundColor="#f5f5f5"
            foregroundColor="#2470c210"
            {...props}
        >
            {/* trait header */}
            <rect x="0" y="5" rx="3" ry="3" width="60" height="10" />
            <rect x="0" y="25" rx="3" ry="3" width="600" height="10" />
        </ContentLoader>
    );
}

export default L_MSQProgressLoader;
