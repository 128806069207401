import PgAccordion from "../PgAccordion";
import { useNavigate } from "react-router-dom";


function UKPatientFAQ({ history }) {
  const navigate = useNavigate();



  return (
    <div className="py-5">
      <div className="container-xxl">
        <div className="pgs-title my-5 textc-primary__p">
          PureGenomics<sup>&reg;</sup> FAQs
        </div>

        {/* section 1*/}
        <div className="pgs-heading mt-5 mb-2 textc-primary__p">
          Test Kits & Raw Data
        </div>
        <PgAccordion
          userType="Patient"
          question="What genetic testing labs are compatible with PureGenomics<sup>&reg;</sup>?"
        >
          <div>
            PureGenomics<sup>&reg;</sup> <span className="fw-bold">only</span>{" "}
            interprets genetic data from 23andMe<sup>&reg;</sup>, Ancestry<sup>&reg;</sup>, or Nordic Laboratories.
          </div>
        </PgAccordion>
        {/* 23andMe®, Ancestry®, or Nordic Laboratories… */}
        <PgAccordion
          userType="Patient"
          question="I noticed that 23andMe<sup>&reg;</sup>, Ancestry<sup>&reg;</sup>, or Nordic Laboratories offer different DNA kits you can purchase. Is there a particular DNA kit that I should buy if I am interested in using your platform? "
        >
          <div>
            {" "}
            Any DNA kit sold by either 23andMe<sup>&reg;</sup>, Ancestry<sup>&reg;</sup>, or Nordic Laboratories is compatible with PureGenomics<sup>&reg;</sup>.
            The kits are typically available for less than £100.
          </div>
        </PgAccordion>

        <PgAccordion
          userType="Patient"
          question="How long after I complete the 23andMe<sup>&reg;</sup>, Ancestry<sup>&reg;</sup>, or Nordic Laboratories genetic test will I get my results?"
          answer="Both companies state that a period of 6-8 weeks can be expected. When the results are ready, you will be able to log in and download your genetic raw data file"
        />
        <PgAccordion
          userType="Patient"
          question="Which testing companies’ genetic raw data file provides the most SNPs on your PureGenomics<sup>&reg;</sup> report? "
        >
          <div>
            <div className="pgs-text">
            23andMe<sup>&reg;</sup>, Ancestry<sup>&reg;</sup>, or Nordic Laboratories use a
              “genotyping chip” to detect patients’ DNA data. The reference SNP
              cluster IDs, also known as rs numbers, are included on the raw
              data is dependent on which chip was used. These companies commonly
              update their chip versions for a variety of reasons, which can
              impact the raw data available for further analysis. Below is a
              chart that outlines the differences in the most common chip
              versions used in PureGenomics<sup>&reg;</sup>. (The “X” indicates
              that the SNP is included in that chip version).
            </div>
            <div className="overflow-x-scroll overflow-x-lg-hidden">
              <table className="pg-table my-3 text-center fw-bold">
                <thead>
                  <tr>
                    <td rowSpan="2" className="text-start">
                      SNP Tested
                    </td>
                    <td colSpan="2">23andMe</td>
                    <td>Ancestry</td>
                  </tr>
                  <tr>
                    <td>Version 4</td>
                    <td>Version 5</td>
                    <td>Version 2</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-start">EDN1 (rs5370)</td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td></td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-start">GATA3 (rs4143094</td>
                    <td></td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-start">AOC1 (rs10156191)</td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td></td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-start">BCMO1 (rs12934922)</td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td></td>
                  </tr>

                  <tr>
                    <td className="text-start">TCN2 (rs1801198)</td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td></td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-start">SLC23A1 (rs33972313)</td>
                    <td></td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td></td>
                  </tr>

                  <tr>
                    <td className="text-start">DHCR7 (rs12785878)</td>
                    <td></td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-start">CYP2R1 (rs10741657)</td>
                    <td></td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-start">GPx1P1 (rs1050450)</td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>

                  <tr>
                    <td className="text-start">DRD2 (rs6277)</td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </PgAccordion>
        {/* section 2 */}
        <div className="pgs-heading mt-5 mb-2 textc-primary__p">
          Using PureGenomics<sup>&reg;</sup>
        </div>
        <PgAccordion
          userType="Patient"
          question="What is the cost to use PureGenomics<sup>&reg;</sup>?"
        >
          PureGenomics<sup>&reg;</sup> is complimentary to use! We believe in
          better health for all, that's why you can benefit from all that
          PureGenomics<sup>&reg;</sup> has to offer at no cost.
        </PgAccordion>

        <PgAccordion
          userType="Patient"
          question="Why do you offer this complimentary?"
          answer="Our company supports the advancement of Functional Medicine by providing technology, clinical protocols, and evidence-based products to best serve practitioners to advance care and offer personalisation."
        />
        <PgAccordion
          userType="Patient"
          question="I would like to use your service to analyze my raw data. How do I sign up?"
          answer="Patients must be invited by their healthcare provider to create an account. Please reach out to your healthcare provider regarding setting up a free account with us."
        />
        <PgAccordion
          userType="Patient"
          question="Can my provider upload my raw data file for me?"
          answer="Yes, your provider can upload your raw data file for you. You will need to email them the data file that you received from your testing company. This file will be in the form of a zip or text file, PDFs are not compatible with our system."
        />
        <PgAccordion
          userType="Patient"
          question="Do my genetic results from other genetic testing labs work with PureGenomics<sup>&reg;</sup>?"
        >
          <div>
            PureGenomics<sup>&reg;</sup> is only compatible with 23andMe<sup>&reg;</sup>, Ancestry<sup>&reg;</sup>, or Nordic Laboratories.
          </div>
        </PgAccordion>

        <PgAccordion
          userType="Patient"
          question="Can I order 23andMe<sup>&reg;</sup>, Ancestry<sup>&reg;</sup>, or Nordic Laboratories test kits through PureGenomics<sup>&reg;</sup>?"
        >
          <div>
            {" "}
            No. You must order the kits directly through {" "}
            <a
              href="https://www.23andme.com/en-gb/"
              target="_blank"
              rel="noreferrer"
            >
              23andme.com/en-gb/
            </a>, or
            {" "}
            <a
              href="http://ancestry.co.uk/"
              target="_blank"
              rel="noreferrer"
            >
              Ancestry.co.uk
            </a>{" "} or other authorized retail stores. Please note that
            Pure Encapsulations<sup>&reg;</sup> is not affiliated with or
            endorsed by 23andMe<sup>&reg;</sup>, Ancestry<sup>&reg;</sup>, or Nordic Laboratories.
          </div>
        </PgAccordion>

        <PgAccordion
          userType="Patient"
          question="How do I download my test data from 23andMe<sup>&reg;</sup>, Ancestry<sup>&reg;</sup>, or Nordic Laboratories, and how do I upload this data to PureGenomics<sup>&reg;</sup>?"
        >
          <span className="text-decoration-underline fw-bold">
            Through 23andMe
          </span>
          <sup>&reg;</sup>:
          <div>
            Once logged into their PureGenomics<sup>&reg;</sup> account, the
            patient will need to click the{" "}
            <a
              href="https://you.23andme.com/tools/data/download/"
              target="_blank"
              rel="noreferrer"
            >
              23andMe
            </a>
            <sup>&reg;</sup> link to log into their account. Once you log into
            23andMe
            <sup>&reg;</sup>, directions are provided on this page for you to
            download your genetic raw data file.
          </div>
          <br />
          <div>
            At the bottom of this page, you need to check a box acknowledging
            you are downloading sensitive genetic data from a secure server, and
            then click submit request.
          </div>
          <br />
          <div>
            23andMe<sup>&reg;</sup> requires a 2-step authentication process.
            You will receive an email from 23andMe<sup>&reg;</sup> when your raw
            data download is ready. Click on the email’s call to action at the
            bottom of the email. This will take you back to your 23andMe
            <sup>&reg;</sup> account where you can download your raw data zip
            file and save it to your computer.
          </div>
          <br />
          <div>
            Lastly, go back into your PureGenomics<sup>&reg;</sup> patient
            dashboard and click <b>Upload Your Data</b>, select your file from the saved
            location, and click Start Upload.
          </div>
          <br />
          <div>
            Once your data is uploaded, you will be able to see your patient
            report. If you haven’t already, go ahead and schedule an appointment
            to review your report with your healthcare provider. To read more
            directions from 23andMe<sup>&reg;</sup> about downloading your data
            click{" "}
            <a
              href="https://customercare.23andme.com/hc/en-us/articles/212196868-Accessing-Your-Raw-Genetic-Data"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
          </div>
          <br />
          <span className="text-decoration-underline fw-bold">
            Through Ancestry
          </span>
          <sup>&reg;</sup>:
          <br />
          <div>
            Once logged in to your PureGenomics<sup>&reg;</sup> account, you
            will click the{" "}
            <a
              href="https://www.ancestry.co.uk/account/signin/?returnUrl=https%3A%2F%2Fwww.ancestry.co.uk%2Faccount%2Fdata%2F"
              target="_blank"
              rel="noreferrer"
            >
              Ancestry.co.uk
            </a>{" "}
            link to log into your account. Once you log in, directions are
            provided for you to download your genetic raw data file
          </div>
          <br />
          <div>
            <a
              href="http://ancestry.co.uk/"
              target="_blank"
              rel="noreferrer"
            >
              Ancestry.co.uk
            </a>{" "} requires a 2-step authentication process. You will
            receive an email from {" "}
            <a
              href="http://ancestry.co.uk/"
              target="_blank"
              rel="noreferrer"
            >
              Ancestry.co.uk
            </a>{" "} when your raw data download is
            ready. Click on the email’s call to action{" "}
            <span className="fw-bold">Confirm Data Download</span> and save the
            zip file to a location on your computer.
          </div>
          <br />
          <div>
            Lastly, go back into your PureGenomics<sup>&reg;</sup> patient
            dashboard and click <b>Upload Your Data</b>, select your file from the saved
            location, and click Start Upload
          </div>
          <br />
          <div>
            Once your data is uploaded, you will be able to see your patient
            report. If you haven’t already, go ahead and schedule an appointment
            to review your report with your healthcare provider. For more
            download directions from {" "}
            <a
              href="http://ancestry.co.uk/"
              target="_blank"
              rel="noreferrer"
            >
              Ancestry.co.uk
            </a>{" "} click{" "}
            <a
              href="https://support.ancestry.co.uk/s/article/Downloading-AncestryDNA-Raw-Data"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
            .
          </div>
          <br />
        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="How long does it take to have the genetic data interpreted by PureGenomics<sup>&reg;</sup>?"
          answer="Once your data is uploaded, you will be able to see your patient report in your dashboard. If you haven't already, please schedule a consultation with your healthcare provider to review your report. "
        />
        <PgAccordion
          userType="Patient"
          question="What does a PureGenomics<sup>&reg;</sup> report look like? "
        >
          <div>
            PureGenomics<sup>&reg;</sup> offers two ways to view your report; an
            interactive report organized by trait category or available as a
            printable summary report that consolidates all the results into one
            place.{" "}
            <span className="fw-bold">
              <a
                href="https://pg3-abstract.s3.amazonaws.com/abstract/PureGenomics_UK_Sample+Patient+Report.pdf"
                download
              >
                View sample report.
              </a>
            </span>
          </div>
        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="How do I save my report to my computer? "
          answer="To save the report to your computer, click on the View and Print Report button at the top of the page. You will see a download button on the next screen. "
        />
        <PgAccordion
          userType="Patient"
          question="I just received my report. How do I send it to my doctor? "
          answer="As soon as your data is uploaded, your doctor is automatically notified, and the report is instantly made available for their review. Please contact your healthcare provider to schedule an appointment to review your report. "
        />
        <PgAccordion
          userType="Patient"
          question="How does PureGenomics<sup>&reg;</sup> deepen personalisation?  "
        >
          <div>
            A nutrigenomics report is not a stand-alone test. You cannot prescribe or diagnose from a nutrigenomics report. Providers need to understand objectively whether SNPs are being expressed in an individual – and that is done through understanding the patient’s environment (questionnaire) and lab values (lab results entry).
          </div>
        </PgAccordion>

        <PgAccordion
          userType="Patient"
          question="Why does my provider want me to complete a Smart Questionnaire? "
        >
          <div>
            The Smart Questionnaire makes possible more personalised lifestyle
            and dietary recommendations. This is an optional, short and engaging
            questionnaire that takes 5-7 minutes for you to complete. The
            questionnaire provides your provider with more specific information
            about how your environment is affecting your genetic expression. The
            smart questionnaire in combination with your genetic data provides a
            more comprehensive personalised plan.
          </div>
          <br />
          <div>
            Also, responses to the Smart Questionnaire allow for more refinement
            of your diet and lifestyle recommendations. For example, the smart
            questionnaire takes into consideration your food allergies, habits
            (such as caffeine consumption), preferences, and goals.
          </div>
        </PgAccordion>

        <PgAccordion
          userType="Patient"
          question="How do I complete the Smart Questionnaire? "
        >
          <div>
            When you log in to your PureGenomics<sup>&reg;</sup> account, you
            will see the
            <b> Get Started Now </b> button. If you have yet to upload your data
            file, it will bring you to a screen where it will invite you to
            upload. To move past this, you will need to click the
            <b> Dashboard </b> button. Once on the dashboard, scroll down past
            data upload to the
            <b> Start Questionnaire </b> button. Additionally, your practitioner
            can send you an invitation to complete the questionnaire from their
            dashboard. Simply click on the link in the invitation email to begin
            taking the questionnaire.
          </div>
        </PgAccordion>

        <PgAccordion
          userType="Patient"
          question="What if I don’t yet have my genetic data? Can I go ahead and complete the questionnaire? "
        >
          <div>
            Yes, you can complete the questionnaire prior to receiving your
            genetic raw data file. Once your healthcare provider creates your
            account you can log in and complete the questionnaire. When you
            click Get Started it takes you to a page to upload your raw data.
            Since you are not yet ready for this step, click <b>dashboard, </b>
            scroll down past data upload, and click <b>
              Start Questionnaire.
            </b>{" "}
            A PureGenomics<sup>&reg;</sup> report will be generated once you
            upload your genetic raw data file.
          </div>
        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="Can I edit my responses? "
          answer="You will have the ability to edit your responses before submitting your final answers. You will only have one opportunity to make any necessary changes. If you need to edit any answers beyond this, a new questionnaire will need to be completed. You can ask your healthcare provider to send you another questionnaire. "
        />
        <PgAccordion
          userType="Patient"
          question="My provider said we can enter my lab results into PureGenomics<sup>&reg;</sup>? What information is this providing? "
        >
          <div>
            A healthcare provider cannot diagnose or prescribe from a
            nutrigenomics report. The nutrigenomics report provides insight
            where you may need additional support. This tells the provider what
            labs they can order to investigate further.
          </div>
          <br />
          <div>
            Once your provider receives your lab work, they will enter the
            results into PureGenomics<sup>&reg;</sup>. The lab upload capability
            easily displays nutrient status. The nutrient status will help show
            whether a genetic variant is being expressed and directs more
            precise supplement and dosing recommendations.
          </div>
        </PgAccordion>

        <PgAccordion
          userType="Patient"
          question="Do I upload my lab results or does my provider? "
          answer="Your healthcare provider uploads the lab results and will review them with you at your next appointment.  "
        />
        {/* <PgAccordion
          userType="Patient"
          question="How do I delete my PureGenomics<sup>&reg;</sup> report results? "
        >
          <div>
            Click on the My Account button under Account Information on the
            patient dashboard. Scroll to the bottom of the My Account page and
            click <b>Delete Report.</b>
          </div>
          <br />
          <div>
            If you choose to permanently delete this report, you will need to
            re-upload all your data and reach out to your healthcare provider
            for your Summary Report PDF and product recommendations again.
          </div>
        </PgAccordion> */}
        <PgAccordion
          userType="Patient"
          question="What is a polygenic score?"
        >
          <div>
          A polygenic score (PGS) is a measurement of genetic predisposition that analyzes many contributing SNPs, instead of just one.  Most traits are determined by many SNPs, not just one.  Since PGS looks at many SNPs, it is a more comprehensive genetic assessment and is generally more accurate than single SNP analysis.
          </div>

        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="What do the scores mean?"
        >
          <div>
          In PureGenomics, the score reflects the number of risk alleles that contribute to the trait.   The higher the score, the greater the genetic predisposition.  For example, a high score for vitamin B12 indicates that an individual’s B12 needs are greater than the needs of someone with a lower score.
          </div>

        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="Why were enhancements made for just a few select traits?"
        >
          <div>
          The accuracy of scores and intake recommendations depends on robust genome-wide association studies and development of mathematical models requiring population data on blood levels and dietary intake information.  This information is currently not available for all traits. As part of our ongoing research and development, we will continue to evaluate new traits for compatibility with PGS and personalized intake recommendations.
          </div>

        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="How are the SNPs for the polygenic trait selected?"
        >
          <div>
          The contributing SNPs analyzed by the PGS were selected based on genome-wide association studies and other population data sources.  SNPs with strong and statistically significant effects on nutrient levels are included in a polygenic score.
          </div>

        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="How accurate are the scores and intake recommendations?"
        >
          <div>
          The polygenic scores and personalized intake recommendations are calculated from population data showing correlations between nutrient intakes, blood levels and genotypes. These calculations are based on genetics. Practitioners are encouraged to adjust nutrient intake recommendations based on labs and other assessments.
          </div>

        </PgAccordion>
        <div className="pgs-heading mt-5 mb-2  textc-primary__p">
          Privacy and Security:{" "}
        </div>
        <PgAccordion
          userType="Patient"
          question="What is the PureGenomics<sup>&reg;</sup> Privacy Policy? "
        >
          <div>
            PureGenomics<sup>&reg;</sup> will not sell, share, or rent a
            patient's 23andMe<sup>&reg;</sup>, Ancestry<sup>&reg;</sup>, or Nordic Laboratories data
            files to third parties. Nor will we store any genetic data on disc
            unless it is being processed, and the database itself is encrypted.
            Read more about our{" "}
            <a
              className="cursor-pointer"
              onClick={() => navigate("/privacy")}
              target="_blank"
            >
              Privacy Policy.{" "}
            </a>
          </div>
        </PgAccordion>

        <PgAccordion
          userType="Patient"
          question="How is data security and my privacy protected? "
        >
          <div>
            This website takes every precaution to protect your information.
            When users submit sensitive information via the website, your
            information is protected both online and offline with the best
            encryption software in the industry—SSL.
          </div>
          <br />
          <div>
            While we use SSL encryption to protect sensitive information online,
            we also do everything in our power to protect user-information
            offline. All our users' information, not just the sensitive
            information mentioned above, is restricted in our offices. Only
            employees who need the information to perform a specific job (for
            example, a customer service representative) are granted access to
            personally identifiable information.
          </div>
          <br />
          <div>
            PureGenomics<sup>&reg;</sup> will not sell, share, or rent a
            patient's 23andMe<sup>&reg;</sup>, Ancestry<sup>&reg;</sup>, or Nordic Laboratories data file to third
            parties. Nor will we store any genetic data on disc unless it is
            being processed, and the database itself is encrypted. Read more
            about our {" "}
            <a
              className="cursor-pointer"
              onClick={() => navigate("/privacy")}
              target="_blank"
            >
              {" "}
              Privacy Policy.{" "}
            </a>
          </div>
          <br />
          <div>
            Furthermore, it is important to read the consent forms available at
            23andMe<sup>&reg;</sup>, Ancestry<sup>&reg;</sup>, or Nordic Laboratories. Any questions
            can be directed to either genetic testing company, as they have a
            responsibility to address directly.
          </div>
          <br />
          <div>
            Overall, data that is utilized by these testing services are:
          </div>
          <ul>
            <li>
              De-identified (excludes personal details, including name, date of
              birth, or address)
            </li>
            <li>
              Protected by strict laws surrounding how data and personal
              information is stored
            </li>
            <li>
              Based on consent, in the case of needing to opt-in for use in
              research
            </li>
          </ul>
        </PgAccordion>
        {/* section 3 */}
        <div className="pgs-heading mt-5 mb-2  textc-primary__p">
          Troubleshooting:{" "}
        </div>
        <PgAccordion
          userType="Patient"
          question="When my provider was adding me in as their patient, they received an error message asking them to try another email address. How do they proceed? "
        >
          <div>
            That error message means that the email address is already in use in
            our system. The email address is a unique identifier and can only be
            used one time. Since that email has been used previously, you will
            need to provide another email address to establish an account.
          </div>
          <br />
          {/* <div>
            If you have a PureGenomics<sup>&reg;</sup> account with a different
            provider using this email address, you can also contact customer
            support to request your PureGenomics<sup>&reg;</sup> account be
            transferred to your new provider by{" "}
            <a
              className="cursor-pointer"
              href="mailto:support@pure-encapsulations.co.uk"
              target="_blank"
              rel="noreferrer"
            >
              emailing
            </a>{" "}
            us.
          </div> */}
        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="I would like to set my family members up with PureGenomics<sup>&reg;</sup> accounts. How do I do this?   "
        >
          <div>
            Family members will need to be invited by a participating provider
            to set up their own accounts.
          </div>
          <br />
          <div>
            Each patient must use a different email address to create a
            PureGenomics<sup>&reg;</sup> account.
          </div>
        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="I received an error message when I tried to upload my genomic data. How do I fix this?"
        >
          <div>
            There are three reasons an error may occur while uploading your
            genomic data.
          </div>
          <ol type="1">
            <li>
              The data is from a non-compatible testing company. PureGenomics
              <sup>&reg;</sup> is only compatible with 23andMe<sup>&reg;</sup>, Ancestry<sup>&reg;</sup>, or Nordic Laboratories test results.
            </li>
            <li>
              You are attempting to upload results in a format other than a text
              or a zip file (like a PDF). Please upload your genomic report in a
              .txt or .zip file format. This is the common download format from
              23andMe<sup>&reg;</sup>, Ancestry<sup>&reg;</sup>, or Nordic Laboratories.
            </li>
            <li>
              Your data is unreadable. Data may become corrupt and is
              unintelligible by our system. Please re-download the file from the
              testing website and try again. When the pop-up window appears
              where it asks you if you want to Open or Save your data, click{" "}
              <b>Save</b> as opening the file can lead to corruption.
            </li>
          </ol>
        </PgAccordion>
        {/* <PgAccordion
          userType="Patient"
          question="I was attempting to register, and I got a “No match found for email and token combination” error message, how do I proceed? "
          answer=" It is likely that your practitioner re-sent the invitation to you recently. The token error message appears when an older email is selected for completing registration. The registration link from the older email has expired. The registration link will work when you respond to the most recent invitation from your practitioner. Please check your inbox and select the very last email you received from us. "
        /> */}
        {/* section 4 */}
        <div className="pgs-heading mt-5 mb-2 textc-primary__p">
          Understanding the Science
        </div>
        <PgAccordion
          userType="Patient"
          question="What is nutrigenomics? What is nutrigenomics testing? "
          answer="Nutrigenomics is a branch of science that uses cutting-edge genomics technology to study the link between our genes, nutrition, and health. This information allows us to develop personalised strategies for optimal health. "
        />
        <PgAccordion
          userType="Patient"
          question="What are traits? "
          answer="Traits are specific characteristics like eye colour, blood type, or the ability to tolerate lactose that are based on an individual's unique genetic variations (SNPs), as well as environmental factors. "
        />
        <PgAccordion
          userType="Patient"
          question="Which trait categories are supported by PureGenomics<sup>&reg;</sup>? "
        >
          <div>
            PureGenomics<sup>&reg;</sup> reports offer insights into the
            following trait categories:
          </div>
          <ul>
            <li>Vitamins, minerals & omega-3s </li>
            <li>Detoxification </li>
            <li>Glucose metabolism </li>
            <li>Metabolic & weight management </li>
            <li>Cognitive health & memory </li>
            <li>Immune health </li>
            <li>Energy & fitness </li>
            <li>Cardiovascular health </li>
            <li>Gastrointestinal health </li>
          </ul>
        </PgAccordion>

        <PgAccordion
          userType="Patient"
          question="What are Single Nucleotide Polymorphisms (SNPs)? "
          answer="Called SNPs, or 'snips' for short, these are unique parts of your DNA. Some SNPs can affect your health. Some of them can also affect how your body breaks down and uses nutrients. "
        />

        <PgAccordion
          userType="Patient"
          question="If there are millions of SNPs, why does the report only show a limited number? "
        >
          <div>
            Most SNPs have no effect on nutritional health and therefore lack
            nutritional relevance. PureGenomics<sup>&reg;</sup> includes SNPs
            that are:
          </div>
          <ol>
            <li>relevant to health </li>
            <li>validated by published research </li>
            <li>responsive to nutritional support </li>
          </ol>
        </PgAccordion>
        <PgAccordion userType="Patient" question="Supplement Recommendations">
          <div>
            As a dietary supplement company, we cannot make patient-specific
            recommendations. PureGenomics<sup>&reg;</sup> provides you with the
            opportunity to see diet and lifestyle recommendations based on your
            results, however, supplement recommendations and your health needs
            should be discussed with a healthcare provider that can guide you as
            to which supplement may be a fit for you. Your provider can review
            your report and recommend specific supplements for you through
            PureGenomics<sup>&reg;</sup>.
          </div>
        </PgAccordion>
      </div>
    </div>
  );
}

export default UKPatientFAQ;
