import { t } from "i18next";
import React from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const LoadingOverlay = ({ title }) => {
  const { t } = useTranslation();
  return (
    <div
      className="d-flex justify-content-center align-items-center position-fixed"
      style={{
        height: "100vh",
        width: "100vw",
        background: "rgba(20,50,140,0.2)",
        zIndex: 99999,
      }}
    >
      <div className="d-inline-block text-center">
        <Spinner
          animation="border"
          as="div"
          className="textc-primary my-auto"
          role="status"
        ></Spinner>
        <div className="pg-heading w-100 my-3">{title || t("please wait")}</div>
      </div>
    </div>
  );
};

export default LoadingOverlay;
