import * as admin_patient_constant from "../actions/adminPatient";

const initialState = {
    loading_patients: true,
    patients: [],
    total_count: null,
    loading: true
}

const adminPatient = (state = initialState, action) => {
    switch(action.type){
        case admin_patient_constant.GET_PATIENTS_REQUEST: {
            return {...state, loading_patients: true}
        }
        case admin_patient_constant.GET_PATIENTS_SUCCESS: {
            return {...state, loading_patients: false, patients: action.payload.patients, total_count: action.payload.total}
        }
        case admin_patient_constant.GET_PATIENTS_FAILURE: {
            return {...state, loading_patients: false}
        }
        case admin_patient_constant.ADD_PATIENT_REQUEST: {
            return {...state, loading: true}
        }
        case admin_patient_constant.ADD_PATIENT_SUCCESS: {
            return {...state, loading: false}
        }
        case admin_patient_constant.ADD_PATIENT_FAILURE: {
            return {...state, loading: true}
        }
        default:
            return state;
    }
}

export default adminPatient; 