import ContentLoader from "react-content-loader";
function L_PatientInfo(props) {
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height="20%"
      viewBox="0 0 600 80"
      backgroundColor="#9e9e9e52"
      foregroundColor="#18368f80"
      {...props}
    >
      {/* report summary navigator */}
      <rect x="0" y="5" rx="3" ry="3" width="82" height="16" />
      <rect x="0" y="30" rx="3" ry="3" width="140" height="10" />

      <rect x="240" y="5" rx="3" ry="3" width="100" height="16" />
      <rect x="240" y="30" rx="3" ry="3" width="80" height="6" />
      <rect x="240" y="45" rx="3" ry="3" width="80" height="6" />

      <rect x="370" y="5" rx="3" ry="3" width="100" height="16" />
      <rect x="370" y="30" rx="3" ry="3" width="80" height="6" />
      <rect x="370" y="45" rx="3" ry="3" width="80" height="6" />

      <rect x="500" y="5" rx="3" ry="3" width="100" height="16" />
      <rect x="500" y="30" rx="3" ry="3" width="80" height="6" />
      <rect x="500" y="45" rx="3" ry="3" width="80" height="6" />
      <rect x="500" y="60" rx="3" ry="3" width="80" height="6" />
    </ContentLoader>
  );
}

export default L_PatientInfo;
