import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import toastr from 'react-hot-toast'
import { useTranslation } from "react-i18next";

function EditTerminateAlertModal({
    onCancel,
    showModal,
    question,
    onYes }) {

    const { t, i18n } = useTranslation();

    return (
      <div>
        <Modal
          show={showModal}
          size="md-down"
          centered
          aria-labelledby="contained-modal-title-vcenter"
          onHide={onCancel}
          contentClassName="border-radius"
          backdrop="static"
          className="add-practitioner-modal"
        >
          <Modal.Body className="container">
            <div style={style.closeButton} onClick={onCancel}>
              <div className="fas fa-times"></div>
            </div>
            <div className="pg-text__bold my-4">
              {
                question.question_type === "radio" ?
                <span>{ t('patient_ques_alt1') }</span>
                :
                <span>{ t('patient_ques_alt2') }</span>
              }
            </div>
            <div className="d-flex justify-content-end">
                <div>
                  <button className="btn btn-outline mx-2" onClick={onCancel}>{ t('patient_ques_alt3') }</button>
                  <button className="btn btn-primary" onClick={onYes}>{ t('patient_ques_alt4') }</button>
                </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
}

export default EditTerminateAlertModal

const style = {
  closeButton: {
    position: 'absolute',
    right: 14,
    top: 10,
    fontSize: 20,
    cursor: "pointer"
  }
}