import * as ap_constant from "../actions/addPatient";

const initialState = {
    loading: true,
    error: false,
    practiceSetting: null,
    loading_country_list: true,
    country_list: []
};


const addPatientReducer = (state = initialState, action) => {
    switch (action.type) {
        case ap_constant.ADD_PATIENT_REQUEST: {
            return { ...state, loading: true };
        }
        case ap_constant.ADD_PATIENT_SUCCESS: {
            return { ...state, loading: false };
        }
        case ap_constant.ADD_PATIENT_FAILURE: {
            return { ...state, loading: false, error: true };
        }
        case ap_constant.PATIENT_INVITATION_REQUEST: {
            return {
                ...state, loading: true
            }
        }
        case ap_constant.PATIENT_INVITATION_SUCCESS: {
            return {
                ...state, loading: false
            }
        }
        case ap_constant.PATIENT_INVITATION_FAILURE: {
            return {
                ...state, loading: false, error: true
            }
        }
        case ap_constant.GET_PRACTICE_SETTING_REQUEST: {
            return {
                ...state, loading: false, error: true
            }
        }
        case ap_constant.GET_PRACTICE_SETTING_SUCCESS: {
            return {
                ...state, loading: false, practiceSetting: action.payload
            }
        }
        case ap_constant.GET_PRACTICE_SETTING_FAILURE: {
            return {
                ...state, loading: false, error: true
            }
        }
        case ap_constant.GET_COUNTRIES_REQUEST: {
          return {
            ...state, loading_country_list: false, error: true
          }
        }
        case ap_constant.GET_COUNTRIES_SUCCESS: {
          return {
            ...state, loading_country_list: false, country_list: action.payload.countries
          }
        }
        case ap_constant.GET_COUNTRIES_FAILURE: {
          return {
            ...state, loading_country_list: false, error: true
          }
        }
        default:
            return state;
    }
};

export default addPatientReducer;
