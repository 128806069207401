import toast from "react-hot-toast";
import Cookies from "universal-cookie";
import { changeIsAuth, __logout, LOGOUT_ST } from "../store/actions/auth";
import { store } from "../index";
import { clearCookie, getDefaultLanguage } from "./index";
import i18n from "../translation/i18n";
const cookies = new Cookies();

export const handleLogout = (state, response) => {
  if (response.html) {
    store.dispatch(changeIsAuth({ errorHTML: response.html }));
    console.log("in store", response);
  } else toast.error(response?.error || response?.status);
  localStorage.removeItem(`persist:${process.env.REACT_APP_STORE_KEY}`);
  clearCookie();
  store.dispatch({ type: LOGOUT_ST });
};

const getMainDomainName = () => {
  if (window.location.hostname.split('.').includes('localhost'))
    return 'localhost'
  else if (window.location.hostname.split('.').includes('lvh'))
    return 'lvh.me'
  else if (window.location.hostname.split('.').includes('pg4-staging'))
    return 'web.pg4-staging.lmapps'
  else if (window.location.hostname.split('.').includes('lmapps'))
    return 'pg3-prod.lmapps'
  else if (window.location.hostname.split('.').includes('puregenomics'))
    return 'puregenomics'
  else if (window.location.hostname.split('.').includes('pureinsightpro'))
    return 'pureinsightpro'
  else if (window.location.hostname.split('.').includes('pureinsight'))
    return 'pureinsight.pureencapsulationspro'
}

const getTLDomainName = () => {
  // top level
  if (window.location.hostname.endsWith(".ca")) return ".ca";
  else if (window.location.hostname.endsWith(".co.uk")) return ".co.uk";
  else if (window.location.hostname.endsWith(".com")) return ".com";
  else if (window.location.hostname.endsWith(".io")) return ".io";
  else return "";
};

const BASE_URL = `${getMainDomainName() + getTLDomainName()}${
  window.location.port ? ":" + window.location.port : ""
}`; //REACT_APP_PG3_APP_DOMAIN
const DOMAIN_FOR_COOKIE = `.${getMainDomainName() + getTLDomainName()}`;

// fn to handle switching between subdomains while logging in.
export const switchSDAndLogin = (
  token,
  sub_domain,
  practiceId,
  path,
  termsAgreed,
  langauge,
  showDOBBanner
) => {
  clearCookie();
  let cookieConfig = { path: "/", domain: DOMAIN_FOR_COOKIE };
  cookies.set("isAuthenticated", true, {
    path: "/",
    domain: DOMAIN_FOR_COOKIE,
  });
  cookies.set("token", token, { path: "/", domain: DOMAIN_FOR_COOKIE });
  cookies.set("p_id", practiceId, {
    path: "/",
    domain: DOMAIN_FOR_COOKIE,
  });
  cookies.set("practice", sub_domain, {
    path: "/",
    domain: DOMAIN_FOR_COOKIE,
  });
  cookies.set("termsAgreed", termsAgreed, {
    path: "/",
    domain: DOMAIN_FOR_COOKIE,
  });
  cookies.set("showDOBBanner", showDOBBanner, cookieConfig);
  // cookies.set("i18next", langauge, { path: "/" });
  store.dispatch({ type: LOGOUT_ST });
  if (langauge)
    window.location = `${window.location.protocol}//${sub_domain}.${BASE_URL}${path}?lng=${langauge}`;
  else
    window.location = `${window.location.protocol}//${sub_domain}.${BASE_URL}${path}`;
  // window.location = `${window.location.protocol}//${sub_domain}.${BASE_URL}${path}`;
};

export const logoutAction = () => {
  // Access the current Redux state
  const state = store.getState();
  const { token, currentPractice, currentPracticeID } = state.auth;
  clearCookie();
  setDefaultLang();
  store
    .dispatch(__logout(token, currentPractice, currentPracticeID))
    .then((response) => {
      window.localStorage.clear();
    })
    .catch((error) => {
      console.error("Error during logout:", error);
    });
};

const setDefaultLang = () => {
  const default_lang = getDefaultLanguage();
  i18n
    .changeLanguage(default_lang)
    .then(() => {
      cookies.set("i18next", default_lang, { path: "/" });
      console.log(`Language changed to ${default_lang}`);
    })
    .catch((error) => {
      console.error(`Error changing language to ${default_lang}`, error);
    });
};
