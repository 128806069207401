import CustomBadgePill from "../../CustomBadgePill";
import PgCheckbox from "../../PgCheckbox";

export default function FilterComponent({
  filterSignupPending,
  filterNoData,
  filterNotReviewed,
  setfilterNoData,
  setfilterNotReviewed,
  setfilterSignupPending,
  t,
}) {
  return (
    <div className="row px-3">
      {/* <hr className="" /> */}
      <div className="col-12">
        <div className="d-inline-block">
          <PgCheckbox
            title={t("pracpl_line_3")}
            id="f-sing-up-pending__patientlist"
            checked={filterSignupPending}
            onCheck={() => setfilterSignupPending(!filterSignupPending)}
          />
        </div>
        <div className="d-inline-block">
          <PgCheckbox
            title={t("pracpl_line_4")}
            id="f-not-reviewed__patientlist"
            checked={filterNotReviewed}
            onCheck={() => setfilterNotReviewed(!filterNotReviewed)}
          />
        </div>
        <div className="d-inline-block">
          <PgCheckbox
            title={t("pracpl_line_5")}
            id="f-no-data__patientlist"
            checked={filterNoData}
            onCheck={() => setfilterNoData(!filterNoData)}
          />
        </div>
        {/* <CustomBadgePill
          title="Sign-up Pending"
          isActive={filterSignupPending}
          setActive={() => setfilterSignupPending(!filterSignupPending)}
        />
        <CustomBadgePill
          title="No Data"
          isActive={filterNoData}
          setActive={() => setfilterNoData(!filterNoData)}
        />
        <CustomBadgePill
          title="Not Reviewed"
          isActive={filterNotReviewed}
          setActive={() => setfilterNotReviewed(!filterNotReviewed)}
        /> */}
      </div>
      {/* <div className="col-6"></div> */}
    </div>
  );
}
