import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";


function PrivacyUK({history}) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="my-lg-5 py-5">
      <div className="container-xxl p-lg-5 px-4">
        <div className="pgs-title pgs-title__xl ff-v py-5">
          PureGenomics<sup>&reg;</sup> UK Privacy Notice
        </div>

        <div className="pgs-heading__sub ff-m fw-bold text-dark fst-italic d-block">
          Effective Date: April, 2022
        </div>

        <div className="pgs-text py-2">
          SCOPE OF THIS NOTICE
        </div>

        <div className="pgs-text py-2">
          <div>
            Please read this privacy notice (<b>“Notice”</b>) carefully to understand our policies and practices regarding your  Personal  Data  and  how  we  will  treat  it.  This  Notice  applies  to  individuals  (patients  and healthcare  professionals)  who  interact  with  PureGenomics<sup>&reg;</sup> services  (<b>“you”</b>).  This  Notice  explains how  your  Personal  Data  are  collected,  used,  and  disclosed  by  Pure  Encapsulations,  LLC  (<b>“Pure Encapsulations”,  “We”, “Us”</b>).  It  also  tells  you  how  you  can  access  and  update  your  Personal  Data and make certain choices about how your Personal Data are used.
          </div>
          <div className="my-3">
            This  Notice  covers  both  our  online  and  offline  data  collection  activities  (as  applicable),  including Personal  Data  that  We  collect  through  our  various  channels  such  as  websites,  apps,  third  party networks  and  consumer  support  team.  Please  note  that  We  might  aggregate  personal  data  from different sources (e.g. websites, app, consumer services). Please see Section 9 for further information on how to object to this.
          </div>
          <div className="my-3">
            If you do not provide necessary Personal Data to us (We will indicate to you when this is the case, for example, by making this information clear in our registration forms), We may not be able to provide you with our goods and/or services. This Notice can change from time to time (see Section 11).
          </div>
          <div className="my-3">
            PureGenomics<sup>&reg;</sup> helps  healthcare  professionals  to  offer  tailor-made  advice  and  nutritional  support  to their  patients.  It  is  therefore  a  condition  of  using  our  services  that  a  patient  account  within  the PureGenomics<sup>&reg;</sup> app must be linked to a health professional account (meaning that each individual has access to the Personal Data of the other within the app).
          </div>
        </div>

        {/* quick guide */}
        <div className="row my-5">
          <div className="col-lg-4 border  bg-grey-1">
            <div className="pgs-text ff-m fw-bold p-2">
              This Notice provides important information in the following areas:
            </div>
            <ol className="pgs-text pgs-text__xs ff-m">
              <li>
                <a href="#1">SOURCES OF PERSONAL DATA</a>
              </li>
              <li>
                <a href="#2"> PERSONAL DATA THAT WE COLLECT ABOUT YOU AND HOW WE COLLECT IT</a>
              </li>
              <li>
                <a href="#3">PERSONAL DATA OF CHILDREN</a>
              </li>
              <li>
                <a href="#4">COOKIES AND GOOGLE ANALYTICS</a>
              </li>
              <li>
                <a href="#5">USES MADE OF YOUR PERSONAL DATA</a>
              </li>
              <li>
                <a href="#6">DISCLOSURE OF YOUR PERSONAL DATA</a>
              </li>
              <li>
                <a href="#7">RETENTION OF PERSONAL DATA</a>
              </li>
              <li>
                <a href="#8">STORAGE AND/OR TRANSFER OF YOUR PERSONAL DATA</a>
              </li>
              <li>
                <a href="#9">ACCESS TO YOUR PERSONAL DATA</a>
              </li>
              <li>
                <a href="#10"> YOUR CHOICES ABOUT HOW WE USE AND DISCLOSE YOUR PERSONAL DATA</a>
              </li>
              <li>
                <a href="#11">CHANGES TO OUR NOTICE</a>
              </li>
              <li>
                <a href="#12"> DATA CONTROLLERS & CONTACT</a>
              </li>
            </ol>
          </div>
        </div>
        {/* section 1*/}
        <div className="py-4">
          <div className="pgs-heading__sub ff-m fw-bold text-dark " id="1">
            1. SOURCES OF PERSONAL DATA
          </div>
          <div className="pgs-text py-2">
            This  Notice  applies  to  Personal  Data  that  We  collect  from  or  about  you,  through  the  methods described below (see Section 2), from the following sources:
            <div className="my-3">
              <span className="fw-bold fst-italic text-decoration-underline">PureGenomics<sup>&reg;</sup>  websites.</span>{" "}
              Consumer-directed  websites  for  patients  and  healthcare  professionals operated   by   or   for   Pure   Encapsulations,   including   sites   that   We   operate   under   our   own domains/URLs  and  mini-sites  that  We  run  on  third  party  social  networks  such  as  Facebook (<b>“Websites”</b>).
            </div>
            <div className="my-3">
              <span className="fw-bold fst-italic text-decoration-underline">PureGenomics<sup>&reg;</sup>   mobile sites/apps.</span>{" "}
              Consumer-directed   application   for   patients   and   healthcare professionals  operated  by  or  for  Pure  Encapsulations,  such  as  web-browser  applications  and smartphone apps.
            </div>
            <div className="my-3">
              <span className="fw-bold fst-italic text-decoration-underline">E-mail, text  and other electronic messages.</span>{" "}
              Interactions  with  electronic  communications  between you and Pure Encapsulations.
            </div>
            <div className="my-3">
              <span className="fw-bold fst-italic text-decoration-underline">Superadmin.</span>{" "}
              Communications with Our consumer support team.
            </div>
            <div className="my-3">
              <span className="fw-bold fst-italic text-decoration-underline">Data We create.</span>{" "}
                In the course of our interactions with you, we may create Personal Data about you. If you are a patient this could be reports based on your DNA Data, and records of your purchases from our websites). If you are a healthcare professional, this could be a record of the recommendations you choose to share with the patient.
            </div>
            <div className="my-3">
              <span className="fw-bold fst-italic text-decoration-underline">Data  from  other  sources.</span>{" "}
                For  example,  from  third  party  tools  that  use  technology  such  as  google analytics  or  other  storage  technologies  to  provide  Us  with  measurement  and  analytics  services, information  we  acquire  about  you  or  related  to  you  from  our  affiliates,  public  sources  and  data received when we acquire other companies.
            </div>
          </div>
        </div>

        {/* section 2*/}
        <div className="py-4">
          <div className="pgs-heading__sub ff-m fw-bold text-dark " id="2">
            2. PERSONAL DATA THAT WE COLLECT ABOUT YOU AND HOW WE COLLECT IT
          </div>
          <div className="pgs-text py-2">
            Depending on how you interact with Pure Encapsulations (online, offline, over the phone, etc.), We collect various types of information from you, as described below.
            <div className="my-3">
              <span className="fw-bold fst-italic text-decoration-underline">Personal contact information.</span>{" "}
              This includes any information you provide to Us that would allow Us to  contact  you,  such  as  your  name,  postal  address,  e-mail  address,  practice  details  (if  you  are  a healthcare professional) or phone number.
            </div>
            <div className="my-3">
              <span className="fw-bold fst-italic text-decoration-underline">Account  login  information.</span>{" "}
              Any  information  that  is  required  to  give  you  access  to  your  specific account   profile.   Examples   include   your   login   ID/email   address,   screen   name,   password   in unrecoverable form, and/or security question and answer.
            </div>
            <div className="my-3">
              <span className="fw-bold fst-italic text-decoration-underline">Demographic   information & interests.</span>{" "}
              Any   information   that   describes   your   demographic   or behavioural characteristics. Examples include your date of birth, age or age range, gender, geographic location (e.g. postcode/zip code), favourite products, hobbies and interests, and household or lifestyle information.
            </div>
            <div className="my-3">
              <span className="fw-bold fst-italic text-decoration-underline">Sensitive Personal Data (including Genetic Information of patients).</span>{" "}
              We process certain sensitive personal  data  that  you  provide  to  us  for  service  provision,  marketing  or  improvement purposes  with  your  prior  express  consent.  In  particular,  if  you  use  this  service  as  a  patient,  Pure Encapsulations collects and analyses your genetic information, including DNA information, when you choose  to  share  it  with  us.  You  must  share  your  digitised  DNA  data  (<b>“DNA  Data”</b>)  i.e.  your www.23andMe.com  or  ANCESTRY.COM  data  file,  with  Pure  Encapsulations  to  take  advantage  of this  service.  If  we  process  your  sensitive  personal  data  for  other  purposes,  we  rely  on  the following  legal  bases:  (i)  detection  and  prevention  of  crime  (including  the  prevention  of fraud); and (ii) compliance with applicable law (e.g. to comply with our diversity reporting).
            </div>
            <div className="my-3">
              <span className="fw-bold fst-italic text-decoration-underline">Information  from  computer/mobile  device.</span>{" "}
              Any  information  about  the  computer  system  or  other technological device that you use to access one of our Websites or apps, such as the Internet protocol (IP) address used to connect your computer or device to the Internet, operating system type, and web browser type and version. If you access a PureGenomics<sup>&reg;</sup> website or app via a mobile device such as a  smartphone,  the  collected  information  will  also  include,  where  permitted,  your  phone’s  unique device ID, advertising ID, geo-location, and other similar mobile device data.
            </div>
            <div className="my-3">
              <span className="fw-bold fst-italic text-decoration-underline">Websites/communication usage information.</span>{" "}
              As you navigate through and interact with our Websites or  newsletters,  We  use  automatic  data  collection  technologies  to  collect  certain  information  about your actions. This includes information such as which links you click on, which pages or content you view  and  for  how  long,  and  other  similar  information  and  statistics  about  your  interactions,  such  as content  response  times,  download  errors  and  length  of  visits  to  certain  pages.  This  information  is captured  using  automated  technologies  such  as  google  analytics.  You  have  the  right  to  object  to  the use of such technologies, for further details please see Section 4.
            </div>
            <div className="my-3">
              <span className="fw-bold fst-italic text-decoration-underline">Market  research  &  consumer  feedback.</span>{" "}
              Any  information  that  you  voluntarily  share  with  Us  about your experience of using our products and services.
            </div>
            <div className="my-3">
              <span className="fw-bold fst-italic text-decoration-underline">Other  consumer-generated  content.</span>{" "}
              Any  content  that  you  create  and  then  share  with  Us  on  third party  social  networks  or  by  uploading  it  to  one  of  our  Websites  or  apps,  including  the  use  of  third party  social  network  apps  such  as  Facebook.  Examples  include  photos,  videos,  personal  stories,  or other similar media or content. Where permitted, We collect and publish consumer-generated content in connection with a variety of activities, including contests and other promotions, website community features, consumer engagement, and third party social networking.
            </div>
            <div className="my-3">
              <span className="fw-bold fst-italic text-decoration-underline">Calls to Superadmin consumer support services.</span>{" "}
              Communications with Superadmin can be recorded or  listened  into,  in  accordance  with  applicable  laws,  for  local  operational  needs  (e.g.  for  quality  or training  purposes).  Where  required  by  law,  you  will  be  informed  about  such  recording  at  the beginning of your call.
            </div>
          </div>

        </div>

        {/* section 3*/}
        <div className="py-4">
          <div className="pgs-heading__sub ff-m fw-bold text-dark " id="3">
            3. PERSONAL DATA OF CHILDREN
          </div>
          <div className="pgs-text py-2">
          We  do  not  knowingly  solicit  or  collect  personal  data  from  children  below  the  age  of  18.  If  We discover that We have unintentionally collected personal data from a child below 18, We will remove that individual’s personal data from our records promptly.
          </div>
        </div>

        {/* section 4*/}
        <div className="py-4">
          <div className="pgs-heading__sub ff-m fw-bold text-dark " id="4">
            4. COOKIES AND GOOGLE ANALYTICS
          </div>
          <div className="pgs-text py-2">
            <div className="my-3">
              <span className="fw-bold fst-italic text-decoration-underline">Cookies/Similar Technologies.</span>{" "}
              We use site functionality cookies on our websites and apps, we do not currently  use  any  non-essential  or  advertising  cookies  or  similar  technology.  Please  see  our  <a
              className="cursor-pointer"
              onClick={() => navigate("/cookies-notice")}
              target="_blank"
            >
               Cookies Notice
            </a>. for  more  information  on  the  cookies  We  use  and  the  purposes  for which We use them.
            </div>
            <div className="my-3">
              <span className="fw-bold fst-italic text-decoration-underline">Google  Analytics  Opt  Out.</span>{" "}
              Some  of  our  websites  and  apps  use  Google  Analytics  to  improve  our marketing and services and report on performance.
              <div>
                If you  wish  to  prevent  your  data  from  being  used  by  Google  Analytics,  Google  has  developed  the Google Analytics opt-out browser add-on available &nbsp;
                <a
                  href="https://tools.google.com/dlpage/gaoptout/."
                  target="_blank"
                >
                  https://tools.google.com/dlpage/gaoptout/.
                </a>
              </div>
              <div>
                We will not use any information reported by Google Analytics to identify any individual user of our site.
              </div>
            </div>
          </div>

        </div>

        {/* section 5*/}
        <div className="py-4">
          <div className="pgs-heading__sub ff-m fw-bold text-dark " id="5">
            5. USES MADE OF YOUR PERSONAL DATA
          </div>
          <div className="pgs-text py-2">
            The following paragraphs describe the various purposes for which We collect and use your Personal Data, and the different types of Personal Data that are collected for each purpose. Please note that not all of the uses below will be relevant to every individual.

            <div className="overflow-x-scroll overflow-x-lg-hidden">
              <table className="pg-table my-3">
                <thead className="fw-bold text-center">
                  <tr>
                    <td className="fw-bold fst-italic text-decoration-underline">What We use your Personal Data for</td>
                    <td className="fw-bold fst-italic text-decoration-underline">Our reasons</td>
                    <td className="fw-bold fst-italic text-decoration-underline">Our legitimate interests</td>
                  </tr>
                </thead>
                <tbody className="align-top">
                  <tr>
                    <td className="text-start">
                    <div>
                        <span className="fw-bold fst-italic text-decoration-underline">Personalised lifestyle and nutritional support services based on   analysis   of   patient’s   DNA   Data.</span>{" "}
                        We   process   your Personal  Data  (including  patient’s  health  data  such  as  DNA Data),  with  your  consent  to  fulfil  requests  to  perform  the services  We  offer  via  our  Websites  and  apps.  We  share  the output of our analysis with the healthcare professional linked to  a  patient’s  account  in  order  that  they  can  provide  tailor made  advice  and  nutritional  support.  Any  consents  on which  we  rely   (in   particular,   to  the   use  of   health information) can be withdrawn at any time; see Section 9 below.
                      </div>
                    </td>
                    <td>
                      <div>
                        <ul>
                          <li className="text-decoration-underline">
                            Entering into and fulfilling contracts with you
                          </li>
                          <li className="text-decoration-underline">
                            With your consent (where required)
                          </li>
                        </ul>
                      </div>
                    </td>
                    <td>

                    </td>
                  </tr>
                  <tr>
                    <td className="text-start">
                    <div>
                        <span className="fw-bold fst-italic text-decoration-underline">Consumer service.</span>{" "}
                        We use your Personal Data for consumer service  purposes,  including  responding  to  your  enquiries. Our consumer service team is operated through a third party service    provider.    This    typically    requires    the    use    of certain personal contact information and information regarding  the  reason  for  your  inquiry  (e.g.  order  status, technical     issue,     product     question/complaint,     general question, etc.). If your enquiry relates to your DNA Data, or  other  sensitive  personal  data,  then  such  data  will need to be used in order to deal with your inquiry.
                      </div>
                    </td>
                    <td>
                      <div>
                        <ul>
                          <li className="text-decoration-underline">
                            Fulfilling contractual obligations
                          </li>
                          <li className="text-decoration-underline">
                            Legal obligations
                          </li>
                          <li className="text-decoration-underline">
                            Our legitimate interests
                          </li>
                        </ul>
                      </div>
                    </td>
                    <td>
                      <div>
                        <ul>
                          <li>
                            Improving and developing new products and services
                          </li>
                          <li>
                            Being more efficient
                          </li>
                          <li>
                            Defending our interests or those of our partners, including enforcement of contracts or defence of legal claims
                          </li>
                          <li>
                          Compliance with legal obligations
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start">
                    <div>
                        <span className="fw-bold fst-italic text-decoration-underline">Marketing   and   promotions.</span>{" "}
                        With   your   consent   (where required),  We use  your  Personal  Data  to  provide  you  with information    about    goods    or    services    (e.g.    marketing communications,  offers  or  campaigns  or  promotions).  This can be done via means such as email, ads, SMS, phone calls and  postal  mailings  to  the  extent  permitted  by  applicable laws.  This  use  of  your  Personal  Data  is  voluntary,  which means  that  you  can  oppose  (or  withdraw  your  consent  in certain countries) to the processing of your Personal Data for these  purposes.  For  detailed  information  on  how  to  modify your preferences about marketing communication, please see Sections  9  and  10  below.  For  more  information  about  our contests and other promotions, please see the official rules or details posted with each contest/promotion.
                      </div>
                    </td>
                    <td>
                      <div>
                        <ul>
                          <li className="text-decoration-underline">
                            With your consent (where required)
                          </li>
                          <li className="text-decoration-underline">
                            Fulfilling contractual obligations
                          </li>
                          <li className="text-decoration-underline">
                            Our legitimate interests
                          </li>
                        </ul>
                      </div>
                    </td>
                    <td>
                    <div>
                        <ul>
                          <li>
                            Working  out  which  of our products and services   may   interest you   and   telling   you about them
                          </li>
                          <li>
                            Defining types of consumers    for    new products or services
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start">
                    <div>
                        <span className="fw-bold fst-italic text-decoration-underline">Other    general    purposes    (e.g.    analytic,    security).</span>{" "}
                        In accordance with applicable laws, We use your Personal Data for other general business purposes, such as maintaining your account,  conducting  internal  research  and  measuring  the effectiveness  of  our  services  (as  applicable).  We  reserve  the right, if you have more than one PureGenomics<sup>&reg;</sup> account, to reconcile those accounts into one single account. We also use your  Personal  Data  for  management  and  operation  of  our communications,  IT  and  security  systems.  The  use  of  your Personal  Data  for  these  purposes  is  voluntary,  which means  that  you  can  oppose  the  processing  of  your Personal Data for this purpose. For detailed information on how to object, please refer to Section 9 below.
                      </div>
                    </td>
                    <td>
                      <div>
                        <ul>
                          <li className="text-decoration-underline">
                            Fulfilling contractual obligations
                          </li>
                          <li className="text-decoration-underline">
                            With your consent (where required)
                          </li>
                          <li className="text-decoration-underline">
                            Legal obligations
                          </li>
                          <li className="text-decoration-underline">
                            Our legitimate interests
                          </li>
                        </ul>
                      </div>
                    </td>
                    <td>
                    <div>
                        <ul>
                          <li>
                            Improving and developing new services
                          </li>
                          <li>
                            Being more efficient
                          </li>
                          <li>
                            Protect   our   systems, networks and staff
                          </li>
                          <li>
                            Compliance with legal obligations
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start">
                    <div>
                        <span className="fw-bold fst-italic text-decoration-underline">Legal  reasons  or  merger/acquisition.</span>{" "}
                        In  the  event  that  Pure Encapsulations or its assets are acquired by, or merged with, another  company  including  through  bankruptcy,  We  will share  your  Personal  Data  with  any  of  our  legal  successors. We  will  also  disclose  your  Personal  Data  to  third  parties  (i) when  required  by  applicable  law;  (ii)  in  response  to  legal proceedings;  (iii)  in  response  to  a  request  from  a  competent law  enforcement  agency;  (iv)  to  protect  our  rights,  privacy, safety  or  property,  or  the  public;  or  (v)  to  enforce  the  terms of any agreement or the terms of our Website.
                      </div>
                    </td>
                    <td>
                      <div>
                        <ul>
                          <li className="text-decoration-underline">
                            Legal obligations
                          </li>
                          <li className="text-decoration-underline">
                            Our legitimate interests
                          </li>
                        </ul>
                      </div>
                    </td>
                    <td>
                    <div>
                        <ul>
                          <li>
                            Compliance with legal obligations
                          </li>
                          <li>
                            Protect  our  assets  and staff
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* section 6*/}
        <div className="py-4">
          <div className="pgs-heading__sub ff-m fw-bold text-dark " id="6">
            6. DISCLOSURE OF YOUR PERSONAL DATA
          </div>
          <div className="pgs-text py-2">
            In addition to Pure Encapsulations and its affiliates mentioned in <b>Data Controllers & Contact</b> (see Section 12), We share your Personal Data with the following types of third party organisations:
            <div className="my-3">
              <span className="fw-bold fst-italic text-decoration-underline">Healthcare professionals and patients.</span>{" "}
              When a patient registers for a PureGenomics<sup>&reg;</sup> account based on  receiving  an  invitation  to  register  from  their  healthcare  professional,  we  will  share  their  Personal Data  (including  Sensitive  Personal  Data  such  as  health  data  and  DNA  Data)  with  that  healthcare professional.  We  also  share  the  output  of  our  analytic  services  on  a  patient’s  DNA  Data,  such  as reports, with their healthcare professional, who may tailor such recommendations. Similarly, we will share Personal Data (such as name and practice information) of the healthcare professional with their linked  patients.  Please  note  that  if  a  patient  requests  deletion  or  modification  of  their  Personal  Data, We will notify the associated healthcare professional of such request.
            </div>
            <div className="my-3">
              <span className="fw-bold fst-italic text-decoration-underline">Service  providers.</span>{" "}
              These  are  external  companies  that  We  use  to  help  Us  run  our  business  (e.g. consumer  services,  fraud  detection  and  identity  verification,  website  operation,  market  research companies,  support  services,  promotions,  website  development,  data  analysis,  CRC,  etc.).  Service providers,  and  their  selected  staff,  are  only  allowed  to  access  and  use  your  Personal  Data  on  Our behalf for the specific tasks that they have been requested to carry out, based on our instructions, and are  required  to  keep  your  Personal  Data  confidential  and  secure.  Where  required  by  applicable  law, you can obtain a list of the providers processing your Personal Data (see Section 12 to contact Us)
            </div>
            <div className="my-3">
              <span className="fw-bold fst-italic text-decoration-underline">External research partners.</span>{" "}
              We may share your aggregate, anonymous, or de-identified information with our external research partners (including our affiliates).
            </div>
            <div className="my-3">
              <span className="fw-bold fst-italic text-decoration-underline">Third party companies using Personal Data for their own marketing purposes.</span>{" "}
              Except in situations where  you  have  given  your  consent,  We  do  not  license  or  sell  your  Personal  Data  to  third  party companies for their own marketing purposes. Their identity will be disclosed at the time your consent is sought.
            </div>
            <div className="my-3">
              <span className="fw-bold fst-italic text-decoration-underline">Third party recipients using Personal Data for legal reasons or due to merger/acquisition.</span>{" "}
              We will disclose  your  Personal  Data  to  third  parties  for  legal  reasons  or  in  the  context  of  an  acquisition  or  a merger (see Section 5 for details).
            </div>
          </div>
        </div>

        {/* section 7*/}
        <div className="py-4">
          <div className="pgs-heading__sub ff-m fw-bold text-dark " id="7">
            7. RETENTION OF YOUR PERSONAL DATA
          </div>
          <div className="pgs-text py-2">
          Pure Encapsulations takes every reasonable step to ensure that your Personal Data are only processed for  the  minimum  period  necessary  for  the  purposes  set  out  in  this  Privacy  Notice.  The  criteria  for determining the retention period for your Personal Data are:
            <ol type="a">
              <li>
                Pure  Encapsulations  will  retain  copies  of  your  Personal  Data  in  a  form  that  allows  for identification only for as long as:
                <ol type="i">
                  <li>
                    We  maintain  an  ongoing  relationship  with  you  (e.g.  where  you  are  an  active  user  of the app, or you are included in our mailing list and have not unsubscribed),
                  </li>
                  <li>
                    Your  Personal  Data  are  necessary  in  connection  with  the  purposes  set  out  in  this Privacy Notice and We have a valid legal basis,
                  </li>
                </ol>
              </li>
              <li>
                Once  the  periods  noted  in  paragraphs  (a)  (i)  and  (ii)  above  have  lapsed,  Pure Encapsulations  may  also  (where  advisable)  retain  copies  of  your  Personal  Data  in  a form that allows for identification for the duration of:
                <ol type="i">
                  <li>
                    any applicable limitation period (i.e. any period during which a person could bring a legal claim against us), and
                  </li>
                  <li>
                    an  additional  2  months  following  the  end  of  the  applicable  limitation  period  (so  We  are able  to  identify  any  personal  data  of  a  person  who  may  bring  a  claim  at  the  end  of  the applicable period),
                  </li>
                </ol>
              </li>
              <li>
                In  addition,  if  any  relevant  legal  claims  are  brought,  We  may  continue  to  process  your Personal Data for such additional time necessary in connection with that claim.
              </li>
            </ol>
            <div className="my-3">
              During  the  periods  noted  in  paragraphs  b(i)  and  b(ii)  above,  We  will  restrict  our  processing  of  your Personal Data to storage or, and maintaining the security of, those data, except to the extent the data need to be reviewed in connection with any claim, or any obligation under applicable law.
            </div>
            <div className="my-3">
              Once the periods in paragraphs (a), (b) and (c) above, each to the extent applicable, have concluded, We  will  either  (i)  permanently  delete  or  destroy  the  relevant  Personal  Data  or  (ii)  anonymise  the relevant Personal Data.
            </div>
          </div>
        </div>

        {/* section 8*/}
        <div className="py-4">
          <div className="pgs-heading__sub ff-m fw-bold text-dark " id="8">
            8.  DISCLOSURE, STORAGE AND/OR TRANSFER OF YOUR PERSONAL DATA
          </div>
          <div className="pgs-text py-2">
            We  use  appropriate  measures  (described  below)  to  keep  your  Personal  Data  confidential  and  secure. Please note, however, that these protections do not apply to information you choose to share in public areas  such  as  third  party  social  networks.  Similarly,  these  protections  do  not  apply  to  Personal  Data shared between healthcare professionals and patients outside of the PureGenomics<sup>&reg;</sup> platforms.
            <div className="my-3">
              <span className="fw-bold fst-italic text-decoration-underline">People who can access your Personal Data.</span>{" "}
              Your Personal Data will be processed by our authorised staff or agents, on a need to know basis, depending on the specific purposes for which your Personal Data have been collected (e.g. our staff in charge of consumer care matters will have access to your consumer record). Please see section 6 for details of how we may share your Personal Data.
            </div>
            <div className="my-3">
              <span className="fw-bold fst-italic text-decoration-underline">Measures taken in operating environments.</span>{" "}
              We store your Personal Data in operating environments that use reasonable security measures to prevent unauthorised access. We follow reasonable standards to  protect  Personal  Data,  including  encrypting  all  data  at  rest  and  in-flight.  The  transmission  of information via the Internet is, unfortunately, not completely secure and although We will do our best to  protect  your  Personal  Data,  We  cannot  guarantee  the  security  of  the  data  during  transmission through our Websites/apps.
            </div>
            <div className="my-3">
              <span className="fw-bold fst-italic text-decoration-underline">Measures  We  expect  you  to  take.</span>{" "}
              It  is  important  that  you  also  play  a  role  in  keeping  your  Personal Data  safe  and  secure.  When  signing  up  for  an  online  account,  please  be  sure  to  choose  an  account password  that  would  be  difficult  for  others  to  guess  and  never  reveal  your  password  to  anyone  else. You are responsible for keeping this password confidential and for any use of your account. If you use a shared or public device, never choose to have your login ID/email address or password remembered and make sure to log out of your account every time you leave the device. You should also make use of any privacy settings or controls We provide you in our Websites/apps.
            </div>
            <div className="my-3">
              <span className="fw-bold fst-italic text-decoration-underline">Transfer of your Personal Data.</span>{" "}
              Pure  Encapsulations  is  based  in  the  USA.  Because  of  the  international  nature  of  our  business,  the storage  as  well  as  the  processing  of  your  Personal  Data  as  described  above  will  require  that  your Personal Data are ultimately transferred/transmitted to, and/or stored at, a destination outside of your country  of  residence  (e.g.  the  UK)  and  the  wider  European  Economic  Area  (“EEA”),  including  to countries  which  have  different  data  protection  standards  to  those  which  apply  in  the  UK  and  wider EEA. In particular, data collected within the PureGenomics<sup>&reg;</sup> app will be stored and processed in the USA  by  and  for  Pure  Encapsulations.  Where  applicable,  we  (i)  have  put  in  place  European Commission  approved  standard  contractual  clauses  to  protect  your  Personal  Data  (and  you  have  a right to ask Us for a copy of these clauses (by contacting Us as set out below) and/or (ii) will rely on your consent (where permitted by law).
            </div>
          </div>
        </div>

        {/* section 9*/}
        <div className="py-4">
          <div className="pgs-heading__sub ff-m fw-bold text-dark " id="9">
            9. YOUR RIGHTS
          </div>
          <div className="pgs-text py-2">
            <div className="my-3">
              <span className="fw-bold fst-italic text-decoration-underline">Access  to  Personal  Data.</span>{" "}
              You  have  the  right  to  access,  review  and  request  a  physical  or  electronic copy of information held about you. You also have the right to request information on the source of your Personal Data.
              <div className="my-3">
                These  rights  can  be  exercised  by  sending  us  an  e-mail  to {""}
                <a href="mailto:support@pure-encapsulations.co.uk">
                support@pure-encapsulations.co.uk
                </a>{" "}  or writing  to  us  at Pure  Encapsulations,  490  Boston  Post  Road,  Sudbury,  MA  01776  USA,  attaching  a copy of your ID or equivalent details (where requested by Us and permitted by law). If the request is submitted  by  a  person  other  than  you,  without  providing  evidence  that  the  request  is  legitimately made  on  your  behalf,  the  request  will  be  rejected.  Please  note  that  any  identification  information provided  to  Us  will  only  be  processed  in  accordance  with,  and  to  the  extent  permitted  by  applicable laws.
              </div>
            </div>
            <div className="my-3">
              <span className="fw-bold fst-italic text-decoration-underline">Additional rights (e.g. modification, deletion of Personal Data).</span>{" "}
              Where provided by law, you can (i) request  the  deletion,  portability,  correction  or  revision  of  your  Personal  Data; (ii)  limit  the  use  and disclosure of your Personal Data; and (iii) revoke consent to any of our data processing activities.
              <div className="border border-secondary px-3 py-2">
                Subject to applicable law, you may also have the following additional rights regarding the use of your Relevant Personal Data:
                <ul>
                  <li>
                    the  right  to  object,  on  grounds  relating  to  your  particular  situation,  to  the  use  of  your  Relevant Personal Data by us, or on our behalf; and
                  </li>
                  <li>
                    the right to object to the Processing of your Relevant Personal Data by us, or on our behalf, for direct marketing purposes.
                  </li>
                </ul>
              </div>
              <div className="my-3">
                Please note that, for both healthcare professionals and patients, in certain circumstances, We will not be able to delete your Personal Data without also deleting your user account.
              </div>
              <div className="my-3">
                PureGenomics<sup>&reg;</sup> helps  healthcare  professionals  to  offer  tailor-made  advice  and  nutritional  support  to their  patients.  It  is  therefore  a  condition  of  using  our  services  that  a  patient  account  within  the PureGenomics<sup>&reg;</sup> app must be linked to a healthcare professional account. If a healthcare professional requests their account be deleted, We will also delete any associated patient accounts. If this situation arises, We will notify the patients and provide not less than 30 days to download your Personal Data after  which  both  the  healthcare  professional  account  and  the  associated  patient  accounts  will  be deleted.
              </div>
              <div className="my-3">
                A healthcare professional may also request the deletion of a linked patient’s account if they cease to work with the patient or for any other reason
              </div>
              <div className="my-3">
                Please  note  that  if  a  patient  requests  deletion  or  modification  of  their  Personal  Data,  We  will  also notify the associated healthcare professional of such request.
              </div>
              <div className="my-3">
                We may be required to retain some of your Personal Data after you have requested deletion, to satisfy our  legal  or  contractual  obligations.  We  may  also  be  permitted  by  applicable  laws  to  retain  some  of your Personal Data to satisfy our business needs.
              </div>
              <div className="my-3">
                Where available, our app has a dedicated feature through which you can review and edit the Personal Data that you have provided and exercise certain of the rights above. Please note that We require our registered  patients  and  healthcare  professionals  to  verify  their  identity  (e.g.  login  ID/email  address, password)  before  they  can  access  or  make  changes  to  their  account  information.  This  helps  prevent unauthorised access to your account.
              </div>
              <div className="my-3">
                We  hope  that  We  can  satisfy  queries  you  may  have  about  the  way  we  process  your  Personal  Data. However,  if  you  have  unresolved  concerns  you  also  have  the  right  to  complain  to  competent  data protection authorities.
              </div>
            </div>
          </div>
        </div>

        {/* section 10*/}
        <div className="py-4">
          <div className="pgs-heading__sub ff-m fw-bold text-dark " id="1">
            10.  YOUR CHOICES ABOUT HOW WE USE AND DISCLOSE YOUR PERSONAL DATA
          </div>
          <div className="pgs-text py-2">
            We  strive  to  provide  you  with  choices  regarding  the  Personal  Data  that  you  provide  to  Us.  The following mechanisms give you control over your Personal Data:
            <div className="my-3">
              <span className="fw-bold fst-italic text-decoration-underline">Google Analytics.</span>{" "}
              You can opt out of Google Analytics. Please see Section 4 above.
            </div>
            <div className="my-3">
              <span className="fw-bold fst-italic text-decoration-underline">Advertising, marketing and promotions.</span>{" "}
                You can consent for your Personal Data to be used by Pure Encapsulations  to  promote  its  products  or  services  through  tick-box(es)  located  on  the  registration forms  or  by  answering  the  question(s)  presented  by  our  consumer  services  representatives.  If  you decide  that  you  no  longer  wish  to  receive  such  communications,  you  can  subsequently  unsubscribe from receiving marketing-related communications at any time, by following the instructions provided in  each  such  communication.  To  unsubscribe  from  marketing  communications  sent  by  any  medium, including  third  party  social  networks,  you  can  opt-out  at  any  time  by  unsubscribing  through  links available  in  our  communications,  logging  into  the  Websites/apps  or  third  party  social  networks  and adjusting your user preferences in your account profile by unchecking the relevant boxes, by emailing us at <a href="mailto:support@pure-encapsulations.co.uk">
                support@pure-encapsulations.co.uk
                </a>{" "} or by calling our consumer services team. Please note that, even  if  you  opt-out  from  receiving  marketing  communications,  you  will  still  receive  administrative communications  from  Us,  such  as  order  or  other  transaction  confirmations,  notifications  about  your account  activities  (e.g.  account  confirmations,  password  changes,  etc.),  and  other  important  non marketing related announcements.
            </div>
          </div>
        </div>

        {/* section 11*/}
        <div className="py-4">
          <div className="pgs-heading__sub ff-m fw-bold text-dark " id="11">
            11. CHANGES TO THIS NOTICE
          </div>
          <div className="pgs-text py-2">
            If  We  change  the  way  We  handle  your  Personal  Data,  We  will  update  this  Notice.  We  reserve  the right to make changes to our practices and this Notice at any time, please check back frequently to see any updates or changes to our Notice.
          </div>
        </div>

        {/* section 12*/}
        <div className="py-4">
          <div className="pgs-heading__sub ff-m fw-bold text-dark " id="12">
            12.  DATA CONTROLLERS & CONTACT
          </div>
          <div className="pgs-text py-2">
            To ask questions or make comments on this Notice and our privacy practices or to make a complaint about   our   compliance   with   applicable   privacy   laws,   please   contact   Us   at: <a href="mailto:support@pure-encapsulations.co.uk">
                support@pure-encapsulations.co.uk
                </a>{" "} or writing to us at 490 Boston Post Road, Sudbury, MA 01776 USA or call our consumer services team, on +44 2080 049974.
            <div className="my-3">
              We  will  acknowledge  and  investigate  any  complaint  about  the  way  We  manage  Personal  Data (including a complaint that We have breached your rights under applicable privacy laws).
            </div>
            <div className="overflow-x-scroll overflow-x-lg-hidden">
              <table className="pg-table my-3">
                <thead className="fw-bold">
                  <tr>
                    <td className="fw-bold fst-italic text-left">Data controllers</td>
                    <td className="fw-bold fst-italic text-left">Responsible for</td>
                  </tr>
                </thead>
                <tbody className="align-top">
                  <tr>
                    <td className="text-start">
                      <p>
                        Pure Encapsulations LLC
                      </p>
                      <p>
                        490 Boston Post Road
                      </p>
                      <p>
                        Sudbury, MA 01776 USA
                      </p>
                    </td>
                    <td>
                      All activities
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start">
                      <p className="fw-bold ">
                        In addition, all the following entities
                      </p>
                    </td>
                    <td>

                    </td>
                  </tr>
                  <tr>
                    <td className="text-start">
                      <p className="fw-bold ">
                        Scientific Wellness Ltd
                      </p>
                      <p className="fw-bold ">
                        71-75 Shelton Street, Covent Garden
                      </p>
                      <p className="fw-bold ">
                        London, England WC2H 9JQ
                      </p>
                    </td>
                    <td>
                      Customer Relationship Management , Marketing, PureGenomics support.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyUK;