import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const NumberInput = ({ question, onInput, inputValues }) => {
  const { t } = useTranslation();
  const answers = useSelector((state) => state.pg4.q_answers);
  const errors = useSelector((state) => state.pg4.q_errors);

  return (
    <div className="pg4-card">
      <div className="pg-heading__sub textc-primary__p">
        {t()}question?.question_text
      </div>
      <div className="pg-text">{t(question?.placeholder_en)}</div>
      <input
        type="number"
        className="my-2 msq-input"
        onInput={(e) => onInput({ [question.label]: Number(e.target.value) })}
      />
      {errors[question.question_id] && (
        <div className="text-danger my-2">
          <span>
            <i className="fas fa-info-circle me-1"></i>
            {t(errors[question.question_id])}
          </span>
        </div>
      )}
    </div>
  );
};

export default NumberInput;
