import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { getPatientReportDashboardData } from "../../store/actions/patientDashboard";
import { PgStepper } from "../../components/PgStepper";
import { AfterSNPFilePicked } from "../../components/Patient/AfterSNPFilePicked";
import { UploadDataManually } from "../../components/Patient/UploadDataManually";
import { PgProgressBar } from "../../components/Patient/PgProgressBar";
import UploadErrorComponent from "../../components/Patient/UploadErrorComponent";
import GeneticUploadSuccess from "../../components/Patient/GeneticUpdloadSuccess";
import { updatePatientReport } from "../../store/actions/patientReport";
import { useLocation, useNavigate } from "react-router-dom";

import {
  geneticUpload,
  UPDATE_GENETIC_UPLOAD,
  getGeneticUploadStatus,
  updateGeneticUpload,
} from "../../store/actions/genetics";

import { updateQuestionSession } from "../../store/actions/questions";
import AfterUploadStarted from "../../components/Patient/AfterUploadStarted";
import { useTranslation } from "react-i18next";

function GeneticUpload() {
  const location = useLocation();
  // redux state variable
  const fileSelected = useSelector((state) => state.genetics.fileSelected);
  const selectedFile = useSelector((state) => state.genetics.selectedFile);
  const fileUploadStarted = useSelector(
    (state) => state.genetics.fileUploadStarted
  );
  const responseFile = useSelector((state) => state.genetics.responseFile);
  const documentId = useSelector((state) => state.genetics.documentId);
  const uploadStatus = useSelector((state) => state.genetics.fileStatus);
  const uploadError = useSelector((state) => state.genetics.uploadError);
  const errorText = useSelector((state) => state.genetics.errorText);
  const currentPracticeID = useSelector(
    (state) => state.auth.currentPracticeID
  );
  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const currentUserId = useSelector((state) => state.auth.currentUserId);
  const userType = useSelector((state) => state.auth.userType);
  const token = useSelector((state) => state.auth.token);
  const loading = useSelector((state) => state.genetics.loading);
  const questionnaireData = useSelector(
    (state) => state.patientDashboard.questionnaireData
  );
  const recentQuestionnaireData = useSelector(
    (state) => state.patientDashboard.recentQuestionnaireData
  );
  const questionnaireInvited = useSelector(
    (state) => state.patientDashboard.questionnaireInvited
  );

  // redux actions
  const dispatch = useDispatch();

  const uploadGeneticAction = (
    token,
    currentPracticeID,
    currentPractice,
    data
  ) => dispatch(geneticUpload(token, currentPracticeID, currentPractice, data));
  const getGeneticUploadStatusAction = (
    token,
    currentPracticeID,
    currentPractice,
    data
  ) =>
    dispatch(
      getGeneticUploadStatus(token, currentPracticeID, currentPractice, data)
    );
  const updateGeneticUploadAction = (data) =>
    dispatch(updateGeneticUpload(data));
  const updateQuestionSessionAction = (
    token,
    currentPracticeID,
    currentPractice,
    data
  ) =>
    dispatch(
      updateQuestionSession(token, currentPracticeID, currentPractice, data)
    );
  const getPatientReportData = (token, currentPracticeID, currentPractice) =>
    dispatch(
      getPatientReportDashboardData(token, currentPracticeID, currentPractice)
    );
  const updatePatientReportAction = (data) =>
    dispatch(updatePatientReport(data));

  //local state variables
  let intervalTracker;
  const [showNext, setShowNext] = useState(false);
  const [disableDoneButton, setDisableDoneButton] = useState(true);
  const [showSuccessModel, setShowSuccessModel] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (fileUploadStarted && !_.isUndefined(documentId)) {
      updateLoadingStatus();
    }
    return () => {
      clearInterval(intervalTracker);
    };
  }, [documentId]);

  const { t, i18n } = useTranslation();

  const onChangeHandler = (event) => {
    const fileInput = event.target.files[0];
    if (
      fileInput.type === "text/plain" ||
      fileInput.type === "application/zip" ||
      fileInput.type === "application/x-zip-compressed"
    ) {
      updateGeneticUploadAction({
        fileSelected: true,
        fileStatus: "Uploading",
        fileUploadStarted: false,
        uploadError: false,
        selectedFile: fileInput,
      });
    } else {
      toast.error(t("patient_genup_err1"));
    }
  };

  const _updateLoadingStatus = () => {
    let data = {
      type: "genetic",
      document_id: documentId,
    };
    getGeneticUploadStatusAction(
      token,
      currentPracticeID,
      currentPractice,
      data
    ).then((response) => {
      console.log(response);
      if (!response.payload.success) {
        clearInterval(intervalTracker);
      }
      if (response.payload.current_state === "Complete") {
        clearInterval(intervalTracker);
        // updatePatientReportAction({
        //   currentPatientId: currentUserId,
        //   currentReportId: response.payload.genetic_report_id,
        // });
        // navigate("/patient/report");

        // case no PG-2981 comment
        navigate("/patient/dashboard");
      }
    });
  };

  const updateLoadingStatus = () => {
    _updateLoadingStatus();
    intervalTracker = setInterval(() => {
      console.log("Polling for file status..");
      _updateLoadingStatus();
    }, 5000);
  };

  const upload = () => {
    updateGeneticUploadAction({
      fileUploadStarted: true,
    });
    const data = new FormData();
    data.append("file", selectedFile);
    data.append("user_id", currentUserId);
    if (userType == "Patient") {
      data.append("patient_id", currentUserId);
    }
    data.append("user_type", userType);
    uploadGeneticAction(token, currentPracticeID, currentPractice, data).then(
      (response) => {
        setDisableDoneButton(false);
        if (response.error) {
          toast.error(response.payload.response?.message);
          updateGeneticUploadAction({
            uploadError: true,
            fileUploadStarted: true,
          });
        } else {
          updateGeneticUploadAction({
            fileStatus: "Uploading",
            uploadError: false,
            fileUploadStarted: true,
          });
        }
      }
    );
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="container-xl pt-1 pt-lg-5 px-0">
          <div
            className="pg-text pg-link text-color__p"
            onClick={() => navigate("/patient/dashboard")}
          >
            {" "}
            <i className="fas fa-chevron-left"></i> {t("dashboard")}
          </div>
          <div className="row mt-4 justify-content-center justify-content-lg-between">
            <div className="col-12 col-lg-6 order-lg-0 order-1">
              <hr className="my-5 d-block d-lg-none" />
              <div className="pg-title text-color__p">
                {t("patient_genup_txt1")}
              </div>
              {!fileUploadStarted && (
                <p className="pg-text my-2 my-lg-4">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("patient_genup_txt2"),
                    }}
                  />
                </p>
              )}
            </div>
          </div>
          <hr className="d-none d-lg-block" />
        </div>
      </div>
      <div className="container-fluid">
        <div className="container-xl pt-1 pt-lg-5 px-0">
          {/* before selecting file */}
          {!fileSelected && (
            <UploadDataManually
              onChange={onChangeHandler}
              userType={userType}
            />
          )}

          {/* after selecting file */}
          {fileSelected && !fileUploadStarted && (
            <div>
              {/* <div className="row justify-content-center my-3 mb-5"> */}
              {/* <div className="offset-lg-2 col-12 col-lg-5 px-lg-4 "> */}
              <AfterSNPFilePicked
                snpData={selectedFile}
                removeSelectedFile={upload}
                onStartUpload={upload}
                addAnotherFile={onChangeHandler}
              />
              {/* </div> */}
              <div className="my-5" />
              {/* </div> */}
            </div>
          )}

          {/* after clicking upload */}
          {fileUploadStarted && (
            <div className="container-fluid px-0">
              <AfterUploadStarted
                status={uploadStatus}
                isInvited={questionnaireInvited}
                qStatus={recentQuestionnaireData?.status}
                error={uploadError}
                errorText={errorText}
                onTryAgain={() => {
                  updateGeneticUploadAction({
                    selectedFile: null,
                    fileSelected: false,
                    fileUploadStarted: false,
                    uploadError: false,
                  });
                }}
                onCompleteQuest={() => {}}
              />

              <div className="row justify-content-center my-5">
                <div className="col-12 col-lg-5">
                  <span
                    dangerouslySetInnerHTML={{ __html: t("patient_genup_div") }}
                  />
                </div>
              </div>
              <div className="my-5 pt-5" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default GeneticUpload;
