import { useSelector } from "react-redux";
import "../../../styles/PG4/pg4table.scss";
// /home/rashid/Project/pg-3.0-web/src/styles/pg4/pg4table.scss
import { useTable, usePagination, useSortBy, useRowSelect } from "react-table";
import { reportTableData } from "./constant";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Popup from "reactjs-popup";
import { ReactComponent as InfoIcon } from "../../../assets/info.svg";
import ReportTableFooter from "./ReportTableFooter";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { isValidDate } from "../../../methods";

const DashboardReportTable = ({ _data }) => {
  const [showFooter, setshowFooter] = useState(false);
  const tableData = Array.isArray(_data?.data) ? _data?.data : [];
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  // const tableData = useSelector(state => state.pg4.dashboard.report_table || []);

  console.log("db", { _data });

  const columns = useMemo(
    () => [
      {
        Header: t("prac_past_report_table_header_1"),
        accessor: "date_generated",
        Cell: (value) => {
          return (
            //   <a className="pg-text" onClick={() => {}}>
            <div className="position-relative">
              {/* {moment(value.value).locale("fr")} */}
              {/* {moment(value.value).format("MMM Do, YYYY")} */}
              {isValidDate(value.value)
                ? format(new Date(value.value), "MMM do, yyyy", {
                    locale: i18n.language === "fr" ? fr : undefined,
                  })
                : value.value}
            </div>
            //   </a>
          );
        },
      },
      {
        Header: (
          <>
            {t("prac_past_report_table_header_2")}
            <Popup
              offsetY={10}
              className="dashboard-report-table__popover"
              on={["hover", "focus"]}
              trigger={(open) => (
                <InfoIcon
                  stroke="#fff"
                  height={30}
                  width={30}
                  className=""
                  style={{
                    marginLeft: "4px",
                    cursor: "pointer",
                  }}
                />
              )}
              contentStyle={{ padding: 12, width: "auto" }}
              position="bottom center"
              closeOnDocumentClick
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: t("pat_report_table_msq_score_content"),
                }}
              />
            </Popup>
          </>
        ),
        accessor: "msq_score",
      },
      {
        Header: t("current_status"),
        accessor: "current_status",
        sortable: false,
        filterable: false,
        Cell: (value) => {
          return (
            //   <a className="pg-text" onClick={() => {}}>
            <div className="position-relative">
              {String(value?.value).toLocaleLowerCase() === "completed" ? (
                <button
                  className="pg4-btn pg4-btn_primary"
                  onClick={() =>
                    navigate(
                      `/patient/report/new/${value?.row?.original?.report_id}`
                    )
                  }
                >
                  {t("patient_view_report_btn")}
                </button>
              ) : (
                <span className="text-capitalize">
                  {t(value?.value.toLowerCase())}
                </span>
              )}
            </div>
            //   </a>
          );
        },
      },
    ],
    []
  );
  const data = useMemo(() => tableData, [columns, tableData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    nextPage,
    canNextPage,
    previousPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 3,
        // sortBy: [{ id: 'date_generated', desc: true }]
      },
    },
    useSortBy,
    usePagination
  );

  return (
    <div>
      <div className="w-100 pg4-card p-0 rounded-0">
        <table
          table
          {...getTableProps()}
          className="w-100 pg4-table pg4-table-responsive pg4-table-striped pg4-w-100 "
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span className="fs-4">
                      {column.isSorted ? (column.isSortedDesc ? "↓" : "↑") : ""}{" "}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              // {rows?.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          {/* {error && (
        <tbody>
            <tr>
                <td colSpan="3">
                    <div className="w-100 text-center">
                        {t('pracpl_error')}
                    </div>
                </td>
            </tr>
        </tbody>
    )}*/}
          {tableData.length < 1 && (
            <tbody>
              <tr>
                <td colSpan="3">
                  <div className="w-100 text-center">{t("pracpl_line_5")}</div>
                </td>
              </tr>
            </tbody>
          )}
        </table>
        {showFooter && (
          <ReportTableFooter
            pageSize={pageSize}
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            nextPage={nextPage}
            previousPage={previousPage}
            setPageSize={setPageSize}
            pageIndex={pageIndex}
            pageOptions={pageOptions}
          />
        )}
      </div>
      {tableData.length > 3 && !showFooter && (
        <div className="d-flex">
          <div
            className="pg4-text textc-primary__p cp"
            onClick={() => {
              setshowFooter(true);
              setPageSize(6);
            }}
          >
            {t("view_more")} {">"}
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardReportTable;
