import React from 'react'
import { useTranslation } from 'react-i18next'

function _404() {
    const { t } = useTranslation()
    return (
        <div className="my-5 py-5">
            <div className="container">
                <div className="pg-title">
                    {t('error_page_line_1')}
                </div>
                <div className="pg-text">
                    {t('error_page_line_2')}
                </div>
            </div>
        </div>
    )
}

export default _404
