import PgAccordion from "../PgAccordion";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useLanguageConditions from "../../../hooks/useLanguageConditions";

function PatientFAQ({ history }) {
  const { t, i18n } = useTranslation();
  const { isCA, isUK, isUS } = useLanguageConditions();

  const navigate = useNavigate();

  return (
    <div className="py-5">
      <div className="container-xxl">
        <div className="pgs-title my-5 textc-primary__p">
          FAQs: the UK General Data Protection Regulation
        </div>

        {/* section 1*/}
        <div className="pgs-heading mt-5 mb-2 textc-primary__p">
          Introduction to the General Data Protection Regulation (GDPR)
        </div>
        <div className="pgs-text d-none d-lg-block">
          On May 25, 2018, the most significant piece of European data
          protection legislation to be introduced in 20 years took effect. The
          General Data Protection Regulation <b>(“GDPR”)</b> creates
          comprehensive data protection rules across the European Union.
          Following the UK’s exit from the European Union, it introduced its own
          version of this legislation, known as the UK-GDPR.
          <br />
          <br />
          UK-GDPR is about ensuring people can trust organizations to use their
          data fairly and responsibly.
          <br />
          <br />
          Pure Encapsulations, LLC (trading as PureGenomics<sup>&reg;</sup>) has
          prepared this document to help health care professionals{" "}
          <b>(“HCPs”)</b> and patients using our services in the UK to better
          understand the regulations and address some of the most common
          questions we receive about the UK-GDPR. You can find more information
          about how we process your data in the PureGenomics<sup>&reg;</sup>{" "}
          <a
            className="cursor-pointer"
            onClick={() => navigate(isCA ? "/privacy-policy" : "/privacy")}
            target="_blank"
          >
            Privacy Policy.{" "}
          </a>
        </div>
        <PgAccordion
          userType="Patient"
          question="Who does the regulation apply to?"
        >
          <div>
            <ul>
              <li>
                Organizations based in the UK (which may include a HCP
                practice).
              </li>
              <li>
                Organizations like PureGenomics that provide services and
                process data from or about individuals in the UK.
              </li>
            </ul>
          </div>
        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="What is the difference between a data controller and a data processor? Is PureGenomics<sup>&reg;</sup> a controller or processor of my personal data?"
        >
          <div>
            Under the UK-GDPR, organizations may either be a “data controller”
            or a “data processor”.
            <br />
            <br />
            <ul>
              <li>
                A data controller is an entity that determines the purposes
                (i.e., why) and means (i.e., how) of the data processing and is
                mainly responsible for complying with applicable data protection
                rules. It is possible to have more than one data controller if
                there are several entities that have input on the purposes and
                means of how the data is processed.
              </li>
              <li>
                A data processor is an entity that acts on behalf and under the
                instructions of a data controller. The UK-GDPR imposes
                additional obligations on processors (e.g., record-keeping
                obligations, assisting the controller with fulfilling
                individuals’ requests with regard to their personal data).
              </li>
            </ul>
            <b>
              PureGenomics<sup>&reg;</sup> is an independent data controller of
              the personal data of individuals that use the PureGenomics
              <sup>&reg;</sup> website and app (both HCPs and patients).{" "}
            </b>{" "}
            PureGenomics<sup>&reg;</sup> determines what type of information can
            be collected from HCPs (with their consent) (e.g. their login data
            and contact information) and patients (with their consent) i.e. the
            DNA data of the patient and their login and contact information.
          </div>
          <br />
          <div>
            As an independent data controller, PureGenomics<sup>&reg;</sup> has
            specific responsibilities to comply with, including:
            <ul>
              <li>
                Implementing appropriate technical and organizational measures
                to ensure and demonstrate that any data processing is performed
                in compliance with the UK-GDPR.
              </li>
              <li>
                Controllers’ obligations also relate to principles such as
                lawfulness, fairness and transparency, purpose limitation, data
                minimization, and accuracy, as well as fulfilling individuals’
                rights with respect to their data.
              </li>
            </ul>
            If HCPs collect personal data from their patients, they may also be
            an independent controller of that personal data and should take
            steps to ensure compliance with UK-GDPR including putting in place
            their own privacy policy.
          </div>
        </PgAccordion>

        <PgAccordion
          userType="Patient"
          question="What does “personal data” include under the UK-GDPR?"
          answer="The UK-GDPR defines personal data as “any information relating to an identified or identifiable natural person,” meaning any individual who can be identified, directly or indirectly, by one or more elements of data relating to his or her physical, physiological, mental, economic, cultural or social identity. The threshold to qualify as personal data is very low. If someone can link a data element to an individual using all the legal means reasonably likely to be used, the data qualifies as personal data."
        />
        <PgAccordion
          userType="Patient"
          question="What personal data rights are protected by the UK-GDPR and how is PureGenomics<sup>&reg;</sup> addressing the requirements?"
        >
          <div>
            The UK-GDPR strengthens individuals’ data rights under existing law,
            in particular:
            <br />
            <br />
            <ul>
              <li>the rights of access</li>
              <li>the right to rectification</li>
              <li>the right to erasure</li>
              <li>the right to object, and</li>
              <li>the right to withdraw consent.</li>
            </ul>
            In addition, the UK-GDPR created new rights for individuals,
            including:
            <br />
            <br />
            <ul>
              <li>the right to data portability, and</li>
              <li>the right to restrict processing activities.</li>
            </ul>
          </div>
          <div>
            The regulation is very prescriptive regarding how data controllers
            like PureGenomics<sup>&reg;</sup> need to reply to individuals’
            requests to exercise their rights (e.g., content of responses,
            timing).
            <br />
            <br />
            As an independent data controller, PureGenomics<sup>&reg;</sup> are
            required to fulfil individual data requests that fall under the
            UK-GDPR’s protection. The PureGenomics<sup>&reg;</sup> privacy
            policy is made available to patients and HCPs upon signing up to use
            our services and is available for you to review at any time via our
            site and application{" "}
            <a
              className="cursor-pointer"
              onClick={() => navigate(isCA ? "/privacy-policy" : "/privacy")}
              target="_blank"
            >
              Privacy Policy.{" "}
            </a>
            PureGenomics<sup>&reg;</sup> provides several methods to access and
            export personal data. Below is a summary table of the requirements
            around individuals’ rights and how PureGenomics<sup>&reg;</sup>{" "}
            complies with such requests.
          </div>
          <div className="overflow-x-scroll overflow-x-lg-hidden">
            <table className="pg-table my-3">
              <thead className="fw-bold text-center">
                <tr>
                  <td>Right</td>
                  <td>Controller Requirement</td>
                  <td>
                    How PureGenomics<sup>&reg;</sup> addresses the requirement
                  </td>
                </tr>
              </thead>
              <tbody className="align-top">
                <tr>
                  <td className="text-start">
                    <b>Right to information</b>
                  </td>
                  <td>
                    In order to allow individuals to enforce their data
                    protection rights, the UK-GDPR obliges controllers to
                    provide individuals with access to their personal data.
                    Specifically, individuals have the right to be provided with
                    information on the identity of the controller, the reasons
                    for processing their personal data and other relevant
                    information necessary to ensure the fair and transparent
                    processing of personal data.
                  </td>
                  <td>
                    <ul>
                      <li>
                        PureGenomics<sup>&reg;</sup> provides a secure
                        password-protected portal for the HCPs and patients. The
                        PureGenomics<sup>&reg;</sup> portal contains the
                        personal data that is provided by the HCP or patient.
                        The patient data is utilized by PureGenomics
                        <sup>&reg;</sup> and clear instructions are provided for
                        the patient on which data is utilized by us and how the
                        data will be processed to provide recommendations.
                      </li>
                      <li>
                        For further details on how and why PureGenomics
                        <sup>&reg;</sup> process your personal data, please see
                        our{" "}
                        <a
                          className="cursor-pointer"
                          onClick={() =>
                            navigate(isCA ? "/privacy-policy" : "/privacy")
                          }
                          target="_blank"
                        >
                          Privacy Policy.{" "}
                        </a>
                      </li>
                    </ul>
                  </td>
                </tr>

                <tr>
                  <td className="text-start">
                    <b>Right of access</b>
                  </td>
                  <td>
                    Controllers are required to provide individuals with access
                    to their personal data. Specifically, individuals have the
                    right to obtain the following:
                    <ul>
                      <li>
                        confirmation of whether, and where, the controller is
                        processing their personal data;
                      </li>
                      <li>information about the purposes of the processing;</li>
                      <li>
                        information about the categories of data being
                        processed;
                      </li>
                      <li>
                        information about the categories of recipients with whom
                        the data may be shared;
                      </li>
                      <li>
                        information about the period for which the data will be
                        stored (or the criteria used to determine that period);
                      </li>
                      <li>
                        information about the existence of the rights to
                        erasure, to rectification, to restriction of processing
                        and to object to processing;
                      </li>
                      <li>
                        information about the existence of the right to complain
                        to a data protection authority;
                      </li>
                      <li>
                        where the data were not collected from individuals,
                        information as to the source of the data; and
                      </li>
                      <li>
                        information about the existence of, and an explanation
                        of the logic involved in, any automated processing that
                        has a significant effect on individuals.
                      </li>
                    </ul>
                    Additionally, individuals may request a copy of the personal
                    data being processed.
                  </td>
                  <td>
                    <ul>
                      <li>
                        PureGenomics<sup>&reg;</sup> currently processes
                        personal data in the US in compliance with the UK-GDPR.
                      </li>
                      <li>
                        PureGenomics<sup>&reg;</sup> processes personal data on
                        behalf of HCPs and patients based in the UK in
                        accordance with our privacy policy.
                      </li>
                      <li>
                        The patient’s genetic information, questionnaire
                        information and lab result information are processed to
                        provide the HCP with personalized suggestions for the
                        patient. The HCP reviews the patient information and
                        then further customizes and provides recommendations to
                        the patient.
                      </li>
                      <li>
                        The PureGenomics<sup>&reg;</sup> system encrypts all
                        personal data.
                      </li>
                      <li>
                        PureGenomics<sup>&reg;</sup> employees do not have
                        access to encrypted data except as allowed by our
                        privacy policy when we specifically receive a request
                        from a practice or a patient for customer assistance.
                      </li>
                      <li>
                        PureGenomics<sup>&reg;</sup> only shares personal data
                        with third parties when necessary to fulfil our
                        contractual obligations to HCPs and patients and as
                        further described in our privacy policy.
                      </li>
                      <li>
                        The PureGenomics<sup>&reg;</sup> privacy policy provides
                        information about the rights that individuals have
                        including the rights to erasure, to rectification, to
                        restriction of processing and to object to processing;
                      </li>
                      <li>
                        PureGenomics do not purchase information about
                        individuals from any 3rd parties.
                      </li>
                    </ul>
                  </td>
                </tr>

                <tr>
                  <td className="text-start">
                    <b>Right to rectification</b>
                  </td>
                  <td>
                    Individuals are entitled to require a controller to rectify
                    any errors in their personal data. Controllers must ensure
                    that inaccurate or incomplete data are erased or rectified.
                    Individuals have the right to rectification of inaccurate
                    personal data.
                  </td>
                  <td>
                    PureGenomics<sup>&reg;</sup> does not directly access the
                    personal data but does provide the HCP and the patient with
                    the ability to rectify the patients’ personal data in the
                    PureGenomics<sup>&reg;</sup> system. The HCP and patient can
                    go to the “Manage Data” tile on their respective dashboards
                    and securely make a request from the application.
                    <br />
                    Please note that if a patient requests modification of their
                    personal data, PureGenomcis® will notify the associated HCP
                    of such request.
                  </td>
                </tr>

                <tr>
                  <td className="text-start">
                    <b>Right to erase</b>
                  </td>
                  <td>
                    Individuals are entitled to require a controller to delete
                    their personal data if the continued processing of those
                    data is not justified. Individuals have the right to erasure
                    of personal data (the "right to be forgotten") if:
                    <ul>
                      <li>
                        the data is no longer needed for their original purpose
                        (and no new lawful purpose exists);
                      </li>
                      <li>
                        the lawful basis for the processing is the individual's
                        consent, the individual withdraws that consent, and no
                        other lawful ground exists;
                      </li>
                      <li>
                        the individual exercises the right to object, and the
                        controller has no overriding grounds for continuing the
                        processing;
                      </li>
                      <li>the data have been processed unlawfully; or</li>
                      <li>erasure is necessary for compliance with UK law.</li>
                    </ul>
                  </td>
                  <td>
                    The patient and HCP can request erasure of their data via
                    the Manage Data tile in their respective dashboards for any
                    reason. In addition, if a HCP receives a request to erase a
                    patient’s personal data, the HCP can look up the
                    PureGenomics<sup>&reg;</sup> ID number available on the
                    Patient Detail page and send a request for erasure to{" "}
                    <a href="mailto:support@pure-encapsulations.co.uk">
                      support@pure-encapsulations.co.uk
                    </a>{" "}
                    PureGenomics<sup>&reg;</sup> will perform the erasure within
                    30 days of the request and provide written confirmation of
                    the erasure to the HCP.
                    <br />
                    Since the patient account is tied to the HCP account the
                    following will apply:
                    <ul>
                      <li>
                        If the patient requests erasure, the HCP will also be
                        notified.
                      </li>
                      <li>
                        A HCP may request the deletion of a linked patient’s
                        account if they cease to work with the patient or for
                        any other reason.
                      </li>
                      <li>
                        If the HCP requests erasure of their account then this
                        will also erase all of the associated patient data and
                        accounts. The patients will be notified by us and be
                        given 30 days to download their information, after which
                        the HCP data and account will be deleted.
                      </li>
                    </ul>
                    After deletion, neither the HCP nor the patient data will
                    exist in the PureGenomics<sup>&reg;</sup> system (although
                    we may be able to retain your personal data for limited
                    reasons as set out in our privacy policy).
                  </td>
                </tr>

                <tr>
                  <td className="text-start">
                    <b>Right to restrict processing</b>
                  </td>
                  <td>
                    In some circumstances, individuals may not be entitled to
                    require the controller to erase their personal data but may
                    be entitled to limit the purposes for which the controller
                    can process those data (e.g., the exercise or defence of
                    legal claims; protecting the rights of another person or
                    entity; purposes that serve a substantial public interest;
                    or such other purposes as the individuals may consent to).
                    Individuals may request processing be restricted if:
                    <ul>
                      <li>
                        the accuracy of the data is contested (and only for as
                        long as it takes to verify that accuracy);
                      </li>
                      <li>
                        the processing is unlawful and the individual requests
                        restriction (as opposed to exercising the right to
                        erasure);
                      </li>
                      <li>
                        the controller no longer needs the data for their
                        original purpose, but the data are still required by the
                        controller to establish, exercise or defend legal
                        rights; or
                      </li>
                      <li>
                        if verification of overriding grounds is pending, in the
                        context of an erasure request.
                      </li>
                    </ul>
                  </td>
                  <td>
                    HCPs and patients may directly request PureGenomics
                    <sup>&reg;</sup> to restrict processing of their data via
                    the Manage data tile in their dashboard. This will archive
                    their record.
                    <br />
                    In addition, as the accounts are linked, a HCP can also
                    “archive” a patient in the PureGenomics<sup>&reg;</sup>{" "}
                    system.
                  </td>
                </tr>

                <tr>
                  <td className="text-start">
                    <b>Right to data portability</b>
                  </td>
                  <td>
                    Individuals have the right to transfer their personal data
                    between controllers (e.g., to move account details from one
                    online platform to another), meaning that they may:
                    <ul>
                      <li>
                        receive a copy of their personal data in a structured,
                        commonly used, machine-readable format that supports
                        re-use;
                      </li>
                      <li>
                        transfer their personal data from one controller to
                        another;
                      </li>
                      <li>
                        store their personal data for further personal use on a
                        private device; and
                      </li>
                      <li>
                        have their personal data transmitted directly between
                        controllers without hindrance.
                      </li>
                    </ul>
                  </td>
                  <td>
                    If the HCP receives a request for a copy of a patient’s
                    personal data in the PureGenomics<sup>&reg;</sup> system,
                    the HCP can make a request through the Manage Data Tile in
                    the Pure Genomics dashboard. For a file in machine-readable
                    format, PureGenomics<sup>&reg;</sup> will generate this file
                    and make it available to the patient via the Manage Data
                    page in the patient portal.
                    <br />
                    <br />
                    PureGenomics<sup>&reg;</sup> has limited personal
                    information on the HCP, they may request their personal data
                    in machine-readable format from{" "}
                    <a href="mailto:support@pure-encapsulations.co.uk">
                      support@pure-encapsulations.co.uk
                    </a>{" "}
                  </td>
                </tr>

                <tr>
                  <td className="text-start">
                    <b>Right to object</b>
                  </td>
                  <td>
                    Individuals have the right to object, on grounds relating to
                    their particular situation, to the processing of personal
                    data, where the basis for that processing is either based on
                    “public interest” or “legitimate interests” of the
                    controller. The controller must cease such processing unless
                    it:
                    <ul>
                      <li>
                        can demonstrate compelling legitimate grounds for the
                        processing which override the interests, rights and
                        freedoms of the individual; or
                      </li>
                      <li>
                        requires the data in order to establish, exercise or
                        defend legal rights.
                      </li>
                    </ul>
                  </td>
                  <td>
                    If the HCP objects to the use of their own personal data or
                    receives an objection from a patient and needs to either
                    cease processing or erase a patient’s personal data, the HCP
                    can make the request via the PureGenomics<sup>&reg;</sup>{" "}
                    application from the Manage Data Tile in the dashboard.
                    <br />
                    <br />
                    If the patient objects they can also log into the patient
                    portal and make the request directly to us via the
                    PureGenomics<sup>&reg;</sup> application from the Manage
                    Data Tile in the dashboard.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </PgAccordion>
        {/* <PgAccordion
          userType="Patient"
          question="List of PureGenomics Sub-Processors"
          answer=""
        /> */}
        <PgAccordion
          userType="Patient"
          question="What happens if there is a data breach?"
          answer="PureGenomics<sup>&reg;</sup> takes security and privacy very seriously. The application is hosted on a state-of-the-art secure platform. In the unlikely event of a breach, PureGenomics<sup>&reg;</sup> will, where appropriate, notify impacted HCPs, patients and relevant data protection authorities promptly and no later than 72 hours after becoming aware of the breach."
        />
        <PgAccordion
          userType="Patient"
          question="What else is PureGenomics<sup>&reg;</sup> doing to ensure it is compliant with the UK-GDPR?"
        >
          <div>
            As part of ensuring it meets the UK-GDPR obligations, PureGenomics
            <sup>&reg;</sup> will maintain internal records of it processing
            activities and conduct privacy impact assessments as needed for
            certain processing activities.
            <br />
            <br />
            We have also updated our Privacy Policies to make sure they are GDPR
            compliant. For more information on our privacy-related policies,
            please refer to our <b>Application</b> Privacy Policy.
          </div>
        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="What’s next?"
          answer="PureGenomics<sup>&reg;</sup> is dedicated to evaluating and meeting the highest bar for data privacy globally and will continuously monitor the work of data protection authorities wherever our clients are located to ensure our Services include features and capabilities that meet data privacy obligations across jurisdictions."
        />
        {/* section 2 */}
        <div className="pgs-heading mt-5 mb-2 textc-primary__p">Contact Us</div>
        <div className="pgs-text d-none d-lg-block">
          Once you’ve had a chance to review these FAQs, please feel free to
          contact us if you have any questions or concerns:
          <br />
          <br />
          <div>
            <b>
              PureGenomics<sup>&reg;</sup>
            </b>
            <br />
            Pure Encapsulations LLC
            <br />
            Attention: Privacy
            <br />
            <a href="mailto:support@pure-encapsulations.co.uk">
              support@pure-encapsulations.co.uk
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PatientFAQ;
