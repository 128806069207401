import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from 'react-router-dom'
import { useTable, usePagination, useSortBy, useRowSelect } from "react-table";
import TextInputWithIcon from "../../TextInputWithIcon";
import TableFooter from "../Catalog/TableFooter";
import { parse } from 'query-string'
import moment from "moment";
import _ from "lodash";
import L_PatientList from "../../LoadingEffectComponent/L_PatientList";
import { useTranslation } from "react-i18next";

function PracticePatientLogTable({ patient, error, updatePatientTable, totalData, loading }) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
    const [controlledPageCount, setControlledPageCount] = useState(1);
    const [sPageIndex, setSPageIndex] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [recentURL, setRecentURL] = useState("")

    const [sortName, setsortName] = useState("");

    const continueSort = (url) => {
        // console.log(url);
        if (sortName) {
            updatePatientTable(`${url}&sort_key=email&sort_value=${sortName}`);
        } else {
            updatePatientTable(url)
        }
    }
    useEffect(() => {
        gotoPage(0);
    }, [searchText])

    const manualSort = (e) => {
        const field = e.target.innerText.split(' ')[0]?.toLowerCase();
        console.log(field);
        if (field === "name") {
            setsortName(sortName === "" ? 'DESC' : sortName === "DESC" ? "ASC" : "DESC")
            updatePatientTable(`${recentURL}&sort_key=last_name&sort_value=${sortName === "" ? 'DESC' : sortName === "DESC" ? "ASC" : "DESC"}`)
        }
    }

    const column = [
        { Header: `Name  ${sortName === "DESC" ? ' ↓' : ' ↑'}`, accessor: "patient_name" },
        {
            Header: "Request Type", accessor: "request_type", Cell: (raw_data) => {
                const data = raw_data.value;
                return <div>{_.startCase(data)}</div>
            }
        },
        {
            Header: "Request Date", accessor: "requested_date", Cell: (value) => {
                return <div>{moment(value.value).format('L')}</div>
            }
        },
        {
            Header: "Status", accessor: "aasm_state", Cell: (raw_data) => {
                const data = raw_data.value;
                return <div>{_.startCase(data)}</div>
            }
        },
        {
            Header: "Completed Date", accessor: "completed_date", Cell: (value) => {
                return <div>{value?.row?.original?.aasm_state === "completed" && moment(value.value).format('L')}</div>
            }
        },
    ];

    const columns = useMemo(() => column, [patient]);
    const data = useMemo(() => patient, [patient]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        canNextPage,
        previousPage,
        canPreviousPage,
        pageOptions,
        pageCount,
        gotoPage,
        setPageSize,
        state: { pageIndex, pageSize },
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: sPageIndex,
            },
            manualPagination: true,
            pageCount: controlledPageCount,
            autoResetSortBy: false,
            // autoResetPage: true,
            autoResetPage: false,
            disableSortRemove: true,
            defaultCanSort: true,
        },
        useSortBy,
        usePagination,
        useRowSelect
    );

    useEffect(() => {
        const url = parse(window.location.search);
        if (url.p_id) {
            setSearchText(url.p_id)
        }
    }, []);

    useEffect(() => {
        setControlledPageCount(Math.ceil(totalData / pageSize));
    });
    useEffect(() => {
        let url = `per_page=${pageSize}&page=${pageIndex + 1}`;
        if (searchText.trim().length > 1) url += `&search=${searchText}`;
        setRecentURL(url);
        const delayDebounceFn = setTimeout(() => {
            continueSort(url);
        }, 1000)
        return () => clearTimeout(delayDebounceFn)
        // updatePatientTable(url);
    }, [pageSize, pageIndex, searchText]);

    return (
        <div>
            <div className="row justify-content-center">
                <div className="col-md-10 col-lg-8">
                    <div className="my-3 mt-5">
                        <TextInputWithIcon
                            label="Patient Name"
                            placeholder="Search Patients"
                            value={searchText}
                            inputHandler={(e) => setSearchText(e.target.value)}
                            Icon={
                                <i className="fa fa-search text-secondary" aria-hidden="true"></i>
                            }
                        />
                    </div>
                </div>
            </div>
            {/* Table  */}
            {loading
                ? <L_PatientList />
                : <div>
                    <table {...getTableProps()} className="pg-table  my-2">
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())} onClick={manualSort}>
                                            {column.render("Header")}
                                            <span className="fs-4">
                                                {column.isSorted ? (column.isSortedDesc ? "↓" : "↑") : ""}{" "}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        {error && (
                            <tbody>
                                <tr>
                                    <td colSpan="5">
                                        <div className="w-100 text-center">
                                          {t('pracpl_error')}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        )}
                        {patient.length < 1 && !error && (
                            <tbody>
                                <tr>
                                    <td colSpan="5">
                                        <div className="w-100 text-center">{t('pracpl_nodata')}</div>
                                    </td>
                                </tr>
                            </tbody>
                        )}
                        {patient.length > 0 && !error && (
                            <tbody {...getTableBodyProps()}>
                                {page.map((row) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map((cell) => {
                                                return (
                                                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        )}
                    </table>
                    <TableFooter
                        pageSize={pageSize}
                        canNextPage={canNextPage}
                        canPreviousPage={canPreviousPage}
                        nextPage={nextPage}
                        previousPage={previousPage}
                        setPageSize={setPageSize}
                        pageIndex={pageIndex}
                        pageOptions={pageOptions}
                    />
                </div>}
        </div>
    )
}

export default PracticePatientLogTable