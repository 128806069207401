import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const useLanguageConditions = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  useEffect(() => {
    const handleLanguageChanged = (lng) => {
      setLanguage(lng);
    };

    // Subscribe to the languageChanged event
    i18n.on('languageChanged', handleLanguageChanged);

    // Cleanup the subscription on unmount
    return () => {
      i18n.off('languageChanged', handleLanguageChanged);
    };
  }, [i18n]);

  const isCA = language === "ca" || language === "fr";
  const isUK = language === "en_gb";
  const isUS = language === "en";

  return { isCA, isUK, isUS };
};

export default useLanguageConditions;