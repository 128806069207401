import { ProgressBar } from "react-bootstrap";

export function PgProgressBar({ status, color }) {
  let _status = status === "Processing" ? 'Analysing' : status;
  return (
    <div className="">
      <div className="mt-2 mt-lg-2">
        <ProgressBar
          animated
          variant={color}

          now={
            _status === "uploading"
              ? 10
              : _status === "importing"
                ? 36
                : _status === "analyzing"
                  ? 65
                  : _status === "complete"
                    ? 100
                    : 100
          }
          style={{ height: 30, borderRadius: "0px" }}
        />
      </div>
    </div>
  );
}
