import _ from 'lodash'
const QuestionnnaireFindings = ({ data,userType, t }) => {
  const patientTextColor = { color: userType === "Patient" ? "var(--pg_primary__p)" : 'var(--pg_primary)' }
  return (
    <div>
      {(!_.isEmpty(data) || data.length > 0) && <div>
        <hr className="my-5" />
        <div className="pg-heading" style={patientTextColor}>{t('trait_line_4')}</div>
        {data?.map((qf, i) => {
          return <div className="pg-text my-2" key={i}>{qf}</div>;
        })}
      </div>}
    </div>
  );
};
export default QuestionnnaireFindings
