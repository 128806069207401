import React, { useState, useEffect } from "react";
import _ from "lodash";
import { t } from "i18next";

function PgRatings({ noOfStars = 5, rated, size, style }) {
  const [stars, setStars] = useState(null);
  useEffect(() => {
    let divs = (
      <div className="pg-text d-inline-block" style={{ fontSize: size || 14 }}>
        {_.times(rated, () => (
          <i className="fas fa-star" style={{ marginRight: 3, ...style }}></i>
        ))}
        {_.times(noOfStars - rated, () => (
          <i
            className="far fa-star text-secondary"
            style={{ marginRight: 3 }}
          ></i>
        ))}
        <span className="mx-2" style={style}>
          {rated} {t("of")} {noOfStars}
        </span>
      </div>
    );
    setStars(divs);
  }, [noOfStars, rated]);

  return stars;
}

export default PgRatings;
