import * as gu_constant from "../actions/genetics";

const initialState = {
  fileSelected: false,
  fileUploadStarted: false,
  selectedFile: {},
  responseFile: null,
  documentId: null,
  uploadError: false,
  fileUploaded: false,
  fileStatus: "Uploading",
  loading: true,
  error: false,
};

const geneticUploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case gu_constant.GENETIC_UPLOAD_REQUEST: {
      return { ...state, loading: true };
    }
    case gu_constant.GENETIC_UPLOAD_SUCCESS: {
      console.log("result:", action.payload);
      return {
        ...state,
        loading: false,
        documentId: action.payload.document_id,
        fileUploaded: true,
        fileUploadStarted: true,
      };
    }
    case gu_constant.GENETIC_UPLOAD_FAILURE: {
      return { ...state, loading: false, uploadError: true };
    }
    case gu_constant.UPDATE_GENETIC_UPLOAD: {
      console.log("updateing the state...", action.payload);
      return { ...state, ...action.payload };
    }
    case gu_constant.GENETIC_UPLOAD_STATUS_REQUEST: {
      return { ...state };
    }
    case gu_constant.GENETIC_UPLOAD_STATUS_SUCCESS: {
      let fileStatus = state.fileStatus;
      console.log('payload', action.payload.current_state)
      if (action.payload.current_state) {
          fileStatus = action.payload.current_state === 'Processing' ? 'Analyzing' : action.payload.current_state;
      }
      return { ...state, uploadError: !action.payload.success, fileStatus ,errorText:action.payload.message};
    }
    case gu_constant.GENETIC_UPLOAD_STATUS_FAILURE: {
      return { ...state, uploadError: true };
    }
    case gu_constant.CLEAR_GENETIC_STATE: {
      return initialState;
    }
    default:
      return state;
  }
};

export default geneticUploadReducer;
