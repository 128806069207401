import React, { Component, useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { useTable, usePagination, useSortBy, useRowSelect } from "react-table";
import toastr from "react-hot-toast";
import _ from "lodash";
import { connect, useSelector, useDispatch } from "react-redux";
import PgMultiSelect from "../../../components/PgMultiSelect";
import TextInput from "../../../components/TextInput";
import { validateEmail } from "../../../methods";
import { addAdminUser } from "../../../store/actions/adminUser";
import { PgRadioButton } from "../../../components/PgRadioButton";

function AddAdminUser({
  showModal,
  onCancel,
  updateAdminUserTable,
  fromDashboard
}) {
  const [isValidForm, setIsValidForm] = useState(false);

  const token = useSelector(state => state.auth.token)
  const dispatch = useDispatch()

  //form fields
  const [fName, setfName] = useState("");
  const [lName, setlName] = useState("");
  const [email, setEmail] = useState("");
  const [countries, setCountries] = useState([]);
  const [countryList, setCountryList] = useState([]);
  
  //for validation
  const [fNameError, setfNameError] = useState(false);
  const [lNameError, setlNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [countriesError, setCountriesError] = useState(false);
  
  const onDone = () => {
    setfNameError(false)
    setlNameError(false)
    setEmailError(false)
    setCountriesError(false)
    
    if (email.length === 0 || !validateEmail(email)) setEmailError(true);
    if (fName.length === 0) setfNameError(true);
    if (lName.length === 0) setlNameError(true);
    if (countries.length === 0) setCountriesError(true);
    if (
      email.length !== 0 &&
      validateEmail(email) &&
      fName.length !== 0 &&
      lName.length !== 0 &&
      countries.length !== 0
    ) {
      setIsValidForm(true);
    }
  };

  useEffect(() => {
    if (isValidForm) {
      handleDone();
    }
  }, [isValidForm]);

  const handleDone = async () => {
    let data = {
      admin: {
        first_name: _.startCase(fName),
        last_name: lName,
        email: email.toLowerCase(),
        full_name: fName+" "+lName,
        assigned_regions: countries
      },
    };
    const response = await dispatch(addAdminUser(token, data));
    if (response.error) {
      //toastr.error("Email already exists in system. Please choose another");
      setIsValidForm(false);
    } else {
      onCancel();
      if(!fromDashboard) updateAdminUserTable();
      toastr.success("New Admin User Created");
    }
  };
  
  return (
    <div>
      <Modal
        show={showModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={onCancel}
        contentClassName="border-radius"
        backdrop="static"
        className="add-practitioner-modal"
      >
        <Modal.Body>
        <div style={{
                        position: 'absolute',
                        right: 25,
                        top: 15,
                        fontSize: 20,
                        cursor: "pointer",
                        color: 'var(--pg_primary)'
                    }} onClick={onCancel}>
              <div className="fas fa-times"></div>
          </div>
          <div className="container">
            <div className="text-center pg-title">
              Add Admin User
            </div>
            <div className="row">
              <div className="col-12">
                <TextInput
                  label="First Name"
                  showLabel={true}
                  required={true}
                  id="f-name__addadminuser"
                  placeholder="Enter Admin First Name"
                  value={fName}
                  error={fNameError}
                  type="text"
                  errorMessage="First Name is required"
                  inputHandler={(e) => {
                    setfName(e.target.value);
                  }}
                />

                <TextInput
                  label="Last Name"
                  showLabel={true}
                  required={true}
                  id="l-name__addadminuser"
                  placeholder="Enter Admin Last Name"
                  value={lName}
                  error={lNameError}
                  errorMessage="Last Name is required"
                  inputHandler={(e) => {
                    setlName(e.target.value);
                  }}
                />

                <TextInput
                  label="Email"
                  showLabel={true}
                  required={true}
                  emailField={true}
                  id="email-input__addadminuser"
                  placeholder="Enter Admin Email"
                  value={email}
                  error={emailError}
                  errorMessage="Enter valid Email"
                  inputHandler={(e) => {
                    if (
                      e.target.value.length < 0 &&
                      !validateEmail(e.target.value)
                    ) {
                      setEmailError(true);
                    } else {
                      setEmailError(false);
                      setEmail(e.target.value);
                    }
                  }}
                />

                <PgMultiSelect
                  label="Assigned Regions"
                  showLabel={true}
                  required={true}
                  id="country__addpractitioner"
                  placeholder="Select regions"
                  setSelectedItem={setCountries}
                  existingSelections={countries}
                  errorMessage="Enter at least one region"
                  options={["Global", "US", "UK/EU", "CA"]}
                  error={countries.length != 0 ? "" : countriesError}
                />
                
                <button
                  className="btn btn-primary text-light px-5 my-1 mx-1  w-100"
                  onClick={onDone}
                >
                  SAVE
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AddAdminUser;
