import Modal from "react-bootstrap/Modal";
import { report_icons } from "../../Patient/PG4/constant";
import SVGIcon from "../../SVGIcon";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFocusCardDetail } from "../../../store/actions/pg4report";
import L_FocusCardDetail from "../../LoadingEffectComponent/PG4/L_FocusCardDetail";
import _ from "lodash";
import { useTranslation } from "react-i18next";

const FocusCard = ({ data }) => {
  const [showDetails, setshowDetails] = useState(false);

  const iconKeys = Object.keys(report_icons);
  let Icon = null;
  const iconName = data?.focus_id_key?.toLowerCase();
  for (const iconKey of iconKeys) {
    if (iconName?.includes(iconKey)) {
      Icon = iconKey;
      break;
    }
  }
  const { t, i18n } = useTranslation();

  const toggleModal = () => setshowDetails(!showDetails);

  return (
    <div className="focus-card">
      {showDetails && (
        <FocusCardDetails
          data={data}
          show={showDetails}
          onClose={toggleModal}
        />
      )}
      <div className="focus-card__title">
        {Icon && <SVGIcon icon={report_icons[Icon]} />} {data?.focus_id}
      </div>
      <hr />
      <div className="focus-card__score">
        {t("prac_past_report_table_header_2")} :{" "}
        {
          <span
            className={`${data?.msq_score_key === "N/A" ? "bg-secondary" : ""}`}
            data-score={data?.msq_score_key?.toUpperCase()}
          >
            {" "}
            {data?.msq_score}{" "}
          </span>
        }
      </div>
      <div className="focus-card__impact">
        {t("report_focus_card_txt_1")} :{" "}
        <span data-impact={data?.dna_impact_key?.toUpperCase()}>
          {" "}
          {data?.dna_impact}{" "}
        </span>
      </div>
      <div className="focus-card__btn text-center " onClick={toggleModal}>
        <button className="pg4-btn pg4-btn_outline  focus-card__btn">
          {t("view_detail_txt")}
        </button>
      </div>
    </div>
  );
};

export default FocusCard;

const FocusCardDetails = ({ data, show, onClose }) => {
  const iconKeys = Object.keys(report_icons);
  let Icon = null;
  const iconName = data?.focus_id?.toLowerCase();
  for (const iconKey of iconKeys) {
    if (iconName?.includes(iconKey)) {
      Icon = iconKey;
      break;
    }
  }

  const token = useSelector((state) => state.auth.token);
  const userType = useSelector((state) => state.auth.userType);
  const currentPracticeID = useSelector(
    (state) => state.auth.currentPracticeID
  );
  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const reportData = useSelector((state) => state.pg4Report?.reportData);
  const [isLoading, setIsLoading] = useState(true);
  const [cardDetail, setcardDetail] = useState({});
  const dispatch = useDispatch();
  const getCardDetailsAction = (report_id, focus_id) =>
    dispatch(
      getFocusCardDetail({
        token,
        currentPractice,
        currentPracticeID,
        report_id,
        focus_id,
      })
    );

  useEffect(() => {
    getCardDetailsAction(reportData?.report_id, data.focus_id_key).then(
      (response) => {
        setIsLoading(false);
        if (!response.error) {
          setcardDetail(response.payload.data);
        }
      }
    );
  }, []);
  const { t, i18n } = useTranslation();
  return (
    <Modal show={show} onHide={onClose} size="xl">
      <Modal.Body className="p-0 relative">
        {isLoading ? (
          <L_FocusCardDetail />
        ) : (
          <>
            <div
              onClick={onClose}
              className="cp position-absolute fw-bold px-2 top-0 end-0 mt-3 me-3"
            >
              <i className="cp fas fa-times fs-3 text_primary" />
            </div>
            <div className="p-2 p-lg-4 rounded bg-light">
              <div className="d-flex justify-content-between">
                <div className="pg4-text__title text_primary">
                  <div className="focus-card__title">
                    {Icon && <SVGIcon icon={report_icons[Icon]} />}{" "}
                    {data?.focus_id}
                  </div>
                </div>
              </div>
              <div className="row my-3">
                <div className="col-12 col-md-6 col-lg-5">
                  <div className="pg4-text__subheading  text_primary">
                    {t("report_focus_card_txt_2")}
                  </div>
                  {!_.isEmpty(cardDetail?.questionnaire) ? (
                    <div className="">
                      {Object.entries(cardDetail?.questionnaire || {}).map(
                        ([msq, result], idx) => {
                          return (
                            <div
                              className="rounded border p-1 px-3 my-2"
                              key={idx}
                            >
                              <div className="focus-card__score d-flex m-0 my-1">
                                <div className="pg4-text__subheading text_primary me-2">
                                  {msq} {t("score")} :
                                </div>
                                <span
                                  data-score={result?.status_key?.toUpperCase()}
                                >
                                  {" "}
                                  {result?.status}{" "}
                                </span>
                              </div>
                              <div className="">
                                {Array.isArray(result.questions) &&
                                  result?.questions?.map((data) => (
                                    <div className="my-3">
                                      <div>
                                        <b>{data?.question}</b>
                                      </div>
                                      <div className="d-flex align-items-center mt-1">
                                        <div
                                          className="fw-bold d-flex flex-col align-items-center justify-content-center"
                                          style={{
                                            color: "#fff",
                                            background: "#000",
                                            borderRadius: "50%",
                                            height: 20,
                                            maxHeight: 20,
                                            width: 30,
                                            maxWidth: 20,
                                            fontSize: 12,
                                          }}
                                        >
                                          {data?.score}
                                        </div>
                                        <div className="ms-2">
                                          {data.answer?.join()}
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  ) : (
                    <div className="text-start">{t("pracpl_line_5")}</div>
                  )}
                </div>
                {/* right side column */}
                <div className="col-12 col-md-6 col-lg-7">
                  <div className="mb-2">
                    <div className="pg4-text__subheading text_primary">
                      {t("diet_txt")}
                    </div>
                    {Array.isArray(cardDetail?.diet) &&
                    cardDetail?.diet?.length > 0 ? (
                      <div>
                        <ul>
                          {cardDetail?.diet?.map((data) => (
                            <li>
                              {data?.recommendation}
                              <TriggerBadge data={data?.trigger} />
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      t("pracpl_line_5")
                    )}
                  </div>
                  <div className="mb-2">
                    <div className="pg4-text__subheading text_primary">
                      {t("lifestyle_txt")}
                    </div>
                    {Array.isArray(cardDetail?.lifestyle) &&
                    cardDetail?.lifestyle?.length > 0 ? (
                      <div>
                        <ul>
                          {cardDetail?.lifestyle?.map((data) => (
                            <li>
                              {data?.recommendation}
                              <TriggerBadge data={data?.trigger} />
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      t("pracpl_line_5")
                    )}
                  </div>
                  <div className="mb-2">
                    <div className="pg4-text__subheading text_primary">
                      {t("supplement_txt")}
                    </div>
                    {Array.isArray(cardDetail?.supplements) &&
                    cardDetail?.supplements?.length > 0 ? (
                      <ul>
                        {cardDetail?.supplements?.map((data) => (
                          <li className="mb-2">
                            <div
                              className="fw-bold"
                              dangerouslySetInnerHTML={{
                                __html: data.supplement_name,
                              }}
                            />
                            <span
                              dangerouslySetInnerHTML={{ __html: data.desc }}
                            />{" "}
                            <TriggerBadge data={data?.trigger} />
                            <div>{data.dosage}</div>
                            <div>{data.follow_up}</div>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      t("pracpl_line_5")
                    )}
                  </div>
                  {userType === "User" && (
                    <div className="mb-2">
                      <div className="pg4-text__subheading text_primary">
                        {t("report_further_assessment_txt")}
                      </div>
                      {Array.isArray(cardDetail?.further_assessment) &&
                      cardDetail?.further_assessment?.length > 0 ? (
                        <div>
                          <ul>
                            {cardDetail?.further_assessment?.map((data) => (
                              <li>
                                {data?.recommendation}
                                <TriggerBadge data={data?.trigger} />
                              </li>
                            ))}
                          </ul>
                        </div>
                      ) : (
                        t("pracpl_line_5")
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

const TriggerBadge = ({ data }) => {
  const { t, i18n } = useTranslation();
  console.log({ data });
  return (
    data &&
    (data === "lab" || data === "dna") && (
      <span class="badge bg-dark text-bg-secondary text-uppercase ms-1">
        {t(data)}
      </span>
    )
  );
};
