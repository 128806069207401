import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { usePagination, useRowSelect, useSortBy, useTable } from 'react-table';
import TableFooter from './RequestLogTableFooter';
import _ from 'lodash';
import moment from 'moment';
import { useTranslation } from "react-i18next";

function RequestLogTable({ logData = [], error, loading }) {
    const [controlledPageCount, setControlledPageCount] = useState(1);

    // useEffect(() => {
    //     // setPageSize(3)
    //     setControlledPageCount(Math.ceil(logData.length / pageSize));
    // });

    const column = [
        {
            Header: "Request Type", accessor: "request_type", Cell: (value) => {
                return <div>{_.startCase(value.value)}</div>
            }
        },
        {
            Header: "Request Date", accessor: "created_at", Cell: (value) => {
                return <div>{moment(value.value).format('L')}</div>
            }
        },
        {
            Header: "Status", accessor: "aasm_state", Cell: (value) => {
                return <div>{_.startCase(value.value)}</div>
            }
        },
        {
            Header: "Completed Date", accessor: "updated_at", Cell: (value) => {
                return <div>{value?.row?.original?.aasm_state === "completed" && moment(value.value).format('L')}</div>
            }
        },
        {
            Header: "", accessor: "patient_data_url", Cell: (value) => {
                if (value.value?.url)
                    return <div><a href={value.value.url} className="text-primary text-decoration-underline"> {"Download"}</a></div>
                else
                    return <div></div>
            }
        },

    ];
    const { t, i18n } = useTranslation();
    const columns = useMemo(() => column, []);
    const data = useMemo(() => logData, [logData]);
    const [sPageIndex, setSPageIndex] = useState(0);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        canNextPage,
        previousPage,
        canPreviousPage,
        pageOptions,
        pageCount,
        setPageSize,
        state: { pageIndex, pageSize },
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: sPageIndex,
                pageSize: 3
            },
            // manualPagination: true,
            // pageCount: controlledPageCount,
            // autoResetSortBy: false,
            // autoResetPage: true,
            // autoResetPage: false,
            // disableSortRemove: true,
            // defaultCanSort: true,
        },
        useSortBy,
        usePagination,
        useRowSelect
    );

    return <div>
        <table {...getTableProps()} className="pg-table my-2">
            <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render("Header")}
                                <span className="fs-4">
                                    {column.isSorted ? (column.isSortedDesc ? "↓" : "↑") : ""}{" "}
                                </span>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <>
                {error && (
                    <tbody>
                        <tr>
                            <td colSpan="5">
                                <div className="w-100 text-center">
                                   {t('pat_gdpr_log_table_unable_fetch')}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                )}
                {logData.length < 1 && !error && (
                    <tbody>
                        <tr>
                            <td colSpan="5">
                                <div className="w-100 text-center">{t('pat_gdpr_log_table_no_log')}</div>
                            </td>
                        </tr>
                    </tbody>
                )}
                {logData.length > 0 && !error && (
                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                )}
            </>
        </table>
        <TableFooter
            pageSize={pageSize}
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            nextPage={nextPage}
            previousPage={previousPage}
            setPageSize={setPageSize}
            pageIndex={pageIndex}
            pageOptions={pageOptions}
        />
    </div>;
}

export default RequestLogTable;
