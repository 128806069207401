export default function SuccessAlertComponent({ text ,top,right}) {
    return (
      <div className="d-inline-block w-75 text-center position-absolute" style={{top:top,right:right}}>
        <div
          className="d-inline-block ml-auto bgc-success text-light py-2  px-2 border-radius w-75 text-start"
          role="alert"
        >
          <i className="fas fa-check fw-5 mx-2 mr-3"></i>{" "}
          <span className="mr-5 mx-2"> {text}</span>
        </div>
      </div>
    );
  }