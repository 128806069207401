import ContentLoader from "react-content-loader";
function L_TraitCategories(props) {
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height="20%"
      viewBox="0 0 600 450"
      backgroundColor="#9e9e9e52"
      foregroundColor="#18368f80"
      {...props}
    >
      {/* trait header */}
      <rect x="0" y="5" rx="3" ry="3" width="280" height="30" />
      <rect x="540" y="20" rx="3" ry="3" width="18" height="18" />
      <rect x="560" y="20" rx="3" ry="3" width="18" height="18" />
      <rect x="580" y="20" rx="3" ry="3" width="18" height="18" />

      {/* trait card */}
      <rect x="0" y="60" rx="3" ry="3" width="190" height="120" />
      <rect x="205" y="60" rx="3" ry="3" width="190" height="120" />
      <rect x="410" y="60" rx="3" ry="3" width="190" height="120" />

      <rect x="0" y="190" rx="3" ry="3" width="190" height="120" />
      <rect x="205" y="190" rx="3" ry="3" width="190" height="120" />
      <rect x="410" y="190" rx="3" ry="3" width="190" height="120" />

      <rect x="0" y="320" rx="3" ry="3" width="190" height="120" />
      <rect x="205" y="320" rx="3" ry="3" width="190" height="120" />
      <rect x="410" y="320" rx="3" ry="3" width="190" height="120" />
    </ContentLoader>
  );
}

export default L_TraitCategories;
