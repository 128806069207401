import ContentLoader from "react-content-loader";

export default function L_LabUploadTest(props) {
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height="20%"
      viewBox="0 0 600 130"
      backgroundColor="#9e9e9e52"
      foregroundColor="#18368f80"
      {...props}
    >
      {/* Vendor */}
      <rect x="0" y="9" rx="3" ry="3" width="100" height="25" />
      <rect x="10" y="60" rx="3" ry="3" width="92" height="6" />
      <rect x="10" y="80" rx="3" ry="3" width="92" height="6" />
      <rect x="10" y="100" rx="3" ry="3" width="92" height="6" />
      <rect x="20" y="120" rx="3" ry="3" width="92" height="6" />

      {/* Test */}
      <rect x="190" y="9" rx="3" ry="3" width="120" height="25" />
      <rect x="190" y="60" rx="3" ry="3" width="140" height="20" />

      <rect x="400" y="9" rx="3" ry="3" width="120" height="25" />
      <rect x="400" y="60" rx="3" ry="3" width="140" height="20" />
    </ContentLoader>
  );
}
