import React, { Component, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getLifeStyleSuggestion
} from "../../../store/actions/patientReport";
import LifestyleItem from './LifestyleItem';
import NutritionDiet from './NutritionDiet';

import { useTranslation } from "react-i18next";

function LifestyleSuggestions() {

// redux state variable

  const currentPracticeID = useSelector(state => state.auth.currentPracticeID);
  const currentPractice = useSelector(state => state.auth.currentPractice);
  const token = useSelector(state => state.auth.token);
  const currentReportId = useSelector(state => state.patientReport.currentReportId);
  const lifestyleSuggestions = useSelector(state => state.patientReport.lifeStyleSuggestion);
  const loading = useSelector(state => state.patientReport.isGettingLifeStyleSuggestion);
  const userType = useSelector(state => state.auth.userType);


  const dispatch= useDispatch();
  // redux actions
  const getLifeStyleSuggestionAction = (token, currentPracticeID, currentPractice, reportId) => dispatch(getLifeStyleSuggestion(token, currentPracticeID, currentPractice, reportId));

  const { t, i18n } = useTranslation();
  const patientTextColor = { color: userType === "Patient" ? "var(--pg_primary__p)" : 'var(--pg_primary)' }
  useEffect(() => {
    getLifeStyleSuggestionAction(token, currentPracticeID, currentPractice, currentReportId)
  }, [])

  return (
    <div>
      {
        loading ?
          <div>Loading...</div>
          :
          <div className="my-3">
            <div className="pg-title mb-5" style={patientTextColor}>{t('lifestyle_title')}</div>
            <NutritionDiet
              title={t('lifestyle_heading_1')}
              lifestyleKey="nutrition_and_diet"
              description={lifestyleSuggestions.nutrition_and_diet}
              userType={userType}
              />
            <LifestyleItem
              title={t('lifestyle_heading_2')}
              lifestyleKey="exercise"
              description={lifestyleSuggestions.exercise}
              userType={userType}
              />
            <LifestyleItem
              title={t('lifestyle_heading_3')}
              lifestyleKey="sleep"
              description={lifestyleSuggestions.sleep}
              userType={userType}
              />
            <LifestyleItem
              title={t('lifestyle_heading_4')}
              lifestyleKey="stress_management"
              description={lifestyleSuggestions.stress_management}
              userType={userType}
              />
            <LifestyleItem
              title={t('lifestyle_heading_5')}
              lifestyleKey="sun_exposure"
              description={lifestyleSuggestions.sun_exposure}
              userType={userType}
              />
            <LifestyleItem
              title={t('lifestyle_heading_6')}
              lifestyleKey="environmental_exposures"
              description={lifestyleSuggestions.environmental_exposures}
              userType={userType}
            />
            <LifestyleItem
              title={t('lifestyle_heading_7')}
              lifestyleKey="medication_and_substance_use"
              description={lifestyleSuggestions.medication_and_substance_use}
              userType={userType}
            />
          </div>
      }
    </div>
  )
}

export default LifestyleSuggestions;
