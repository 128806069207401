import React, { useState, useEffect } from 'react'
import { Spinner } from "react-bootstrap";
import { clearCookie } from '../methods'

export const Loading = ({ title }) => {
  console.log('inside loading.................');
  const [showResetButton, setShowResetButton] = useState(false)
  useEffect(() => {
    const sto = setTimeout(() => {
      setShowResetButton(true)
    }, 5000);
    return () => clearTimeout(sto)
  }, [])

  const clearStorage = () => {
    clearCookie();
    localStorage.clear();
    window.location.href = ""
  }

  return (
    <div className="position-relative d-flex justify-content-center align-items-center" style={{ height: '100vh', background: 'rgba(20,50,140,0.1)' }}>
      <div className="d-inline-block text-center">
        <Spinner
          animation="border"
          as="div"
          className="textc-primary my-auto"
          role="status"
        ></Spinner>
        <div className="pg-heading w-100 my-3">{title || "Loading"}...</div>
        {showResetButton && <div className="position-absolute d-flex pg-text" style={{ bottom: 3, left: 5, fontSize: 10 }}>
          <div>Stuck on this page?</div>
          <div className="pg-link ms-2" onClick={clearStorage} style={{ fontSize: 10 }}>{" "} click here</div>
          <div>to reload</div>
        </div>}
      </div>
    </div>
  );
};
