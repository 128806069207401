import React, { useState, useEffect, useMemo } from "react";
import { useTable, usePagination, useSortBy, useRowSelect } from "react-table";
import TableFooter from "./TableFooter";
import TextInputWithIcon from "../../TextInputWithIcon";
import { PgRadioButton } from "../../PgRadioButton";

export const PatientTableForCatalog = ({
  patient,
  error,
  updatePatientTable,
  totalData,
  selectedPatient,
  setSelectedPatient,
  t,
}) => {
  const [controlledPageCount, setControlledPageCount] = useState(1);
  const [sPageIndex, setSPageIndex] = useState(0);
  const [searchText, setSearchText] = useState("");

  const column = [
    { Header: t('pracand_fname'), accessor: "first_name" },
    { Header: t('pracand_lname'), accessor: "last_name" },
    {
      Header: "",
      accessor: "id",
      sortable: false,
      filterable: false,
      Cell: (value) => {
        return (
          //   <a className="pg-text" onClick={() => {}}>
          <div className="position-relative">
            <label className="pg_radio__1  mx-3 pg-text">
              <input
                type="radio"
                name="hello"
                id={value.value}
                value={value.value}
                // checked={selectedPatient === value.value}
                defaultChecked={selectedPatient === value.value}
                onChange={() => setSelectedPatient(value.value)}
              />
              <div className="pg_radio__1__btn"></div>
            </label>
          </div>
          //   </a>
        );
      },
    },
  ];

  const columns = useMemo(() => column, [selectedPatient]);
  const data = useMemo(() => patient, [patient,selectedPatient]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    canNextPage,
    previousPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    setPageSize,
    state: { pageIndex, pageSize },
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: sPageIndex,
      },
      manualPagination: true,
      pageCount: controlledPageCount,
      autoResetSortBy: false,
      // autoResetPage: true,
      autoResetPage: false,
      disableSortRemove: true,
      defaultCanSort: true,
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    setControlledPageCount(Math.ceil(totalData / pageSize));
  });
  useEffect(() => {
    setSelectedPatient('')
    let url = `per_page=${pageSize}&page=${pageIndex + 1}`;
    if (searchText.trim().length > 1) url = `&full_name=${searchText}`;
    updatePatientTable(url);
  }, [pageSize, pageIndex, searchText]);

  return (
    <div>
      <div className="my-3 mt-5">
        <TextInputWithIcon
          label="Patient Name"
          placeholder={t('pracpl_line_2')}
          value={searchText}
          inputHandler={(e) => setSearchText(e.target.value)}
          Icon={
            <i className="fa fa-search text-secondary" aria-hidden="true"></i>
          }
        />
      </div>
      <table {...getTableProps()} className="pg-table patient-table my-2">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span className="fs-4">
                    {column.isSorted ? (column.isSortedDesc ? "↓" : "↑") : ""}{" "}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {error && (
          <tbody>
            <tr>
              <td colSpan="3">
                <div className="w-100 text-center">
                  {t('pracpl_error')}
                </div>
              </td>
            </tr>
          </tbody>
        )}
        {patient.length < 1 && !error && (
          <tbody>
            <tr>
              <td colSpan="3">
                <div className="w-100 text-center">{t('pracpl_nodata')}</div>
              </td>
            </tr>
          </tbody>
        )}
        {patient.length > 0 && !error && (
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
      <TableFooter
        pageSize={pageSize}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        nextPage={nextPage}
        previousPage={previousPage}
        setPageSize={setPageSize}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
      />
    </div>
  );
};

export default PatientTableForCatalog;
