import { createAction } from "redux-api-middleware";
const baseUrl = process.env.REACT_APP_PG3_APP_DOMAIN;
const protocol = process.env.REACT_APP_PROTOCOL;
const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;

export const GDPR_PATIENT_REQUEST = "GDPR_PATIENT_REQUEST";
export const GDPR_PATIENT_SUCCESS = "GDPR_PATIENT_SUCCESS";
export const GDPR_PATIENT_FAILURE = "GDPR_PATIENT_FAILURE";

export const getGDPRPatientListForAdmin = (token, url) => {
    return createAction({
        endpoint: `${baseUrl}/admin/gdpr/patient_list?${url}`,
        method: "GET",
        headers: {
            Authorization: token,
            "User-Type": "Admin",
            "Content-Type": "application/json",
        },
        types: [
            GDPR_PATIENT_REQUEST,
            GDPR_PATIENT_SUCCESS,
            GDPR_PATIENT_FAILURE,
        ],
    });
};

export const getGDPRPractitionerListForAdmin = (token, url) => {
    return createAction({
        endpoint: `${baseUrl}/admin/gdpr/practitioner_list?${url}`,
        method: "GET",
        headers: {
            Authorization: token,
            "User-Type": "Admin",
            "Content-Type": "application/json",
        },
        types: [
            GDPR_PATIENT_REQUEST,
            GDPR_PATIENT_SUCCESS,
            GDPR_PATIENT_FAILURE,
        ],
    });
};
