import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import {
  getPatientLabVendors,
  updateLabUpload,
} from "../../../store/actions/labUpload";
import L_LabUploadTest from "../../LoadingEffectComponent/Report/L_LabUploadTest";
import OtherLabVendorsSearch from "../../Practice/Lab/OtherLabVendorsSearch";
import { useTranslation } from "react-i18next";

function LabTestForManualEntry({
  labdata,
  selectTests,
  selectVendor,
  selectedVendorId,
}) {
  // redux state variable
  const currentPracticeID = useSelector(
    (state) => state.auth.currentPracticeID
  );
  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const token = useSelector((state) => state.auth.token);
  const othersVendors = useSelector((state) => state.labUpload.othersVendors);
  const currentPatientId = useSelector(
    (state) => state.patientReport.currentPatientId
  );
  const userType = useSelector((state) => state.auth.userType);
  const fileStatus = useSelector((state) => state.labUpload.fileStatus);

  const dispatch = useDispatch();
  // redux actions
  const getPatientLabVendorsAction = (
    token,
    currentPracticeID,
    currentPractice
  ) =>
    dispatch(getPatientLabVendors(token, currentPracticeID, currentPractice));
  const updateLabUploadAction = (data) => dispatch(updateLabUpload(data));

  const DEFAULT_VENDOR_ID = -1;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [currentVendor, setCurrentVendor] = useState(DEFAULT_VENDOR_ID);
  const [disableTextBox, setDisableTextBox] = useState(false);
  const [otherChecked, setOtherChecked] = useState(true);
  const [vendorName, setVendorName] = useState("");
  const { t, i18n } = useTranslation();
  const [currentDatatEntryStatus, setCurrentDataEntryStatus] = useState({
    manualEntry: true,
    practiceId: currentPracticeID,
    tests: [],
    error: false,
    from: "Lab Upload",
    vendorId: currentVendor,
    selectedVendorName: vendorName,
  });

  const findPatientIndex = (patientId) => {
    return _.findIndex(fileStatus, { patientId: patientId });
  };

  const redirectToManualEntry = () => {
    currentDatatEntryStatus.vendorId = currentVendor;
    currentDatatEntryStatus.selectedVendorName = vendorName;
    setCurrentDataEntryStatus(currentDatatEntryStatus);
    let lab_url = "/patient/lab/manual_entry";
    navigate(lab_url, { state: currentDatatEntryStatus });
  };

  useEffect(() => {
    getPatientLabVendorsAction(token, currentPracticeID, currentPractice).then(
      (res) => {
        const vendor_id = DEFAULT_VENDOR_ID;
        const patientIndex = findPatientIndex(currentPatientId);
        let oldFileStatus = [...fileStatus];
        if (patientIndex < 0) {
          oldFileStatus.push({
            patientId: currentPatientId,
            vendor_id,
            tests: [],
          });
          updateLabUploadAction({
            fileStatus: oldFileStatus,
          });
        } else {
          oldFileStatus[patientIndex] = { vendor_id };
        }
        setCurrentVendor(vendor_id);
        setLoading(false);
      }
    );
  }, []);

  const onSelect = (vendor) => {
    let vendorIndex = _.findIndex(othersVendors, { name: vendor });
    if (vendorIndex >= 0) {
      let vendorId =
        othersVendors[_.findIndex(othersVendors, { name: vendor })]["id"];
      setCurrentVendor(vendorId);
      selectVendor(vendorId);
    } else {
      setCurrentVendor(vendorIndex);
      selectVendor(vendorIndex);
    }
    return;
  };

  const selectedOtherVendor = (vendorId) => {
    let vendorIndex = _.findIndex(othersVendors, { id: vendorId });
    if (vendorIndex >= 0) {
      return othersVendors[_.findIndex(othersVendors, { id: vendorId })];
    }
    if (!otherChecked) {
      return "";
    }
  };

  const disableManualEntryBtn = () => {
    return currentVendor === DEFAULT_VENDOR_ID && vendorName === "";
  };

  let buttonStyle = "btn btn-primary text-light px-5 mx-1 w-100";
  let disabledStyle = "btn bg-secondary text-light px-5 mx-1 w-100";
  const style = {
    fontFamily: "Mulish",
    fontSize: 18,
    fontWeight: "700 !important",
    lineHeight: "40px",
  };

  return (
    <div className="container-xl py-1 py-lg-2">
      {loading ? (
        <L_LabUploadTest />
      ) : (
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="pg-heading my-4">{t("txt_vendor")}</div>
            <div className="pg-heading__sub my-4">
              {t("txt_select_your_lab_vendor")}:
            </div>
            <div className="mx-4">
              <OtherLabVendorsSearch
                options={othersVendors}
                objectKey="name"
                onSelect={onSelect}
                textBoxName="others"
                label="other vendors"
                showLabel={false}
                required={false}
                optional={false}
                isDisable={disableTextBox}
                index={0}
                id="other_vendors_text_box"
                selectedItem={selectedOtherVendor(currentVendor)}
                placeholder={t("txt_add_vendor_name")}
                setVendorName={setVendorName}
              />
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="pg-heading my-4">{t("txt_data_entry")}</div>
                <div className="pg-heading__sub my-4">
                  {t("txt_enter_results_manually")}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-12">
                {disableManualEntryBtn() ? (
                  <label
                    htmlFor="disabled-manual-entry-button"
                    className={disabledStyle}
                    style={style}
                    title={t("txt_please_select_your_lab_vendor")}
                  >
                    {t("txt_enter_data")}
                  </label>
                ) : (
                  <button
                    className={buttonStyle}
                    style={style}
                    onClick={redirectToManualEntry}
                    title={t("txt_enter_data")}
                  >
                    {t("txt_enter_data")}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}{" "}
      <div className="my-5"></div>
      <div>
        <div className="pg-heading__sub pt-5 textc-primary">
          {t("lab_page_footer_title")}
        </div>
        <div className="row my-3 pg-text__small">
          <div className="col-12 col-lg-4">
            <div className="pg-text__bold__small">{t("lab_test_group")}</div>
            <div className="p">{t("lab_test_list_vitamin_a_retinol")}</div>
            <div>{t("lab_test_list_vitamin_b6")}</div>
            <div>{t("lab_test_list_homocysteine")}</div>
            <div>{t("lab_test_list_folate_rbc")}</div>
            <div>{t("lab_test_name_folate(folicacid)serum")}</div>
            <div>{t("lab_test_list_vitamin_b12")}</div>
            <div>{t("lab_test_list_methymalonic_acid")}</div>
            <div>{t("lab_test_list_vitamin_d")}</div>
            <div>{t("lab_test_list_iron")}</div>
            <div>{t("lab_test_list_iron_tibc")}</div>
            <div>{t("lab_test_list_ferritin")}</div>
            <div>{t("lab_test_list_alkaline_phosphatase")}</div>
            <div>{t("lab_test_list_omega_check")}</div>
          </div>
          <div className="col-12 col-lg-4 pg-text__small">
            <div className="pg-text__bold__small">{t("lab_test_group_2")}</div>
            <div>{t("lab_test_list_fasting_glucose")}</div>
            <div>{t("lab_test_list_hemoglobin_a1c")}</div>
            <div>{t("lab_test_name_lpirscore")}</div>
            <div>{t("lab_test_name_triglycerides")}</div>
            <div className="pg-text__bold__small mt-2">
              {t("lab_test_group_4")}
            </div>
            <div>{t("lab_test_list_fasting_glucose")}</div>
            <div>{t("lab_test_list_hemoglobin_a1c")}</div>
            <div>{t("lab_test_list_adiponectin")}</div>
            <div className="pg-text__bold__small mt-2">
              {t("lab_test_group_6")}
            </div>
            <div>{t("lab_test_list_il_6")}</div>
            <div>{t("lab_test_list_tnf_alpha")}</div>
            <div>{t("lab_test_list_neutrophil")}</div>
            <div>{t("lab_test_list_eosinophil")}</div>
            <div>{t("lab_test_list_basophil")}</div>
            <div>{t("lab_test_list_glyca")}</div>
          </div>
          <div className="col-12 col-lg-4 pg-text__small">
            <div className="pg-text__bold__small">{t("lab_test_group_3")} </div>
            <div>{t("lab_test_list_fasting_glucose")}</div>
            <div>{t("lab_test_list_hemoglobin_a1c")}</div>
            <div className="pg-text__bold__small mt-2">
              {t("lab_test_group_5")}
            </div>
            <div>{t("lab_test_list_hscrp")}</div>
            <div>{t("lab_test_list_oxidized_ldl")}</div>
            <div>{t("lab_test_list_hdl_c")}</div>
            <div>{t("lab_test_list_hdl_p")}</div>
            <div>{t("lab_test_name_triglycerides")}</div>
            <div>{t("lab_test_list_q10")}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LabTestForManualEntry;
