import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ProgressBar } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux';
import { parse } from "query-string";
import toast from "react-hot-toast";
import _ from "lodash";
import { useNavigate } from "react-router-dom";

import { createPatient, verfiyPasswordToken } from "../../store/actions/addPatient";
import PasswordInput from "../../components/PasswordInput ";
import { validateEmail } from "../../methods";
import { Loading } from "../../components/Loading";
import "./Login.css";

function PatientRegistration({ history }) {
  const { t } = useTranslation()

  // redux state variable
  const loading = useSelector(state => state.addPatient.loading);
  const dispatch = useDispatch();
  // redux actions
  const registerPatient = (data, url) => dispatch(createPatient(data, url));
  const verifyToken = (token) => dispatch(verfiyPasswordToken(token));

  // local state  variables
  const [email, setEmail] = useState("");
  const [invitationToken, setInvitationToken] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [progressValue, setProgressValue] = useState(0);
  const [statusText, setStatusText] = useState("");
  const [c1, setC1] = useState(false);
  const [c2, setC2] = useState(false);
  const [c3, setC3] = useState(false);
  const [c4, setC4] = useState(false);
  const [c5, setC5] = useState(false);
  const [userType, setUserType] = useState("");
  const [authCode, setAuthCode] = useState("");
  const navigate = useNavigate();
  const [verfiyingToken, setverfiyingToken] = useState(true)

  //   to validate query
  useEffect(() => {
    const parsed = parse(window.location.search);
    if (_.isUndefined(parsed.email) || _.isUndefined(parsed.invitation_token)) {
      toast.error(t('prp_line_14'));
      return navigate("/");
    }
    if (!validateEmail(parsed.email) || parsed.invitation_token.length < 1) {
      toast.error(t('prp_line_14'));
      return navigate("/");
    }
    const email = parsed.email;
    setInvitationToken(parsed.invitation_token);
    setUserType(parsed.user_type || "Patient");
    setEmail(email);
    __verfiyToken(parsed.invitation_token);
    setAuthCode(parsed.auth_code);
  }, []);

  const __verfiyToken = async (token) => {
    const respose = await verifyToken(token);
    if (respose.error) {
      navigate('/users/login');
    } else setverfiyingToken(false)
  }

  //   to validate passowrd
  useEffect(() => {
    setC1(false);
    setC2(false);
    setC3(false);
    setC4(false);
    setC5(false);
    setProgressValue(0);
    setPasswordError(false);
    setConfirmPasswordError(false);
    setDisableSubmit(true);
    setStatusText("");

    if(userType == 'Patient'){
      if (password.length > 9) {
        setC1(true);
        setProgressValue(75);
        setStatusText(t('prp_line_16'));
      } else if (password.length > 7) {
        setProgressValue(75);
        setStatusText(t('prp_line_16'));
      } else if (password.length < 2) {
        setStatusText(t('prp_line_17'));
        setProgressValue(25);
      }
    }
    else if(userType == 'User'){
      if (password.length > 9) {
        setC1(true);
        setProgressValue(75);
        setStatusText(t('prp_line_16'));
      } else if (password.length > 7) {
        setProgressValue(75);
        setStatusText(t('prp_line_16'));
      } else if (password.length < 2) {
        setStatusText(t('prp_line_17'));
        setProgressValue(25);
      }
    }
    else{
      if (password.length > 15) {
        setC1(true);
        setProgressValue(75);
        setStatusText(t('prp_line_16'));
      } else if (password.length > 12) {
        setProgressValue(75);
        setStatusText(t('prp_line_16'));
      } else if (password.length > 7) {
        setStatusText(t('prp_line_17'));
        setProgressValue(50);
      } else if (password.length < 8 && password.length > 0) {
        setStatusText(t('prp_line_17'));
        setProgressValue(25);
      }
    }

    if (/(?=.*[A-Z])/.test(password)) setC2(true);
    if (/(?=.*[a-z])/.test(password)) setC3(true);
    if (/(?=.*\d)/.test(password)) setC4(true);
    if (/(?=.*[\W_])/.test(password)) setC5(true);

    if (confirmPassword.length > 0 && confirmPassword !== password) {
      setConfirmPasswordError(true);
      // setDisableSubmit(true)
    }

    if(c1 && c2 && c3 && c4 && c5){
      setStatusText(t('prp_line_15'));
      setProgressValue(100);
    }

    if (c1 && c2 && c3 && c4 && c5 && password === confirmPassword) {
      setConfirmPasswordError(false);
      setDisableSubmit(false);
    }
  }, [password, confirmPassword, c1, c2, c3, c4, c5]);

  let handleLogin = async (e) => {
    e.preventDefault();
    var redirectUrl, data;
    if (userType === "User") {
      redirectUrl = "invitation";
      data = {
        user: {
          invitation_token: invitationToken,
          password,
          password_confirmation: confirmPassword,
        },
      };
    } else if (userType === "AdminUser") {
      redirectUrl = "admin_users/invitation";
      data = {
        admin_user: {
          invitation_token: invitationToken,
          password,
          password_confirmation: confirmPassword,
        },
      };
    } else {
      redirectUrl = "patients/invitation";
      data = {
        patient: {
          invitation_token: invitationToken,
          password,
          password_confirmation: confirmPassword,
        },
      };
    }
    data['auth_code'] = authCode
    const response = await registerPatient(data, redirectUrl);
    if (!response.error) {
      toast.success(t('prp_line_18'));
      navigate("/users/login");
    }
  };
  return (
    <div className="container-fluid">
      {verfiyingToken
        ? <Loading />
        : <div className="py-3 px-lg-5 mx-0 mx-lg-5">
          <div className="my-5">
            {userType === "Patient" && (
              <div className="pg-title">{t('prp_line_1')}</div>
            )}
            {userType === "Practitioner" && (
              <div className="pg-title">{t('prp_line_2')}</div>
            )}
            {userType === "AdminUser" && (
              <div className="pg-title">{t('prp_line_3')}</div>
            )}
            <div className="pg-text py-4">
              {t('prp_line_4')}
            </div>
          </div>
          <form onSubmit={handleLogin} className="mb-5">
            <div className="row justify-content-center justify-content-md-start">
              <div className="col-lg-4 col-12">
                <TextInput
                  value={email}
                  placeholder="Email Address"
                  isDisable={true}
                  className="my-0 py-0"
                />
                <PasswordInput
                  className="my-0 py-0"
                  placeholder={t('prp_line_5')}
                  value={password}
                  error={passwordError}
                  errorMessage=
                  {
                    userType === 'Patient'
                      ? "Password must be minimum of 10 characters long and contain at least 1 lowercase letter, 1 uppercase letter, 1 special character and 1 number"
                      : userType === 'User'
                      ? "Password must be minimum of 10 characters long and contain at least 1 lowercase letter, 1 uppercase letter, 1 special character and 1 number"
                      : "Password must be minimum of 16 characters long and contain at least 1 lowercase letter, 1 uppercase letter, 1 special character and 1 number"
                  }
                  inputHandler={(e) => setPassword(e.target.value)}
                />
                <PasswordInput
                  placeholder={t('prp_line_7')}
                  value={confirmPassword}
                  error={confirmPasswordError}
                  errorMessage={t('prp_line_8')}
                  inputHandler={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
              <div className="offset-lg-1 col-lg-4 col-10 pg-text pt-3 ps-0">
                <div className="ms-4 ps-1">{t('prp_line_9')}</div>
                <div className={c1 ? "my-3 text-success" : "my-3 text-secondary"}>
                  {c1 ? (
                    <i className="fas fa-check-circle fs-5 me-1"></i>
                  ) : (
                    <i className="far fa-check-circle fs-5 me-1"></i>
                  )}{" "}
                  {
                  userType === 'Patient'
                    ? "Minimum of 10 characters"
                    : userType === 'User'
                    ? "Minimum of 10 characters"
                    : "Minimum of 16 characters"
                  }
                </div>
                <div className={c2 ? "my-3 text-success" : "my-3 text-secondary"}>
                  {c2 ? (
                    <i className="fas fa-check-circle fs-5 me-1"></i>
                  ) : (
                    <i className="far fa-check-circle fs-5 me-1"></i>
                  )}{" "}
                  {t('prp_line_10')}
                </div>
                <div className={c3 ? "my-3 text-success" : "my-3 text-secondary"}>
                  {c3 ? (
                    <i className="fas fa-check-circle fs-5 me-1"></i>
                  ) : (
                    <i className="far fa-check-circle fs-5 me-1"></i>
                  )}{" "}
                  {t('prp_line_11')}
                </div>
                <div className={c4 ? "my-3 text-success" : "my-3 text-secondary"}>
                  {c4 ? (
                    <i className="fas fa-check-circle fs-5 me-1"></i>
                  ) : (
                    <i className="far fa-check-circle fs-5 me-1"></i>
                  )}{" "}
                  {t('prp_line_12')}
                </div>
                <div className={c5 ? "my-3 text-success" : "my-3 text-secondary"}>
                  {c5 ? (
                    <i className="fas fa-check-circle fs-5 me-1"></i>
                  ) : (
                    <i className="far fa-check-circle fs-5 me-1"></i>
                  )}{" "}
                  {t('prp_line_20')}
                </div>
                <div className="ms-4 ps-1">
                  {t('prp_line_13')}{" "}
                  <span
                    className={
                      progressValue <= 50
                        ? "text-danger"
                        : progressValue <= 75
                          ? "text-info"
                          : "text-success"
                    }
                  >
                    {" "}
                    {statusText}
                  </span>
                  <div className="pe-lg-3 me-lg-3">
                    <ProgressBar
                      now={progressValue}
                      variant={
                        progressValue <= 50
                          ? "danger"
                          : progressValue <= 75
                            ? "info"
                            : "success"
                      }
                      style={{ height: 10, width: "100%" }}
                      className="border-radius my-3"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row my-lg-0 my-5">
              <div className="col-12 col-lg-4">
                <input
                  type="submit"
                  value={t("register")}
                  disabled={disableSubmit}
                  className="btn bgc-primary text-light w-100"
                />
              </div>
            </div>
          </form>
        </div>}
    </div>
  );
}


export default PatientRegistration;

function TextInput({
  type,
  inputHandler,
  placeholder,
  error,
  errorMessage,
  value,
  isDisable,
}) {
  const style = {
    padding: 15,
    width: "100%",
    outline: "none",
    border: error ? "2px solid #f00" : "3px solid #acd4ff",
    borderRadius: "4px",
    height: 50,
    transition: "all .3s ease-in-out",
  };
  const { t } = useTranslation();
  return (
    <div className="w-90 my-3">
      <input
        placeholder={placeholder}
        type={type}
        onInput={inputHandler}
        value={value}
        style={style}
        disabled={isDisable}
      />
      <div className="text-secondary mx-3 my-1 transition-all">{t('required')}</div>
    </div>
  );
}
