// src/methods/myaccount-apis.js

const protocol = process.env.REACT_APP_PROTOCOL;
const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;

export const getPractitionersList = ({
  token,
  currentPracticeID,
  currentPractice,
}) => {
  return fetch(
    `${protocol}//${currentPractice}${PG3_BASE_URL}/fetch_practitioners`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        "Current-Practice": currentPracticeID,
      },
    }
  ).then((response) => response.json());
};

export const getCountries = ({ token, currentPracticeID, currentPractice }) => {
  return fetch(
    `${protocol}//${currentPractice}${PG3_BASE_URL}/fetch_countries`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        "Current-Practice": currentPracticeID,
      },
    }
  ).then((response) => response.json());
};

export const getPractitionerTypes = ({
  token,
  currentPracticeID,
  currentPractice,
}) => {
  return fetch(
    `${protocol}//${currentPractice}${PG3_BASE_URL}/fetch_practitioner_types`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        "Current-Practice": currentPracticeID,
      },
    }
  ).then((response) => response.json());
};

export const getPractitionerDetails = ({
  token,
  currentPracticeID,
  currentPractice,
  id,
}) => {
  return fetch(
    `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/users/${id}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        "Current-Practice": currentPracticeID,
      },
    }
  ).then((response) => response.json());
};

export const editPractitioner = ({
  token,
  currentPracticeID,
  currentPractice,
  id,
  data,
}) => {
  return fetch(
    `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/users/${id}`,
    {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        "Current-Practice": currentPracticeID,
      },
    }
  ).then((response) => response.json());
};

export const getPractitionerTerms = ({
  token,
  currentPracticeID,
  currentPractice,
}) => {
  return fetch(
    `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/terms_and_conditions/${currentPracticeID}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        "Current-Practice": currentPracticeID,
      },
    }
  ).then((response) => response.json());
};

export const getPatientDetails = ({
  token,
  currentPracticeID,
  currentPractice,
  id,
}) => {
  return fetch(
    `${protocol}//${currentPractice}${PG3_BASE_URL}/patient/account_details/${id}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        "Current-Practice": currentPracticeID,
      },
    }
  ).then((response) => response.json());
};

export const editPatient = ({
  token,
  currentPracticeID,
  currentPractice,
  id,
  data,
}) => {
  return fetch(
    `${protocol}//${currentPractice}${PG3_BASE_URL}/patient/patients/${id}`,
    {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        "Current-Practice": currentPracticeID,
      },
    }
  ).then((response) => response.json());
};
