import React from "react";
import { useTranslation } from "react-i18next";
export default React.forwardRef(function TextInput(
  {
    type,
    inputHandler,
    placeholder,
    error,
    errorMessage,
    value,
    isDisable,
    id,
    required,
    label,
    showLabel,
    optional,
    emailField,
  },
  ref
) {
  const { t, i18n } = useTranslation();
  const style = {
    padding: 15,
    width: "100%",
    outline: "none",
    border: error ? "2px solid #f00" : "3px solid #acd4ff",
    borderRadius: "4px",
    height: 50,
    transition: "all .3s ease-in-out",
  };

  return (
    <div className="my-2">
      {showLabel && (
        <div>
          <div className="d-inline-block pg-text__bold textc-primary">
            {label}
          </div>
          {required && (
            <span className="textc-primary fst-italic"> ({t("required")})</span>
          )}
          {optional && !isDisable && (
            <span className="textc-primary fst-italic"> ({t("optional")})</span>
          )}
        </div>
      )}
      <div>
        <input
          ref={ref}
          placeholder={placeholder}
          type={type}
          onInput={inputHandler}
          value={value}
          style={style}
          disabled={isDisable}
          id={id}
          className={emailField && "email-input"}
        />
        <div
          className="text-danger mx-2 my-1 transition-all"
          style={
            error && errorMessage !== ""
              ? { opacity: 1 }
              : { opacity: 0, maxHeight: "24px" }
          }
        >
          <div
            dangerouslySetInnerHTML={{
              __html: `<i class="fas fa-info-circle me-1"></i>` + errorMessage,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
});
