import ContentLoader from "react-content-loader";
function L_LabUpload(props) {
  if(props.loaderSection == 3){
    return (
      <ContentLoader
        speed={2}
        width="100%"
        height="100%"
        viewBox="0 0 600 250"
        backgroundColor="#9e9e9e52"
        foregroundColor="#18368f80"
        {...props}
      >

        {/* Diagram title */}

        <rect x="67" y="18" rx="0" ry="0" width="98" height="14" />
    <rect x="70" y="38" rx="0" ry="0" width="49" height="7" />
    <rect x="70" y="50" rx="0" ry="0" width="37" height="3" />
    <rect x="69" y="60" rx="0" ry="0" width="52" height="19" />
    <rect x="218" y="17" rx="0" ry="0" width="98" height="14" />
    <rect x="70" y="89" rx="0" ry="0" width="49" height="7" />
    <rect x="70" y="101" rx="0" ry="0" width="37" height="3" />
    <rect x="69" y="111" rx="0" ry="0" width="52" height="19" />
    <rect x="228" y="38" rx="0" ry="0" width="49" height="7" />
    <rect x="228" y="50" rx="0" ry="0" width="37" height="3" />
    <rect x="227" y="60" rx="0" ry="0" width="52" height="19" />
    <rect x="228" y="89" rx="0" ry="0" width="49" height="7" />
    <rect x="228" y="101" rx="0" ry="0" width="37" height="3" />
    <rect x="227" y="111" rx="0" ry="0" width="52" height="19" />



      </ContentLoader>
    );
  }
  else{
    return (
      <ContentLoader
        speed={2}
        width="100%"
        height="100%"
        viewBox="0 0 400 150"
        backgroundColor="#9e9e9e52"
        foregroundColor="#18368f80"
        {...props}
      >

        {/* Diagram title */}

    <rect x="67" y="18" rx="0" ry="0" width="98" height="14" />
    <rect x="70" y="38" rx="0" ry="0" width="49" height="7" />
    <rect x="70" y="50" rx="0" ry="0" width="37" height="3" />
    <rect x="69" y="60" rx="0" ry="0" width="52" height="19" />
    <rect x="218" y="17" rx="0" ry="0" width="98" height="14" />
    <rect x="70" y="89" rx="0" ry="0" width="49" height="7" />
    <rect x="70" y="101" rx="0" ry="0" width="37" height="3" />
    <rect x="69" y="111" rx="0" ry="0" width="52" height="19" />
    <rect x="228" y="38" rx="0" ry="0" width="49" height="7" />
    <rect x="228" y="50" rx="0" ry="0" width="37" height="3" />
    <rect x="227" y="60" rx="0" ry="0" width="52" height="19" />
    <rect x="228" y="89" rx="0" ry="0" width="49" height="7" />
    <rect x="228" y="101" rx="0" ry="0" width="37" height="3" />
    <rect x="227" y="111" rx="0" ry="0" width="52" height="19" />


      </ContentLoader>
    );
  }

}

export default L_LabUpload;
