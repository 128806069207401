import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PractitionerReportTable from '../../../components/Admin/CSVReport/PractitionerReportTable'
import { getPractitionerCSVReportTable } from '../../../store/actions/adminPractitioner'
import { useNavigate } from "react-router-dom";


function PractitionerReport({ history }) {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [totalData, setTotalData] = useState(0)
    const [data, setData] = useState([])
    const dispath = useDispatch()
    const token = useSelector(state => state.auth.token)
    const getPractitionerCSVReportTableFn = (url) => dispath(getPractitionerCSVReportTable(token, url))
    const navigate = useNavigate();


    const updatePractitionerTable = (url) => {
        setLoading(true)
        getPractitionerCSVReportTableFn(url).then(response => {
            setLoading(false)
            if (!response.error) {
                setData(response.payload.reports);
                setTotalData(response.payload.total)
                // setpatientData([]);
                // setTotalData(response.payload.total)
            } else setError(true);
        });
    };



    return (
        <div className='container py-5'>
            <div
                className="cursor-pointer textc-primary pg-link"
                onClick={() => navigate("/admin/dashboard")}
            >
                <i className="fas fa-chevron-left mr-2"></i> Dashboard
            </div>
            <div className="pg-heading my-4">
                Practitioner Report
            </div>
            <PractitionerReportTable
                practitioner={data}
                error={error}
                totalData={totalData}
                // totalData={20}
                loading={loading}
                updatePractitionerTable={(url) => updatePractitionerTable(url)}
            />
        </div>
    )
}

export default PractitionerReport
