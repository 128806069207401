import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { updateTrait } from "../../../store/actions/traits";
import TraitCardDropDown from "../TraitCategory/TraitCardDropDown";
import warningIcon from "../../../assets/warning.png";
import {
  pg3_report_icons,
  pg3_report_white_icons,
} from "../../Patient/PG4/constant";

function CurrentTraitCategory({ traits, currentTrait }) {
  const [currentTraitState, setCurrentTraitState] = useState(currentTrait);

  // redux state variable

  const allTraits = useSelector((state) => state.trait.allTraits);
  const currentTraitId = useSelector((state) => state.trait.currentTraitId);

  // redux actions
  const dispatch = useDispatch();
  const updateTraitInfoAction = (data) => dispatch(updateTrait(data));

  useEffect(() => {
    const formattedData = traits.map((trait) => ({
      category: trait.title,
      items: trait.traits.categorization
        ? _.flatten(Object.values(trait.traits.items))
        : trait.traits.items,
    }));
    const updatetCurrentTrait = formattedData.filter((filteredData) => {
      if (_.findIndex(filteredData.items, { trait_id: currentTraitId }) >= 0)
        return filteredData.category;
    });
    setCurrentTraitState(updatetCurrentTrait[0].category);
  }, []);

  return (
    <div className="row g-md-3 g-1">
      {traits.map((trait, i) => {
        return (
          <div className="col-12 col-md-6 col-lg-4 position-relative">
            <CurrentTraitCategoryCard
              trait={trait}
              // src={onHovered ? pg3_report_white_icons[traitData.title] : pg3_report_icons[traitData.title]}
              coloredImage={
                pg3_report_icons[
                  trait.title_key ? trait.title_key : trait.title
                ]
              }
              whiteImage={
                pg3_report_white_icons[
                  trait.title_key ? trait.title_key : trait.title
                ]
              }
              isHighlighted={trait.title === currentTraitState}
            />
          </div>
        );
      })}
    </div>
  );
}

export default CurrentTraitCategory;

const CurrentTraitCategoryCard = ({
  coloredImage,
  whiteImage,
  isHighlighted,
  trait,
}) => {
  const [showTraitList, setShowTraitList] = useState(false);
  const [onMouseHovered, setonMouseHovered] = useState(false);
  // const [highlight, setHighlight] = useState(false)
  return (
    <div
      className="position-relative "
      onClick={() => {
        setShowTraitList(!showTraitList);
      }}
      onMouseEnter={() => {
        // setHighlight(true)
        setonMouseHovered(true);
      }}
      onMouseLeave={() => {
        // setHighlight(false)
        setonMouseHovered(false);
        setShowTraitList(false);
      }}
    >
      <div
        className="d-flex border-radius border px-1 cursor-pointer transition-all"
        style={{
          background: isHighlighted || onMouseHovered ? trait.color : "",
          height: 80,
        }}
      >
        <img
          src={isHighlighted || onMouseHovered ? whiteImage : coloredImage}
          alt={trait.title}
          className="d-inline-block mx-1 my-auto"
          style={{ height: 35, width: 35 }}
        />
        <div
          className="d-flex justify-content-between ms-1 my-auto w-100"
          style={{
            color: isHighlighted || onMouseHovered ? "white" : "",
          }}
        >
          <div
            className="pg-text__bold"
            style={{
              color: isHighlighted || onMouseHovered ? "white" : "",
            }}
          >
            {trait.title}
          </div>
          <div className="my-auto" style={{ minWidth: "fit-content" }}>
            {trait.considerableTraits > 0 && (
              <span>
                <span className="me-1">{trait.considerableTraits}</span>
                <img src={warningIcon} alt="" className="align-top" />
              </span>
            )}
          </div>
        </div>
      </div>
      {showTraitList && (
        <TraitCardDropDown
          traits={trait.traits}
          color={trait.color}
          positionTop={78}
          traitCat={trait.title}
        />
      )}
    </div>
  );
};
