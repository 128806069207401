import * as tr_constant from "../actions/traits";

const initialState = {
  currentTraitCategory: null,
  currentTraitId: null,
  currentTraitIndex: 0,
  canNext: false,
  canPrevious: false,
  isGettingTraitInfo: true,
  allTraits: [],
  traitInfo: {
    overview: "",
    implications: "",
    affected_snips: [],
    lab_results: [],
    questionnaire_findings: [],
    diet_lifestyle_recommendations: "",
    supplement_recommendations: {
      recommended: [],
      alternatives: [],
    },
  },
};

const traitInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case tr_constant.GET_INDIVIDUAL_TRAIT_REQUEST: {
      return {
        ...state, isGettingTraitInfo: true, traitInfo: {
          overview: "",
          implications: "",
          affected_snips: [],
          lab_results: [],
          questionnaire_findings: [],
          diet_lifestyle_recommendations: "",
          supplement_recommendations: {
            recommended: [],
            alternatives: [],
          },
        },
      };
    }
    case tr_constant.GET_INDIVIDUAL_TRAIT_SUCCESS: {
      return {
        ...state,
        isGettingTraitInfo: false,
        traitInfo: action.payload,
      };
    }
    case tr_constant.GET_INDIVIDUAL_TRAIT_FAILURE: {
      return { ...state, isGettingTraitInfo: false };
    }
    case tr_constant.UPDATE_TRAIT_INFO: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

export default traitInfoReducer;
