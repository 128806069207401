import ContentLoader from "react-content-loader";
function L_LabReviewPage() {
    return (
        <ContentLoader
          speed={2}
          width="100%"
          height="20%"
          viewBox="0 0 600 400"
          backgroundColor="#9e9e9e52"
          foregroundColor="#18368f80"
          // {...props}
        >
          {/*  title */}
          <rect x="0" y="20" rx="3" ry="3" width="150" height="30" />
          <rect x="0" y="80" rx="3" ry="3" width="600" height="30" />

          {/* row */}
          <rect x="0" y="130" rx="3" ry="3" width="140" height="30" />
          <rect x="150" y="130" rx="3" ry="3" width="140" height="30" />
          <rect x="300" y="130" rx="3" ry="3" width="140" height="30" />
          <rect x="450" y="130" rx="3" ry="3" width="140" height="30" />

          {/* row */}
          <rect x="0" y="170" rx="3" ry="3" width="140" height="30" />
          <rect x="150" y="170" rx="3" ry="3" width="140" height="30" />
          <rect x="300" y="170" rx="3" ry="3" width="140" height="30" />
          <rect x="450" y="170" rx="3" ry="3" width="140" height="30" />

          {/* row */}
          <rect x="0" y="210" rx="3" ry="3" width="140" height="30" />
          <rect x="150" y="210" rx="3" ry="3" width="140" height="30" />
          <rect x="300" y="210" rx="3" ry="3" width="140" height="30" />
          <rect x="450" y="210" rx="3" ry="3" width="140" height="30" />

          {/* row */}
          <rect x="0" y="250" rx="3" ry="3" width="140" height="30" />
          <rect x="150" y="250" rx="3" ry="3" width="140" height="30" />
          <rect x="300" y="250" rx="3" ry="3" width="140" height="30" />
          <rect x="450" y="250" rx="3" ry="3" width="140" height="30" />

          {/* row */}
          <rect x="0" y="290" rx="3" ry="3" width="140" height="30" />
          <rect x="150" y="290" rx="3" ry="3" width="140" height="30" />
          <rect x="300" y="290" rx="3" ry="3" width="140" height="30" />
          <rect x="450" y="290" rx="3" ry="3" width="140" height="30" />

          {/* row */}
          <rect x="0" y="330" rx="3" ry="3" width="140" height="30" />
          <rect x="150" y="330" rx="3" ry="3" width="140" height="30" />
          <rect x="300" y="330" rx="3" ry="3" width="140" height="30" />
          <rect x="450" y="330" rx="3" ry="3" width="140" height="30" />

        </ContentLoader>
      );
}

export default L_LabReviewPage
