import React, { useState } from "react";
import PgSelect from "../../components/PgSelect";
import toastr from "react-hot-toast";
import { productionTask } from "../../store/actions/adminUser";
import { useSelector, useDispatch } from "react-redux";

const ProductionTask = () => {
  const [task, setTask] = useState(["genotype_task", "suggested_macronutrient_sources_task", "diagram_task", "patient_historial_data", "genetic_upload_historial_data", "practitioner_type_historial_data", "lab_uploads_historial_data", "question_sessions_historial_data", "practice_historial_data", "update_practice_owner_and_address", "update_rialto_tenant_address"]);
  const [selectedTask, setSelectedTask] = useState("genotype_task");

  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const productionTaskAction = (token, data) =>
    dispatch(productionTask(token, data));

  let updateTask = (option) => {
    setSelectedTask(option);
  };

  let selectSubmit = async () => {
    let data = {
      type: selectedTask,
    };
    const response = await productionTaskAction(token, data);
    console.log(response);

    if (!response.error) {
      toastr.success("request sent");
    }
  };
  return (
    <div className="my-5 py-5">
      <div className="container-xxl my-5 py-5">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-6">
            <PgSelect
              label="Select Production Task"
              showLabel={true}
              required={false}
              id="production_task"
              setSelectedItem={updateTask}
              selectedItem={selectedTask}
              placeholder="Production Task"
              options={task}
            />
            <button
              className="btn btn-primary text-light px-5 my-1 mx-1 mb-5  w-100"
              onClick={selectSubmit}
            >
              SEND
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductionTask;
