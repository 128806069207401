import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from 'react-router-dom'
import { useTable, usePagination, useSortBy, useRowSelect } from "react-table";
import { Accordion, useAccordionToggle } from "react-bootstrap"
import { parse } from 'query-string'
import moment from "moment";
import _ from "lodash";
import L_PatientList from "../../LoadingEffectComponent/L_PatientList";
import TableFooter from "../../../Pages/Admin/TableFooter";
import TextInputWithIcon from "../../TextInputWithIcon";
import PgCheckbox from "../../PgCheckbox";
import FilterIcon from "../../../assets/filterIcon.png";

function AdminPracLogTable({ patient, error, updatePractitionerTable, totalData, loading }) {
    const [showFilterOption, setShowFilterOption] = useState(false);
  const navigate = useNavigate();
    const [controlledPageCount, setControlledPageCount] = useState(1);
    const [sPageIndex, setSPageIndex] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [recentURL, setRecentURL] = useState("")
    const [filter, setFilter] = useState([])

    const [sortName, setsortName] = useState("");

    const continueSort = (url) => {
        if (sortName) {
            updatePractitionerTable(`${url}&sort_key=email&sort_value=${sortName}`);
        } else {
            updatePractitionerTable(url)
        }
    }
    useEffect(() => {
        gotoPage(0);
    }, [searchText])

    const manualSort = (e) => {
        const field = e.target.innerText.split(' ')[0]?.toLowerCase();
        console.log(field);
        if (field === "name") {
            setsortName(sortName === "" ? 'DESC' : sortName === "DESC" ? "ASC" : "DESC")
            updatePractitionerTable(`${recentURL}&sort_key=last_name&sort_value=${sortName === "" ? 'DESC' : sortName === "DESC" ? "ASC" : "DESC"}`)
        }
    }

    const column = [
        { Header: `Name  ${sortName === "DESC" ? ' ↓' : ' ↑'}`, accessor: "name" },
        {
            Header: "Request Type", accessor: "request_type", Cell: (raw_data) => {
                const data = raw_data.value;
                return <div>{_.startCase(data)}</div>
            }
        },
        {
            Header: "Request Date", accessor: "request_date", Cell: (value) => {
                return <div>{moment(value.value).format('L')}</div>
            }
        },
        {
            Header: "Status", accessor: "status", Cell: (raw_data) => {
                const data = raw_data.value;
                return <div>{_.startCase(data)}</div>
            }
        },
        {
            Header: "Completed Date", accessor: "completed_date", Cell: (value) => {
                return <div>{value?.row?.original?.aasm_state === "completed" && moment(value.value).format('L')}</div>
            }
        },
    ];

    const columns = useMemo(() => column, [patient]);
    const data = useMemo(() => patient, [patient]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        canNextPage,
        previousPage,
        canPreviousPage,
        pageOptions,
        pageCount,
        gotoPage,
        setPageSize,
        state: { pageIndex, pageSize },
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: sPageIndex,
                pageSize: 100
            },
            manualPagination: true,
            pageCount: controlledPageCount,
            autoResetSortBy: false,
            // autoResetPage: true,
            autoResetPage: false,
            disableSortRemove: true,
            defaultCanSort: true,
        },
        useSortBy,
        usePagination,
        useRowSelect
    );

    useEffect(() => {
        const url = parse(window.location.search);
        if (url.p_id) {
            setSearchText(url.p_id)
        }
    }, []);

    useEffect(() => {
        setControlledPageCount(Math.ceil(totalData / pageSize));
    });
    useEffect(() => {
        let url = `per_page=${pageSize}&page=${pageIndex + 1}`;
        if (searchText.trim().length > 1) url += `&search=${searchText}`;
        if (filter.length > 0) url += `&filter=${filter.toString()}`;
        setRecentURL(url);
        const delayDebounceFn = setTimeout(() => {
            continueSort(url);
        }, 1000)
        return () => clearTimeout(delayDebounceFn)
        // updatePractitionerTable(url);
    }, [pageSize, pageIndex, searchText, filter]);

    return (
        <div>
            <div className="my-5">
                <Accordion>
                    <div className="row justify-content- px-1 px-xl-0 ">
                        <div className="col-4 col-md-3 col-xl-2">
                            <div
                                className="d-flex justify-content-between  mt-2"
                                style={{ borderBottom: showFilterOption && "0.5px solid #808080", }}
                            >
                                <CustomToggle
                                    showFilterOption={showFilterOption}
                                    setShowFilterOption={setShowFilterOption}
                                    eventKey="0"
                                />
                                {filter.length > 0 && showFilterOption && (
                                    <div>
                                        <span
                                            className="pg-link mt-2"
                                            onClick={() => setFilter([])}
                                            id="reset-filter__patientlist"
                                        >
                                            <i
                                                className="fa fa-times-circle textc-primary me-2"
                                                aria-hidden="true"
                                            ></i>
                                            Reset
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-1 d-none d-lg-block"></div>
                        <div className="col-8 col-md-8">
                            <div className="mx-2" style={{ width: "70%" }}>
                                <TextInputWithIcon
                                    label="Patient Name"
                                    required={false}
                                    value={searchText}
                                    inputHandler={(e) => setSearchText(e.target.value)}
                                    // fetchTypedPatient={fetchTypedPatient}
                                    placeholder="Search Practitioner"
                                    type="search"
                                    id="search-patient__patientlist"
                                    Icon={
                                        <i
                                            className="fa fa-search text-secondary"
                                            aria-hidden="true"
                                        ></i>
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <Accordion.Collapse eventKey="0" as="div">
                            <FilterComponent filter={filter} setFilter={setFilter} />
                        </Accordion.Collapse>
                    </div>
                </Accordion>

            </div>
            {/* Table  */}
            {loading
                ? <L_PatientList />
                : <div>
                    <table {...getTableProps()} className="pg-table  my-2">
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())} onClick={manualSort}>
                                            {column.render("Header")}
                                            <span className="fs-4">
                                                {column.isSorted ? (column.isSortedDesc ? "↓" : "↑") : ""}{" "}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        {error && (
                            <tbody>
                                <tr>
                                    <td colSpan="5">
                                        <div className="w-100 text-center">
                                            Unable to fetch practitioner list
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        )}
                        {patient.length < 1 && !error && (
                            <tbody>
                                <tr>
                                    <td colSpan="5">
                                        <div className="w-100 text-center">No Practitioner Found</div>
                                    </td>
                                </tr>
                            </tbody>
                        )}
                        {patient.length > 0 && !error && (
                            <tbody {...getTableBodyProps()}>
                                {page.map((row) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map((cell) => {
                                                return (
                                                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        )}
                    </table>
                    <TableFooter
                        pageSize={pageSize}
                        canNextPage={canNextPage}
                        canPreviousPage={canPreviousPage}
                        nextPage={nextPage}
                        previousPage={previousPage}
                        setPageSize={setPageSize}
                        pageIndex={pageIndex}
                        pageOptions={pageOptions}
                    />
                </div>}
        </div>
    )
}

export default AdminPracLogTable

function CustomToggle({
    children,
    eventKey,
    setShowFilterOption,
    showFilterOption,
}) {
    const decoratedOnClick = useAccordionToggle(eventKey, () => { });

    return (
        <span
            className="font-weight-bold cursor-pointer my-2"
            onClick={(e) => {
                decoratedOnClick(e);
                setShowFilterOption(!showFilterOption);
            }}
        >
            <span className="pg-link">
                <img src={FilterIcon} alt="" className="me-2 mt-1" />
                Filter
            </span>
        </span>
    );
}

function FilterComponent({
    filter, setFilter
}) {
    const changeFilterMethod = (value) => {
        if (filter.includes(value)) setFilter(filter.filter(x => x !== value))
        else setFilter([...filter, value])
    }
    return (
        <div className="row px-3 py-1">
            {/* <hr className="" /> */}
            <div className="col-12">
                <div className="d-inline-block">
                    <PgCheckbox
                        title="Cancelled"
                        id="f-not-reviewed__patientlist"
                        checked={filter.includes('cancelled')}
                        onCheck={() => changeFilterMethod('cancelled')}
                    />
                </div>
                <br />
                <div className="d-inline-block">
                    <PgCheckbox
                        title="Completed"
                        id="f-not-reviewed__patientlist"
                        checked={filter.includes('completed')}
                        onCheck={() => changeFilterMethod('completed')}
                    />
                </div>
                <br />
                <div className="d-inline-block">
                    <PgCheckbox
                        title="In Progress"
                        id="f-sing-up-pending__patientlist"
                        checked={filter.includes('in_progress')}
                        onCheck={() => changeFilterMethod('in_progress')}
                    />
                </div>
                <br />
                <div className="d-inline-block">
                    <PgCheckbox
                        title="Requested"
                        id="admin-gdpr-data-requested__patientlist"
                        checked={filter.includes('requested')}
                        onCheck={() => changeFilterMethod('requested')}
                    />
                </div>
            </div>
        </div>
    );
}