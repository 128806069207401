import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import _ from "lodash";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { PgProgressBar } from "../../components/Patient/PgProgressBar";
import L_LabUpload from "../../components/LoadingEffectComponent/L_LabUpload";
import LabTest from "../../components/Practice/Lab/LabTest";
import LabTestForManualEntry from "../../components/Practice/Lab/LabTestForManualEntry"
import {
  getLabVendors,
  labUpload,
  updateLabUpload,
  getLabUploadStatus,
} from "../../store/actions/labUpload";
import { isPdfFiles } from "../../methods";
import FilePicker from "../../components/Patient/FilePicker";
import AfterLabFilesSelected from "../../components/Practice/Lab/AfterLabFilesSelected";
import PgPrompt from "../../components/PgPrompt";
import AfterUploadStarted from "../../components/Practice/Lab/AfterUploadStarted";

let labDataTemplate = {
  vendor_id: null,
  tests: [],
  files: [],
  isFileSelected: false,
};

function LabUpload({ history, location }) {
  const navigate = useNavigate();
  // redux state variable

  const isAuth = useSelector(state => state.auth.isAuth);
  const currentPracticeID = useSelector(state => state.auth.currentPracticeID);
  const currentPractice = useSelector(state => state.auth.currentPractice);
  const currentUserId = useSelector(state => state.auth.currentUserId);
  const currentPatientId = useSelector(state => state.patientReport.currentPatientId);
  const userType = useSelector(state => state.auth.userType);
  const token = useSelector(state => state.auth.token);
  const isGettingTest = useSelector(state => state.labUpload.isGettingTest);
  const vendors = useSelector(state => state.labUpload.vendors);
  const labUploadStarted = useSelector(state => state.labUpload.labUploadStarted);
  const uploadError = useSelector(state => state.labUpload.uploadError);
  const fileStatus = useSelector(state => state.labUpload.fileStatus);
  const language = useSelector(state => state.auth.language);
  const userPermissions = useSelector(state => state.auth.userPermissions);

  // redux actions
  const dispatch = useDispatch();

  const getLabVendors = (token, currentPracticeID, currentPractice) => dispatch(getLabVendors(token, currentPracticeID, currentPractice));
  const labUploadAction = (token, currentPracticeID, currentPractice, data) => dispatch(labUpload(token, currentPracticeID, currentPractice, data));
  const updateLabUploadAction = (data) => dispatch(updateLabUpload(data));
  const getLabUploadStatusAction = (token, currentPracticeID, currentPractice, data) => dispatch(getLabUploadStatus(token, currentPracticeID, currentPractice, data));


  const [labData, setLabData] = useState(labDataTemplate);
  const [disableDoneButton, setDisableDoneButton] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [vendorId, setVendorId] = useState(-1);
  const [isGettingCurrentPatient, setIsGettingCurrentPatient] = useState(true);
  const [currentPatientStatus, setCurrentPatientStatus] = useState({
    uploadStarted: false,
    patientId: "",
    tests: [],
    documentId: "",
    status: "Uploading",
    error: false,
  });
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (!userPermissions?.some(item => item.hasOwnProperty('UploadingPatientLabFiles'))) {
      navigate("/practice/patients")
    }
  }, []);

  useEffect(() => {
    setIsGettingCurrentPatient(true);
    let currentPatientStatusIndex = findPatientIndex(currentPatientId);
    if (currentPatientStatusIndex >= 0) {
      let currentPatientStatus = fileStatus[currentPatientStatusIndex];
      setCurrentPatientStatus(currentPatientStatus);
    }
    setIsGettingCurrentPatient(false);
  }, [fileStatus]);

  useEffect(() => {
    if (currentPatientStatus.uploadStarted) {
      setDisableDoneButton(false);
      if (!currentPatientStatus.uploadError) updateLoadingStatus();
    }
    return () => clearInterval(intervalTracker);
  }, [currentPatientStatus]);

  const onDone = () => {
    // navigate("/practice/patient/lab/review", {state:currentPatientStatus});
    if (currentPatientStatus.status?.toLowerCase() === "completed")
      navigate("/practice/patient/lab/review", { state: currentPatientStatus });
    else navigate(-1);
  };

  const findPatientIndex = (patientId) => {
    return _.findIndex(fileStatus, { patientId: patientId });
  };

  const selectTests = (testType) => {
    let patientIndex = findPatientIndex(currentPatientId);
    if (patientIndex < 0) console.log("It shouldnt be printed");
    else {
      let labFileStatus = [...fileStatus];
      if (labFileStatus[patientIndex].tests.includes(testType))
        labFileStatus[patientIndex].tests = labFileStatus[
          patientIndex
        ].tests.filter((test) => test !== testType);
      else labFileStatus[patientIndex].tests.push(testType);
      updateLabUploadAction({
        fileStatus: labFileStatus,
      });
    }
  };

  const selectVendor = (vendor_id) => {
    setVendorId(vendor_id);
  };

  const onFilePicked = (e, t = 0) => {
    const pickedFiles = e.target.files;
    if (isPdfFiles(pickedFiles)) toast.error(t('lab_and_genetic_line_6'));
    else {
      let patientIndex = findPatientIndex(currentPatientId);
      let labFileStatus = [...fileStatus];
      if (patientIndex < 0) console.log("It shouldnt be printed");
      else {
        labFileStatus[patientIndex].files = [...pickedFiles];
        labFileStatus[patientIndex].isFileSelected = true;
        labFileStatus[patientIndex].fileSize = pickedFiles.length;
        if (t === 2) {
          labFileStatus[patientIndex].status = "Uploading";
          labFileStatus[patientIndex].error = false;
          labFileStatus[patientIndex].uploadError = false;
          labFileStatus[patientIndex].uploadStarted = false;
        }
        updateLabUploadAction({
          fileStatus: labFileStatus,
        });
      }
    }
  };

  const addAnotherFile = (e) => {
    const file = e.target.files[0];
    // validating file type
    if (file.type !== "application/pdf")
      return toast.error(t('lab_and_genetic_line_6'));

    let patientIndex = findPatientIndex(currentPatientId);
    let labFileStatus = [...fileStatus];

    // validating duplicate file
    if (patientIndex < 0) console.log("It shouldnt be printed");
    else if (
      _.find(
        labFileStatus[patientIndex].files,
        (_file) => _file.name === file.name
      )
    )
      toast.error("File already exist!");
    else {
      labFileStatus[patientIndex].files.push(file);
      labFileStatus[patientIndex].fileSize++;
      updateLabUploadAction({
        fileStatus: labFileStatus,
      });
    }
  };

  const removeSelectedFile = (i) => {
    let patientIndex = findPatientIndex(currentPatientId);
    let labFileStatus = [...fileStatus];
    // validating duplicate file
    if (patientIndex < 0) console.log("It shouldnt be printed");
    else {
      const file = labFileStatus[patientIndex].files[i];
      let fileLength = labFileStatus[patientIndex].files;
      labFileStatus[patientIndex].files = labFileStatus[
        patientIndex
      ].files.filter((_file) => _file.name !== file.name);
      labFileStatus[patientIndex].fileSize--;
      fileLength = labFileStatus[patientIndex].files.length;
      labFileStatus[patientIndex].isFileSelected =
        fileLength === 0 ? false : true;
      updateLabUploadAction({
        fileStatus: labFileStatus,
      });
    }
  };

  const onStartUpload = () => {
    let labFileStatus = [...fileStatus];
    let patientIndex = findPatientIndex(currentPatientId);
    if (patientIndex < 0) {
      console.log("This shouldnt be printed");
    } else {
      labFileStatus[patientIndex].uploadStarted = true;
      labFileStatus[patientIndex].status = "Uploading";
      labFileStatus[patientIndex].statusText = "";
      labFileStatus[patientIndex].error = false;
      updateLabUploadAction({
        fileStatus: labFileStatus,
      });
    }
    let data = new FormData();
    data.append("lab_vendor_id", vendorId);
    data.append("patient_id", currentPatientStatus.patientId);
    data.append("user_type", "Practitioner");
    data.append("tests_ids", currentPatientStatus.tests);
    data.append("uploaded_by", currentUserId);
    for (var i = 0; i < labFileStatus[patientIndex].fileSize; i++) {
      data.append(
        `files[${i}]`,
        labFileStatus[patientIndex].files[i],
        labFileStatus[patientIndex].files[i].name
      );
    }
    labUploadAction(token, currentPracticeID, currentPractice, data).then(
      (response) => {
        if (response.error) {
          setDisableDoneButton(false);
          let labFileStatus = [...fileStatus];
          labFileStatus[patientIndex] = {
            ...labFileStatus[patientIndex],
            uploadError: true,
            error: true,
          };
          updateLabUploadAction({
            fileStatus: labFileStatus,
          });
        } else {
          setDisableDoneButton(false);
          let labFileStatus = [...fileStatus];
          labFileStatus[patientIndex] = {
            ...labFileStatus[patientIndex],
            documentId: response.payload.lab_document_session.id,
            documentIds: response.payload.document_ids,
            status: response.payload.lab_document_session.code,
            uploadError: false,
            error: false,
            statusText: response.payload.message,
          };
          updateLabUploadAction({
            fileStatus: labFileStatus,
          });
        }
      }
    );
  };

  let intervalTracker;
  const _getLabUploadStatusAction = () => {
    let data = {
      type: "lab",
      document_id: currentPatientStatus.documentId,
    };
    getLabUploadStatusAction(
      token,
      currentPracticeID,
      currentPractice,
      data
    ).then((response) => {
      let labFileStatus = [...fileStatus];
      let patientIndex = findPatientIndex(currentPatientId);
      if (!response.payload.success) {
        clearInterval(intervalTracker);
        if (patientIndex < 0) {
          console.log("This shouldnt be printed");
        } else {
          labFileStatus[patientIndex].error = true;
          labFileStatus[patientIndex].errorText = response.payload.message;
          updateLabUploadAction({
            fileStatus: labFileStatus,
          });
        }
      } else if (response.payload.lab_process_completed) {
        // showNext(true);
        clearInterval(intervalTracker);
        labFileStatus[patientIndex].status = "Completed";
        labFileStatus[patientIndex].error = false;
        updateLabUploadAction({
          fileStatus: labFileStatus,
        });
        if (currentPatientStatus.status?.toLowerCase() === "completed")
          navigate("/practice/patient/lab/review", { state: currentPatientStatus });
      } else {
        labFileStatus[patientIndex].status = response.payload.current_state;
        labFileStatus[patientIndex].statusText = response.payload.message;
        labFileStatus[patientIndex].error = false;
        updateLabUploadAction({
          fileStatus: labFileStatus,
        });
      }
    });
  };

  const updateLoadingStatus = () => {
    // this function will be called immediately
    _getLabUploadStatusAction();
    intervalTracker = setInterval(() => {
      _getLabUploadStatusAction();
    }, 5000);
  };

  const onTryAgain = () => {
    let patientIndex = findPatientIndex(currentPatientId);
    let labFileStatus = [...fileStatus];
    if (patientIndex < 0) console.log("It shouldnt be printed");
    else {
      labFileStatus[patientIndex].uploadStarted = false;
      labFileStatus[patientIndex].isFileSelected = false;
      labFileStatus[patientIndex].error = false;
      updateLabUploadAction({
        fileStatus: labFileStatus,
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>{t('puregenomics_1')} - {t('prac_lab_upload_header_1')}</title>
      </Helmet>
      {isGettingCurrentPatient ? (
        <L_LabUpload loaderSection={language === "en" ? 3 : 2} />
      ) : (
        <div className="container-fluid">
          <PgPrompt
            show={showAlert}
            onHide={() => setShowAlert(false)}
            onCancel={() => setShowAlert(false)}
            onAccept={() => navigate("/practice/patients")}
          />
          <div>
            <div className="container-xxl py-4 px-lg-1 px-md-2 px-1">
              <div className="row mt-4 justify-content-center justify-content-lg-between">
                <div
                  className="pg-link"
                  onClick={() =>
                    currentPatientStatus.uploadStarted && disableDoneButton
                      ? setShowAlert(true)
                      : navigate("/practice/patients")
                  }
                >
                  <i className="fa fa-chevron-left me-2" aria-hidden="true"></i>
                  {t('patients')}
                </div>
                <div className="col-12">
                  <div className="position-relative d-inline-block">
                    <div className="pg-title  color-white my-2 d-inline-block">
                      {t('lab_upload_page_title')}
                    </div>
                    <div
                      className="pg-text__bold"
                      style={{
                        position: "absolute",
                        top: 15,
                        right: -45,
                        color: "#4f7a28",
                      }}
                    >
                      {i18n.language === "en" ? t('beta_text') : ""}
                    </div>
                  </div>
                </div>
                <div className="pg-heading my-2">
                  {location?.state?.patientName}
                </div>
                {!currentPatientStatus.isFileSelected &&
                  !currentPatientStatus.uploadStarted && (
                    <div className="col-12 pg-text mt-3">
                      <span dangerouslySetInnerHTML={{ __html: t('lab_upload_page_title_2') }} />

                    </div>
                  )}
              </div>
              <hr className="my-4" />
            </div>
          </div>
          {
            <div>
              {!currentPatientStatus.isFileSelected &&
                !currentPatientStatus.uploadStarted && (
                  language === "en" ?  
                  <LabTest
                    labdata={currentPatientStatus}
                    selectedVendorId={vendorId}
                    selectTests={selectTests}
                    selectVendor={selectVendor}
                    onFilePicked={onFilePicked}
                  /> :
                  <LabTestForManualEntry
                  labdata={currentPatientStatus}
                  selectedVendorId={vendorId}
                  selectTests={selectTests}
                  selectVendor={selectVendor}
                  />
                )}

              {/* after file is picked */}
              {currentPatientStatus.isFileSelected &&
                !currentPatientStatus.uploadStarted && (
                  <AfterLabFilesSelected
                    onStartUpload={onStartUpload}
                    addAnotherFile={addAnotherFile}
                    labData={currentPatientStatus}
                    removeSelectedFile={removeSelectedFile}
                  />
                )}

              {/* after start upload */}
              {currentPatientStatus.uploadStarted &&
                currentPatientStatus.isFileSelected && (
                  <div>
                    <div className="my-5">
                      <AfterUploadStarted
                        uploadType="lab"
                        status={currentPatientStatus.status}
                        error={currentPatientStatus.error}
                        message={currentPatientStatus.statusText}
                        errorText={currentPatientStatus.errorText}
                        onTryAgain={() => onTryAgain()}
                      />
                    </div>
                    <div className="mt-5 pt-5" />
                    <div className="mt-5 pt-5" />
                    <div className="mt-5 pt-5" />
                  </div>
                )}
            </div>
          }

          {/* action button */}
          {/* <div className="row justify-content-center bgc-secondary py-3">
            <div className="col-lg-4 col-md-6 col-10">
              <button
                className="w-100 btn bg-sky-blue px-5 textc-primary py-2"
                disabled={disableDoneButton}
                onClick={onDone}
              >
                Done
              </button>
            </div>
          </div> */}
        </div>
      )}
    </>
  );
}

export default LabUpload;
