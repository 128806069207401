import * as pg4_constant from "../actions/pg4dashboard";

const initialState = {
    answersLoading: true,
    questionsLoading: true,
    saveResponseLoading: false,
    error: { status: false, type: '', message: '' },
    q_questions: {},
    q_current_section: {},
    q_answers: {},
    q_question_and_answers: {},
    q_response: {},
    q_errors: {},
    dashboard: [],
    q_sections: []
};


const PatientDashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case pg4_constant.MSQ_REQUEST: {
            return { ...state, questionsLoading: true };
        }
        case pg4_constant.MSQ_SUCCESS: {
            console.log('msq action:', action.payload);
            const keys = Object.keys(action.payload?.sections_and_questions);
            const formattedResponse = {
                q_sections: Object.keys(action.payload?.sections_and_questions)?.map(section_id => {
                    let sections = action.payload?.sections_and_questions;
                    return {
                        id: section_id,
                        name: sections[section_id]?.section_name
                    }
                }),
                q_questions: action.payload?.sections_and_questions,
                q_current_section: action.payload?.sections_and_questions[keys[0]]
            }
            const errorFields = extractQuestionIds(formattedResponse.q_current_section.questions);
            return { ...state, questionsLoading: false, q_errors: errorFields, ...formattedResponse };
        }
        case pg4_constant.MSQ_FAILURE: {
            return { ...state, questionsLoading: false, error: { ...action.payload }, };
        }
        case pg4_constant.MSQ_ANSWER_REQUEST: {
            return { ...state, answersLoading: true };
        }
        case pg4_constant.MSQ_ANSWER_SUCCESS: {
            const keys = Object.keys(state.q_questions);
            const lastSection = action.payload?.last_answered_section_id;
            const formattedResponse = {
                q_answers: action.payload?.answers,
                q_current_section: state.q_questions[String((lastSection > 0 && state.q_questions?.[lastSection]) ? lastSection : keys[0])]
            }
            console.log('formatted state (answer)', formattedResponse);
            const errorFields = extractQuestionIds(formattedResponse.q_current_section.questions);
            return { ...state, answersLoading: false, q_errors: errorFields, ...formattedResponse };
        }
        case pg4_constant.MSQ_ANSWER_FAILURE: {
            return { ...state, answersLoading: false, error: { ...action.payload }, };
        }
        case pg4_constant.MSQ_ANSWER_SAVE_REQUEST: {
            return { ...state, saveResponseLoading: true, };
        }
        case pg4_constant.MSQ_ANSWER_SAVE_SUCCESS: {
            return { ...state, saveResponseLoading: false, };
        }
        case pg4_constant.MSQ_ANSWER_SAVE_FAILURE: {
            return { ...state, saveResponseLoading: false, };
        }
        case pg4_constant.UPDATE_ANSWERS: {
            return { ...state, q_answers: { ...state.q_answers, ...action.payload }, };
        }
        case pg4_constant.CLEAR_ANSWERS: {
            return { ...state, q_answers: {}, };
        }
        case pg4_constant.UPDATE_SECTIONS: {
            const errorFields = extractQuestionIds(action.payload?.questions);
            return { ...state, q_current_section: { ...action.payload }, q_errors: errorFields };
        }

        case pg4_constant.UPDATE_ERRORS: {
            return { ...state, q_errors: action.payload };
        }

        case pg4_constant.CLEAR_STATE: {
            return {
                answersLoading: true,
                questionsLoading: true,
                error: { status: false, type: '', message: '' },
                q_questions: {},
                q_current_section: {},
                q_answers: {},
                q_errors: {},
                q_question_and_answers: {},
                q_response: {},
                dashboard: [],
                q_sections: []
            };
        }
        default:
            return state;
    }
};

export default PatientDashboardReducer;



function extractQuestionIds(questions) {
    const questionIds = {};

    function extractIds(ques) {
        if (ques.question_id && !ques.question_id.startsWith('GRP_')) {
            questionIds[ques.question_id] = false;
        }

        if (ques.sub_questions) {
            ques.sub_questions.forEach(subQuestion => extractIds(subQuestion));
        }
    }

    questions.forEach(question => extractIds(question));

    // return Object.keys(questionIds);
    return questionIds;
}
