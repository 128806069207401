import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateRialtoState } from "../../../../store/actions/ui";

export const OurProductCard = ({ cardInfo, context }) => {
 const dispatch = useDispatch();
  const navigate = useNavigate();
  const updateRialtoStateAction = (payload => dispatch(updateRialtoState(payload)));

  const currentPracticeID = useSelector(state => state.auth.currentPracticeID);
  const currentPractice = useSelector(state => state.auth.currentPractice);
  const token = useSelector(state => state.auth.token);
  const patients = useSelector(state => state.patientList.patientListForCatalog);
  const loading = useSelector(state => state.patientList.isGettingPatientListForCatalog);
  const totalData = useSelector(state => state.patientList.totalCatalogData);
  const rilatoState = useSelector(state => state.ui.rialtoState);
  const currentPatientId = useSelector(state => state.auth.currentUserId);


  // const navigateFn = () => {
  //   navigate("/patient/supplement_catalog", {
  //     state: {
  //       path: "/pg_patient_recommendations",
  //       browseCatalog: false,
  //     },
  //   });
  // };

  const redirectToCatalog = () => {
   const state = {
     path: `/tenants/${currentPracticeID}/patients/${currentPatientId}/catalog`,
     browseCatalog: true,
   };
   updateRialtoStateAction({
     ...rilatoState,
     ...state
   })
   navigate("/patient/supplement_catalog", { state });
 };

  return (
    <div className="col-12 col-lg-6 align-self-stretch my-3">
      <div className="pg4-card position-relative overflow-hidden dashboard-card bg-light h-100 my-3">
        <div className="d-flex flex-column justify-content-between h-100">
          <div>
            <div className="pg4-text__heading textc-primary__p">
              Our Products{" "}
            </div>
            <div className="pg4-text my-2 fw-bold">
            Our products are FREE FROM unnecessary additives and many common allergens. Choose from hundreds of supplements, formulated to support different health categories, including cardiovascular, metabolic, immune, cognitive, gastrointestinal, hormone, and more.
            </div>
          </div>
          <button
            className="pg4-btn pg4-btn_primary w-100"
            onClick={redirectToCatalog}
          >
           OUR PRODUCTS
          </button>
        </div>
      </div>
    </div>
  );
};
