import ContentLoader from "react-content-loader";
function L_LabUpload(props) {
  if(props.loaderSection == 3){
    return (
      <ContentLoader
        speed={2}
        width="100%"
        height="20%"
        viewBox="0 0 600 250"
        backgroundColor="#9e9e9e52"
        foregroundColor="#18368f80"
        {...props}
      >
  
        {/* Diagram title */}
        
        <rect x="44" y="26" rx="3" ry="3" width="188" height="26" />
        <rect x="44" y="75" rx="3" ry="3" width="500" height="1" />
  
        <rect x="44" y="100" rx="3" ry="3" width="98" height="20" />
        <rect x="44" y="130" rx="3" ry="3" width="88" height="6" />
        <rect x="44" y="150" rx="3" ry="3" width="88" height="6" />
        <rect x="44" y="170" rx="3" ry="3" width="88" height="6" />
        <rect x="44" y="190" rx="3" ry="3" width="88" height="6" />
        <rect x="44" y="210" rx="3" ry="3" width="88" height="6" />
  
        <rect x="244" y="100" rx="3" ry="3" width="98" height="20" />
        <rect x="244" y="130" rx="3" ry="3" width="88" height="6" />
        <rect x="244" y="150" rx="3" ry="3" width="88" height="6" />
        <rect x="244" y="170" rx="3" ry="3" width="88" height="6" />
        <rect x="244" y="190" rx="3" ry="3" width="88" height="6" />
        <rect x="244" y="210" rx="3" ry="3" width="88" height="6" />
  
        <rect x="444" y="100" rx="3" ry="3" width="98" height="20" />
        <rect x="444" y="130" rx="3" ry="3" width="90" height="15" />
        
      </ContentLoader>
    );
  }
  else{
    return (
      <ContentLoader
        speed={2}
        width="100%"
        height="20%"
        viewBox="0 0 600 250"
        backgroundColor="#9e9e9e52"
        foregroundColor="#18368f80"
        {...props}
      >
  
        {/* Diagram title */}
        
        <rect x="44" y="26" rx="3" ry="3" width="188" height="26" />
        <rect x="44" y="75" rx="3" ry="3" width="500" height="1" />
  
        <rect x="44" y="100" rx="3" ry="3" width="200" height="20" />
        <rect x="44" y="130" rx="3" ry="3" width="200" height="15" />
  
        <rect x="320" y="100" rx="3" ry="3" width="200" height="20" />
        <rect x="320" y="130" rx="3" ry="3" width="200" height="15" />
        
      </ContentLoader>
    );
  }
 
}

export default L_LabUpload;
