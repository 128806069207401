import { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { getPatientPastReportData } from "../../../store/actions/patientDashboard";
import PastReportTable from "../../../components/Patient/PastReportTable";
import qs from "query-string";
import _ from "lodash";
import { updatePatientReport } from "../../../store/actions/patientReport";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const PatientPastReport = ({ }) => {
  const navigate = useNavigate();

  // redux state variable
  const currentPracticeID = useSelector(state => state.auth.currentPracticeID);
  const currentPractice = useSelector(state => state.auth.currentPractice);
  const token = useSelector(state => state.auth.token);
  const pastReports = useSelector(state => state.patientDashboard.pastReports);
  const loading = useSelector(state => state.patientDashboard.isGettingPatientPastReport);
  const currentUserId = useSelector(state => state.auth.currentUserId);

  // redux actions
  const dispatch = useDispatch();
  const getPatientpastReports = (token, currentPracticeID, currentPractice, currentUserId) => dispatch(getPatientPastReportData(token, currentPracticeID, currentPractice, currentUserId));
  const updatePatientReportAction = (data) => dispatch(updatePatientReport(data));

  useEffect(() => {
    getPatientpastReports(token, currentPracticeID, currentPractice, currentUserId);
  }, []);

  const { t, i18n } = useTranslation();

  return (
    <div className="container-xl py-5 px-md-2 px-3">
      <div className="col-12 col-lg-6 text-left mb-5">
        <div className="pg-link  text-color__p" onClick={() => navigate("/patient/dashboard")}>
          <i className="fas fa-chevron-left"></i> {t('dashboard')}
        </div>
      </div>
      <div className="pg-title text-color__p">{t('pat_past_rep_h1')}</div>
      <div className="row justify-content-center justify-content-lg-between my-4">
        <div className="col-12 col-lg-6">
          <span dangerouslySetInnerHTML={{ __html: t('pat_past_rep_txt1') }} />
        </div>
        <div className="row">
          {!loading && <PastReportTable past_reports={pastReports} error={false} patient_id={currentUserId} updatePatientReport={updatePatientReportAction} />}
        </div>
      </div>
    </div>
  );
};
export default PatientPastReport;
