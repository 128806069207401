import React, { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { parseUrl } from "query-string";
import _ from "lodash";

import { clearCookie } from "../../methods";
import Cookies from "universal-cookie";
import {
  LOGOUT,
  resendOtp,
  verifyOtp,
} from "../../store/actions/auth";
import TextInput from "../../components/TextInput";
import { switchSDAndLogin } from "../../methods/auth_methods";
import LoginIssuesContact from './LoginIssuesContact'
import { useLocation } from "react-router-dom";

const cookies = new Cookies();

function OtpVerificationPage() {
  const routerLoc = useLocation();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [otp, setotp] = useState("");
  const [otpError, setotpError] = useState("");

  const mfaEnabled = useSelector((state) => state.auth.mfaEnabled);
  const postLogin = useSelector((state) => state.auth.postLogin);
  const userType = useSelector((state) => state.auth.userType);
  const currentUserId = useSelector((state) => state.auth.currentUserId);
  const token = useSelector((state) => state.auth.token);
  const termsAgreed = useSelector((state) => state.auth.termsAgreed);
  // const practices = useSelector(state => state.auth.practices);

  const verifyOtpAction = (otp) =>
    dispatch(verifyOtp(currentUserId, userType, otp));
  const resendOtpAction = () => dispatch(resendOtp(currentUserId, userType));
  const logoutAction = () => dispatch({ type: LOGOUT });
  const reloadState = (payload) => dispatch({ type: "RELOAD_STATE", payload });

  useEffect(() => {
    console.log(routerLoc.state);
  }, []);

  const verifyOTPfn = async (e) => {
    if (otp.length > 7) {
      let response = await verifyOtpAction(otp);
      if (response.error) {
        // toast.error("Invalid OTP!!!");
      } else {
        // try {
        let decodedData;
        decodedData = jwt_decode(token);
        const practices = decodedData.practices;
        if (userType === "AdminUser") {
          reloadState({
            token,
            ...decodedData,
            practiceID: cookies.get("p_id"),
            practice: cookies.get("practice"),
          });
          clearCookie();
        } else if (userType === "User") {
          let subdomain,
            practiceID,
            path = `/practice/dashboard`;
          if (postLogin) path = "/hcp/educational_1";
          if (practices.length === 0) {
            return toast.error("Invalid User");
          }
          if (practices.length === 1) {
            subdomain = practices[0].subdomain;
            practiceID = practices[0].id;
          }
          if (practices.length > 1) {
            const data = routerLoc.state;
            subdomain = data.practiceName;
            practiceID = data.practiceID;
          }
          if (routerLoc.state?.toReport) {
            let __subdomain = window.location.host.split(".")[0];
            const to = parseUrl(
              routerLoc?.state?.report?.from?.search ?? ""
            ).query;
            const currentPractice = _.find(practices, { __subdomain });
            if (to.patient_id && currentPractice) {
              path = `/practice/patient/report${routerLoc?.state?.report?.from?.search}`;
              subdomain = currentPractice.subdomain;
              practiceID = currentPractice.id;
            }
          }
          switchSDAndLogin(token, subdomain, practiceID, path, termsAgreed);
        } else {
          const domain = practices.subdomain;
          const practiceId = practices.id;
          const search = routerLoc?.search;
          let path = `/patient/dashboard${search}`;
          if (decodedData.user.welcome_shown) {
            path = `/patient/dashboard${search}`;
          }
          switchSDAndLogin(token, domain, practiceId, path);
        }
      }
    } else {
      setotpError("Invalid security code!!!");
    }
  };

  const resendOtpfn = async (e) => {
    let response = await resendOtpAction();
    if (!response.error) {
      toast.success(t('mfa_line_5'));
    }
  };

  return (
    <div>
      <div className="row justify-content-center my-5 py-5">
        <div className="col-12 col-md-4">
          <br />
          <div className="flex-row">
            <div>
              <span className="pg-text__bold text-color__p">
                {t('mfa_line_1')}
              </span>
            </div>
            <div className="mt-n4 fst-italic">
              {t('mfa_line_2')}
            </div>
            <div>
              <TextInput
                type="number"
                label=""
                showLabel={false}
                required={false}
                optional={false}
                id="otp-verification"
                placeholder={t('mfa_line_3')}
                value={otp}
                error={otpError}
                errorMessage={t('mfa_line_4')}
                inputHandler={(e) => {
                  setotp(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="text-center my-3">
            <button
              className="btn btn-secondary mb-5 px-5 mx-1"
              onClick={resendOtpfn}
            >
              {t('mfa_btn_1')}
            </button>
            <button
              className="btn btn-primary mb-5 px-5 mx-1"
              onClick={verifyOTPfn}
            >
              {t('mfa_btn_2')}
            </button>
          </div>
        </div>
      </div>
      <LoginIssuesContact />
    </div>
  );
}

export default OtpVerificationPage;
