import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import 'react-circular-progressbar/dist/styles.css';
import { useTranslation } from "react-i18next";

export default function PatientTile({ updatePatientReport, content, loading }) {
const navigate = useNavigate();
  const [mainContent, setmainContent] = useState({

  });

  useEffect(() => {

  }, []);

  const goToReportPage = (patientId, reportId) => {
    updatePatientReport({
      currentPatientId: patientId,
      currentReportId: reportId,
    });
    navigate("/practice/patient/report");
  };
  const { t, i18n } = useTranslation();

  return (
    <div className="pg-card p-3 px-4" style={{ minHeight: 390, backgroundColor: '#2470c2' }} >
        <div>
          <span dangerouslySetInnerHTML={{ __html: t('patient_dashboard_step1') }} />
          <div className="mt-1 w-100 text-center">
            <a
              className="btn btn-light mx-auto my-4 step-1-btn"
              href={ t('23andMe_init_url') } target="_blank"
            >
              <span dangerouslySetInnerHTML={{ __html: t('patient_dashboard_d1') }} />
            </a>
            <a
              className="btn btn-light mx-auto my-2 step-1-btn"
              href={ t('ancestry_init_url') } target="_blank"
            >
              <span dangerouslySetInnerHTML={{ __html: t('patient_dashboard_d2') }} />
            </a>
          </div>
        </div>
    </div>
  );
}
