import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateRialtoState } from "../../../store/actions/ui";

function SupplementRecommendationInTraits({
  data,
  currentPracticeID,
  patientId,
  userType,
  supplementSuggestions,
  currentReportId,
  t
}) {
  const patientTextColor = {
    color:
      userType === "Patient" ? "var(--pg_primary__p)" : "var(--pg_primary)",
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const rilatoState = useSelector(state => state.ui.rialtoState);
  const updateRialtoStateAction = (payload => dispatch(updateRialtoState(payload)));

  const nagivateToSupplements = (e) => {
    const state = {
      path: `/tenants/${currentPracticeID}/patients/${patientId}/rialto_service`,
      reportId: currentReportId,
      browseCatalog: false,
    };
    updateRialtoStateAction({
      ...rilatoState,
      ...state
    })
    navigate("/practice/supplement_catalog", { state });
  };

  const navigateToViewRecommededScript = (supplementSuggestions, userType) => {
    let path =
      userType == "Patient" &&
        supplementSuggestions.script_placed_status !== true
        ? "/supplement_suggestions"
        : "/view_recommended_script";

    const state = {
      path: path,
      reportId: currentReportId,
      browseCatalog: false,
      scriptId: supplementSuggestions.script_id,
      userType: userType,
    };
    updateRialtoStateAction({
      ...rilatoState,
      ...state
    })
    userType == "Patient"
      ? navigate("/patient/supplement_catalog", { state })
      : navigate("/practice/supplement_catalog", { state });
  };

  return (
    <div>
      <hr className="my-5" />
      <div className="pg-heading my-2" style={patientTextColor}>
        {t("supplement")} {" "}
        {supplementSuggestions.script_available
          ? t("recommendations")
          : t("suggestions")}
      </div>
      {data?.recommended?.length < 1 ? (
        <div className="pg-text">{t('gene_analysis_td_6')}</div>
      ) : (
        data?.recommended?.map((sr, i) => {
          return (
            <div
              className="pg-text__bold my-1"
              key={i}
              dangerouslySetInnerHTML={{ __html: sr }}
            />
          );
        })
      )}
      <br />
      {data?.alternatives?.length > 0 && (
        <div className="mb-2">
          <div className="pg-text__bold" style={{ color: "#888" }}>
            {t('gene_analysis_td_8')}
          </div>
          {data?.alternatives?.map((sa, i) => {
            return (
              <div
                className="pg-text"
                key={i}
                dangerouslySetInnerHTML={{ __html: sa }}
              />
            );
          })}
        </div>
      )}
      {userType === "User" && supplementSuggestions.script_available && (
          <div>
            <p className="pg-note">
              {t('trait_line_6')}
            </p>
            <button
              className="btn btn-primary text-light"
              onClick={() =>
                navigateToViewRecommededScript(supplementSuggestions, userType)
              }
            >
              {t('trait_btn_2')}
            </button>
          </div>
        )}
    </div>
  );
}

export default SupplementRecommendationInTraits;
