import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const SupplementRecommendationCard = ({ cardInfo, context }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const patient_id = useSelector((state) => state.auth.currentUserId);
  const currentPractice = useSelector((state) => state.auth.currentPractice);

  const navigateFn = () => {
    navigate("/patient/supplement_catalog", {
      state: {
        path: "/pg_patient_recommendations",
        browseCatalog: false,
      },
    });
  };

  return (
    <div className="col-12 col-lg-6 align-self-stretch my-3">
      <div className="pg4-card position-relative overflow-hidden dashboard-card bg-light h-100 my-3">
        <div className="d-flex flex-column justify-content-between h-100">
          <div>
            <div className="pg4-text__heading textc-primary__p">
            {t('gene_analysis_th_6')}{" "}
            </div>
            <div className="pg4-text my-2 fw-bold">
              {t('pat_dash_supplement_recom_card_content')}
            </div>
          </div>
          <button
            className="pg4-btn pg4-btn_primary w-100"
            onClick={navigateFn}
          >
            {t('pat_supplement_recom_view_history')}
          </button>
        </div>
      </div>
    </div>
  );
};
