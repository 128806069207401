import ContentLoader from "react-content-loader";
export function L_ReportDiagram(props) {
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height="20%"
      viewBox="0 0 600 600"
      backgroundColor="#9e9e9e52"
      foregroundColor="#18368f80"
      {...props}
    >
      {/* report summary navigator */}
      <rect x="24" y="5" rx="3" ry="3" width="52" height="6" />

      {/* download all button */}
      <rect x="520" y="65" rx="3" ry="3" width="52" height="6" />

      {/* Diagram title */}
      <rect x="24" y="26" rx="3" ry="3" width="88" height="26" />

      {/* patient name */}
      <rect x="24" y="69" rx="3" ry="3" width="52" height="10" />

      {/* content */}
      <rect x="24" y="99" rx="3" ry="3" width="252" height="6" />
      <rect x="24" y="109" rx="3" ry="3" width="92" height="6" />

      {/* hr */}
      <rect x="24" y="168" rx="3" ry="3" width="410" height="6" />
      <rect x="24" y="278" rx="3" ry="3" width="410" height="6" />
      <rect x="24" y="388" rx="3" ry="3" width="410" height="6" />
      <rect x="24" y="498" rx="3" ry="3" width="410" height="6" />
    </ContentLoader>
  );
}
