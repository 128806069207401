import ContentLoader from "react-content-loader";
function L_PatientDashboard() {
    return (
        <ContentLoader
            speed={2}
            width="100%"
            height="20%"
            viewBox="0 0 600 620"
            backgroundColor="#9e9e9e52"
            foregroundColor="#18368f80"
        // {...props}
        >
            {/*  title */}
            <rect x="0" y="30" rx="3" ry="3" width="150" height="30" />
            <rect x="0" y="70" rx="3" ry="3" width="250" height="20" />
            <rect x="500" y="80" rx="3" ry="3" width="100" height="10" />

            <rect x="0" y="100" rx="3" ry="3" width="600" height="30" />
            {/* row */}
            <rect x="0" y="140" rx="3" ry="3" width="140" height="30" />
            <rect x="155" y="140" rx="3" ry="3" width="140" height="30" />
            <rect x="305" y="140" rx="3" ry="3" width="140" height="30" />
            <rect x="455" y="140" rx="3" ry="3" width="140" height="30" />

            <rect x="0" y="200" rx="3" ry="3" width="290" height="200" />
            <rect x="300" y="200" rx="3" ry="3" width="290" height="200" />

            <rect x="0" y="410" rx="3" ry="3" width="290" height="200" />
            <rect x="300" y="410" rx="3" ry="3" width="290" height="200" />

        </ContentLoader>

    )
}

export default L_PatientDashboard
