import { createAction } from "redux-api-middleware";

export const GET_PATIENT_DETAILS_REQUEST =
  "GET_PATIENT_DETAILS_REQUEST";
export const GET_PATIENT_DETAILS_SUCCESS =
  "GET_PATIENT_DETAILS_SUCCESS";
export const GET_PATIENT_DETAILS_FAILURE =
  "GET_PATIENT_DETAILS_FAILURE";

export const EDIT_PATIENT_REQUEST =
  "EDIT_PATIENT_REQUEST";
export const EDIT_PATIENT_SUCCESS =
  "EDIT_PATIENT_SUCCESS";
export const EDIT_PATIENT_FAILURE =
  "EDIT_PATIENT_FAILURE";

export const GET_PATIENT_TYPES_REQUEST =
  "GET_PATIENT_TYPES_REQUEST";
export const GET_PATIENT_TYPES_SUCCESS =
  "GET_PATIENT_TYPES_SUCCESS";
export const GET_PATIENT_TYPES_FAILURE =
  "GET_PATIENT_TYPES_FAILURE";

export const CHANGE_PASSWORD_REQUEST =
  "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS =
  "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE =
  "CHANGE_PASSWORD_FAILURE";

export const REMOVE_REPORT_REQUEST =
  "REMOVE_REPORT_REQUEST";
export const REMOVE_REPORT_SUCCESS =
  "REMOVE_REPORT_SUCCESS";
export const REMOVE_REPORT_FAILURE =
  "REMOVE_REPORT_FAILURE";

export const GET_PRACTITIONERS_LIST_REQUEST =
  "GET_PRACTITIONERS_LIST_REQUEST";
export const GET_PRACTITIONERS_LIST_SUCCESS =
  "GET_PRACTITIONERS_LIST_SUCCESS";
export const GET_PRACTITIONERS_LIST_FAILURE =
  "GET_PRACTITIONERS_LIST_FAILURE";

const protocol = process.env.REACT_APP_PROTOCOL;
const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;

export const getPatientDetails = (
  token,
  currentPracticeID,
  currentPractice,
  id
) => {

  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/patient/account_details/${id}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      GET_PATIENT_DETAILS_REQUEST,
      GET_PATIENT_DETAILS_SUCCESS,
      GET_PATIENT_DETAILS_FAILURE,
    ],
  });
};

export const editPatient = (
  token,
  currentPracticeID,
  currentPractice,
  id,
  data
) => {

  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/patient/patients/${id}`,
    body: JSON.stringify(data),
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      EDIT_PATIENT_REQUEST,
      EDIT_PATIENT_SUCCESS,
      EDIT_PATIENT_FAILURE,
    ],
  });
};

export const changePatientPassword = (token,
  currentPracticeID,
  currentPractice,
  id, data) => {

  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/patient/update_patient_password`,
    body: JSON.stringify(data),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      CHANGE_PASSWORD_REQUEST,
      CHANGE_PASSWORD_SUCCESS,
      CHANGE_PASSWORD_FAILURE,
    ],
  });
};
export const removeReport = (token,
  currentPracticeID,
  currentPractice,
  id) => {

  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/patient/remove_report/${id}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      REMOVE_REPORT_REQUEST,
      REMOVE_REPORT_SUCCESS,
      REMOVE_REPORT_FAILURE,
    ],
  });
};

export const getPractitionersList = (
  token,
  currentPracticeID,
  currentPractice
) => {

  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/fetch_practitioners`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      GET_PRACTITIONERS_LIST_REQUEST,
      GET_PRACTITIONERS_LIST_SUCCESS,
      GET_PRACTITIONERS_LIST_FAILURE,
    ],
  });
};