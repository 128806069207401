import React from "react";
import WhyPGImage__1 from "../../../assets/static/whypg_1.webp";
import WhyPGImage__2 from "../../../assets/static/whypg_2.jpg";
import WhyPGImage__3 from "../../../assets/static/dpg_9.png";
import WhyPGImage__4 from "../../../assets/static/whypg_4.jpg";
import WhyPGImage__5 from "../../../assets/static/whypg_5.webp";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// rebranding
import WPI_Image2 from '../../../assets/pg4/rebranding/WPI_Image2.jpg'
import WPI_Image3 from '../../../assets/pg4/rebranding/WPI_Image3.jpg'

function WhyPG() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="py-3">
      <div className="container-xxl px-0">
        {i18n.language === "en_gb" && <div className="row justify-content-lg-between justify-content-center pb-2">
          <div className="col-11 col-lg-5 my-auto">
            <div className="pgs-heading__sm textc-primary__p">
              <span dangerouslySetInnerHTML={{ __html: t('puregenomics_h1') }} />
            </div>
            <div className="pgs-title textc-primary__p">
              <span dangerouslySetInnerHTML={{ __html: t('puregenomics_sub_h1') }} />
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <img src={WhyPGImage__1} alt="" className="w-100 my-3" />
          </div>
        </div>}
        <div className="row justify-content-lg-between justify-content-center">
          <div className="col-12 col-lg-6">
            <img src={i18n.language === "en_gb" ? WhyPGImage__2 : WPI_Image2 } alt="" className="w-100 my-3" />
          </div>
          <div className="col-11 col-lg-5 my-auto">
            {i18n.language !== "en_gb" && <>
              <div className="pgs-heading__sm textc-primary__p">
                <span dangerouslySetInnerHTML={{ __html: t('puregenomics_h1') }} />
              </div>
              <div className="pgs-title textc-primary__p">
                <span dangerouslySetInnerHTML={{ __html: t('puregenomics_sub_h1') }} />
              </div>
            </>}
            <div className="pgs-text mb-5 pb-5">
              <span dangerouslySetInnerHTML={{ __html: t('puregenomics_row2_p1') }} />
            </div>
          </div>
        </div>
        <div className="pgs-heading textc-primary__p text-lg-center text-start mx-4 ">
          <span dangerouslySetInnerHTML={{ __html: t('puregenomics_section_2') }} />
        </div>
        <div className="row justify-content-lg-between justify-content-center py-5">
          <div className="col-12 col-lg-6 my-auto order-lg-last">
            <img src={i18n.language === "en_gb" ? WPI_Image3 : WhyPGImage__3 } alt="" className="w-100 my-3" />
          </div>

          <div className="col-11 col-lg-5">
            <div className="pgs-title textc-primary__p">
              <span dangerouslySetInnerHTML={{ __html: t('puregenomics_section_h3') }} />
            </div>
            <div className="pgs-text my-3">
              <span dangerouslySetInnerHTML={{ __html: t('puregenomics_row4_p1') }} />
            </div>
            <div className="pgs-text">
              <span dangerouslySetInnerHTML={{ __html: t('puregenomics_row4_p2') }} />
            </div>
          </div>
        </div>

        {/* condition to hide section for US */}
        {i18n.language === "en_gb" && (
          <div className="row justify-content-lg-between justify-content-center py-5">
            <div className="col-12 col-lg-5">
              <img src={WhyPGImage__4} alt="" className="w-100 my-3" />
            </div>
            <div className="col-11 col-lg-5 my-auto">
              <span dangerouslySetInnerHTML={{ __html: t('puregenomics_section_4') }} />
              <div className="pgs-text">
                <span dangerouslySetInnerHTML={{ __html: t('puregenomics_row5_p2') }} />
              </div>
            </div>
          </div>
        )}
        {i18n.language === "en_gb" && (
          <div className="row justify-content-lg-between justify-content-center">
            <div className="col-12 col-lg-6 my-auto order-lg-last">
              <img src={WhyPGImage__5} alt="" className="w-100 my-3" />
            </div>

            <div className="col-11 col-lg-5">
              <div className="pgs-title textc-primary__p">
                <span dangerouslySetInnerHTML={{ __html: t('puregenomics_section_h4') }} />
              </div>
              <div className="pgs-text my-2">
                <span dangerouslySetInnerHTML={{ __html: t('puregenomics_row6_p1') }} />
              </div>
              <div className="pgs-text my-2">
                <span dangerouslySetInnerHTML={{ __html: t('puregenomics_row6_p2') }} />
              </div>
              <div className="pgs-text my-2">
                <span dangerouslySetInnerHTML={{ __html: t("puregenomics_row6_p3") }} />
              </div>
            </div>
          </div>
        )}
      </div>
      <div class="text-center">
        <button
          className="btn btn-primary__p w-25 mt-3"
          onClick={() => navigate("/patient/how-to-get-started")}
        >
          {t("puregenomics_get_btn_1")}
        </button>
      </div>
    </div>
  );
}

export default WhyPG;
