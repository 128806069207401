import React, { Component, useEffect, useState, useRef } from "react";
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from "react-redux";
import toastr from "react-hot-toast";
import _ from "lodash";

import PgSelect from "../../PgSelect";
import TextInput from "../../TextInput";
import { validateEmail } from "../../../methods";
import { PgRadioButton } from "../../PgRadioButton";
import L_EditPractitioner from "../../LoadingEffectComponent/Admin/L_EditPractitioner";

import { getPatient, editPatient, gdprRequest } from '../../../store/actions/adminPatient'
import { getCountries } from "../../../store/actions/adminUser";
import PatientFilterComponent from "../PatientFilterComponent";
import { getPractitionersList } from "../../../store/actions/adminPractitioner";
import PgCheckbox from "../../PgCheckbox";
import PgSelectSearch from "../../PgSelectSearch";
import RequestLogTable from "../../Patient/GDPR/RequestLogTable";
import { getPatientGDPRRequestLog } from "../../../store/actions/patientDashboard";
import { useLocation, Link } from "react-router-dom";
import { parse } from "query-string";
import ReactDatePicker from "react-datepicker";
import { months, years } from "../../../methods/constant";
import moment from "moment";

function EditPatientModal({ onCancel, showModal, patientId, updatePatientTable }) {

    const dispatch = useDispatch();
    const token = useSelector(state => state.auth.token);
    const location = useLocation();
    const params = parse(location.search);
    const selectedRegion = params["region"]
    const practitionersList = useSelector(state => state.adminPractitioner.practitionersList)

    const [countryList, setCountryList] = useState([]);
    const [gettingPatInfo, setgettingPatInfo] = useState(true)

    const [isValidForm, setIsValidForm] = useState(false);
    //form fields
    const [fName, setfName] = useState("");
    const [lName, setlName] = useState("");
    const [email, setEmail] = useState("");
    const [age, setAge] = useState("");
    const [dob, setDOB] = useState(null);
    const [skincolor, setSkinColor] = useState("");

    const [gender, setGender] = useState("")
    const [country, setCountry] = useState("");
    const [practitionerName, setPractitionerName] = useState("")
    const [practitionerId, setPractitionerId] = useState("")
    const [reportStatus, setReportStatus] = useState("");

    //for validation
    const [fNameError, setfNameError] = useState(false);
    const [lNameError, setlNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [ageError, setAgeError] = useState(false)
    const [dobError, setDobError] = useState(false)
    const [skinColorError, setskinColorError] = useState("");

    const [genderError, setGenderError] = useState(false)
    const [countryError, setCountryError] = useState(false);
    const [geneticError, setGeneticError] = useState(false)
    const [practitionerNameError, setPractitionerNameError] = useState(false)

    const [genetics, setGenetics] = useState('')
    const [lab, setLab] = useState('')
    const [questionnaire, setQuestionnaire] = useState(false)
    const [chooseLab, setChooseLab] = useState(false)
    const [pactitionersNameList, setPactitionersNameList] = useState([])
    const [selectedPractitioner, setSelectedPractitioner] = useState("")

    const gdprRequestLog = (pID) => dispatch(getPatientGDPRRequestLog(pID, token))
    const [requestLogList, setrequestLogList] = useState([]);
    const [requestLogListError, setrequestLogListError] = useState(false);

    const [showActionPrompt, setshowActionPrompt] = useState(false);
    const [actionPromptMessage, setactionPromptMessage] = useState("");
    const [currentActionType, setCurrentActionType] = useState(null);
    const [patientType, setPatientType] = useState("")

    const onDone = () => {
        setEmailError(false);
        setfNameError(false);
        setlNameError(false);
        setGenderError(false);
        setskinColorError(false);
        setDobError(false);
        setCountryError(false);
        setPractitionerNameError(false);
        
        if (email.length === 0 || !validateEmail(email)) setEmailError(true);
        if (fName.length === 0) setfNameError(true);
        if (lName.length === 0) setlNameError(true);
        if (!_.isDate(dob)) setDobError('Select Valid Date!');
        if (gender.length === 0) setGenderError("Gender is required");
        if (skincolor.length === 0) setskinColorError("Skin color is required");
        if (
            email.length !== 0 &&
            validateEmail(email) &&
            fName.length !== 0 &&
            lName.length !== 0 &&
            (_.isDate(dob)) &&
            gender.length !== 0 &&
            country.length !== 0 &&
            skincolor.length !== 0
        ) {           
            handleDone();
        }
        else
            document.querySelectorAll(".edit-patient-modal")[0].scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        if (selectedRegion === "EU")
            gdprRequestLog(patientId).then(response => {
                if (!response.error)
                    setrequestLogList(response.payload)
                else setrequestLogListError(true);
            });
    }, [])

    useEffect(() => {

        dispatch(getPractitionersList(token)).then(response => {
            if (!response.error) {
                const practitionerList = response.payload.practitioners;
                setPactitionersNameList(practitionerList?.map(a => a.full_name));
                /******  
                 * getting patient detail after getting practitioner list 
                 * since patient detail has dependency on pract.. list it is fetched earlier
                 * 
                *********/
                dispatch(getPatient(token, patientId)).then(response => {
                    if (!response.error) {
                        setgettingPatInfo(false);
                        const data = response.payload;
                        setSelectedPractitioner(data.assigned_practitioner);
                        setEmail(data.email.toLowerCase());
                        setfName(data.first_name);
                        setlName(data.last_name);
                        // setAge(data.age);
                        setDOB(data.dob ? new Date(data.dob) : null);
                        setGender(data.gender || "");
                        setSkinColor(data.skin_color || "");
                        setCountry(data.country);
                        data.upload_snp ? setGenetics("patient") : setGenetics("practice");
                        setReportStatus(data.report_status)
                        setLab(data.upload_lab);
                        setQuestionnaire(data.invite_questionnaire);
                        setPatientType(data.patient_type)
                    }
                });
            }
        })
        dispatch(getCountries(token)).then(response => {
            if (!response.error) {
                var availableCountries = response.payload?.countries
                if (selectedRegion !== "US" && selectedRegion !== "CA") {
                    _.remove(availableCountries, function (n) {
                        return n === "United States";
                    });
                    _.remove(availableCountries, function (n) {
                        return n === "Canada";
                    });
                } else if(selectedRegion === "CA"){
                    _.remove(availableCountries, function (n) {
                        return n === "United States";
                    });
                    setCountry("Canada")
                } else {
                    setCountry("United States")
                }
                setCountryList(availableCountries);
            }
        });
    }, [])

    const getPractitionerId = (name, _practitionersList = practitionersList) => {
        const practitioner = _.find(_practitionersList, ['full_name', name])
        console.log(practitioner, practitionersList, name)
        setPractitionerId(practitioner?.id || "")
        setPractitionerName(name)
    }

    const gdprRequestFn = (message, actionType) => {
        setactionPromptMessage(message);
        setCurrentActionType(actionType);
        setshowActionPrompt(true)
    }

    const gdprRequestAPIFn = () => {
        dispatch(gdprRequest(token, patientId, currentActionType)).then(response => {
            setshowActionPrompt(false);
            if (!response.error)
                toastr.success('Request sent successfully.')
        })
    }

    const handleDone = async () => {
        let data = {
            patient: {
                first_name: _.startCase(fName),
                last_name: lName,
                full_name: `${_.startCase(fName)} ${lName}`,
                email: email.toLowerCase(),
                // age: age,
                dob: moment(dob).format(),
                gender: gender,
                skin_color: skincolor,
                country: country,
                upload_snp: genetics === "patient" ? true : false
            },
        };
        const response = await dispatch(editPatient(token, patientId, data));
        if (!response.error) {
            onCancel();
            setIsValidForm(true);
            updatePatientTable()
            toastr.success("Patient Updated successfully");
        }
    };

    return (
        <Modal
            show={showModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            onHide={onCancel}
            contentClassName="border-radius"
            backdrop="static"
            className="edit-patient-modal"
        >
            <Modal.Header>
                <div className="pg-text text-center">
                    <div className="pg-heading my-3 mx-4 text-center">
                        Manage Patient
                    </div>
                    <div
                        style={{
                        position: "absolute",
                        right: 25,
                        top: 15,
                        fontSize: 20,
                        cursor: "pointer",
                        color: "var(--pg_primary)",
                        }}
                        onClick={onCancel}
                    >
                        <div className="fas fa-times"></div>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                {gettingPatInfo
                    ? <L_EditPractitioner />
                    : <div className="container">
                        {showActionPrompt && <ActionPrompt
                            show={showActionPrompt}
                            onReject={() => setshowActionPrompt(false)}
                            message={actionPromptMessage}
                            onAccept={gdprRequestAPIFn}
                        />}
                        <div className="row">
                            <div className="col-12">
                                <TextInput
                                    label="First Name"
                                    showLabel={true}
                                    required={true}
                                    id="f-name__addpatient"
                                    placeholder="Enter Patient First Name"
                                    value={fName}
                                    error={fNameError}
                                    type="text"
                                    errorMessage="First Name is required"
                                    inputHandler={(e) => {
                                        setfName(e.target.value);
                                    }}
                                />

                                <TextInput
                                    label="Last Name"
                                    showLabel={true}
                                    required={true}
                                    id="l-name__addpatient"
                                    placeholder="Enter Patient Last Name"
                                    value={lName}
                                    error={lNameError}
                                    errorMessage="Last Name is required"
                                    inputHandler={(e) => {
                                        setlName(e.target.value);
                                    }}
                                />

                                <TextInput
                                    label="Email"
                                    showLabel={true}
                                    required={true}
                                    emailField={true}
                                    isDisable={patientType === "test"}
                                    id="email-input__addpatient"
                                    placeholder="Enter Patient Email"
                                    value={email}
                                    error={emailError}
                                    errorMessage="Enter valid Email"
                                    inputHandler={(e) => {
                                        if (
                                            e.target.value.length < 0 &&
                                            !validateEmail(e.target.value)
                                        ) {
                                            setEmailError(true)
                                        } else {
                                            setEmailError(false)
                                            setEmail(e.target.value)
                                        }
                                    }}
                                />

                                {(patientType !== "test") && <div className="my-2 dateVal">
                                    <div className="d-inline-block pg-text__bold textc-primary">Date Of Birth</div>
                                    <ReactDatePicker
                                        renderCustomHeader={({
                                            date,
                                            changeYear,
                                            changeMonth
                                        }) => (
                                            <div
                                                style={{
                                                    margin: 10,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <select
                                                    value={date.getFullYear()}
                                                    onChange={({ target: { value } }) => changeYear(value)}
                                                >
                                                    {years.map((option) => (
                                                        <option key={option} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>

                                                <select
                                                    value={months[date.getMonth()]}
                                                    onChange={({ target: { value } }) =>
                                                        changeMonth(months.indexOf(value))
                                                    }
                                                >
                                                    {months.map((option) => (
                                                        <option key={option} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        )}
                                        onChange={(date) => {
                                            setDOB(date);
                                        }}
                                        style={{ width: "100%" }}
                                        value={dob}
                                        dateFormat="MM/dd/yyyy"
                                        onChangeRaw={() => { setDOB("") }}
                                        selected={dob}
                                        className={(_.isEmpty(dobError)) ? "dateFld" : "dateFld error"}
                                        placeholderText="Enter DOB MM/DD/YYYY"
                                        minDate={new Date("1900-01-01")}
                                        maxDate={new Date()}
                                        popperPlacement="bottom-start"
                                        disabled={patientType === "test"}
                                        popperModifiers={{
                                            flip: {
                                                behavior: ["bottom"]
                                            },
                                            preventOverflow: {
                                                enabled: false
                                            },
                                            hide: {
                                                enabled: false
                                            }
                                        }}
                                    />
                                    <div className="text-danger mx-2 my-1 transition-all" style={!(_.isEmpty(dobError)) ? { opacity: 1 } : { opacity: 0, minHeight: '16px' }} >
                                        {!(_.isEmpty(dobError)) && <div dangerouslySetInnerHTML={{ __html: `<i class="fas fa-info-circle me-2"></i>` + dobError }}></div>}
                                    </div>
                                </div>}
                                <PgSelect
                                    label="Gender"
                                    showLabel={true}
                                    required={true}
                                    optional={false}
                                    id="gender__addpatient"
                                    setSelectedItem={setGender}
                                    selectedItem={gender}
                                    placeholder="Enter Patient Gender"
                                    options={['Male', 'Female', 'Intersex']}
                                    error={gender !== '' ? '' : genderError}
                                />
                                <PgSelect
                                    label="Patient Skin Color(Required for Vitamin D Polygenic Scoring)"
                                    showLabel={true}
                                    required={true}
                                    optional={false}
                                    id="skincolor__addpatient"
                                    setSelectedItem={setSkinColor}
                                    selectedItem={skincolor}
                                    placeholder="Patient Skin Color(Required for Vitamin D Polygenic Scoring)"
                                    options={["Dark", "Medium", "Light"]}
                                    error={skincolor !== "" ? "" : skinColorError}
                                />

                                {
                                    (selectedRegion === "US" || selectedRegion === "CA") ?
                                        <TextInput
                                            label="Country"
                                            showLabel={true}
                                            id="l-country__addpractitioner"
                                            placeholder="Enter Country"
                                            value={selectedRegion === "US" ? "United States" : "Canada"}
                                            isDisable={true}
                                            error={countryError}
                                            inputHandler={(e) => {
                                                setCountry(e.target.value);
                                            }}
                                        />
                                        :
                                        <PgSelect
                                            label="Country"
                                            showLabel={true}
                                            required={true}
                                            optional={false}
                                            id="country__addpatient"
                                            setSelectedItem={setCountry}
                                            selectedItem={country}
                                            placeholder="Enter Patient Country"
                                            options={countryList}
                                            error={countryError}
                                        />
                                }


                                <TextInput
                                    label="Practitioner"
                                    showLabel={true}
                                    isDisable={true}
                                    required={false}
                                    optional={false}
                                    id="practitioner-name__addpatient"
                                    value={selectedPractitioner}
                                />

                                <button
                                    className="btn btn-primary text-light px-5 my-1 mx-1  w-100"
                                    onClick={onDone}
                                >
                                    UPDATE
                                </button>
                            </div>
                            {selectedRegion === "EU" && <div>
                                <div>
                                    <hr className="my-4" />
                                    <div className="col-12">
                                        <div className="pg-heading text-center my-3">
                                            Manage Patient Data
                                        </div>
                                        <div className="pg-text fw-bold">
                                            The General Data Protection Regulation (“GDPR”) which took effect on May 25, 2018 creates comprehensive data protection rules pertaining to personal data. The following tools are provided to assist you in complying with individual requests that fall under the GDPR’s protection.
                                        </div>
                                        <div>
                                            <div className="row my-3 mt-5 mt-md-3 justify-content-center">
                                                <div className="col-12 col-md-6 my-auto">
                                                    <button
                                                        onClick={() => gdprRequestFn(
                                                            'Do you wish to proceed?',
                                                            'request_data')}
                                                        className='btn btn-primary w-100'
                                                    > Request Patient Record
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row my-3 mt-5 mt-md-3 justify-content-center">
                                                <div className="col-12 col-md-6 my-auto">
                                                    <button
                                                        onClick={() => gdprRequestFn(
                                                            'Do you wish to proceed?',
                                                            'delete_data'
                                                        )}
                                                        className='btn btn-primary w-100'>Delete Patient Record</button>
                                                </div>
                                            </div>
                                            <div className="row my-3 mt-5 mt-md-3 justify-content-center">
                                                <div className="col-12 col-md-6 my-auto">
                                                    <button
                                                        onClick={() => gdprRequestFn(
                                                            'Do you wish to proceed?',
                                                            'inactivate_account')}
                                                        className='btn btn-primary w-100'
                                                    >Inactivate Patient Account</button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="pg-heading text-center my-3">Request Log</div>
                                <RequestLogTable
                                    logData={requestLogList}
                                    error={requestLogListError}
                                />
                            </div>}
                        </div>
                    </div>
                }</Modal.Body>
        </Modal>)
}

export default EditPatientModal

export const ActionPrompt = ({ show, onAccept, onReject, message, acceptLabel = 'Confirm', rejectLabel = 'Go Back' }) => {
    return (
        <Modal
            style={{
                background: "rgba(0, 0, 0, 0.5)",
                backdropFilter: "blur(.1)",
            }}
            centered
            show={show}
            onHide={onReject}
            bsPrefix="border-radius modal modal-2"
        >
            <div className="p-3">
                <div className="pg-text__bold">{message}</div>
                <div className="d-flex justify-content-end mt-4">
                    <button className="btn bg-sky-blue px-4 mx-1" onClick={onReject}>{rejectLabel}</button>
                    <button className="btn bgc-primary text-light px-5" onClick={onAccept}>{acceptLabel}</button>
                </div>
            </div>
        </Modal>)
}