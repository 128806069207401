import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ActionButtons from "../ActionButtons";
import {
  resetAdminPassword,
  archiveAdmins,
  restoreAdmins,
} from "../../../store/actions/adminUser";
import toastr from "react-hot-toast";
import EditAdminModal from "./EditAdminModal";
import PractitionerBatchFnModal from "../Practitioner/PractitionerBatchFnModal";
import { toggleLoading } from "../../../store/actions/ui";
import { isAllArrayElementHas, isAnyArrayElementHas } from "../../../methods";

function AdminBatchFn({
  admins,
  selectedAdmins,
  setSelectedAdminUserArray,
  updateAdminUserTable,
  masterAdmin,
}) {
  const token = useSelector((state) => state.auth.token);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showRestModal, setShowRestModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [showRestoreModal, setShowRestoreModal] = useState(false);
  // const _resetAdminPassword = useDispatch((token, ids) => resetAdminPassword(token, ids))
  const dispatch = useDispatch();

  useEffect(() => {
    // console.log(admins, "\n selected admins", selectedAdmins)
  }, [selectedAdmins]);

  const onResetPassword = () => {
    dispatch(toggleLoading(true));
    dispatch(resetAdminPassword(token, selectedAdmins)).then((response) => {
      dispatch(toggleLoading(false));
      if (!response.error) {
        console.log(response);
        toastr.success("Password reset successful");
        setShowRestModal(false);
      }
    });
  };

  const onArchive = () => {
    if (
      isAnyArrayElementHas(admins, selectedAdmins, "aasm_state", "archived")
    ) {
      dispatch(toggleLoading(true));
      dispatch(archiveAdmins(token, selectedAdmins)).then((response) => {
        dispatch(toggleLoading(false));
        if (!response.error) {
          toastr.success(response.payload.message);
          setSelectedAdminUserArray([]);
          updateAdminUserTable();
        }
        setShowArchiveModal(false);
      });
    } else {
      setShowArchiveModal(false);
      toastr.error("Select only active admins");
    }
  };

  const onRestore = () => {
    if (
      isAllArrayElementHas(admins, selectedAdmins, "aasm_state", "archived")
    ) {
      dispatch(toggleLoading(true));
      dispatch(restoreAdmins(token, selectedAdmins)).then((response) => {
        dispatch(toggleLoading(false));
        if (!response.error) {
          updateAdminUserTable();
          setSelectedAdminUserArray([]);
          toastr.success(response.payload.message);
        }
        setShowRestoreModal(false);
      });
    } else {
      setShowRestoreModal(false);
      toastr.error("Select only archived admins");
    }
  };

  return (
    <div>
      {showEditModal && (
        <EditAdminModal
          showModal={showEditModal}
          adminId={selectedAdmins[0]}
          updateAdminUserTable={updateAdminUserTable}
          onCancel={() => setShowEditModal(false)}
        />
      )}
      {showRestModal && (
        <PractitionerBatchFnModal
          showModal={showRestModal}
          onYes={onResetPassword}
          onCancel={() => setShowRestModal(false)}
        >
          <div className="pg-text__bold">
            Are you sure to reset the selected admin passowrd?
          </div>
        </PractitionerBatchFnModal>
      )}
      {showArchiveModal && (
        <PractitionerBatchFnModal
          showModal={showArchiveModal}
          onCancel={() => setShowArchiveModal(false)}
          onYes={onArchive}
        >
          <div className="pg-text__bold my-4">
            Are you sure to archive selected Admin ?
          </div>
        </PractitionerBatchFnModal>
      )}
      {showRestoreModal && (
        <PractitionerBatchFnModal
          showModal={showRestoreModal}
          onCancel={() => setShowRestoreModal(false)}
          onYes={onRestore}
        >
          <div className="pg-text__bold my-4">
            Are you sure to restore the selected archived Admins ?
          </div>
        </PractitionerBatchFnModal>
      )}
      <ActionButtons
        onArchive={() => setShowArchiveModal(true)}
        onRestore={() => setShowRestoreModal(true)}
        onEdit={() => setShowEditModal(true)}
        onReset={() => setShowRestModal(true)}
        normalAdmin={!masterAdmin}
        disableEdit={selectedAdmins.length !== 1}
        disableReset={selectedAdmins.length === 0}
        disableArchive={
          selectedAdmins.length === 0 ||
          !isAnyArrayElementHas(
            admins,
            selectedAdmins,
            "aasm_state",
            "archived"
          )
        }
        disableRestore={
          selectedAdmins.length === 0 ||
          !isAllArrayElementHas(
            admins,
            selectedAdmins,
            "aasm_state",
            "archived"
          )
        }
        context="admin"
      />
    </div>
  );
}

export default AdminBatchFn;
