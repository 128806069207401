import React, { useState, useEffect } from "react";
import { Accordion } from "react-bootstrap";

function FAQPgAccordion({ question, answer, children, userType = "User", searchedText = "" }) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    // Expand the accordion by default if the searched text is found
    if (searchedText && (answer?.includes(searchedText) || children?.includes(searchedText))) {
      console.log('in pgaccord...', question, children, children.includes(searchedText))
      setShow(true);
    }
  }, [searchedText, question, answer]);

  // Function to highlight search query while preserving HTML structure and ignoring specific HTML tags
  const highlightSearchQuery = (htmlContent, query) => {
    const antiPattern = /\b(?:reg|tra|trad|trade|ref)\b/;
    if (!query || query.length < 4 || antiPattern.test(query)) return htmlContent;
    const pattern = new RegExp(`(?![^<>]*>)(?<!\\b(?:trade|reg|ref)\\b)(${query})`, 'gi');
    const highlightedContent = htmlContent.replace(pattern, '<span class="bg-warning">$1</span>');

    return highlightedContent;
  };



  return (
    <div
      className="my-3 pb-2"
      style={{ borderBottom: "1px solid rgba(0,0,0,0.2)" }}
    >
      <Accordion>
        <Accordion.Toggle eventKey="0" className="br cp" as={div}>
          <div
            className="d-flex justify-content-between w-100 cp"
            onClick={() => setShow(!show)}
          >
            <div
              className={`pgs-text fw-bold textc-primary${userType === "User" ? "" : "__p"
                }`}
              dangerouslySetInnerHTML={{ __html: highlightSearchQuery(question, searchedText) }}
            />
            <div className="my-auto">
              {" "}
              <i
                className={`fas fa-chevron-down transition-all my-auto ms-5 mx-2 textc-primary${userType === "User" ? "" : "__p"
                  }`}
                style={{
                  transform: show ? "rotate(0deg)" : "rotate(-90deg)",
                }}
              ></i>{" "}
            </div>
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0" in={show}>
          <div className="pgs-text my-2" dangerouslySetInnerHTML={{ __html: highlightSearchQuery(answer || children, searchedText) }} />
        </Accordion.Collapse>
      </Accordion>
    </div>
  );
}

export default FAQPgAccordion;
const div = ({ children, onClick }) => <div onClick={onClick}>{children}</div>;
