import React, { Component, useEffect, useState, useRef } from "react";
import { useTable, usePagination, useSortBy, useRowSelect } from "react-table";
import {useSelector,useDispatch } from "react-redux";
import { getPatients } from "../../../store/actions/adminPatient";
import { getPractitionersList } from "../../../store/actions/adminPractitioner";
import { useNavigate } from "react-router-dom";
import PatientTable from "./PatientTable";
import { useLocation } from "react-router-dom";
import { parse } from "query-string";


function PatientList() {
  
  
  // redux state variable
  const token = useSelector(state => state.auth.token);
  const patients = useSelector(state => state.adminPatient.patients);
  const totalPatients = useSelector(state => state.adminPatient.total_count);
  const loading = useSelector(state => state.adminPatient.loading_patients);
  
  
  const dispatch= useDispatch();

  // redux actions
  const getPatientsAction = (token, url) => dispatch(getPatients(token, url));
  const getPractitionersListAction = (token) => dispatch(getPractitionersList(token));

  
  const navigate = useNavigate();
  const regions = useSelector(state => state.auth.region)
  const location = useLocation();
  const practitionerId = location.state? location.state.practitionerId : ""
  const practiceID = location.state? location.state.practiceID : ""

  const [selectedPractitioner, setSelectedPractitioner] = useState("");

  const params = parse(location.search);
  const selectedRegion = params["region"]

  const updatePatientTable = (url = "per_page=10&page=1") => {
    let updatedUrl = params["migrated"]? (url + "&migrated=" + params["migrated"]) : url
    getPatientsAction(token, updatedUrl+"&region="+ selectedRegion);
    // getPractitionersListAction(token)
  };

  const back = () => {
    practitionerId === '' ?
      navigate("/admin/dashboard") :
      navigate("/admin/practitioners?migrated=true&region="+ selectedRegion)
  }

  return (
    <div className="container-fluid">
      <div className="container-xxl py-5 px-lg-0">
        <div
          className="cursor-pointer textc-primary pg-link"
          onClick={() => back()}
        >
          <i className="fas fa-chevron-left mr-2"></i> {" "}
          {practitionerId === '' ? 'Dashboard' : 'Practitioners'}
        </div>
        <div className=" my-4 pg-title">Patients</div>
        <div>
          <PatientTable
            token={token}
            patients={patients}
            updatePatientTable={updatePatientTable}
            totalData={totalPatients}
            createdBy={practitionerId}
            practiceID={practiceID}
            loading={loading}
            selectedRegion={selectedRegion}
          />
        </div>
      </div>
    </div>
  );
}

export default PatientList;
