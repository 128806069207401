import React, { Component, useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import toastr from "react-hot-toast";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import PgSelect from "../../../../components/PgSelect";
import TextInput from "../../../../components/TextInput";
import { validateEmail } from "../../../../methods";

import {
    getPractitionersList,
    getPatientDetails,
    editPatient,
    removeReport,
} from "../../../../store/actions/patientAccount";
import { getCountries } from "../../../../store/actions/addPatient";
import PgSelectSearch from "../../../../components/PgSelectSearch";


import { useTranslation } from "react-i18next";
import L_EditPractitioner from "../../../LoadingEffectComponent/Admin/L_EditPractitioner";
import SetNewPasswordModal from "../../../../Pages/NewPasswordModal";

function AccountDetails({ }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.token);
    const currentPracticeID = useSelector(
        (state) => state.auth.currentPracticeID
    );
    const currentPractice = useSelector((state) => state.auth.currentPractice);
    const currentUserId = useSelector((state) => state.auth.currentUserId);
    const [showNewPasswordModal, setShowNewPasswordModal] = useState(false);
    const [reportStatus, setReportStatus] = useState("");

    const practitionersList = useSelector(
        (state) => state.adminPractitioner.practitionersList
    );

    const [gettingPatInfo, setgettingPatInfo] = useState(true);
    const [countryList, setCountryList] = useState([]);

    const [isValidForm, setIsValidForm] = useState(false);
    //form fields
    const [fName, setfName] = useState("");
    const [lName, setlName] = useState("");
    const [email, setEmail] = useState("");
    const [age, setAge] = useState("");
    const [gender, setGender] = useState("");
    const [country, setCountry] = useState("US");
    const [practitionerName, setPractitionerName] = useState("");
    const [practitionerId, setPractitionerId] = useState("");

    //for validation
    const [fNameError, setfNameError] = useState(false);
    const [lNameError, setlNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [ageError, setAgeError] = useState(false);
    const [genderError, setGenderError] = useState(false);
    const [countryError, setCountryError] = useState(false);
    const [geneticError, setGeneticError] = useState(false);
    const [practitionerNameError, setPractitionerNameError] = useState(false);
    const [loading, setLoading] = useState(false);

    const [pactitionersNameList, setPactitionersNameList] = useState([]);
    const [selectedPractitioner, setSelectedPractitioner] = useState({});
    const [gettingPatientInfo, setgettingPatientInfo] = useState(true);

    const onDone = () => {
        setEmailError(false);
        setfNameError(false);
        setlNameError(false);
        setAgeError(false);
        setGenderError(false);
        setPractitionerNameError(false);
        if (email.length === 0 || !validateEmail(email)) setEmailError(true);
        if (fName.length === 0) setfNameError(true);
        if (lName.length === 0) setlNameError(true);
        if (age.length === 0) setAgeError(t('pracand_age_error'));
        if (gender.length === 0) setGenderError(true);
        if (
            email.length !== 0 &&
            validateEmail(email) &&
            fName.length !== 0 &&
            lName.length !== 0 &&
            age.length !== 0 &&
            gender.length !== 0
        ) {
            handleDone();
        } else
            window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const { t, i18n } = useTranslation();

    useEffect(() => {
        dispatch(
            getPractitionersList(token, currentPracticeID, currentPractice)
        ).then((response) => {
            if (!response.error) {
                const practitionerList = response.payload.practitioners;
                setPactitionersNameList(practitionerList?.map((a) => a.full_name));
                /******
                 * getting patient detail after getting practitioner list
                 * since patient detail has dependency on pract.. list it is fetched earlier
                 *
                 *********/
                dispatch(
                    getPatientDetails(
                        token,
                        currentPracticeID,
                        currentPractice,
                        currentUserId
                    )
                ).then((response) => {
                    if (!response.error) {
                        setgettingPatientInfo(false);
                        const data = response.payload;
                        setSelectedPractitioner({
                            id: data.created_by,
                            full_name: data.assigned_practitioner,
                        });
                        setEmail(data.email.toLowerCase());
                        setfName(data.first_name);
                        setlName(data.last_name);
                        setAge(data.age);
                        setGender(data.gender);
                        setCountry(data.country);
                        setReportStatus(data.report_status);
                    }
                });
            }
        });
        dispatch(getCountries(token, currentPracticeID, currentPractice)).then(response => {
            if (!response.error) {
                var availableCountries = response.payload?.countries
                if (country !== "US" && country !== "CA") {
                    setCountry("")
                    _.remove(availableCountries, function (n) {
                        return n === "United States";
                    });
                    _.remove(availableCountries, function (n) {
                        return n === "Canada";
                    });
                } else if (country === "CA"){
                    setCountry("")
                    _.remove(availableCountries, function (n) {
                        return n === "United States";
                    });
                    setCountry("Canada")
                }
                else {
                    setCountry("United States")
                }
                setCountryList(availableCountries);
            }
        });
    }, [reportStatus]);

    const handleDone = async () => {
        let data = {
            patient: {
                first_name: _.startCase(fName),
                last_name: lName,
                full_name: `${_.startCase(fName)} ${lName}`,
                email: email,
                age: age,
                gender: gender,
                country: country
            },
        };
        setLoading(true);
        const response = await dispatch(
            editPatient(
                token,
                currentPracticeID,
                currentPractice,
                currentUserId,
                data
            )
        );
        setLoading(false);
        if (response.error) {
            toastr.error(t('patient_err1'));
        } else {
            window.scrollTo({ top: 0, behavior: "smooth" });
            toastr.success(t('patient_suc1'));
        }
    };

    const removePatientReport = () => {
        dispatch(
            removeReport(token, currentPracticeID, currentPractice, currentUserId)
        ).then((response) => {
            if (!response.error) {
                setReportStatus(response.payload.report_status);
                window.scrollTo({ top: 0, behavior: "smooth" });
                toastr.success(t('patient_suc2'));
            }
        });
    };

    return (
        <div className="" id="edit-practitioner-modal">
            <Helmet>
                <title>{t('pat_account_h1')}</title>
            </Helmet>
            {gettingPatientInfo ? (
                <L_EditPractitioner />
            ) : (
                <div className="row justify-content-md-center account-form px-2">
                    <div className="col-md-12">
                        <TextInput
                            label={t('prac_register_first_name')}
                            showLabel={true}
                            required={true}
                            id="f-name__addpatient"
                            placeholder={t('pracand_fname_ph')}
                            value={fName}
                            error={fNameError}
                            type="text"
                            errorMessage={t('pracand_fname_error')}
                            inputHandler={(e) => {
                                setfName(e.target.value);
                            }}
                        />

                        <TextInput
                            label={t('pracand_lname')}
                            showLabel={true}
                            required={true}
                            id="l-name__addpatient"
                            placeholder={t('pracand_lname_ph')}
                            value={lName}
                            error={lNameError}
                            errorMessage={t('pracand_lname_error')}
                            inputHandler={(e) => {
                                setlName(e.target.value);
                            }}
                        />

                        <TextInput
                            label={t('pracand_email')}
                            showLabel={true}
                            required={true}
                            emailField={true}
                            id="email-input__addpatient"
                            placeholder={t('pracand_email_ph')}
                            value={email}
                            error={emailError}
                            errorMessage={t('prac_reg_email_req')}
                            inputHandler={(e) => {
                                if (
                                    e.target.value.length < 0 &&
                                    !validateEmail(e.target.value)
                                ) {
                                    setEmailError(true);
                                } else {
                                    setEmailError(false);
                                    setEmail(e.target.value);
                                }
                            }}
                        />

                        <TextInput
                            label={t('pracand_age')}
                            showLabel={true}
                            required={true}
                            optional={false}
                            placeholder={t('pracand_age_ph')}
                            id="age__addpatient"
                            value={age}
                            error={ageError}
                            errorMessage={ageError}
                            type="number"
                            inputHandler={(e) => {
                                const value = parseInt(e.target.value);
                                if (value > 100 || value < 0) setAgeError(t('pracand_age_error'));
                                else {
                                    setAgeError("");
                                    setAge(e.target.value);
                                }
                            }}
                        />
                        <PgSelect
                            label={t('pracand_gender')}
                            showLabel={true}
                            required={true}
                            optional={false}
                            id="gender__addpatient"
                            setSelectedItem={setGender}
                            selectedItem={gender}
                            placeholder={t('pracand_gender_ph')}
                            options={["Male", "Female", "Intersex"]}
                            error={gender !== "" ? "" : genderError}
                        />

                        {
                            (country !== "United States" && country !== "Canada") &&
                            <PgSelect
                                label={t('pracand_country')}
                                showLabel={true}
                                required={true}
                                optional={false}
                                id="country__addpatient"
                                setSelectedItem={setCountry}
                                selectedItem={country}
                                placeholder={t('pracand_country_ph')}
                                options={countryList}
                                error={countryError}
                            />
                        }

                        <TextInput
                            label={t('practitioner')}
                            showLabel={true}
                            id="l-name__addpatient"
                            isDisable={true}
                            value={selectedPractitioner.full_name}
                            inputHandler={(e) => {
                                setlName(e.target.value);
                            }}
                        />
                        <button
                            className="btn btn-outline__p px-5 my-3 w-100"
                            onClick={() => setShowNewPasswordModal(true)}
                        >
                            {t('patient_account_txt3')}
                        </button>
                        {showNewPasswordModal && (
                            <SetNewPasswordModal
                                showModal={showNewPasswordModal}
                                onCancel={() => setShowNewPasswordModal(false)}
                                currentPractice={currentPractice}
                                currentPracticeID={currentPracticeID}
                                currentUserId={currentUserId}
                                userType="Patient"
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}
export default AccountDetails;
