import { createAction } from "redux-api-middleware";
const protocol = process.env.REACT_APP_PROTOCOL;
const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;

export const UPDATE_PATIENT_REPORT = "UPDATE_PATIENT_REPORT";

export const GET_PATIENT_REPORT_REQUEST = "GET_PATIENT_REPORT_REQUEST";
export const GET_PATIENT_REPORT_SUCCESS = "GET_PATIENT_REPORT_SUCCESS";
export const GET_PATIENT_REPORT_FAILURE = "GET_PATIENT_REPORT_FAILURE";

export const GET_PATIENT_DETAIL_REQUEST = "GET_PATIENT_DETAIL_REQUEST";
export const GET_PATIENT_DETAIL_SUCCESS = "GET_PATIENT_DETAIL_SUCCESS";
export const GET_PATIENT_DETAIL_FAILURE = "GET_PATIENT_DETAIL_FAILURE";

export const GET_TRAIT_CATEGORIES_REQUEST = "GET_TRAIT_CATEGORIES_REQUEST";
export const GET_TRAIT_CATEGORIES_SUCCESS = "GET_TRAIT_CATEGORIES_SUCCESS";
export const GET_TRAIT_CATEGORIES_FAILURE = "GET_TRAIT_CATEGORIES_FAILURE";

export const GET_SUPPLEMENT_SUGGESTION_REQUEST =
  "GET_SUPPLEMENT_SUGGESTION_REQUEST";
export const GET_SUPPLEMENT_SUGGESTION_SUCCESS =
  "GET_SUPPLEMENT_SUGGESTION_SUCCESS";
export const GET_SUPPLEMENT_SUGGESTION_FAILURE =
  "GET_SUPPLEMENT_SUGGESTION_FAILURE";

export const GET_LIFESTYLE_SUGGESTIONS_REQUEST =
  "GET_LIFESTYLE_SUGGESTIONS_REQUEST";
export const GET_LIFESTYLE_SUGGESTIONS_SUCCESS =
  "GET_LIFESTYLE_SUGGESTIONS_SUCCESS";
export const GET_LIFESTYLE_SUGGESTIONS_FAILURE =
  "GET_LIFESTYLE_SUGGESTIONS_FAILURE";

export const GET_LIFESTYLE_SUGGESTIONS_DETAILS_REQUEST =
  "GET_LIFESTYLE_SUGGESTIONS_DETAILS_REQUEST";
export const GET_LIFESTYLE_SUGGESTIONS_DETAILS_SUCCESS =
  "GET_LIFESTYLE_SUGGESTIONS_DETAILS_SUCCESS";
export const GET_LIFESTYLE_SUGGESTIONS_DETAILS_FAILURE =
  "GET_LIFESTYLE_SUGGESTIONS_DETAILS_FAILURE";

export const GET_LIFESTYLE_SUGGESTION_REQUEST =
  "GET_LIFESTYLE_SUGGESTION_REQUEST";
export const GET_LIFESTYLE_SUGGESTION_SUCCESS =
  "GET_LIFESTYLE_SUGGESTION_SUCCESS";
export const GET_LIFESTYLE_SUGGESTION_FAILURE =
  "GET_LIFESTYLE_SUGGESTION_FAILURE";

export const GET_GENE_ANALYSIS_LIST_REQUEST = "GET_GENE_ANALYSIS_LIST_REQUEST";
export const GET_GENE_ANALYSIS_LIST_SUCCESS = "GET_GENE_ANALYSIS_LIST_SUCCESS";
export const GET_GENE_ANALYSIS_LIST_FAILURE = "GET_GENE_ANALYSIS_LIST_FAILURE";

export const GET_GENE_ANALYSIS_CONTENT_REQUEST =
  "GET_GENE_ANALYSIS_CONTENT_REQUEST";
export const GET_GENE_ANALYSIS_CONTENT_SUCCESS =
  "GET_GENE_ANALYSIS_CONTENT_SUCCESS";
export const GET_GENE_ANALYSIS_CONTENT_FAILURE =
  "GET_GENE_ANALYSIS_CONTENT_FAILURE";

export const GET_REPORT_DIAGRAMS_REQUEST = "GET_REPORT_DIAGRAMS_REQUEST";
export const GET_REPORT_DIAGRAMS_SUCCESS = "GET_REPORT_DIAGRAMS_SUCCESS";
export const GET_REPORT_DIAGRAMS_FAILURE = "GET_REPORT_DIAGRAMS_FAILURE";

export const GET_SUPPLEMENTAL_GENOTYPE_REQUEST =
  "GET_SUPPLEMENTAL_GENOTYPE_REQUEST";
export const GET_SUPPLEMENTAL_GENOTYPE_SUCCESS =
  "GET_SUPPLEMENTAL_GENOTYPE_SUCCESS";
export const GET_SUPPLEMENTAL_GENOTYPE_FAILURE =
  "GET_SUPPLEMENTAL_GENOTYPE_FAILURE";

export const GET_GENOTYPE_FILTER_REQUEST = "GET_GENOTYPE_FILTER_REQUEST";
export const GET_GENOTYPE_FILTER_SUCCESS = "GET_GENOTYPE_FILTER_SUCCESS";
export const GET_GENOTYPE_FILTER_FAILURE = "GET_GENOTYPE_FILTER_FAILURE";

export const DOWNLOAD_REPORT_REQUEST = "DOWNLOAD_REPORT_REQUEST";
export const DOWNLOAD_REPORT_SUCCESS = "DOWNLOAD_REPORT_SUCCESS";
export const DOWNLOAD_REPORT_FAILURE = "DOWNLOAD_REPORT_FAILURE";

export const GET_REPORT_VIEW_TYPE_REQUEST = "GET_REPORT_VIEW_TYPE_REQUEST";
export const GET_REPORT_VIEW_TYPE_SUCCESS = "GET_REPORT_VIEW_TYPE_SUCCESS";
export const GET_REPORT_VIEW_TYPE_FAILURE = "GET_REPORT_VIEW_TYPE_FAILURE";

export const CHANGE_REPORT_VIEW_TYPE_REQUEST = "CHANGE_REPORT_VIEW_TYPE_REQUEST";
export const CHANGE_REPORT_VIEW_TYPE_SUCCESS = "CHANGE_REPORT_VIEW_TYPE_SUCCESS";
export const CHANGE_REPORT_VIEW_TYPE_FAILURE = "CHANGE_REPORT_VIEW_TYPE_FAILURE";

export const POST_GENERATE_POLYGENE_SCORE_REQUEST = "POST_GENERATE_POLYGENE_SCORE_REQUEST";
export const POST_GENERATE_POLYGENE_SCORE_SUCCESS = "POST_GENERATE_POLYGENE_SCORE_SUCCESS";
export const POST_GENERATE_POLYGENE_SCORE_FAILURE = "POST_GENERATE_POLYGENE_SCORE_FAILURE";

export const updatePatientReport = (data) => {
  return {
    type: UPDATE_PATIENT_REPORT,
    payload: data,
  };
};


export const updatePolygenicScore = (
  {token,
  currentPracticeID,
  currentPractice,
  data}
) => {
  const protocol = process.env.REACT_APP_PROTOCOL;
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;

  return createAction({   
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/run_ps_on_demand?id=${data.currentPatientId}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      POST_GENERATE_POLYGENE_SCORE_REQUEST,
      POST_GENERATE_POLYGENE_SCORE_SUCCESS,
      POST_GENERATE_POLYGENE_SCORE_FAILURE,
    ],
  });
};

export const getPatientReport = (
  token,
  currentPracticeID,
  currentPractice,
  id
) => {
  const protocol = process.env.REACT_APP_PROTOCOL;
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;

  return createAction({
    // http://kb.lvh.me:3002/practice/report_mock?id=169
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/report_mock?id=${id}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      GET_PATIENT_REPORT_REQUEST,
      GET_PATIENT_REPORT_SUCCESS,
      GET_PATIENT_REPORT_FAILURE,
    ],
  });
};

export const getPatientDetailInReport = (
  token,
  currentPracticeID,
  currentPractice,
  data
) => {
  const protocol = process.env.REACT_APP_PROTOCOL;
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/report/${data.reportId}/patient_detail/${data.patientId}`,
    // endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/report_mock_patient_detail/${id}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      GET_PATIENT_DETAIL_REQUEST,
      GET_PATIENT_DETAIL_SUCCESS,
      GET_PATIENT_DETAIL_FAILURE,
    ],
  });
};

export const getTraitCategories = (
  token,
  currentPracticeID,
  currentPractice,
  id
) => {
  const protocol = process.env.REACT_APP_PROTOCOL;
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;

  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/reports/${id}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      GET_TRAIT_CATEGORIES_REQUEST,
      GET_TRAIT_CATEGORIES_SUCCESS,
      GET_TRAIT_CATEGORIES_FAILURE,
    ],
  });
};

export const getSupplementSuggestions = (
  token,
  currentPracticeID,
  currentPractice,
  data
) => {
  const protocol = process.env.REACT_APP_PROTOCOL;
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;

  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/reports/${data.reportId}/supplement_suggestions?user_type=${data.userType}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      GET_SUPPLEMENT_SUGGESTION_REQUEST,
      GET_SUPPLEMENT_SUGGESTION_SUCCESS,
      GET_SUPPLEMENT_SUGGESTION_FAILURE,
    ],
  });
};

export const getLifeStyleSuggestion = (
  token,
  currentPracticeID,
  currentPractice,
  id
) => {
  const protocol = process.env.REACT_APP_PROTOCOL;
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;

  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/reports/${id}/lifestyle_suggestions`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      GET_LIFESTYLE_SUGGESTIONS_REQUEST,
      GET_LIFESTYLE_SUGGESTIONS_SUCCESS,
      GET_LIFESTYLE_SUGGESTIONS_FAILURE,
    ],
  });
};

export const getLifeStyleSuggestionDetails = (
  token,
  currentPracticeID,
  currentPractice,
  id,
  lifestyle
) => {
  const protocol = process.env.REACT_APP_PROTOCOL;
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;

  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/reports/${id}/lifestyle_suggestion_details/?lifestyle=${lifestyle}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      GET_LIFESTYLE_SUGGESTIONS_DETAILS_REQUEST,
      GET_LIFESTYLE_SUGGESTIONS_DETAILS_SUCCESS,
      GET_LIFESTYLE_SUGGESTIONS_DETAILS_FAILURE,
    ],
  });
};

export const getGeneAnalysisList = (
  token,
  currentPracticeID,
  currentPractice,
  id
) => {
  const protocol = process.env.REACT_APP_PROTOCOL;
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;

  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/reports/gene_analysis_title`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      GET_GENE_ANALYSIS_LIST_REQUEST,
      GET_GENE_ANALYSIS_LIST_SUCCESS,
      GET_GENE_ANALYSIS_LIST_FAILURE,
    ],
  });
};

export const getGeneAnalysisContent = (
  token,
  currentPracticeID,
  currentPractice,
  data, filter = false
) => {
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/reports/${data.traitSubId}/${data.reportId}/gene_analysis_summary?filter=${filter === "Show Actionable"}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,

    },
    types: [
      GET_GENE_ANALYSIS_CONTENT_REQUEST,
      GET_GENE_ANALYSIS_CONTENT_SUCCESS,
      GET_GENE_ANALYSIS_CONTENT_FAILURE,
    ],
  });
};

export const getReportDiagrams = (
  token,
  currentPracticeID,
  currentPractice,
  id
) => {
  const protocol = process.env.REACT_APP_PROTOCOL;
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;

  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/reports/${id}/trait_diagrams`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      GET_REPORT_DIAGRAMS_REQUEST,
      GET_REPORT_DIAGRAMS_SUCCESS,
      GET_REPORT_DIAGRAMS_FAILURE,
    ],
  });
};

export const getSupplementalGenotype = (
  token,
  currentPracticeID,
  currentPractice,
  id
) => {
  const protocol = process.env.REACT_APP_PROTOCOL;
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;

  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/genotype?report_id=${id}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      GET_SUPPLEMENTAL_GENOTYPE_REQUEST,
      GET_SUPPLEMENTAL_GENOTYPE_SUCCESS,
      GET_SUPPLEMENTAL_GENOTYPE_FAILURE,
    ],
  });
};

export const setGenotypeFilters = (
  currentPracticeID, currentPractice, token,
  data
) => {
  const protocol = process.env.REACT_APP_PROTOCOL;
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;

  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/add_remove_gene`,
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      GET_GENOTYPE_FILTER_REQUEST,
      GET_GENOTYPE_FILTER_SUCCESS,
      GET_GENOTYPE_FILTER_FAILURE,
    ],
  });
};


export const getPDFDownloaded = (
  token,
  currentPracticeID,
  currentPractice,
  { userType, patient_id, report_id }
) => {
  const protocol = process.env.REACT_APP_PROTOCOL;
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;

  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/${userType}/reports/${report_id}/${patient_id}/pdf_view.pdf`,
    method: "GET",
    headers: {
      "Content-Type": "application/pdf",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      DOWNLOAD_REPORT_REQUEST,
      {
        type: DOWNLOAD_REPORT_SUCCESS, payload: (action, state, res) => {
          return res
          // return res.json().then(json => { return { ...json, token: authToken } });
        }
      },
      DOWNLOAD_REPORT_FAILURE,
    ],
  });
};


export const getReportViewType = (
  token,
  currentPracticeID,
  currentPractice,
  id
) => {
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/reports/fetch_report_layout`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      GET_REPORT_VIEW_TYPE_REQUEST,
      GET_REPORT_VIEW_TYPE_SUCCESS,
      GET_REPORT_VIEW_TYPE_FAILURE,
    ],
  });
};

export const changeReportViewType = (
  token,
  currentPracticeID,
  currentPractice,
  id,
  data
) => {
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/reports/update_report_layout`,
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      CHANGE_REPORT_VIEW_TYPE_REQUEST,
      CHANGE_REPORT_VIEW_TYPE_SUCCESS,
      CHANGE_REPORT_VIEW_TYPE_FAILURE,
    ],
  });
};