import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import AddRecommendation from "./AddRecommendation";
import { SupplementRecommendationItems } from "./SupplementRecommendationItems";
import "./personalizedRecommendation.scss";
import NoteForPatient from "./NoteForPatient";
import RecommendationCardHeader from "./RecommendationCardHeader";
import { useTranslation } from "react-i18next";

function SupplementRecommendation({
  focusAreas,
  reportData,
  data,
  onSavePatientNotes,
  onDeleteRecommendation,
  onNagivateToSupplements,
  onNavigateToViewRecommededScript,
}) {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const containerRef = useRef(null);
  const shadowRef = useRef(null);
  const [recommendations, setRecommendations] = useState(
    data.recommendations || []
  );

  useEffect(() => {
    setRecommendations(data?.recommendations || []);
    setSelectedItem([]);
  }, [data]);

  const handleScroll = () => {
    const container = containerRef.current;
    const shadow = shadowRef.current;
    if (container.scrollHeight > container.clientHeight) {
      const isScrolledToBottom =
        container.scrollHeight - container.scrollTop === container.clientHeight;
      shadow.style.opacity = isScrolledToBottom ? 0 : 1;
    } else {
      shadow.style.opacity = 0;
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    container.addEventListener("scroll", handleScroll);
    handleScroll(); // Check scroll position initially
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="pg4-card rounded p-3 py-3 position-relative">
      <RecommendationCardHeader
        context="Supplement"
        is_shared={data.is_shared}
        focusAreas={focusAreas}
        recommendationLength={recommendations?.length}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        selectedItemLength={selectedItem?.length}
        setSelectedItem={setSelectedItem}
        onDeleteRecommendation={() =>
          onDeleteRecommendation(selectedItem, "supplements")
        }
        isEditable={
          recommendations?.length > 0 &&
          !data.is_shared &&
          !reportData?.script?.script_available
        }
        title={t("supplement_txt")}
      />

      <b className="pg4-text text_primary">{t("recommendations")}</b>
      <ul ref={containerRef} className="r-item__container">
        {recommendations?.map((item) => (
          <SupplementRecommendationItems
            key={item.recommendation_id}
            item={item}
            focusAreas={focusAreas}
            data={item}
            isEditing={isEditing}
            selectedItem={selectedItem}
            onSelect={setSelectedItem}
          />
        ))}
      </ul>
      <div ref={shadowRef} className="scroll-shadow"></div>
      {!data?.is_shared && (
        <button
          onClick={
            reportData?.script?.script_id
              ? onNavigateToViewRecommededScript
              : onNagivateToSupplements
          }
          className="pg4-btn position-relative pg4-btn_outline  w-100 text-secondary border-secondary"
          style={{ marginTop: -20 }}
          disabled={data.is_shared}
        >
          {reportData?.script?.script_available ||
          reportData?.script?.draft_script_id
            ? t("update_supplements")
            : "+ " + t("add_new_supplements")}
        </button>
      )}
      <NoteForPatient
        onSavePatientNotes={(notes) =>
          onSavePatientNotes({ supplement: notes })
        }
        notes={data?.notes}
        is_shared={data?.is_shared}
      />
    </div>
  );
}

export default SupplementRecommendation;
