import { createAction } from "redux-api-middleware";

const protocol = window.location.protocol;
const baseUrl = process.env.REACT_APP_PG3_APP_DOMAIN;

export const ADD_PRACTITIONER_REQUEST = "ADD_PRACTITIONER_REQUEST";
export const ADD_PRACTITIONER_SUCCESS = "ADD_PRACTITIONER_SUCCESS";
export const ADD_PRACTITIONER_FAILURE = "ADD_PRACTITIONER_FAILURE";

export const GET_PRACTITIONERS_REQUEST = "GET_PRACTITIONERS_REQUEST";
export const GET_PRACTITIONERS_SUCCESS = "GET_PRACTITIONERS_SUCCESS";
export const GET_PRACTITIONERS_FAILURE = "GET_PRACTITIONERS_FAILURE";

export const GET_PRACTITIONERS_LIST_REQUEST = "GET_PRACTITIONERS_LIST_REQUEST";
export const GET_PRACTITIONERS_LIST_SUCCESS = "GET_PRACTITIONERS_LIST_SUCCESS";
export const GET_PRACTITIONERS_LIST_FAILURE = "GET_PRACTITIONERS_LIST_FAILURE";

export const BATCH_FUNCTION_REQUEST = "BATCH_FUNCTION_REQUEST";
export const BATCH_FUNCTION_SUCCESS = "BATCH_FUNCTION_SUCCESS";
export const BATCH_FUNCTION_FAILURE = "BATCH_FUNCTION_FAILURE";

export const GET_PRACTITIONER_TYPES_REQUEST = "GET_PRACTITIONER_TYPES_REQUEST";
export const GET_PRACTITIONER_TYPES_SUCCESS = "GET_PRACTITIONER_TYPES_SUCCESS";
export const GET_PRACTITIONER_TYPES_FAILURE = "GET_PRACTITIONER_TYPES_FAILURE";

export const PRACTITIONER_GDPR_REQUEST = "PRACTITIONER_GDPR_REQUEST";
export const PRACTITIONER_GDPR_REQUEST_SUCCESS = "PRACTITIONER_GDPR_REQUEST_SUCCESS";
export const PRACTITIONER_GDPR_REQUEST_FAILURE = "PRACTITIONER_GDPR_REQUEST_FAILURE";

export const getStaffList = (token, url) => {

  const protocol = window.location.protocol;
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;
  return createAction({
    endpoint: `${baseUrl}/admin/staffs?${url}`,
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: token },
    types: [
      GET_PRACTITIONERS_REQUEST,
      GET_PRACTITIONERS_SUCCESS,
      GET_PRACTITIONERS_FAILURE
    ],
  });
}

export const addStaff = (token, data) => {
  const protocol = window.location.protocol;
  return createAction({
    endpoint: `${baseUrl}/admin/staffs`,
    body: JSON.stringify(data),
    method: "POST",
    headers: { Authorization: token, "User-Type": "Admin", "Content-Type": "application/json" },
    types: [
      ADD_PRACTITIONER_REQUEST,
      ADD_PRACTITIONER_SUCCESS,
      ADD_PRACTITIONER_FAILURE,
    ],
  });
};

export const getStaff = (token, id) => {
  const protocol = window.location.protocol;
  return createAction({
    endpoint: `${baseUrl}/admin/staffs/${id}`,
    method: "GET",
    headers: { Authorization: token, "User-Type": "Admin", "Content-Type": "application/json" },
    types: [
      ADD_PRACTITIONER_REQUEST,
      ADD_PRACTITIONER_SUCCESS,
      ADD_PRACTITIONER_FAILURE,
    ],
  });
};

export const editStaff = (token, id, data) => {
  const protocol = window.location.protocol;

  return createAction({
    endpoint: `${baseUrl}/admin/staffs/${id}`,
    body: JSON.stringify(data),
    method: "PATCH",
    headers:  { Authorization: token, "User-Type": "Admin", "Content-Type": "application/json" },
    types: [
      BATCH_FUNCTION_REQUEST,
      BATCH_FUNCTION_SUCCESS,
      BATCH_FUNCTION_FAILURE,
    ],
  });
};

export const getStaffPermissions = (token) => {
  const protocol = window.location.protocol;
  return createAction({
    endpoint: `${baseUrl}/admin/staff/optional_permissions`,
    method: "GET",
    headers: { Authorization: token, "User-Type": "Admin", "Content-Type": "application/json" },
    types: [
      ADD_PRACTITIONER_REQUEST,
      ADD_PRACTITIONER_SUCCESS,
      ADD_PRACTITIONER_FAILURE,
    ],
  });
};

export const resetLockout = (token, ids) => {
  const data = {
    ids
  };
  return createAction({
    endpoint: `${baseUrl}/admin/user/reset_lockout`,
    body: JSON.stringify(data),
    method: "PATCH",
    headers: {
      Authorization: token,
      "User-Type": "Admin",
      "Content-Type": "application/json",
    },
    types: [
      BATCH_FUNCTION_REQUEST,
      BATCH_FUNCTION_SUCCESS,
      BATCH_FUNCTION_FAILURE,
    ],
  });
};