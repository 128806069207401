import React, { useState } from "react";
import { Link } from "react-router-dom";

function RialtoPatientFAQ() {
	return (
		<div>
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-12 col-md-11  my-5 px-md-0 px-3">
						{/* <p className="pgs-title my-5">Ordering Supplements</p> */}
						<div className="row justify-content-center">
							<div className="col-11 col-lg-12">
								<div className="pgs-heading fw-bold textc-primary my-2">
									Can I Order Supplements through PureGenomics?
								</div>
								<div className="pgs-text my-2">
									If your practitioner opts to offer the Virtual Dispensary available through PureGenomics®, you will be able to review their recommendations and purchase Pure Encapsulations supplements right from the PureGenomics patient portal.
								</div>
								<br />
								<div className="pgs-heading fw-bold textc-primary my-2">
									How Do I Purchase Supplements through PureGenomics?
								</div>
								<div className="pgs-text my-2">
									First, your provider must opt into the Virtual Dispensary and generate a supplement recommendation based on your genetic information and other optional features. You will receive an email letting you know that your PureGenomics® supplement recommendations are ready to view. You will need to click on the <i>Patient Log In</i>, then <i>View Supplement Recommendations</i> followed by Details. On the next page, you will be able to <b>CHECKOUT</b> and then <b>PLACE ORDER</b>.
								</div>
								<br />
								<div className="pgs-heading fw-bold textc-primary my-2">
									How Long Will it Take for Me to Receive My Supplements?
								</div>
								<div className="pgs-text my-2">
									Orders received by 4:00 pm ET are shipped that day via UPS 2-Day service.
								</div>
								<br />
								<div className="pgs-heading fw-bold textc-primary my-2">
									Will I Be Charged Shipping?
								</div>
								<div className="pgs-text my-2">
									Shipping is FREE for continental US orders over $100
									(exceptions may apply). Orders under $100 will be charged $10
									for shipping.
									<p>For Puerto Rico, and Alaska there is a $12 flat
										rate shipping fee and free shipping is at $500.Orders to all
										other US territories do not qualify for free shipping.
										Shipping to these locations is billed separately based on
										actual costs.
									</p>
									<p>We do not currently offer shipping to Hawaii.</p>
								</div>
								<br />
								<div className="pgs-heading fw-bold textc-primary my-2">
									Am I Able to Add or Delete Items from my Order?
								</div>
								<div className="pgs-text my-2">
									You will not be able to directly edit your practitioner’s recommendations before adding to cart. Contact your provider before placing your order if you have any questions or would like to make modifications to the recommendations. They can make any changes or modifications needed and send you a revised recommendation.
								</div>
								<br />
								<div className="pgs-heading fw-bold textc-primary my-2">
									Will My Practitioner Be Notified When I Place An Order?
								</div>
								<div className="pgs-text my-2">
									Yes, they will be able to view your order status from their practitioner portal.
								</div>
								<br />
								<div className="pgs-heading fw-bold textc-primary my-2">
									What is the Return Policy?
								</div>
								<div className="pgs-text my-2">
									Returns must be made within 30 days of receipt and require preauthorization. Any claims of shortage or damage must be made within 7 days of receiving the package. Authorized returns must be received by our warehouse within 14 days of authorization. You will be credited upon receipt. Processing and shipping fees apply.
								</div>
								<br />
								<div className="pgs-heading fw-bold textc-primary my-2">
									How do I Return a Product?
								</div>
								<div className="pgs-text my-2">
									Returns can be initiated by clicking on <b>View Supplement Recommendations</b> on your patient dashboard. You will see a <b>Return</b> button in line with the order that the product came from. On the next page, select the item and the reason for the return. You will see a drop-down with all the available return options. Click <b>Next</b> to submit.
								</div>
								<br />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default RialtoPatientFAQ;
