import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { generateRandomHash } from "../../../../methods";
import _ from "lodash";
import { useSelector } from "react-redux";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

const MultiTextInput = ({ question, onInput }) => {
  const { t } = useTranslation();
  const answers = useSelector((state) => state.pg4.q_answers);
  const errors = useSelector((state) => state.pg4.q_errors);

  const [inputs, setInputs] = useState([
    { id: generateRandomHash(8), value: "" },
    { id: generateRandomHash(8), value: "" },
    { id: generateRandomHash(8), value: "" },
  ]);

  const handleInputChange = (id, value) => {
    if (value.length <= 50)
      setInputs((prevInputs) => {
        const updatedInputs = prevInputs.map((input) => {
          if (input.id === id) {
            return { ...input, value: value };
          }
          return input;
        });
        return updatedInputs;
      });
  };

  const handleInputBlur = (idx, value) => {
    const prevState = answers[question?.question_id]?.answer || [];
    prevState[idx] = value;
    const updatedAnswer = {
      [question.question_id]: {
        question_id: question.question_id,
        answer: prevState,
      },
    };
    onInput(updatedAnswer);
  };

  /**
   * populating the state with empty array for escaping validation
   *  remove this hook if this field needs to be manadatory **/
  useEffect(() => {
    const prevState = answers[question?.question_id]?.answer || [];
    const updatedAnswer = {
      [question.question_id]: {
        question_id: question.question_id,
        answer: prevState,
      },
    };
    onInput(updatedAnswer);
  }, []);

  return (
    <div className="pg4-card">
      <div className="pg-heading__sub textc-primary__p">
        {t(question?.question_text)}
      </div>
      <div className="pg-text">{t(question?.placeholder)}</div>
      {inputs.map((_input, index) => (
        <div key={_input.id} classNames="my-input">
          <div className="d-flex" key={_input.id}>
            <input
              type="text"
              className="w-100 my-2 msq-input"
              placeholder={t("msq_enter_concern")}
              value={_input.value}
              onChange={(e) => handleInputChange(_input.id, e.target.value)}
              onBlur={(e) => handleInputBlur(index, e.target.value)}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default MultiTextInput;
