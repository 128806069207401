import React from "react";
import { useTranslation } from "react-i18next";

export default function NewInformation({ onDismiss, content }) {

  const { t, i18n } = useTranslation();

  return (
    <div className="pq-card p-4 px-5" style={{ backgroundColor: '#f6f6f6 !important', background: '#f6f6f6 !important', height: '390 !important' }}>
      <div className="pd-pg-heading text-center">{ t('patient_dashboard_h1') }</div>
      <div className=" justify-content-between">
        <div className="my-4">
          <div className="pg-heading__sub">
            { t('patient_dashboard_mq_h1') }
          </div>
          <div className="pg-text my-2 py-2">
            { t('patient_dashboard_mq_c1') }
          </div>
          <div className="pg-heading__sub">
            { t('patient_dashboard_new_h1') }
          </div>
          <div className="pg-text my-2 py-2">
            { t('patient_dashboard_new_c') }
          </div>
          {/* <div className="pg-heading__sub">{content?.title}</div>
          <div className="pg-text my-2 py-2">
            {content?.content}
          </div> */}
          {/* <a
            className="cursor-pointer pg-text d-block my-3"
            onClick={onDismiss}
            id="close-infobox__patient-dashboard"
          >
            Dismiss
          </a> */}
        </div>
      </div>
    </div>
  );
}
