import React from "react";

function ActionButtons({
    context = "practitioner",
    disableEdit, disableReset, disableLockout, disableArchive, disableRestore, disableApprove, disableReportArchive, disableReportRestore, disableGeneticUploadReminder, disableRegistrationReminder,  
    onEdit, onReset, onLockout, onArchive, onRestore, onApprove, onDownload, onReportArchive, onReportRestore, onSendGeneticUploadReminder, onRegistrationReminder, normalAdmin
}) {
    const style = {
        batchFnBtnActive: {
            // color: "red", cursor: 'pointer'
            // filter:"brightness(1.2)"
        },
        batchFnBtnDisabled: {
            color: "var(--pg_primary)", cursor: 'not-allowed',opacity:0.6
        }
    }
    return (
        <div className="d-flex justify-content-between ">
            <div className="d-flex justify-content-evenly" >
                <div className="mx-2 pg-link batch-fn-btn"
                    onClick={(disableEdit || normalAdmin) ? undefined : onEdit}
                    style={(disableEdit || normalAdmin)? style.batchFnBtnDisabled : style.batchFnBtnActive}>
                    <i className="mx-1 fas fa-pen"></i>Manage
                </div>
                <div className="mx-2 pg-link"
                    onClick={disableReset ? undefined : onReset}
                    style={disableReset ? style.batchFnBtnDisabled : style.batchFnBtnActive}>
                    <i className="mx-1 fas fa-lock"></i> Reset Password
                </div>
                <div
                    onClick={disableArchive ? undefined : onArchive}
                    className="mx-2 pg-link" style={(disableArchive || normalAdmin) ? style.batchFnBtnDisabled : style.batchFnBtnActive}>
                    <i className="mx-1 fas fa-archive"></i>Archive {context}
                </div>
                <div
                    onClick={disableRestore ? undefined : onRestore}
                    className="mx-2 pg-link" style={(disableRestore || normalAdmin)? style.batchFnBtnDisabled : style.batchFnBtnActive}>
                    <i className="mx-1 fas fa-trash-restore"></i>Restore {context}
                </div>
                {(context === "practitioner" || context === "patient") && <>
                    <div
                        onClick={disableLockout ? undefined : onLockout}
                        className="mx-2 pg-link" style={disableLockout ? style.batchFnBtnDisabled : style.batchFnBtnActive}>
                        <i className="mx-1 fas fa-unlock"></i>Reset Lockout
                    </div>
                </>}
                {context === "practitioner" &&
                    <div
                        onClick={disableApprove ? undefined : onApprove}
                        className="mx-2 pg-link" style={disableApprove ? style.batchFnBtnDisabled : style.batchFnBtnActive}>
                        <i className="mx-1 fas fa-user-check"></i>Approve
                    </div>}

                {context === "patient" && <>
                    <div
                        onClick={disableReportArchive ? undefined : onReportArchive}
                        className="mx-2 pg-link" style={disableReportArchive ? style.batchFnBtnDisabled : style.batchFnBtnActive}>
                        <i className="mx-1 fas fa-archive"></i>Archive Report
                    </div>
                    <div
                        onClick={disableReportRestore ? undefined : onReportRestore}
                        className="mx-2 pg-link" style={disableReportRestore ? style.batchFnBtnDisabled : style.batchFnBtnActive}>
                        <i className="mx-1 fas fa-trash-restore"></i>Restore Report
                    </div>
                    <div className="mx-2 pg-link"
                      onClick={disableGeneticUploadReminder ? undefined : onSendGeneticUploadReminder}
                      style={disableGeneticUploadReminder ? style.batchFnBtnDisabled : style.batchFnBtnActive}>
                      <i className="mx-1 fas fa-bell"></i> Genetic Upload Reminder
                    </div>
                </>}
                {(context === "practitioner" || context === "patient") && <div className="mx-2 pg-link"
                  onClick={disableRegistrationReminder ? undefined : onRegistrationReminder}
                  style={disableRegistrationReminder ? style.batchFnBtnDisabled : style.batchFnBtnActive}>
                  <i className="mx-1 fas fa-bell"></i> Registration Reminder
                </div>}

        
      </div>
      <div onClick={onDownload} className="pg-link">
        <i className="mx-1 fas fa-download"></i>Download to CSV
      </div>
    </div>
  );
}

export default ActionButtons;
