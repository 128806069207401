import { AnimatePresence, motion } from "framer-motion";

export const Dropdown = ({ label, menuId, showMenu, toggleMenu, children }) => {
  const dropdownVariants = {
    open: {
      opacity: 1,
      height: "fit-content",
      transition: { duration: 0.3, ease: "easeInOut" },
    },
    closed: {
      opacity: 0,
      height: 0,
      transition: { duration: 0.3, ease: "easeInOut" },
    },
    initial: {
      opacity: 0,
      height: 0,
      transition: { duration: 0.3, ease: "easeInOut" },
    },
  };

  return (
    <li
      role="menuitem"
      aria-haspopup="true"
      aria-expanded={showMenu}
      onMouseEnter={toggleMenu}
      onMouseLeave={toggleMenu}
      onFocus={toggleMenu}
      onBlur={toggleMenu}
      style={{ zIndex: 9 }}
    >
      <button
        className=""
        type="button"
        aria-label={`${label} Menu`}
        aria-controls={menuId}
      >
        {label}
      </button>
      <AnimatePresence>
        {showMenu && (
          <motion.ul
            variants={dropdownVariants}
            initial="closed"
            animate="open"
            exit="closed"
            className="pg4-dropdown-menu"
            role="menu"
            id={menuId}
            aria-labelledby={`${label}-button`}
          >
            {children}
          </motion.ul>
        )}
      </AnimatePresence>
    </li>
  );
};
