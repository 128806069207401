import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const OrderHistoryCard = ({ cardInfo, context }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const token = useSelector(state => state.auth.token);
    const patient_id = useSelector(state => state.auth.currentUserId);
    const currentPractice = useSelector(state => state.auth.currentPractice);
    const { t, i18n } = useTranslation();
    const navigateFn = () => {
        navigate("/patient/order_history", {
            state: {
                path: "/pg_patient_orders",
                browseCatalog: false,
            }
        });
    }

    return (
        <div className="col-12 col-lg-6 align-self-stretch my-3">


            <div className="pg4-card position-relative overflow-hidden dashboard-card bg-light h-100 my-3" >
                <div className="d-flex flex-column justify-content-between h-100">
                    <div>
                        <div className="pg4-text__heading textc-primary__p" >{t('pracd_btn_8')}</div>
                        <div className="pg4-text my-2 fw-bold" >
                        {t('pat_order_history_sub_header_1')}
                        </div>
                    </div>
                    <button
                        className="pg4-btn pg4-btn_primary w-100"
                        onClick={navigateFn}
                    >
                        {t('pat_order_history_view_txt')}
                    </button>
                </div>
            </div>
        </div>
    );
}
