//  For PHQ
import PgInfoTooltip from "../../PgInfoTooltip";

export default function MSQToolTipText3() {
  return (
    <PgInfoTooltip position="left center" stroke="#000" height={30} width={30}>
      {/* <div style={{ padding: "12px" }}> */}
      FR PHQ-9 scores can be used to plan and monitor treatment. To score, tally
      the numbers of all the responses
      <br />
      (not at all=0, several days=1, more than half the days=2, and nearly every
      day=3).
      <br />
      Add the numbers together to total the score. Interpret the score by using
      the guide listed below.
      <br />
      <br />
      <table className="pg-table  my-2">
        <thead>
          <tr>
            <th>Score</th>
            <th>Depression Severity</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>0-4</td>
            <td>None-minimal</td>
            <td>Patient may not need depression treatment.</td>
          </tr>
          <tr>
            <td>5-9</td>
            <td>Mild</td>
            <td>
              Use clinical judgment about treatment,
              <br /> based on patient’s duration of symptoms and functional
              impairment.
            </td>
          </tr>
          <tr>
            <td>10-14</td>
            <td>Moderate</td>
            <td>
              Use clinical judgment about treatment,
              <br /> based on patient’s duration of symptoms and functional
              impairment.
            </td>
          </tr>
          <tr>
            <td>15-19</td>
            <td>Moderately severe</td>
            <td>
              Treat using antidepressants, psychotherapy or a combination of
              treatment.
            </td>
          </tr>
          <tr>
            <td>20-27</td>
            <td>Severe</td>
            <td>Treat using antidepressants with or without psychotherapy.</td>
          </tr>
        </tbody>
      </table>
      {/* </div> */}
    </PgInfoTooltip>
  );
}
