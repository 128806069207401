import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import PgLogo from "../assets/pg_logo_1.svg";
import PiLogo from "../assets/PureInsight_Logo_KO_u2.svg";
import "../styles/footer.css";
import useLanguageConditions from "../hooks/useLanguageConditions";

export default function Footer({ userType }) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { isCA, isUK, isUS } = useLanguageConditions();

  const inRialto = useSelector((state) => state.ui.inRialto);
  const enableECommerce = useSelector((state) => state.auth.enableECommerce);
  return (
    <footer
      className="container-fluid no-print py-5"
      style={{
        background: userType === "Patient" ? "#2470C2" : "var(--pg_primary)",
      }}
    >
      {inRialto && enableECommerce ? (
        <div className="container-xl px-0">
          <div className="row text-white">
            <div className="col-12 col-md-12 col-lg-3 ">
              <div className="mt-1 mt-lg-0 pg-text__bold text-white">
                {t("help")}
              </div>
              <Link
                className="my-2 text-white text-decoration-none d-block"
                to={
                  userType === "Patient" ? "patient-faq" : "/practitioner-faq"
                }
              >
                {userType === "Patient"
                  ? t("footer_link_2")
                  : t("footer_link_1")}
              </Link>
              <Link
                className="my-2 text-white text-decoration-none d-block"
                to="/patient/customer-service"
              >
                {t("customer_service_txt")}
              </Link>
            </div>
            <div className="col-12 col-md-12 col-lg-3">
              <div className="mt-3 mt-lg-0 pg-text__bold text-white">
                {t("about")}
              </div>
              <Link
                className="my-2 text-white text-decoration-none d-block"
                to="/contact-us"
              >
                {t("contact_us_txt")}
              </Link>
            </div>
            <div className="col-12 col-md-12 col-lg-3">
              <div className="mt-3 mt-lg-0 pg-text__bold text-white">
                {t("legal")}
              </div>
              <Link
                className="my-2 text-white text-decoration-none d-block"
                to={isCA ? "/disclaimer" : "/legal-disclaimer"}
              >
                {t("footer_link_6")}
              </Link>
              <Link
                className="my-2 text-white text-decoration-none d-block"
                to={isCA ? "/privacy-policy" : "/privacy"}
              >
                {t("footer_link_7")}
              </Link>
              <Link
                className="my-2 text-white text-decoration-none d-block"
                to="/dispensary-terms-use"
              >
                {t("dispensary_terms_txt")}
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-12 my-5 mt-5 pg-text__small text-white">
              <div className="pg-text__small text-white my-2">
                {t("footer_txt_1")}
              </div>
              <div className="pg-text__small text-white my-2">
                {t("footer_txt_2")}
              </div>
              <div className="pg-text__small text-white my-2">
                {t("footer_txt_3")}
              </div>

              <div className="row mt-4">
                <div className="col-6 col-md-4 col-lg-2 my-2 my-lg-0 ">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.facebook.com/PureEncapsulations"
                    className="text-white fw-bold"
                  >
                    {t("facebook")}
                  </a>
                </div>
                <div className="col-6 col-md-4 col-lg-2 my-2 my-lg-0">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://twitter.com/PureEncaps"
                    className="text-white fw-bold"
                  >
                    {t("twitter")}
                  </a>
                </div>
                <div className="col-6 col-md-4 col-lg-2 my-2 my-lg-0">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkedin.com/company/pure-encapsulations/"
                    className="text-white fw-bold"
                  >
                    {" "}
                    {t("linkedin")}
                  </a>
                </div>
                <div className="col-6 col-md-4 col-lg-2 my-2 my-lg-0">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.instagram.com/pureencapsulations/"
                    className="text-white fw-bold"
                  >
                    {t("instagram")}
                  </a>
                </div>
                <div className="col-6 col-md-4 col-lg-2 my-2 my-lg-0">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.youtube.com/user/pureencapsulations"
                    className="text-white fw-bold"
                  >
                    {t("youtube")}
                  </a>
                </div>
              </div>
              <div className="pg-text__small text-white mt-2">
                © {new Date().getFullYear()}{" "}
                <span dangerouslySetInnerHTML={{ __html: t("puregemonics") }} />
                . {t("all_rights_reserved")}
              </div>
            </div>
          </div>
          <div>
            {/* <PgLogo fill={"#fff"} fontSize="100px" /> */}
            {isUK ? (
              <img src={PgLogo} alt="logo" style={{ width: 172 }} />
            ) : (
              <img src={PiLogo} alt="logo" style={{ width: 172 }} />
            )}
          </div>
        </div>
      ) : (
        <div className="container-xl px-0">
          <div className="row text-white">
            <div className="col-12 col-md-12 col-lg-3 ">
              <div className="mt-1 mt-lg-0 pg-text__bold text-white">
                {t("help")}
              </div>
              <Link
                className="my-2 text-white text-decoration-none d-block"
                to={isCA ? "hcp-faqs" : "/hcp/faqs"}
              >
                {t("footer_link_1")}
              </Link>
              {isUK && (
                <Link
                  className="my-2 text-white text-decoration-none d-block"
                  to="/patient/gdpr-faqs"
                >
                  {t("footer_link_11")} {/* GDPR FAQs*/}
                </Link>
              )}
              <Link
                className="my-2 text-white text-decoration-none d-block"
                to={isCA ? "/patient-faqs" : "/patient/faqs"}
              >
                {t("footer_link_2")}
              </Link>
              <a
                className="my-2 text-white text-decoration-none d-block"
                href="https://www.youtube.com/playlist?list=PLfpPUB-xUWePWGSPdaZHTxam9qeg9REKD"
                target="_blank"
                rel="noreferrer"
              >
                {t("footer_link_3")}
              </a>
            </div>
            <div className="col-12 col-md-12 col-lg-3">
              <div className="mt-3 mt-lg-0 pg-text__bold text-white">
                {t("about")}
              </div>
              <Link
                className="my-2 text-white text-decoration-none d-block"
                to="/contact-us"
              >
                {t("footer_link_4")}
              </Link>
              {isUK ? (
                <Link
                  className="my-2 text-white text-decoration-none d-block"
                  to="/our-team"
                >
                  {t("footer_link_5")}
                </Link>
              ) : (
                ""
              )}
            </div>
            <div className="col-12 col-md-12 col-lg-3">
              <div className="mt-3 mt-lg-0 pg-text__bold text-white">
                {t("legal")}
              </div>
              {isUK && (
                <Link
                  className="my-2 text-white text-decoration-none d-block"
                  to="/cookies-notice"
                >
                  {t("fr_c_notice")}
                </Link>
              )}
              {isCA && (
                <Link
                  className="my-2 text-white text-decoration-none d-block"
                  to={
                    i18n.language === "fr"
                      ? "https://www.faitavecnestle.ca/temoins?_wrapper_format=html"
                      : "https://www.madewithnestle.ca/nestle-cookies-policy"
                  }
                >
                  {t("fr_c_notice")}
                </Link>
              )}
              <Link
                className="my-2 text-white text-decoration-none d-block"
                to={isCA ? "/disclaimer" : "/legal-disclaimer"}
              >
                {t("footer_link_6")}
              </Link>
              <Link
                className="my-2 text-white text-decoration-none d-block"
                to={
                  isCA
                    ? i18n.language === "fr"
                      ? "https://www.faitavecnestle.ca/protection-des-renseignements-personnels?_wrapper_format=html"
                      : "https://www.madewithnestle.ca/privacy-policy?_wrapper_format=html"
                    : "/privacy"
                }
              >
                {t("footer_link_7")}
              </Link>
              <Link
                className="my-2 text-white text-decoration-none d-block"
                to={isCA ? "/terms-of-service" : "/terms-use"}
              >
                {t("footer_link_9")}
              </Link>
              {i18n.language == "en" && (
                <Link
                  className="my-2 text-white text-decoration-none d-block"
                  to="/health-data-notice"
                >
                  {t("footer_link_10")}
                </Link>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12 my-5 mt-5 pg-text__small text-white">
              <div dangerouslySetInnerHTML={{ __html: t("footer_content") }} />
              <div className="pg-text__small text-white my-2">
                © {new Date().getFullYear()}{" "}
                <span
                  dangerouslySetInnerHTML={{ __html: t("footer_content_2") }}
                />
              </div>
            </div>
          </div>
          <div>
            {/* <PgLogo fill={"#fff"} fontSize="100px" /> */}
            {isUK ? (
              <img src={PgLogo} alt="logo" style={{ width: 172 }} />
            ) : (
              <img src={PiLogo} alt="logo" style={{ width: 172 }} />
            )}
          </div>
        </div>
      )}
    </footer>
  );
}
