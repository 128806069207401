import ContentLoader from "react-content-loader";
function L_FocusCardDetail(props) {
    return (
        <div>
            <div className="container">
                <ContentLoader
                    speed={2}
                    width="100%"
                    height="20%"
                    viewBox="0 0 600 450"
                    backgroundColor="#f5f5f5"
                    foregroundColor="#2470c210"
                    className="py-4"
                    {...props}
                >
                    <rect x="0" y="0" rx="3" ry="3" width="200" height="40" />
                    <rect x="0" y="50" rx="3" ry="3" width="200" height="30" />
                    <rect x="300" y="50" rx="3" ry="3" width="200" height="30" />
                    <rect x="0" y="100" rx="3" ry="3" width="200" height="150" />
                    <rect x="300" y="100" rx="3" ry="3" width="200" height="150" />

                    <rect x="0" y="260" rx="3" ry="3" width="200" height="150" />
                    <rect x="300" y="260" rx="3" ry="3" width="200" height="150" />
                </ContentLoader>
            </div>
        </div>
    );
}

export default L_FocusCardDetail;
