export default function PgCheckbox({ name, title, onCheck, checked, id }) {
  return (
    <label className="pg_checkbox my-3 mx-5 pg-text" id={`${id}__label`}>
      <input
        type="checkbox"
        name={name}
        id={id}
        onChange={onCheck}
        checked={checked}
      />
      {title}
      <div className="pg_checkbox__btn" id={`${id}__cb`}></div>
    </label>
  );
}
