import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
function GeneticUploadSuccess({ onAccept, onCancel, showModal }) {
  const { t, i18n } = useTranslation();
  return (
    <Modal
      show={showModal}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onCancel}
      contentClassName="border-radius"
    >
      <Modal.Body>
        <div className="pg-text ">
          <div className="pg-heading my-3">{t('thank_you')}</div>
          {t('prac_gene_success_upload_txt')}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn bg-none text-dark px-5" onClick={onCancel}>
          {t('cancel')}
        </button>
        <button className="btn bgc-primary text-light px-5" onClick={onAccept}>
        {t('ok')}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
export default GeneticUploadSuccess;
