import React from 'react'
import ContentLoader from 'react-content-loader'

const L_MSQSections = props => {
    return (
        <div className='py-2'>

            <ContentLoader
                speed={2}
                width={"100%"}
                height={530}
                viewBox="0 0 250 490"
                backgroundColor="#f5f5f5"
                foregroundColor="#2470c210"
                {...props}
            >

                <rect x="0" y="0" rx="8" ry="8" width="188" height="30" />
                <circle cx="230" cy="16" r="15" />

                <rect x="0" y="50" rx="8" ry="8" width="188" height="30" />
                <circle cx="230" cy="66" r="15" />


                <rect x="0" y="100" rx="8" ry="8" width="188" height="30" />
                <circle cx="230" cy="116" r="15" />



                <rect x="0" y="150" rx="8" ry="8" width="188" height="30" />
                <circle cx="230" cy="166" r="15" />


                <rect x="0" y="200" rx="8" ry="8" width="188" height="30" />
                <circle cx="230" cy="216" r="15" />


                <rect x="0" y="250" rx="8" ry="8" width="188" height="30" />
                <circle cx="230" cy="266" r="15" />

                <rect x="0" y="300" rx="8" ry="8" width="188" height="30" />
                <circle cx="230" cy="316" r="15" />

                <rect x="0" y="350" rx="8" ry="8" width="188" height="30" />
                <circle cx="230" cy="366" r="15" />

                <rect x="0" y="400" rx="8" ry="8" width="188" height="30" />
                <circle cx="230" cy="416" r="15" />

                <rect x="0" y="450" rx="8" ry="8" width="188" height="30" />
                <circle cx="230" cy="466" r="15" />


            </ContentLoader>
        </div>
    )
}

L_MSQSections.metadata = {
    name: 'Arfath Tade', // My name
    github: 'arfath77', // Github username
    description:
        'Global sidebar with tabs containig search bar and list of options', // Little tagline
    filename: 'L_MSQSections', // filename of your loader
}

export default L_MSQSections