import React, { useState, useEffect } from "react";
import { ProgressBar } from "react-bootstrap";
import { connect } from "react-redux";
import toast from "react-hot-toast";
import * as _ from "lodash";

import PasswordInput from "../../components/PasswordInput ";

import { parse } from "query-string";
import { validateEmail } from "../../methods";
import "./Login.css";
import { updatePassword } from "../../store/actions/auth";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function PasswordUpdatePage({ history, updatePasswordFn, loading }) {
  const { t } = useTranslation()
  // local state  variables
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [oldPasswordError, setOldPasswordError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [progressValue, setProgressValue] = useState(0);
  const [statusText, setStatusText] = useState("");
  const [c1, setC1] = useState(false);
  const [c2, setC2] = useState(false);
  const [c3, setC3] = useState(false);
  const [c4, setC4] = useState(false);
  const [c5, setC5] = useState(false);
  const [userType, setUserType] = useState("")
  const navigate = useNavigate();

  // to validate query
  useEffect(() => {
    const parsed = parse(window.location.search);
    setUserType(parsed.user_type);
  }, []);

  // to validate passowrd
  useEffect(() => {
    setC1(false);
    setC2(false);
    setC3(false);
    setC4(false);
    setC5(false);
    setProgressValue(0);
    setPasswordError(false);
    setConfirmPasswordError(false);
    setDisableSubmit(true);
    setStatusText("");
    if(userType == 'Patient'){
      if (password.length > 9) {
        setC1(true);
        setProgressValue(75);
        setStatusText(t('prp_line_16'));
      } else if (password.length > 7) {
        setProgressValue(75);
        setStatusText(t('prp_line_16'));
      } else if (password.length < 2) {
        setStatusText(t('prp_line_17'));
        setProgressValue(25);
      }
    }
    else if(userType == 'Practitioner'){
      if (password.length > 9) {
        setC1(true);
        setProgressValue(75);
        setStatusText(t('prp_line_16'));
      } else if (password.length > 7) {
        setProgressValue(75);
        setStatusText(t('prp_line_16'));
      } else if (password.length < 2) {
        setStatusText(t('prp_line_17'));
        setProgressValue(25);
      }
    }
    else{
      if (password.length > 15) {
        setC1(true);
        setProgressValue(75);
        setStatusText(t('prp_line_16'));
      } else if (password.length > 12) {
        setProgressValue(75);
        setStatusText(t('prp_line_16'));
      } else if (password.length > 7) {
        setStatusText(t('prp_line_17'));
        setProgressValue(50);
      } else if (password.length < 8 && password.length > 0) {
        setStatusText(t('prp_line_17'));
        setProgressValue(25);
      }
    }

    if (/(?=.*[A-Z])/.test(password)) setC2(true);
    if (/(?=.*[a-z])/.test(password)) setC3(true);
    if (/(?=.*\d)/.test(password)) setC4(true);
    if (/(?=.*[\W_])/.test(password)) setC5(true);

    if (confirmPassword.length > 0 && confirmPassword !== password) {
      setConfirmPasswordError(true);
      // setDisableSubmit(true)
    }

    if(c1 && c2 && c3 && c4 && c5){
      setStatusText(t('prp_line_15'));
      setProgressValue(100);
    }

    if (validateEmail(email) && c1 && c2 && c3 && c4 && c5 && password === confirmPassword && oldPassword.length > 0) {
      setConfirmPasswordError(false);
      setDisableSubmit(false);
    }
  }, [password, email, confirmPassword, c1, c2, c3, c4, c5, oldPassword]);

  let handlePasswordUpdate = async (e) => {
    if (!validateEmail(email)){
      setEmailError(true);
      return;
    }

    e.preventDefault();
    let data = {
      user: {
          email: email,
          old_password: oldPassword,
          new_password: password,
          confirm_password: confirmPassword
      }
    }
    const response = await updatePasswordFn(data);
    if (!response.error) {
      navigate("/users/login");
      toast.success("Password reset successful");
    }
  };
  return (
    <div className="container-fluid">
      <div className=" py-3 px-2 px-lg-5 mx-0 mx-lg-5">
        <div className="my-3">
          <div className="pg-title">Update Password</div>
        </div>
        <form onSubmit={handlePasswordUpdate} className="mb-5">
          <div className="row justify-content-center justify-content-md-start">
            <div className="col-lg-4 col-12">
              <TextInput
                value={email}
                error={emailError}
                errorMessage={emailErrorMessage}
                placeholder={t('auth_page_email_ph')}
                className="my-0 py-0"
                id="email-input__resetpassword"
                inputHandler={(e) => {
                  setEmail(String(e.target.value).toLowerCase());
                  if (e.target.value.length == 0 || !validateEmail(e.target.value))
                    {
                      setEmailError(true);
                      setEmailErrorMessage(t('auth_page_email_error'))
                    } else {
                      setEmailError(false);
                    }
                  }}
              />
              <PasswordInput
                className="my-0 py-0"
                placeholder={t('npm_line_2')}
                error={oldPasswordError}
                errorMessage={t('npm_line_4')}
                value={oldPassword}
                inputHandler={(e) => {
                  setOldPassword(e.target.value)
                  if (e.target.value.length == 0){
                    setOldPasswordError(true)
                  }
                  else{
                    setOldPasswordError(false)
                  }
                }}
                id="old-password-input__resetpassword"
                required={true}
              />
              <PasswordInput
                className="my-0 py-0"
                placeholder={t('prp_line_5')}
                value={password}
                error={passwordError}
                errorMessage={
                  userType === 'Patient'
                    ? "Minimum of 10 characters"
                    : userType === 'Practitioner'
                    ? "Minimum of 10 characters"
                    : "Minimum of 16 characters"
                }
                inputHandler={(e) => setPassword(e.target.value)}
                id="password-input__resetpassword"
                required={true}
              />
              <PasswordInput
                placeholder={t('prp_line_7')}
                value={confirmPassword}
                error={confirmPasswordError}
                errorMessage={t('prp_line_8')}
                inputHandler={(e) => setConfirmPassword(e.target.value)}
                id="confirm-password-input__resetpassword"
                required={true}
              />
            </div>
            <div className="offset-lg-1 col-lg-4 col-10 pg-text pt-3 ps-0">
              <div className="ms-4 ps-1">{t('prp_line_9')}</div>
              <div className={c1 ? "my-3 text-success" : "my-3 text-secondary"}>
                {c1 ? (
                  <i className="fas fa-check-circle fs-5 me-1"></i>
                ) : (
                  <i className="far fa-check-circle fs-5 me-1"></i>
                )}{" "}
                {
                  userType === 'Patient'
                    ? "Minimum of 10 characters"
                    : userType === 'Practitioner'
                    ? "Minimum of 10 characters"
                    : "Minimum of 16 characters"
                }
              </div>
              <div className={c2 ? "my-3 text-success" : "my-3 text-secondary"}>
                {c2 ? (
                  <i className="fas fa-check-circle fs-5 me-1"></i>
                ) : (
                  <i className="far fa-check-circle fs-5 me-1"></i>
                )}{" "}
                {t('prp_line_10')}
              </div>
              <div className={c3 ? "my-3 text-success" : "my-3 text-secondary"}>
                {c3 ? (
                  <i className="fas fa-check-circle fs-5 me-1"></i>
                ) : (
                  <i className="far fa-check-circle fs-5 me-1"></i>
                )}{" "}
                {t('prp_line_11')}
              </div>
              <div className={c4 ? "my-3 text-success" : "my-3 text-secondary"}>
                {c4 ? (
                  <i className="fas fa-check-circle fs-5 me-1"></i>
                ) : (
                  <i className="far fa-check-circle fs-5 me-1"></i>
                )}{" "}
                {t('prp_line_12')}
              </div>
              <div className={c5 ? "my-3 text-success" : "my-3 text-secondary"}>
                {c5 ? (
                  <i className="fas fa-check-circle fs-5 me-1"></i>
                ) : (
                  <i className="far fa-check-circle fs-5 me-1"></i>
                )}{" "}
                {t('prp_line_20')}
              </div>
              <div className="ms-4 ps-1">
                {t('prp_line_13')}{" "}
                <span
                  className={
                    progressValue <= 50
                      ? "text-danger"
                      : progressValue <= 75
                        ? "text-info"
                        : "text-success"
                  }
                >
                  {" "}
                  {statusText}
                </span>
                <div className="pe-lg-3 me-lg-3">
                  <ProgressBar
                    now={progressValue}
                    variant={
                      progressValue <= 50
                        ? "danger"
                        : progressValue <= 75
                          ? "info"
                          : "success"
                    }
                    style={{ height: 10, width: "100%" }}
                    className="border-radius my-3"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row my-lg-0 my-5">
            <div className="col-12 col-lg-4">
              <input
                type="submit"
                value={'Update Password'}
                disabled={disableSubmit}
                className="btn bgc-primary text-light w-100"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.addPatient.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updatePasswordFn: (data) => dispatch(updatePassword({ data })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordUpdatePage);

function TextInput({ error, errorMessage, type, inputHandler, placeholder, value, id }) {
  const style = {
    padding: 15,
    width: "100%",
    outline: "none",
    border: error ? "2px solid #f00" : "3px solid #acd4ff",
    borderRadius: "4px",
    height: 50,
    transition: "all .3s ease-in-out",
  };
  const { t } = useTranslation();
  return (
    <div className="w-90 my-3">
      <input
        placeholder={placeholder}
        type={type}
        id={id}
        onInput={inputHandler}
        value={value}
        style={style}
        required={true}
      />
      <div
        className="text-danger mx-2 my-1 transition-all"
        style={error ? { opacity: 1 } : { opacity: 0 }}
      >
        <i className="fas fa-info-circle"></i> {errorMessage}
      </div>
    </div>
  );
}
