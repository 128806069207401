import React, { useState, useEffect } from "react";

function ValidationError(props) {
  
    const validationError = props.question;
  
  return (
    <div>
      <div
        className="talk-bubble tri-right round border right-top"
        style={{ position: "relative" }}
      >
        <div className="talktext">
          <p className="pg-text">{validationError.question}</p>
        </div>
      </div>
    </div>
  );
}

export default ValidationError;
