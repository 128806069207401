import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { connect } from "react-redux";
import qs, { parseUrl } from "query-string";
import { Helmet } from "react-helmet";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import InfoBox from "../../components/Practice/Dashboard/InfoBox";
import PatientTile from "../../components/Practice/Dashboard/PatientTile";
import PatientSupplement from "../../components/Practice/Dashboard/PatientSupplement";
import SupplementCatalogTile from "../../components/Practice/Dashboard/SupplementCatalogTile";
import SupportAndResources from "../../components/Practice/Dashboard/SupportAndResources";
import { updatePatientReport } from "../../store/actions/patientReport";
// Action
import {
  getDashboardContent,
  closeWhatsNew,
} from "../../store/actions/practiceDashboard";
import { acceptTermsConditions, changeIsAuth } from "../../store/actions/auth";
import { Loading } from "../../components/Loading";
import TermsAndConditions from "../TermsAndConditions";
import GDPRTile from "../../components/Practice/Dashboard/GDPRTile";
import GDPRPracticeStatus from "./GDPRPracticeStatus";
import { dispatch } from "react-hot-toast";
import useLanguageConditions from "../../hooks/useLanguageConditions";

function Dashboard({ history }) {
  const { t, i18n } = useTranslation();
  const { isCA, isUK, isUS } = useLanguageConditions();

  const navigate = useNavigate();

  const [showInfo, setshowInfo] = useState(false);
  const [hideFirstInfo, setHideFirstInfo] = useState(false);
  const [hideSecondInfo, setHideSecondInfo] = useState(false);
  const [showRialtoTermModal, setShowRialtoTermModal] = useState(false);
  const [showRebrandingTerm, setshowRebrandingTerm] = useState(false);
  const [rebrandingTerm, setRebrandingTerm] = useState("");
  const [rialtoTerms, setRialtoTerms] = useState("");
  const [showpracticeTermModal, setShowpracticeTermModal] = useState(false);
  const [practiceTerms, setPracticeTerms] = useState("");
  const isStaff = useSelector((state) => state.auth.isStaff);
  // const [showLoading, setShowLoading] = useState(true)

  // redux state variable
  const currentPracticeID = useSelector(
    (state) => state.auth.currentPracticeID
  );
  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const token = useSelector((state) => state.auth.token);
  const loading = useSelector((state) => state.practiceDashboard.loading);
  const content = useSelector((state) => state.practiceDashboard.content);
  const termsAgreed = useSelector((state) => state.auth.termsAgreed);
  const firstName = useSelector((state) => state.auth.firstName);
  const lastName = useSelector((state) => state.auth.lastName);
  const lang = useSelector((state) => state.auth.language);
  const dispatch = useDispatch();
  // redux actions
  const getDashboardContentAction = (
    token,
    currentPracticeID,
    currentPractice
  ) => dispatch(getDashboardContent(token, currentPracticeID, currentPractice));
  const closeWhatsNewAction = (
    token,
    currentPracticeID,
    currentPractice,
    closeType
  ) =>
    dispatch(
      closeWhatsNew(token, currentPracticeID, currentPractice, closeType)
    );
  const updatePatientReportAction = (data) =>
    dispatch(updatePatientReport(data));
  const acceptTermsConditionsAction = (
    token,
    currentPracticeID,
    currentPractice,
    type
  ) =>
    dispatch(
      acceptTermsConditions(token, currentPracticeID, currentPractice, type)
    );
  const changeAuth = (pld) => dispatch(changeIsAuth(pld));

  const getDashboardData = () => {
    getDashboardContentAction(token, currentPracticeID, currentPractice).then(
      (response) => {
        if (!response.error) {
          if (!termsAgreed) {
            setPracticeTerms(response.payload?.terms_and_condition);
            setShowpracticeTermModal(true);
          } else if (!response.payload?.agree_rebranding_term) {
            setRebrandingTerm(response.payload?.rebranding_terms?.content);
            setshowRebrandingTerm(true);
          } else if (response.payload.agree_rialto_term) {
            setRialtoTerms(response.payload.rialto_terms.content);
            setShowRialtoTermModal(true);
          }
          setshowInfo(!_.isEmpty(response.payload.whats_new_header));
        }
      }
    );
  };

  useEffect(() => {
    // const to = parseUrl(window.location.href).query
    // if (to.patient_id) {
    // 	navigate(`${to.to}&patient_id=${to.patient_id}`)
    // 	setShowLoading(false)
    // } else {
    // setShowLoading(false)
    let qp = parseUrl(window.location.href).query;
    console.log(qp, "-=-=-=-=-=-=-=-=");
    if (qp.scriptId) {
      const obj = qs.parse(window.location.search, { decode: false });
      return navigate("/patient/supplement_suggestions", {
        state: {
          path: "/supplement_suggestions",
          script: obj,
        },
      });
    }
    if (qp.orderId) {
      console.log(qp.orderId, "-=-=-=-=-=-=-=-=");
      const obj = qs.parse(window.location.search, { decode: false });
      return navigate("/practice/order/" + obj.orderId, {
        state: {
          path: `/order/${obj.orderId}`,
          orderId: obj.orderId,
        },
      });
    }

    getDashboardData();
  }, []);

  useEffect(() => {
    if (termsAgreed && showpracticeTermModal) {
      getDashboardData();
      setShowpracticeTermModal(false);
    }
  }, [termsAgreed]);

  const handleClose = (closeType) => {
    closeWhatsNewAction(
      token,
      currentPracticeID,
      currentPractice,
      closeType
    ).then((response) => {
      if (!response.error) {
        if (closeType === "1") {
          setHideFirstInfo(!hideFirstInfo);
        } else {
          setHideSecondInfo(!hideSecondInfo);
        }
      }
    });
  };

  const handleAcceptTerms = (e) => {
    acceptTermsConditionsAction(
      token,
      currentPracticeID,
      currentPractice,
      e
    ).then((response) => {
      if (!response.error) {
        if (e === "rebranding_agreement") setshowRebrandingTerm(false);
        if (e === "practice") {
          changeAuth({ termsAgreed: true });
        } else {
          setShowRialtoTermModal(false);
        }
        if (e !== "practice") getDashboardData();
      }
    });
  };

  return (
    <div>
      <Helmet>
        <title>{t("puregenomics_1")} - Practitioner Dashboard</title>
      </Helmet>
      {/* {showLoading
				? <Loading />
				:  */}
      <div className="container-xl py-5">
        <GDPRPracticeStatus />
        {showRialtoTermModal && (
          <div>
            <TermsAndConditions
              showModal={true}
              content={rialtoTerms}
              showClose={false}
              showButton={true}
              acceptTerms={() => handleAcceptTerms("rialto")}
              title={"VIRTUAL DISPENSARY TERMS OF USE"}
            />
          </div>
        )}
        {showRebrandingTerm && (isUS || isCA) && (
          <div>
            <TermsAndConditions
              showModal={true}
              content={rebrandingTerm}
              showClose={false}
              showButton={true}
              acceptTerms={() => handleAcceptTerms("rebranding_agreement")}
              title={"NOTICE AND ACKNOWLEDGEMENT"}
            />
          </div>
        )}
        {showpracticeTermModal && (
          <div>
            <TermsAndConditions
              showModal={true}
              content={practiceTerms}
              showClose={false}
              showButton={true}
              acceptTerms={() => handleAcceptTerms("practice")}
              title={"PUREGENOMICS PLATFORM TERMS OF USE"}
            />
          </div>
        )}
        <div className="mt-3 my-2 pg-title ">
          {t("pracd_welcome")}, {firstName} {lastName}
        </div>
        <div className="containers">
          <div className="row g-5">
            <div className="col-12 col-sm-12 col-lg-6">
              <PatientTile
                updatePatientReport={updatePatientReportAction}
                loading={loading}
                content={loading ? {} : content?.patients_recent_activity}
              />
            </div>
            <div className="col-12 col-sm-12 col-lg-6">
              <PatientSupplement loading={loading} />
            </div>
            {isUK && !isStaff && (
              <div className="col-12 col-sm-12 col-lg-6">
                <GDPRTile />
                <div className="my-5 col-12 col-sm-12">
                  <SupportAndResources />
                </div>
              </div>
            )}

            {showInfo && (!hideFirstInfo || !hideSecondInfo) && (
              <div className="col-12 col-lg-6">
                <InfoBox
                  onCloseHandler={handleClose}
                  content={loading ? {} : content?.whats_new_header}
                  loading={loading}
                  hideFirstInfo={hideFirstInfo}
                  hideSecondInfo={hideSecondInfo}
                />
              </div>
            )}
            {(isUS || isCA || isStaff) && (
              <div className="col-12 col-lg-6">
                <SupportAndResources />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
