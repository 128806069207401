import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import _ from 'lodash';
import { getGDPRRequestLogForPractitioner } from '../../../store/actions/practiceDashboard';
import L_GDPRActivitiesTile from '../../LoadingEffectComponent/Dashboard/Practice/L_GDPRActivitiesTile';
import { useTranslation } from "react-i18next";

function GDPRTile() {
  const navigate = useNavigate();
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [totalRequest, settotalRequest] = useState(0)

    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.token);
    const currentPractice = useSelector((state) => state.auth.currentPractice);
    const currentPracticeID = useSelector((state) => state.auth.currentPracticeID);
    const gdprRequestLog = () => dispatch(getGDPRRequestLogForPractitioner(token, currentPracticeID, currentPractice))

    useEffect(() => {
        gdprRequestLog().then(response => {
            setLoading(false)
            if (!response.error) {
                console.log(response)
                setData(response.payload.response)
                settotalRequest(response.payload.total_count)
            }
        });
    }, [])


    useEffect(() => {
        console.log('data updated', data)
    }, [data])

    // const data = [{ text: ' Canadian Users Sign-in Here Canadian Users Sign-in Here', status: 'requested' }, { text: 'Canadian Users Sign-in Here', status: 'requested' }]
    const { t, i18n } = useTranslation();
    return (
        <div className='pg-card position-relative p-4' style={{ minHeight: 700, backgroundColor: '#14328c' }} >
            {loading
                ? <L_GDPRActivitiesTile />
                : <div>
                    <div className="pg-heading text-white text-center my-2">{t('header_btn_18')}</div>
                    <div className="my-4 mt-5">
                        <div className="d-flex justify-content-between">
                            <div className="mt-3 pg-text__bold text-white mb-3">{t('pracd_ra')}</div>
                            <div className="mt-3 pg-text__bold text-white mb-3 me-5">{t('prac_status')}</div>
                        </div>
                        {data.length < 1 && (
                            <div className="d-flex justify-content-between pg-text text-white text-center">
                                <div>{t('pracd_nodata')}</div>
                            </div>
                        )}
                        {data.length > 0 && data.map((__data, i) => {
                            return (
                                <div key={i}>
                                    <div className="d-flex justify-content-between pg-text text-white my-1">
                                        <div>{__data.text}</div>
                                        <div
                                            className="text-start w-md-100"
                                            style={{ width: "25%" }}
                                        >
                                            <div
                                                className="text-capitalize d-inline-block cursor-pointer text-underline my-1"
                                                id={`view-results__dashboard__${i + 1}`}
                                                style={{ color: '#b7dbff' }}
                                                onClick={() =>navigate(`/practice/patients/gdpr?p_id=${__data.text.split('\'')[0]}`)}
                                            >
                                                {_.startCase(__data.status)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                        <div className='mt-2' style={{ borderBottom: '1px solid #fff' }} />
                        <div className="pg-text__bold text-white my-2">{t('pracd_stat')}</div>
                        <div className="d-flex">
                            <div className="pg-heading__count">
                                {totalRequest}
                            </div>
                            <div className="pg-text__patient">{t('prac_total_req')}</div>
                        </div>
                        <div className="mt-5 pg-card__btn w-100">
                            <button
                                className="btn btn-light px-5 d-block mx-auto my-4"
                                onClick={() =>navigate("/practice/patients/gdpr")}
                                id="view-patient-btn__dashboard"
                            >
                                {t('prac_view_list')}
                            </button>
                            {/* <button
                                className="btn btn-outline__invert px-3 d-block mx-auto my-2"
                                id="add-patient-btn__dashboard"
                            // onClick={() =>navigate("/practice/gdpr")}
                            >
                                Manage Patient GDPR
                            </button> */}
                        </div>
                    </div>
                </div>}
        </div>
    )
}

export default GDPRTile