import React from "react";
import { useTranslation } from "react-i18next";

function LegalDesclaimer() {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-11  my-5 px-md-0 px-3">
            <span dangerouslySetInnerHTML={{ __html: t('disclaimer_content') }} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LegalDesclaimer;
