const TextInputWithIcon = ({
  Icon,
  type,
  inputHandler,
  placeholder,
  error,
  errorMessage,
  value,
  isDisable,
  id,
  required, label
}) => {
  const style = {
    padding: 5,
    width: "100%",
    border: "3px solid #acd4ff",
    borderRadius: "4px",
    height: 50,
  };

  return (
    <div className="search-input">
      {/* <div className="d-inline-block pg-text__bold textc-primary">{label}</div> {required && <span className="textc-primary fst-italic">(Required)</span>} */}
      <div className="d-flex justify-content-between" style={style}>
        <span className="m-auto text-center p-0" style={{ width: 30 }}>{Icon}</span>
        <input
          placeholder={placeholder}
          type={type}
          onInput={inputHandler}
          value={value}
          style={{
            border: "none",
            width: "100%",
            height: "100%",
            margin: "0px 5px",
            outline: "none",
          }}
          id={id}
          disabled={isDisable}
        />
      </div>
    </div>
  );
};


export default TextInputWithIcon;