import { useEffect, useState } from "react";
import { connect } from "react-redux";
import jwt_decode from "jwt-decode";
import Cookies from "universal-cookie";
import { toast, Toaster } from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import { useGA4React } from "ga-4-react";
import { QueryClient, QueryClientProvider } from "react-query";

import { Loading } from "./components/Loading";
import LoadingOverlay from "./components/LoadingOverlay";
import MainLayout from "./Layout/MainLayout";
import SideNav from "./components/Sidenav";
import "./App.css";
import MaintainanceBanner from "./components/MaintainanceBanner";

import { refreshToken } from "./store/actions/auth";
import { getPractitionerTerms } from "./store/actions/practitionerAccount";
import TermsAndConditions from "./Pages/TermsAndConditions";
import { clearCookie } from "./methods";
import ErrorBoundary from "./components/ErrorBoundary";
import { useIdleTimer } from "react-idle-timer";
import DOBInputModal from "./components/DOBInputModal";
import { logoutAction } from "./methods/auth_methods";
const DOMAIN_FOR_COOKIE = process.env.REACT_APP_BASE_DOMAIN_FOR_COOKIE;
const IN_MAINTAINANCE = process.env.REACT_APP_SHOW_MAINTAINANCE_BANNER;
const MAINTAINANCE_START_TIME = process.env.REACT_APP_MAINTAINANCE_START_TIME;
const MAINTAINANCE_END_TIME = process.env.REACT_APP_MAINTAINANCE_END_TIME;

const cookies = new Cookies();
const timeout = 15 * 60 * 1000; // 15 min
const promptBeforeIdle = 5_000;

function App() {
  const qc = new QueryClient();

  // redux state variable
  const showLoading = useSelector((state) => state.ui.loading);
  const _token = useSelector((state) => state.auth.token);
  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const isAuth = useSelector((state) => state.auth.isAuth);
  const mfaEnabled = useSelector((state) => state.auth.mfaEnabled);
  const termsAgreed = useSelector((state) => state.auth.termsAgreed);
  const userType = useSelector((state) => state.auth.userType);
  const showDobBanner = useSelector((state) => state.auth.showDobBanner);
  const currentPracticeID = useSelector(
    (state) => state.auth.currentPracticeID
  );
  const dispatch = useDispatch();

  // redux actions

  const reloadState = (payload) => dispatch({ type: "RELOAD_STATE", payload });

  // const ga4React = useGA4React();
  const [loading, setloading] = useState(true);
  const [practitionerTerms, setPractitionerTerms] = useState("");
  const [showModal, setShowModal] = useState(true);
  const [showMaintanenceModal, setShowMaintanenceModal] = useState(
    IN_MAINTAINANCE === "true"
  );
  const [showDOBInputModal, setShowDOBInputModal] = useState(false);
  // useEffect(() => {
  // ga4React?.pageview(window.location.pathname + window.location.search);
  // }, [location])

  const { start, activate, reset } = useIdleTimer({
    timeout,
    promptBeforeIdle,
    crossTab: true,
    startManually: true,
    // onActive: () => console.log('user is active again'),
    onPrompt: () => {
      if (isAuth) {
        toast(
          <LogoutIdleAlert
            onContinue={() => start()}
            onDismiss={autoLogout}
            remainingSeconds={5}
          />,
          {
            duration: 60000,
          }
        );
      }
    },
    onIdle: () => {
      toast.dismiss();
      if (isAuth) {
        console.log("user is idle");
        reset();
        logoutAction();
      } else {
        console.log("user is idle no actions performed");
      }
    },
  });

  const continueLoggedIn = () => {
    toast.dismiss();
    dispatch(refreshToken(_token, currentPractice, currentPracticeID)).then(
      (response) => {
        if (response.error) {
          logoutAction();
        }
      }
    );
    console.log("call api to refresh token!!!");
  };

  const autoLogout = () => {
    toast.dismiss();
    logoutAction();
  };

  useEffect(() => {
    if (cookies.get("isAuthenticated") === "true") {
      const token = cookies.get("token");
      let decodedData;
      try {
        decodedData = jwt_decode(token);
        reloadState({
          token,
          ...decodedData,
          practiceID: cookies.get("p_id"),
          practice: cookies.get("practice"),
          termsAgreed: cookies.get("termsAgreed"),
          showDobBanner: cookies.get("showDOBBanner") === "true",
        });
        clearCookie();
        console.log("decoded token", decodedData);
        // localStorage.setItem('i18nextLng', decodedData.user.language);
        cookies.set("i18next", decodedData.user.language, { path: "/" });
        // window.location.href =
        //   window.location.href + "?lng=" + decodedData.user.language;
      } catch (error) {
        toast.error("Login again");
      }
      setloading(false);
    } else setloading(false);
  }, []);

  useEffect(() => {
    if (userType === "Patient") {
      console.info("setting patient based themes");
      window.document.body.classList.add("patient");
    }
  }, [userType]);

  useEffect(() => {
    if (mfaEnabled && !termsAgreed && isAuth && userType == "User") {
      dispatch(
        getPractitionerTerms(_token, currentPracticeID, currentPractice)
      ).then((response) => {
        if (!response.error) {
          const practitionerTerms = response.payload.terms_and_agreements;
          setPractitionerTerms(practitionerTerms[0].content);
        }
      });
    }
  });

  useEffect(() => {
    let timer;
    if (_token) {
      if (start()) console.log("Warn: IdleTimer started!");
      let decodedData = jwt_decode(_token);
      timer = setInterval(() => {
        let remainingSeconds =
          decodedData.exp - Math.round(new Date().getTime() / 1000);
        if (remainingSeconds < 61) {
          clearInterval(timer);
          toast(
            <LogoutAlert
              onContinue={continueLoggedIn}
              onDismiss={autoLogout}
              remainingSeconds={remainingSeconds}
            />,
            {
              duration: 60000,
            }
          );
        }
      }, 1000);
      return () => {
        clearInterval(timer);
      };
    }
  }, [_token]);

  return loading ? (
    <Loading />
  ) : (
    <div className="position-relative">
      {showLoading && <LoadingOverlay />}
      <SideNav />
      {showMaintanenceModal && (
        <MaintainanceBanner
          showModal={true}
          onCancel={() => setShowMaintanenceModal(false)}
          startTime={MAINTAINANCE_START_TIME}
          endTime={MAINTAINANCE_END_TIME}
        />
      )}
      {showDobBanner && userType === "Patient" && (
        <DOBInputModal showModal={true} onCancel={setShowDOBInputModal} />
      )}
      <QueryClientProvider client={qc}>
        <ErrorBoundary
          fallback={
            <div>
              <center>
                <h3 style={{ color: "red" }}>Something went wrong!</h3>
                <p>Please check console for more detail</p>
              </center>
            </div>
          }
        >
          <MainLayout />
        </ErrorBoundary>
      </QueryClientProvider>
    </div>
  );
}

export default App;

const LogoutIdleAlert = ({ onContinue, onDismiss, remainingSeconds }) => {
  const [counter, setCounter] = useState(remainingSeconds);
  const [timer, setTimer] = useState("");

  useEffect(() => {
    console.log(counter);
    if (counter < 1) {
      clearInterval(timer);
      onDismiss();
    }
  }, [counter]);

  useEffect(() => {
    showCounter(counter);
  }, []);

  const showCounter = (counter) => {
    setTimer(
      setInterval(() => {
        setCounter((c) => --c);
      }, 1000)
    );
  };

  return (
    <span>
      <p>Your session will be logged out in {counter} seconds!</p>
      {/* <button
        className="btn btn-outline px-5 my-3"
        onClick={() => onContinue()}
      >
        Continue to stay logged in
      </button> */}
    </span>
  );
};

const LogoutAlert = ({ onContinue, onDismiss, remainingSeconds }) => {
  const [counter, setCounter] = useState(remainingSeconds);
  const [timer, setTimer] = useState("");

  useEffect(() => {
    console.log(counter);
    if (counter < 1) {
      clearInterval(timer);
      onDismiss();
    }
  }, [counter]);

  useEffect(() => {
    showCounter(counter);
  }, []);

  const showCounter = (counter) => {
    setTimer(
      setInterval(() => {
        setCounter((c) => --c);
      }, 1000)
    );
  };

  return (
    <span>
      <p>Your session will be logged out in {counter} seconds!</p>
      <button
        className="btn btn-outline px-5 my-3"
        onClick={() => onContinue()}
      >
        Continue to stay logged in
      </button>
    </span>
  );
};
