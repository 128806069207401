import React, { Component, useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import toastr from "react-hot-toast";
import { parse } from "query-string";
import _ from "lodash";

import TextInput from "../../TextInput";
import { validateEmail } from "../../../methods";
import L_EditPractitioner from "../../LoadingEffectComponent/Admin/L_EditPractitioner";

import { getStaff, editStaff } from "../../../store/actions/practiceDashboard";
import { useLocation } from "react-router-dom";

import {} from "../../../store/actions/practiceDashboard";
import PgCheckbox from "../../../components/PgCheckbox";
import { useTranslation } from "react-i18next";

function EditStaffModal({
  onCancel,
  showModal,
  practitioner,
  updatePractitionerTable,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const location = useLocation();
  const params = parse(location.search);
  const selectedRegion = params["region"];

  const [gettingPracInfo, setgettingPracInfo] = useState(true);

  //form fields
  const [practitionerId, setPractitionerId] = useState("");
  const [fName, setfName] = useState("");
  const [lName, setlName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [accountType, setAccountType] = useState("");

  //for validation
  const [fNameError, setfNameError] = useState(false);
  const [lNameError, setlNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [addressValidation, setAddressValidation] = useState(false);

  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const currentPracticeID = useSelector(
    (state) => state.auth.currentPracticeID
  );

  const [optionalPermissions, setOptionalPermissions] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  useEffect(() => {
    dispatch(
      getStaff(token, practitioner.id, currentPractice, currentPracticeID)
    ).then((response) => {
      if (!response.error) {
        const data = response.payload;
        setgettingPracInfo(false);
        setPractitionerId(data.id);
        setEmail(data.email || "");
        setfName(data.first_name || "");
        setlName(data.last_name || "");
        setPhone(data.phone_number || "");
        setOptionalPermissions(data.optional_permissions);
        if (Array.isArray(data.optional_permissions))
          setOptionalPermissions(
            data.optional_permissions.map((permission) => ({
              key: _.lowerCase(permission),
              value: permission,
            }))
          );
        setSelectedPermissions(data.selected_permissions);
      } else {
        // toastr.error('Something went wr')
        onCancel();
      }
    });
  }, []);

  const onDone = () => {
    setfNameError(false);
    setlNameError(false);
    setEmailError(false);
    setPhoneError(false);

    if (email.length === 0 || !validateEmail(email)) setEmailError(true);
    if (fName.length === 0) setfNameError(true);
    if (lName.length === 0) setlNameError(true);
    if (phone.length === 0) setPhoneError(true);
    if (
      email.length !== 0 &&
      validateEmail(email) &&
      fName.length !== 0 &&
      lName.length !== 0 &&
      phone.length !== 0
    ) {
      handleDone();
    } else {
      document
        .querySelectorAll(".edit-practitioner-modal")[0]
        .scrollTo({ top: 0, behavior: "smooth" });
    }
  };
  const handleDone = async () => {
    let data = {
      staff: {
        // id: practitionerId,
        first_name: _.startCase(fName),
        last_name: lName,
        full_name: `${_.startCase(fName)} ${lName}`,
        email: email.toLowerCase(),
        phone_number: phone,
        access_roles: selectedPermissions,
      },
    };
    dispatch(
      editStaff(
        token,
        practitioner.id,
        data,
        currentPractice,
        currentPracticeID
      )
    ).then((response) => {
      if (false) {
        // setAtriumIdError(true);
        // document
        // .querySelectorAll(".edit-practitioner-modal")[0]
        // .scrollTo({ top: 0, behavior: "smooth" });
      } else if (!response.error) {
        toastr.success(t("staff_edit_alert_1"));
        onCancel();
        updatePractitionerTable();
      }
    });
  };

  const loadPermissions = (value) => {
    setSelectedPermissions((selectedValue) => {
      let oldSelectedValue = [...selectedValue];
      if (oldSelectedValue.includes(value)) {
        return oldSelectedValue.filter(
          (singleSelectedValue) => singleSelectedValue != value
        );
      } else {
        return [...oldSelectedValue, value];
      }
    });
  };

  function OptionalPermission() {
    return optionalPermissions.map(({ key, value }, index) => {
      return (
        <PgCheckbox
          name="optional-permission"
          id={index}
          key={index}
          checked={selectedPermissions.includes(value)}
          onCheck={() => loadPermissions(value)}
          title={t(key)}
        />
      );
    });
  }

  return (
    <Modal
      show={showModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={onCancel}
      contentClassName="border-radius"
      backdrop="static"
      className="edit-practitioner-modal"
      id="edit-practitioner-modal"
    >
      <Modal.Body>
        <div
          style={{
            position: "absolute",
            right: 25,
            top: 15,
            fontSize: 20,
            cursor: "pointer",
            color: "var(--pg_primary)",
          }}
          onClick={onCancel}
        >
          <div className="fas fa-times"></div>
        </div>
        <div className="container">
          <div className="text-center pg-title">
            {t("prac_edit_staff_modal_header_1")}
          </div>
          {gettingPracInfo ? (
            <L_EditPractitioner />
          ) : (
            <div className="row">
              <div className="col-12">
                <TextInput
                  label={t("staff_members_new_fname")}
                  showLabel={true}
                  required={true}
                  id="f-name__addpractitioner"
                  placeholder={t("staff_members_new_fname_ph")}
                  value={fName}
                  error={fName?.trim() === ""}
                  type="text"
                  errorMessage={t("staff_members_new_fname_error")}
                  inputHandler={(e) => {
                    setfName(e.target.value);
                  }}
                />

                <TextInput
                  label={t("staff_members_new_lname")}
                  showLabel={true}
                  required={true}
                  id="l-name__addpractitioner"
                  placeholder={t("staff_members_new_lname_ph")}
                  value={lName}
                  error={lName?.trim() === ""}
                  errorMessage={t("staff_members_new_lname_error")}
                  inputHandler={(e) => {
                    setlName(e.target.value);
                  }}
                />

                <TextInput
                  label={t("staff_members_new_email")}
                  showLabel={true}
                  required={true}
                  emailField={true}
                  id="email-input__addpractitioner"
                  placeholder={t("staff_members_new_email_ph")}
                  value={email}
                  error={email === "" || !validateEmail(email)}
                  errorMessage={t("staff_members_new_email_error")}
                  inputHandler={(e) => {
                    // if (
                    //     e.target.value.length < 0 &&
                    //     !validateEmail(e.target.value)
                    // ) {
                    //     setEmailError(true)
                    // } else {
                    //     setEmailError(false)
                    setEmail(e.target.value);
                    // }
                  }}
                />
                <TextInput
                  label={t("staff_members_new_phone")}
                  showLabel={true}
                  required={addressValidation}
                  id="phone__addpractitioner"
                  placeholder={t("staff_members_new_phone_ph")}
                  error={phoneError}
                  errorMessage={t("staff_members_new_phone_error")}
                  value={phone}
                  inputHandler={(e) => {
                    setPhone(e.target.value);
                  }}
                />
                <div className="d-inline-block pg-text__bold textc-primary">
                  {t("staff_members_new_optional_permissons")}
                </div>
                <OptionalPermission />
                <button
                  className="btn btn-primary text-light px-5 my-1 mx-1  w-100"
                  onClick={onDone}
                >
                  {t("update")}
                </button>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default EditStaffModal;

export const ActionPrompt = ({
  show,
  onAccept,
  onReject,
  message,
  acceptLabel = "Confirm",
  rejectLabel = "Go Back",
}) => {
  return (
    <Modal
      style={{
        background: "rgba(0, 0, 0, 0.5)",
        backdropFilter: "blur(.1)",
      }}
      centered
      show={show}
      onHide={onReject}
      bsPrefix="border-radius modal modal-2"
    >
      <div className="p-3">
        <div className="pg-text__bold">{message}</div>
        <div className="d-flex justify-content-end mt-4">
          <button className="btn bg-sky-blue px-4 mx-1" onClick={onReject}>
            {rejectLabel}
          </button>
          <button
            className="btn bgc-primary text-light px-5"
            onClick={onAccept}
          >
            {acceptLabel}
          </button>
        </div>
      </div>
    </Modal>
  );
};
