import React, { Component, useEffect, useState } from "react";
import _ from "lodash";
import { Modal } from "react-bootstrap";
import TaxUpload from "./Practice/TaxUpload";

function TaxModelVd({ showModal, onCancel, token, currentPracticeID, currentPractice }) {
  return (
    <Modal
      show={showModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={onCancel}
      contentClassName="border-radius"
      backdrop="static"
      className=""
      scrollable={true}
    >
      <Modal.Body>
        <div
          style={{
            position: "absolute",
            right: 25,
            top: 15,
            fontSize: 20,
            cursor: "pointer",
            color: "var(--pg_primary)",
          }}
          onClick={onCancel}
        >
          <div className="fas fa-times"></div>
        </div>
        <div> 
        <TaxUpload
          token={token}
          currentPracticeID={currentPracticeID}
          currentPractice={currentPractice}
        />
        </div>

      </Modal.Body>
    </Modal>
  );
}
export default TaxModelVd;
