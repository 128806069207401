import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ShippingCart = () => {  
  const [scriptId, setScriptId] = useState(null);
  const [cartCount, setCartCount] = useState(0);

  const patientId = useSelector(state => state.auth.currentUserId);
  const appToken = useSelector(state => state.auth.appToken);
  const tenantId = useSelector(state => state.auth.currentPracticeID);
  const language = useSelector(state => state.auth.language);
  const cartRef = useRef(null);
  const navigate = useNavigate();

  const handleIframe = (event) => {
    if (scriptId) {
      event.preventDefault();      
      const state = {
        path: `/script_config?app_token=${appToken}&script_id=${scriptId}&lang=${language}`,      
        browseCatalog: false,
      };
      if (
        window.location.href === `${window.location.origin}/patient/shipping_cart`
      ) {
        return (window.location.href = `${window.location.origin}/patient/shipping_cart`);
      } else {
        navigate("/patient/shipping_cart", { state });
      }
    } else {
      console.log(event);
    }
  };

  const load = async () => {
    try {
      const url = `${process.env.REACT_APP_RIALTO_BASE_URL}/rialto/api/patient_has_script?patient_id=${patientId}&profile_id=&tenant_id=${tenantId}&rialto_token=${appToken}&aasm_state=in_cart`;
      await fetch(url)
        .then((response) => response.text())
        .then((json) => {
          console.log({json})
          const obj = JSON.parse(json); 
          const script_id = obj?.script_id || obj?.draft_script_id
          const cart_count = obj?.script_item_count || 0    
          updateCart(script_id, cart_count);               
        });
    } catch (error) {
      console.error('Error fetching data:', error);
    }    
  };

  const updateCart = (script_id, count) => {    
    const cartElement = cartRef.current;    
    setCartCount(count);
    setScriptId(script_id);

    if (!script_id && cartElement) {
      cartElement.style.cursor = "pointer";      
      return;
    }
  }
  
  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    const handleMessage = (event) => {
      const origin = process.env.REACT_APP_RIALTO_WEB_HOST;

      if (event?.origin === origin && event?.data?.event === 'pg-cart-count') {
        const script_id = event?.data?.script_id || event?.data?.draft_script_id || scriptId;        
        const cart_count = event?.data?.count || 0
        updateCart(script_id, cart_count);
      } else if (event?.origin === origin && event?.data?.event === 'pg-cart-reload') {
        window.parent.location.replace('/');
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [scriptId]);

  return (
    <div className="d-flex mx-2 position-relative">
      <span className="cart-count">{cartCount}</span>
      <span className="cursor-pointer" id="cartElement" ref={cartRef} onClick={handleIframe}>
        <i className="fas fa-shopping-cart cart-icon d-flex justify-content-center align-items-center" aria-hidden="true" />
      </span>      
    </div>
  );
};

export default ShippingCart;
