import React from 'react'
import { Link } from 'react-router-dom'

function UKCookiesNotice() {
    return (
        <div className='container-xxl my-5 px-md-1 px-0'>
            <div>
                <div className="pgs-heading textc-primary my-5">
                    This cookie notice applies to the PureGenomics® website and application (“Sites”).
                </div>
                <div>
                    <div className='my-5'>
                        <div className="pgs-text textc-primary fw-bold">
                            1. What are cookies?
                        </div>
                        <div className="ps-4 pgs-text mt-2">
                            Cookies are text files that are placed on your computer by websites that you visit. Cookies are then sent back to the originating website on each subsequent visit, or to another website that recognises that cookie. They are used in order to make websites work, or work more efficiently, as well as to provide information to the owners of the website.
                            <div className='mt-3'>
                                We use the term "cookies" throughout this notice to also cover all similar technologies, such as web beacons SDK, log files, pixel tags.
                            </div>
                        </div>
                    </div>
                    <div className='my-5'>
                        <div className="pgs-text textc-primary fw-bold">
                            2. What types of cookies are used on PureGenomics® Sites?
                        </div>
                        <div className="ps-4 pgs-text mt-2">
                            We use the following types of cookies on PureGenomics® Sites:
                            <div className='my-3'>
                                <table className='pg-table cookies-table'>
                                    <thead>
                                        <tr>
                                            <th>Cookie categories</th>
                                            <th>Lifespan</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className='fw-bold'>Site functionality cookies</div>
                                                <div className="ps-4">
                                                    These cookies allow you to navigate the site and use our features, such as registration, logging in and product favourites. If you disable these cookies certain parts of the website will not function for you, for example, adding items to your basket, proceeding to checkout.
                                                </div>
                                            </td>
                                            <td className='fw-bold'>As long as necessary</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className='my-5'>
                        <div className="pgs-text textc-primary fw-bold">
                            3. Manage your cookie preferences
                        </div>
                        <div className="ps-4 pgs-text mt-2">
                            As set out above, we only use necessary cookies that enable core
                            site functionality and analytic cookies that allow us to improve the functionality and your
                            experience. You may disable these by changing your browser settings, but this may affect
                            how the web app functions.  You should ensure that your device settings
                            reflect your cookies preferences. You can also set your browser to warn you before
                            accepting cookies or you set it to refuse them.
                            <br />
                            <br />
                            Additionally, where available, you can opt-out of cookies by visiting the following sites:
                            <a href="http://www.aboutads.info/choices/#completed" target="_blank"> http://www.aboutads.info/choices/#completed</a> or
                            <a href="http://www.youronlinechoices.eu/" target="_blank"> http://www.youronlinechoices.eu/</a>
                        </div>
                    </div>
                    <div className='my-5'>
                        <div className="pgs-text textc-primary fw-bold">
                            4. Notice Update
                        </div>
                        <div className="ps-4 pgs-text mt-2">
                            We reserve the right to make any changes and corrections to this Notice. Please refer to this page from time to time to review these and new additional information. We will place updates on PureGenomics®  Sites and, where appropriate, we will notify of any changes by email.
                        </div>
                    </div>
                    <div className='my-5'>
                        <div className="pgs-text textc-primary fw-bold">
                            4. Privacy Policy
                        </div>
                        <div className="ps-4 pgs-text mt-2">
                            For further information about how your data is used (including controller details), please see our
                            <Link to="/privacy"> Privacy Policy</Link>.
                        </div>
                    </div>
                </div>
                <div className='pgs-heading__sub'>
                    This Cookie Notice was last updated April 27, 2022.
                </div>
            </div>
        </div>
    )
}

export default UKCookiesNotice