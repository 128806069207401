import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash';
import { getReportViewType, changeReportViewType } from '../../../store/actions/patientReport'

const ViewTypeChanger = ({
  viewType,
  userType,
  collapsible,
  tableFilter,
  setViewType,
  setTableFilter,
  setCollapsible }) => {

  const dispatch = useDispatch();
  // const userType = useSelector(state => state.auth.userType);
  const token = useSelector(state => state.auth.token);
  const currentPracticeID = useSelector(state => state.auth.currentPracticeID);
  const currentPractice = useSelector(state => state.auth.currentPractice);
  const currentUserId = useSelector(state => state.auth.currentUserId);

  useEffect(() => {
    if (userType === "User")
      dispatch(getReportViewType(token,
        currentPracticeID,
        currentPractice, currentUserId)).then(response => {
          if (!response.error) {
            setViewType(response.payload.report_layout);
            setCollapsible(response.payload.report_expand)
            setTableFilter(_.startCase(response.payload?.report_filter))
          }
        })
  }, [])

  const changeViewType = (type) => {
    dispatch(changeReportViewType(token,
      currentPracticeID,
      currentPractice,
      currentUserId,
      { report_layout: type, report_expand: collapsible, report_filter: tableFilter }
    )).then(_response => {
      // if (!_response.error)
      // console.log("View type changes updated", _response)
    })
    setViewType(type)
  }

  return (
    <>
      <span
        className={viewType === "grid" ? userType === "Patient" ? 'text-color__p' : "textc-primary" : "text-secondary"}
      >
        <i className="fas fa-th mx-2" onClick={() => changeViewType("grid")}></i>
      </span>
      <span
        className={viewType === "list" ? userType === "Patient" ? 'text-color__p' : "textc-primary" : "text-secondary"}
      >
        <i
          className="fas fa-list-ul mx-2"
          onClick={() => changeViewType("list")}
        ></i>
      </span>
      {userType === "User" && <span
        className={viewType === "table" ? userType === "Patient" ? 'text-color__p' : "textc-primary" : "text-secondary"}
      >
        <i
          className="fas fa-table mx-2"
          onClick={() => changeViewType("table")}
        ></i>
      </span>}
    </>
  );
};

export default ViewTypeChanger;