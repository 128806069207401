import React, { Component, useEffect, useState, useRef } from "react";
import Scroll from "react-scroll";
import ValidationError from "./QuestionTypes/ValidationError";
import TextQuestion from "./QuestionTypes/TextQuestion";
import NumberQuestion from "./QuestionTypes/NumberQuestion";
import DateQuestion from "./QuestionTypes/DateQuestion";
import RadioQuestion from "./QuestionTypes/RadioQuestion";
import CheckboxRadioQuestion from "./QuestionTypes/CheckboxRadioQuestion";
import "../../styles/questionnaire.css";
import "../../styles/chatTyper.css";
import { PgStepper } from "../../components/PgStepper";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getQuestion,
  submitAnswer,
  getQuestions,
  getWelcomeQuestion,
  patientAnswers,
} from '../../store/actions/questions'
import WelcomeQuestion from './QuestionTypes/WelcomeQuestion'
import _ from 'lodash'
import ChatTyper from './ChatTyper'
import toast from 'react-hot-toast';
import { useTranslation } from "react-i18next";

var Link = Scroll.Link
var DirectLink = Scroll.DirectLink
var Element = Scroll.Element
var Events = Scroll.Events
var scroll = Scroll.animateScroll
var scrollSpy = Scroll.scrollSpy
var scroller = Scroll.scroller

function Questionnaire() {

  // redux state variable
  const currentPractice = useSelector(state => state.auth.currentPractice);
  const currentPracticeID = useSelector(state => state.auth.currentPracticeID);
  const currentUserId = useSelector(state => state.auth.currentUserId);
  const token = useSelector(state => state.auth.token);
  const geneticBy = useSelector(state => state.welcomeScreen.genetic_upload_by);
  const isInvited = useSelector(state => state.welcomeScreen.invite_questionnaire);

  // redux actions
  const dispatch = useDispatch();
  const getWelcomeQuestionAction = (patient_id) => dispatch(getWelcomeQuestion(patient_id));
  const getQuestionAction = (question_id) => dispatch(getQuestion(question_id));
  const submitAnswerAction = (data) => dispatch(submitAnswer(data));
  const getQuestionsAction = () => dispatch(getQuestions());
  const patientAnswersAction = (token, currentPracticeID, currentPractice, patientId) => dispatch(patientAnswers(token, currentPracticeID, currentPractice, patientId));

  //local variables
  const [scrollItems, setScrollItems] = useState([])
  const [conversationItems, setConversationItems] = useState([])
  const [showQuestions, setShowQuestions] = useState(true)
  const [patientAnswer, setPatientAnswer] = useState('')
  const [currentQuestionId, setCurrentQuestionId] = useState(1)
  const [showProgress, setShowProgress] = useState(false)
  const [editQuestionId, setEditQuestionId] = useState('')
  const [showSpinner, setShowSpinner] = useState(false)
  const [showTyper, setShowTyper] = useState(false)
  const language = useSelector((state) => state.auth.language)
  const navigate = useNavigate();

  const onChangeAnswer = (questionId) => {
    setEditQuestionId(questionId)
    setShowQuestions(true)
    setCurrentQuestionId(questionId)
  }

  const startAnswering = () => {
    setShowQuestions(true)
  }

  const { t, i18n } = useTranslation();

  const addAnswer = (arrayIndex, question, answer) => {
    question.answer = answer;
    let newArr = [...conversationItems];
    newArr[arrayIndex] = question;
    setConversationItems(newArr);
    if (
      (question.id === "42" && language === "en" && question.answer > 96) ||
      (question.id === "42" && (language === "ca" || language === "uk") && question.answer > 245) ||
      (question.id === "43" && language === "en" && question.answer > 1000) ||
      (question.id === "43" && (language === "ca" || language === "uk") && question.answer > 450)
    ) {
      var validationError = {
        question_id: 999,
        question_type: "validation-error",
        question: t('patient_q_msg1')
      };
      const { answer, ...newQuestion } = question;
      setConversationItems([...newArr, validationError, newQuestion])
      setTimeout(() => {
        var questionElements = document.querySelectorAll(".question-class:last-child")
        var nextElement = questionElements[questionElements.length - 1]
        if (nextElement != null) {
          nextElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
        }
      }, 1000);
    }
    else {
      if (
        question.question_type === "checkbox-radio" ||
        question.question_type === "radio"
      ) {
        var data = {
          patient_id: currentUserId.toString(),
          question_id: currentQuestionId,
          text: "",
          selections: answer,
          edit: false,
        };
      } else {
        var data = {
          patient_id: currentUserId.toString(),
          question_id: currentQuestionId,
          text: answer,
          selections: [],
          edit: false,
        };
      }
      submitAnswerAction(data).then((res) => {
        if (res.error) {
          toast.error(t('patient_q_msg2'),
            {
              style: {
                fontSize: '16px',
              },
              duration: 15000
            })
          navigate("/patient/dashboard");
        }
        else {
          if (parseInt(res.payload.next_question_id) < 2) {
            setConversationItems(conversationItems);
          } else {
            const newScrollItems = _.filter(scrollItems, function (o) {
              return o.id > 1.2;
            });
            setScrollItems(newScrollItems);
            setShowProgress(true);
          }
          if (res.payload.next_question_id === null) {
            navigate("/patient/review_answer", { state: { viewOnly: false } });
          } else {
            setCurrentQuestionId(res.payload.next_question_id);

            getQuestionAction(res.payload.next_question_id).then((result) => {
              if (result.error) {
                toast.error(t('patient_q_msg3'),
                  {
                    style: {
                      fontSize: '16px',
                    },
                    duration: 15000
                  })
                navigate("/patient/dashboard");
              }
              else {
                if (res.payload.next_question_id < 3) {
                  setConversationItems([result.payload]);
                } else if (res.payload.next_question_id == 2) {
                  setShowProgress(true);
                } else {
                  setShowTyper(true);
                  setTimeout(() => {
                    setShowTyper(false);
                    setConversationItems([...conversationItems, result.payload]);
                  }, 1200);
                }
              }
            });

            if (res.payload.next_question_id > 1.2) {
              setTimeout(() => {
                var nextElement = document.getElementById(
                  `${res.payload.next_question_id}Container`
                );
                if (nextElement != null) {
                  nextElement.scrollIntoView({ behavior: "smooth", block: "center" });
                }
              }, 2200);
            }
          }
        }
      });
    }

  };

  useEffect(() => {
    Events.scrollEvent.register('begin', function () {
      console.log('begin', arguments)
    })
    Events.scrollEvent.register('end', function () {
      console.log('end', arguments)
    })
    scrollSpy.update()
    return () => {
      Events.scrollEvent.remove('begin')
      Events.scrollEvent.remove('end')
    }
  }, [])

  useEffect(() => {
    getWelcomeQuestionAction(currentUserId).then((res) => {
      if (res.error) {
        toast.error(t('patient_q_msg4'),
          {
            style: {
              fontSize: '16px',
            },
            duration: 15000
          })
        navigate("/patient/dashboard");
      }
      else {
        if (
          res.payload?.session !== '{}' &&
          JSON.parse(res.payload?.session).questionnaire?.length !== 0
        ) {
          var resumeArrayList = resumeAnswerArray(
            JSON.parse(res.payload.session).questionnaire,
          )
          const resumeArray = _.filter(resumeArrayList, function (o) {
            return parseInt(o.id) > 1.2
          })
          var lastAnsweredQuestion = resumeArrayList[resumeArrayList.length - 1]
          if (lastAnsweredQuestion.id !== "1") {
            setConversationItems(resumeArray)
            setShowProgress(true)
          }
          else {
            setShowTyper(true)
          }
          if (lastAnsweredQuestion === undefined) {
            toast.error(t('patient_q_msg4'),
              {
                style: {
                  fontSize: '16px',
                },
                duration: 15000
              })
            navigate("/patient/dashboard");
          }
          else {
            if (
              lastAnsweredQuestion.question_type === 'checkbox-radio' ||
              lastAnsweredQuestion.question_type === 'radio'
            ) {
              var data = {
                patient_id: currentUserId.toString(),
                question_id: lastAnsweredQuestion.id,
                text: '',
                selections: lastAnsweredQuestion.answer,
                edit: false,
              }
            } else {
              var data = {
                patient_id: currentUserId.toString(),
                question_id: lastAnsweredQuestion.id,
                text: lastAnsweredQuestion.answer.toString(),
                selections: [],
                edit: false,
              }
            }
            submitAnswerAction(data).then((result) => {
              setShowProgress(true)
              if (result.error) {
                toast.error(t('patient_q_msg4'),
                  {
                    style: {
                      fontSize: '16px',
                    },
                    duration: 15000
                  })
                navigate("/patient/dashboard");
              }
              else {
                if (result.payload.next_question_id === null) {
                  navigate('/patient/review_answer', { state: { viewOnly: false } })
                } else {
                  var currentElement = document.getElementById(
                    `${lastAnsweredQuestion.id}Container`,
                  )
                  if (currentElement != null) {
                    currentElement.scrollIntoView({
                      behavior: 'smooth',
                      block: 'nearest',
                    })
                  }
                  setCurrentQuestionId(result.payload.next_question_id)
                  getQuestionAction(result.payload.next_question_id).then(
                    (result_question) => {
                      if (result_question.error) {
                        toast.error(t('patient_q_msg4'),
                          {
                            style: {
                              fontSize: '16px',
                            },
                            duration: 15000
                          })
                        navigate("/patient/dashboard");
                      }
                      else {
                        setShowTyper(true)
                        setTimeout(() => {
                          setShowTyper(false)
                          setConversationItems([
                            ...resumeArray,
                            result_question.payload,
                          ])
                          var nextElement = document.getElementById(
                            `${result.payload.next_question_id}Container`,
                          )
                          if (nextElement != null) {
                            nextElement.scrollIntoView({
                              behavior: 'smooth',
                              block: 'nearest',
                            })
                          }
                        }, 2500)
                      }
                    },
                  )
                }
              }
            })
          }
        } else {
          setConversationItems([res.payload])
        }
      }
    })
  }, [])

  const resumeAnswerArray = (answers) => {
    console.log(answers)
    var resumeAnswer = []
    answers.forEach(function (answerItem) {
      resumeAnswer.push({
        id: answerItem.id,
        question_type: answerItem.question_type,
        question: answerItem.question_text,
        answer: answerItem.answer_text,
        type: answerItem.question_type,
      })
    })
    return resumeAnswer
  }

  const isStepperVisible = () => {
    return geneticBy && isInvited
  }

  useEffect(() => {
    getQuestionsAction().then((res) => {
      const newScrollItems = _.filter(res.payload, function (o) {
        return o.id > 1.2;
      });
      setScrollItems(newScrollItems)
    })
  }, [])

  return (
    <div>
      <div className="container-fluid">
        <div className="container-xxll pt-1 pt-lg-5 px-lg-5 px-2">
          <div className="row mt-4 justify-content-center justify-content-lg-between">
            <div className="col-12 col-lg-6 order-lg-0 order-1">
              <hr className="my-5 d-block d-lg-none" />
              <div className="pg-title"> {t('questionnaire')}</div>
            </div>
            <div className="col-12 col-lg-4 order-md-0">
              <div className="w-100 float-end pg-text textc-primary">
                {isStepperVisible() && <PgStepper current={1} />}
              </div>
            </div>
          </div>
        </div>
      </div>

      {showQuestions && showProgress && (
        <div className="scroll-link-wrapper">
          {scrollItems.map((item, index) => (
            <div key={index} className="scroll-links">
              <Link
                activeclassName="active"
                to={item.id + 'Container'}
                spy={true}
                smooth={true}
                duration={1500}
                offset={-70}
                // duration={function (scrollDistanceInPx) { return duration; }}
                delay={1000}
                containerId="containerElement"
                style={{ display: 'inline-block' }}
                className={
                  `scroll-link ` +
                  (item.id !== currentQuestionId ? item.id : ' activeScroll ')
                }
              ></Link>
              <br />
            </div>
          ))}
        </div>
      )}

      <Element
        name="test7"
        className="element"
        id="containerElement"
        style={{
          height: '90vh',
          overflow: 'scroll',
          paddingTop: '55px',
        }}
      >
        <div>
          {showQuestions && (
            <div className="conversation-wrapper">
              {conversationItems.map((item, index) => (
                <div key={index} id={item.id + 'Container'}>
                  <Element
                    name={item.id + 'Container'}
                    //style={{ marginBottom: "100vh" }}
                    className="question-bubble  question-class"
                  >
                    {item.question_type === 'nan' && (
                      <WelcomeQuestion
                        question={item}
                        addtextAnswer={addAnswer}
                        index={index}
                        isEdit={false}
                        editAnswerText={''}
                        showSpinner={showSpinner}
                      />
                    )}
                    {item.question_type === "validation-error" && (
                      <ValidationError
                        question={item}
                        index={index}
                      />
                    )}
                    {item.question_type === "date" && (
                      <DateQuestion
                        question={item}
                        addtextAnswer={addAnswer}
                        index={index}
                        isEdit={false}
                        editAnswerText={''}
                        showSpinner={showSpinner}
                      />
                    )}
                    {item.question_type === 'number' && (
                      <NumberQuestion
                        question={item}
                        addtextAnswer={addAnswer}
                        index={index}
                        isEdit={false}
                        editAnswerText={''}
                        showSpinner={showSpinner}
                      />
                    )}
                    {item.question_type === 'text' && (
                      <TextQuestion
                        question={item}
                        addtextAnswer={addAnswer}
                        index={index}
                        isEdit={false}
                        editAnswerText={''}
                        showSpinner={showSpinner}
                      />
                    )}
                    {item.question_type === 'radio' && (
                      <RadioQuestion
                        question={item}
                        addtextAnswer={addAnswer}
                        index={index}
                        isEdit={false}
                        editAnswerText={''}
                        showSpinner={showSpinner}
                      />
                    )}
                    {item.question_type === 'checkbox-radio' && (
                      <CheckboxRadioQuestion
                        question={item}
                        addtextAnswer={addAnswer}
                        index={index}
                        isEdit={false}
                        editAnswerText={''}
                        showSpinner={showSpinner}
                      />
                    )}
                  </Element>
                </div>
              ))}
              {showTyper && <ChatTyper />}
            </div>
          )}
        </div>
      </Element>
    </div>
  )
}
export default Questionnaire;
