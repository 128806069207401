import { useState } from "react";
import PgAccordion from "../PgAccordion";
import { useNavigate } from "react-router-dom";
import ExternalSitePrompt from "../ExternalSitePrompt";

function CAPatientFAQ() {
  const navigate = useNavigate();
  const [showExternalSiteModal, setShowExternalSiteModal] = useState(false);
  const [externalSiteURL, setExternalSiteURL] = useState("");

  return (
    <div className="py-5">
      {showExternalSiteModal && (
        <ExternalSitePrompt
          showModal={showExternalSiteModal}
          externalSiteLink={externalSiteURL}
          onCancel={() => setShowExternalSiteModal(false)}
        />
      )}
      <div className="container-xxl">
        <div className="pgs-title my-5 textc-primary__p">
          PureInsight<sup>&trade;</sup> FAQs
        </div>

        {/* section 1*/}
        <div className="pgs-heading mt-5 mb-3 textc-primary__p">
          PureInsight<sup>&trade;</sup> Usage and Registration
        </div>
        <PgAccordion
          userType="Patient"
          question="What is PureInsight<sup>&trade;</sup>?"
        >
          <div>
            PureInsight<sup>&trade;</sup> is our new, complementary platform that helps your healthcare
            provider create a deeper, personalized plan of care unique to your health needs.
            Unlike PureGenomics<sup>&reg;</sup>, our PureInsight<sup>&trade;</sup> platform allows your
            healthcare provider utilize patient questionnaires and validated tools to provide a plan of care.
            With PureInsight<sup>&trade;</sup>, genetic data and lab test data are no longer required but remain optional
            for you to upload.
          </div>
        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="Why is PureGenomics<sup>&reg;</sup> changing to PureInsight<sup>&trade;</sup>?"
        >
          <div>
            We want our services to align with your needs and our go-forward strategy to support
            and empower your healthcare provider in designing evidence- based, personalized and a
            scientifically supported plan of care focused on diet, exercise, lifestyle, and supplement
            recommendations that can be shared with you.
          </div>
        </PgAccordion>

        <PgAccordion
          userType="Patient"
          question="Will the rebrand introduce a new logo?"
        >
          Yes, the new logo is shown throughout the website – PureInsight<sup>&trade;</sup> Powered by PureGenomics<sup>&reg;</sup>
        </PgAccordion>

        <PgAccordion
          userType="Patient"
          question="What is changing for me?"
        >
          <div>
            <p className="">
              The look and feel of our website will change slightly. Individual patient pages will remain the same with updated descriptions to help emphasize what this platform delivers and the tools available when you sign in to your PureInsight<sup>&trade;</sup> account. The platform also introduces patient questionnaires and validated tools to provide a plan of care. With PureInsight<sup>&trade;</sup>, genetic data and lab test data is no longer required but remain optional for you to upload.
            </p>
            <p className="">
              If you don’t have access, you will need to speak with your healthcare provider, and they will send you an email invitation with a link to register for an account.  Rest assured that you will continue to receive the exceptional services and features that you have become familiar with. This transition will be smooth and effortless, allowing your healthcare provider to utilize our services with even greater efficiency and provide you with a wholistic plan of care.
            </p>
          </div>
        </PgAccordion>

        <PgAccordion
          userType="Patient"
          question="I have a PureGenomics<sup>&reg;</sup> account. Do I need to create a new one to access PureInsight<sup>&trade;</sup>?"
        >
          <div>
            No, your credentials from your PureGenomics<sup>&reg;</sup> can be used to log in to access the PureInsight<sup>&trade;</sup> platform.
          </div>
        </PgAccordion>

        <PgAccordion
          userType="Patient"
          question="I did not have a PureGenomics<sup>&reg;</sup> account. How do I get access to PureInsight<sup>&trade;</sup>?"
          answer="If you don’t have access, you will need to speak with your healthcare provider, and they will send you an email invitation with a link to register for an account."
        />

        <PgAccordion
          userType="Patient"
          question="Is the https://www.puregenomics.ca/  web address staying the same?"
        >
          <div>
            No, the new web address is <a href="https://pureinsight.pureencapsulationspro.com">pureinsight.pureencapsulationspro.com</a>
          </div>
        </PgAccordion>

        <PgAccordion
          userType="Patient"
          question="Is there a specific transition plan I need to be aware of?"
          answer="No, we will gradually transition to the new brand. Access to your patient account is not impacted and you can use new features and tools 24/7.  "
        />

        <PgAccordion
          userType="Patient"
          question="Where can I send any follow-up questions I might have?"
        >
          <div>
            For any additional questions about the rebranding of PureGenomics<sup>&reg;</sup> to PureInsight<sup>&trade;</sup>,
            please click on <a href="/contact-us">Contact Us</a> and submit your inquiry.
          </div>
        </PgAccordion>

        <PgAccordion
          userType="Patient"
          question="What is the cost to use PureInsight<sup>&trade;</sup>?"
        >
          <div>
            PureInsight™ is complimentary to use! We believe in supporting better health
            for all, that's why you can benefit from all that PureInsight™ has to offer at no cost.
          </div>
        </PgAccordion>

        <PgAccordion
          userType="Patient"
          question="Why do you offer this complimentary?"
          answer="Our company believes in the advancement of Functional Medicine by providing technology, clinical protocols, and evidence-based products to best support healthcare providers in their personalized care of their patients."
        />


        <PgAccordion
          userType="Patient"
          question="I would like to use your service to analyze my raw data. Is this service still available and how do I sign up?"
        >
          <div>
            <p>
              Patients must be invited by their healthcare provider to create an account. Please reach out to your healthcare provider regarding setting up a free account with us.
            </p>
            <p>
              After your healthcare provider adds you as a patient, you will receive an email from PureInsight<sup>&trade;</sup> on behalf of your provider, to complete your registration. When you log in for the first time, you will be prompted to set up a password and consent to certain key policies and terms of service. You will then have access to your personalized dashboard which will guide you through the tool.
            </p>
          </div>
        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="Which are the supported browsers and devices/platforms? "
        >
          <div>
            The PureInsight<sup>&trade;</sup> application is compatible with all devices and browsers.
          </div>
        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="When my healthcare provider added me in as a patient, they received an error message asking them to try another email address. What should I do? "
          answer={"That error message means that the email address is already in use in our system. The email address is a unique identifier and can only be used one time. Since that email has been used previously, you will need to provide another email address to establish an account. "}
        />
        <PgAccordion
          userType="Patient"
          question="I would like to set my family members up with PureInsight<sup>&trade;</sup> accounts. How do I do this?"
          answer="Family members will need to be invited by a participating healthcare provider to set up their own accounts. Each patient must use a different email address to create a PureInsight™ account. Patients must be must be eighteen (18) years of age or older."
        />


        {/* section 2 */}
        <div className="pgs-heading mt-5 mb-3 textc-primary__p">
          Personalization and Questionnaires
        </div>
        <PgAccordion
          userType="Patient"
          question="Why does my healthcare provider want me to complete a Multi- Symptom Questionnaire (MSQ)?"
          answer="The MSQ is a short, simple quiz, designed to provide your healthcare provider with a glimpse of your overall health and wellness status. This information can help inform your healthcare provider about your personalized lifestyle and supplement considerations."
        />

        <PgAccordion
          userType="Patient"
          question="How do I complete the Multi-Symptom Questionnaire?"
        >
          <div>
            When you log in to your PureInsight<sup>&trade;</sup> account, you will see the “MULTI-SYMPTOM QUESTIONNAIRE” in your Dashboard.
            Just click “START QUESTIONNAIRE” .
          </div>
        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="What if I don’t yet have my genetic data? Can I go ahead and complete the MSQ?"
          answer="Yes, you can complete the questionnaire MSQ prior to receiving your genetic raw data file. While uploading genetic raw data remains an option, it is no longer required. Once your healthcare provider creates your account, you can log in and complete the questionnaire. When you click “Get Started” it takes you to a page to upload your raw data. Since you are not yet ready for this step, click dashboard, scroll down past data upload, and click “Start Questionnaire.”"
        />
        <PgAccordion
          userType="Patient"
          question="Will my answers be saved if I need to continue answering the Multi-Symptom Questionnaire at a later time?"
          answer="Yes. It is saved automatically. If you decide to return to your dashboard or exit completely, when you log back in, under the Multi-Symptom Questionnaire section, click on the Continue Questionnaire button, and you can pick up where you left off."
        />
        <PgAccordion
          userType="Patient"
          question="Can I edit my responses?"
          answer="You will have the ability to edit your responses before submitting your final answers. You will only have one opportunity to make any necessary changes. If you need to edit any answers beyond this or update your responses in the future, you will need to complete a new questionnaire. You can ask your healthcare provider to send you a new questionnaire."
        />
        <PgAccordion
          userType="Patient"
          question="Why does my healthcare provider require additional health questionnaires? Generalized Anxiety Disorder Questionnaire (GAD-7) and Patient Health Questionnaire (PHQ-9)?"
          answer="Based on how the Multi-Symptom Questionnaire is answered, these additional questionnaires may be recommended by your healthcare provider. These additional assessment instruments can help your healthcare provider gain a deeper understanding of your health and wellness status."
        />
        <PgAccordion
          userType="Patient"
          question="How does my MSQ score affect me?"
          answer="The score is a measure of the overall frequency of your health and wellness status  and will help your healthcare provider track your progress over time."
        />

        {/* section 3 */}
        <div className="pgs-heading mt-5 mb-3  textc-primary__p">
          Genetic Testing, Labs and Upload
        </div>
        <PgAccordion
          userType="Patient"
          question="What genetic testing labs are compatible with PureInsight<sup>&trade;</sup>?"
        >
          <div>
            PureInsight<sup>&trade;</sup> only interprets genetic data from 23andMe<sup>&reg;</sup> and Ancestry<sup>&reg;</sup>.  While uploading genetic raw data remains an option, it is no longer required
          </div>
        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="I noticed that 23andMe<sup>&reg;</sup> and Ancestry<sup>&reg;</sup> offer different DNA kits. Is there a particular DNA kit that I should buy if I am interested in using your platform?"
        >
          <div>
            Any DNA kit sold by either 23andMe<sup>&reg;</sup> or Ancestry<sup>&reg;</sup> is compatible with PureInsight<sup>&trade;</sup>.
          </div>
        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="Can I order 23andMe<sup>&reg;</sup> or Ancestry<sup>&reg;</sup> test kits through PureInsight<sup>&trade;</sup>?"
        >
          <div>
            No. You must order the kits directly through 23andMe.ca, or Ancestry.ca or other authorized retail stores. Please note that Pure Encapsulations<sup>&reg;</sup> is not affiliated with or endorsed by 23andMe<sup>&reg;</sup> or Ancestry<sup>&reg;</sup>.
          </div>
        </PgAccordion>

        <PgAccordion
          userType="Patient"
          question="How do I download my test data from 23andMe<sup>&reg;</sup> or Ancestry<sup>&reg;</sup>, and how do I upload this data to PureInsight<sup>&trade;</sup>?"
        >
          <div className="mt-3">
            <b>Through 23andMe<sup>&reg;</sup>:  </b>
            <p>You can access 23andMe<sup>&reg;</sup> through your PureInsight<sup>&trade;</sup>account by logging in and clicking on the 23andMe<sup>&reg;</sup> link.  Please note that when you click on the 23andMe<sup>&reg;</sup>link, you will be leaving the PureInsight<sup>&trade;</sup> website and will be transferred to the 23andMe<sup>&reg;</sup> website and be subject to 23andMe<sup>&reg;</sup> website’s privacy policy and terms of service.  PureInsight<sup>&trade;</sup> is not responsible for the content and services provided by 23andMe<sup>&reg;</sup>. </p>
            <p>Once you log into 23andMe<sup>&reg;</sup>, directions are provided on this page for you to download your genetic raw data file.  </p>
            <p>At the bottom of this page, you need to check a box acknowledging you are downloading sensitive genetic data from a secure server, and then click “Submit Request.”  </p>
            <p>23andMe<sup>&reg;</sup> requires a 2-step authentication process. You will receive an email from 23andMe<sup>&reg;</sup> when your raw data download is ready. Click on the email’s call to action at the bottom of the email. This will take you back to your 23andMe<sup>&reg;</sup> account where you can download your raw data .zip file and save it to your computer.  </p>
            <p>Lastly, go back into your PureInsight<sup>&trade;</sup> patient dashboard and click “Upload Your Data,” select your file from the saved location, and click “Start Upload.”  </p>
            <p>Once your DNA data is uploaded, your health care provider will review the results and release your PureInsight<sup>&trade;</sup>patient report with recommendations based on the information that you have provided. Once the report is released by your health care provider, you will be able to immediately view it and follow-up with your health care provider as necessary. schedule an appointment to review your PureInsight(TM) patient report with your healthcare provider. To read more directions from 23andMe<sup>&reg;</sup> about downloading your data click <a
              target="_blank"
              className="cursor-pointer"
              onClick={() => {
                setExternalSiteURL("https://customercare.23andme.com/hc/en-us/articles/212196868-Accessing-Your-Raw-Genetic-Data");
                setShowExternalSiteModal(true)
              }}
            >here</a>. </p>

            <b className="mt-3">Through Ancestry<sup>&reg;</sup>:  </b>
            <p>You can access Ancestry<sup>&reg;</sup> through your PureInsight <sup>&trade;</sup>account by logging in and clicking on the Ancestry<sup>&reg;</sup> link.  Please note that when you click on the Ancestry<sup>&reg;</sup> link, you will be leaving the PureInsight<sup>&trade;</sup>website and will be transferred to the Ancestry<sup>&reg;</sup> website and be subject to Ancestry<sup>&reg;</sup> website’s privacy policy and terms of service.  PureInsight<sup>&trade;</sup> is not responsible for the content and services provided by Ancestry<sup>&reg;</sup>. </p>
            <p>Once logged in to your PureInsight<sup>&trade;</sup> account, you will need to click the Ancestry<sup>&reg;</sup>.ca link to log into your account on Ancestry.ca. Once you log in, directions are provided for you to download your genetic raw data file.</p>
            <p>Ancestry.ca requires a 2-step authentication process. You will receive an email from Ancestry.ca when your raw data download is ready. Click on the email’s call to action Confirm Data Download and save the .zip file to a location on your computer.</p>
            <p>Lastly, go back into your PureInsight<sup>&trade;</sup> patient dashboard and click “Upload Your Data,” select your file from the saved location, and click “Start Upload.”</p>
            <p>Once your DNA data is uploaded, your health care provider will review the results and release your PureInsight<sup>&trade;</sup> patient report and their recommendations based on the information that you have provided. Once the report is released by your health care provider, you will be able to immediately view it and follow-up with your health care provider as necessary. For more download directions from Ancestry.ca click {" "}
              <a
                onClick={() => {
                  setExternalSiteURL("https://support.ancestry.com/s/article/Downloading-AncestryDNA-Raw-Data");
                  setShowExternalSiteModal(true)
                }}
                className="cursor-pointer"
                target="_blank">here
              </a>.
            </p>
          </div>
        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="I received an error message when I tried to upload my genomic data. How do I fix this?"
        >
          <div>
            <p>There are three reasons an error may occur while uploading your genomic data.  </p>
            <ul>
              <li>The data is from a non-compatible testing company. PureInsight<sup>&trade;</sup> is only compatible with 23andMe<sup>&reg;</sup> or Ancestry<sup>&reg;</sup> test results.</li>
              <li>You are attempting to upload results in a format other than a text or a zip file (like a PDF). Please upload your genomic report in a .txt or .zip file format. This is the common download format from 23andMe<sup>&reg;</sup> or Ancestry<sup>&reg;</sup>. </li>
              <li>Your data is unreadable. Data may become corrupt and is unintelligible by our system. Please re-download the file from the testing website and try again. When the pop-up window appears where it asks you if you want to Open or Save your data, click Save as opening the file can lead to corruption.</li>
            </ul>
          </div>
        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="Do my genetic results from other genetic testing labs work with PureInsight<sup>&trade;</sup>?"
        >
          <div>
            PureInsight<sup>&trade;</sup> is only compatible with 23andMe
            <sup>&reg;</sup> and Ancestry<sup>&reg;</sup>.
          </div>
        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="How long after I complete the 23andMe<sup>&reg;</sup> or Ancestry<sup>&reg;</sup> genetic test will I get my results?"
          answer="Both companies state that a period of 6-8 weeks can be expected. When the results are ready, you will be able to log in and download your genetic raw data file"
        />
        <PgAccordion
          userType="Patient"
          question="Can my provider upload my raw data file for me?"
          answer="Yes, your provider can upload your raw data file for you. You will need to email them the data file that you received from your testing company. This file will be in the form of a zip or text file, PDFs are not compatible with our system."
        />
        <PgAccordion
          userType="Patient"
          question="My healthcare provider said we can enter my blood lab results into PureInsight<sup>&trade;</sup>? What information is this providing?"
        >
          <div>
            <p>Blood lab data helps your healthcare provider learn more about your unique health and wellness needs. Once your healthcare provider receives your lab work, they will enter the results into PureInsight<sup>&trade;</sup>. The lab upload capability easily displays nutrient status. The nutrient status will help show whether a genetic variant is being expressed and proposes more precise supplement and dosing considerations. </p>
            <p>A healthcare provider cannot diagnose or prescribe medication using the PureInsight<sup>&trade;</sup> services including the nutrigenomics report. The nutrigenomics report provides insight where you may need additional nutritional support. This tells the provider what labs they can order to investigate further.</p>
            <p>PureInsight<sup>&trade;</sup> is a general wellness tool intended to help maintain or encourage a healthy lifestyle.  It is not medical advice nor intended to be used to diagnose, treat, cure or prevent any disease or condition.</p>
          </div>
        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="Do I upload my lab results or does my provider? "
          answer="Your healthcare provider uploads the lab results and will review them with you at your next appointment.  "
        />
        <PgAccordion
          userType="Patient"
          question="I can only view my raw genetic data from 23andMe<sup>&reg;</sup> but not download it, what should I do?"
        >
          <div>
            Unfortunately, we do not have control over the 23andMme<sup>&reg;</sup> system.
            Nevertheless, we maintain ongoing communication with them, and at present,
            they do not have a specific timeframe for resolving the issue.
            We suggest you reach out to 23andMe<sup>&reg;</sup> for information on alternative
            methods to obtain your genetic file so you can upload it in PureInsight<sup>&trade;</sup>
            and get your patient report. Alternatively, our system is also compatible with
            genetic data from Ancestry<sup>&reg;</sup>.
          </div>
        </PgAccordion>

        {/* section 4 */}
        <div className="pgs-heading mt-5 mb-3 textc-primary__p">
          Report
        </div>
        <PgAccordion
          userType="Patient"
          question="How long does it take to have the genetic data interpreted by PureInsight<sup>&trade;</sup>?"
        >
          <div>
            Once your data is uploaded, your provider will receive information about your genetic data
            to review. Once the healthcare provider has reviewed according to their clinical discretion,
            they will release the report and their recommendations for your viewing.
            If you haven't already, please schedule a consultation with your healthcare provider
            to review your PureInsight<sup>&trade;</sup> patient report.
          </div>
        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="How will I know if my PureInsight<sup>&trade;</sup> report is ready?"
        >
          <div>
            You will receive an email notifying you that your healthcare provider has finalized your PureInsight<sup>&trade;</sup> report and it is ready for your review.
          </div>
        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="What does a PureInsight<sup>&trade;</sup> report look like?"
        >
          <div>
            PureInsight<sup>&trade;</sup> offers two ways to view your report; an interactive report organized by trait category or available as a printable summary report that consolidates all the results into one place.
          </div>
        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="What is the difference between the PureInsight<sup>&trade;</sup> report shared by my healthcare provider and the full DNA report?"
        >
          <div>
            The PureInsight<sup>&trade;</sup> report shared by your healthcare provider focuses on specific areas of your lifestyle, health, and wellness by providing personalized diet, exercise, lifestyle, and supplement recommendations. The DNA report provides details about your analyzed genetic traits.
          </div>
        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="How do I save my PureInsight<sup>&trade;</sup> patient report to my computer?"
        >
          <div>
            To save the report to your computer, click on the View and Print Report button at the top of the page. You will see a download button on the next screen.
          </div>
        </PgAccordion>

        <PgAccordion
          userType="Patient"
          question="I just received my PureInsight<sup>&trade;</sup> patient report. How do I send it to my doctor?"
        >
          <div>
            As soon as your data is uploaded, your doctor is automatically notified, and the report is instantly made available for their review. Please contact your healthcare provider to schedule an appointment to review your PureInsight<sup>&trade;</sup> patient report.
          </div>
        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="How does PureInsight<sup>&trade;</sup> deepen personalization?  "
        >
          <div>
            PureInsight<sup>&trade;</sup> allows your health care provider to collect a variety of clinically relevant information from questionnaires, DNA, and blood work to support their ability to clearly identify priority health areas and help you achieve your health and wellness goals. DNA is no longer required but when DNA is provided, you get nutrigenomic insights that help you to better understand how your genetics may influence your health and you receive highly personalized recommendations based on the both your questionnaires and genetics results.
          </div>
        </PgAccordion>


        {/* section 5 */}
        <div className="pgs-heading mt-5 mb-3 textc-primary__p">
          Supplement Recommendations
        </div>
        <PgAccordion
          userType="Patient"
          question="How can I purchase get the supplements recommended by my healthcare provider?"
        >
          <div>
            Please follow up with your healthcare provider for guidance regarding supplement recommendations and fulfillment.  You can also easily access your healthcare provider’s supplement recommendations via our virtual dispensary. You will be able to shop the recommendations from your healthcare practitioner as well as access the entire Pure Encapsulations product catalog of over 400 supplements.
          </div>
        </PgAccordion>

        {/* section 6 */}
        <div className="pgs-heading mt-5 mb-3 textc-primary__p">
          Nutrigenomics and Traits
        </div>
        <PgAccordion
          userType="Patient"
          question="What is nutrigenomics? What is nutrigenomics testing?  "
        >
          <div>
            Nutrigenomics is a branch of science that uses cutting-edge genomics technology to study the link between our genes, nutrition, and health. This information allows us to develop personalized strategies to help patients achieve their health and wellness goals.
          </div>
        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="What are traits?"
        >
          <div>
            Traits are specific characteristics like eye color, blood type, or the ability to tolerate lactose that are based on an individual's unique genetic variations (SNPs), as well as environmental factors.
          </div>
        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="Which trait categories are supported by PureInsight<sup>&trade;</sup>? "
        >
          <div>
            PureInsight<sup>&trade;</sup> reports offer insights into the
            following trait categories:
          </div>
          <ul>
            <li>Vitamins, minerals & omega-3s </li>
            <li>Detoxification </li>
            <li>Glucose metabolism </li>
            <li>Metabolic & weight management </li>
            <li>Cognitive health & memory </li>
            <li>Immune health </li>
            <li>Energy & fitness </li>
            <li>Cardiovascular health </li>
            <li>Gastrointestinal health </li>
          </ul>
        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="What are Single Nucleotide Polymorphisms (SNPs)? "
          answer="Called SNPs, or 'snips' for short, these are unique parts of your DNA. Some SNPs can affect your health. Some of them can also affect how your body breaks down and uses nutrients. "
        />

        <PgAccordion
          userType="Patient"
          question="If there are millions of SNPs, why does the report only show a limited number? "
        >
          <div>
            Most SNPs have no effect on nutritional health and therefore lack
            nutritional relevance. PureInsight<sup>&trade;</sup> includes SNPs
            that are:
          </div>
          <ol>
            <li>Relevant to health </li>
            <li>Validated by published research </li>
            <li>Responsive to nutritional support </li>
          </ol>
        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="Which testing companies’ genetic raw data file provides the most SNPs on your PureInsight<sup>&trade;</sup> report? "
        >
          <div>
            <div className="pgs-text">
              23andMe<sup>&reg;</sup> and Ancestry<sup>&reg;</sup> use a
              “genotyping chip” to detect patients’ DNA data. The reference SNP
              cluster IDs, also known as rs numbers, are included on the raw
              data is dependent on which chip was used. These companies commonly
              update their chip versions for a variety of reasons, which can
              impact the raw data available for further analysis. Below is a
              chart that outlines the differences in the most common chip
              versions used in PureInsight<sup>&trade;</sup>. (The “X” indicates
              that the SNP is included in that chip version).
            </div>
            <div className="overflow-x-scroll overflow-x-lg-hidden">
              <table className="pg-table my-3 text-center fw-bold">
                <thead>
                  <tr>
                    <td rowSpan="2" className="text-start">
                      SNP Tested
                    </td>
                    <td colSpan="2">23andMe</td>
                    <td>Ancestry</td>
                  </tr>
                  <tr>
                    <td>Version 4</td>
                    <td>Version 5</td>
                    <td>Version 2</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-start">EDN1 (rs5370)</td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td></td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-start">GATA3 (rs4143094)</td>
                    <td></td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-start">AOC1 (rs10156191)</td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td></td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-start">BCMO1 (rs12934922)</td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td></td>
                  </tr>

                  <tr>
                    <td className="text-start">TCN2 (rs1801198)</td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td></td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-start">SLC23A1 (rs33972313)</td>
                    <td></td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td></td>
                  </tr>

                  <tr>
                    <td className="text-start">DHCR7 (rs12785878)</td>
                    <td></td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-start">CYP2R1 (rs10741657)</td>
                    <td></td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-start">GPx1P1 (rs1050450)</td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>

                  <tr>
                    <td className="text-start">DRD2 (rs6277)</td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </PgAccordion>

        {/* section 7 */}
        <div className="pgs-heading mt-5 mb-3 textc-primary__p">
          Polygenic Scores and Traits
        </div>
        <PgAccordion
          userType="Patient"
          question="What is a polygenic score?"
        >
          <div>
            A polygenic score (PGS) is a measurement of genetic predisposition that analyzes many contributing SNPs, instead of just one.  Most traits are determined by many SNPs, not just one.  Since PGS looks at many SNPs, it is a more comprehensive genetic assessment and is generally more accurate than single SNP analysis.
          </div>
        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="What do the scores mean?"
        >
          <div>
            In PureInsight<sup>&trade;</sup>, the score reflects the number of risk alleles that contribute to the trait.   The higher the score, the greater the genetic predisposition.  For example, a high score for vitamin B<sub>12</sub> indicates that an individual’s B<sub>12</sub> needs are greater than the needs of someone with a lower score.
          </div>
        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="How long should I wait for my Polygenic Scores to be available?"
        >
          <div>
            Calculating the Polygenic scores should take no more than an hour to get generated, and once the score is ready, as long as your health care practitioner has released your report, an email notification will be sent to both you and your practitioner. If your scores were not generated by then, please contact us <a href="/contact-us">here</a>.
          </div>
        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="Why were enhancements made for just a few select traits? "
        >
          <div>
            The accuracy of scores and intake recommendations depends on robust genome-wide association studies and development of mathematical models requiring population data on blood levels and dietary intake information. This information is currently not available for all traits. As part of our ongoing research and development, we will continue to evaluate new traits for compatibility with PGS and personalized intake recommendations.
          </div>
        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="How are the SNPs for the polygenic trait selected? "
        >
          <div>
            The contributing SNPs analyzed by the PGS were selected based on genome-wide association studies and other population data sources. SNPs with strong and statistically significant effects on nutrient levels are included in a polygenic score.
          </div>
        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="How accurate are the scores and intake recommendations?"
        >
          <div>
            The polygenic scores and personalized intake recommendations are calculated from population data showing correlations between nutrient intakes, blood levels and genotypes. These calculations are based on genetics. Practitioners are encouraged to adjust nutrient intake recommendations based on labs and other assessments.
          </div>
        </PgAccordion>

        {/* section 8 */}
        <div className="pgs-heading mt-5 mb-3 textc-primary__p">
          Privacy and Security
        </div>
        <PgAccordion
          userType="Patient"
          question="Where can I go to learn more about the PureInsight<sup>&trade;</sup> Privacy Policy?"
        >
          <div>
            PureInsight<sup>&trade;</sup> will not sell, share, or rent a patient's 23andMe<sup>&reg;</sup> or Ancestry<sup>&reg;</sup> data files to third parties. Nor will we store any genetic data on disc unless it is being processed, and the database itself is encrypted. For more information about our privacy and security practices, please review our Privacy Policy.
          </div>
        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="What privacy and security measures are taken to protect my genetic data?"
        >
          <div>
            <p>We use industry standard practices to store your genetic test results and other Personal Information you provide to us in a secured database, which employs a number of security measures. The specific security measures used are based on the sensitivity of the Personal Information collected.  </p>
            <p>All connections to and from our website are encrypted using Secure Socket Layer (SSL) technology. In addition, your genetic raw data is completely de-identified and encrypted in our database encryption to protect sensitive information online, we also do everything in our power to protect user-information offline.</p>
            <p>As well as protecting users' information from unauthorized access from those outside of the company, we strictly limit access to this database from within the company. Only employees who need the information to perform a specific job (for example, a customer service representative) are granted access to personally identifiable information.</p>
          </div>
        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="How secure and private is PureInsight<sup>&trade;</sup>?"
        >
          <div>
            <p>We store your genetic test results without your name or other common identifying information. This means that no outside affiliate can access your genetic data and that your Personal Information has been unattached from your DNA.</p>
            <p>We use secure server software to encrypt Personal Information (including sensitive health information and genetic data), and we only partner with security companies that meet and commit to our security standards. While we cannot guarantee that loss, misuse, or alteration of data will not occur, we use reasonable efforts to prevent this.</p>
            <p>It is also important for you to guard against unauthorized access to your Personal Information by maintaining strong passwords and protecting against the unauthorized use of your own computer or device. You should not disclose your authentication information to any third party and should immediately notify us of any unauthorized use of your password.</p>
          </div>
        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="Who will have access to my DNA?"
        >
          <div>
            <p>Only you and your healthcare provider will have access to your genetic data file. We do not share with third parties your name or other common identifying information linked to your genetic data, except as legally required or with your explicit consent. We cannot secure personal data that you release on your own or that you request us to release. If you ask your healthcare provider to incorporate your data into your medical record, for example, under federal regulations the government may access it for law enforcement purposes.</p>
            <p>Please note, should you access the website of 23andMe<sup>&reg;</sup> or Ancestry<sup>&reg;</sup> by way of links provided on this website, you are leaving PureInsight<sup>&trade;</sup> and will be subject to the privacy and security policies of 23andMe<sup>&reg;</sup> or Ancestry<sup>&reg;</sup>, as applicable. We are not responsible for the content or services provided by external websites.</p>
          </div>
        </PgAccordion>

        {/* section 9 */}
        <div className="pgs-heading mt-5 mb-3 textc-primary__p">
          Virtual Dispensary
        </div>
        <PgAccordion
          userType="Patient"
          question="Can I order any products that are available from Pure Encapsulations?"
        >
          <div>
            Yes, once your Pure Patient Direct account activation is complete, you will be able to shop the recommendations from your healthcare practitioner as well as access the entire Pure Encapsulations product catalog of over 400 supplements.
          </div>
        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="Why am I unable to find an item my practitioner recommended in the product catalog?"
        >
          <div>
            Items that are on backorder are not available to be ordered. Once they become available, you will see them in the product search. For questions on items, please contact Customer Service.
          </div>
        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="Can I track orders?"
        >
          <div>
            Yes, your "My Orders" page will have all order details available to you. You will also receive an email with tracking information once your order has shipped.
          </div>
        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="Why did an item on my order get cancelled?"
        >
          <div>
            Items with low inventory may go on backorder as the order is placed. Any backordered items will automatically be cancelled by our system. You may order the item again once it becomes available.
          </div>
        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="How long will it take to receive my supplements?"
        >
          <div>
            Orders received by 3:00pm (ET) are shipped for FREE that day via UPS 2-Day service. All other orders will be shipped the next business day. Please note that orders placed on Thursday or Friday that include refrigerated items will be held until Monday.
          </div>
        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="Will I be charged shipping?"
        >
          <div>
            Shipping is FREE for orders within the continental U.S.
          </div>
        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="What is your return policy?"
        >
          <div>
            Returns must be made within 30 days of receipt and require preauthorization, which can be requested by going to your order history within your account portal and selecting the order you wish to return. Any claims of shortage or damage must be made within 7 days of receiving your package. For more information, please check the <a href="https://pureencapsulationspro.com/patient-direct-terms-of-sale" target="_blank" rel="noreferrer">Terms of Sale</a>.
          </div>
        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="Can I add or delete items from my order?"
        >
          <div>
            You can add or delete products during your checkout process. Once you provide your payment information and your order is confirmed, you will no longer be able to make changes to your order. You can always place a new order for any products you forgot to add to your shopping cart.
          </div>
        </PgAccordion>
        <PgAccordion
          userType="Patient"
          question="Who can I contact for assistance?"
        >
          <div>
            <div class="pg-text">
              For any questions, our Customer Service team is available M-F, 8:30am – 7:00pm (ET) by phone: 800-263-5861 or by email: <a href="emailto:customerservice@pureencapsulations.com" class="pg-text">customerservice@pureencapsulations.com</a>
            </div>

            <div class="pg-text">
              For all other questions, our Technical Support team is available M-F, from 9:00am – 5:00pm (ET) by phone: 877-760-0323 or by email: <a href="emailto:pureinsight@pureencapsulations.com" class="pg-text">pureinsight@pureencapsulations.com</a>.
            </div>
         </div> 
        </PgAccordion>

      </div>
    </div>
  );
}

export default CAPatientFAQ;
