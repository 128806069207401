import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import PgCheckbox from "../../PgCheckbox";
import GenotypeFilterTable from './GenotypeFilterTable'
import { Spinner } from "react-bootstrap";

function GenotypFilter({
  showModal,
  onCancel,
  data,
  updateTable, t
}) {

  return (
    <Modal
      show={showModal}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onCancel}
      contentClassName="border-radius p-4 genotype-filter-table"
    >
      <Modal.Header className="d-block py-3">
        <div className="pg-heading ">{t('genotype_line_2')}</div>
        <div className="pg-text">{t('genotype_line_3')}</div>
      </Modal.Header>
      <Modal.Body className="px-5 ">
        <div className="row">
          {data?.length > 0 &&
            <GenotypeFilterTable
              genotype={data}
              error={false}
              updateTable={updateTable}
              onCancel={onCancel}
            />}
        </div>
      </Modal.Body>
      {/* <Modal.Footer className="d-flex justify-content-end">
        <div>
          <button className="btn bg-none textc-primary px-5" onClick={onCancel}>
            Cancel
          </button>
          <button
            className="btn bgc-primary text-light px-5"
            onClick={() => {
              onCancel();
            }}
          >
            Save
          </button>
        </div>
      </Modal.Footer> */}


    </Modal>
  );
}

export default GenotypFilter;
