import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function MultiIntervalDropdown({ question, onInput }) {
  const { t } = useTranslation();
  const answers = useSelector((state) => state.pg4.q_answers);
  const errors = useSelector((state) => state.pg4.q_errors);

  // const [selectedOptions, setSelectedOptions] = useState(
  //     question.options.map(() => null)
  // );

  const handleOptionChange = (index, event) => {
    // console.log({ question })
    const prevState = answers?.[question?.question_id]?.answer || [];
    if (Array.isArray(prevState)) {
      prevState[index] = parseInt(event?.target?.value);
      for (let i = 0; i < question?.options?.length; i++) {
        if (index !== i) {
          if (!prevState[i]) {
            console.log("updating this input", i, prevState);
            prevState[i] = question?.options?.[i]?.start;
          }
        }
      }
      const updatedAnswer = {
        [question.question_id]: {
          question_id: question.question_id,
          answer: prevState,
        },
      };
      console.log("state:", updatedAnswer);
      onInput(updatedAnswer);
    }
  };

  const generateOptions = (options) => {
    return Array.from(
      { length: (options.end - options.start) / options.interval + 1 },
      (_, index) => options.start + index * options.interval
    );
  };

  // useEffect(() => {
  //     console.log(errors, 'errors');
  // }, [errors]);

  return (
    <div className="pg4-card" id={question.question_id}>
      <div className="pg-heading__sub textc-primary__p">
        {t(question.question_text)}
      </div>
      <div className="d-flex mt-3 fw-bold flex-row">
        {question.options.map((option, index) => (
          <div className="d-flex flex-column mx-1 text-capitalize" key={index}>
            {t(option.unit)}
            <select
              id={`option_${index}`}
              // defaultValue={answers?.[question.question_id]?.answer[index]}
              value={
                answers?.[question.question_id]?.answer[index] >= 0
                  ? answers?.[question.question_id]?.answer[index]
                  : ""
              }
              onChange={(event) => handleOptionChange(index, event)}
              className="pg4-select"
              placeholder={"Enter your " + question.question_text}
            >
              <option value="" disabled></option>
              {generateOptions(option).map((value) => (
                <option key={value} value={value}>
                  {t(value)}
                </option>
              ))}
            </select>
          </div>
        ))}
      </div>
      <div
        className="text-danger transition-all"
        style={{ opacity: errors[question?.question_id] ? 1 : 0 }}
      >
        <i className="fas fa-info-circle"></i> This field is required
      </div>
    </div>
  );
}

export default MultiIntervalDropdown;
