import * as pa_constant from "../actions/patientAccount";

const initialState = {
  isGettingPatientDetail: true,
  PatientDetail: '',
  loading_patient_types: true
};

const patientAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    
    //patient detail
    case pa_constant.GET_PATIENT_DETAILS_REQUEST: {
      return { ...state, isGettingPatientDetail: true, PatientDetail: '' };
    }
    case pa_constant.GET_PATIENT_DETAILS_SUCCESS: {
      return {
        ...state,
        isGettingPatientDetail: false,
        PatientDetail: action.payload.patient,
      };
    }
    case pa_constant.GET_PATIENT_DETAILS_FAILURE: {
      return { ...state, isGettingPatientDetail: false };
    }

    //edit patient
    case pa_constant.EDIT_PATIENT_REQUEST:
    case pa_constant.EDIT_PATIENT_SUCCESS:
    case pa_constant.EDIT_PATIENT_FAILURE: {
      return { ...state };
    }

    //change password
    case pa_constant.CHANGE_PASSWORD_REQUEST:
    case pa_constant.CHANGE_PASSWORD_SUCCESS:
    case pa_constant.CHANGE_PASSWORD_FAILURE: {
      return { ...state };
    }

    //remove report
    case pa_constant.REMOVE_REPORT_REQUEST:
    case pa_constant.REMOVE_REPORT_SUCCESS:
    case pa_constant.REMOVE_REPORT_FAILURE: {
      return { ...state };
    }

    //fetch practitioners
    case pa_constant.GET_PRACTITIONERS_LIST_REQUEST:
    case pa_constant.GET_PRACTITIONERS_LIST_SUCCESS: 
    case pa_constant.GET_PRACTITIONERS_LIST_FAILURE: {
      return { ...state };
    }

    default:
      return state;
  }
};

export default patientAccountReducer;