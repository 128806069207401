import react, { useState, useEffect } from "react";
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";
import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
import { toggleSidebar } from "../methods/sidenav";
import "../styles/sidenav.css";
import { logoutAction } from "../methods/auth_methods";

const SideNav = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const cookies = new Cookies();
  // redux state
  const isAuth = useSelector((state) => state.auth.isAuth);
  const mfaEnabled = useSelector((state) => state.auth.mfaEnabled);
  const userType = useSelector((state) => state.auth.userType);
  const token = useSelector((state) => state.auth.token);
  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const language = useSelector((state) => state.auth.language);
  const currentPracticeID = useSelector(
    (state) => state.auth.currentPracticeID
  );
  const currentPG2UserId = useSelector((state) => state.auth.currentPG2UserId);

  // localstate
  const [inHomePage, setInHomePage] = useState(true);

  useEffect(() => {
    if (location.pathname === "/") {
      setInHomePage(false);
    } else {
      setInHomePage(true);
    }
  }, [location]);

  const goToDashboard = () => {
    navigate(
      `/${
        userType === "AdminUser"
          ? "admin"
          : userType === "User"
          ? "practice"
          : "patient"
      }/dashboard`
    );
    toggleSidebar();
  };

  return (
    <div className="sidebar-container" id="sidebar-container">
      <div className="sidebar-backdrop" onClick={toggleSidebar}></div>

      <div
        className="px-2 mb-3 bgc-primary sidebar d-flex flex-column justify-content-center align-items-center	"
        id="sidebar"
      >
        <div className="d-flex justify-content-end mb-10 mx-5 my-3 w-75">
          <i
            className="fas fa-times text-light mt-1"
            onClick={toggleSidebar}
          ></i>
        </div>

        {isAuth && mfaEnabled ? (
          <div className="h-100 d-flex flex-column align-content-start justify-content-start text-center">
            <button className="btn btn-light py-2" onClick={goToDashboard}>
              {t("dashboard")}
            </button>
            {!inHomePage && (
              <div
                className="my-3 fw-bold w-100 text-white mx-auto"
                onClick={() => {
                  logoutAction();
                  toggleSidebar();
                }}
              >
                {t("header_btn_1")}
              </div>
            )}
          </div>
        ) : (
          <div className="h-100 d-flex flex-column align-items-center justify-content-start pb-5">
            <a
              href="https://www.pureencapsulationspro.com/"
              target="_blank"
              className="fw-bold text-white mt-2"
            >
              Pure Encapsulations Pro
            </a>
            <NavLink
              className="provider-reg-link anchor"
              to="/hcp/registration"
            >
              {t("header_btn_2")}
            </NavLink>

            <button
              className="btn btn-light w-100 py-2"
              onClick={() => {
                toggleSidebar();
                navigate("/users/login");
              }}
              id="sign-in-btn__sidebar"
            >
              {t("prac_register_sign_in_text")}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SideNav;
