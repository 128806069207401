import { createAction } from "redux-api-middleware";
const protocol = process.env.REACT_APP_PROTOCOL;
const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;

export const LAB_VENDOR_TEST_REQUEST = "LAB_VENDOR_TEST_REQUEST";
export const LAB_VENDOR_TEST_SUCCESS = "LAB_VENDOR_TEST_SUCCESS";
export const LAB_VENDOR_TEST_FAILURE = "LAB_VENDOR_TEST_FAILURE";

export const LAB_UPLOAD_REQUEST = "LAB_UPLOAD_REQUEST";
export const LAB_UPLOAD_SUCCESS = "LAB_UPLOAD_SUCCESS";
export const LAB_UPLOAD_FAILURE = "LAB_UPLOAD_FAILURE";

export const LAB_UPLOAD_STATUS_REQUEST = "LAB_UPLOAD_STATUS_REQUEST";
export const LAB_UPLOAD_STATUS_SUCCESS = "LAB_UPLOAD_STATUS_SUCCESS";
export const LAB_UPLOAD_STATUS_FAILURE = "LAB_UPLOAD_STATUS_FAILURE";

export const LAB_UPLOAD_RESULT_REVIEW_REQUEST =
  "LAB_UPLOAD_RESULT_REVIEW_REQUEST";
export const LAB_UPLOAD_RESULT_REVIEW_SUCCESS =
  "LAB_UPLOAD_RESULT_REVIEW_SUCCESS";
export const LAB_UPLOAD_RESULT_REVIEW_FAILURE =
  "LAB_UPLOAD_RESULT_REVIEW_FAILURE";

export const POST_LAB_REVIEW_RESULT_REQUEST = "POST_LAB_REVIEW_RESULT_REQUEST";
export const POST_LAB_REVIEW_RESULT_SUCCESS = "POST_LAB_REVIEW_RESULT_SUCCESS";
export const POST_LAB_REVIEW_RESULT_FAILURE = "POST_LAB_REVIEW_RESULT_FAILURE";

export const UPDATE_LAB_UPLOAD = "UPDATE_LAB_UPLOAD";

export const COMMON_REQUEST = "COMMON_REQUEST";
export const COMMON_SUCCESS = "COMMON_SUCCESS";
export const COMMON_FAILURE = "COMMON_FAILURE";
export const LAB_TEST_SUCCESS = "LAB_TEST_SUCCESS";

export const updateLabUpload = (pld) => {
  return {
    type: UPDATE_LAB_UPLOAD,
    payload: pld,
  };
};

export const labUpload = (token, currentPracticeID, currentPractice, data) => {
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/upload_lab_reports`,
    method: "POST",
    body: data,
    headers: { Authorization: token, "Current-Practice": currentPracticeID },
    types: [LAB_UPLOAD_REQUEST, LAB_UPLOAD_SUCCESS, LAB_UPLOAD_FAILURE],
  });
};

export const createManualEntries = (token, currentPracticeID, currentPractice, data) => {
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/create_manual_entries`,
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [COMMON_REQUEST, COMMON_SUCCESS, COMMON_FAILURE],
  });
};

export const patientCreateManualEntries = (token, currentPracticeID, currentPractice, data) => {
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/patient/create_manual_entries`,
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [COMMON_REQUEST, COMMON_SUCCESS, COMMON_FAILURE],
  });
};

export const deleteManualEntry = (token, currentPracticeID, currentPractice, lab_report_result_id) => {
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/lab_report_result?lab_report_result_id=${lab_report_result_id}`,
    method: "DELETE",
    headers: { Authorization: token, "Current-Practice": currentPracticeID },
    types: [COMMON_REQUEST, COMMON_SUCCESS, COMMON_FAILURE],
  });
};

export const getLabUploadStatus = (
  token,
  currentPracticeID,
  currentPractice,
  data
) => {
  const type = data.type;
  const document_id = data.document_id;
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/web/api/get_analyze_status?type=${type}&document_id=${document_id}`,
    method: "GET",
    headers: { Authorization: token, "Current-Practice": currentPracticeID },
    types: [
      LAB_UPLOAD_STATUS_REQUEST,
      LAB_UPLOAD_STATUS_SUCCESS,
      LAB_UPLOAD_STATUS_FAILURE,
    ],
  });
};

export const getLabVendors = (token, currentPracticeID, currentPractice) => {

  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/vendor_tests`,
    // endpoint: `${baseUrl}/practice/vendor_tests`,
    method: "GET",
    headers: {
      "Content-Type": "text/plain",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      LAB_VENDOR_TEST_REQUEST,
      LAB_VENDOR_TEST_SUCCESS,
      LAB_VENDOR_TEST_FAILURE,
    ],
  });
};

export const getPatientLabVendors = (token, currentPracticeID, currentPractice) => {

  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/patient/vendor_tests`,    
    method: "GET",
    headers: {
      "Content-Type": "text/plain",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      LAB_VENDOR_TEST_REQUEST,
      LAB_VENDOR_TEST_SUCCESS,
      LAB_VENDOR_TEST_FAILURE,
    ],
  });
};


export const getLabTests = (token, currentPracticeID, currentPractice) => {

  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/lab_tests`,
    method: "GET",
    headers: {
      "Content-Type": "text/plain",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      COMMON_REQUEST,
      LAB_TEST_SUCCESS,
      COMMON_FAILURE,
    ],
  });
};

export const getLabUploadReview = (
  token,
  currentPracticeID,
  currentPractice,
  currentPatientId,
  id,
  history,
) => {
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/lab_report_results?patient_id=${currentPatientId}&document_ids=${id}&history=${history}`,
    method: "GET",
    headers: {
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      LAB_UPLOAD_RESULT_REVIEW_REQUEST,
      LAB_UPLOAD_RESULT_REVIEW_SUCCESS,
      LAB_UPLOAD_RESULT_REVIEW_FAILURE,
    ],
  });
};

export const postLabUploadReview = (
  token,
  currentPracticeID,
  currentPractice,
  data
) => {
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/update_lab_results`,
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      POST_LAB_REVIEW_RESULT_REQUEST,
      POST_LAB_REVIEW_RESULT_SUCCESS,
      POST_LAB_REVIEW_RESULT_FAILURE,
    ],
  });
};
