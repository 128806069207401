import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateLabUpload } from "../../../../store/actions/labUpload";
import { questionnaireStarted, retakeQuestionnaire } from "../../../../store/actions/pg4dashboard";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../../methods";
import { updatePatientReport } from "../../../../store/actions/patientReport";
import { updateGeneticUpload } from "../../../../store/actions/genetics";
import { ReactComponent as ClockIcon } from "../../../../assets/dashboard/clock.svg";
import { ReactComponent as CheckIcon } from "../../../../assets/dashboard/check.svg";
import { ReactComponent as FileIcon } from "../../../../assets/dashboard/file.svg";
import { ReactComponent as TrendingIcon } from "../../../../assets/dashboard/trending-down.svg";

export const CompletedCard = ({ cardInfo, context }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const token = useSelector(state => state.auth.token);
    const patient_id = useSelector(state => state.auth.currentUserId);
    const currentPractice = useSelector(state => state.auth.currentPractice);
    const questionnaireStartedAction = (session_id) => dispatch(questionnaireStarted({ token, patient_id, currentPractice, session_id }));
    const retakeQuestionnaireAction = (questionnaire_id) => dispatch(retakeQuestionnaire({ token, patient_id, currentPractice, questionnaire_id }));
    const updateLabFileStatus = (data) => dispatch(updateLabUpload(data));
    const labFileStatus = useSelector(state => state.labUpload.fileStatus);
    const updatePatientReportAction = (data) => dispatch(updatePatientReport(data));
    const updateGeneticUploadAction = (data) => dispatch(updateGeneticUpload(data));
    const { t, i18n } = useTranslation();

    const goToLabUploadPage = () => {
        const isOldFile = ((cardInfo.id) && (cardInfo.status?.toLowerCase() !== "completed"));
        console.log('locstate:', cardInfo, isOldFile);
        if (isOldFile) {
            navigate('/patient/lab', { state: { isOldFile, dID: cardInfo.id, dIDs: cardInfo.document_ids } })
        } else {
            navigate('/patient/lab', { state: { isOldFile } })
        }
    }


    const navigateFn = async () => {
        console.log(cardInfo.status)
        if (cardInfo.card_type === "genetic_upload") {
            navigate("/patient/snp")
        }
        else if (cardInfo.card_type === "blood_work") {
            goToLabUploadPage();
        }
        else if ((cardInfo.card_type === "questionnaire_msq" || cardInfo.card_type === "questionnaire_avq") && cardInfo.status === "completed") {
            const response = await retakeQuestionnaireAction(cardInfo.questionnaire_id);
            if (!response.error) {
                navigate("/patient/msq", { state: { ...cardInfo, session_id: response.payload.session_id, questionnaire_id: response.payload.questionnaire_id } })
            }
            else
                navigate("/patient/msq", { state: cardInfo })
        }
        else if ((cardInfo.card_type === "questionnaire_msq" || cardInfo.card_type === "questionnaire_avq") && cardInfo.status === "assigned") {
            const response = await questionnaireStartedAction(cardInfo.session_id);
            navigate("/patient/msq", { state: cardInfo })
        }
        else if (cardInfo.card_type === "questionnaire_msq" || cardInfo.card_type === "questionnaire_avq") {
            navigate("/patient/msq", { state: cardInfo })
        }
    }

    const goToPG3Report = () => {
        updatePatientReportAction({
            currentPatientId: patient_id,
            currentReportId: cardInfo.report_id,
        });
        navigate("/patient/report");
    }

    const gotoGeneticUpload = () => {
        if (cardInfo.status === "completed")
            updateGeneticUploadAction({
                fileSelected: false,
                fileStatus: "Uploading",
                fileUploadStarted: false,
                uploadError: false,
                documentId: null,
            });
        else
            updateGeneticUploadAction({
                fileSelected: true,
                fileStatus: cardInfo.status,
                fileUploadStarted: true,
                uploadError: false,
                documentId: cardInfo.id,
            });
        navigate("/patient/snp");
    }
    return (<div className="pg4-card position-relative overflow-hidden dashboard-card bg-light h-100 my-3" >
        {cardInfo?.showInfoBanner && <div className="position-absolute p-3" style={{ transform: 'rotate(130deg)', top: -67, right: -55, height: 120, width: 120, background: 'var(--pg_primary__p)' }}>
            <div className="position-relative">
                <i className="position-absolute far fa-star fs-4" style={{ transform: 'rotate(158deg)', top: '30px', right: "-8px", color: '#fff' }}></i>
            </div>
        </div>}
        <div className="d-flex flex-column justify-content-between h-100">
            <div>
                <div className="pg4-text__heading textc-primary__p" >{cardInfo.heading}</div>
                <div className="pg4-text my-3 text-uppercase text_primary fw-semibold">
                    {!cardInfo?.showInfoBanner && <div className="text_primary fw-semibold">
                        <ClockIcon /> <span className="my-auto ms-2"> {cardInfo.timimg_data}</span>
                    </div>}
                    {(!cardInfo?.showInfoBanner && (cardInfo.card_type === "questionnaire_msq" || cardInfo.card_type === "questionnaire_avq")) &&
                        <div className="">
                            <div className="my-2">
                                <CheckIcon /> <span className="ms-2">{t('pat_dash_comp_card_icon_txt_1')} {formatDate(cardInfo.last_completed)}</span>
                            </div>
                            <div className="my-2">
                                <TrendingIcon /> <span className="ms-1">{t('pat_dash_comp_card_icon_txt_2')} {cardInfo.previous_score}</span>
                            </div>
                            <div className="my-2">
                                <FileIcon /> <span className="ms-2">{t('pat_dash_comp_card_icon_txt_3')} {formatDate(cardInfo.recommeded_retake)}</span>
                            </div>
                        </div>
                    }
                </div>
                {cardInfo.html_content && <div className="pg4-text my-2" dangerouslySetInnerHTML={{ __html: cardInfo.html_content }} />}
            </div>
            {cardInfo.card_type !== "genetic_upload" && <div className="d-flex justify-content-center">
                <button
                    className="pg4-btn pg4-btn_primary w-sm-100 w-100 mx-3"
                    onClick={navigateFn}
                >
                    {cardInfo.button_text}
                </button>
            </div>}
            {cardInfo.card_type === "genetic_upload" && <div className="d-flex flex-wrap justify-content-between">
                <button
                    className="pg4-btn pg4-btn_primary w-sm-100"
                    onClick={gotoGeneticUpload}
                    style={{ width: '49%' }}
                >
                    {cardInfo.button_text}
                </button>
                {(cardInfo.report_id) &&
                    <button
                        className=" pg4-btn pg4-btn_outline w-sm-100 mt-2 mt-md-0"
                        onClick={goToPG3Report}
                        style={{ width: '49%', lineHeight: 1.2 }}
                    > {t('pat_dashboard_btn_1')} </button>
                }
            </div>}
        </div>
    </div>);
}
