import ContentLoader from "react-content-loader";
function L_Multiline() {
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height="220px"
      backgroundColor="#9e9e9e52"
      foregroundColor="#18368f80"
      // {...props}
    >
      <rect x="80" y="17" rx="4" ry="4" width="500" height="13" />
      <rect x="80" y="40" rx="3" ry="3" width="500" height="15" />
      <rect x="80" y="67" rx="4" ry="4" width="500" height="13" />
      <rect x="80" y="90" rx="3" ry="3" width="500" height="15" />

      <rect x="80" y="140" rx="4" ry="4" width="500" height="13" />
      <rect x="80" y="163" rx="3" ry="3" width="500" height="15" />
      <rect x="80" y="190" rx="4" ry="4" width="500" height="13" />
      <rect x="80" y="217" rx="3" ry="3" width="500" height="15" />
    </ContentLoader>
  );
}

export default L_Multiline;
