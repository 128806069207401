import React, { useState, useEffect, useMemo } from "react";
import { useTable, usePagination, useSortBy, useRowSelect } from "react-table";
import TableFooter from "../TableFooter";
import TextInputWithIcon from "../../../components/TextInputWithIcon";
import { PgRadioButton } from "../../../components/PgRadioButton";
import PgCheckbox from "../../../components/PgCheckbox";
import AddAdminUser from "./AddAdminUser";
import { useNavigate } from "react-router-dom";
import AdminBatchFn from "../../../components/Admin/AdminUser/AdminBatchFn";
import L_PatientList from '../../../components/LoadingEffectComponent/L_PatientList'

export const AdminUserTable = ({
  adminUsers,
  error,
  totalData,
  updateAdminUserTable,
  token,
  masterAdmin,
  loading,
}) => {
const navigate = useNavigate();

  const [selectedAdminUserArray, setSelectedAdminUserArray] = useState([])

  const [controlledPageCount, setControlledPageCount] = useState(1);
  const [sPageIndex, setSPageIndex] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [userStatus, setUserStatus] = useState([])
  const [showAddAdminUserModal, setShowAddAdminUserModal] = useState(false)
  const [recentURL, setRecentURL] = useState("")

  const closeAddAdminUserModal = () => {
    setShowAddAdminUserModal(false)
  }

  const onSelectAll = () => {
    setSelectedAdminUserArray(spa => {
      if (spa.length === adminUsers.length)
        return [];
      else
        return adminUsers.map(xx => xx.id);
    })
  }

  const onSelectAdminUser = (row) => {
    setSelectedAdminUserArray(spa => {
      if (spa.includes(row.id))
        return spa.filter(_id => _id !== row.id)
      else
        return [...spa, row.id]
    })
  }

  const [sortfName, setsortfName] = useState("")
  const [sortlName, setsortlName] = useState("");
  const [sortEmail, setsortEmail] = useState("")
  const [sortStatus, setsortStatus] = useState("")

  const resetSort = () => {
    setsortfName(false); setsortlName(false); setsortStatus(false); setsortEmail(false);
  }
  const manualSort = (e) => {
    resetSort()
    const field = e.target.innerText.split(' ')[0]?.toLowerCase();
    console.log(field);
    if (field === "email") {
      setsortEmail(sortEmail === "" ? 'DESC' : sortEmail === "DESC" ? "ASC" : "DESC")
      updateAdminUserTable(`${recentURL}&sort_key=email&sort_value=${sortEmail === "" ? 'DESC' : sortEmail === "DESC" ? "ASC" : "DESC"}`)
    }
    if (field === "first") {
      setsortfName(sortfName === "" ? 'DESC' : sortfName === "DESC" ? "ASC" : "DESC")
      updateAdminUserTable(`${recentURL}&sort_key=first_name&sort_value=${sortfName === "" ? 'DESC' : sortfName === "DESC" ? "ASC" : "DESC"}`)
    }
    if (field === "last") {
      setsortlName(sortlName === "" ? 'DESC' : sortlName === "DESC" ? "ASC" : "DESC")
      updateAdminUserTable(`${recentURL}&sort_key=last_name&sort_value=${sortlName === "" ? 'DESC' : sortlName === "DESC" ? "ASC" : "DESC"}`)
    }
    if (field === "status") {
      setsortStatus(sortStatus === "" ? 'DESC' : sortStatus === "DESC" ? "ASC" : "DESC")
      updateAdminUserTable(`${recentURL}&sort_key=aasm_state&sort_value=${sortStatus === "" ? 'DESC' : sortStatus === "DESC" ? "ASC" : "DESC"}`)
    }
  }

  const continueSort = (url) => {
    console.log(url);
    if (sortEmail) {
      updateAdminUserTable(`${url}&sort_key=email&sort_value=${sortEmail}`)
    }
    else if (sortfName) {
      updateAdminUserTable(`${url}&sort_key=first_name&sort_value=${sortfName}`)
    }
    else if (sortStatus) {
      updateAdminUserTable(`${url}&sort_key=aasm_state&sort_value=${sortStatus}`)
    }
    else if (sortlName) {
      updateAdminUserTable(`${url}&sort_key=last_name&sort_value=${sortlName}`)
    } else
      updateAdminUserTable(url);
  }

  const column = [
    {
      Header: <div className="position-relative practitioner-batch-checkbox">
        <PgCheckbox checked={selectedAdminUserArray.length > 0 && selectedAdminUserArray.length === adminUsers.length} onCheck={onSelectAll} />
      </div>,
      accessor: "id",
      sortable: false,
      filterable: false,
      Cell: (value) => {
        const { row } = value;
        return (
          <div className="position-relative practitioner-batch-checkbox">
            <PgCheckbox
              checked={selectedAdminUserArray.includes(row.original.id)}
              onCheck={(e) => onSelectAdminUser(row.original)} />
          </div>
        );
      },
    },
    { Header: `First Name ${sortfName === "DESC" ? ' ↓' : ' ↑'}`, accessor: "first_name" },
    { Header: `Last Name ${sortlName === "DESC" ? ' ↓' : ' ↑'}`, accessor: "last_name" },
    { Header: `Email ${sortEmail === "DESC" ? ' ↓' : ' ↑'}`, accessor: "email" },
    { Header: `Status ${sortStatus === "DESC" ? ' ↓' : ' ↑'}`, accessor: "aasm_state" },
  ];

  const columns = useMemo(() => column, [selectedAdminUserArray, adminUsers]);
  const data = useMemo(() => adminUsers, [adminUsers]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    canNextPage,
    previousPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: sPageIndex,
        // pageSize: 3
        pageSize: 100
      },
      manualPagination: true,
      pageCount: controlledPageCount,
      autoResetSortBy: false,
      // autoResetPage: true,
      autoResetPage: false,
      disableSortRemove: true,
      defaultCanSort: true,
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    setControlledPageCount(Math.ceil(totalData / pageSize));
  })

  useEffect(() => {
    gotoPage(0);
  }, [searchText])

  useEffect(() => {
    let url = `per_page=${pageSize}&page=${pageIndex + 1}`;
    if (searchText.trim().length > 1) url += `&search=${searchText}`;
    if (userStatus.length > 0) url += `&aasm_state=${userStatus}`;
    setRecentURL(url);
    setSelectedAdminUserArray([])
    const delayDebounceFn = setTimeout(() => {
      continueSort(url);
    }, 1000)
    return () => clearTimeout(delayDebounceFn)
  }, [pageSize, pageIndex, searchText]);

  return (
    <div className={"admin-table" + (masterAdmin ? '' : ' normal-admin')}>
      <div className="mt-5 d-flex justify-content-start">
        <TextInputWithIcon
          label="Admin User Name"
          placeholder="Search Admin Users"
          value={searchText}
          inputHandler={(e) => setSearchText(e.target.value)}
          Icon={
            <i className="fa fa-search text-secondary" aria-hidden="true"></i>
          }
        />
        { masterAdmin && <div className="justify-content-end">
          <button
            id="add-patient-btn__patientlist"
            className="btn btn-outline px-4 ms-2"
            onClick={() => setShowAddAdminUserModal(true)}
          >
            <i className="fas fa-plus mx-1"></i> Add new Admin User
          </button>
        </div> }
      </div>

      <div className="my-3">
        {loading
          ? <div className="mt-5">
            <L_PatientList />
          </div>
          : <>
            <div className="my-3">
              <AdminBatchFn
                updateAdminUserTable={() => updateAdminUserTable(recentURL)}
                admins={adminUsers}
                selectedAdmins={selectedAdminUserArray}
                setSelectedAdminUserArray={setSelectedAdminUserArray}
                masterAdmin={masterAdmin}
              />
            </div>
            <table {...getTableProps()} className="pg-table practitioners-table my-2">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps(column.getSortByToggleProps())} onClick={manualSort}>
                        {column.render("Header")}
                        <span className="fs-4">
                          {column.isSorted ? (column.isSortedDesc ? "↓" : "↑") : ""}{" "}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              {error && (
                <tbody>
                  <tr>
                    <td colSpan="8">
                      <div className="w-100 text-center">
                        Unable to fetch admin users list
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
              {adminUsers.length < 1 && !error && (
                <tbody>
                  <tr>
                    <td colSpan="8">
                      <div className="w-100 text-center">No Admin User Found</div>
                    </td>
                  </tr>
                </tbody>
              )}
              {adminUsers.length > 0 && !error && (
                <tbody {...getTableBodyProps()}>
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
            <TableFooter
              pageSize={pageSize}
              canNextPage={canNextPage}
              canPreviousPage={canPreviousPage}
              nextPage={nextPage}
              previousPage={previousPage}
              setPageSize={setPageSize}
              pageIndex={pageIndex}
              pageOptions={pageOptions}
            />
          </>
        }
      </div>

      {
        showAddAdminUserModal &&
        <AddAdminUser
          showModal={true}
          onCancel={closeAddAdminUserModal}
          updateAdminUserTable={updateAdminUserTable}
          fromDashboard={false}
        />
      }
    </div>
  );
};
export default AdminUserTable;

