import { createAction } from "redux-api-middleware";

const protocol = process.env.REACT_APP_PROTOCOL;
const baseUrl = process.env.REACT_APP_PG3_APP_DOMAIN;

export const ADD_ADMIN_USER_REQUEST = "ADD_ADMIN_USER_REQUEST";
export const ADD_ADMIN_USER_SUCCESS = "ADD_ADMIN_USER_SUCCESS";
export const ADD_ADMIN_USER_FAILURE = "ADD_ADMIN_USER_FAILURE";

export const GET_ADMIN_USERS_REQUEST = "GET_ADMIN_USERS_REQUEST";
export const GET_ADMIN_USERS_SUCCESS = "GET_ADMIN_USERS_SUCCESS";
export const GET_ADMIN_USERS_FAILURE = "GET_ADMIN_USERS_FAILURE";

export const ADMIN_BATCH_FN_REQUEST = "ADMIN_BATCH_FN_REQUEST";
export const ADMIN_BATCH_FN_SUCCESS = "ADMIN_BATCH_FN_SUCCESS";
export const ADMIN_BATCH_FN_FAILURE = "ADMIN_BATCH_FN_FAILURE";

export const GET_COUNTRIES_REQUEST = "GET_COUNTRIES_REQUEST";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_FAILURE = "GET_COUNTRIES_FAILURE";

export const GLOBAL_SEARCH_REQUEST = "GLOBAL_SEARCH_REQUEST";
export const GLOBAL_SEARCH_SUCCESS = "GLOBAL_SEARCH_SUCCESS";
export const GLOBAL_SEARCH_FAILURE = "GLOBAL_SEARCH_FAILURE";

export const getAdminUsers = (token, url) => {
  return createAction({
    endpoint: `${baseUrl}/admin/admin_users?${url}`,
    method: "GET",
    headers: { Authorization: token, "User-Type": "Admin" },
    types: [
      GET_ADMIN_USERS_REQUEST,
      GET_ADMIN_USERS_SUCCESS,
      GET_ADMIN_USERS_FAILURE,
    ],
  });
};

export const addAdminUser = (token, data) => {
  return createAction({
    endpoint: `${baseUrl}/admin/admin_users`,
    body: JSON.stringify(data),
    method: "POST",
    headers: {
      Authorization: token,
      "User-Type": "Admin",
      "Content-Type": "application/json",
    },
    types: [
      ADD_ADMIN_USER_REQUEST,
      ADD_ADMIN_USER_SUCCESS,
      ADD_ADMIN_USER_FAILURE,
    ],
  });
};

export const productionTask = (token, data) => {
  return createAction({
    endpoint: `${baseUrl}/production_task`,
    body: JSON.stringify(data),
    method: "POST",
    headers: {
      Authorization: token,
      "User-Type": "Admin",
      "Content-Type": "application/json",
    },
    types: [
      ADD_ADMIN_USER_REQUEST,
      ADD_ADMIN_USER_SUCCESS,
      ADD_ADMIN_USER_FAILURE,
    ],
  });
};

export const getAdminUser = (token, id) => {
  return createAction({
    endpoint: `${baseUrl}/admin/admin_users/${id}`,
    method: "GET",
    headers: {
      Authorization: token,
      "User-Type": "Admin",
      "Content-Type": "application/json",
    },
    types: [
      ADMIN_BATCH_FN_REQUEST,
      ADMIN_BATCH_FN_SUCCESS,
      ADMIN_BATCH_FN_FAILURE,
    ],
  });
};

export const editAdminUser = (token, id, data) => {
  return createAction({
    endpoint: `${baseUrl}/admin/admin_users/${id}`,
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Authorization: token,
      "User-Type": "Admin",
      "Content-Type": "application/json",
    },
    types: [
      ADMIN_BATCH_FN_REQUEST,
      ADMIN_BATCH_FN_SUCCESS,
      ADMIN_BATCH_FN_FAILURE,
    ],
  });
};

export const resetAdminPassword = (token, ids) => {
  const data = { ids };
  return createAction({
    endpoint: `${baseUrl}/admin/admin_user/reset_password`,
    method: "PATCH",
    body: JSON.stringify(data),
    headers: {
      Authorization: token,
      "User-Type": "Admin",
      "Content-Type": "application/json",
    },
    types: [
      ADMIN_BATCH_FN_REQUEST,
      ADMIN_BATCH_FN_SUCCESS,
      ADMIN_BATCH_FN_FAILURE,
    ],
  });
};

export const archiveAdmins = (token, ids) => {
  const data = {
    ids
  };
  return createAction({
    endpoint: `${baseUrl}/admin/admin_user/archive`,
    body: JSON.stringify(data),
    method: "PATCH",
    headers: { Authorization: token, "User-Type": "Admin", "Content-Type": "application/json" },
    types: [
      ADMIN_BATCH_FN_REQUEST,
      ADMIN_BATCH_FN_SUCCESS,
      ADMIN_BATCH_FN_FAILURE,
    ],
  });
};

export const restoreAdmins = (token, ids) => {
  const data = {
    ids
  };
  return createAction({
    endpoint: `${baseUrl}/admin/admin_user/restore`,
    body: JSON.stringify(data),
    method: "PATCH",
    headers: { Authorization: token, "User-Type": "Admin", "Content-Type": "application/json" },
    types: [
      ADMIN_BATCH_FN_REQUEST,
      ADMIN_BATCH_FN_SUCCESS,
      ADMIN_BATCH_FN_FAILURE,
    ],
  });
};

export const getCountries = (token) => {
  return createAction({
    endpoint: `${baseUrl}/fetch_countries`,
    method: "GET",
    headers: { Authorization: token, "User-Type": "Admin" },
    types: [
      GET_COUNTRIES_REQUEST,
      GET_COUNTRIES_SUCCESS,
      GET_COUNTRIES_FAILURE,
    ],
  });
};

// Global Search
export const getPractitionerAndPatientForGS = (data, token) => {
  return createAction({
    endpoint: `${baseUrl}/admin/universal_search?search=${data}`,
    // body:JSON.stringify({search:data}),
    method: "GET",
    headers: { Authorization: token, "User-Type": "Admin" },
    types: [
      GLOBAL_SEARCH_REQUEST,
      GLOBAL_SEARCH_SUCCESS,
      GLOBAL_SEARCH_FAILURE,
    ],
  });
};
