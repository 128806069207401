import React, { useState, useEffect } from "react";
import WhyPGImage from "../../../assets/static/dpg_9.png";
import HTGSImage1 from "../../../assets/static/htgs_1.svg";
import HTGSImage2 from "../../../assets/static/htgs_2.svg";
import HTGSImage3 from "../../../assets/static/htgs_3.svg";
import ExternalSitePrompt from "../ExternalSitePrompt";
import { useTranslation } from "react-i18next";


// rebranding
import PHTGS_Image1 from '../../../assets/pg4/rebranding/PHTGS_Image1.jpg'


function PatientGetStarted() {
  const [showExternalSitePrompt, setShowExternalSitePrompt] = useState(false);
  const [externalSiteLink, setExternalSiteLink] = useState("");
  const { t, i18n } = useTranslation();
  const enableV2Nordic = process.env.REACT_APP_ENABLE_V2_NORDIC;

  return (
    <div className="my-5 py-5">
      {showExternalSitePrompt && (
        <ExternalSitePrompt
          userType="Patient"
          showModal={showExternalSitePrompt}
          externalSiteLink={externalSiteLink}
          onCancel={() => setShowExternalSitePrompt(false)}
        />
      )}
      <div className="container-xxl my-lg-5 my-2 px-0">
        <div className="row justify-content-lg-around justify-content-center px-0">
          <div className="col-11 col-lg-4 order-lg-last">
            <div className="pgs-title textc-primary__p d-lg-none">
              <span dangerouslySetInnerHTML={{ __html: t('get_started_pat_h1') }} />
            </div>
            <img src={i18n.language === "en_gb" ? WhyPGImage : PHTGS_Image1 } alt="Humane using Phone" className="my-4 my-md-0 w-100" />
          </div>
          <div className="col-11 col-lg-7 my-lg-auto">
            <div className="pgs-title textc-primary__p d-none d-lg-block">
              <span dangerouslySetInnerHTML={{ __html: t('get_started_pat_h1') }} />
            </div>
            <div className="pgs-heading textc-primary__p">
              {t('get_started_pat_txt1')}
            </div>
            <div className="pgs-text">
              <span dangerouslySetInnerHTML={{ __html: t('getstartedPat_p1') }} />
            </div>
          </div>
        </div>

        <div className="row justify-content-lg-around justify-content-center mt-lg-5 pt-lg-5 mt-2 pt-2">
          <div className="col-11 col-lg-5 my-lg-5 my-5">
            <div className="d-lg-flex">
              <img
                src={HTGSImage1}
                alt=""
                className="pe-0 img-fluid pe-lg-5 my-3"
              />
              <div className="pgs-heading textc-primary__p  d-block">
                <span dangerouslySetInnerHTML={{ __html: t('get_started_pat_step1') }} />
              </div>
            </div>
            <div className="pgs-text my-2">
              <span dangerouslySetInnerHTML={{ __html: t('getstartedPat_row2_l1_p1') }} />
            </div>
          </div>
          <div className="col-11 col-lg-5 my-lg-5 my-2">
            <div className="d-lg-flex">
              <img
                src={HTGSImage2}
                alt=""
                className="pe-0 img-fluid pe-lg-5 my-3"
              />
              <div className="pgs-heading textc-primary__p d-block">
                {t('get_started_pat_step2')} <br className="d-none d-lg-block" />
                <span dangerouslySetInnerHTML={{ __html: t('getstartedPat_row2_l2_h1') }} />
              </div>
            </div>
            <div className="pgs-text my-3">
              <span dangerouslySetInnerHTML={{ __html: t('getstartedPat_row2_l2_p1') }} />
            </div>
          </div>
          <div className="col-11 col-lg-5 my-lg-5 my-2">
            <div className="d-lg-flex">
              <img
                src={HTGSImage3}
                alt=""
                className="pe-0 img-fluid  pe-lg-5 my-3"
              />
              <div className="pgs-heading textc-primary__p d-block">
                <span dangerouslySetInnerHTML={{ __html: t('get_started_pat_step3') }} />
              </div>
            </div>
            <div className="pgs-text my-3">
              <span dangerouslySetInnerHTML={{ __html: t('getstartedPat_row2_l3_p1') }} />
            </div>
            <div className="pgs-text my-3">
              {t('get_started_pat_text1')}
            </div>
          </div>
          {i18n.language === "en_gb" && 
						<div className="col-11 col-lg-5 my-lg-5 my-2">
							<div className="d-lg-flex">
								<i class="fas fa-clipboard pe-0 textc-primary__p img-fluid pe-lg-5 my-3" style={{ fontSize: '5rem' }}></i>
								<div className="pgs-heading textc-primary__p d-block">
									{t('get_started_pat_step3_h2')} <br className="d-none d-lg-block" />
									<span dangerouslySetInnerHTML={{ __html: t('get_started_pat_step3_t1') }} />
								</div>
							</div>
							<div className="pgs-text my-3">
								<span dangerouslySetInnerHTML={{ __html: t('get_started_pat_step3_t2') }} />
							</div>
						</div>
					}
        </div>
        {/* disabling this blocks as these may be needed in version 2 for nordic */}
        {(i18n.language === "en_gb" && enableV2Nordic == 'true') &&
          <div className="row">
            <div className="col-11 col-lg-5 mb-lg-5 pb-lg-5 mb-2 pb-2 mx-lg-5 mx-4">
              <div className="d-lg-flex">
                <i class="fas fa-dna pe-0 textc-primary__p img-fluid pe-lg-5 my-3" style={{ fontSize: '5rem' }}></i>
                <div className="pgs-heading textc-primary__p d-block">
                  {t('get_started_pat_optional_h1')} <br className="d-none d-lg-block" />
                  <span dangerouslySetInnerHTML={{ __html: t('get_started_pat_optional_t1') }} />
                </div>
              </div>
              <div className="pgs-text my-3">
                <span dangerouslySetInnerHTML={{ __html: t('get_started_pat_optional_t2') }} />
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
}

export default PatientGetStarted;
