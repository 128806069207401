import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";

function PgMultiSelect({
  setSelectedItem,
  selectedItem,
  placeholder,
  options = [],
  error, errorMessage, id, label, required, showLabel, existingSelections
}) {
  console.log(existingSelections, 'existingSelections')
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    setSelectedOptions(existingSelections)
  });

  return (
    <div className="my-4">
      {
        showLabel && 
        <div>

          <div className="d-inline-block pg-text__bold textc-primary">{label}</div> {required && <span className="textc-primary fst-italic">(Required)</span>}
        </div>
      }
      
      <div className="my-3">
        <div>
          {selectedOptions && selectedOptions.map((item, index) => (
            <div key={index} className="chip">
              <div className="chip-content">{item}</div>
              <div className="chip-close" 
                onClick={() => {
                  setSelectedOptions(selectedOptions.filter(option => option != item))
                  setSelectedItem(selectedOptions.filter(option => option != item))
                  }}>
                <svg className="chip-svg" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path></svg>
              </div>
            </div>
          ))}
        </div>
      </div>
      
      <div className="cursor-pointer">
        <Dropdown
          className="d-inline-block position-relative w-100"
          onToggle={() => setIsPopupOpen(!isPopupOpen)}
          id={id}
        >
          <Dropdown.Toggle
            as="div"
            bsPrefix="border-radius py-auto pg-text d-flex justify-content-between"
            style={{
              border: error ? "2px solid #f00" : "3px solid #acd4ff",
              borderRadius: "8px",
              height: 50,
              padding: "6px 10px",
              width: "100%",
            }}
          >
            <span
              id="selected-item"
              className="my-auto"
              style={{ color: selectedItem ? "#000" : "grey" }}
            >
              {selectedItem || placeholder}
            </span>{" "}
            <i
              className="fa fa-chevron-down my-auto transition-all fs-4"
              aria-hidden="true"
              style={{
                transform: isPopupOpen ? "rotate(-180deg)" : "rotate(0deg)",
              }}
            ></i>
          </Dropdown.Toggle>
          <Dropdown.Menu
            bsPrefix="dropdown-menu w-100 border-radius"
            style={{
              borderRadius: 8,
              background: "white",
              zIndex: 9999,
              opacity: 1,
              maxHeight: "300px",
              overflowY: "auto"
            }}
          >
            {options.map((option, i) => {
              return (
                <Dropdown.Item
                  eventKey="1"
                  onClick={() => {
                    if(!selectedOptions.includes(option)){
                      //debugger
                      setSelectedOptions([...selectedOptions, option])
                      setSelectedItem([...selectedOptions, option])}  
                    }
                  }
                  key={i}
                  className="pg-text text-dark"
                  id={`select-option__${option}`}
                >
                  {option}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
        <div
          className="text-danger mx-2 my-1 transition-all"
          style={error ? { opacity: 1 } : { opacity: 0 }}
        >
          <i className="fas fa-info-circle"></i> {errorMessage}
        </div>
      </div>
    </div>
  );
  //   </div>;
}

export default PgMultiSelect;
