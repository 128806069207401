import React, { useState } from "react";
import WYGImage_1 from "../../../assets/static/wyg_1.webp";
import WYGImage_2 from "../../../assets/static/wyg_2.svg";
import WYGImage_3 from "../../../assets/static/wyg_3.svg";
import WYGImage_4 from "../../../assets/static/wyg_4.svg";
import WYGImage_5 from "../../../assets/static/wyg_5.svg";
import WYGImage_6 from "../../../assets/static/wyg_6.webp";
import ExternalSitePrompt from "../ExternalSitePrompt";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";


function WhatYouGet() {
  const [showExternalSiteModal, setShowExternalSiteModal] = useState(false);
  const [externalSiteURL, setExternalSiteURL] = useState("");
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="my-5 py-3">
      <div className="container-xxl px-0 px-md-3">
        {showExternalSiteModal && (
          <ExternalSitePrompt
            showModal={showExternalSiteModal}
            externalSiteLink={externalSiteURL}
            onCancel={() => setShowExternalSiteModal(false)}
          />
        )}
        <div className="row justify-content-lg-between justify-content-center">
          <div className="col-11 col-lg-4 my-3 my-lg-auto">
            <span dangerouslySetInnerHTML={{ __html: t('wht_u_get_main_h1') }} />
            <div className="pgs-text">
              <span dangerouslySetInnerHTML={{ __html: t('wht_u_get_p1') }} />
            </div>
          </div>
          <div className="col-12 col-lg-6 px-0">
            <img
              src={WYGImage_1}
              alt=""
              className="w-100 px-md-5 px-lg-0 mx-lg-0 px-0"
            />
          </div>
        </div>
        <div className="pgs-heading fw-bold textc-primary__p my-1 mt-5 px-3 px-lg-0">
          <span dangerouslySetInnerHTML={{ __html: t('wht_u_get_row2_h1') }} />
        </div>
        <div className="row justify-content-lg-between justify-content-center">
          <div className="col-11 col-lg-5 my-2 ">
            <img src={WYGImage_2} alt="Report Image" className="my-3" />
            <div className="pgs-text">
              <span dangerouslySetInnerHTML={{ __html: t('wht_u_get_section_1') }} />
            </div>
          </div>
          <div className="col-11 col-lg-5 my-2">
            <img src={WYGImage_3} alt="Report Image" className="my-3" />
            <div className="pgs-text">
              <span dangerouslySetInnerHTML={{ __html: t('wht_u_get_section_2') }} />
            </div>
          </div>
          <div className="col-11 col-lg-5 my-2">
            <img src={WYGImage_4} alt="Report Image" className="my-3" />
            <div className="pgs-text">
              <span dangerouslySetInnerHTML={{ __html: t('wht_u_get_section_3') }} />
            </div>
          </div>
          <div className="col-11 col-lg-5 my-2">
            <img src={WYGImage_5} alt="Report Image" className="my-3" />
            <div className="pgs-text">
              <span dangerouslySetInnerHTML={{ __html: t('wht_u_get_txt1_section_4') }} />{" "}
              <span
                className="text-decoration-underline text-primary cursor-pointer text-dark"
                onClick={() => {
                  setExternalSiteURL(t('pure_url'));
                  setShowExternalSiteModal(true);
                }}
              >
                { t('wht_u_get_txt2_section_4') }
              </span>
              <sup>&reg;</sup> { t('wht_u_get_txt3_section_4') }
            </div>
          </div>
        </div>
        <div className="row justify-content-lg-between justify-content-center my-5">
          <div className="col-12 col-lg-6 px-0">
            <img src={WYGImage_6} alt="Get Started Image" className="w-100" />
          </div>
          <div className="col-11 col-lg-5 my-auto">
            <span dangerouslySetInnerHTML={{ __html: t('wht_u_get_section_5') }} />
            <button
              className="btn btn-primary__p w-100"
              onClick={() => navigate("/patient/how-to-get-started")}
            >
              { t('wht_u_get_btn_1') }
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhatYouGet;
