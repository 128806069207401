import React, { Component, useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import toastr from "react-hot-toast";
import _ from "lodash";

import PgSelect from "../../PgSelect";
import TextInput from "../../TextInput";
import { validateEmail } from "../../../methods";
import { getPractitionerTypes } from "../../../store/actions/adminPractitioner";
import { getCountries } from "../../../store/actions/adminUser";
import { addPractitioner } from "../../../store/actions/auth";
import { PgRadioButton } from "../../PgRadioButton";
import { useNavigate } from "react-router-dom";

// import TermsAndConditions from "../../../Pages/Practice/TermsAndConditions"

import AccoutCreated from "../../../assets/static/created.svg";
import { Spinner } from "react-bootstrap";
import ButtonWithLoader from "../../ButtonWithLoader";
import { useTranslation } from "react-i18next";
import {
  licenseNotMandatoryTypes,
  licenseNotNeededTypes,
  stateList,
  caStateList,
} from "../../../constant";
import PureAccountModelVd from "../../PureAccountModelVd";
import useLanguageConditions from "../../../hooks/useLanguageConditions";

function Registration({ history }) {
  const { t, i18n } = useTranslation();
  const { isCA, isUK, isUS } = useLanguageConditions();

  const navigate = useNavigate();

  // redux state variable
  const practitionersTypeList = useSelector(
    (state) => state.adminPractitioner.practitioner_types
  );
  const dispatch = useDispatch();
  // redux actions
  const addPractitionerAction = (data) => dispatch(addPractitioner(data));
  const getPractitionerTypesAction = () => dispatch(getPractitionerTypes());

  const [countryList, setCountryList] = useState([]);
  const [isValidForm, setIsValidForm] = useState(false);
  //form fields
  const [fName, setfName] = useState("");
  const [lName, setlName] = useState("");
  const [email, setEmail] = useState("");
  const [pureAccountNumber, setPureAccountNumber] = useState("");
  const [healthcareProviderType, setHealthcareProviderType] = useState("");
  const [otherHealthcareProviderType, setOtherHealthcareProviderType] =
    useState("");
  const [license, setLicense] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("US");
  const [userState, setUserState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [phone, setPhone] = useState("");
  const [fax, setFax] = useState("");
  const [practiceName, setPracticeName] = useState("");
  const [showAfterPracticeCreation, setshowAfterPracticeCreation] =
    useState(false);
  // const [terms, setTerms] = useState(false);
  // const [
  //   showTermsAndConditionsModal,
  //   setShowTermsAndConditionsModal,
  // ] = useState(false);

  //for validation
  const [fNameError, setfNameError] = useState(false);
  const [lNameError, setlNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [healthcareProviderTypeError, setHealthcareProviderTypeError] =
    useState("");
  const [
    otherHealthcareProviderTypeError,
    setOtherHealthcareProviderTypeError,
  ] = useState("");
  const [licenseError, setLicenseError] = useState(false);
  const [address1Error, setAddress1Error] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [zipcodeError, setZipcodeError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [disableLicense, setDisableLicense] = useState(false);
  const [requireLicense, setRequireLicense] = useState(false);

  const [practititionerTypesList, setPractititionerTypesList] = useState([]);
  const [practitionerTypeId, setPractitionerTypeId] = useState("");
  const [loading, setLoading] = useState(false);

  const [pureAccountModal, setPureAccountModal] = useState(isUS);
  const [disableFld, setDisableFld] = useState(false);
  const [pureAccountEmail, setPureAccountEmail] = useState("");

  const autoFillRegistrationData = (pureAccountDetails) => {
    setfName(pureAccountDetails?.result?.firstName);
    setlName(pureAccountDetails?.result?.lastName);
    setEmail(pureAccountDetails?.result?.email);
    setPureAccountEmail(pureAccountDetails?.result?.email);
    setPureAccountNumber(pureAccountDetails?.result?.atriumId);
    // setHealthcareProviderType(pureAccountDetails?.result?.healthcareProviderType);
    setAddress1(pureAccountDetails?.result?.streetAddress1);
    setAddress2(pureAccountDetails?.result?.streetAddress2);
    setAddress3(pureAccountDetails?.result?.streetAddress3);
    setCity(pureAccountDetails?.result?.city);
    // setCountry(pureAccountDetails?.result?.country);
    setUserState(pureAccountDetails?.result?.state);
    setZipcode(pureAccountDetails?.result?.zipCode);
    setPhone(pureAccountDetails?.result?.phoneNumber);
    setPracticeName(pureAccountDetails?.result?.practiceName);
    setDisableFld(true);
  };

  console.log(i18n.language, "langauge ");
  useEffect(() => {
    getPractitionerTypesAction().then((response) => {
      if (!response.error) {
        const practitionerTypes = response.payload.practitioner_types;
        if (isUK) {
          setPractititionerTypesList(
            _.filter(practitionerTypes, ["regions", ["UK/EU"]]).map(
              (a) => a.practitioner_type
            )
          );
        } else if (isCA) {
          setPractititionerTypesList(
            _.filter(practitionerTypes, ["regions", ["CA"]]).map(
              (a) => a.practitioner_type
            )
          );
        } else {
          setPractititionerTypesList(
            _.filter(practitionerTypes, ["regions", ["US"]]).map(
              (a) => a.practitioner_type
            )
          );
        }
      }
    });
    dispatch(getCountries()).then((response) => {
      if (!response.error) {
        var availableCountries = response.payload?.countries;
        if (isUK) {
          setCountry("");
          _.remove(availableCountries, function (n) {
            return n === "United States";
          });
          _.remove(availableCountries, function (n) {
            return n === "Canada";
          });
        } else if (isCA) {
          setCountry("Canada");
        } else {
          setCountry("United States");
        }
        setCountryList(availableCountries);
      }
    });
  }, []);

  const onDone = () => {
    setfNameError(false);
    setlNameError(false);
    setEmailError(false);
    setHealthcareProviderTypeError(false);
    setLicenseError(false);
    setAddress1Error(false);
    setCityError(false);
    setStateError(false);
    setCountryError(false);
    setZipcodeError(false);
    setPhoneError(false);

    if (email.length === 0 || !validateEmail(email)) setEmailError(true);
    if (fName.length === 0) setfNameError(true);
    if (lName.length === 0) setlNameError(true);
    if (healthcareProviderType.length === 0)
      setHealthcareProviderTypeError(t("prac_reg_health_req_msg"));
    if (address1.length === 0) setAddress1Error(true);
    if (city.length === 0) setCityError(true);
    if (userState.length === 0) setStateError(t("prac_reg_state_req_msg"));
    if (country.length === 0) setCountryError("Country is Required");
    if (zipcode.length === 0) setZipcodeError(true);
    if (phone.length === 0) setPhoneError(true);
    if (
      healthcareProviderType.length !== 0 &&
      otherHealthcareProviderType.length === 0
    )
      setOtherHealthcareProviderTypeError(true);
    if (requireLicense) if (license.length === 0) setLicenseError(true);
    if (
      email.length !== 0 &&
      validateEmail(email) &&
      fName.length !== 0 &&
      lName.length !== 0 &&
      healthcareProviderType.length !== 0 &&
      (requireLicense ? license.length !== 0 : true) &&
      address1.length !== 0 &&
      city.length !== 0 &&
      country.length !== 0 &&
      (userState.length !== 0 || i18n.language === "en_gb") &&
      zipcode.length !== 0 &&
      phone.length !== 0
    ) {
      setIsValidForm(true);
      handleDone();
    } else
      window.scrollTo({
        top: window.innerWidth < 400 ? 550 : 400,
        behavior: "smooth",
      });
  };

  const handleDone = async () => {
    console.log(!!pureAccountNumber && pureAccountNumber !== "");
    console.log("!!pureAccountNumber && pureAccountNumber");    
    let data = {
      user: {
        first_name: _.startCase(fName),
        last_name: lName,
        full_name: `${_.startCase(fName)} ${lName}`,
        email: email.toLowerCase(),
        healthcare_provider: healthcareProviderType,
        other_healthcare_provider: otherHealthcareProviderType,
        license: license,
        address_1: address1,
        address_2: address2,
        address_3: address3,
        city: city,
        country: country,
        state: userState,
        zipcode: zipcode,
        phone_number: phone,
        fax: fax,
        practitioner_type_id: practitionerTypeId,
        enable_ecommerce: false,
      },
      practice_attributes: [
        {
          name: practiceName,
          enable_ecommerce: false,
          rialto_configuration_attributes: {
            enable_e_commerce: false,
            pure_account_number: pureAccountNumber,
            pure_account_email: pureAccountEmail,
            pure_account_completed_at: new Date().toUTCString()
          },
        },
      ],
    };
    setLoading(true);
    const response = await addPractitionerAction(data);
    setLoading(false);
    if (response.error) {
      setIsValidForm(false);
    } else {
      setshowAfterPracticeCreation(true);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      toastr.success(t("prac_reg_new_pract_msg"));
    }
  };

  const getPractitionerTypeId = (type) => {
    const practitionerType = _.find(practitionersTypeList, [
      "practitioner_type",
      type,
    ]);
    setPractitionerTypeId(practitionerType.id);
  };

  const updateProviderType = (option) => {
    getPractitionerTypeId(option);
    setHealthcareProviderType(option);
    if (licenseNotNeededTypes.includes(option)) {
      setRequireLicense(false);
      setDisableLicense(true);
    } else if (licenseNotMandatoryTypes.includes(option)) {
      setRequireLicense(false);
      setDisableLicense(false);
    } else {
      setRequireLicense(true);
      setDisableLicense(false);
    }
  };

  return (
    <div className="my-5">
      <div className="container-xxl">
        {!showAfterPracticeCreation && (
          <>
            <div className="row justify-content-center">
              <div className="col-12 col-lg-10">
                <h1 className="pgs-title mb-3">
                  {t("prac_register_header_1")}{" "}
                  <span
                    dangerouslySetInnerHTML={{ __html: t("puregemonics") }}
                  />
                </h1>
                <div className="pgs-heading textc-primary">
                  {t("prac_register_follow_text")}
                </div>
                <ul className="list reg-list mt-3">
                  <li>
                    {t("prac_register_accout_text")}{" "}
                    <span
                      onClick={() => navigate("/users/login")}
                      className="cp fw-bold text-decoration-underline textc-primary"
                    >
                      {t("prac_register_sign_in_text")}
                    </span>
                  </li>
                  <li>{t("prac_register_info_text")}</li>
                  <li>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t("register_instruction_3"),
                      }}
                    ></span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-lg-6">
                <h3 className="pgs-heading textc-primary mt-5 mb-4 mx-5">
                  {t("prac_register_enter_info_text")}
                </h3>
                <TextInput
                  label={t("prac_register_first_name")}
                  showLabel={true}
                  required={true}
                  id="f-name__addpractitioner"
                  placeholder={t("prac_reg_fname")}
                  value={fName}
                  error={fNameError}
                  type="text"
                  errorMessage={t("prac_reg_fname_req")}
                  inputHandler={(e) => {
                    setfName(e.target.value);
                  }}
                />
                <TextInput
                  label={t("prac_register_last_name")}
                  showLabel={true}
                  required={true}
                  id="l-name__addpractitioner"
                  placeholder={t("prac_reg_lname")}
                  value={lName}
                  error={lNameError}
                  errorMessage={t("prac_reg_lname_req")}
                  inputHandler={(e) => {
                    setlName(e.target.value);
                  }}
                />
                <TextInput
                  label={t("prac_register_email")}
                  showLabel={true}
                  required={true}
                  emailField={true}
                  id="email-input__addpractitioner"
                  placeholder={t("prac_reg_email")}
                  value={email}
                  error={emailError}
                  errorMessage={t("prac_reg_email_req")}
                  inputHandler={(e) => {
                    if (
                      e.target.value.length < 0 &&
                      !validateEmail(e.target.value)
                    ) {
                      setEmailError(true);
                    } else {
                      setEmailError(false);
                      setEmail(e.target.value);
                    }
                  }}
                />
                <TextInput
                  label={t("prac_register_atrium_account_id")}
                  showLabel={true}
                  optional={true}
                  id="puraccountnumber__addpractitioner"
                  placeholder={t("prac_reg_atrium_account_id")}
                  value={pureAccountNumber}
                  errorMessage={t("prac_reg_atrium_account_id_req")}
                  inputHandler={(e) => {
                    // setPureAccountNumber(e.target.value);
                    if (i18n.language !== "en") {
                      setPureAccountNumber(e.target.value);
                    } else {
                      setPureAccountModal(pureAccountNumber === "");
                    }
                  }}
                />
                <PgSelect
                  label={t("prac_register_type")}
                  showLabel={true}
                  required={true}
                  id="providerType__addpractitioner"
                  setSelectedItem={updateProviderType}
                  selectedItem={healthcareProviderType}
                  placeholder={t("prac_reg_hp")}
                  options={practititionerTypesList}
                  error={
                    healthcareProviderType != ""
                      ? ""
                      : healthcareProviderTypeError
                  }
                />
                {healthcareProviderType === "Other" && (
                  <TextInput
                    label={t("prac_register_other")}
                    showLabel={true}
                    required={healthcareProviderType === "Other"}
                    id="other-type__addpractitioner"
                    placeholder={t("prac_reg_ohp")}
                    value={otherHealthcareProviderType}
                    errorMessage={t("prac_reg_ohp_req")}
                    inputHandler={(e) => {
                      setOtherHealthcareProviderType(e.target.value);
                    }}
                  />
                )}
                <TextInput
                  label={t("prac_register_lic")}
                  showLabel={true}
                  required={requireLicense}
                  optional={!requireLicense}
                  isDisable={disableLicense}
                  id="l-license__addpractitioner"
                  placeholder={t("prac_reg_lic")}
                  errorMessage={t("prac_reg_lic_req")}
                  value={license}
                  error={licenseError}
                  inputHandler={(e) => {
                    setLicense(e.target.value);
                  }}
                />
                <TextInput
                  label={t("prac_register_name")}
                  showLabel={true}
                  optional={true}
                  id="practice-name__addpractitioner"
                  placeholder={t("prac_reg_pn")}
                  value={practiceName}
                  inputHandler={(e) => {
                    setPracticeName(e.target.value);
                  }}
                />
                <TextInput
                  label={t("prac_register_adr_1")}
                  showLabel={true}
                  required={true}
                  id="l-address1__addpractitioner"
                  placeholder={t("prac_reg_adr1")}
                  errorMessage={t("prac_reg_adr1_req")}
                  value={address1}
                  error={address1Error}
                  inputHandler={(e) => {
                    setAddress1(e.target.value);
                  }}
                />
                <TextInput
                  label={t("prac_register_adr_2")}
                  showLabel={true}
                  optional={true}
                  id="l-address1__addpractitioner"
                  placeholder={t("prac_reg_adr2")}
                  value={address2}
                  inputHandler={(e) => {
                    setAddress2(e.target.value);
                  }}
                />
                <TextInput
                  label={t("prac_register_adr_3")}
                  showLabel={true}
                  optional={true}
                  id="l-address1__addpractitioner"
                  placeholder={t("prac_reg_adr3")}
                  value={address3}
                  inputHandler={(e) => {
                    setAddress3(e.target.value);
                  }}
                />
                <TextInput
                  label={t("prac_register_city")}
                  showLabel={true}
                  required={true}
                  id="l-city__addpractitioner"
                  placeholder={t("prac_reg_city")}
                  errorMessage={t("prac_reg_city_req")}
                  value={city}
                  error={cityError}
                  inputHandler={(e) => {
                    setCity(e.target.value);
                  }}
                />
                {(isUS || isCA) && (
                  <TextInput
                    label={t("prac_register_country")}
                    showLabel={true}
                    id="l-country__addpractitioner"
                    placeholder={t("prac_reg_country")}
                    value={i18n.language === "en" ? "United States" : "Canada"}
                    isDisable={true}
                    error={countryError}
                    inputHandler={(e) => {
                      setCountry(e.target.value);
                    }}
                  />
                )}

                {isUK && (
                  <PgSelect
                    label={t("prac_register_country")}
                    showLabel={true}
                    required={true}
                    optional={false}
                    id="country__addpractitionerreg"
                    setSelectedItem={setCountry}
                    selectedItem={country}
                    placeholder={t("prac_reg_country")}
                    options={countryList}
                    error={countryError}
                  />
                )}
                {!isUK ? (
                  <PgSelect
                    label={t("state")}
                    showLabel={true}
                    required={true}
                    id="state__addpractitioner"
                    setSelectedItem={setUserState}
                    selectedItem={userState}
                    placeholder={t("prac_reg_state")}
                    options={country === "Canada" ? caStateList : stateList}
                    error={userState !== "" ? "" : stateError}
                  />
                ) : (
                  <TextInput
                    label="County"
                    showLabel={true}
                    optional={true}
                    id="state__addpractitioner"
                    placeholder="Enter County"
                    value={userState}
                    inputHandler={(e) => {
                      setUserState(e.target.value);
                    }}
                  />
                )}
                <TextInput
                  label={t("code")}
                  showLabel={true}
                  required={true}
                  id="zipcode__addpractitioner"
                  placeholder={t("prac_reg_zip")}
                  errorMessage={t("prac_reg_zip_req")}
                  value={zipcode}
                  error={zipcodeError}
                  inputHandler={(e) => {
                    setZipcode(e.target.value);
                  }}
                />
                <TextInput
                  label={t("prac_register_phone")}
                  showLabel={true}
                  required={true}
                  id="phone__addpractitioner"
                  placeholder={t("prac_reg_phone")}
                  value={phone}
                  error={phoneError}
                  errorMessage="Phone Number is Required"
                  inputHandler={(e) => {
                    setPhone(e.target.value);
                  }}
                />
                <TextInput
                  label={t("prac_register_fax")}
                  showLabel={true}
                  optional={true}
                  id="fax__addpractitioner"
                  placeholder={t("prac_reg_fax")}
                  value={fax}
                  inputHandler={(e) => {
                    setFax(e.target.value);
                  }}
                />

                {isUK && (
                  <div className="my-3 pgs-text">
                    Before continuing, please agree to the{" "}
                    <a href="/terms-use" target="_blank">
                      Terms of Service
                    </a>{" "}
                    and that your contact details will be used and shared in
                    confidence with your linked patients, so that you can
                    provide them with personalised DNA-based nutrition advice
                    and to help provide the best possible application
                    experience. You can opt out at any time. For more
                    information, see the PureGenomics®{" "}
                    <a href="/privacy" target="_blank">
                      {" "}
                      Privacy Policy
                    </a>
                    .
                  </div>
                )}

                <ButtonWithLoader
                  className="btn btn-primary text-light px-5 my-1 mx-1  w-100"
                  onClick={onDone}
                  disabled={loading}
                >
                  {isUK ? "I Agree" : t("prac_register_btn")}
                </ButtonWithLoader>
              </div>
            </div>
          </>
        )}
        {showAfterPracticeCreation && (
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8">
              <div className="text-center">
                <img src={AccoutCreated} height="160" width="160" />
              </div>
              <span
                dangerouslySetInnerHTML={{ __html: t("prac_register_message") }}
              ></span>
            </div>
          </div>
        )}
      </div>
      <PureAccountModelVd
        showModal={pureAccountModal}
        onCancel={() => setPureAccountModal(false)}
        token={""}
        currentPracticeID={""}
        currentPractice={""}
        isLogin={false}
        onRefresh={(response) => autoFillRegistrationData(response)}
        enableClose={true} 
      />
    </div>
  );
}

export default Registration;
