import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const DeleteRecommendationModal = ({ showModal, onCancel, onConfirm }) => {
  const { t, i18n } = useTranslation();
  return (
    <Modal
      show={showModal}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={onCancel}
      contentClassName="border-radius p-2"
      className="delete-recommendation-confirmation-modal"
      centered
    >
      {/* <Modal.Header></Modal.Header> */}
      <Modal.Body>
        <b>{t("report_delete_recom_modal_content")}</b>
      </Modal.Body>
      <Modal.Footer>
        <button className="pg4-btn pg4-btn_outline" onClick={onCancel}>
          {t("cancel")}
        </button>
        <button className="pg4-btn pg4-btn_primary" onClick={onConfirm}>
          {t("patient_info_btn")}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteRecommendationModal;
