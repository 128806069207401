import * as admin_patient_constant from "../actions/rialtoManagement";

const initialState = {
  loading: true,
  pure_account_number: "",
  pure_discount: "",
  stripe_account_id: "",
  discount: "",
  enable_e_commerce: false,
  tax_cert_response: [],
  upload_tax_status: ""
};

const rialtoManagement = (state = initialState, action) => {
  switch (action.type) {
    case admin_patient_constant.UPDATE_PURE_NUMBER_REQUEST: {
      return { ...state, loading: true };
    }
    case admin_patient_constant.UPDATE_PURE_NUMBER_SUCCESS: {
      return { ...state, loading: false };
    }
    case admin_patient_constant.UPDATE_PURE_NUMBER_FAILURE: {
      return { ...state, loading: true };
    }
    case admin_patient_constant.UPDATE_DISCOUNT_REQUEST: {
      return { ...state };
    }
    case admin_patient_constant.UPDATE_DISCOUNT_SUCCESS: {
      return { ...state };
    }
    case admin_patient_constant.UPDATE_DISCOUNT_FAILURE: {
      return { ...state };
    }
    case admin_patient_constant.GET_MANAGEMENT_DATA_REQUEST: {
      return { ...state };
    }
    case admin_patient_constant.GET_MANAGEMENT_DATA_SUCCESS: {
      return {
        ...state,
        pure_account_number: action.payload?.pure_account_number,
        pure_discount: action.payload?.pure_discount,
        stripe_account_id: action.payload?.stripe_account_id,
        discount: action.payload?.discount,
        enable_e_commerce: action.payload?.enable_e_commerce
      };
    }
    case admin_patient_constant.GET_MANAGEMENT_DATA_FAILURE: {
      return { ...state };
    }
    case admin_patient_constant.REVOKE_STRIPE_REQUEST: {
      return { ...state };
    }
    case admin_patient_constant.REVOKE_STRIPE_SUCCESS: {
      return { ...state };
    }
    case admin_patient_constant.REVOKE_STRIPE_FAILURE: {
      return { ...state };
    }
    case admin_patient_constant.CANCEL_REVOKE_STRIPE_REQUEST: {
      return { ...state };
    }
    case admin_patient_constant.CANCEL_REVOKE_STRIPE_SUCCESS: {
      return { ...state };
    }
    case admin_patient_constant.CANCEL_REVOKE_STRIPE_FAILURE: {
      return { ...state };
    }
    case admin_patient_constant.STRIPE_AUTO_CONNECT_REQUEST: {
      return { ...state };
    }
    case admin_patient_constant.STRIPE_AUTO_CONNECT_SUCCESS: {
      return { ...state };
    }
    case admin_patient_constant.STRIPE_AUTO_CONNECT_FAILURE: {
      return { ...state };
    }
    case admin_patient_constant.TAX_CERT_REQUEST: {
      return { ...state };
    }
    case admin_patient_constant.TAX_CERT_SUCCESS: {
      return { ...state, tax_cert_response: action.payload };
    }
    case admin_patient_constant.TAX_CERT_FAILURE: {
      return { ...state };
    }

    case admin_patient_constant.UPLOAD_TAX_REQUEST: {
      return { ...state };
    }
    case admin_patient_constant.UPLOAD_TAX_SUCCESS: {
      return { ...state, upload_tax_status: action.payload };
    }
    case admin_patient_constant.UPLOAD_TAX_FAILURE: {
      return { ...state };
    }
    case admin_patient_constant.PAN_REQUEST: {
      return { ...state };
    }
    case admin_patient_constant.PAN_SUCCESS: {
      return { ...state };
    }
    case admin_patient_constant.PAN_FAILURE: {
      return { ...state };
    }
    default:
      return state;
  }
};

export default rialtoManagement;
