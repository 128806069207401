import { Modal } from "react-bootstrap";

import { useTranslation } from "react-i18next";
function EditPatientUploadModal({ onSubmit, onCancel, showModal }) {
  const { t, i18n } = useTranslation();

  return (
    <Modal
      show={showModal}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onCancel}
      contentClassName="border-radius"
    >
      <Modal.Body>
        <div className="pg-link fw-bold" dangerouslySetInnerHTML={{ __html: t('pracpl_epum_line_1') }} />
      </Modal.Body>
      <Modal.Footer>
        <button className="btn bg-none text-dark px-5" onClick={onCancel}>{t('cancel')}</button>
        <button className="btn bgc-primary text-light px-5" onClick={onSubmit}>{t('send')}</button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditPatientUploadModal;
