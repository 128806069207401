import { createAction } from "redux-api-middleware";

export const GET_PRACTITIONER_DETAILS_REQUEST =
  "GET_PRACTITIONER_DETAILS_REQUEST";
export const GET_PRACTITIONER_DETAILS_SUCCESS =
  "GET_PRACTITIONER_DETAILS_SUCCESS";
export const GET_PRACTITIONER_DETAILS_FAILURE =
  "GET_PRACTITIONER_DETAILS_FAILURE";

export const EDIT_PRACTITIONER_REQUEST = "EDIT_PRACTITIONER_REQUEST";
export const EDIT_PRACTITIONER_SUCCESS = "EDIT_PRACTITIONER_SUCCESS";
export const EDIT_PRACTITIONER_FAILURE = "EDIT_PRACTITIONER_FAILURE";

export const GET_PRACTITIONER_TYPES_REQUEST = "GET_PRACTITIONER_TYPES_REQUEST";
export const GET_PRACTITIONER_TYPES_SUCCESS = "GET_PRACTITIONER_TYPES_SUCCESS";
export const GET_PRACTITIONER_TYPES_FAILURE = "GET_PRACTITIONER_TYPES_FAILURE";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const GET_PRACTITIONER_TERMS_REQUEST = "GET_PRACTITIONER_TERMS_REQUEST";
export const GET_PRACTITIONER_TERMS_SUCCESS = "GET_PRACTITIONER_TERMS_SUCCESS";
export const GET_PRACTITIONER_TERMS_FAILURE = "GET_PRACTITIONER_TERMS_FAILURE";

export const getPractitionerDetails = (
  token,
  currentPracticeID,
  currentPractice,
  id
) => {
  const protocol = process.env.REACT_APP_PROTOCOL;
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;

  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/users/${id}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      GET_PRACTITIONER_DETAILS_REQUEST,
      GET_PRACTITIONER_DETAILS_SUCCESS,
      GET_PRACTITIONER_DETAILS_FAILURE,
    ],
  });
};

export const editPractitioner = (
  token,
  currentPracticeID,
  currentPractice,
  id,
  data
) => {
  const protocol = process.env.REACT_APP_PROTOCOL;
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;

  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/users/${id}`,
    body: JSON.stringify(data),
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      EDIT_PRACTITIONER_REQUEST,
      EDIT_PRACTITIONER_SUCCESS,
      EDIT_PRACTITIONER_FAILURE,
    ],
  });
};

export const getPractitionerTypes = (
  token,
  currentPracticeID,
  currentPractice
) => {
  const protocol = process.env.REACT_APP_PROTOCOL;
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;

  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/fetch_practitioner_types`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      GET_PRACTITIONER_TYPES_REQUEST,
      GET_PRACTITIONER_TYPES_SUCCESS,
      GET_PRACTITIONER_TYPES_FAILURE,
    ],
  });
};

export const changeUserPassword = (
  token,
  currentPracticeID,
  currentPractice,
  id,
  data
) => {
  const protocol = process.env.REACT_APP_PROTOCOL;
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;

  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/update_user_password`,
    body: JSON.stringify(data),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      CHANGE_PASSWORD_REQUEST,
      CHANGE_PASSWORD_SUCCESS,
      CHANGE_PASSWORD_FAILURE,
    ],
  });
};

export const getPractitionerTerms = (
  token,
  currentPracticeID,
  currentPractice
) => {
  const protocol = process.env.REACT_APP_PROTOCOL;
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;

  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/terms_and_conditions/${currentPracticeID}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      GET_PRACTITIONER_TERMS_REQUEST,
      GET_PRACTITIONER_TERMS_SUCCESS,
      GET_PRACTITIONER_TERMS_FAILURE,
    ],
  });
};
