import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const useLangSpecificConst = () => {
  const { t, i18n } = useTranslation();

  const [languageOptions, setLanguageOptions] = useState([
    { label: t("english"), value: "ca" },
    { label: t("french"), value: "fr" },
  ]);
  const [genderOptions, setGenderOptions] = useState([
    { label: t("male"), value: "Male" },
    { label: t("femal"), value: "Female" },
    { label: t("intersex"), value: "Intersex" },
  ]);
  const [skinOptions, setSkinOptions] = useState([
    { label: t("dark"), value: "Dark" },
    { label: t("medium"), value: "Medium" },
    { label: t("light"), value: "Light" },
  ]);

  // Function to dynamically generate the language options
  const getCALanguageOptions = () => [
    { label: t("english"), value: "ca" },
    { label: t("french"), value: "fr" },
  ];

  // Function to dynamically generate gender options
  const getGenderOptions = () => [
    { label: t("male"), value: "Male" },
    { label: t("femal"), value: "Female" },
    { label: t("intersex"), value: "Intersex" },
  ];

  // Function to dynamically generate skin options
  const getSkinOptions = () => [
    { label: t("dark"), value: "Dark" },
    { label: t("medium"), value: "Medium" },
    { label: t("light"), value: "Light" },
  ];

  useEffect(() => {
    setLanguageOptions(getCALanguageOptions());
    setGenderOptions(getGenderOptions());
    setSkinOptions(getSkinOptions());

    const handleLanguageChange = () => {
      setLanguageOptions(getCALanguageOptions());
      setGenderOptions(getGenderOptions());
      setSkinOptions(getSkinOptions());
    };
    handleLanguageChange();
  }, [i18n.language]);

  return {
    languageOptions,
    genderOptions,
    skinOptions,
  };
};

export default useLangSpecificConst;
