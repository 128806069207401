//  MSQQuestions.jsx
import _ from "lodash";
import L_MSQQuestions from "./L_MSQQuestions";
import HeightInput from "./QuestionTypes/HeightInput";
import MultiRadioSelection from "./QuestionTypes/MultiRadioSelection";
import MultiTextInput from "./QuestionTypes/MultiTextInput";
import NumberInput from "./QuestionTypes/NumberInput";
import RadioSelection from "./QuestionTypes/RadioSelection";
import { QuestionTypes } from "./constant";
import { useEffect, useState } from "react";
import RadioVariantSelection from "./QuestionTypes/RadioVariantSelection";
import HourSelect from "./QuestionTypes/HourSelect";
import { useSelector } from "react-redux";
import IntervalDropdown from "./QuestionTypes/IntervalDropdown";
import MultiIntervalDropdown from "./QuestionTypes/MultiIntervalDropdown";

const MSQQuestions = ({
  questionsData,
  onInput,
  errors,
  isLoading,
  inputValues,
}) => {
  const questionsSection = useSelector((state) => state.pg4.q_current_section);

  return (
    <div>
      {isLoading ? (
        <L_MSQQuestions />
      ) : (
        <>
          {questionsSection?.questions?.map((q, index) => {
            const question = q,
              q_index = q.question_id + "_" + index;
            if (question?.sub_questions?.length > 0) {
              return (
                <MultiRadioSelection
                  question={question}
                  onInput={onInput}
                  key={q_index}
                />
              );
            }
            if (question?.question_type === QuestionTypes.NUMBER)
              return (
                <NumberInput
                  question={question}
                  onInput={onInput}
                  key={q_index}
                />
              );
            if (question?.question_type === QuestionTypes.MULTI_TEXT)
              return (
                <MultiTextInput
                  question={question}
                  onInput={onInput}
                  key={q_index}
                />
              );
            if (question?.question_type === QuestionTypes.INTERVAL_DROPTDOWN)
              return (
                <IntervalDropdown
                  question={question}
                  onInput={onInput}
                  key={q_index}
                />
              );
            if (
              question?.question_type === QuestionTypes.MULTI_INTERVAL_DROPTDOWN
            )
              return (
                <MultiIntervalDropdown
                  question={question}
                  onInput={onInput}
                  key={q_index}
                />
              );
            if (question?.question_type === QuestionTypes.HOUR)
              return (
                <HourSelect
                  question={question}
                  onInput={onInput}
                  key={q_index}
                />
              );
            if (question?.question_type === QuestionTypes.RADIO_VARIANT)
              return (
                <RadioVariantSelection
                  question={question}
                  onInput={onInput}
                  key={q_index}
                />
              );
            if (question?.question_type === QuestionTypes.MULTITEXT)
              return (
                <MultiTextInput
                  question={question}
                  onInput={onInput}
                  key={q_index}
                />
              );
            if (question?.question_type === QuestionTypes.RADIO)
              return (
                <RadioSelection
                  question={question}
                  onInput={onInput}
                  key={q_index}
                />
              );
          })}
        </>
      )}
    </div>
  );
};

export default MSQQuestions;
