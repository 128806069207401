import React, { useMemo } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import _ from "lodash";
import NewBadgeIcon from "../../assets/newBadgeIcon.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function PastReportTable({ error, past_reports, patient_id, updatePatientReport }) {
const navigate = useNavigate();
  const goToReportPage = (reportId, pg2_report_url) => {
    if (!_.isEmpty(pg2_report_url)) {
      window.open(pg2_report_url)
    } else {
      updatePatientReport({
        currentPatientId: patient_id,
        currentReportId: reportId,
      });
     navigate("/patient/report");
    }
  };
  const { t, i18n } = useTranslation();
  const column = [
    {
      Header: t('patient_dashboard_txt2'),
      accessor: "genetic_uploaded_at",
    },
    {
      Header: t('genetics'),
      accessor: "genetic_report_name",
      Cell: (value) => {
        const data = value
        return (
          <div dangerouslySetInnerHTML={{ __html: data.value }} />
        );
      },
    },
    {
      Header: t('questionnaire'),
      accessor: "questionnaire_status",
    },
    {
      Header: " ",
      accessor: "report_id",
      Cell: (value) => {
        const data = value?.row?.original;
        if (data.genetic_report_name == "Error") {
          return ( "" );
        } else {
          return (
            <button
              className="btn btn-primary__p my-0 py-0 ms-2 past-report-btn"
              onClick={() => goToReportPage(value.value, data.pg2_report_url)}
            >
              { t('patient_view_report_btn') }
            </button >
          );
        }
      },
    },
  ];

const columns = useMemo(() => column, []);
const data = useMemo(() => past_reports, [past_reports]);

const {
  getTableProps,
  getTableBodyProps,
  headerGroups,
  page,
  nextPage,
  canNextPage,
  previousPage,
  canPreviousPage,
  pageOptions,
  pageCount,
  setPageSize,
  state: { pageIndex, pageSize },
  prepareRow,
} = useTable(
  {
    columns,
    data,
  },
  useSortBy,
  usePagination
);

return (
  <table {...getTableProps()} className="pg-table past-report-table">
    <thead>
      {headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => (
            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
              {column.render("Header")}
              <span className="fs-4">
                {column.isSorted ? (column.isSortedDesc ? "↓" : "↑") : ""}{" "}
              </span>
            </th>
          ))}
        </tr>
      ))}
    </thead>
    {error && (
      <tbody>
        <tr>
          <td colSpan="3">
            <div className="w-100 text-center">
              { t('patient_rep_list_msg1') }
            </div>
          </td>
        </tr>
      </tbody>
    )}
    {past_reports.length < 1 && !error && (
      <tbody>
        <tr>
          <td colSpan="3">
            <div className="w-100 text-center">{ t('patient_rep_err2') }</div>
          </td>
        </tr>
      </tbody>
    )}
    {past_reports.length > 0 && !error && (
      <tbody {...getTableBodyProps()}>
        {page.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    )}
  </table>
);
}
