import { createAction } from "redux-api-middleware";

//  WS -> Welcome Screen
export const PATIENT_WS_DATA_REQUEST = "PATIENT_WS_DATA_REQUEST";
export const PATIENT_WS_DATA_SUCCESS = "PATIENT_WS_DATA_SUCCESS";
export const PATIENT_WS_DATA_FAILURE = "PATIENT_WS_DATA_FAILURE";

export const WELCOME_SHOWN_REQUEST = "WELCOME_SHOWN_REQUEST";
export const WELCOME_SHOWN_SUCCESS = "WELCOME_SHOWN_SUCCESS";
export const WELCOME_SHOWN_FAILURE = "WELCOME_SHOWN_FAILURE";

const protocol = process.env.REACT_APP_PROTOCOL;
const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;

export const getPatientWSData = (token, currentPracticeID, currentPractice) => {


    return createAction({
        endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/patient/welcome_dashboard`,
        method: "GET",
        headers: { "Content-Type": "application/json", Authorization: token, 'Current-Practice': currentPracticeID },
        types: [
            PATIENT_WS_DATA_REQUEST,
            PATIENT_WS_DATA_SUCCESS,
            PATIENT_WS_DATA_FAILURE,
        ],
    });
};


export const getPatientTACContent = (token, currentPracticeID, currentPractice) => {

    return createAction({
        endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/patient/terms_and_condition_content/uk_patient_agreement`,
        method: "GET",
        headers: { "Content-Type": "application/json", Authorization: token, 'Current-Practice': currentPracticeID },
        types: [
            WELCOME_SHOWN_REQUEST,
            WELCOME_SHOWN_SUCCESS,
            WELCOME_SHOWN_FAILURE,
        ],
    });
};


export const agreePatientTAC = (token, currentPracticeID, currentPractice, id) => {

    return createAction({
        endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/patient/terms_and_condition/uk_patient_agreement`,
        method: "POST",
        body: JSON.stringify({ id }),
        headers: { "Content-Type": "application/json", Authorization: token, 'Current-Practice': currentPracticeID },
        types: [
            WELCOME_SHOWN_REQUEST,
            WELCOME_SHOWN_SUCCESS,
            WELCOME_SHOWN_FAILURE,
        ],
    });
};

export const updateDOBAndGender = (token, currentPracticeID, currentPractice, pld) => {
    const body = JSON.stringify(pld);
    return createAction({
        endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/update_patient_resource`,
        method: "POST",
        body,
        headers: { "Content-Type": "application/json", Authorization: token, 'Current-Practice': currentPracticeID },
        types: [
            WELCOME_SHOWN_REQUEST,
            WELCOME_SHOWN_SUCCESS,
            WELCOME_SHOWN_FAILURE,
        ],
    });
}
