import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";
import AddPractitioner from "./Practitioner/AddPractitioner";
import AddStaff from "./Staff/AddStaff";
import AddPatient from "./Patient/AddPatient"
import AddAdminUser from "./AdminUser/AddAdminUser"
import { getPractitionersList } from '../../store/actions/adminPractitioner'
import toast from "react-hot-toast";


const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)
  const regions = useSelector(state => state.auth.region)
  const masterAdmin = useSelector(state => state.auth.masterAdmin)
  const [showAddPractitionerModal, setShowAddPractitionerModal] = useState(false)
  const [showAddPatientModal, setShowAddPatientModal] = useState(false)
  const [showAddAdminModal, setShowAddAdminModal] = useState(false)
  const [showAddStaffModal, setShowAddStaffModal] = useState(false)

  const closeAddPractitionerModal = () => {
    setShowAddPractitionerModal(false)
  }

  const closeAddStaffModal = () => {
    setShowAddStaffModal(false)
  }

  const onDownload = () => {
    const baseUrl = process.env.REACT_APP_PG3_APP_DOMAIN;
    fetch(
      `${baseUrl}/admin/user/download_csv.csv`,
      {
        headers: {
          "Content-Type": "application/csv",
          Authorization: token,
          "Current-Type": "AdminUser",
        }
      }
    ).then(response => {
      toast.success(response);
    })
  }

  const closeAddPatientModal = () => {
    setShowAddPatientModal(false)
  }

  const closeAddAdminModal = () => {
    setShowAddAdminModal(false)
  }

  return (
    <div className="container-xl py-5 px-md-2 px-3">
      {/* <div className="mt-3 my-2 pd-pg-title ">Dashboard</div> */}
      <div className="container-xl mt-5 px-0">
        <div className="row g-5">
          <div className="col-12 col-sm-12 col-lg-6">
            <div
              className="d-flex flex-column p-3 px-4"
              style={{ minHeight: 400, backgroundColor: "#14328c" }}
            >
              <div className="flex-grow-1">
                <div className="pg-heading text-white text-center my-2">
                  Practitioners
                </div>
              </div>
              <div className="mt-auto">
                <div className="d-grid gap-3 mx-1 px-1 mx-sm-4 px-sm-5">
                  {(masterAdmin || (regions.includes("Global")) || regions.includes("US")) && <button
                    className="btn btn-light"
                    onClick={() => navigate("/admin/practitioners?migrated=true&region=US")}
                  >
                    Manage US Practitioners
                  </button>}

                  {(masterAdmin || (regions.includes("Global")) || regions.includes("UK/EU")) && <button
                    className="btn btn-light"
                    onClick={() => navigate("/admin/practitioners?migrated=true&region=EU")}
                  >
                    Manage UK/EU Practitioners
                  </button>}

                  {(masterAdmin || (regions.includes("Global")) || regions.includes("CA")) && <button
                    className="btn btn-light"
                    onClick={() => navigate("/admin/practitioners?migrated=true&region=CA")}
                  >
                    Manage CA Practitioners
                  </button>}
                  <button
                    className="btn btn-outline__invert mt-3"
                    id="add-patient-btn__dashboard"
                    onClick={() => setShowAddPractitionerModal(true)}
                  >
                    <i className="fas fa-plus mx-1"></i> Add New Practitioner
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-lg-6">
            <div
              className="d-flex flex-column p-3 px-4"
              style={{ minHeight: 400, backgroundColor: "#14328c" }}
            >
              <div className="flex-grow-1">
                <div className="pg-heading text-white text-center my-2">
                  Patients
                </div>
              </div>
              <div className="mt-auto">
                <div className="d-grid gap-3 mx-1 px-1 mx-sm-4 px-sm-5">
                  {(masterAdmin || (regions.includes("Global")) || regions.includes("US")) && <button
                    className="btn btn-light"
                    onClick={() => navigate("/admin/patients?migrated=true&region=US", {
                      state: {
                        practitionerId: '',
                      }
                    })}
                  >
                    Manage US Patients
                  </button>}
                  {(masterAdmin || (regions.includes("Global")) || regions.includes("UK/EU")) && <button
                    className="btn btn-light"
                    onClick={() => navigate("/admin/patients?migrated=true&region=EU", {
                      state: {
                        practitionerId: '',
                      }
                    })}
                  >
                    Manage UK/EU Patients
                  </button>}

                  {(masterAdmin || (regions.includes("Global")) || regions.includes("CA")) && <button
                    className="btn btn-light"
                    onClick={() => navigate("/admin/patients?migrated=true&region=CA", {
                      state: {
                        practitionerId: '',
                      }
                    })}
                  >
                    Manage CA Patients
                  </button>}
                  <button
                    className="btn btn-outline__invert mt-3"
                    onClick={() => setShowAddPatientModal(true)}
                  >
                    <i className="fas fa-plus mx-1"></i> Add New Patient
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-lg-6">
            <div
              className="d-flex flex-column p-3 px-4"
              style={{ minHeight: 400, backgroundColor: "#14328c" }}
            >
              <div className="flex-grow-1">
                <div className="pg-heading text-white text-center my-2">
                  Admins
                </div>
              </div>
              <div className="mt-auto">
                <div className="d-grid gap-3 mx-1 px-1 mx-sm-4 px-sm-5">
                  <button
                    className="btn btn-light"
                    onClick={() => navigate("/admin/users")}
                  >
                    Manage Admins
                  </button>
                  {masterAdmin && <button
                    className="btn btn-outline__invert mt-3"
                    onClick={() => setShowAddAdminModal(true)}
                  >
                    <i className="fas fa-plus mx-1"></i> Add New Admin
                  </button>}
                </div>
              </div>
            </div>
          </div>
          {/* Report Cotroller */}
          <div className="col-12 col-sm-12 col-lg-6">
            <div
              className="d-flex flex-column p-3 px-4"
              style={{ minHeight: 400, backgroundColor: "#14328c" }}
            >
              <div className="flex-grow-1">
                <div className="pg-heading text-white text-center my-2">
                  Admin Report
                </div>
              </div>
              <div className="mt-auto">
                <div className="d-grid gap-3 mx-1 px-1 mx-sm-4 px-sm-5">
                {/* <button
                  className="btn btn-light px-5 d-block mx-auto my-4"
                  onClick={() => navigate("/admin/report/patient")}
                >
                  View Patient Reports
                </button> */}
                <button
                  className="btn btn-light mt-3"
                  onClick={() => navigate("/admin/report/practitioner")}
                >
                  View Practitioner Reports
                </button>
                {/* <button
                  className="btn btn-outline__invert px-4 d-block mx-auto my-4"
                  onClick={onDownload}
                >
                  Download Reports
                </button> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-lg-6">
            <div
              className="d-flex flex-column p-3 px-4"
              style={{ minHeight: 400, backgroundColor: "#14328c" }}
            >
              <div className="flex-grow-1">
                <div className="pg-heading text-white text-center my-2">
                  Staff Members
                </div>
              </div>
              <div className="mt-auto">
                <div className="d-grid gap-3 mx-1 px-1 mx-sm-4 px-sm-5">
                  {(masterAdmin || (regions.includes("Global")) || regions.includes("US")) && <button
                    className="btn btn-light "
                    onClick={() => navigate("/admin/staffs?migrated=true&region=US")}
                  >
                    Manage US Staff Members
                  </button>}
                  {(masterAdmin || (regions.includes("Global")) || regions.includes("UK/EU")) && <button
                    className="btn btn-light "
                    onClick={() => navigate("/admin/staffs?migrated=true&region=EU")}
                  >
                    Manage UK/EU Staff Members
                  </button>}

                  {(masterAdmin || (regions.includes("Global")) || regions.includes("CA")) && <button
                    className="btn btn-light "
                    onClick={() => navigate("/admin/staffs?migrated=true&region=CA")}
                  >
                    Manage CA Staff Members
                  </button>}
                  <button
                    className="btn btn-outline__invert mt-3"
                    id="add-patient-btn__dashboard"
                    onClick={() => setShowAddStaffModal(true)}
                  >
                    <i className="fas fa-plus mx-1"></i> Add New Staff Member
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* GDPR */}
          {regions && (regions.includes("UK/EU") || (regions.includes("Global"))) && <div className="col-12 col-sm-12 col-lg-6">
            <div
              className="d-flex flex-column p-3 px-4"
              style={{ minHeight: 400, backgroundColor: "#14328c" }}
            >
              <div className="flex-grow-1">
                <div className="pg-heading text-white text-center my-2">
                  Manage List
                </div>
              </div>
              <div className="mt-auto">
                <div className="d-grid gap-3 mx-1 px-1 mx-sm-4 px-sm-5">
                  <button
                    className="btn btn-light "
                    onClick={() => navigate("/admin/gdpr/patient")}
                  >
                    View Patient List
                  </button>
                  <button
                    className="btn btn-light"
                    onClick={() => navigate("/admin/gdpr/practitioner")}
                  >
                    View Practitioner List
                  </button>
                </div>
              </div>
            </div>
          </div>}
        </div>
      </div>
      {
        showAddPractitionerModal &&
        <AddPractitioner
          showModal={true}
          onCancel={closeAddPractitionerModal}
          context="dashboard"
        />
      }
      {
        showAddPatientModal &&
        <AddPatient
          context="dashboard"
          showModal={true}
          onCancel={closeAddPatientModal}
        />
      }
      {
        showAddAdminModal &&
        <AddAdminUser
          showModal={true}
          onCancel={closeAddAdminModal}
          fromDashboard={true}
        />
      }
      {
        showAddStaffModal &&
        <AddStaff
          showModal={true}
          onCancel={closeAddStaffModal}
          context="dashboard"
        />
      }
    </div>
  );
};

export default Dashboard;
