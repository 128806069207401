import * as question_constant from "../actions/questions";

const initialState = {
  loading: true,
  loading_welcome: true,
  loading_questions: true,
  loading_question: true,
  loading_answers: true,
  loading_answer: true,
  loading_session: true,
  error: false,
  question: "",
  newQuestionId: "",
  patientAnswer: [],
  session_status: "No Data",
  session: "",
  questionnaire_status: "Analyzing",
  recomm_report_id: null
};


const getQuestionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case question_constant.GET_QUESTIONS_REQUEST: {
      return { ...state, loading_questions: true };
    }
    case question_constant.GET_QUESTIONS_SUCCESS: {
      console.log(action.payload);
      return { ...state, loading_questions: false };
    }
    case question_constant.GET_QUESTIONS_FAILURE: {
      return { ...state, loading_questions: false, error: true };
    }
    default:
      return state;
  }
};

const getQuestionReducer = (state = initialState, action) => {
  switch (action.type) {
    // welcome question
    case question_constant.GET_WELCOME_REQUEST: {
      return { ...state, loading_welcome: true };
    }
    case question_constant.GET_WELCOME_SUCCESS: {
      return { ...state, loading_welcome: false, session: action.payload?.session, question: action.payload?.question };
    }
    case question_constant.GET_WELCOME_FAILURE: {
      return { ...state, loading_welcome: false, error: true };
    }

    
    // get question
    case question_constant.GET_QUESTION_REQUEST: {
      return { ...state, loading_question: true };
    }
    case question_constant.GET_QUESTION_SUCCESS: {
      return { ...state, loading_question: false, question: action.payload };
    }
    case question_constant.GET_QUESTION_FAILURE: {
      return { ...state, loading_question: false, error: true };
    }
    
    //answer submit
    case question_constant.SUBMIT_ANSWER_REQUEST: {
      return { ...state, loading_answer: true };
    }
    case question_constant.SUBMIT_ANSWER_SUCCESS: {
      return {
        ...state,
        loading_answer: false,
        newQuestionId: action.payload.next_question_id,
      };
    }
    case question_constant.SUBMIT_ANSWER_FAILURE: {
      return { ...state, loading_answer: false, error: true };
    }

    //patient answer
    case question_constant.PATIENT_ANSWERS_REQUEST: {
      return { ...state, loading_answers: true };
    }
    case question_constant.PATIENT_ANSWERS_SUCCESS: {
       return { ...state, loading_answers: false, patientAnswer: action.payload?.questionnaire };
    }
    case question_constant.PATIENT_ANSWERS_FAILURE: {
      return { ...state, loading_answers: false, error: true };
    }

    //update session
    case question_constant.UPDATE_QUESTION_SESSION_REQUEST: {
      return { ...state, loading_answers: true };
    }
    case question_constant.UPDATE_QUESTION_SESSION_SUCCESS: {
      return { ...state, loading_answers: false, session_status: action.payload?.session_status };
    }
    case question_constant.UPDATE_QUESTION_SESSION_FAILURE: {
      return { ...state, loading_answers: false, error: true };
    }
    case question_constant.UPDATE_QUESRIONNAIRE_PROCESS: {
      return {
        ...state,
        recomm_report_id: action.payload.recomm_report_id,
        questionnaire_status: "Analyzing",
      };
    }
    case question_constant.QUESTIONNAIRE_PROCESS_STATUS_REQUEST: {
      return { ...state };
    }
    case question_constant.QUESTIONNAIRE_PROCESS_STATUS_SUCCESS: {
      let questionnaire_status = state.questionnaire_status;
      if (action.payload.current_state) {
          questionnaire_status = action.payload.current_state === 'Processing' ? 'Analyzing' : action.payload.current_state;
      }
      return { ...state, uploadError: !action.payload.success, questionnaire_status, errorText:action.payload?.message };
    }
    case question_constant.QUESTIONNAIRE_PROCESS_STATUS_FAILURE: {
      return { ...state, loading_answers: false, error: true };
    }

    default:
      return state;
  }
};

export default getQuestionReducer;
