import { createAction } from "redux-api-middleware";

export const SNP_UPLOAD_REQUEST = "SNP_UPLOAD_REQUEST";
export const SNP_UPLOAD_SUCCESS = "SNP_UPLOAD_SUCCESS";
export const SNP_UPLOAD_FAILURE = "SNP_UPLOAD_FAILURE";

export const SNP_UPLOAD_STATUS_REQUEST = "SNP_UPLOAD_STATUS_REQUEST";
export const SNP_UPLOAD_STATUS_SUCCESS = "SNP_UPLOAD_STATUS_SUCCESS";
export const SNP_UPLOAD_STATUS_FAILURE = "SNP_UPLOAD_STATUS_FAILURE";

export const UPDATE_SNP_UPLOAD = "UPDATE_SNP_UPLOAD";

export const updateSnpUpload = (pld) => {
  return {
    type: UPDATE_SNP_UPLOAD,
    payload: pld,
  };
};

export const uploadSNPForPractice = (
  token,
  currentPracticeID,
  currentPractice,
  data
) => {
  const protocol = process.env.REACT_APP_PROTOCOL;
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/web/api/send_genetic_file`,
    method: "POST",
    body: data,
    headers: { Authorization: token, "Current-Practice": currentPracticeID },
    types: [SNP_UPLOAD_REQUEST, SNP_UPLOAD_SUCCESS, SNP_UPLOAD_FAILURE],
  });
};

export const getSNPUploadStatus = (
  token,
  currentPracticeID,
  currentPractice,
  data
) => {
  const protocol = process.env.REACT_APP_PROTOCOL;
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;
  const type = data.type;
  const document_id = data.document_id;
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/web/api/get_analyze_status?type=${type}&document_id=${document_id}`,
    method: "GET",
    headers: { Authorization: token, "Current-Practice": currentPracticeID },
    types: [
      SNP_UPLOAD_STATUS_REQUEST,
      SNP_UPLOAD_STATUS_SUCCESS,
      SNP_UPLOAD_STATUS_FAILURE,
    ],
  });
};
