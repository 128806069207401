import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const InputContainer = styled.div`
  width: 100%;
  border: ${(props) => (props.error ? "2px solid #f00" : "3px solid #acd4ff")};
  border-radius: 4px;
  overflow: hidden;
  height: 50px;
  transition: all 0.3s ease-in-out;
  display: flex;
  &:focus-within {
    border: 2px solid #007bff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
`;

const Input = styled.input`
  flex-grow: 1;
  padding: 15px;
  outline: none;
  border: none;
`;

const IconButton = styled.button`
  background: transparent;
  border: none;
  padding: 13px 5px 15px 0;
  text-align: center;
  width: 12%;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

const ErrorMessage = styled.div`
  opacity: ${(props) => (props.error ? 1 : 0)};
  transition: opacity 0.3s;
  color: red;
  margin: 5px 0;
`;

const Label = styled.label`
  font-weight: bold;
  color: var(--primary);
`;

const RequiredText = styled.span`
  font-style: italic;
  color: var(--primary);
`;

export default function PasswordInput({
  inputHandler,
  placeholder,
  error,
  errorMessage,
  id,
  required,
  label,
  value,
  showLabel = false,
}) {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="my-4">
      {showLabel && (
        <div>
          <Label htmlFor={id}>{label}</Label>
          {required && <RequiredText> ({t("required")})</RequiredText>}
        </div>
      )}
      <div>
        <InputContainer error={error}>
          <Input
            placeholder={placeholder}
            type={showPassword ? "text" : "password"}
            onInput={inputHandler}
            id={id}
            value={value}
            defaultValue={value}
            aria-required={true}
            aria-invalid={error}
            aria-describedby={error ? `${id}-error` : null}
          />
          <IconButton
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="password-eye-icon"
            aria-label={showPassword ? "Hide password" : "Show password"}
          >
            <i
              className={showPassword ? "fas fa-eye" : "fas fa-eye-slash"}
              style={{ color: "#000", fontSize: 16 }}
            ></i>
          </IconButton>
        </InputContainer>
        {error && (
          <ErrorMessage id={`${id}-error`} error={error}>
            <i className="fas fa-info-circle"></i> {errorMessage}
          </ErrorMessage>
        )}
      </div>
    </div>
  );
}

PasswordInput.propTypes = {
  inputHandler: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  id: PropTypes.string.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
  showLabel: PropTypes.bool,
};

PasswordInput.defaultProps = {
  placeholder: "",
  error: false,
  errorMessage: "",
  required: false,
  label: "",
  value: "",
  showLabel: false,
};
