import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import _ from "lodash";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import qs from "query-string";
import { initializeI18next } from "../methods";
const REACT_APP_PG3_APP_DOMAIN = process.env.REACT_APP_PG3_APP_DOMAIN;
const UK_TLD = process.env.REACT_APP_UK_TLD;
const CA_TLD = process.env.REACT_APP_CA_TLD;
const cookies = new Cookies();
const obj = qs.parse(window.location.search, { decode: false });

initializeI18next();

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "en",
    fallbackLng: false,
    saveMissing: false,
    initImmediate: true,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    nonExplicitSupportedLngs: true,
    debug: true,
    react: {
      useSuspense: true,
    },
    backend: {
      loadPath: `${REACT_APP_PG3_APP_DOMAIN}/language_text?lang={{lng}}`,
      crossDomain: true,
    },
  });

export default i18n;
