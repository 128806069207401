import React, { useState, useEffect } from "react";

function WelcomeQuestion(props) {
  const welcomeQuestion = props.question;
  const showSpinner = props.showSpinner;
  const submitTextAnswer = (index, question, answer) => {
    props.addtextAnswer(index, question, answer);
  };

  return (
    <div style={{ position: "relative"}}>
      {showSpinner && (
        <div
          className="spinner-border textc-primary text-center mx-3 my-1 welcome-spinner"
          role="status"
          style={{ height: 15, width: 15 }}
        >
          {" "}
        </div>
      )}
      <p className="pg-text">{welcomeQuestion.question}</p>
      <div className="text-center">
        <button
          className="btn bgc-primary text-light"
          onClick={() => submitTextAnswer(props.index, welcomeQuestion, "")}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default WelcomeQuestion;
