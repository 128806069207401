import React, { useState, useEffect } from "react";

import ConsiderAction from "../assets/warning.png";
import EnhanceBenefit from "../assets/enhance.png";

import ErrorIcon from "../assets/help.png";

function ActionImage({ action }) {
  const [currentElement, setCurrentElement] = useState(null);
  useEffect(() => {
    if (action === "consider-action")
      setCurrentElement(<img src={ConsiderAction} alt="Consider Action" />);
    else if (action === "enhanced-benefit")
      setCurrentElement(<img src={EnhanceBenefit} alt="Consider Action" />);
    else setCurrentElement(null)
  }, [action]);
  return <div className="d-inline-block mx-1">{currentElement}</div>;
}

export default ActionImage;
