import React from "react";

export default function ChatTyper() {
  return (
    <div className="is-typing">
      <div className="jump1"></div>
      <div className="jump2"></div>
      <div className="jump3"></div>
      <div className="jump4"></div>
      <div className="jump5"></div>
    </div>
  );
}
