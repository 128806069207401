import React, { Component, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { updateQuestionSession } from "../../../store/actions/questions";
import toast from 'react-hot-toast';


function RadioQuestion({ question, addtextAnswer, index, isEdit, showSpinner, editAnswerText }) {

  // redux state variable
  const currentPractice = useSelector(state => state.auth.currentPractice);
  const currentPracticeID = useSelector(state => state.auth.currentPractice);
  const currentUserId = useSelector(state => state.auth.currentUserId);
  const token = useSelector(state => state.auth.token);

  // redux actions

  const dispatch = useDispatch();
  const updateQuestionSessionAction = (token, currentPracticeID, currentPractice, data) => dispatch(updateQuestionSession(token, currentPracticeID, currentPractice, data));

  const radioQuestion = question;
  const [patientAnswer, setPatientAnswer] = useState([]);
  const [showOK, setShowOK] = useState(false);
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedBgItem, setSelectedBgItem] = useState([]);
  const navigate = useNavigate();
  const lang = useSelector((state) => state.auth.language)
  const language = (lang === undefined ? "en" : lang)

  useEffect(() => {
    if (isEdit) {
      setSelectedBgItem(editAnswerText[0])
      setPatientAnswer(editAnswerText)
      setShowOK(true)
    }
  }, []);

  const setAnswer = (value, index) => {
    setSelectedBgItem(value);
    setPatientAnswer([value]);
    setShowOK(true);
    setTimeout(() => {
      submitRadioAnswer(index, radioQuestion, [value])
    }, 1000);

  };

  const submitRadioAnswer = (index, question, answer) => {
    if (answer[0].trim() === "No, try later") {
      updateQuestionSessionAction(token, currentPracticeID, currentPractice, {
        patient_id: currentUserId,
        status: "pending",
      });
      toast.success("Thank you, you may access and update the questionnaire at any time from the Dashboard.");
      navigate("/patient/dashboard");
    } else {
      radioQuestion.answer = answer;
      addtextAnswer(index, question, answer);
    }
  };

  return (
    <div>
      <div
        className="talk-bubble tri-right round border right-top"
        style={{ position: "relative" }}
      >
        <div className="talktext">
          <p className="pg-text radio-p"
            dangerouslySetInnerHTML={{
              __html: (("question_" + language) in radioQuestion ? radioQuestion["question_" + language] : radioQuestion.question),
            }}
          />
        </div>
      </div>
      <div className="options-wrapper">
        {"answer" in radioQuestion && (
          <div className="talk-bubble tri-right border round btm-left-in answer-bubble pg-text">
            <div className="talktext">
              <p className="pg-text">{radioQuestion.answer}</p>
            </div>
          </div>
        )}
        {!("answer" in radioQuestion) && (
          <div className="choices-wrapper">
            <div className="question-support-text">
              <p>
                {radioQuestion["instructions_" + language]}
              </p>
            </div>
            {JSON.parse(radioQuestion["choices_" + language]).map((option, index) => (
              <div
                className={
                  `talk-bubble tri-right round border radio-options ` +
                  (selectedBgItem === option ? "selected-bg" : "")
                }
                key={index}
              >
                <div
                  className="talktext"
                  onClick={() => setAnswer(option, index)}
                >
                  <p className="pg-text">{option}</p>
                </div>
              </div>
            ))}
          </div>
        )}
        {showSpinner && (
          <div
            className="spinner-border textc-primary text-center mx-3 my-1 questionnaire-spinner"
            role="status"
            style={{ height: 15, width: 15 }}
          >
            {" "}
          </div>
        )}
      </div>
    </div>
  );
}

export default RadioQuestion;
