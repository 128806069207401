import * as admin_practitioner_constant from "../actions/adminPractitioner";

const initialState = {
    loading_practitioners: true,
    practitioners: [],
    total_count: null,
    loading: true,
    loading_practitioners_list: true,
    practitionersList: [],
    loading_practitioner_types: true,
    practitioner_types: []
}

const adminPractitioner = (state = initialState, action) => {
    switch (action.type) {
        case admin_practitioner_constant.GET_PRACTITIONERS_REQUEST: {
            return { ...state, loading_practitioners: true }
        }
        case admin_practitioner_constant.GET_PRACTITIONERS_SUCCESS: {
            return { ...state, loading_practitioners: false, practitioners: action.payload.practitioners, total_count: action.payload.total }
        }
        case admin_practitioner_constant.GET_PRACTITIONERS_FAILURE: {
            return { ...state, loading_practitioners: false }
        }
        case admin_practitioner_constant.ADD_PRACTITIONER_REQUEST: {
            return { ...state, loading: true }
        }
        case admin_practitioner_constant.ADD_PRACTITIONER_SUCCESS: {
            return { ...state, loading: false }
        }
        case admin_practitioner_constant.ADD_PRACTITIONER_FAILURE: {
            return { ...state, loading: true }
        }
        case admin_practitioner_constant.BATCH_FUNCTION_REQUEST: {
            return { ...state, }
        }
        case admin_practitioner_constant.BATCH_FUNCTION_SUCCESS: {
            return { ...state, }
        }
        case admin_practitioner_constant.BATCH_FUNCTION_FAILURE: {
            return { ...state, }
        }
        case admin_practitioner_constant.GET_PRACTITIONERS_LIST_REQUEST: {
            return { ...state, loading_practitioners_list: true }
        }
        case admin_practitioner_constant.GET_PRACTITIONERS_LIST_SUCCESS: {
            return { ...state, loading_practitioners_list: false, practitionersList: action.payload.practitioners }
        }
        case admin_practitioner_constant.GET_PRACTITIONERS_LIST_FAILURE: {
            return { ...state, loading_practitioners_list: true }
        }
        case admin_practitioner_constant.GET_PRACTITIONER_TYPES_REQUEST: {
            return { ...state, loading_practitioner_types: true }
        }
        case admin_practitioner_constant.GET_PRACTITIONER_TYPES_SUCCESS: {
            return { ...state, loading_practitioner_types: false, practitioner_types: action.payload.practitioner_types }
        }
        case admin_practitioner_constant.GET_PRACTITIONER_TYPES_FAILURE: {
            return { ...state, loading_practitioner_types: true }
        }
        default:
            return state;
    }
}

export default adminPractitioner;