import React from "react";
export default function ActionButton({ onCancel, onDone, isDone, t }) {
  return (
    <div className="mt-4 py-3 px-2 px-xl-5 bgc-secondary ">
      <div className="container-xl ">
        <div className="">
          <button
            className="mr-4 btn btn-secondary text-light px-5 my-1"
            onClick={onCancel}
            id="cancel-btn__addpatient"
          >
            {t('cancel')}
          </button>
          <button
            className="btn btn-primary text-light px-5 my-1 mx-1"
            disabled={isDone}
            onClick={onDone}
            id="done-btn__addpatient"
          >
            {t('done')}
          </button>
        </div>
      </div>
    </div>
  );
}
