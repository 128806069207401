import * as pa_constant from "../actions/practitionerAccount";

const initialState = {
  isGettingPractitionerDetail: true,
  PractitionerDetail: "",
  loading_practitioner_types: true,
  practitioner_types: [],
  practitioner_terms: [],
};

const practitionerAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    //practitioner detail
    case pa_constant.GET_PRACTITIONER_DETAILS_REQUEST: {
      return { ...state, isGettingPatientDetail: true, PractitionerDetail: "" };
    }
    case pa_constant.GET_PRACTITIONER_DETAILS_SUCCESS: {
      return {
        ...state,
        isGettingPatientDetail: false,
        PractitionerDetail: action.payload.patient,
      };
    }
    case pa_constant.GET_PRACTITIONER_DETAILS_FAILURE: {
      return { ...state, isGettingPatientDetail: false };
    }

    //practitioner types
    case pa_constant.GET_PRACTITIONER_TYPES_REQUEST: {
      return {
        ...state,
        loading_practitioner_types: true,
        PractitionerDetail: "",
      };
    }
    case pa_constant.GET_PRACTITIONER_TYPES_SUCCESS: {
      return {
        ...state,
        loading_practitioner_types: false,
        practitioner_types: action.payload.practitioner_types,
      };
    }
    case pa_constant.GET_PRACTITIONER_TYPES_FAILURE: {
      return { ...state, loading_practitioner_types: false };
    }

    //edit practitioner
    case pa_constant.EDIT_PRACTITIONER_REQUEST: {
      return { ...state };
    }
    case pa_constant.EDIT_PRACTITIONER_SUCCESS: {
      return { ...state };
    }
    case pa_constant.EDIT_PRACTITIONER_FAILURE: {
      return { ...state };
    }

    //change password
    case pa_constant.CHANGE_PASSWORD_REQUEST: {
      return { ...state };
    }
    case pa_constant.CHANGE_PASSWORD_SUCCESS: {
      return { ...state };
    }
    case pa_constant.CHANGE_PASSWORD_FAILURE: {
      return { ...state };
    }

    //practitioner terms
    case pa_constant.GET_PRACTITIONER_TERMS_REQUEST: {
      return { ...state, practitioner_terms: null };
    }
    case pa_constant.GET_PRACTITIONER_TERMS_SUCCESS: {
      return {
        ...state,
        practitioner_terms: action.payload.terms_and_agreements,
      };
    }
    case pa_constant.GET_PRACTITIONER_TERMS_FAILURE: {
      return { ...state, practitioner_terms: null };
    }
    default:
      return state;
  }
};

export default practitionerAccountReducer;
