import React from 'react'
import Header from '../components/Header'
import HeaderUS from '../components/HeaderUS'
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function HomePageLayout() {
    const { i18n } = useTranslation()
    return (
        <div style={{ paddingTop: 140 }}>
            {i18n.language === 'en' ? <HeaderUS /> : <Header />}
            <Outlet />
        </div>
    )
}

export default HomePageLayout
