import React, { useState, useEffect } from 'react'
import DatePicker from 'react-date-picker'
import ReactDatePicker from 'react-datepicker'
import { formatBirthDate } from '../../../methods'
import 'react-datepicker/dist/react-datepicker.css'
import { useSelector } from "react-redux";

function DateQuestion(props) {
  const dateQuestion = props.question
  const showSpinner = props.showSpinner
  const isEdit = props.isEdit
  const answer = props.editAnswerText
  const [patientAnswer, setPatientAnswer] = useState('')
  const [showOK, setShowOK] = useState(false)
  const [birthDate, setBirthDate] = useState('')
  const [startDate, setStartDate] = useState(new Date())
  const range = (start, end) => {
    return Array.apply(0, Array(end - 1)).map((element, index) => index + start)
  }
  const years = range(1900, new Date().getFullYear() + 1, 1)
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const lang = useSelector((state) => state.auth.language)
  const language = (lang === undefined ? "en" : lang)
  
  useEffect(() => {
    if (isEdit) {
      setPatientAnswer(answer[0])
      setBirthDate(new Date(Date.parse(answer[0])))
      setShowOK(true)
    }
  }, [])

  const setAnswer = (value) => {
    if (value !== null) {
      setBirthDate(value)
      setPatientAnswer(formatBirthDate(value))
      setShowOK(true)
    }
  }

  const submitNumberAnswer = (index, question, answer) => {
    dateQuestion.answer = answer
    props.addtextAnswer(index, question, answer)
    setShowOK(false)
  }

  return (
    <div>
      <div
        className="talk-bubble tri-right round border right-top"
        style={{ position: 'relative' }}
      >
        <div className="talktext">
          <p className="pg-text">
            {
              ("question_" + language) in dateQuestion ? dateQuestion["question_" + language] : dateQuestion.question 
            }
          </p>
        </div>
      </div>
      <div>
        {!('answer' in dateQuestion) && (
          <div>
            <div className="question-support-text">
              <p>{dateQuestion["instructions_" + language]}</p>
            </div>
            {/* <DatePicker
              onChange={(date) => setAnswer(date)}
              value={birthDate}
              dateFormat="MMM dd, y"
              selected={birthDate}
              className="border btm-left-in text-box"
            /> */}
            <ReactDatePicker
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <div
                  style={{
                    margin: 10,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <button
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                  >
                    {'<'}
                  </button>
                  <select
                    value={date.getFullYear()}
                    onChange={({ target: { value } }) => changeYear(value)}
                  >
                    {years.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>

                  <select
                    value={months[date.getMonth()]}
                    onChange={({ target: { value } }) =>
                      changeMonth(months.indexOf(value))
                    }
                  >
                    {months.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>

                  <button
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                  >
                    {'>'}
                  </button>
                </div>
              )}
              onChange={(date) => {
                setBirthDate(date)
                setAnswer(date)
              }}
              value={birthDate}
              dateFormat="MMM dd, y"
              selected={birthDate}
              className="border btm-left-in text-box"
              placeholderText="Click to select a date"
              popperPlacement="botom"
              popperModifiers={{
                flip: {
                  behavior: ["bottom"]
                },
                preventOverflow: {
                  enabled: false
                },
                hide: {
                  enabled: false
                }
            }}
            />
          </div>
        )}
        {showSpinner && (
          <div
            className="spinner-border textc-primary text-center mx-3 my-1 questionnaire-spinner"
            role="status"
            style={{ height: 15, width: 15 }}
          >
            {' '}
          </div>
        )}
        {showOK && (
          <div className="submit-wrapper">
            <button
              className="submit talk-bubble border round btm-left-in"
              onClick={() =>
                submitNumberAnswer(props.index, dateQuestion, patientAnswer)
              }
              value="ok"
            >
              OK
            </button>
          </div>
        )}
        <div>
          {'answer' in dateQuestion && (
            <div className="talk-bubble tri-right border round btm-left-in answer-bubble">
              <div className="talktext">
                <p className="pg-text">{dateQuestion.answer}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default DateQuestion
