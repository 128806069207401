import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next"

function LoginIssuesContact() {
    const { t } = useTranslation()
    return(
        <div className='row justify-content-center'>
            <div className='col-sm-12 col-md-10 col-lg-6 mb-2 text-center'>
                {t('auth_page_line_4')}
            </div>
        </div>
    )
}


export default LoginIssuesContact