import React from "react";
import { useNavigate } from "react-router-dom";
// import Stepper from "react-stepper-horizontal";
import Steps from "rc-steps";
import "rc-steps/assets/index.css";
import "../styles/pgStepper.css";
import { getFinishIcon } from "../methods";

export const PgStepper = ({ current }) => {
const navigate = useNavigate();
  return (
    <Steps
      current={current}
      labelPlacement={"vertical"}
      icons={{ finish: getFinishIcon() }}
    >
      <Steps.Step
        className="cursor-pointer"
        title="Genetics"
      />
      <Steps.Step
        className="cursor-pointer"
        title="Questionnaire"
      />
    </Steps>
  );
};
