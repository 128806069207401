import React, { useState, useEffect } from "react";
import { arrayDisplay, splitTo2DArray, removeLastElement } from "../../../methods";
import { useSelector } from "react-redux";

function CheckboxRadioQuestion(props) {
  const checkboxQuestion = props.question;
  const showSpinner = props.showSpinner;
  const isEdit = props.isEdit;
  const answer = props.editAnswerText;
  const initialCheckedItems = [];
  const [checkedItems, setCheckedItems] = useState(initialCheckedItems);
  const [showOK, setShowOK] = useState(false);
  const [selectedBgItems, setSelectedBgItems] = useState([]);
  const [checkboxItems, setCheckboxItems] = useState([]);
  const lang = useSelector((state) => state.auth.language)
  const language = (lang === undefined ? "en" : lang)

  useEffect(() => {
    if (isEdit) {
      setSelectedBgItems(answer);
      setCheckboxItems(answer);
      setShowOK(true);
    }
  }, []);

  const addCheckedItem = (index, value) => {
    const choices = JSON.parse(checkboxQuestion["choices_" + language]);
    const radioOptionvalue = choices[choices.length - 1];
    if (value != "") {
      setShowOK(true);
      var valueIndex = checkboxItems.indexOf(value);
      var selectedItem = selectedBgItems.indexOf(value);
      if (value === radioOptionvalue) {
        setCheckboxItems([value]);
        setSelectedBgItems([value]);
      } else {
        var radioIndex = checkboxItems.indexOf(radioOptionvalue);
        if (radioIndex > -1) {
          checkboxItems.splice(radioIndex, 1);
          selectedBgItems.splice(radioOptionvalue, 1);
        }
        if (valueIndex > -1) {
          setCheckboxItems(checkboxItems.filter(item => item != value));
          setSelectedBgItems(selectedBgItems.filter(item => item != value))
          selectedBgItems.length === 1 && setShowOK(false);
        } else {
          setCheckboxItems([...checkboxItems, value]);
          setSelectedBgItems([...selectedBgItems, value]);
        }
      }
      setCheckedItems(checkboxItems);
    }
  };

  const setAnswer = () => {
    checkboxQuestion.answer = checkboxItems;
    props.addtextAnswer(props.index, checkboxQuestion, checkboxItems);
    setShowOK(false);
  };

  return (
    <div>
      <div
        className="talk-bubble tri-right round border right-top"
        style={{ position: "relative" }}
      >
        <div className="talktext">
          <p className="pg-text">
            {
              ("question_" + language) in checkboxQuestion ? checkboxQuestion["question_" + language] : checkboxQuestion.question 
            }
          </p>
        </div>
      </div>

      <div>
        {!("answer" in checkboxQuestion) && (
          <div>
            <div className="question-support-text">
              <p>{checkboxQuestion["instructions_" + language]}</p>
            </div>
            <div className="select-options-wrapper">
              <div className="select-options-wrapper2">

              {splitTo2DArray(removeLastElement(JSON.parse(checkboxQuestion["choices_" + language]))).map(
                (optionArray, index) => (
                  <div className="checkbox-options-row">
                    {optionArray.map((option, optionIndex) => (
                      <div
                        className={
                          `talk-bubble tri-right round border select-options ` +
                          (selectedBgItems.indexOf(option) > -1
                            ? "selected-bg"
                            : "")
                        }
                        key={optionIndex}
                        onClick={() => addCheckedItem(optionIndex, option)}
                      >
                        <div className="talktext">
                          <p className="pg-text">{option}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                )
              )}
              </div>
              <div>

              <div
                className={
                  `last-option round border ` +
                  (selectedBgItems.indexOf(JSON.parse(checkboxQuestion["choices_" + language]).slice(-1)[0]) > -1
                    ? "selected-bg"
                    : "")
                }
                key={JSON.parse(checkboxQuestion.choices)[JSON.parse(checkboxQuestion["choices_" + language]).length -1]}
                onClick={() => addCheckedItem(JSON.parse(checkboxQuestion["choices_" + language])[JSON.parse(checkboxQuestion["choices_" + language]).length -1], JSON.parse(checkboxQuestion["choices_" + language]).slice(-1)[0])}
              >
                <div className="talktext">
                  <p className="pg-text">{JSON.parse(checkboxQuestion["choices_" + language]).slice(-1)[0]}</p>
                </div>
              </div>
              </div>
            </div>
          </div>
        )}
        {!("answer" in checkboxQuestion) && (
          <div className="submit-wrapper">
          {showOK && ( <button
              className="submit talk-bubble border round btm-left-in submit"
              onClick={setAnswer}
              value="ok"
            >
              OK
            </button>)}
          </div>
        )}
        {"answer" in checkboxQuestion && (
          <div className="talk-bubble tri-right border round btm-left-in answer-bubble">
            <div className="talktext">
              <p className="pg-text">{arrayDisplay(checkboxQuestion.answer)}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CheckboxRadioQuestion;
