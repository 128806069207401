import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";

function PgMultiSelectSearch({
  options = [],
  objectKey,
  objectKey2,
  label,
  placeholder,
  id,
  required,
  showLabel,
  optional,
  isDisable = false,
  onSelect,
  onRemove,
  selectedItem,
  errorMessage,
}) {
  const { t } = useTranslation();
  console.log("selected", selectedItem);
  const [inputText, setInputText] = useState(
    _.isObject(selectedItem) ? selectedItem[objectKey] : selectedItem
  );
  const [optionState, setOptionState] = useState(options);
  const [focused, setFocused] = React.useState(false);
  const [showSuggestion, setShowSuggestion] = useState(false);

  const onBlur = () => setShowSuggestion(false);
  let inputRef = useRef(null);

  const onFocus = (e) => {
    setFocused(true);
    dropDownList(e);
  };

  const onInput = (e) => {
    dropDownList(e);
  };

  const dropDownList = (e) => {
    onSelect({});
    const value = /^[a-zA-Z0-9 _]*$/.test(e.target.value)
      ? e.target.value
      : inputText;
    setInputText(value);
    const regex = new RegExp(`${value}`, `i`);
    setOptionState((os) => {
      return options
        .sort()
        .filter((v) => regex.test(_.isObject(v) ? v[objectKey] : v));
    });
  };

  const __onSelect = (e) => {
    setFocused(false);
    setInputText(_.isObject(e) ? e[objectKey] : e);
    setShowSuggestion(false);
    onSelect(e);
    setInputText("");
  };
  const style = {
    input: {
      border: errorMessage ? "2px solid #f00" : "3px solid #acd4ff",
    },
    close: {
      position: "absolute",
      right: 10,
      top: 15,
      // fontSize:12,
    },
  };

  return (
    <div className="pg-ss" id={`${id}-pg-ss`}>
      {showLabel && (
        <div>
          <div className="d-inline-block pg-text__bold textc-primary">
            {label}
          </div>
          {required && (
            <span className="textc-primary fst-italic"> (Required)</span>
          )}
          {optional && !isDisable && (
            <span className="textc-primary fst-italic"> (Optional)</span>
          )}
        </div>
      )}
      <div className="my-3">
        <div>
          {selectedItem &&
            selectedItem.map((item, index) => (
              <div key={index} className="chip">
                <div className="chip-content">{item.full_name}</div>
                <div className="chip-close" onClick={() => onRemove(item)}>
                  <svg
                    className="chip-svg"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path>
                  </svg>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="pg-ss__container" id={`${id}-pg-ss__container`}>
        <input
          style={style.input}
          id={`${id}-pg-ss__input`}
          disabled={isDisable}
          autoComplete={"alaban"}
          placeholder={placeholder}
          type="search"
          value={inputText}
          className="pg-ss__input"
          type="text"
          onInput={onInput}
          ref={inputRef}
          onFocus={onFocus}
        />
        {(focused || showSuggestion) && (
          <div
            className="cp fas fa-times fs-5"
            style={style.close}
            onClick={() => {
              setShowSuggestion(false);
              setFocused(false);
            }}
          ></div>
        )}
        {(focused || showSuggestion) && (
          <ul className="pg-ss__list" id={`${id}-pg-ss__list`}>
            {optionState.length > 0 ? (
              optionState.map((option) => (
                <li
                  className="pg-ss__list_item"
                  id={`${id}-pg-ss__list_item`}
                  onClick={() => __onSelect(option)}
                >
                  {_.isObject(option) ? (
                    <div>
                      {option[objectKey]}
                      {objectKey2 && <span> | {option[objectKey2]}</span>}
                    </div>
                  ) : (
                    option
                  )}
                </li>
              ))
            ) : (
              <div className="text-center py-2">
                {inputText} {t("not_found")}
              </div>
            )}
          </ul>
        )}
      </div>
      <div
        className="text-danger mx-2 my-1 transition-all"
        style={errorMessage ? { opacity: 1 } : { opacity: 0 }}
      >
        <i className="fas fa-info-circle"></i> {errorMessage}
      </div>
    </div>
  );
}

export default PgMultiSelectSearch;
