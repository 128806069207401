import * as lu_constant from "../actions/labUpload";

const initialState = {
  loading: true,
  isGettingTest: true,
  error: false,
  vendors: [],
  othersVendors: [],
  fileStatus: [],
  isGettingResultReview: true,
  resultReview: [],
  isPostingLabResults: false,
  tests: []
};

const labUploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case lu_constant.LAB_UPLOAD_REQUEST: {
      return { ...state, loading: true };
    }
    case lu_constant.LAB_UPLOAD_SUCCESS: {
      return { ...state, loading: false };
    }
    case lu_constant.LAB_UPLOAD_FAILURE: {
      return { ...state, loading: false, uploadError: false };
    }
    case lu_constant.LAB_VENDOR_TEST_REQUEST: {
      return { ...state, isGettingTest: true };
    }
    case lu_constant.LAB_VENDOR_TEST_SUCCESS: {
      return {
        ...state,
        isGettingTest: false,
        vendors: action.payload.lab_vendors,
        othersVendors: action.payload.others
      };
    }
    case lu_constant.LAB_VENDOR_TEST_FAILURE: {
      return { ...state, isGettingTest: false };
    }
    case lu_constant.UPDATE_LAB_UPLOAD: {
      return { ...state, ...action.payload };
    }

    case lu_constant.LAB_UPLOAD_RESULT_REVIEW_REQUEST: {
      return { ...state, isGettingResultReview: true, resultReview: [] };
    }
    case lu_constant.LAB_UPLOAD_RESULT_REVIEW_SUCCESS: {
      return {
        ...state,
        isGettingResultReview: false,
        resultReview: action.payload,
      };
    }
    case lu_constant.LAB_UPLOAD_RESULT_REVIEW_FAILURE: {
      return { ...state, isGettingResultReview: false, resultReview: [] };
    }
    case lu_constant.LAB_TEST_SUCCESS: {
      return {
        ...state,
        tests: action.payload,
      };
    }
    case lu_constant.COMMON_REQUEST:
    case lu_constant.COMMON_SUCCESS:
    case lu_constant.COMMON_FAILURE:
    case lu_constant.LAB_UPLOAD_STATUS_REQUEST:
    case lu_constant.LAB_UPLOAD_STATUS_SUCCESS:
    case lu_constant.LAB_UPLOAD_STATUS_FAILURE:
    case lu_constant.POST_LAB_REVIEW_RESULT_FAILURE:
    case lu_constant.POST_LAB_REVIEW_RESULT_SUCCESS:
    case lu_constant.POST_LAB_REVIEW_RESULT_REQUEST: {
      return { ...state };
    }
    default:
      return state;
  }
};

export default labUploadReducer;
