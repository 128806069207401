import React, { useState, useEffect } from "react";
import DPGImage_1 from "../../../assets/static/dpg_1.jpg";
import DPGImage_2 from "../../../assets/static/dpg_2.webp";
import DPGImage_3 from "../../../assets/static/dpg_3.webp";
import DPGImage_4 from "../../../assets/static/dpg_4.png";
import DPGImage_5 from "../../../assets/static/dpg_5.svg";
import DPGImage_6 from "../../../assets/static/dpg_6.svg";
import DPGImage_7 from "../../../assets/static/dpg_7.svg";
import DPGImage_8 from "../../../assets/static/dpg_8.svg";
import DPGImage_9 from "../../../assets/static/dpg_9.png";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// rebranding
import DPIImage_4 from "../../../assets/pg4/rebranding/DPI_Image4.jpg";
import DPIImage_9 from "../../../assets/pg4/rebranding/DPI_Image9.jpg";


function DiscoverPG({ history }) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (i18n.language === "en_gb") {
      window.onscroll = function (e) {
        scrollRotate(e);
      };
      function scrollRotate(e) {
        var dpg_img = document.getElementById("dpg-img__2");
        var dpg_img_1 = document.getElementById("dpg-img__3");
        dpg_img.style.transform = "rotate(" + window.pageYOffset / 10 + "deg)";
        dpg_img_1.style.transform =
          "rotate(" + -(window.pageYOffset / 8) + "deg)";
      }
    }
    return () => (window.onscroll = undefined);
  }, []);
  return (
    <div className="my-5">
      <div className="container-xxl px-md-1 px-0 my-5">
        {/* <div className="row justify-content-center">}
        {/* section 1 */}
        <div className="row justify-content-lg-between justify-content-center ">
          <div className="col-12 col-lg-6 px-0">
            <div className="d-lg-none my-3 px-4">
              <div className="pgs-heading__sm textc-primary">
                <span dangerouslySetInnerHTML={{ __html: t('discover_header_1') }} />
              </div>
              <div className="pgs-title pgs-title__xl my-3">
                <span dangerouslySetInnerHTML={{ __html: t('discover_sub_header_1') }} />
              </div>
            </div>
            <div className="px-md-5 mx-md-5 px-lg-0 mx-lg-0 px-0">
              <img
                src={DPGImage_1}
                alt="Discover PureGenomics"
                className="w-100 img-fluid"
                style={{ borderRadius: 24 }}
              />
            </div>
          </div>
          <div className="col-11 col-lg-5 my-auto">
            <div className="d-none d-lg-block">
              <div className="pgs-heading__sub">
                <span dangerouslySetInnerHTML={{ __html: t('discover_header_1') }} />
              </div>
              <div className="pgs-title my-2">
                <span dangerouslySetInnerHTML={{ __html: t('discover_sub_header_1') }} />
              </div>
            </div>
            <div className="pgs-text my-2">
              <span dangerouslySetInnerHTML={{ __html: t('discover_para_1') }} />
            </div>
            <div className="pgs-heading__sub my-2">
              <span dangerouslySetInnerHTML={{ __html: t('discover_sub_paragraph_1') }} />
            </div>
          </div>
        </div>

        {/* section 2 */}
        {i18n.language === "en_gb" && <div className="row my-5 justify-content-lg-between justify-content-center overflow-hidden">
          <div className="col-12 col-lg-6 my-5 order-lg-last">
            <div
              className="dpg-img__2"
              style={{
                background: `url(${DPGImage_2}) `,
                backgroundSize: "100%",
                zIndex: -99,
              }}
              id="dpg-img__2"
            >
              <img
                src={DPGImage_3}
                alt=""
                className="w-100 position-relative dpg-img__3"
                id="dpg-img__3"
              />
            </div>
          </div>
          <div
            className="col-11 col-lg-5 px-lg-5 px-md-3 my-auto"
            style={{ zIndex: 9 }}
          >
            <div className="pgs-title">
              <span dangerouslySetInnerHTML={{ __html: t('nutrigenomics_header_1') }} />
            </div>
            <div className="pgs-text">
              <span dangerouslySetInnerHTML={{ __html: t('nutrigenomics_paragraph_1') }} />
            </div>
          </div>
        </div>}

        {/* section 3 */}
        <div className="row my-5 justify-content-lg-between justify-content-center">
          <div className="col-11 col-lg-5 my-md-3 my-lg-0 my-5 ">
            <div className="pgs-title">
              <span dangerouslySetInnerHTML={{ __html: t('offer_header_1') }} />
              <div className="pgs-text my-2">
                <span dangerouslySetInnerHTML={{ __html: t('offer_paragraph_1') }} />
              </div>
              <div className="pgs-text my-2">
                <span dangerouslySetInnerHTML={{ __html: t('discover_paragragh_1') }} />
              </div>
            </div>
          </div>
          <div className={`col-11 col-lg-6 my-auto ${i18n.language === "en_gb" && 'order-lg-first'}`}>
            <div className="">
              <img src={i18n.language !== "en_gb" ? DPIImage_4 : DPGImage_4} alt="Sample Report" className="w-100 " />
              <div className="text-center">
                <a
                  className="btn btn-outline px-5 lh-lg"
                  href={t('prac_sample_report_url')}
                  download
                >
                  {t('offer_paragraph_btn_1')}
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-lg-between justify-content-center">
          <div className="pgs-heading textc-primary fw-bold px-4">
            {t('support_step_heading')}
          </div>
          <div className="col-11 col-lg-5 my-4">
            <img src={DPGImage_5} alt="Accessible & Applicable Insights" />
            <span dangerouslySetInnerHTML={{ __html: t('support_step_1') }} />
          </div>
          <div className="col-11 col-lg-5 my-4">
            <img src={DPGImage_6} alt="Accessible & Applicable Insights" />
            <div className="pgs-heading__sub my-3 fw-bold ff-m d-block">
              {t('support_step_heading_2')}
            </div>
            <div className="pgs-text">
              <span dangerouslySetInnerHTML={{ __html: t('discover_paragragh_2') }} />
            </div>
          </div>
          <div className="col-11 col-lg-5">
            <img src={DPGImage_7} alt="Accessible & Applicable Insights" />
            <span dangerouslySetInnerHTML={{ __html: t('support_step_heading_3') }} />
          </div>
          <div className="col-11 col-lg-5">
            <img src={DPGImage_8} alt="Accessible & Applicable Insights" />
            <span dangerouslySetInnerHTML={{ __html: t('support_step_heading_4') }} />
          </div>
          <div className="col-11 col-lg-12">
            <div className="text-center my-4">
              <button
                onClick={() => navigate("/hcp/snps-genetic-testing")}
                className="btn btn-outline px-5"
              >
                {t('learn_abt_science_btn_1')}
              </button>
            </div>
          </div>
        </div>
        <div className="row justify-content-lg-between justify-content-center my-5">
          <div className="col-12 col-lg-6 order-lg-last p-0">
            <img src={i18n.language === "en_gb" ? DPGImage_9 : DPIImage_9 } alt="Ipad Image" className="w-100" />
          </div>
          <div className="col-11 col-lg-5 my-auto">
            <div className="pgs-title my-3">
              <span dangerouslySetInnerHTML={{ __html: t('get_started_txt_1') }} />
            </div>
            <div className="pgs-text my-lg-2 my-4">
              <span dangerouslySetInnerHTML={{ __html: t('discover_paragragh_3') }} />
            </div>
            <button
              className="btn btn-primary"
              onClick={() => navigate("/hcp/getting-started")}
            >
              {t('get_started_btn_1')}
            </button>
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}

export default DiscoverPG;
