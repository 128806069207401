import { Modal } from "react-bootstrap";
const HowToReadReport = ({ show, onHide, t }) => {
  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => onHide(false)}
      aria-labelledby="example-modal-sizes-title-sm"
      contentClassName="border-radius "
    >
      <Modal.Body className="pg-text px-4 py-4">
        <div className="d-flex justify-content-between">
          <div className="pg-heading">{t('pracrs_htryr_2')}</div>
          <div className="pg-link" onClick={() => onHide(false)}>
            <i className="fas fa-times"></i>
          </div>
        </div>
        <div className="pe-5" dangerouslySetInnerHTML={{ __html: t('pracrs_htryr_3_new') }} />
      </Modal.Body>
    </Modal>
  );
};

export default HowToReadReport;
