
import { createAction } from "redux-api-middleware";

export const GET_PS_LIST_TRAIT_REQUEST = "GET_PS_LIST_TRAIT_REQUEST";
export const GET_PS_LIST_TRAIT_SUCCESS = "GET_PS_LIST_TRAIT_SUCCESS";
export const GET_PS_LIST_TRAIT_FAILURE = "GET_PS_LIST_TRAIT_FAILURE"



export const getPSlist = (
  token,
  currentPracticeID,
  currentPractice,
  userType,
  reportId
) => {
  const protocol = process.env.REACT_APP_PROTOCOL;
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/web/api/ps_list/${reportId}`,
    method: "GET",
    headers: { Authorization: token, "Current-Practice": currentPracticeID, "Current-Type": userType },
    types: [
      GET_PS_LIST_TRAIT_REQUEST,
      GET_PS_LIST_TRAIT_SUCCESS,
      GET_PS_LIST_TRAIT_FAILURE,
    ],
  });
};


