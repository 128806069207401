import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";


function RialtoPractitionerFAQ() {
  const { t, i18n } = useTranslation()
  return (
    <div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-11  my-5 px-md-0 px-3">
            <p className="pgs-title my-5">What Is It?</p>
            <div className="row justify-content-center">
              <div className="col-11 col-lg-12">
                <div className="pgs-heading fw-bold textc-primary my-2">
                  What is the Virtual Dispensary?
                </div>
                <div className="pgs-text my-2">
                  The Virtual Dispensary is an optional feature available through PureGenomics.com that enables the patient to review their practitioner’s recommendations, purchase Pure Encapsulations supplements directly, and have them shipped directly to their door.
                </div>
                <br />
                <div className="pgs-heading fw-bold textc-primary my-2">
                  Who is it For?
                </div>
                <div className="pgs-text my-2">
                  This feature is for practitioners that would like to recommend and seamlessly offer supplements for purchase to their patients as part of the PureGenomics.com experience.
                </div>
                <br />
                <div className="pgs-heading fw-bold textc-primary my-2">
                  Why Should Practitioners Consider This?
                </div>
                <div className="pgs-text my-2">
                  <ul>
                    <li>
                      We facilitate the transactions between you and your patient. The Virtual Dispensary allows a full end-to-end experience within PureGenomics.com, from nutrigenomics evaluation to supplement (and lifestyle) recommendations, and an optional buy button for delivery to the patient’s doorstep. No need to carry inventory or manage a virtual storefront.
                    </li>
                    <br />
                    <li>
                      The Virtual Dispensary is free to use and available 24/7. It does not require any software to be downloaded and we manage the details for you, from shopping cart to payment, inventory, shipping, and returns.  This leaves more time for you to focus on what’s most important, providing exceptional patient care and personalized recommendations to help optimize their health and wellness goals.
                    </li>
                  </ul>
                </div>
                <br />
                <div className="pgs-heading fw-bold textc-primary my-2">
                  Why Do I Need A Stripe Account?
                </div>
                <div className="pgs-text my-2">
                  If you opt into the Virtual Dispensary, we will help manage the financial transactions for you, with the help of a Stripe account. If your patient chooses to purchase supplements from your recommendation list, the patient pays the SRP and you receive the resulting difference in the wholesale price minus any applied discounts and fees. Stripe makes this seamless transaction possible, all in just a few clicks.
                </div>
                <br />
                <div className="pgs-heading fw-bold textc-primary my-2">
                  Can I Provide a Discount to My Patients?
                </div>
                <div className="pgs-text my-2">
                  Yes, by setting your discount amount in the Virtual Dispensary Management section of your profile, you can provide your patients with a discount if you choose (exclusions for certain products apply).
                </div>
              </div>
            </div>
            <p className="pgs-title my-5">Getting Started</p>
            <div className="row justify-content-center">
              <div className="col-11 col-lg-12">
                <div className="pgs-heading fw-bold textc-primary my-2">
                  I would like to offer the Virtual Dispensary to my patients. How do I get started?
                </div>
                <div className="pgs-text my-2">
                  Great! If you already have a Pure Encapsulations account, you’re one step ahead to getting access to Virtual Dispensary. All you need to do is contact our
                  {" "}<a
                    className="cursor-pointer"
                    href={`mailto:${i18n.language === "en_gb" ? 'puregenomics@pureencapsulations.com' : 'pureinsight@pureencapsulations.com'}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    PureGenomics Support Center
                  </a>{" "} and provide your account number. They will guide you through the next steps so you can quickly begin offering this feature to your patients. As part of this process, we will ensure that any appropriate Reseller Certificates and/or tax-exempt information is on file. If not, state-specific forms will be provided to you for completion.
                  <p>
                    If you don’t currently have a Pure Encapsulations account, please visit
                    {" "}<a
                      className="cursor-pointer"
                      href="http://www.pureencapsulationspro.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      www.pureencapsulationspro.com
                    </a>
                    {" "} to set one up. Once your account has been approved, you will receive an email that contains your account number (which will be needed for Virtual Dispensary activation). We will then ensure that you receive any appropriate Reseller Certificates or tax-exempt forms needed before Virtual Dispensary activation.
                  </p>
                </div>
                <br />
                <div className="pgs-heading fw-bold textc-primary my-2">
                  Do I Need a Reseller Certificate for the Virtual Dispensary?
                </div>
                <div className="pgs-text my-2">
                  <p>
                    Reseller Certificates are needed for certain states with applicable taxes, and for certain transactional models.
                  </p>
                  <p>
                    If you are selling to patients in a state that taxes supplements, you will need a Reseller Certificate on file. Our PureGenomics Support Center will guide you through any state-specific forms that are needed.
                  </p>
                </div>
                <br />
                <div className="pgs-heading fw-bold textc-primary my-2">
                  How Long Will it Take to Activate the Virtual Dispensary?
                </div>
                <div className="pgs-text my-2">
                  The activation process may take up to five business days, but this may vary occasionally. You will be notified via email when your account has been approved for activation.
                </div>
                <br />
                <div className="pgs-heading fw-bold textc-primary my-2">
                  My Virtual Dispensary Activation Was Approved, What Do I Do Now?
                </div>
                <div className="pgs-text my-2">
                  You are now ready to start setting up your Virtual Dispensary. In the upper right-hand corner of PureGenomics.com, click on the icon next to your name and choose Virtual Dispensary Management. This is where you will enter your Pure Encapsulations account number and set up your <a
                    className="cursor-pointer"
                    href="http://stripe.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Stripe
                  </a>
                  {" "}account, which will establish and ensure proper and seamless financial transactions. If you do not see the Virtual Dispensary option in the drop-down menu, please contact our{" "}<a
                    className="cursor-pointer"
                    href={`mailto:${i18n.language === "en_gb" ? 'puregenomics@pureencapsulations.com' : 'pureinsight@pureencapsulations.com'}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    PureGenomics Support Center
                  </a>.
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <p className="pgs-title my-5">Recommending Supplements</p>
              <div className="col-11 col-lg-12">
                <div className="pgs-heading fw-bold textc-primary my-2">
                  How Do I Generate Supplement Recommendations in the Virtual Dispensary?
                </div>
                <div className="pgs-text my-2">
                  To create a list of supplement recommendations for a patient, locate the patient's name in your patient list and click on <b>View Report</b>.
                  In their <i>Report Summary</i> Scroll down to the <i>Supplement Suggestions</i> section and click on <b>View & Edit Suggestions</b>.

                  You will see a prepopulated list of recommendations based on some of the genetic variances identified in the report and any relevant information from the optional questionnaire (preferences, goals, habits, allergens to avoid). Select any desired supplements from the list provided, then click <b>Next</b>.

                  You will have the opportunity to add additional products from the broader Pure Encapsulations catalog. If you do not wish to add any additional supplements, click <b>Next</b>.

                  On the following page, you can customize instructions for your patient. We also recommend including your practice’s phone number/contact info in the Information and Recommendations section so that they can reach out to you if they need any modifications.

                  After you click on <b>Send to Patient</b>, they will receive an email letting them know that they have Supplement Recommendations to view.
                </div>
                <br />
                <div className="pgs-heading fw-bold textc-primary my-2">
                  Am I Required to Use the Supplement Suggestions Provided Through PureGenomics®?
                </div>
                <div className="pgs-text my-2">
                  No, not at all! The prepopulated supplement suggestions are provided as a starting point and to help enhance efficiency for your workflow. They are generated by the system based on any relevant genetic variances for the patient and include personal preferences or allergens to avoid (if the questionnaire was completed). There is no requirement or obligation to use these suggestions! If you don't see the products you'd like to recommend on the prepopulated list, simply hit <b>Next</b>. to choose from all of our Pure Encapsulations products.
                </div>
                <br />
                <div className="pgs-heading fw-bold textc-primary my-2">
                  How Do I Track Patient Purchases?
                </div>
                <div className="pgs-text my-2">
                  You can view order totals for all patients from the Patient Supplement Recommendation list on your dashboard. Simply click on <b>Details</b> for any recommendation made to a patient and any transactions completed by the patient will be noted.
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <p className="pgs-title my-5">Patient-Related Processes</p>
              <div className="col-11 col-lg-12">
                <div className="pgs-heading fw-bold textc-primary my-2">
                  How Will My Patient Know When Their Recommendation is Ready?
                </div>
                <div className="pgs-text my-2">
                  If you have opted into the Virtual Dispensary and have generated a supplement recommendation for the patient via PureGenomics®, we send the patient an email indicating that their supplement recommendations are ready to view. Once they log in, they will have the option to <i>View Supplement Recommendations</i>. They can then proceed to <b>CHECKOUT</b> and <b>PLACE ORDER</b>. Products will be shipped directly to the patient.
                </div>
                <br />
                <div className="pgs-heading fw-bold textc-primary my-2">
                  Are Patients Able to Change, Add or Delete Items to Their Order?
                </div>
                <div className="pgs-text my-2">
                  Currently, patients are not able to change, add or delete any items from the supplement recommendations you send them. For any changes they might want, they will need to contact you based on the contact information you provide as part of their supplement recommendation. You can add on any supplement from the entire Pure Encapsulations catalog!
                </div>
                <br />
                <div className="pgs-heading fw-bold textc-primary my-2">
                  How Will I Know If My Patient Has Ordered?
                </div>

                <div className="pgs-text my-2">
                  You can visit the <i>Patient Supplement Recommendation</i> section on your dashboard. You will be able to see the status change as orders are sent, viewed, processed, shipped, and delivered.
                </div>
                <br />
                <div className="pgs-heading fw-bold textc-primary my-2">
                  Will I be Charged for My Patient’s Order?
                </div>
                <div className="pgs-text my-2">
                  No! That is the beauty of the Virtual Dispensary! The patient will pay for the order directly, online via the patient portal on PureGenomics.com--you do not need to collect payment. The patient pays the SRP and you receive the resulting difference in the wholesale price minus any applied discounts and fees. Stripe makes this seamless transaction possible, all in just a few clicks.
                </div>
                <br />
                <div className="pgs-heading fw-bold textc-primary my-2">
                  How Long Will it Take My Patient to Receive Their Order?
                </div>
                <div className="pgs-text my-2">
                  Orders received by 4:00 pm ET are typically shipped that day, via UPS 2-Day service.
                </div>
                <br />
                <div className="pgs-heading fw-bold textc-primary my-2">
                  Will They Be Charged Shipping?
                </div>
                <div className="pgs-text my-2">
                  <p>Shipping  is  FREE  for  continental  US  orders  over  $100  (exceptions  may  apply). Orders  under $100 will be charged $10.00 for shipping.</p>

                  <p>For Puerto Rico, and Alaska there is a $12 flat rate shipping fee and free shipping is at $500.Orders to all other US territories do not qualify for free shipping.</p>

                  <p>Shipping to these locations is billed separately based on actual costs. We do not currently offer shipping to Hawaii.</p>
                </div>
                <br />
                <div className="pgs-heading fw-bold textc-primary my-2">
                  What Happens if My Patient Needs to Make a Return?
                </div>
                <div className="pgs-text my-2">
                  Returns can be made within 30 days of receipt and require preauthorization. Any claims of shortage or damage must be made within 7 days of receiving the package. Authorized returns must be received by our warehouse within 14 days of authorization. They will be credited upon receipt. Returns can be initiated on their patient dashboard.
                </div>
                <br />
                <div className="pgs-heading fw-bold textc-primary my-2">
                  Can I See Patient Returns?
                </div>
                <div className="pgs-text my-2">
                  You will be able to see that the patient returned a product under the Patient Supplement Recommendation section on your dashboard after clicking on <b>Details</b>.
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div >
  );
}

export default RialtoPractitionerFAQ;
