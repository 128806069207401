import React from "react";

function StaffActionButtons({
    context = "Staff Member",
    disableEdit, disableReset, disableArchive, disableRestore, disableLockout,
    onEdit, onReset, onArchive, onRestore, onLockout, normalAdmin, disableRegistrationReminder, onRegistrationReminder
}) {
    const style = {
        batchFnBtnActive: {
            // color: "red", cursor: 'pointer'
            // filter:"brightness(1.2)"
        },
        batchFnBtnDisabled: {
            color: "var(--pg_primary)", cursor: 'not-allowed',opacity:0.6
        }
    }
    return (
        <div className="d-flex justify-content-between ">
            <div className="d-flex justify-content-evenly" >
                <div className="mx-2 pg-link batch-fn-btn"
                    onClick={(disableEdit || normalAdmin) ? undefined : onEdit}
                    style={(disableEdit || normalAdmin)? style.batchFnBtnDisabled : style.batchFnBtnActive}>
                    <i className="mx-1 fas fa-pen"></i>Manage
                </div>
                <div className="mx-2 pg-link"
                    onClick={disableReset ? undefined : onReset}
                    style={disableReset ? style.batchFnBtnDisabled : style.batchFnBtnActive}>
                    <i className="mx-1 fas fa-lock"></i> Reset Password
                </div>
                <div
                    onClick={disableArchive ? undefined : onArchive}
                    className="mx-2 pg-link" style={(disableArchive || normalAdmin) ? style.batchFnBtnDisabled : style.batchFnBtnActive}>
                    <i className="mx-1 fas fa-archive"></i>Archive {context}
                </div>
                <div
                    onClick={disableRestore ? undefined : onRestore}
                    className="mx-2 pg-link" style={(disableRestore || normalAdmin)? style.batchFnBtnDisabled : style.batchFnBtnActive}>
                    <i className="mx-1 fas fa-trash-restore"></i>Restore {context}
                </div>
                <div
                    onClick={disableLockout ? undefined : onLockout}
                    className="mx-2 pg-link" style={(disableLockout || normalAdmin)? style.batchFnBtnDisabled : style.batchFnBtnActive}>
                    <i className="mx-1 fas fa-trash-restore"></i>Reset Lockout
                </div>
                <div className="mx-2 pg-link"
                  onClick={disableRegistrationReminder ? undefined : onRegistrationReminder}
                  style={disableRegistrationReminder ? style.batchFnBtnDisabled : style.batchFnBtnActive}>
                  <i className="mx-1 fas fa-bell"></i> Registration Reminder
                </div>
      </div>
    </div>
  );
}

export default StaffActionButtons;
