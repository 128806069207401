import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import PgGroupButton from "../../PgGroupButton";
import GeneAnalysisList from "../GeneAnalysis/GeneAnalysisList";
import { changeReportViewType } from "../../../store/actions/patientReport";

function TraitTableTypeContainer({
  collapsible,
  tableFilter,
  viewType,
  psStatus,
}) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const traits = useSelector((state) => state.patientReport.traitCategories);
  const token = useSelector((state) => state.auth.token);
  const currentPracticeID = useSelector(
    (state) => state.auth.currentPracticeID
  );
  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const currentUserId = useSelector((state) => state.auth.currentUserId);
  const [currentTableType, setCurrentTableType] = useState(tableFilter);
  const [expandAll, setExpandAll] = useState(collapsible);

  const updateTableFilterState = (tableType, expanded) => {
    dispatch(
      changeReportViewType(
        token,
        currentPracticeID,
        currentPractice,
        currentUserId,
        {
          report_layout: viewType,
          report_expand: expanded,
          report_filter: tableType,
        }
      )
    ).then((_response) => {
      // if (!_response.error)
      // console.log("View type changes updated", _response)
    });
    setCurrentTableType(tableType);
    setExpandAll(expanded);
  };

  useEffect(() => {
    console.log({ currentTableType });
  }, [currentTableType]);

  return (
    <div className="my-2">
      <hr />
      <div className="d-flex justify-content-end">
        <div className="mx-3">
          <PgGroupButton
            onButtonClick={(cbt) => updateTableFilterState(cbt, expandAll)}
            activeButton={currentTableType}
            buttonNames={[
              t("pracrs_btn_5") /* Show All*/,
              t("pracrs_btn_4") /* Show Actionable */,
            ]}
          />
        </div>
        <div
          className="pg-link transition-all"
          onClick={() =>
            updateTableFilterState(currentTableType, expandAll !== true)
          }
        >
          {
            expandAll
              ? t("pracrs_btn_6") /* Collapse All*/
              : t("pracrs_btn_7") /* Expand All*/
          }
          <i
            className="fa fa-chevron-down ms-2 transition-all"
            style={{
              transform: expandAll ? "rotate(-180deg)" : "rotate(0deg)",
            }}
          ></i>
        </div>
      </div>
      <hr />
      <div>
        <GeneAnalysisList
          t={t}
          traits={traits}
          expandAll={expandAll}
          filter={currentTableType}
          psStatus={psStatus}
        />
      </div>
    </div>
  );
}

export default TraitTableTypeContainer;
