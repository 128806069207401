import React, { useState, useEffect } from "react";
import { ProgressBar } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import toast from 'react-hot-toast';
import { parse, parseUrl } from "query-string";
import _ from "lodash";
import jwt_decode from "jwt-decode";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";

import { _logout, LOGOUT, changeIsAuth } from "../../store/actions/auth";

import { updatePassword } from "../../store/actions/Pg2User";

import PasswordInput from "../../components/PasswordInput ";

import { validateEmail, clearCookie } from "../../methods";
import "./Login.css";
import LoginIssuesContact from './LoginIssuesContact'
import { switchSDAndLogin } from "../../methods/auth_methods";

const cookies = new Cookies();
function PatientRegistration({
  history,
  loading,
  logoutAction,
  location,
}) {
  const BASE_URL = process.env.REACT_APP_BASE_DOMAIN;
  const DOMAIN_FOR_COOKIE = process.env.REACT_APP_BASE_DOMAIN_FOR_COOKIE;

  // local state  variables
  const [email, setEmail] = useState("");
  const [invitationToken, setInvitationToken] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [progressValue, setProgressValue] = useState(0);
  const [statusText, setStatusText] = useState("");
  const [c1, setC1] = useState(false);
  const [c2, setC2] = useState(false);
  const [c3, setC3] = useState(false);
  const [c4, setC4] = useState(false);
  const [c5, setC5] = useState(false);
  const [userType, setUserType] = useState("")
  const navigate = useNavigate();

  //
  const dispatch = useDispatch();
  const updatePasswordAction = (data, url) => dispatch(updatePassword(data, url));
  const changeIsAuthAction = (payload) => dispatch(changeIsAuth(payload))

  //   to validate query
  useEffect(() => {
    const parsed = parse(window.location.search);
    if (_.isUndefined(parsed.email) || _.isUndefined(parsed.invitation_token)) {
      toast.error("Invalid URL");
      return navigate("/");
    }
    if (!validateEmail(parsed.email) || parsed.invitation_token.length < 1) {
      toast.error("Invalid URL", "Incorrect Email/Token");
      return navigate("/");
    }
    const email = parsed.email;
    setInvitationToken(parsed.invitation_token);
    setUserType(parsed.user_type);
    setEmail(email);
  }, []);

  //   to validate passowrd
  useEffect(() => {
    setC1(false);
    setC2(false);
    setC3(false);
    setC4(false);
    setC5(false);
    setProgressValue(0);
    setPasswordError(false);
    setConfirmPasswordError(false);
    setDisableSubmit(true);
    setStatusText("");
    if (userType == 'Patient'){
      if (password.length > 9) {
        setC1(true);
        setProgressValue(75);
        setStatusText("Medium");
      } else if (password.length > 7) {
        setProgressValue(75);
        setStatusText("Medium");
      } else if (password.length < 2) {
        setStatusText("Weak");
        setProgressValue(25);
      }
    }
    else if (userType == 'Practitioner'){
      if (password.length > 9) {
        setC1(true);
        setProgressValue(75);
        setStatusText("Medium");
      } else if (password.length > 7) {
        setProgressValue(75);
        setStatusText("Medium");
      } else if (password.length < 2) {
        setStatusText("Weak");
        setProgressValue(25);
      }
    }
    else{
      if (password.length > 15) {
        setC1(true);
        setProgressValue(75);
        setStatusText("Medium");
      } else if (password.length > 12) {
        setProgressValue(75);
        setStatusText("Medium");
      } else if (password.length > 7) {
        setStatusText("Weak");
        setProgressValue(50);
      } else if (password.length < 8 && password.length > 0) {
        setStatusText("Weak");
        setProgressValue(25);
      }
    }

    if (/(?=.*[A-Z])/.test(password)) setC2(true);
    if (/(?=.*[a-z])/.test(password)) setC3(true);
    if (/(?=.*\d)/.test(password)) setC4(true);
    if (/(?=.*[\W_])/.test(password)) setC5(true);

    if (confirmPassword.length > 0 && confirmPassword !== password) {
      setConfirmPasswordError(true);
      // setDisableSubmit(true)
    }

    if(c1 && c2 && c3 && c4 && c5){
      setStatusText("Strong");
      setProgressValue(100);
    }

    if (c1 && c2 && c3 && c4 && c5 && password === confirmPassword) {
      setConfirmPasswordError(false);
      setDisableSubmit(false);
    }
  }, [password, confirmPassword, c1, c2, c3, c4, c5]);

  let handleLogin = async (e) => {
    e.preventDefault();
    var redirectUrl, data;
    if (userType === "Practitioner") {
      redirectUrl = "/invitation";
      data = {
        user: {
          invitation_token: invitationToken,
          password,
          password_confirmation: confirmPassword,
          is_from_pg2_update: true
        }
      }
    }
    else {
      redirectUrl = "/patients/invitation"
      data = {
        patient: {
          invitation_token: invitationToken,
          password,
          password_confirmation: confirmPassword,
          is_from_pg2_update: true
        }
      }
    }
    const response = await updatePasswordAction(data, redirectUrl);
    if (!response.error) {
      const token = response.payload.token;
      const decodedData = jwt_decode(response.payload.token);
      toast.success("Password creation successful");

      const practices = decodedData.practices;

      changeIsAuthAction({
        isAuth: false,
        token: response.payload.token,
        currentUserId: response.payload.resource.id,
        userType: decodedData.user_type,
        postLogin: response.payload.resource.post_login,
        termsAgreed: Array.isArray(practices)
          ? practices[0]?.terms_agreed
          : practices?.terms_agreed,
      })

      const to = parseUrl(location?.state?.from?.search || "").query;
      const subdomain = window.location.host.split(".")[0];
      const currentPractice = _.find(practices, { subdomain });

      if (response?.payload?.resource?.mfa_enabled &&
        (practices?.length === 1 || decodedData.user_type !== "User")
      ) {
        navigate("/users/verify_otp", {
          state: {
            toReport: to.patient_id && currentPractice ? true : false,
            report: location?.state,
          }
        });
        return
      } else {
        let path = response.payload.resource.post_login ? `/hcp/educational_1` : `/practice/dashboard`;
        let language = decodedData.user.language
        let practiceId = Array.isArray(practices) ? practices[0]?.id : practices?.id
        let domain = Array.isArray(practices) ? practices[0]?.subdomain : practices?.subdomain
        switchSDAndLogin(response.payload.token, domain, practiceId, path, language);
      }
    }
  };
  return (
    <div className="container-fluid">
      <div className=" py-3 px-2 px-lg-5 mx-0 mx-lg-5">
        <div className="my-5">
          {userType === "Patient" && (
            <div className="pg-title">Update Password</div>
          )}
          {userType === "Practitioner" && (
            <div className="pg-title">Update Password</div>
          )}
          <div className="pg-text py-4">
            The PureGenomics<sup>&reg;</sup> system has been recently upgraded with enhanced security features. Take advantage of these features in 1 easy step.<br />
            1) Reset your password on this page to a strong password
          </div>
        </div>
        <form onSubmit={handleLogin} className="mb-5">
          <div className="row justify-content-center justify-content-md-start">
            <div className="col-lg-4 col-12">
              <TextInput
                value={email}
                placeholder="Email Address"
                isDisable={true}
                className="my-0 py-0"
              />
              <PasswordInput
                className="my-0 py-0"
                placeholder="Create Password"
                value={password}
                error={passwordError}
                errorMessage= {
                  userType === 'Patient'
                    ? "Minimum of 10 characters"
                    : userType === 'Practitioner'
                    ? "Minimum of 10 characters"
                    : "Minimum of 16 characters"
                }
                inputHandler={(e) => setPassword(e.target.value)}
              />
              <PasswordInput
                placeholder="Confirm Password"
                value={confirmPassword}
                error={confirmPasswordError}
                errorMessage="Both the password should be same"
                inputHandler={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <div className="offset-lg-1 col-lg-4 col-10 pg-text pt-3 ps-0">
              <div className="ms-4 ps-1">Password requirements:</div>
              <div className={c1 ? "my-3 text-success" : "my-3 text-secondary"}>
                {c1 ? (
                  <i className="fas fa-check-circle fs-5 me-1"></i>
                ) : (
                  <i className="far fa-check-circle fs-5 me-1"></i>
                )}{" "}
                Between 6 and 18 characters
              </div>
              <div className={c2 ? "my-3 text-success" : "my-3 text-secondary"}>
                {c2 ? (
                  <i className="fas fa-check-circle fs-5 me-1"></i>
                ) : (
                  <i className="far fa-check-circle fs-5 me-1"></i>
                )}{" "}
                At least 1 uppercase letter
              </div>
              <div className={c3 ? "my-3 text-success" : "my-3 text-secondary"}>
                {c3 ? (
                  <i className="fas fa-check-circle fs-5 me-1"></i>
                ) : (
                  <i className="far fa-check-circle fs-5 me-1"></i>
                )}{" "}
                At least 1 lowercase letter
              </div>
              <div className={c4 ? "my-3 text-success" : "my-3 text-secondary"}>
                {c4 ? (
                  <i className="fas fa-check-circle fs-5 me-1"></i>
                ) : (
                  <i className="far fa-check-circle fs-5 me-1"></i>
                )}{" "}
                At least 1 number
              </div>
              <div className={c5 ? "my-3 text-success" : "my-3 text-secondary"}>
                {c5 ? (
                  <i className="fas fa-check-circle fs-5 me-1"></i>
                ) : (
                  <i className="far fa-check-circle fs-5 me-1"></i>
                )}{" "}
                At least 1 special character
              </div>
              <div className="ms-4 ps-1">
                Password Strength:{" "}
                <span
                  className={
                    progressValue <= 50
                      ? "text-danger"
                      : progressValue <= 75
                        ? "text-info"
                        : "text-success"
                  }
                >
                  {" "}
                  {statusText}
                </span>
                <div className="pe-lg-3 me-lg-3">
                  <ProgressBar
                    now={progressValue}
                    variant={
                      progressValue <= 50
                        ? "danger"
                        : progressValue <= 75
                          ? "info"
                          : "success"
                    }
                    style={{ height: 10, width: "100%" }}
                    className="border-radius my-3"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row my-lg-0 my-5">
            <div className="col-12 col-lg-4">
              <input
                type="submit"
                value="Update Password"
                disabled={disableSubmit}
                className="btn bgc-primary text-light w-100"
              />
            </div>
          </div>
        </form>
      </div>
      <LoginIssuesContact />
    </div>
  );
}

export default (PatientRegistration)


function TextInput({
  type,
  inputHandler,
  placeholder,
  error,
  errorMessage,
  value,
  isDisable,
}) {
  const style = {
    padding: 15,
    width: "100%",
    outline: "none",
    border: error ? "2px solid #f00" : "3px solid #acd4ff",
    borderRadius: "4px",
    height: 50,
    transition: "all .3s ease-in-out",
  };

  return (
    <div className="w-90 my-3">
      <input
        placeholder={placeholder}
        type={type}
        onInput={inputHandler}
        value={value}
        style={style}
        disabled={isDisable}
      />
      <div className="text-secondary mx-3 my-1 transition-all">Required</div>
    </div>
  );
}
