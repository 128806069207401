import { Modal } from "react-bootstrap";

import { useTranslation } from "react-i18next";
function QuestionnaireAlertModal({ onSubmit, onCancel, showModal, snpUpload }) {
  const { t, i18n } = useTranslation();
  return (
    <Modal
      show={showModal}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onCancel}
      contentClassName="border-radius"
    >
      <Modal.Body>
        <div className="pg-link fw-bold">
          {
            snpUpload
              ? <div dangerouslySetInnerHTML={{ __html: t('pracpl_qam_line_1') }} />
              : <div dangerouslySetInnerHTML={{ __html: t('pracpl_qam_line_2') }} />
          }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn bgc-primary text-light px-5" onClick={onCancel}>{t('ok')}</button>
      </Modal.Footer>
    </Modal>
  );
}

export default QuestionnaireAlertModal;
