import React, { useState, useEffect } from 'react'
import TraitListCard from "./TraitListCard";
import TraitGridCard from "./TraitGridCard";
import TraitTableTypeContainer from "./TraitTableTypeContainer";

import { useTranslation } from "react-i18next";
function TraitViewContainer({ traits, viewType, tableFilter, collapsible,psStatus }) {

  const [selectedGrid, setSelectedGrid] = useState(0);

  const { t, i18n } = useTranslation();
  useEffect(() => {
    console.log(viewType);
  }, [viewType])

  const updateSelectedGrid = (index) => setSelectedGrid(index)

  return <>
    {viewType === "grid" && (
      traits.map((trait, i) => {
        return <TraitGridCard traitData={trait} key={trait.title} index={i} updateSelectedGrid={updateSelectedGrid} selectedGrid={selectedGrid} />;
      })
    )
    }
    {
      viewType === "list" && (
        <div className="row my-2 justify-content-center px-0">
          <div className="col-12 col-md-6  col-lg-5">
            <div className="pg-text__bold my-3">{t('pracrs_line_4')}</div>
            {traits?.map((trait, i) => {
              if (trait.considerableTraits > 0)
                return <TraitListCard traitData={trait} key={i} index={i} />;
            })}
          </div>
          <div className="col-12 col-md-6 col-lg-5">
            <div className="pg-text__bold my-3">{t('pracrs_line_5')}</div>
            {traits?.map((trait, i) => {
              if (trait.considerableTraits < 1)
                return <TraitListCard traitData={trait} key={i} />;
            })}
          </div>
          {/* </div> */}
        </div>
      )
    }
    {
      viewType === "table" && <div>
        <TraitTableTypeContainer viewType={viewType} tableFilter={tableFilter} collapsible={collapsible} psStatus={psStatus} />
      </div>
    }
  </>
}

export default TraitViewContainer;
