import ContentLoader from "react-content-loader";
const L_Genotype = (props) => {
    return (
        <ContentLoader
            speed={2}
            width="100%"
            height="20%"
            viewBox="0 0 600 600"
            backgroundColor="#9e9e9e52"
            foregroundColor="#18368f80"
            {...props}
        >
            {/* report summary navigator */}
            <rect x="0" y="5" rx="3" ry="3" width="52" height="6" />

            {/* download all button */}
            <rect x="520" y="55" rx="3" ry="3" width="52" height="6" />

            {/* title */}
            <rect x="0" y="46" rx="3" ry="3" width="288" height="26" />

            {/* patient name */}
            <rect x="0" y="85" rx="3" ry="3" width="102" height="10" />

            {/* para content */}
            <rect x="0" y="110" rx="3" ry="3" width="250" height="8" />
            <rect x="0" y="125" rx="3" ry="3" width="250" height="8" />
            <rect x="0" y="140" rx="3" ry="3" width="250" height="8" />
            <rect x="0" y="155" rx="3" ry="3" width="250" height="8" />

            {/* search input */}
            <rect x="104" y="188" rx="3" ry="3" width="400" height="30" />
            <rect x="0" y="200" rx="3" ry="3" width="52" height="6" />

            <rect x="0" y="250" rx="3" ry="3" width="600" height="40" />
            <rect x="0" y="300" rx="3" ry="3" width="600" height="25" />
            <rect x="0" y="330" rx="3" ry="3" width="600" height="25" />
            <rect x="0" y="360" rx="3" ry="3" width="600" height="25" />
            <rect x="0" y="390" rx="3" ry="3" width="600" height="25" />
            <rect x="0" y="420" rx="3" ry="3" width="600" height="25" />
            <rect x="0" y="450" rx="3" ry="3" width="600" height="25" />
            <rect x="0" y="480" rx="3" ry="3" width="600" height="25" />
            <rect x="0" y="510" rx="3" ry="3" width="600" height="25" />
            <rect x="0" y="540" rx="3" ry="3" width="600" height="25" />
            <rect x="0" y="570" rx="3" ry="3" width="600" height="25" />
        </ContentLoader>
    )
}

export default L_Genotype
