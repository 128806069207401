import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import toastr from 'react-hot-toast'
import { parse } from "query-string";
import _ from 'lodash'

import ActionButtons from '../AdminStaffActionButtons'
import EditStaffModal from './AdminEditStaffModal'
import StaffBatchFnModal from './AdminStaffBatchFnModal'

import { archiveStaffs, restoreStaffs, resetPasswordStaffs, sendRegistrationReminder } from '../../../store/actions/practiceDashboard'
import { resetLockout } from '../../../store/actions/adminStaff'
import { toggleLoading } from '../../../store/actions/ui'
import { isAllArrayElementHas, isAnyArrayElementHas } from '../../../methods'



function StaffBatchFn({
    filter = [],
    setSelectedPractitioner, selectedPractitioner, practitioners, updatePractitionerTable }) {

    const dispatch = useDispatch();
    const token = useSelector(state => state.auth.token);
    const masterAdmin = useSelector(state => state.auth.masterAdmin);
    const [showEditModal, setShowEditModal] = useState(false)
    const [showResetModal, setShowResetModal] = useState(false);
    const [showLockoutModal, setShowLockoutModal] = useState(false)
    const [showArchiveModal, setShowArchiveModal] = useState(false)
    const [showRestoreModal, setShowRestoreModal] = useState(false)
    const [showApproveModal, setShowApproveModal] = useState(false)
    const [showRegistrationReminderModal, setShowRegistrationReminderModal] = useState(false)

    const currentPractice = useSelector((state) => state.auth.currentPractice);
    const currentPracticeID = useSelector((state) => state.auth.currentPracticeID);

    const onRestore = () => {
        if (isAllArrayElementHas(practitioners, selectedPractitioner, 'aasm_state', "archived")) {
            dispatch(toggleLoading(true));
            dispatch(restoreStaffs(token, selectedPractitioner, currentPractice, currentPracticeID)).then(response => {
                dispatch(toggleLoading(false));
                if (!response.error) {
                    updatePractitionerTable();
                    setSelectedPractitioner([]);
                    toastr.success(response.payload.message)
                }
                setShowRestoreModal(false)
            });
        } else {
            setShowRestoreModal(false)
            toastr.error("Select only archived Staff member")
        }
    }
    const onArchive = () => {
        if (isAnyArrayElementHas(practitioners, selectedPractitioner, 'aasm_state', "archived")) {
            dispatch(toggleLoading(true));
            dispatch(archiveStaffs(token, selectedPractitioner, currentPractice, currentPracticeID)).then(response => {
                dispatch(toggleLoading(false));
                if (!response.error) {
                    toastr.success(response.payload.message);
                    setSelectedPractitioner([]);
                    updatePractitionerTable();
                }
                setShowArchiveModal(false)
            });
        } else {
            setShowArchiveModal(false)
            toastr.error("Select only active practitioner")
        }
    }

    const onReset = () => {
        dispatch(toggleLoading(true));
        dispatch(resetPasswordStaffs(token, selectedPractitioner, currentPractice, currentPracticeID)).then(response => {
            dispatch(toggleLoading(false));
            if (!response.error) {
                toastr.success(response.payload.message);
                setSelectedPractitioner([]);
                updatePractitionerTable();
            }
            setShowResetModal(false)
        });
    }

    const onRegistrationReminder = () => {
        if (isAllArrayElementHas(practitioners, selectedPractitioner, 'aasm_state', "pending")) {
            dispatch(toggleLoading(true));
            dispatch(sendRegistrationReminder(token, selectedPractitioner, currentPractice, currentPracticeID)).then(response => {
                dispatch(toggleLoading(false));
                if (!response.error) {
                    toastr.success(response.payload.message);
                    setSelectedPractitioner([]);
                    updatePractitionerTable();
                }
                setShowRegistrationReminderModal(false)
            });
        } else {
            setShowRegistrationReminderModal(false)
            toastr.error("Select only pending staff member")
        }

    }

    const onLockout = () => {
        dispatch(toggleLoading(true));
        dispatch(resetLockout(token, selectedPractitioner)).then(response => {
            dispatch(toggleLoading(false));
            if (!response.error) {
                toastr.success(response.payload.message);
                setSelectedPractitioner([]);
                updatePractitionerTable();
            }
            setShowLockoutModal(false)
        });;
    }

    return (
        <div className="my-2 mt-4">
            {showEditModal && <EditStaffModal
                showModal={showEditModal}
                onCancel={() => setShowEditModal(false)}
                updatePractitionerTable={updatePractitionerTable}
                practitioner={practitioners[_.findIndex(practitioners, { id: selectedPractitioner[0] })] || {}}
            />}
            {showResetModal && <StaffBatchFnModal
                onCancel={() => setShowResetModal(false)}
                showModal={showResetModal}
                onYes={onReset}
            >
                <div className="pg-text__bold my-4">
                    Are you sure you want to reset the password for the selected staff member?
                </div>
            </StaffBatchFnModal>}
            {showArchiveModal && <StaffBatchFnModal
                showModal={showArchiveModal}
                onCancel={() => setShowArchiveModal(false)}
                onYes={onArchive}
            >
                <div className="pg-text__bold my-4">
                    Are you sure to archive selected Staff Member ?
                </div>
            </StaffBatchFnModal>}
            {showRestoreModal && <StaffBatchFnModal
                showModal={showRestoreModal}
                onCancel={() => setShowRestoreModal(false)}
                onYes={onRestore}
            >
                <div className="pg-text__bold my-4">
                    Are you sure to restore the selected archived Staff Members ?
                </div>
            </StaffBatchFnModal>}
            {showRegistrationReminderModal && <StaffBatchFnModal
                showModal={showRegistrationReminderModal}
                onCancel={() => setShowRegistrationReminderModal(false)}
                onYes={onRegistrationReminder}
            >
                <div className="pg-text__bold my-4">
                    Are you sure to send registration emails to the selected staff member?
                </div>
            </StaffBatchFnModal>}
            {showLockoutModal && <StaffBatchFnModal
                showModal={showLockoutModal}
                onCancel={() => setShowLockoutModal(false)}
                onYes={onLockout}
            >
                <div className="pg-text__bold my-4">
                    Are you sure to reset lockout for selected Staff?
                </div>
            </StaffBatchFnModal>}
            <ActionButtons
                onArchive={() => setShowArchiveModal(true)}
                onEdit={() => setShowEditModal(true)}
                onReset={() => setShowResetModal(true)}
                onRestore={() => setShowRestoreModal(true)}
                onRegistrationReminder={() => setShowRegistrationReminderModal(true)}
                onLockout={() => setShowLockoutModal(true)}
                disableEdit={selectedPractitioner.length !== 1}
                disableArchive={selectedPractitioner.length === 0 || !isAnyArrayElementHas(practitioners, selectedPractitioner, 'aasm_state', 'archived')}
                disableReset={selectedPractitioner.length === 0 || !isAnyArrayElementHas(practitioners, selectedPractitioner, 'aasm_state', 'pending') || !isAnyArrayElementHas(practitioners, selectedPractitioner, 'aasm_state', 'awaiting') || !isAnyArrayElementHas(practitioners, selectedPractitioner, 'aasm_state', 'archived')}
                disableLockout={selectedPractitioner.length === 0 || !isAllArrayElementHas(practitioners, selectedPractitioner, 'aasm_state', 'locked')}
                disableRestore={selectedPractitioner.length === 0 || !isAllArrayElementHas(practitioners, selectedPractitioner, 'aasm_state', 'archived')}
                disableApprove={selectedPractitioner.length === 0 || !isAllArrayElementHas(practitioners, selectedPractitioner, 'aasm_state', 'pending')}
                disableRegistrationReminder={selectedPractitioner.length === 0 || !isAllArrayElementHas(practitioners, selectedPractitioner, 'aasm_state', 'pending')}                
            />
        </div>
    )
}

export default StaffBatchFn
