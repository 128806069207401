import React, { useState } from "react";
import { useDispatch } from 'react-redux';

import toast from 'react-hot-toast';
import { useTranslation } from "react-i18next"

import './Login.css'
import TextInput from "../../components/TextInput";
import { validateEmail } from "../../methods";
import { forgotPassword } from '../../store/actions/auth'
import LoginIssuesContact from './LoginIssuesContact'

function ForgotPasswordPage() {
  const { t } = useTranslation()
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [responseError, setresponseError] = useState("");

  // redux actions
  const dispatch= useDispatch();
  const forgotPasswordAction = (email) => dispatch(forgotPassword(email));

    let onTextInput = (e) => {
    if (!validateEmail(e.target.value.trim())) {
      setEmailError(true);
    } else {
      setEmailError(false);
      setEmail(e.target.value.toLowerCase());
    }
  };

  let handleLogin = async (e) => {
    e.preventDefault();
    if (email.length === 0 || emailError) {
      setEmailError(true);
    } else {
      const response = await forgotPasswordAction(email.trim());
      if (!response.error)
        toast.success(response.payload.success, { duration: 6000 })
    }
  };
  return (
    <div className="container sign-in__container pt-5">
      <div
        className="row justify-content-center p-2 my-5 mt-5 pb-5"
        style={{ height: "100%" }}
      >
        <div className="col-12 col-md-8 col-lg-6">
          <div
            className="text-center"
            style={{
              color: "var(--pg_primary)",
              fontSize: '44px',
              fontFamily: "Varela Round",
              lineHeight: "70px",
            }}
          >
            {t('fp_line_1')}
          </div>
          <div className="w-100 pt-4">
            <form onSubmit={handleLogin}>
              <TextInput
                label={t('auth_page_email_ph')}
                required={true}
                type="text"
                inputHandler={onTextInput}
                emailField={true}
                placeholder={t('auth_page_email_ph')}
                error={emailError}
                errorMessage={t('auth_page_email_error')}
                id="email-input__forgotpassword"
              />
              {responseError.length !== 0 && (
                <div className="alert alert-danger textc-danger">
                  <i className="fas fa-info-circle"></i> {responseError}
                </div>
              )}
              <input
                type="submit"
                value={t('send')}
                className="py-2 btn w-100 bgc-primary text-light"
                id="submit-btn__forgotpassword"
                style={{ background: "var(--pg_primary) !important" }}
              />
            </form>
          </div>
          <div className="pt-5 pb-5 mb-5 mt-5"></div>
        </div>
      </div>
      <LoginIssuesContact />
    </div>
  );
}
export default ForgotPasswordPage;