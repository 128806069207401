import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function GeneticUploadErrorModal({
  onCancel,
  showModal,
  onSubmit,
  context,
  uploadSnp
}) {
  const { t, i18n } = useTranslation();
const navigate = useNavigate();
  return (
    <div>
      <Modal
        show={showModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={onCancel}
        contentClassName="border-radius"
        backdrop="static"
        className="edit-patient-modal"
      >
        <Modal.Header closeButton>
          <div className="pg-text text-center">
            <div className="pg-heading my-3 mx-4 text-center">{t('upload_error')}</div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-12">
                {
                  context === "practitioner" ||
                    (context === "patient" && uploadSnp)
                    ?
                    <div>
                      <div dangerouslySetInnerHTML={{ __html: t('pracpl_guem_line_1') }} />
                      {
                        context === "patient" &&
                        <div dangerouslySetInnerHTML={{ __html: t('pracpl_guem_line_2') }} />
                      }
                      {
                        context !== "patient" &&
                          <div dangerouslySetInnerHTML={{ __html: t('pracpl_guem_line_3') }} />
                      }
                    </div>
                    :
                    <div dangerouslySetInnerHTML={{__html:t('pracpl_guem_line_4')}} />
                }
              </div>
              <div className="col-12">
                {
                  (context === "patient" && uploadSnp) &&
                  <button
                    className="btn btn-primary__p text-light px-5 my-3 mx-1 w-100"
                    onClick={() => onSubmit()}
                  >
                    {t('try_again')}
                  </button>
                }
                {
                  (context === "patient" && !uploadSnp) &&
                  <br />
                }
                {
                  context === "practitioner" &&
                  <button
                    className="btn btn-primary text-light px-5 my-3 mx-1 w-100"
                    onClick={() => onSubmit()}
                  >
                    {t('try_again')}
                  </button>
                }
                <div dangerouslySetInnerHTML={{__html:t('support_line')}} />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default GeneticUploadErrorModal;
