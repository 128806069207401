import React, { Component, useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { connect, useSelector, useDispatch } from "react-redux";
import toastr from "react-hot-toast";
import _ from "lodash";

import TextInput from "../../../components/TextInput";
import { validateEmail } from "../../../methods";
import {
  addStaff,
  getStaffPermissions,
} from "../../../store/actions/practiceDashboard";
import PgCheckbox from "../../../components/PgCheckbox";
import { useTranslation } from "react-i18next";

function AddStaff({
  token,
  showModal,
  onCancel,
  addStaffAction,
  updateStaffTable,
  context,
}) {
  const { t, i18n } = useTranslation();
  const regions = useSelector((state) => state.auth.region);
  const dispatch = useDispatch();
  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const currentPracticeID = useSelector(
    (state) => state.auth.currentPracticeID
  );

  const [isValidForm, setIsValidForm] = useState(false);
  //form fields
  const [fName, setfName] = useState("");
  const [lName, setlName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  //for validation
  const [fNameError, setfNameError] = useState(false);
  const [lNameError, setlNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [optionalPermissions, setOptionalPermissions] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  const onDone = () => {
    setfNameError(false);
    setlNameError(false);
    setEmailError(false);
    setPhoneError(false);

    if (email.length === 0 || !validateEmail(email)) setEmailError(true);
    if (fName.length === 0) setfNameError(true);
    if (lName.length === 0) setlNameError(true);
    if (phone.length === 0) setPhoneError(true);
    if (
      email.length !== 0 &&
      validateEmail(email) &&
      fName.length !== 0 &&
      lName.length !== 0 &&
      phone.length !== 0
    ) {
      setIsValidForm(true);
    }
  };

  useEffect(() => {
    dispatch(
      getStaffPermissions(token, currentPractice, currentPracticeID)
    ).then((response) => {
      if (!response.error) {
        if (Array.isArray(response.payload?.optional_permissions))
          setOptionalPermissions(
            response.payload?.optional_permissions.map((permission) => ({
              key: _.lowerCase(permission),
              value: permission,
            }))
          );
      }
    });
  }, []);

  useEffect(() => {
    if (isValidForm) {
      handleDone();
    }
  }, [isValidForm]);

  const handleDone = async () => {
    let data = {
      staff: {
        first_name: _.startCase(fName),
        last_name: lName,
        full_name: `${_.startCase(fName)} ${lName}`,
        email: email.toLowerCase(),
        phone_number: phone,
        access_roles: selectedPermissions,
      },
    };
    setLoading(true);
    const response = await addStaffAction(
      token,
      data,
      currentPractice,
      currentPracticeID
    );
    setLoading(false);
    if (response.error) {
      setIsValidForm(false);
    } else {
      if (context === "practitioner-list") updateStaffTable();
      onCancel();
      toastr.success(t("staff_alert_txt_1"));
    }
  };

  const loadPermissions = (value) => {
    setSelectedPermissions((selectedValue) => {
      let oldSelectedValue = [...selectedValue];
      if (oldSelectedValue.includes(value)) {
        return oldSelectedValue.filter(
          (singleSelectedValue) => singleSelectedValue != value
        );
      } else {
        return [...oldSelectedValue, value];
      }
    });
  };

  function OptionalPermission() {
    return optionalPermissions.map(({ key, value }, index) => {
      return (
        <PgCheckbox
          name="optional-permission"
          id={index}
          key={index}
          checked={selectedPermissions.includes(value)}
          onCheck={() => loadPermissions(value)}
          title={t(key)}
        />
      );
    });
  }

  return (
    <div>
      <Modal
        show={showModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={onCancel}
        contentClassName="border-radius"
        backdrop="static"
        className="add-practitioner-modal"
      >
        <Modal.Body>
          <div
            style={{
              position: "absolute",
              right: 25,
              top: 15,
              fontSize: 20,
              cursor: "pointer",
              color: "var(--pg_primary)",
            }}
            onClick={onCancel}
          >
            <div className="fas fa-times"></div>
          </div>
          <div className="container">
            <div className="text-center pg-title">
              {t("staff_members_new_header")}
            </div>
            <div className="row">
              <div className="col-12">
                <TextInput
                  label={t("staff_members_new_fname")}
                  showLabel={true}
                  required={true}
                  id="f-name__addpractitioner"
                  placeholder={t("staff_members_new_fname_ph")}
                  value={fName}
                  error={fNameError}
                  type="text"
                  errorMessage={t("staff_members_new_fname_error")}
                  inputHandler={(e) => {
                    setfName(e.target.value);
                  }}
                />

                <TextInput
                  label={t("staff_members_new_lname")}
                  showLabel={true}
                  required={true}
                  id="l-name__addpractitioner"
                  placeholder={t("staff_members_new_lname_ph")}
                  value={lName}
                  error={lNameError}
                  errorMessage={t("staff_members_new_lname_error")}
                  inputHandler={(e) => {
                    setlName(e.target.value);
                  }}
                />

                <TextInput
                  label={t("staff_members_new_email")}
                  showLabel={true}
                  required={true}
                  emailField={true}
                  id="email-input__addpractitioner"
                  placeholder={t("staff_members_new_email_ph")}
                  value={email}
                  error={emailError}
                  errorMessage={t("staff_members_new_email_error")}
                  inputHandler={(e) => {
                    if (
                      e.target.value.length < 0 &&
                      !validateEmail(e.target.value)
                    ) {
                      setEmailError(true);
                    } else {
                      setEmailError(false);
                      setEmail(e.target.value);
                    }
                  }}
                />
                <TextInput
                  label={t("staff_members_new_phone")}
                  showLabel={true}
                  required={true}
                  id="phone__addpractitioner"
                  placeholder={t("staff_members_new_phone_ph")}
                  error={phoneError}
                  errorMessage={t("staff_members_new_phone_error")}
                  value={phone}
                  inputHandler={(e) => {
                    setPhone(e.target.value);
                  }}
                />

                <div className="d-inline-block pg-text__bold textc-primary">
                  {t("staff_members_new_optional_permissons")}
                </div>
                <OptionalPermission />
                <button
                  className="btn btn-primary text-light px-5 my-1 mx-1  w-100"
                  onClick={onDone}
                >
                  {t("staff_members_new_save_btn")}
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    practitionersTypeList: state.adminPractitioner.practitioner_types,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    addStaffAction: (token, data, currentPractice, currentPracticeID) =>
      dispatch(addStaff(token, data, currentPractice, currentPracticeID)),
  };
};

export default connect(mapStateToProps, mapDispatchtoProps)(AddStaff);
