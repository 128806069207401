import * as pg4_constant from "../actions/pg4report";

const initialState = {
    reportLoading: true,
    patientDetailLoading: true,
    deletingRecommendation: false,
    deleteError: null,
    reportError: false,
    reportData: {},
    patientInfo: {},
};


const Pg4ReportReducer = (state = initialState, action) => {
    switch (action.type) {
        case pg4_constant.PG4_REPORT_REQUEST: {
            return { ...state, reportLoading: true, reportData: {}, error: {}, reportError: false };
        }
        case pg4_constant.PG4_REPORT_SUCCESS: {
            return { ...state, reportLoading: false, reportData: action.payload, reportError: false };
        }
        case pg4_constant.PG4_REPORT_FAILURE: {
            return { ...state, reportLoading: false, error: { ...action.payload }, reportError: true };
        }
        case pg4_constant.PG4_DELETE_RECOMMENDATION_REQUEST: {
            return { ...state, deletingRecommendation: true };
        }
        case pg4_constant.PG4_DELETE_RECOMMENDATION_SUCCESS: {
            return { ...state, deletingRecommendation: false };
        }
        case pg4_constant.PG4_DELETE_RECOMMENDATION_FAILURE: {
            return { ...state, deletingRecommendation: false, deleteError: action.payload };
        }
        case pg4_constant.CLEAR_STATE: {
            return {
                reportLoading: true,
                patientDetailLoading: true,
                reportData: {},
                patientInfo: {},
            };
        }
        default:
            return state;
    }
};

export default Pg4ReportReducer;

