import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import _, { set } from "lodash";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import L_LabUpload from "../../components/LoadingEffectComponent/L_LabUpload";
import LabTest from "../../components/Patient/Lab/LabTest";
import LabTestForManualEntry from "../../components/Patient/Lab/LabTestForManualEntry"
import { labUpload, getLabUploadStatus } from "../../store/actions/labUpload";
import { isPdfFiles } from "../../methods";
import AfterLabFilesSelected from "../../components/Practice/Lab/AfterLabFilesSelected";
import PgPrompt from "../../components/PgPrompt";
import AfterUploadStarted from "../../components/Practice/Lab/AfterUploadStarted";

function PatientLabUpload() {
  const navigate = useNavigate();
  const routerLoc = useLocation();
  // redux state variable

  const currentPracticeID = useSelector(state => state.auth.currentPracticeID);
  const currentPractice = useSelector(state => state.auth.currentPractice);
  const currentUserId = useSelector(state => state.auth.currentUserId);
  const token = useSelector(state => state.auth.token);
  const language = useSelector(state => state.auth.language);

  // redux actions
  const dispatch = useDispatch();
  const labUploadAction = (token, currentPracticeID, currentPractice, data) => dispatch(labUpload(token, currentPracticeID, currentPractice, data));
  const getLabUploadStatusAction = (token, currentPracticeID, currentPractice, data) => dispatch(getLabUploadStatus(token, currentPracticeID, currentPractice, data));


  const [disableDoneButton, setDisableDoneButton] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [vendorId, setVendorId] = useState(-1);
  const [isGettingCurrentPatient, setIsGettingCurrentPatient] = useState(true);
  const [currentPatientStatus, setCurrentPatientStatus] = useState({
    uploadStarted: routerLoc?.state?.isOldFile,
    uploadError: false,
    isFileSelected: routerLoc?.state?.isOldFile,
    fileSize: null,
    patientId: "",
    tests: [],
    files: [],
    documentId: routerLoc?.state?.dID,
    status: "Uploading",
    error: false,
  });
  const { t, i18n } = useTranslation();
  let intervalTracker;

  useEffect(() => {
    if (routerLoc.state?.isOldFile) {
      if (!currentPatientStatus.uploadError) updateLoadingStatus(routerLoc?.state?.dID, routerLoc?.state?.dIDs);
    }
    setIsGettingCurrentPatient(false);
    return () => clearInterval(intervalTracker);
  }, []);

  useEffect(() => {
    // if (currentPatientStatus.uploadStarted) {
    //   setDisableDoneButton(false);
    //   if (!currentPatientStatus.uploadError) updateLoadingStatus();
    // }
    // return () => clearInterval(intervalTracker);
    console.log({ currentPatientStatus }, 'routerLoc?', routerLoc?.state)
  }, [currentPatientStatus]);


  const selectTests = (testType) => {
    setCurrentPatientStatus(preLabFileStatus => {
      const _labFileStatus = { ...preLabFileStatus };
      if (_labFileStatus.tests.includes(testType))
        _labFileStatus.tests = _labFileStatus.tests.filter((test) => test !== testType);
      else _labFileStatus.tests.push(testType);
      return _labFileStatus;
    })
  };

  const selectVendor = (vendor_id) => {
    setVendorId(vendor_id);
  };

  const onFilePicked = (e, t = 0) => {
    const pickedFiles = e.target.files;
    if (isPdfFiles(pickedFiles)) toast.error(t('lab_and_genetic_line_6'));
    else {
      setCurrentPatientStatus(preLabFileStatus => {
        const _labFileStatus = { ...preLabFileStatus };
        _labFileStatus.files = [...pickedFiles];
        _labFileStatus.isFileSelected = true;
        _labFileStatus.fileSize = pickedFiles.length;
        if (t === 2) {
          _labFileStatus.status = "Uploading";
          _labFileStatus.error = false;
          _labFileStatus.uploadError = false;
          _labFileStatus.uploadStarted = false;
        }
        return _labFileStatus;
      })
    }
  };

  const addAnotherFile = (e) => {
    const file = e.target.files[0];
    setCurrentPatientStatus(labFileStatus => {
      let _labFileStatus = { ...labFileStatus };
      if (file.type !== "application/pdf")
        return toast.error(t('lab_and_genetic_line_6'));
      if (
        _.find(
          _labFileStatus.files,
          (_file) => _file.name === file.name
        )
      )
        toast.error("File already exist!");
      else {
        _labFileStatus.files.push(file);
        _labFileStatus.fileSize++;

      }
      return _labFileStatus;
    })
  };

  const removeSelectedFile = (i) => {
    setCurrentPatientStatus(labFileStatus => {
      let _labFileStatus = { ...labFileStatus };
      // validating duplicate file
      const file = _labFileStatus.files[i];
      let fileLength = _labFileStatus.files;
      _labFileStatus.files = _labFileStatus.files.filter((_file) => _file.name !== file.name);
      _labFileStatus.fileSize--;
      fileLength = _labFileStatus.files.length;
      _labFileStatus.isFileSelected =
        fileLength === 0 ? false : true;
      return _labFileStatus;
    })
  };

  const onStartUpload = () => {
    let data = new FormData();
    data.append("lab_vendor_id", vendorId);
    data.append("patient_id", currentUserId);
    data.append("user_type", "Patient");
    data.append("tests_ids", currentPatientStatus.tests);
    data.append("uploaded_by", currentUserId);
    for (var i = 0; i < currentPatientStatus.fileSize; i++) {
      data.append(
        `files[${i}]`,
        currentPatientStatus.files[i],
        currentPatientStatus.files[i].name
      );
    }
    setCurrentPatientStatus(preLabFileStatus => {
      return {
        ...preLabFileStatus,
        uploadStarted: true,
        status: "Uploading",
        statusText: "",
        error: false,
      };
    })
    labUploadAction(token, currentPracticeID, currentPractice, data).then(
      (response) => {
        if (response.error) {
          setDisableDoneButton(false);
          setCurrentPatientStatus(preLabFileStatus => {
            return {
              ...preLabFileStatus,
              uploadError: true,
              error: true,
            };
          });
        } else {
          setDisableDoneButton(false);
          updateLoadingStatus(response.payload.lab_document_session.id, response.payload.document_ids);
          setCurrentPatientStatus((preLabFileStatus) => {
            return {
              ...preLabFileStatus,
              documentId: response.payload.lab_document_session.id,
              documentIds: response.payload.document_ids,
              status: response.payload.lab_document_session.code,
              uploadError: false,
              error: false,
              statusText: response.payload.message,
            };
          });
        }
      }
    );
  };

  const _getLabUploadStatusAction = (dID, dIDs) => {
    let data = {
      type: "lab",
      document_id: dID ? dID : currentPatientStatus.documentId,
    };
    getLabUploadStatusAction(
      token,
      currentPracticeID,
      currentPractice,
      data
    ).then((response) => {
      if (!response.payload.success) {
        clearInterval(intervalTracker);
        setCurrentPatientStatus(labFileStatus => {
          return { ...labFileStatus, error: true, errorText: response.payload.message };
        })
      } else if (response.payload.lab_process_completed) {
        clearInterval(intervalTracker);
        setCurrentPatientStatus(preLabFileStatus => {
          return {
            ...preLabFileStatus,
            status: "Completed",
            error: false,
          };
        })
        if (response.payload.current_state === "Complete")
          navigate("/patient/lab/review", { state: { documentIds: dIDs ? dIDs : currentPatientStatus.documentIds, ...currentPatientStatus, data } });
      } else {
        setCurrentPatientStatus(preLabFileStatus => {
          return {
            ...preLabFileStatus, status: response.payload.current_state,
            statusText: response.payload.message,
            error: false,
          };
        })
      }
    });
  };

  const updateLoadingStatus = (dID, dIDs) => {
    // this function will be called immediately
    _getLabUploadStatusAction(dID, dIDs);
    intervalTracker = setInterval(() => {
      _getLabUploadStatusAction(dID, dIDs);
    }, 5000);
  };

  const onTryAgain = () => {
    setCurrentPatientStatus(preLabFileStatus => {
      return { ...preLabFileStatus, uploadStarted: false, isFileSelected: false };
    })
  };

  return (
    <>
      <Helmet>
        <title>{t('puregenomics_1')} - Patient Lab upload</title>
      </Helmet>
      {isGettingCurrentPatient ? (
        <L_LabUpload loaderSection={language == "en" ? 3 : 2} />
      ) : (
        <div className="container-fluid">
          <PgPrompt
            show={showAlert}
            onHide={() => setShowAlert(false)}
            onCancel={() => setShowAlert(false)}
            onAccept={() => navigate("/patient/dashboard")}
          />
          <div>
            <div className="container-xxl py-4 px-lg-1 px-md-2 px-1">
              <div className="row mt-4 justify-content-center justify-content-lg-between">
                <div
                  className="pg-link"
                  onClick={() =>
                    currentPatientStatus.uploadStarted && disableDoneButton
                      ? setShowAlert(true)
                      : navigate("/patient/dashboard")
                  }
                >
                  <i className="fa fa-chevron-left me-2" aria-hidden="true"></i>
                  {t('dashboard')}
                </div>
                <div className="col-12">
                  <div className="position-relative d-inline-block">
                    <div className="pg-title  color-white my-2 d-inline-block">
                      {t('lab_upload_page_title')}
                    </div>
                    <div
                      className="pg-text__bold"
                      style={{
                        position: "absolute",
                        top: 15,
                        right: -45,
                        color: "#4f7a28",
                      }}
                    >
                      {i18n.language === "en" ? t('beta_text') : ""}
                    </div>
                  </div>
                </div>
                <div className="pg-heading my-2">
                  {routerLoc?.state?.patientName}
                </div>
                {!currentPatientStatus.isFileSelected &&
                  !currentPatientStatus.uploadStarted && (
                    <div className="col-12 pg-text mt-3">
                      <span dangerouslySetInnerHTML={{ __html: t('lab_upload_page_title_2') }} />

                    </div>
                  )}
              </div>
              <hr className="my-4" />
            </div>
          </div>
          {
            <div>
              {!currentPatientStatus.isFileSelected &&
                !currentPatientStatus.uploadStarted && (
                  language === "en" ?
                    <LabTest
                      labdata={currentPatientStatus}
                      selectedVendorId={vendorId}
                      selectTests={selectTests}
                      selectVendor={selectVendor}
                      onFilePicked={onFilePicked}
                    /> :
                    <LabTestForManualEntry
                      labdata={currentPatientStatus}
                      selectedVendorId={vendorId}
                      selectTests={selectTests}
                      selectVendor={selectVendor}
                    />
                )}

              {/* after file is picked */}
              {currentPatientStatus.isFileSelected &&
                !currentPatientStatus.uploadStarted && (
                  <AfterLabFilesSelected
                    onStartUpload={onStartUpload}
                    addAnotherFile={addAnotherFile}
                    labData={currentPatientStatus}
                    removeSelectedFile={removeSelectedFile}
                  />
                )}

              {/* after start upload */}
              {currentPatientStatus.uploadStarted &&
                currentPatientStatus.isFileSelected && (
                  <div>
                    <div className="my-5">
                      <AfterUploadStarted
                        uploadType="lab"
                        status={currentPatientStatus.status}
                        error={currentPatientStatus.error}
                        message={currentPatientStatus.statusText}
                        errorText={currentPatientStatus.errorText}
                        onTryAgain={() => onTryAgain()}
                      />
                    </div>
                    <div className="mt-5 pt-5" />
                    <div className="mt-5 pt-5" />
                    <div className="mt-5 pt-5" />
                  </div>
                )}
            </div>
          }

          {/* action button */}
          {/* <div className="row justify-content-center bgc-secondary py-3">
            <div className="col-lg-4 col-md-6 col-10">
              <button
                className="w-100 btn bg-sky-blue px-5 textc-primary py-2"
                disabled={disableDoneButton}
                onClick={onDone}
              >
                Done
              </button>
            </div>
          </div> */}
        </div>
      )}
    </>
  );
}

export default PatientLabUpload;
