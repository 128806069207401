import PgInfoTooltip from "../../PgInfoTooltip";

export default function MSQToolTipText1() {
  return (
    <PgInfoTooltip position="left center" stroke="#000" height={30} width={30}>
      {/* <div style={{ padding: "12px" }}> */}
      This is calculated by assigning scores of 0, 1, 2, and 3 to the response
      categories, respectively, of <br />
      “not at all,” “several days,” “more than half the days,” and “nearly every
      day.” <br />
      GAD-7 total score for the seven items ranges from 0 to 21.
      <br />
      <br />
      0-4: minimal anxiety
      <br />
      5-9: mild anxiety
      <br />
      >=8: further assessment is warranted
      <br />
      10-14: moderate anxiety
      <br />
      15-21: severe anxiety
      <br />
      {/* </div> */}
    </PgInfoTooltip>
  );
}
