//  For PHQ
import PgInfoTooltip from "../../PgInfoTooltip";

export default function MSQToolTipText4() {
  return (
    <PgInfoTooltip position="left center" stroke="#000" height={30} width={30}>
      The Multi Symptom Questionnaire (MSQ) score is calculated based on
      responses.
      <br />
      <br />
      0-12: Good
      <br />
      13-37: Consider Action
      <br />
      38+: Elevated
      <br />
    </PgInfoTooltip>
  );
}
