import DashboardReportTable from "../../components/Patient/PG4/DashboardReportTable";
import { useDispatch, useSelector } from "react-redux";
import { getAVQRecommendation, getPatientDashboard, questionnaireStarted, retakeQuestionnaire } from "../../store/actions/pg4dashboard";
import { useEffect, useRef, useState } from "react";
import ScrollToTopFAB from "../../components/ScrollToTopFAB";
import NewInformation from "../Patient/Report/NewInformation";
import SupportAndResources from "../Patient/Report/SupportAndResources";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import L_PG4Dashboard from "../../components/LoadingEffectComponent/PG4/L_PG4Dashboard";
import { updateLabUpload } from "../../store/actions/labUpload";
import _ from "lodash";
import { formatDate } from "../../methods";
import AssignedCard from "../../components/Patient/PG4/dashboard/AssignedCard";
import { CompletedCard } from "../../components/Patient/PG4/dashboard/CompletedCard";
import { reportTableData } from "../../components/Patient/PG4/constant";
import { getPatientScriptStatus } from "../../store/actions/patientDashboard";
import { SupplementRecommendationCard } from "../../components/Patient/PG4/dashboard/SupplementRecommendationCard";
import { OrderHistoryCard } from "../../components/Patient/PG4/dashboard/OrderHistoryCard";
import PatientTAC from "../../components/Patient/PatientTAC";
import { OurProductCard } from "../../components/Patient/PG4/dashboard/OurProductCard";

const PatientDashboard = ({ }) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [dashboardData, setDashboardData] = useState({});
    const token = useSelector(state => state.auth.token);
    const currentPractice = useSelector(state => state.auth.currentPractice);
    const firstName = useSelector(state => state.auth.firstName);
    const lastName = useSelector(state => state.auth.lastName);
    const patientId = useSelector(state => state.auth.currentUserId);
    const currentPracticeID = useSelector(state => state.auth.currentPracticeID);
    const appToken = useSelector(state => state.auth.appToken);
    const [isLoading, setIsLoading] = useState(true);
    const getDashboardFn = () => dispatch(getPatientDashboard({ token, currentPractice }));
    const getPatientScriptStatusAction = (patientId, profileId, tenantId, appToken) => dispatch(getPatientScriptStatus(patientId, profileId, tenantId, appToken));
    const getAVQRecommendationAction = (patientId, profileId, tenantId, appToken) => dispatch(getAVQRecommendation(patientId, profileId, tenantId, appToken));
    const [hasScript, setHasScript] = useState(false);
    const [hasOrder, setHasOrder] = useState(false)
    const intervalController = useRef(null);
    const language = useSelector((state) => state.auth.language);
    const practitionerCountry = useSelector((state) => state.auth.country);

    useEffect(() => {
        // console.log(location?.state?.r_id ? 'Check for recome' : 'dont check');
        // if (location?.state?.r_id) {
        //     const intervalId = setInterval(async () => {
        //         const res = await getAVQRecommendationAction();
        //         if (!res.error) {
        //             if (res.payload.status === "completed")
        //                 clearInterval(intervalController.current);
        //         } else clearInterval(intervalController.current)
        //     }, 2000);
        //     intervalController.current = intervalId;
        // }
        // return () => clearInterval(intervalController.current)
    }, []);

    useEffect(() => {
        _getDBFn();
        _checkHasScript();
    }, []);

    const _checkHasScript = async () => {
        const response = await getPatientScriptStatusAction(patientId, null, currentPracticeID, appToken)
        if (!response.error) {
            setHasScript(response?.payload?.patient_has_recommendations)
            setHasOrder(response?.payload?.patient_has_orders)
        }
    }
    const _getDBFn = async () => {
        const response = await getDashboardFn();
        setIsLoading(false)
        if (!response.error) {
            setDashboardData(response.payload)
            console.log(response);
        }
    }

    return (<div className="py-4">
        <PatientTAC />
        {isLoading
            ? <L_PG4Dashboard />
            : <div>
                <ScrollToTopFAB />
                <div className="container py-4">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-12 col-xl-11">
                            <div className="row justify-content-between">
                                <div className="col-12 ">
                                    <div className="pg4-text__title textc-primary__p" id="stt-ref">
                                    {t('pracd_welcome')}, {firstName + " " + lastName}
                                    </div>
                                    {!_.isEmpty(dashboardData?.reports?.data) ? <div className="my-4">
                                        <div className="d-flex justify-content-between">
                                            <div className="pg4-text__heading textc-primary__p">
                                              {t('report')}
                                            </div>
                                        </div>
                                        <DashboardReportTable _data={dashboardData?.reports} />
                                    </div> :
                                        <div className="bg_secondary p-2 p-lg-3 my-4 rounded">
                                            <div className="d-flex justify-content-between flex-wrap  align-items-center">
                                                <div>
                                                    <b className="pg4-text__subheading text-uppercase">{t('pat_dashboard_header')}</b>
                                                    <p>{t('pat_dashboard_content_1')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {((dashboardData?.active_questionnaires?.msq || dashboardData?.active_questionnaires?.avq?.length > 0)) && <div className=" px-lg-0 dashboard-card__container bg_tertiary py-4" >
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-lg-12 col-xl-11">
                                <div className="row">
                                    {dashboardData?.active_questionnaires?.msq && (
                                        <>
                                            <div className="pg4-text__heading text_primary my-3">
                                            {t('pat_dashboard_content_2')}
                                            </div>
                                            <div className="col-12 col-lg-6 align-self-stretch my-3">
                                                <DashboardCardMini cardInfo={{ ...dashboardData.active_questionnaires.msq, showInfoBanner: true }} />
                                            </div>
                                        </>
                                    )}
                                    {dashboardData?.active_questionnaires?.avq?.length > 0 && (
                                        <>
                                            <div className="pg4-text__heading text_primary my-3">
                                                {/* Based on your results, your health care provider would like you to complete the following: */}
                                                {t('pat_dashboard_content_3')}
                                            </div>
                                            {dashboardData?.active_questionnaires?.avq?.map((card, index) => <div className="col-12 col-lg-6 align-self-stretch my-3" key={index} >
                                                <DashboardCardMini context="active" cardInfo={{ ...card, showInfoBanner: true }} key={index} />
                                            </div>)}
                                        </>
                                    )}
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
                {language === "en" && practitionerCountry == "United States" && <div className="px-lg-0 dashboard-card__container py-4" >
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-lg-12 col-xl-11">
                            <div className="row"><OurProductCard/></div>
                            </div>
                        </div>
                    </div>    
                </div>}
                {(Array.isArray(dashboardData?.others_assigned) && dashboardData?.others_assigned?.length > 0) && <div className="px-lg-0 dashboard-card__container py-4" >
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-lg-12 col-xl-11">
                                <div className="row">
                                    <div className="col-12 pg4-text__title text_primary my-2 mt-4">
                                    {t('pat_dashboard_content_4')} {dashboardData?.others_assigned?.length} items:
                                    </div>
                                    {dashboardData?.others_assigned?.map((card, index) => <div className="col-12 col-lg-6 align-self-stretch my-3" key={index} >
                                        <AssignedCard context="assigned" cardInfo={card} key={index} />
                                    </div>)}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>}
                <div className=" px-lg-0 dashboard-card__container" >
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-lg-12 col-xl-11">
                                <div className="row">
                                    {hasScript && <SupplementRecommendationCard />}
                                    {(hasOrder && language === "en") && <OrderHistoryCard />}
                                    {(Array.isArray(dashboardData?.completed) && dashboardData?.completed?.length > 0) && <><div className="col-12 pg4-text__title text_primary my-2 mt-4">
                                    {t('pat_dashboard_card_1')}
                                    </div>
                                        {dashboardData?.completed?.map((card, index) => <div className="col-12 col-lg-6 align-self-stretch my-3" key={index} >
                                            <CompletedCard context="context" cardInfo={card} key={index} />
                                        </div>)}</>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-12 col-xl-11">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-lg-6 mt-4 ">
                                    <NewInformation />
                                </div>
                                <div className="col-12 col-sm-12 col-lg-6 mt-4">
                                    <SupportAndResources />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
    </div>);
}

export default PatientDashboard;

const DashboardCardMini = ({ cardInfo, context }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const token = useSelector(state => state.auth.token);
    const patient_id = useSelector(state => state.auth.currentUserId);
    const currentPractice = useSelector(state => state.auth.currentPractice);
    const questionnaireStartedAction = (session_id) => dispatch(questionnaireStarted({ token, patient_id, currentPractice, session_id }));
    const updateLabFileStatus = (data) => dispatch(updateLabUpload(data));
    const labFileStatus = useSelector(state => state.labUpload.fileStatus);
    const { t, i18n } = useTranslation();

    const goToLabUploadPage = () => {
        const isOldFile = ((cardInfo.id) && (cardInfo.status?.toLowerCase() !== "completed"));
        console.log('locstate:', cardInfo, isOldFile);
        if (isOldFile) {
            navigate('/patient/lab', { state: { isOldFile, dID: cardInfo.id, dIDs: cardInfo.document_ids } })
        } else {
            navigate('/patient/lab', { state: { isOldFile } })
        }
    }


    const navigateFn = async () => {
        console.log({ cardInfo })
        const response = await questionnaireStartedAction(cardInfo?.session_id);
        console.log({ response })
        navigate("/patient/msq", { state: cardInfo })
    }
    return (<div className="pg4-card position-relative overflow-hidden dashboard-card bg-light h-100 my-3" >
        {cardInfo?.showInfoBanner && <div className="position-absolute p-3" style={{ transform: 'rotate(130deg)', top: -67, right: -55, height: 120, width: 120, background: 'var(--pg_primary__p)' }}>
            <div className="position-relative">
                <i className="position-absolute far fa-star fs-4" style={{ transform: 'rotate(158deg)', top: '30px', right: "-8px", color: '#fff' }}></i>
            </div>
        </div>}
        <div className="d-flex flex-column justify-content-between h-100">
            <div>
                <div className="pg4-text__heading textc-primary__p" >{cardInfo.heading}</div>
                <div className="pg4-text my-2">
                    {!cardInfo?.showInfoBanner && <>
                        <i className="far fa-clock"></i>  {cardInfo.timimg_data}
                    </>}
                    {(!cardInfo?.showInfoBanner && (cardInfo.card_type === "questionnaire_msq" || cardInfo.card_type === "questionnaire_avq")) &&
                        <div className="">
                            <br />
                            <b>{t('pat_dash_comp_card_icon_txt_1')}</b> {formatDate(cardInfo.last_completed)}
                            <br />
                            <b>{t('pat_dash_comp_card_icon_txt_2')}</b> {cardInfo.previous_score}
                            <br />
                            <b>{t('pat_dash_comp_card_icon_txt_3')}</b> {formatDate(cardInfo.recommeded_retake)}
                        </div>
                    }
                </div>
                <div className="pg4-text my-2" dangerouslySetInnerHTML={{ __html: cardInfo.html_content }} />
            </div>
            <button
                className="pg4-btn pg4-btn_primary w-sm-100 mx-0 mx-lg-5"
                onClick={navigateFn}
            >
                {cardInfo.button_text}
            </button>
            {(cardInfo.card_type === "genetic_upload" && cardInfo.status == "completed") &&
                <button className="pg4-btn pd-btn w-sm-100 mx-0 mx-lg-5" onClick={navigateFn}> {t('pracpl_btn_2')} </button>
            }
        </div>
    </div>);
}



