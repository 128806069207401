import React, { useState, useEffect } from "react";
import AboutPEImage_1 from "../../../assets/static/aboutpe_1.jpg";
import AboutPEImage_2 from "../../../assets/static/aboutpe_2.jfif";
import DPGImage_9 from "../../../assets/static/dpg_9.png";
import ExternalSitePrompt from "../ExternalSitePrompt";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function AboutPE({ history }) {
  const [showExternalSiteModal, setShowExternalSiteModal] = useState(false);
  const [externalSiteURL, setExternalSiteURL] = useState("");
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="my-5">
      <div className="container-xxl px-0 px-md-4">
        {showExternalSiteModal && (
          <ExternalSitePrompt
            showModal={showExternalSiteModal}
            externalSiteLink={externalSiteURL}
            onCancel={() => setShowExternalSiteModal(false)}
          />
        )}
        <div className="row justify-content-lg-between justify-content-evenly my-lg-5 my-0">
          <div className="col-11 col-lg-5 order-last order-lg-first">
            <div className="d-none d-lg-block">
              <span dangerouslySetInnerHTML={{ __html: t('about_main_header') }} />
            </div>
            <div className="pgs-text my-3">
              <span dangerouslySetInnerHTML={{ __html: t('pureencapsulation_p1_1') }} />{" "}
              <span
                className="text-decoration-underline text-primary cursor-pointer text-dark fw-bold"
                onClick={() => {
                  setExternalSiteURL(t('pure_url'));
                  setShowExternalSiteModal(true);
                }}
              >
                {t('about_link_text_1')}
              </span>
              <sup>&reg;</sup>, {t('about_plain_text_2')}
            </div>
          </div>
          <div className="col-12 col-lg-6 px-0">
            <div className="d-lg-none my-3 px-4">
              <span dangerouslySetInnerHTML={{ __html: t('about_main_header') }} />
            </div>
            <img src={AboutPEImage_1} alt="" className="w-100" />
          </div>
        </div>
        <div className="py-lg-5 py-2"></div>
        <div className="row justify-content-lg-between justify-content-center my-lg-5 my-0">
          <div className="col-12 col-lg-6 px-0">
            <div className="pgs-text d-block d-lg-none mx-4 px-2 px-lg-0">
              {t('about_plain_text_3')}
            </div>
            <img
              src={AboutPEImage_2}
              alt="Supplement Bottle Image"
              className="w-100 py-4"
            />
          </div>
          <div className="col-11 col-lg-5">
            <div className="pgs-text d-none d-lg-block">
              {t('about_plain_text_3')}
            </div>

            <ul className="pgs-text">
              <span dangerouslySetInnerHTML={{ __html: t('about_list_1') }} />
            </ul>
            <div className="pgs-text__sm">
              *{t('about_plain_text_4')}{" "}
              <span
                className="text-decoration-underline text-primary cursor-pointer text-dark"
                onClick={() => {
                  setExternalSiteURL(t('pure_url'));
                  setShowExternalSiteModal(true);
                }}
              >
                {t('about_plain_text_5')}
              </span>
              <sup>&reg;</sup> {t('about_plain_text_6')}
            </div>
          </div>
        </div>

        <div className="py-lg-5 py-2"></div>
        <div className="row justify-content-lg-between justify-content-center my-5">
          <div className="col-12 col-lg-6 order-lg-last px-0">
            <img src={DPGImage_9} alt="Ipad Image" className="w-100" />
          </div>
          <div className="col-11 col-lg-5 my-auto">
            <div className="pgs-title my-3">
              <span dangerouslySetInnerHTML={{ __html: t('about_section_1') }} />
            </div>
            <div className="pgs-text my-lg-2 my-4">
              <span dangerouslySetInnerHTML={{ __html: t('pureencapsulation_row3_p1') }} />
            </div>
            <button
              className="btn btn-primary"
              onClick={() => navigate("/hcp/getting-started")}
            >
              {t('about_section_text_1')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutPE;
