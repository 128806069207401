import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import toastr from "react-hot-toast";
import _ from "lodash";
import { useTranslation } from 'react-i18next'
import { createViewLog } from "../../../store/actions/adminPatient";

function PatientInfoModal({
  onCancel,
  showModal,
  currentUserId,
  rowData
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const [isAccept, setAcceptValue] = useState(false);
  const [patientInfo, setPatientInfo] = useState("");

  const onDone = () => {
    setPatientInfo(rowData)
    setAcceptValue(true)
    handleDone();
  };

  const handleDone = async () => {
    let data = {
      admin_id: currentUserId,
      patient_id: rowData.id
    };
    
    dispatch(createViewLog(token, data,)).then(response => {
      if (response.error) {
        toastr.error("Error in creating a log.");
      }
    });
  };
  return (
    <Modal
      show={showModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={onCancel}
      contentClassName="border-radius"
      backdrop="static"
      className="edit-patient-modal"
    >
      <Modal.Header closeButton>
        <div className="pg-text text-center">
          <div className="pg-heading my-3 mx-4">
            { t('patient_information') }
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        {!isAccept ? (
          <div>
             <div className="d-flex justify-content-center" style={{ padding: "15px" }}>
              { t('patient_info_note') }
            </div>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary text-light px-5 my-1 mx-1"
                onClick={onDone}
              >
                { t('patient_info_btn') }
              </button>
            </div>
           
          </div>
        ) : (
          <div >
            <div className="mx-4">
              <p className="d-inline-block pg-text__bold textc-primary">
                { t('name') }: <span className="pg-text">{patientInfo.full_name}</span> 
              </p>
            </div>
            <div className="mx-4">
              <p className="d-inline-block pg-text__bold textc-primary">
                { t('pracand_email') }: <span className="pg-text">{patientInfo.email}</span>
              </p>
            </div>
          </div>
        )}

        
      </Modal.Body>
    </Modal>
  );
}

export default PatientInfoModal;
