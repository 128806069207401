import PgInfoTooltip from "../../PgInfoTooltip";

export default function MSQToolTipText2() {
  return (
    <PgInfoTooltip position="left center" stroke="#000" height={30} width={30}>
      {/* <div style={{ padding: "12px" }}> */}
      Questions 1 and 4<br />
      <br />
      0 = Never
      <br />
      1 = Almost Never
      <br />
      2 = Sometimes
      <br />
      3 = Fairly Often
      <br />
      4 = Very Often
      <br />
      <br />
      Questions 2 and 3<br />
      <br />
      0 = Never
      <br />
      1 = Almost Never
      <br />
      2 = Sometimes
      <br />
      3 = Fairly Often
      <br />
      4 = Very Often
      <br />
      <br />
      Lowest score: 0<br />
      Highest score: 16
      <br />
      <br />
      Higher scores are correlated to more stress.
      {/* </div> */}
    </PgInfoTooltip>
  );
}
