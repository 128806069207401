import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useForm, Controller } from "react-hook-form";
import { useQuery, useMutation, useQueryClient } from "react-query";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import toastr from "react-hot-toast";

import {
  getPractitionerDetails,
  editPractitioner,
  getPractitionerTypes,
  getCountries,
  getPractitionerTerms,
} from "../../methods/myaccount-apis";

import L_EditPractitioner from "../../components/LoadingEffectComponent/Admin/L_EditPractitioner";
import PgSelect from "../../components/common/PgSelect";
import TextInput from "../../components/common/TextInput";
import SetNewPasswordModal from "../NewPasswordModal";
import PracticeTermsTable from "./PracticeTermsTable";
import {
  licenseNotMandatoryTypes,
  licenseNotNeededTypes,
  stateList,
  caStateList,
  regionMapping,
  label_value,
} from "../../constant";
import { validateEmail } from "../../methods";
import Cookies from "universal-cookie";
import useLangSpecificConst from "../../hooks/useLangSpecificConst";
import useLanguageConditions from "../../hooks/useLanguageConditions";
import { waitFor } from "@testing-library/react";
const cookies = new Cookies();

function Account() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { languageOptions } = useLangSpecificConst();
  const { isCA, isUK, isUS } = useLanguageConditions();

  const token = useSelector((state) => state.auth.token);
  const practitionerCountry = useSelector((state) => state.auth.country);
  const currentPracticeID = useSelector(
    (state) => state.auth.currentPracticeID
  );
  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const currentUserId = useSelector((state) => state.auth.currentUserId);
  const [showNewPasswordModal, setShowNewPasswordModal] = useState(false);
  const [requireLicense, setRequireLicense] = useState(false);
  const [disableLicense, setDisableLicense] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [practitionerTypesRaw, setPractitionerTypesRaw] = useState([]);
  const [practititionerTermsListTable, setPractititionerTermsListTable] =
    useState([]);

  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm();

  const {
    data: practitionerDetails,
    isLoading: isLoadingPractitionerDetails,
    refetch,
  } = useQuery(
    "practitionerDetails",
    () =>
      getPractitionerDetails({
        token,
        currentPracticeID,
        currentPractice,
        id: currentUserId,
      }),
    {
      onSuccess: (data) => {
        console.log("practice account:", { data });
        setValue("email", data.email || "");
        setValue("firstName", data.first_name || "");
        setValue("lastName", data.last_name || "");
        setValue("phoneNumber", data.phone_number || "");
        setValue("practitionerType", data.practitioner_type_name || "");
        setValue("license", data.license || "");
        setValue("address1", data.address_1 || "");
        setValue("address2", data.address_2 || "");
        setValue("address3", data.address_3 || "");
        setValue("city", data.city || "");
        setValue("country", data.country || "US");
        setValue("state", data.state || "");
        setValue("zipcode", data.zipcode || "");
        setValue("fax", data.fax || "");
        setValue("status", data.aasm_state || "");
        setValue("practiceName", data.practice_name || "");
        setValue(
          "default_language",
          _.find(languageOptions, {
            value: data.default_language,
          }) || label_value
        );
        updateProviderType(data.practitioner_type_name || "");
      },
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  const { data: practitionerTypes } = useQuery(
    "practitionerTypes",
    async () => {
      const data = await getPractitionerTypes({
        token,
        currentPracticeID,
        currentPractice,
      });
      setPractitionerTypesRaw(data);

      const region = regionMapping[practitionerCountry] || practitionerCountry;
      const response = _.filter(data.practitioner_types, (type) =>
        type.regions.includes(region)
      ).map((a) => a.practitioner_type);

      console.log({ region, response });

      return response;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  const { data: countriesData } = useQuery(
    "countriesList",
    async () => {
      const data = await getCountries({
        token,
        currentPracticeID,
        currentPractice,
      });
      if (practitionerCountry !== "US" && practitionerCountry !== "CA") {
        setValue("country", "");
        _.remove(
          data.countries,
          (n) => n === "United States" || n === "Canada"
        );
      } else if (practitionerCountry === "CA") {
        setValue("country", "Canada");
        _.remove(data.countries, (n) => n === "United States");
      } else {
        setValue("country", "United States");
      }
      return data.countries;
    },
    {
      onSuccess: (data) => {
        setCountryList(data);
      },
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  const { data: termsData } = useQuery(
    "practitionerTerms",
    () => getPractitionerTerms({ token, currentPracticeID, currentPractice }),
    {
      onSuccess: (data) => {
        setPractititionerTermsListTable(data.terms_and_agreements);
      },
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  const mutation = useMutation(
    (data) =>
      editPractitioner({
        token,
        currentPracticeID,
        currentPractice,
        id: currentUserId,
        data,
      }),
    {
      onSuccess: (pld) => {
        toastr.success(t("patient_suc1"));
        const langToSet = pld.language;
        console.log("account detail saved:", pld);
        i18n
          .changeLanguage(langToSet)
          .then(() => {
            cookies.set("i18next", langToSet, { path: "/" });
            console.log(`Language changed to ${langToSet}`);
            refetch();
          })
          .catch((error) => {
            console.error(`Error changing language to ${langToSet}`, error);
          });
      },
      onError: () => {
        toastr.error(t("patient_err1"));
      },
    }
  );

  const updateProviderType = (option) => {
    const practitionerType = _.find(practitionerTypesRaw?.practitioner_types, [
      "practitioner_type",
      option,
    ]);
    setValue("practitionerTypeId", practitionerType?.id);
    if (licenseNotNeededTypes.includes(option)) {
      setRequireLicense(false);
      setDisableLicense(true);
      setValue("license", "");
    } else if (licenseNotMandatoryTypes.includes(option)) {
      setRequireLicense(false);
      setDisableLicense(false);
    } else {
      setRequireLicense(true);
      setDisableLicense(false);
    }
  };

  const onSubmit = (data) => {
    console.log("form submitted");
    mutation.mutate({
      user: {
        first_name: _.startCase(data.firstName),
        last_name: data.lastName,
        full_name: `${_.startCase(data.firstName)} ${data.lastName}`,
        email: data.email.toLowerCase(),
        other_healthcare_provider: data.otherHealthcareProviderType,
        license: data.license,
        address_1: data.address1,
        address_2: data.address2,
        address_3: data.address3,
        city: data.city,
        country:
          practitionerCountry === "CA"
            ? "Canada"
            : practitionerCountry === "US"
            ? "United States"
            : data.country,
        state: data.state,
        zipcode: data.zipcode,
        phone_number: data.phoneNumber,
        fax: data.fax,
        practice_name: data.practiceName,
        practitioner_type_id: data.practitionerTypeId,
        default_language: data.default_language?.value,
      },
    });
  };

  const handleNewPasswordClick = (e) => {
    e.preventDefault();
    setShowNewPasswordModal(true);
  };

  return (
    <div className="container" id="edit-practitioner-modal">
      <Helmet>
        <title>{t("pract_account_h1")}</title>
      </Helmet>
      <div
        className="cursor-pointer textc-primary pg-link"
        onClick={() => navigate("/practice/dashboard")}
        style={{ marginTop: "20px", float: "left", width: "100%" }}
      >
        <i className="fas fa-chevron-left mr-2"></i> {t("dashboard")}
      </div>
      <div className="text-center pg-title mt-5">
        {t("patient_account_txt1")}
      </div>
      <div className="text-center pg-text__bold textc-primary">
        {t("pract_account_txt1")}
      </div>
      {isLoadingPractitionerDetails ? (
        <L_EditPractitioner />
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="row p-5 justify-content-md-center account-form"
        >
          <div className="col-md-6">
            <TextInput
              label={t("prac_register_first_name")}
              showLabel={true}
              required={true}
              id="firstName"
              placeholder={t("prac_reg_fname")}
              error={!!errors.firstName}
              errorMessage={t("prac_reg_fname_req")}
              {...register("firstName", { required: true })}
            />

            <TextInput
              label={t("prac_register_last_name")}
              showLabel={true}
              required={true}
              id="lastName"
              placeholder={t("prac_reg_lname")}
              error={!!errors.lastName}
              errorMessage={t("prac_reg_lname_req")}
              {...register("lastName", { required: true })}
            />

            <TextInput
              label={t("prac_register_email")}
              showLabel={true}
              required={true}
              emailField={true}
              id="email"
              placeholder={t("prac_reg_email")}
              error={!!errors.email}
              errorMessage={t("prac_reg_email_req")}
              {...register("email", {
                required: true,
                validate: validateEmail,
              })}
            />

            <TextInput
              label={t("prac_register_lic")}
              showLabel={true}
              required={requireLicense}
              isDisable={disableLicense}
              id="license"
              placeholder={t("prac_reg_lic")}
              error={!!errors.license}
              errorMessage={t("prac_reg_lic_req")}
              {...register("license", { required: requireLicense })}
            />

            <TextInput
              label={t("prac_register_name")}
              showLabel={true}
              optional={true}
              id="practiceName"
              placeholder={t("prac_reg_pn")}
              {...register("practiceName")}
            />
            
            <TextInput
              label={t("prac_register_adr_1")}
              showLabel={true}
              required={true}
              id="address1"
              placeholder={t("prac_reg_adr1")}
              error={!!errors.address1}
              errorMessage={t("prac_reg_adr1_req")}
              {...register("address1", { required: true })}
            />

            <TextInput
              label={t("prac_register_adr_2")}
              showLabel={true}
              id="address2"
              placeholder={t("prac_reg_adr2")}
              {...register("address2")}
            />

            <TextInput
              label={t("prac_register_adr_3")}
              showLabel={true}
              id="address3"
              placeholder={t("prac_reg_adr3")}
              {...register("address3")}
            />

            <TextInput
              label={t("prac_register_city")}
              showLabel={true}
              required={true}
              id="city"
              placeholder={t("prac_reg_city")}
              error={!!errors.city}
              errorMessage={t("prac_reg_city_req")}
              {...register("city", { required: true })}
            />

            {practitionerCountry === "US" || practitionerCountry === "CA" ? (
              <TextInput
                label={t("prac_register_country")}
                showLabel={true}
                id="country"
                placeholder={t("prac_reg_country")}
                value={
                  practitionerCountry === "CA" ? "Canada" : "United States"
                }
                isDisable={true}
                {...register("country")}
              />
            ) : (
              <Controller
                name="country"
                control={control}
                defaultValue=""
                rules={{
                  required: {
                    value: true,
                    // message: t("pracand_genetic_error"),
                  },
                  // validate: (value) =>
                  //   (value && value.value !== "") ||
                  //   t("pracand_genetic_error"),
                }}
                render={({ field }) => {
                  return (
                    <PgSelect
                      label={t("prac_register_country")}
                      showLabel={true}
                      required={true}
                      id="country"
                      placeholder={t("prac_reg_country")}
                      options={countryList} // Correct options list
                      error={!!errors.country}
                      errorMessage={t("pracand_country_error")}
                      setSelectedItem={field.onChange}
                      selectedItem={field.value}
                    />
                  );
                }}
              />
            )}
            {practitionerCountry === "US" || practitionerCountry === "CA" ? (
              <Controller
                name="state"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field }) => (
                  <PgSelect
                    label={t("state")}
                    showLabel={true}
                    required={true}
                    id="state"
                    placeholder={t("prac_reg_state")}
                    options={
                      practitionerCountry === "CA" ? caStateList : stateList
                    }
                    errorMessage={t("prac_reg_state_req_msg")}
                    error={!!errors.state}
                    setSelectedItem={field.onChange}
                    selectedItem={field.value}
                  />
                )}
              />
            ) : (
              <TextInput
                label="County"
                showLabel={true}
                optional={true}
                id="state"
                placeholder="Enter County"
                {...register("state")}
              />
            )}

            <TextInput
              label={t("code")}
              showLabel={true}
              required={true}
              id="zipcode"
              placeholder={t("prac_reg_zip")}
              error={!!errors.zipcode}
              errorMessage={t("prac_reg_zip_req")}
              {...register("zipcode", { required: true })}
            />

            <TextInput
              label={t("prac_register_phone")}
              showLabel={true}
              id="phoneNumber"
              placeholder={t("prac_reg_phone")}
              {...register("phoneNumber")}
            />

            <TextInput
              label={t("prac_register_fax")}
              showLabel={true}
              id="fax"
              placeholder={t("prac_reg_fax")}
              {...register("fax")}
            />

            {isCA && (
              <div className="my-4">
                <Controller
                  name="default_language"
                  control={control}
                  defaultValue="ca"
                  rules={{
                    required: {
                      value: true,
                      message: t("pracand_genetic_error"),
                    },
                    validate: (value) =>
                      (value && value.value !== "") ||
                      t("pracand_genetic_error"),
                  }}
                  render={({ field }) => (
                    <PgSelect
                      label={t("language")}
                      showLabel={true}
                      required={false}
                      id="default_language"
                      placeholder={t("select_language")}
                      displayKey="label"
                      valueKey="value"
                      options={languageOptions}
                      error={!!errors.default_language}
                      errorMessage={t("pracand_genetic_error")}
                      setSelectedItem={field.onChange}
                      selectedItem={field.value}
                    />
                  )}
                />
              </div>
            )}

            <button
              className="btn btn-outline px-1 my-3 w-100"
              onClick={handleNewPasswordClick}
            >
              {t("patient_account_txt3")}
            </button>
            {showNewPasswordModal && (
              <SetNewPasswordModal
                showModal={showNewPasswordModal}
                onCancel={() => setShowNewPasswordModal(false)}
                currentPractice={currentPractice}
                currentPracticeID={currentPracticeID}
                currentUserId={currentUserId}
                userType="Practitioner"
              />
            )}

            <button
              className="btn btn-primary text-light px-2 my-3 w-100"
              type="submit"
            >
              {t("patient_account_txt4")}
            </button>
          </div>
        </form>
      )}
      <div className="text-center pg-title mt-5">{t("pract_account_txt2")}</div>
      <div className="row p-5 justify-content-md-center">
        <div className="col-6">
          <PracticeTermsTable
            termsData={practititionerTermsListTable}
            error={false}
          />
        </div>
      </div>
    </div>
  );
}

export default Account;
