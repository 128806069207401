import { t } from "i18next";

function NoteForPatient({ onSavePatientNotes, is_shared, notes }) {
  return (
    <>
      <div className="pg4-text__subheading my-2 text_primary fw-bold">
        {t("report_diet_recom_subheader_1")}
      </div>
      <textarea
        onBlur={(e) => {
          const notes = e.target.value;
          if (notes.length > 0 && notes.length < 1000)
            onSavePatientNotes(notes);
        }}
        disabled={is_shared}
        defaultValue={notes}
        className="w-100 patient_notes patient_notes_dl"
        id=""
        rows={5}
      />
    </>
  );
}

export default NoteForPatient;
