import React from "react";
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";

function PrivacyCA() {
  const { t, i18n } = useTranslation();
  return (
    <div className="my-lg-5 py-5">
      <div className="container-xxl p-lg-5 px-4">
        <div className="pgs-title pgs-title__xl ff-v py-5">
          PureInsight<sup>&trade;</sup> Privacy Policy
        </div>

        <div className="pgs-heading__sub ff-m fw-bold text-dark fst-italic d-block">
          Effective Date: July 20th, 2020
        </div>

        <div className="pgs-text py-2">
          <div>
            This Privacy Policy describes the types of information Nestlé Canada Inc. (“ Nestlé”), as provider of the PureInsight<sup>&trade;</sup> online platform and service (“PureInsight<sup>&trade;</sup>”), collects from and about “you” (including healthcare practitioners and patients) when you visit our website, www.puregenomics.com (“Site”) or use our genetic testing & analysis services, reports, software, and associated documentation (collectively, the “Services”).” This Privacy Policy also explains how Nestlé may use, disclose, and secure such information, as well as your ability to control certain uses of it.            
          </div>
          <br />
          <div>
            By using the Site and the Services, you agree to the collection, use, and disclosure of your information as described in this Privacy Policy, and agree to the Nestlé Terms of Service which are incorporated by reference. If you do not agree, please do not access or use the Site or the Services.
          </div>
        </div>

        {/* quick guide */}
        <div className="row my-5">
          <div className="col-lg-4 border  bg-grey-1">
            <div className="pgs-text ff-m fw-bold p-2">
              QUICK GUIDE TO CONTENTS
            </div>
            <ol className="pgs-text pgs-text__xs ff-m">
              <li>
                <a href="#1">INFORMATION WE COLLECT</a>
              </li>
              <li>
                <a href="#2">OUR INFORMATION USE</a>
              </li>
              <li>
                <a href="#3">INFORMATION WE SHARE WITH OTHERS</a>
              </li>
              <li>
                <a href="#4">YOUR CHOICES AND OPT-OUTS</a>
              </li>              
              <li>
                <a href="#5">LINKS</a>
              </li>
              <li>
                <a href="#6">CHILDREN'S PRIVACY</a>
              </li>
              <li>
                <a href="#7">DATA SECURITY</a>
              </li>
              <li>
                <a href="#8">DATA RETENTION</a>
              </li>
              <li>
                <a href="#9">REVISIONS TO THIS PRIVACY POLICY</a>
              </li>
              <li>
                <a href="#10">HOW TO CONTACT US</a>
              </li>
            </ol>
          </div>
        </div>
        {/* section*/}
        <div className="py-4">
          <div className="pgs-heading__sub ff-m fw-bold text-dark " id="1">
            1. INFORMATION WE COLLECT
          </div>
          <div className="pgs-text py-2">
            There are several ways we may obtain information about you, including through (A) information you provide to us directly; (B) information that we automatically collect; and (C) information we receive from third parties. This includes data that identifies you personally whether directly (i.e., your name) or indirectly (i.e., information about your use of the Site).
          </div>
          {/* a */}
          <div className="pgs-text ff-m pt-4 pb-3 fw-bold">
            A. Information You Provide
          </div>
          <div className="pgs-text py-2">
            Nestlé collects information from you when you choose to share it with us. This may include when you use our Site, register or create an online account, purchase products or services, request information or customer support, use the Service, or otherwise communicate with or contact us. The information we collect may include your name, postal address, email address, telephone or mobile number, date of birth, gender, and account information such as user name and password. Health care providers will be required to provide additional information, such as their practice name, license number, and provider type.
          </div>
          <br />
          <div className="pgs-text py-2">
            You may also provide us with certain information in connection with specific transactions:
          </div>
          <ul className="pgs-text">
            <li>
              <span className="fw-bold fst-italic">Genetic Information.</span>{" "}
                Nestlé collects and analyzes your genetic information, including DNA information, when you choose to share it with us. You must share your{" "}
              <a href="https://www.23andme.com/en-ca/" rel="noreferrer" target="_blank">
                www.23andMe.ca
              </a>{" "}
              or{" "}
              <a href="https://www.ancestry.ca/" rel="noreferrer" target="_blank">
                ANCESTRY.CA
              </a>{" "}
              data file with Nestlé to take advantage of this service.
            </li>
          </ul>
          {/* b */}
          <div className="pgs-text ff-m pt-4 pb-3 fw-bold">
            B. Information Automatically Collected
          </div>
          <div className="pgs-text py-2">
            Whenever you visit or interact with the Services, we may use a variety of technologies such as cookies, log files, or other technologies to automatically or passively collect certain information about your online activity.
          </div>
          <br />
          <div className="pgs-text py-2">
            Please note that we automatically collect the following information about you:
          </div>
          <ul className="pgs-text">
            <li>
              <span className="fw-bold fst-italic">
                Computer or Device Information.
              </span>{" "}
              We may automatically collect your Internet Protocol (“IP”) address or other unique identifier or information from the computer, mobile device, tablet or other device you use to access the Services, including but not limited to your browser type, device type, operating system, software version, phone model.
            </li>
            <li>
              <span className="fw-bold fst-italic">Usage Information.</span> We may collect information about your use of the Services, including the date and time you visit the Services, the areas or pages of the Services that you visit, the amount of time you spend viewing or using the Services, the number of times you return to the Services, other click-stream or site usage data, emails that you open, forward or click-through to our Services, and other sites that you may visit. We may use third party tools that use technology such as cookies or other storage technologies to provide us with measurement and analytics services.
            </li>
          </ul>
          {/* c */}
          <div className="pgs-text ff-m pt-4 pb-3 fw-bold">
            C. Information Collected From Other Sources
          </div>
          <div className="pgs-text py-3">
            We may acquire information about you or related to you from our affiliates. We may use this information to provide you with the Services, provide you with enhanced services, maintain the accuracy of the information we collect, target our communications so that we can inform you of products, services or other offers that may be of interest to you, and for analytics, research, and product development purposes. We may combine the information we receive from and about you with information you provide to us and information we automatically collect through our Site.
          </div>
        </div>

        {/* section 2 */}
        <div className="py-4">
          <div className="pgs-heading__sub ff-m fw-bold text-dark " id="2">
            2. INFORMATION USE
          </div>
          <br/>
          <div className="pgs-text py-3">
            Nestlé may use the information we collect from and about you for any of the following business purposes:
          </div>
          <ul className="pgs-text">
            <li>To offer our Services to you;</li>
            <li>
              To respond to your inquiries and contact and communicate with you when necessary; and
            </li>
            <li>
              To address problems with the Site or our business, and to protect the security or integrity of the Site and our business.
            </li>
          </ul>
          <br />
          <div className="pgs-text py-3">
            Nestlé may also use the information we collect from and about you for any of the following commercial purposes:
          </div>
          <ul className="pgs-text">
            <li>
              To review the usage and operations of our Site, develop new products or services, and conduct analysis to enhance or improve our content, products, and services;
            </li>
            <li>
              To contact you with offers or newsletters from Nestlé;
            </li>
            <li>
              To use your data in an aggregated non-specific format for analytical and demographic purposes;
            </li>
            <li>
              To use your data in a de-identified format for our internal research purposes or to enhance our products or services.
            </li>
            <li>
              For other purposes disclosed at the time you provide your information or otherwise with your consent.
            </li>
          </ul>
        </div>
        {/* section 3 */}
        <div className="py-4">
          <div className="pgs-heading__sub ff-m fw-bold text-dark " id="3">
            3. INFORMATION WE SHARE WITH OTHERS
          </div>
          <div className="pgs-text py-3">
            Except as provided in this Privacy Policy, we will not disclose, share, sell, or rent any of your information, including genetic information, to any third parties without your consent. We may share your information with the following:
          </div>
          <ul className="pgs-text">
            <li>
              <span className="fw-bold fst-italic">
                Healthcare Practitioners:{" "}
              </span>
                When you (Patient) register for an account on the Site based upon receiving an invitation to register from your practitioner, we will share your genetic data file with your practitioner.
            </li>
            <li>
              <span className="fw-bold fst-italic">Service Providers: </span>We may share your information with third party service providers that provide business, professional or technical support functions for us (including to the extent necessary to process your payment; fulfill or ship your order; complete your transaction; or provide website hosting services), help us operate our business, the Site, and the Services, or administer activities on our behalf.
            </li>
            <li>
              <span className="fw-bold fst-italic">Employees: </span>Employees of Nestlé may have access to your personal information in the course of providing our services to you. Employee access to personal information will be restricted to those employees who have a legitimate need to know such information for the performance of their employment duties at Nestlé.
            </li>
            <li>
              <span className="fw-bold fst-italic">Affiliates: </span>We may share your information with Nestlé’ affiliates to enhance your experience, provide additional services and content, and for business and operational purposes.
            </li>
            <li>
              <span className="fw-bold fst-italic">
                Legal Matters; Safety:{" "}
              </span>
              We may access and disclose your information to respond to subpoenas, judicial processes, or government requests and investigations, or in connection with an investigation on matters related to public safety, as permitted by law, or otherwise as required by law. We may disclose your information to protect the security of our Site, servers, network systems, and databases. We also may disclose your information as necessary, if we believe that there has been a violation of our Terms of Service, any other legal document or contract related to our services, or the rights of any third party.
            </li>
            <li>
              <span className="fw-bold fst-italic">
                Sale or Transfer of Business or Assets:
              </span>{" "}
              We may sell or purchase assets during the normal course of our business. If another entity acquires us or any of our assets, information we have collected about you may be transferred to such entity. In addition, if any bankruptcy or reorganization proceeding is brought by or against us, such information may be considered an asset of ours and may be sold or transferred to third parties. Should such a sale or transfer occur, we will use reasonable efforts to try to require that the transferee use personal information provided through this Site in a manner that is consistent with this Privacy Policy.
            </li>
            <li>
              <span className="fw-bold fst-italic">Research Purposes: </span>We may share your aggregate, anonymous, or de-identified information with our external research partners, including our affiliates.
            </li>
            <li>
              <span className="fw-bold fst-italic">Other: </span>We also may share your information as disclosed to you at the time of collection.
            </li>
          </ul>
        </div>
        {/* section 4 */}
        <div className="py-4">
          <div className="pgs-heading__sub ff-m fw-bold text-dark " id="4">
            4. YOUR CHOICES AND OPT-OUTS
          </div>
          {/* a */}
          <div>
            <div className="pgs-text pt-4 pb-3 fw-bold">
              A. Cookies; California Do Not Track Disclosures
            </div>
            <div className="pgs-text py-2">
              Certain parts of our Site require cookies. You may adjust your device or Internet browser settings to limit certain tracking or to decline cookies, but by doing so, you will not be able to use certain restricted areas of the Site. Please refer to your device’s settings or your Internet browser’s “Help” section for more information on how to delete and/or disable your device or browser from receiving cookies or controlling your tracking preferences. Our system may not recognize Do Not Track requests or headers from some or all browsers.<br/>
            </div>
            <div className="pgs-text py-2">
              If you would like to learn more about Nestlé’s policy on Cookies and your rights, please visit the <Link to="/cookies-notice">Nestlé Cookies Policy</Link>.
            </div>
          </div>
          {/* b */}
          <div>
            <div className="pgs-text ff-m pt-4 pb-3 fw-bold">
              B. Unsubscribing from our Marketing Communications
            </div>
            <div className="pgs-text py-2">
              We provide our customers with the opportunity to update their information or opt-out of having their information used for marketing purposes, including the Pure NewsCaps newsletter. To opt-out, you may use the unsubscribe feature at the bottom of our email communications or change your preferences in your account.
            </div>
          </div>
          {/* c */}
          <div>
            <div className="pgs-text ff-m pt-4 pb-3 fw-bold">
              C. Updating Your Information
            </div>
            <div className="pgs-text py-2">
              If you wish to verify, correct, delete, or update any of your personal information, you may edit your information in your account profile, or contact us directly using the details in the “Contact Us” section below.
            </div>
          </div>
          {/* d */}
          <div>
            <div className="pgs-text ff-m pt-4 pb-3 fw-bold">
              D. Google Analytics Opt Out
            </div>
            <div className="pgs-text py-2">
            If you wish to prevent your data from being used by Google Analytics, Google has developed the Google Analytics opt-out browser add-on available{" "}
              <a
                href="https://tools.google.com/dlpage/gaoptout/."
                target="_blank"
                rel="noreferrer"
              >
                https://tools.google.com/dlpage/gaoptout/.
              </a>
            </div>
          </div>
        </div>       
        {/* section 5 */}
        <div className="py-4">
          <div className="pgs-heading__sub ff-m fw-bold text-dark " id="5">
            5. LINKS
          </div>
          <div className="pgs-text py-3">
            The Site may have links to third-party websites, which may have privacy policies that differ from our own. We are not responsible for the practices of such sites.
          </div>
        </div>
        {/* section 6 */}
        <div className="py-4">
          <div className="pgs-heading__sub ff-m fw-bold text-dark " id="6">
            6. CHILDREN’S PRIVACY
          </div>
          <div className="pgs-text py-3">
            Protecting children’s privacy is important to us. We do not direct the Site to, nor do we knowingly collect any personal information from, children under the age of thirteen. If Nestlé learns that a child under the age of thirteen has provided personally identifiable information to the Site, it will use reasonable efforts to remove such information from its files. We do not knowingly sell the personal data of minors under the age of 16 without affirmative authorization.
          </div>
        </div>
        {/* section 7 */}
        <div className="py-4">
          <div className="pgs-heading__sub ff-m fw-bold text-dark " id="7">
            7. DATA SECURITY
          </div>
          <div className="pgs-text py-3">
            We take commercially reasonable steps to safeguard the information we collect from and about our customers and Site visitors. When you enter sensitive information on our forms, we encrypt the transmission using secure socket layer technology (SSL). While we make every effort to help ensure the integrity and security of our network and systems, we cannot guarantee our security measures.
          </div>
          <div className="pgs-text py-3">
            Also, as part of our standard procedures, your personal information may be stored and processed in jurisdictions outside of your province of residence or outside the country such as the United State and Switzerland. In such cases, we continue to use generally accepted industry standards and secure operating environments to protect your information. However, regulatory authorities in those jurisdictions may be able to gain access to your information pursuant to the laws of those jurisdictions.
          </div>
        </div>
        {/* section 8 */}
        <div className="py-4">
          <div className="pgs-heading__sub ff-m fw-bold text-dark " id="8">
            8. DATA RETENTION
          </div>
          <div className="pgs-text py-3">
            We will retain your information for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements. In some circumstances we may anonymize your personal data (so that it can no longer be associated with you) for research or statistical purposes in which case we may use this information indefinitely without further notice to you.
          </div>
        </div>
        {/* section 9 */}
        <div className="py-4">
          <div className="pgs-heading__sub ff-m fw-bold text-dark " id="9">
            9. REVISIONS TO THIS PRIVACY POLICY
          </div>
          <div className="pgs-text py-3">
            We reserve the right, at our sole discretion, to change, modify, add, remove, or otherwise revise portions of this Privacy Policy at any time. When we do, we will post the change(s) on the Site. Your continued use of the Site and the Services following the posting of changes to these terms means you accept these changes. If we change the Privacy Policy in a material way, we will provide appropriate notice to you.
          </div>
        </div>
        {/* section 10 */}
        <div className="py-4">
          <div className="pgs-heading__sub ff-m fw-bold text-dark " id="10">
            10. HOW TO CONTACT US
          </div>
          <div className="pgs-text py-3">
          We have designated one person as our Chief Privacy Officer (the "CPO"). The CPO is generally accountable for our compliance with this policy, and with applicable privacy laws. If you have any questions or concerns about this Privacy Policy or the practices described herein, you may contact our CPO at{" "}
            <a href="mailto:customerservice@pureencapsulations.com ">
            customerservice@pureencapsulations.com
            </a>{" "}
            or by mail to:
          </div>
          <div className="pgs-text pgs-text__sm ff-m py-3">
            Atrium Innovations<br/>
            c/o Nestle Health Science<br/>
            1-150 Via Renzo Drive<br/>
            Richmond Hill, ON L4S 0J7<br/>
            Phone: 800.263.5861
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyCA;