import { createAction } from "redux-api-middleware";

export const GET_INDIVIDUAL_TRAIT_REQUEST = "  GET_INDIVIDUAL_TRAIT_REQUEST";
export const GET_INDIVIDUAL_TRAIT_SUCCESS = "  GET_INDIVIDUAL_TRAIT_SUCCESS";
export const GET_INDIVIDUAL_TRAIT_FAILURE = "  GET_INDIVIDUAL_TRAIT_FAILURE";


export const GET_PS_LIST_TRAIT_REQUEST = "  GET_PS_LIST_TRAIT_REQUEST";
export const GET_PS_LIST_TRAIT_SUCCESS = "  GET_PS_LIST_TRAIT_SUCCESS";
export const GET_PS_LIST_TRAIT_FAILURE = "  GET_PS_LIST_TRAIT_FAILURE";

export const UPDATE_TRAIT_INFO = "UPDATE_TRAIT_INFO";

export const updateTrait = (pld) => ({ type: UPDATE_TRAIT_INFO, payload: pld });

export const getTraitInfo = (
  token,
  currentPracticeID,
  currentPractice,
  userType,
  data
) => {
  const { reportId, traitId } = data;
  const protocol = process.env.REACT_APP_PROTOCOL;
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/reports/trait_details/${reportId}/${traitId}`,
    method: "GET",
    headers: { Authorization: token, "Current-Practice": currentPracticeID, "Current-Type": userType },
    types: [
      GET_INDIVIDUAL_TRAIT_REQUEST,
      GET_INDIVIDUAL_TRAIT_SUCCESS,
      GET_INDIVIDUAL_TRAIT_FAILURE,
    ],
  });
};


export const getPRSLists = (
  token,
  currentPracticeID,
  currentPractice,
  userType,
  reportId
) => {
  const protocol = process.env.REACT_APP_PROTOCOL;
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/web/api/prs_list?report_id=${reportId}`,
    method: "POST",
    headers: { Authorization: token, "Current-Practice": currentPracticeID, "Current-Type": userType },
    types: [
      GET_INDIVIDUAL_TRAIT_REQUEST,
      GET_INDIVIDUAL_TRAIT_SUCCESS,
      GET_INDIVIDUAL_TRAIT_FAILURE,
    ],
  });
};



export const getPSlist = (
  token,
  currentPracticeID,
  currentPractice,
  userType,
  reportId
) => {
  const protocol = process.env.REACT_APP_PROTOCOL;
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/web/api/prs_list?report_id=${reportId}`,
    method: "POST",
    headers: { Authorization: token, "Current-Practice": currentPracticeID, "Current-Type": userType },
    types: [
      GET_PS_LIST_TRAIT_REQUEST,
      GET_PS_LIST_TRAIT_SUCCESS,
      GET_PS_LIST_TRAIT_FAILURE,
    ],
  });
};


