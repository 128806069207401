import PgAccordion from "../PgAccordion";
import { useNavigate } from "react-router-dom";

function UKProviderFAQ() {
  const navigate = useNavigate();

  return (
    <div className="my-5">
      <div className="container-xxl">
        <div className="pgs-title my-5">
          PureGenomics<sup>&reg;</sup> FAQs
        </div>
        {/* section 1 */}
        <div className="pgs-heading mt-5 mb-2 textc-primary">
          Test Kits & Raw Data
        </div>
        <PgAccordion question="What genetic testing labs are compatible with PureGenomics<sup>&reg;</sup>? ">
          <div>
            PureGenomics<sup>&reg;</sup> <b>only</b> interprets genetic data
            from 23andMe<sup>&reg;</sup>, Ancestry<sup>&reg;</sup>, or Nordic Laboratories.
          </div>
        </PgAccordion>
        <PgAccordion question="I noticed that 23andMe<sup>&reg;</sup>, Ancestry<sup>&reg;</sup>, or Nordic Laboratories offer different DNA kits you can purchase. Is there a particular DNA kit that patients should buy if they are interested in using your platform?">
          <div>
            Any DNA kit sold by either 23andMe<sup>&reg;</sup>, Ancestry<sup>&reg;</sup>, or Nordic Laboratories is compatible with PureGenomics<sup>&reg;</sup>.
          </div>
        </PgAccordion>

        <PgAccordion question="Can I order a 23andMe<sup>&reg;</sup>, Ancestry<sup>&reg;</sup>, or Nordic Laboratories test kit through PureGenomics<sup>&reg;</sup>? ">
          <div>
            No. You must order the DNA kit directly through 23andMe<sup>&reg;</sup>, Ancestry<sup>&reg;</sup>, or Nordic Laboratories or other authorized
            retailers. Please note that PureGenomics<sup>&reg;</sup> is not
            affiliated with or endorsed by 23andMe<sup>&reg;</sup>, Ancestry<sup>&reg;</sup>, or Nordic Laboratories.
          </div>
        </PgAccordion>

        <PgAccordion
          question="How long after patients complete the 23andMe<sup>&reg;</sup>, Ancestry<sup>&reg;</sup>, or Nordic Laboratories genetic test will they get their results?"
          answer="Both companies state that a period of 6-8 weeks can be expected. Once the patient receives a notification that the results are ready, the patient is ready to login to the testing company’s website and download their genetic raw data file"
        />
        <PgAccordion question="Which testing companies’ genetic raw data file provides the most SNPs on your PureGenomics<sup>&reg;</sup> report? ">
          <div>
            <div className="pgs-text">
            23andMe<sup>&reg;</sup>, Ancestry<sup>&reg;</sup>, or Nordic Laboratories use a
              “genotyping chip” to detect patients’ DNA data. The reference SNP
              cluster IDs, also known as rs numbers, that are included on the raw
              data is dependent on which chip was used. These companies commonly
              update their chip versions for a variety of reasons, which can
              impact the raw data available for further analysis. Below is a
              chart that outlines the differences in the most common chip
              versions used in PureGenomics<sup>&reg;</sup>. (The “X” indicates
              that the SNP is included in that chip version).
            </div>
            <div className="overflow-x-scroll overflow-x-lg-hidden">
              <table className="pg-table my-3 text-center fw-bold">
                <thead>
                  <tr>
                    <td rowSpan="2" className="text-start">
                      SNP Tested
                    </td>
                    <td colSpan="2">23andMe</td>
                    <td>Ancestry</td>
                  </tr>
                  <tr>
                    <td>Version 4</td>
                    <td>Version 5</td>
                    <td>Version 2</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-start">EDN1 (rs5370)</td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td></td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-start">GATA3 (rs4143094</td>
                    <td></td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-start">AOC1 (rs10156191)</td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td></td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-start">BCMO1 (rs12934922)</td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td></td>
                  </tr>

                  <tr>
                    <td className="text-start">TCN2 (rs1801198)</td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td></td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-start">SLC23A1 (rs33972313)</td>
                    <td></td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td></td>
                  </tr>

                  <tr>
                    <td className="text-start">DHCR7 (rs12785878)</td>
                    <td></td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-start">CYP2R1 (rs10741657)</td>
                    <td></td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-start">GPx1P1 (rs1050450)</td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>

                  <tr>
                    <td className="text-start">DRD2 (rs6277)</td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </PgAccordion>
        {/* section 2 */}
        <div className="pgs-heading mt-5 mb-3 textc-primary">
          Using PureGenomics<sup>&reg;</sup>
        </div>
        <PgAccordion question="What is the cost to use PureGenomics<sup>&reg;</sup>?">
          <div>
            PureGenomics<sup>&reg;</sup> is complimentary to use! We believe in
            better health for all, that's why you can benefit from all that
            PureGenomics<sup>&reg;</sup> has to offer at no cost.
          </div>
        </PgAccordion>

        <PgAccordion question="How does a patient create a PureGenomics<sup>&reg;</sup> account?">
          <div>
            <div>
              The healthcare provider must first choose
              <b>
                <i> Add New Patient </i>
              </b>
              on their dashboard. This will trigger the system to send the
              patient a registration email.
            </div>
            <br />
            <div>
              The patient must click on the link in the registration email to
              activate their account. The patient is prompted to create a
              password and log into their patient portal.
            </div>
          </div>
        </PgAccordion>
        <PgAccordion question="Can I register my patients for PureGenomics<sup>&reg;</sup> if they have not received their genetic test results yet?">
          <div>
            Yes, you can add patients and they can activate their PureGenomics
            <sup>&reg;</sup> account even if they have not yet received their
            genetic test results from 23andMe<sup>&reg;</sup>, Ancestry<sup>&reg;</sup>, or Nordic Laboratories.
          </div>
        </PgAccordion>

        <PgAccordion question="How does a patient generate their PureGenomics<sup>&reg;</sup> report?">
          <div>
            For a PureGenomics<sup>&reg;</sup> report to be generated, the
            patient’s genetic raw data file must be uploaded into PureGenomics
            <sup>&reg;</sup>.
          </div>
          <br />
          <div>
            Once logged into their PureGenomics<sup>&reg;</sup> patient account, they will be given two options: log into their account on their testing company’s website to download their genetic raw data file or, if they already have their data file downloaded, simply upload their data. When the file is downloaded and saved to their device, they are ready to upload their data into PureGenomics<sup>&reg;</sup> and generate their report.
          </div>
        </PgAccordion>
        <PgAccordion question="How do patients download their raw data from 23andMe<sup>&reg;</sup>, Ancestry<sup>&reg;</sup>, or Nordic Laboratories, and upload this data to PureGenomics<sup>&reg;</sup>?">
          <div>
            <div>
              <span className="text-decoration-underline fw-bold">
                Through 23andMe
              </span>
              <sup>&reg;</sup>: Once logged into their PureGenomics
              <sup>&reg;</sup> account, the patient will need to click the{" "}
              <a
                href="https://you.23andme.com/tools/data/download/"
                target="_blank"
                rel="noreferrer"
              >
                23andMe
              </a>
              <sup>&reg;</sup> link to log into their account.
            </div>
            <br />
            <div>
              The patient will scroll down to the bottom of the page, check a
              box acknowledging they are downloading from a secure server, and
              then click submit request.
            </div>
            <br />
            <div>
              23andMe<sup>&reg;</sup> requires a 2-step authentication process.
              The patient will receive an email from 23andMe<sup>&reg;</sup> to
              let them know their raw data download is ready. The call to action
              at the bottom of 23andMe<sup>&reg;</sup>’s email takes the patient
              back to their 23andMe<sup>&reg;</sup> account where they can now
              download their raw data file and save it to their computer.
            </div>
            <br />
            <div>
              Lastly, the patient goes back into their PureGenomics
              <sup>&reg;</sup> patient dashboard and clicks <b>Upload Your Data,</b>{" "}
              selects their file from the saved location, and clicks{" "}
              <b>Start Upload</b>.
            </div>
            <br />
            <div>
              Once their data is uploaded, the patient will be able to see their
              patient report. The patient’s report does not include supplement
              or lab recommendations. You will also be notified that their
              report is ready to view from your practitioner dashboard.
            </div>
            <br />
            <div>
              <span className="text-decoration-underline fw-bold">
                Through Ancestry
              </span>
              <sup>&reg;</sup>: Once logged in to their PureGenomics
              <sup>&reg;</sup> account, the patient will need to click the{" "}
              <a
                href="https://www.ancestry.co.uk/account/signin/?returnUrl=https%3A%2F%2Fwww.ancestry.co.uk%2Faccount%2Fdata%2F"
                target="_blank"
                rel="noreferrer"
              >
                Ancestry.co.uk
              </a>{" "}
              link to log into their account. Directions are provided on this
              page for the patient to download their genetic raw data file.
            </div>
            <br />
            <div>
              <a
                href="https://www.ancestry.co.uk/"
                target="_blank"
                rel="noreferrer"
              >
                Ancestry.co.uk
              </a>{" "} requires a 2-step authentication process. The patient
              will receive an email from the testing company to let them know
              their raw data download is ready. The call to action{" "}
              <b>Confirm Data Download</b> at the bottom of the company’s email
              takes the patient back to their {" "}
              <a
                href="https://www.ancestry.co.uk/"
                target="_blank"
                rel="noreferrer"
              >
                Ancestry.co.uk
              </a>{" "} account where they
              can now download their raw data file and save it to their
              computer.
            </div>
            <br />
            <div>
              Lastly, the patient goes back into their PureGenomics
              <sup>&reg;</sup> patient dashboard and clicks <b>Upload Your Data,</b>{" "}
              selects their file from the saved location, and clicks{" "}
              <b>Start Upload</b>.
            </div>
            <br />
            <div>
              Once their data is uploaded, the patient will be able to see their
              patient report. The patient’s report does not include supplement
              or lab recommendations. You will also be notified that their
              report is ready to view from your practitioner dashboard.
            </div>
            <br />
          </div>
        </PgAccordion>
        <PgAccordion question="Can I upload data for my patients?">
          <div>
            Yes, you can upload the patient’s data for them. They need to
            provide you their genetic raw data file or access to their testing
            kit account.
          </div>
          <br />
          <div>
            To upload data for your patient, you first need to create the
            patient’s PureGenomics<sup>&reg;</sup> account. Click
            <b>
              <i> Add New Patient. </i>
            </b>
            On the next page, you will be asked to enter the patient’s name,
            email address, date of birth and gender. In the Genetics section, select the
            <b>
              <i> Practitioner will upload, </i>option{" "}
            </b>
            which will give you the ability to upload their data file.
          </div>
          <br />
          <div>
            If the patient already has a PureGenomics<sup>&reg;</sup> account,
            you will go to
            <b> View Patients</b> and then click on the right arrow found across
            the row of the patient’s name. Then click on <b>Start Upload</b> and
            choose their genetic data file.
          </div>
        </PgAccordion>
        <PgAccordion
          question="How long will it take for PureGenomics<sup>&reg;</sup> to interpret my patients' data?"
          answer="Once the genetic data file is uploaded, patients will see their report immediately within their dashboard. The provider is notified on their dashboard that their patient’s report is ready to view. The report is generally available to view within minutes after patient upload."
        />
        <PgAccordion question="As a healthcare provider, how do I upload my own 23andMe<sup>&reg;</sup>, Ancestry<sup>&reg;</sup>, or Nordic Laboratories  test results to PureGenomics<sup>&reg;</sup>?">
          <div>
            When you receive your 23andMe<sup>&reg;</sup>, Ancestry<sup>&reg;</sup>, or Nordic Laboratories test results, log into your PureGenomics
            <sup>&reg;</sup> account. To upload and process your own genetic
            data, please do the following:
          </div>
          <ol type="1">
            <li>
              Click
              <b>
                <i> Add New Patient.</i>
              </b>
            </li>
            <li>
              Check the
              <b>
                <i> Add Myself as a Patient.</i>
              </b>
            </li>
            <li>Enter any missing information.</li>
            <li>If you would like to take the Questionnaire, check the box.</li>
            <li>If you have labs to upload, check the box.</li>
            <li>
              Click
              <b>
                <i> Done. </i>
              </b>
              The platform will take you to your patient list.
            </li>
            <li>
              Under
              <b>
                <i> Genetics </i>
              </b>
              Click
              <b>
                <i> Start Upload.</i>
              </b>
            </li>
          </ol>
        </PgAccordion>
        {/* section 3 */}
        <div className="pgs-heading mt-5 mb-3 textc-primary">
          Using The New Features:
        </div>
        <PgAccordion question="How does PureGenomics<sup>&reg;</sup> deepen personalisation?">
          <div>
            A nutrigenomics report is not a stand-alone test. You cannot prescribe or diagnose from a nutrigenomics report. Providers need to understand objectively whether SNPs are being expressed in an individual – and that is done through understanding the patient’s environment (questionnaire) and lab values (lab result entry).
          </div>
        </PgAccordion>
        <PgAccordion
          question="What is the Smart Questionnaire?"
          answer="The Smart Questionnaire is for more personalised lifestyle and dietary recommendations. This is an optional, short and engaging questionnaire that takes 5-7 minutes for patients to complete. The questionnaire provides you with more specific information about your patient to use in combination with their genetic data for a comprehensive personalised plan.  Responses to the Smart Questionnaire allow for more refinement of the recommendations. Examples include a patient's food allergies, habits (such as caffeine consumption), preferences, and goals."
        />
        <PgAccordion question="How do I request my patients to complete the Smart Questionnaire?">
          <div>
            <span className="fw-bold text-decoration-underline">
              New Patient:
            </span>{" "}
            When you log in to your PureGenomics<sup>&reg;</sup> account,
            <b>
              <i> Add New Patient. </i>
            </b>
            You will be given the opportunity to check a box that says
            <b>
              <i> invite patient to fill in questionnaire, </i>
            </b>
            Invite patient to fill in questionnaire, indicating that you would
            like them to complete the questionnaire and at the bottom of the page click Done.
          </div>
          <br />
          <div>
            To increase efficiency you can click a box to save settings for the next time you add a new
            patient.
          </div>
          <br />
          <div>
            <span className="fw-bold text-decoration-underline">
              Existing Patient:
            </span>{" "}
            If you would like to have an existing patient complete the
            questionnaire, you can make this request from your patient
            list. Locate the patient on your dashboard, click the arrow
            across from the patient’s name, and click
            <b>
              <i> Send Invite </i>
            </b>
            under Questionnaire. We will send an email to them to their address on file. Patients will also be prompted to complete their
            Questionnaire from their patient dashboard.
          </div>
        </PgAccordion>
        <PgAccordion question="Can my patient complete the questionnaire if they don’t yet have their genetic raw data file?">
          <div>
            Yes, a patient can complete the questionnaire prior to receiving
            their genetic raw data file. You can send an invite to the patient
            from your patient dashboard. You will be able to view the
            questionnaire responses from your patient dashboard. To view the
            responses, click the arrow across from your patient’s name and click
            <b> View Responses</b> under Questionnaire.
            <br />A PureGenomics<sup>&reg;</sup> report will be generated once
            the patient uploads their genetic raw data file.
          </div>
        </PgAccordion>
        <PgAccordion
          question="Can a patient edit their responses?"
          answer="Patients will have the ability to edit their responses before submitting their final answers. They will only have one opportunity to make any necessary changes. If you or they need to edit any answers beyond this, a new questionnaire will need to be completed (which can be requested from your dashboard)."
        />
        <PgAccordion question="Can I request a new questionnaire after several months of care?">
          <div>
            Yes. From your patient dashboard, click the arrow across from the
            patient’s name and click <b>Request New Questionnaire.</b>
          </div>
        </PgAccordion>
        <PgAccordion question="How does the Lab Result Entry capability work?">
          <div>
          The lab entry capability allows you to enter relevant lab results for your patient that correspond to the traits in PureGenomics<sup>&reg;</sup>. The nutrient status data is then displayed for ease in decision-making. This optional feature facilitates the display of phenotype along with the genotype.
          </div>
        </PgAccordion>

        <PgAccordion question="How do I enter a lab result?">
          <div>
            From your patient list click the arrow under Lab Results click Start Upload. On the Lab Results Entry page enter the vendor name and click on enter labs and follow the directions and example. Add the name of the Lab test, the results, units and the reference values. Once you have entered all the relevant labs you can save the results.
          </div>
        </PgAccordion>
        <PgAccordion question="What are lab markers that coincide with the PureGenomics<sup>&reg;</sup> traits?">
          <div>
            The results of the following tests can be manually entered and correspond to the PureGenomics<sup>&reg;</sup> traits.
          </div>

          <div className="overflow-x-scroll overflow-x-lg-hidden">
            <table className="pg-table my-3">
              <thead>
                <tr>
                  <td>
                    <b>Vitamins, Mineral & Omega-3 Profile</b>
                  </td>
                  <td>
                    <b>Glucose Metabolism</b>
                  </td>
                  <td>
                    <b>Weight Management</b>
                  </td>
                  <td>
                    <b>Immune Health</b>
                  </td>
                  <td>
                    <b>Exercise Response</b>
                  </td>
                  <td>
                    <b>Cardiovascular Health</b>
                  </td>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>Vitamin A (retinol)</td>
                  <td>Fasting Glucose</td>
                  <td>Fasting Glucose</td>
                  <td>IL-6</td>
                  <td>Fasting Glucose</td>
                  <td>hsCRP</td>
                </tr>

                <tr>
                  <td>Vitamin B6</td>
                  <td>Hemoglobin A1c</td>
                  <td>Hemoglobin A1c</td>
                  <td>Tumor Necrosis Factor-Alpha</td>
                  <td>Hemoglobin A1c</td>
                  <td>Oxidized LDL</td>
                </tr>

                <tr>
                  <td>Homocyst(e)ine</td>
                  <td>LP-IR Score</td>
                  <td>Adiponectin</td>
                  <td>Neutrophil % (Serum)</td>
                  <td></td>
                  <td>HDL-C</td>
                </tr>

                <tr>
                  <td>Folate, RBC</td>
                  <td>Triglycerides</td>
                  <td></td>
                  <td>Eosinophil % (Serum)</td>
                  <td></td>
                  <td>HDL-P</td>
                </tr>

                <tr>
                  <td>Folate (Folic Acid),Serum</td>
                  <td></td>
                  <td></td>
                  <td>Basophil % (Serum)</td>
                  <td></td>
                  <td>Triglycerides</td>
                </tr>

                <tr>
                  <td>Vitamin B12</td>
                  <td></td>
                  <td></td>
                  <td>GlyCA</td>
                  <td></td>
                  <td>Coenzyme Q10</td>
                </tr>

                <tr>
                  <td>Methylmalonic acid</td>
                </tr>

                <tr>
                  <td>Vitamin D, 25-Hydroxy</td>
                </tr>

                <tr>
                  <td>Iron</td>
                </tr>

                <tr>
                  <td>Iron Binding Capacity (TIBC)</td>
                </tr>


                <tr>
                  <td>Ferritin</td>
                </tr>

                <tr>
                  <td>Alkaline Phosphatase</td>
                </tr>

                <tr>
                  <td>OmegaCheck</td>
                </tr>
              </tbody>
            </table>
          </div>
        </PgAccordion>
        <PgAccordion question="Where do I find my patients' lab results in the PureGenomics<sup>&reg;</sup> report?">
          <div>
            The lab results can be found in the Assessment Recommendations
            column of both the HTML and printable PDF report.
            <br />
            The results can also be viewed from the patient dashboard by
            clicking the arrow at the end of the patient row and click
            <b>
              <i> View Results.</i>
            </b>
          </div>
        </PgAccordion>
        <PgAccordion
          question="If blood levels or other lab values do not show a low nutrient level, but the PureGenomics<sup>&reg;</sup> report recommends a supplement, what should I do?"
          answer="A SNP alone cannot determine an increased nutrient requirement. However, the presence of a SNP helps identify whether a patient is likely to require additional amounts of a specific form of a nutrient. Clinical assessment is necessary in making final decisions regarding the need for supplementation."
        />
        <PgAccordion
          question="How does PureGenomics<sup>&reg;</sup> help increase efficiency with supplement recommendations?"
          answer="It decreases time spent creating supplement recommendations, which was a previous pain point per practitioner feedback. Additionally, the supplement dosage and frequency are auto-filled from the product label. "
          // We also now provide an enhanced ability for patients to purchase your recommendations via a virtual dispensary
        />
        {/* <PgAccordion
          question="Does PureGenomics<sup>&reg;</sup> offer a supplement fulfillment feature?"
          answer="Yes. The platform offers a virtual dispensary as an optional feature that enables the patient to review the practitioner recommendation and purchase Pure Encapsulations supplements that are shipped directly to the patient."
        /> */}
        <PgAccordion
          question="How do I add staff members to my practice?"
        >
          <div>
            After you are logged in, click on your name in the top right corner of the page to access the Staff Management page. Then click the “Add New Staff Member” button to open the form for adding a new staff member. Complete the required fields, assign the desired permissions, and click “Save” to finish. Your new staff member will receive an email to set their password and activate their account.
          </div>
        </PgAccordion>
        <PgAccordion
          question="What can staff members do?"
        >
          <div>
            Staff members can add patients, view the patient list, send patient reminders, and view patient reports. Depending on the optional permissions selected by the practice owner, staff members can also upload patient genetic files and lab files, view recommendations, and view the catalogue and substitutions. Staff members are not permitted to create recommendations, edit recommendations, or perform practice management tasks such as adding other staff members.          
          </div>
        </PgAccordion>
        <PgAccordion
          question="How do I manage my staff members’ permissions?"
        >
          <div>
            Optional permissions are user specific and can be configured from the Staff Management page. After you are logged in, click on your name in the top right corner of the page to access the Staff Management page. Then select a staff member from the list and click “Manage” to open the account details for that staff member. Assign the desired permissions and click “Update” to finish.          
          </div>
        </PgAccordion>
        <PgAccordion
          question="How do I remove a staff member from my practice?"
        >
          <div>
            After you are logged in, click on your name in the top right corner of the page to access the Staff Management page. Then select a staff member from the list and click “Archive Staff Member”. Their account status will be updated to Archived and they will no longer be able to access their account.
          </div>
        </PgAccordion>
        {/* section 4 */}
        <div className="pgs-heading mt-5 mb-2 textc-primary">
          Polygenic Score FAQs<sup></sup>
        </div>
        <PgAccordion

          question="What is a polygenic risk score?"
        >
          <div>
          A polygenic risk score (PRS) is a measurement of genetic predisposition that analyzes many contributing SNPs, instead of just one.  Most traits are determined by many SNPs, not just one.  Since PRS looks at many SNPs, is a more comprehensive genetic assessment, and is generally more accurate than single SNP analysis.
          </div>

        </PgAccordion>
        <PgAccordion

          question="What do the scores mean?"
        >
          <div>
          In PureGenomics, the score reflects the number of risk alleles that contribute to the trait.   The higher the score, the greater the genetic predisposition.  For example, a high score for vitamin B12 indicates that an individual’s B12 needs are greater than the needs of someone with a lower score.
          </div>

        </PgAccordion>
        <PgAccordion

          question="Why were enhancements made for just a few select traits?"
        >
          <div>
          The accuracy of risk scores and intake recommendations depends on robust genome-wide association studies and development of mathematical models requiring population data on blood levels and dietary intake information.  This information is currently not available for all traits. As part of our ongoing research and development, we will continue to evaluate new traits for compatibility with PRS and personalized intake recommendations.
          </div>

        </PgAccordion>
        <PgAccordion

          question="How are the SNPs for the polygenic trait selected?"
        >
          <div>
          The contributing SNPs analyzed by the PRS were selected based on genome-wide association studies and other population data sources.  SNPs with strong and statistically significant effects on nutrient levels are included in a polygenic risk score.
          </div>

        </PgAccordion>
        <PgAccordion

          question="How accurate are the scores and intake recommendations? "
        >
          <div>
          The polygenic risk scores and personalized intake recommendations are calculated from population data showing correlations between nutrient intakes, blood levels and genotypes. These calculations are based on genetics. Practitioners are encouraged to adjust nutrient intake recommendations based on labs and other assessments.
          </div>

        </PgAccordion>


        <div className="pgs-heading mt-5 mb-3 textc-primary">
          PureGenomics<sup>&reg;</sup> Reports
        </div>
        {/* <PgAccordion question="This report looks different from what I am used to. Did you update to a newer version?">
          <div>
            Yes. We have evolved our reports to reflect the current scientific
            knowledge. We offer tutorial videos that highlight the new changes
            to the website functionality and the reports. We also recommend
            scheduling a one-on-one 
            <a
              href="https://karawarecoaching.as.me/puregenomics"
              target="_blank"
              className="cursor-pointer"
              rel="noreferrer"
            >
              consult{" "}
            </a>
             with Business Advisor Kara Ware+ to learn more about the new
            reports in comparison to the previous version. There is no charge
            for your first one-on-one coaching session.
          </div>
        </PgAccordion> */}

        <PgAccordion question="How do I save reports to my computer?">
          <div>
            To save the report to your computer, click on
            <b> View and Print Practitioner Report</b> and/or
            <b> View and Print Patient Report</b> at the top of the page. You
            will see a download button on the next screen.
          </div>
        </PgAccordion>
        <PgAccordion
          question="Is there a way to print out the supplement recommendations with edits?"
          answer="Once you edit and send the patient supplement recommendations, it will appear in the patient report, and you can print either the entire patient report or just the page containing the recommendations. These recommendations can also be seen by the patient. Practitioners even have the ability to add any supplement to the list of recommendations by simply pressing the '+ ADD PRODUCT RECOMMENDATION ' button and a free text box will open."
        />
        <PgAccordion question="What does a PureGenomics<sup>&reg;</sup> report look like?">
          <div>
            PureGenomics<sup>&reg;</sup>  provides an interactive report that
            allows practitioners and patients to explore their results by trait
            category. Practitioners also have access to a printable summary
            report that consolidates all the results into one place. Both
            reports are visible to the healthcare provider.{" "}
            <a
              href="https://pg3-abstract.s3.us-east-1.amazonaws.com/PureGenomics_UK_Sample_Report.pdf"
              download
            >
              <b>View Sample Report</b>
            </a>
          </div>
        </PgAccordion>
        <PgAccordion
          question="Can reports be generated without supplement recommendations?"
          answer="Patient reports are generated without supplement recommendations until the provider edits and sends the recommendations. Once those recommendations are saved and sent to the patient, they will then be shown on the patient report."
        />
        <PgAccordion question="Are supplement recommendations and dosage suggestions provided in the report?">
          <div>
            The healthcare provider report includes Pure Encapsulations
            <sup>&reg;</sup> supplement recommendations, along with suggested
            dosages.
          </div>
          <br />
          <div>
            PureGenomics<sup>&reg;</sup> prioritizes a list of supplement
            suggestions based on traits where Consider Action is suggested. The
            supplement recommendation feature enhances customization by
            auto-populating recommended doses, quantities, and instructions that
            you can edit. The platform provides the ability to send supplement
            recommendations to the patient and easily track if they have
            been reviewed.
          </div>
          <br />
          <div>
            It is up to the healthcare provider to decide which supplement
            recommendations will be visible within the patient report. You are
            only able to edit the supplement recommendations through the
            interactive report. Edits made will be reflected in the printable
            summary report. Once your supplement recommendation list is ready
            for the patient to view, you can push the recommendations through so
            the patient can see them within their reports.
          </div>
        </PgAccordion>
        <PgAccordion
          question="Do I need to dispense all recommended supplements for a given trait category?"
          answer="No. For several traits, multiple supplements are suggested. However, the patient may not require all of them. The selection can be fine-tuned by assessing nutrient levels or other types of testing you would normally include in patient evaluations."
        />
        {/* section 5 */}
        <div className="pgs-heading mt-5 mb-3 textc-primary">
          Traits and SNPs
        </div>
        <PgAccordion question="If there are millions of SNPs, why does the report only show a limited number?">
          <div>
            Most SNPs have no effect on nutritional health and therefore lack
            nutritional relevance. PureGenomics<sup>&reg;</sup> includes SNPs
            that are:
          </div>
          <ul>
            <li>relevant to health</li>
            <li>validated by published research</li>
            <li>responsive to nutritional support</li>
          </ul>
        </PgAccordion>
        <PgAccordion question="Do you plan to add more SNPS to the reports?">
          <div>
            If you have a specific SNP that you would like us to add, 
            <a onClick={() => navigate("/contact-us")}>
              {" "}
              please let us know{" "}
            </a>
            and our scientific team will investigate the information.
          </div>
          <div>
            As more SNPs are added to the report, your patients' reports will be
            automatically updated. New SNPs are added each year as scientific
            evidence becomes available
          </div>
        </PgAccordion>
        <PgAccordion question="If there is a SNP that’s not available on the PureGenomics<sup>&reg;</sup> report, can I still see how the patient is coding?">
          <div>
            PureGenomics<sup>&reg;</sup> does offer a Supplemental Genotype
            Report. These SNPs have yet to have enough published literature
            substantiating the effects on diet and lifestyle. At some point,
            SNPs on the Supplemental Genotype report may migrate over to the
            PureGenomics<sup>&reg;</sup> report. If you have a SNP you wish to
            see that is not already on the Supplemental Genotype report,{" "}
            <a
              className="cursor-pointer"
              onClick={() => navigate("/contact-us")}
              target="_blank"
            >
              contact us
            </a>
          </div>
        </PgAccordion>
        <PgAccordion question="How do I know whether the patient is heterozygous or homozygous for a specific SNP?">
          <div>You simply compare the patient variant vs the risk variant.</div>
          <br />
          <div>
            For instance, in the Folate trait under the MTHFR C677T rs1801133
            SNP the risk variants are CT (Heterozygous) or TT (Homozygous). Both
            variants are shown to affect folic acid conversion.
          </div>
          <br />
          <div>
            If the patient variant is CC and the result is No Action, you know the
            patient is the wild type. Wild type and homozygous always have two
            of the same alleles whereas heterozygous have one of each chemical
            code, therefore, two different alleles.
          </div>
        </PgAccordion>
        <PgAccordion
          question="What are traits?"
          answer="Traits are specific characteristics like eye colour, blood type, or the ability to tolerate lactose that are based on an individual's unique genetic variations (SNPs), as well as environmental factors."
        />
        <PgAccordion question="Which trait categories are supported by PureGenomics<sup>&reg;</sup>?">
          <div>
            PureGenomics<sup>&reg;</sup> reports offer insights into the
            following trait categories:
          </div>
          <ul>
            <li>Vitamins, minerals & omega-3s</li>
            <li>Detoxification</li>
            <li>Glucose metabolism</li>
            <li>Metabolic & weight management</li>
            <li>Cognitive health & memory</li>
            <li>Immune health</li>
            <li>Energy & fitness</li>
            <li>Cardiovascular health</li>
            <li>Gastrointestinal health</li>
          </ul>
        </PgAccordion>
        <PgAccordion
          question="What are Single Nucleotide Polymorphisms (SNPs)?"
          answer="SNPs (single nucleotide polymorphisms) occur normally in a person's DNA, and each SNP represents a genetic variation within a single DNA building block in a gene sequence, called a nucleotide. While most SNPs have no effect on a person's health or development, some can help inform lifestyle choices and health and wellness outcomes."
        />
        <PgAccordion question="As new SNPs become available, will patients need to repeat their 23andMe<sup>&reg;</sup>, Ancestry<sup>&reg;</sup>, or Nordic Laboratories tests?">
          <div>
            No, the test only needs to be performed once. As new SNPs are added,
            the PureGenomics<sup>&reg;</sup> reports will be automatically updated to reflect
            these SNPs.
          </div>
        </PgAccordion>

        {/* section 6 */}
        <div className="pgs-heading mt-5 mb-3 textc-primary">
          Troubleshooting:
        </div>
        <PgAccordion question="While attempting to add a new patient, I received an error message that the email address is already in use. How do I proceed?">
          <div>
            You will need to obtain an alternative email address from the patient as the email address is a unique identifier and can only be used one time. Since that email has been used previously, the patient will need to provide another email address for the account.
          </div>
          <br />
          {/* <div>
            If this patient has a PureGenomics<sup>&reg;</sup> account with a
            different provider using the email address, your patient can also
            contact customer support to request their PureGenomics
            <sup>&reg;</sup> account be transferred to your dashboard by{" "}
            <a
              className="cursor-pointer"
              href="mailto:support@pure-encapsulations.co.uk"
              target="_blank"
              rel="noreferrer"
            >
              emailing
            </a>{" "}
            us.
          </div> */}
        </PgAccordion>
        <PgAccordion question="I received an error message when I tried to upload my patient’s genomic data. How do I fix this?">
          <div>
            There are three reasons an error may occur while uploading your
            patient’s genomic data.
          </div>
          <br />
          <ol type="1">
            <li>
              The data is from a non-compatible testing company. PureGenomics
              <sup>&reg;</sup> is only compatible with 23andMe<sup>&reg;</sup>, Ancestry<sup>&reg;</sup>, or Nordic Laboratories test results.
            </li>
            <li>
              Your patient provided results that is in a format other than a
              text or a zip file (like a PDF). Please ensure the file you are
              uploading is in a .txt or .zip file format. This is also the
              common download format from 23andMe<sup>&reg;</sup>, Ancestry<sup>&reg;</sup>, or Nordic Laboratories.
            </li>
            <li>
              The patient’s data is unreadable. Data may become corrupt and is
              unintelligible by our system. Please ask your patient to
              re-download the file from the testing website and send it to you
              again. When their device asks them if they wish to open or save the file, they will need to select save as opening the file can lead to corruption.
            </li>
          </ol>
        </PgAccordion>
        <PgAccordion question="My patient wants to set their family members up with PureGenomics<sup>&reg;</sup> accounts. How do they do this?">
          <div>
            Family members will need to be invited by you or another
            participating provider to set up their own account.
          </div>
          <br />
          <div>
            Each patient must use a different email address to create a
            PureGenomics<sup>&reg;</sup> account.
          </div>
        </PgAccordion>
        <PgAccordion question="How do I update my personal information?">
          <div>
            All changes can be made from your account dashboard by clicking 
            <b>My Account,</b> found under where your name is listed at the top
            of the page.
          </div>
        </PgAccordion>
        <PgAccordion
          question="I have a patient I am no longer seeing. How do I delete this patient off my dashboard?"
          answer="Patients cannot be permanently removed from your dashboard. If you have a patient who is no longer active within your practice, you can archive them from within your dashboard. To archive, click Edit Patient in their patient row."
        />
        <PgAccordion question="What is the likelihood of a false positive?">
          <div>
            The likelihood of a false positive is information you would need to
            obtain from the genetic service provider you worked with (23andMe<sup>&reg;</sup>, Ancestry<sup>&reg;</sup>, or Nordic Laboratories).
          </div>
        </PgAccordion>

        <PgAccordion question="What if my patients are not tech-savvy?">
          <div>
            Many tech questions can be resolved easily by your staff, like
            helping patients log into their accounts and understanding how to
            download the text file. If the tech problem is not easily resolved,
            please have your patients{" "}
            <a
              className="cursor-pointer"
              onClick={() => navigate("/contact-us")}
            >
              Contact Us
            </a>{" "}
            for assistance.
          </div>
        </PgAccordion>
        <PgAccordion question="Upon attempting to register, I received an email that said more information is needed from me to complete my registration. What does this mean?">
          <div>
            This means that we need to verify that you are a healthcare provider to complete the account setup. Please email us a copy of your credentials to complete your registration.
          </div>
        </PgAccordion>
        <div className="pgs-heading mt-5 mb-3 textc-primary">
          Privacy and Security:
        </div>
        <PgAccordion question="What is the PureGenomics<sup>&reg;</sup> Privacy Policy?">
          <div>
            PureGenomics<sup>&reg;</sup> will not sell, share, or rent a
            patient's 23andMe<sup>&reg;</sup>, Ancestry<sup>&reg;</sup>, or Nordic Laboratories data files to third
            parties. Nor will we store any genetic data on disc unless it is
            being processed, and the database itself is encrypted. Read more
            about our
            <a
              className="cursor-pointer"
              onClick={() => navigate("/privacy")}
              target="_blank"
            >
               Privacy Policy
            </a>
          </div>
        </PgAccordion>
        <PgAccordion question="How is data security and my privacy protected?">
          <div>
            This website takes every precaution to protect your information.
            When users submit sensitive information via the website, your
            information is protected both online and offline with the best
            encryption software in the industry—SSL.
          </div>
          <br />
          <div>
            While we use SSL encryption to protect sensitive information online,
            we also do everything in our power to protect user-information
            offline. All our users' information, not just the sensitive
            information mentioned above, is restricted in our offices. Only
            employees who need the information to perform a specific job (for
            example, a customer service representative) are granted access to
            personally identifiable information.
          </div>
          <br />
          <div>
            PureGenomics<sup>&reg;</sup> will not sell, share, or rent a
            patient's 23andMe<sup>&reg;</sup>, Ancestry<sup>&reg;</sup>, or Nordic Laboratories data file to third
            parties. Nor will we store any genetic data on disc unless it is
            being processed, and the database itself is encrypted. Read more
            about our
            <a
              className="cursor-pointer"
              onClick={() => navigate("/privacy")}
              target="_blank"
            >
               Privacy Policy.
            </a>
          </div>
          <br />
          <div>
            Furthermore, it is important to read the consent forms available at
            23andMe<sup>&reg;</sup>, Ancestry<sup>&reg;</sup>, or Nordic Laboratories and to
            encourage patients to do the same.
          </div>
          <br />
          <div>
            Any questions can be directed to either genetic testing company, as
            they have a responsibility to address directly.
          </div>
          <br />
          <div>
            Overall, data that is utilized by these testing services are:
          </div>
          <br />
          <ul>
            <li>
              De-identified (excludes personal details, including name, date of
              birth, or address)
            </li>
            <li>
              Protected by strict laws surrounding how data and personal
              information is stored
            </li>
            <li>
              Based on consent, in the case of needing to opt-in for use in
              research
            </li>
          </ul>
        </PgAccordion>
      </div>
    </div>
  );
}

export default UKProviderFAQ;
