import React from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function TableFooter({
  pageSize,
  canNextPage,
  canPreviousPage,
  nextPage,
  previousPage,
  setPageSize,
  pageIndex,
  pageOptions,
}) {
  const { t } = useTranslation();
  return (
    <div className="d-flex justify-content-between my-3">
      <div>
        <Dropdown className="d-inline-block">
          <Dropdown.Toggle as="span" className="mx-2">
            <span style={{ fontWeight: "100 !important" }}>{pageSize}</span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item eventKey="1" onClick={() => setPageSize(10)}>
              10
            </Dropdown.Item>
            <Dropdown.Item eventKey="1" onClick={() => setPageSize(15)}>
              15
            </Dropdown.Item>
            <Dropdown.Item eventKey="1" onClick={() => setPageSize(20)}>
              20
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        {t("pracpl_line_6")}
      </div>
      <div>
        <span>
          Page {pageIndex + 1} {t("of")} {pageOptions.length}{" "}
        </span>
        <span>
          <i
            className="fas fa-chevron-up mx-2 cursor-pointer"
            onClick={previousPage}
            disabled={!canPreviousPage}
            style={
              !canPreviousPage
                ? { color: "grey", cursor: "not-allowed" }
                : { color: "black" }
            }
          ></i>
          <i
            className="fas fa-chevron-down mx-3 cursor-pointer"
            onClick={nextPage}
            disabled={!canNextPage}
            style={
              !canNextPage
                ? { color: "grey", cursor: "not-allowed" }
                : { color: "black" }
            }
          ></i>
        </span>
      </div>
    </div>
  );
}
