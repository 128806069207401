import React, { Component, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux';
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';

import { arrayDisplay } from "../../methods";
import {
  patientAnswers,
  submitAnswer,
  getWelcomeQuestion,
  updateQuestionSession,
  endSession,
  updateQuestionnaireProcess
} from "../../store/actions/questions";
import { PgStepper } from "../../components/PgStepper";
import EditAnswer from "./EditAnswer";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom/dist";

function ReviewQuestion({ onChangeAnswer }) {


  // redux state variable
  const currentPractice = useSelector(state => state.auth.currentPractice);
  const currentPracticeID = useSelector(state => state.auth.currentPracticeID);
  const currentUserId = useSelector(state => state.auth.currentUserId);
  const token = useSelector(state => state.auth.token);
  const answers = useSelector(state => state.question.patientAnswer);
  const loading = useSelector(state => state.question.loading_answers);


  const dispatch = useDispatch();

  // redux actions
  const patientAnswersAction = (token, currentPracticeID, currentPractice, patientId) => dispatch(patientAnswers(token, currentPracticeID, currentPractice, patientId));
  const updateQuestionSessionAction = (token, currentPracticeID, currentPractice, data) => dispatch(updateQuestionSession(token, currentPracticeID, currentPractice, data));
  const submitAnswerAction = (data) => dispatch(submitAnswer(data));
  const getWelcomeQuestionAction = (patient_id) => dispatch(getWelcomeQuestion(patient_id));
  const endSessionAction = (data) => dispatch(endSession(data));
  const updateQuestionnaireProcessAction = (data) => dispatch(updateQuestionnaireProcess(data));

  //local state variables
  const [patientAnswers, setPatientAnswers] = useState([]);
  const [unAnsweredQuestions, setunAnsweredQuestions] = useState([]);
  const [viewOnly, setViewOnly] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false)
  const [editQuestionId, setEditQuestionId] = useState('')
  const [editAnswer, setEditAnswer] = useState('')
  const navigate = useNavigate();
  const location = useLocation();

  const [conversationItems, setConversationItems] = useState([]);
  const [patientAnswer, setPatientAnswer] = useState("");
  const [currentQuestionId, setCurrentQuestionId] = useState(1);
  const [showTyper, setShowTyper] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setViewOnly(location.state.viewOnly);
    getWelcomeQuestionAction(currentUserId).then((res) => {
      if (res.error) {
        toast.error(t('patient_rv_msg1'),
          {
            style: {
              fontSize: '16px',
            },
            duration: 15000
          })
        navigate("/patient/dashboard");
      }
      else {
        const sessionAnswers = JSON.parse(res.payload.session).questionnaire
        const unAnsweredQuestions = JSON.parse(res.payload.session).un_answered_questions
        const newanswers = _.filter(sessionAnswers, function (o) {
          return parseInt(o.id) > 1.2;
        });
        setPatientAnswers(newanswers);
        setunAnsweredQuestions(unAnsweredQuestions);
      }
    });
  }, []);

  const submitQuestionnaire = () => {
    let data = { patient_id: currentUserId };
    if (unAnsweredQuestions.length === 0) {
      endSessionAction(data).then((res) => {
        navigate("/patient/thank_you");
        if (res.payload.recommendation_report_id != "") {
          updateQuestionnaireProcessAction({
            recomm_report_id: res.payload.recommendation_report_id,
            questionnaire_status: "Analyzing",
          })
        }
      });
    }
    else {
      toast.error('There are some unanswered questions please answer.',
        {
          style: {
            fontSize: '16px',
          },
          duration: 15000
        })
    }
  };

  const editQuestion = (questionId, answer) => {
    setShowEditModal(true)
    setEditQuestionId(questionId)
    setEditAnswer(answer)
  }

  const closeEditModal = (resetAnswer) => {
    getWelcomeQuestionAction(currentUserId).then((res) => {
      if (res.error) {
        toast.error(t('patient_rv_msg1'),
          {
            style: {
              fontSize: '16px',
            },
            duration: 15000
          })
        navigate("/patient/dashboard");
      }
      else {
        const sessionAnswers = JSON.parse(res.payload.session).questionnaire
        const unAnsweredQuestions = JSON.parse(res.payload.session).un_answered_questions
        const newanswers = _.filter(sessionAnswers, function (o) {
          return parseInt(o.id) > 1.2;
        });
        setPatientAnswers(newanswers);
        setunAnsweredQuestions(unAnsweredQuestions);
      }
    });
    setShowEditModal(false)
  }

  const revertAnswer = () => {
    setShowEditModal(false)
  }

  return (
    <div>
      <div className="container-fluid">
        <div className="container-xxll pt-1 pt-lg-5 px-lg-5 px-2">
          <div className="row mt-4 justify-content-center justify-content-lg-between">
            <div className="col-12 col-lg-6 order-lg-0 order-1">
              <hr className="my-5 d-block d-lg-none" />
              <div className="pg-title"> {t('questionnaire')}</div>
            </div>
          </div>
        </div>
      </div>
      {true && (
        <div>
          <div className="review-answers-wrapper">
            <div className="container-fluid">
              {
                viewOnly &&
                <div>
                  <p className="pg-heading review-header">{t('patient_rv_txt1')}</p>
                  <p>
                  </p>
                </div>
              }
              {
                !viewOnly &&
                <div>
                  <span dangerouslySetInnerHTML={{ __html: t('patient_rv_div1') }} />
                </div>
              }
              {
                <div>
                  {patientAnswers.map((item, index) => {
                    return (
                      <div className="row" index={index}>
                        <hr />
                        <div className="col-lg-6 col-12 pg-text__bold mb-3" dangerouslySetInnerHTML={{ __html: item.question_text }}
                        >
                        </div>
                        <div className="col-lg-4 col-12">
                          {arrayDisplay(item.answer_text)}
                        </div>
                        <div className="col-lg-2 col-12">
                          {
                            !viewOnly &&
                            <div
                              className="pg-link review-question_change my-2"
                              onClick={() => editQuestion(item.id, item.answer_text)}
                            >
                              <i className="fas fa-pen "></i>
                              <span className="mx-2">{t('patient_rv_txt2')}</span>
                            </div>
                          }
                        </div>
                      </div>
                    );
                  })}
                </div>
              }
            </div>
          </div>
          {!viewOnly && (
            <div className="bgc-secondary text-center p-3">
              <div className="row justify-content-center">
                <div className="col-12 col-md-6 col-lg-4">
                  <button
                    className="btn bgc-primary text-light w-100"
                    onClick={submitQuestionnaire}
                  >
                    {t('patient_rv_txt3')}
                  </button>
                </div>
              </div>
            </div>
          )}
          {viewOnly && (
            <div className="bgc-secondary text-center p-3">
              <div className="row justify-content-center">
                <div className="col-12 col-md-6 col-lg-4">
                  <button
                    className="btn bg-sky-blue px-5 textc-primary  w-100"
                    onClick={() => navigate("/patient/dashboard")}
                  >
                    {t('patient_rv_txt4')}
                  </button>
                </div>
              </div>
            </div>
          )}
          {
            showEditModal &&
            < EditAnswer
              showModal={true}
              onCancel={closeEditModal}
              questionId={editQuestionId}
              editAnswerText={editAnswer}
              patientExistingAnswers={patientAnswers}
              revertAnswer={revertAnswer}
            />
          }
        </div>
      )}
    </div>
  );
}
export default ReviewQuestion;
