import React from "react";

const DietAndLifeStyleReccommendations = ({ data, userType, t }) => {
  const patientTextColor = { color: userType === "Patient" ? "var(--pg_primary__p)" : 'var(--pg_primary)' }
  return (
    <div>
      <hr className="my-5" />
      <div className="pg-heading" style={patientTextColor}>{t('gene_analysis_th_5')}</div>
      {data ?
        <div className="pg-text my-3">{data}</div>
        : <div className="pg-text my-3">
          {t('gene_analysis_td_6')}
        </div>}
    </div>
  );
};

export default DietAndLifeStyleReccommendations;
