import ContentLoader from "react-content-loader";
function L_TraitPage(props) {
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height="20%"
      viewBox="0 0 600 1000"
      backgroundColor="#9e9e9e52"
      foregroundColor="#18368f80"
      {...props}
    >
      {/* report summary navigator */}
      <rect x="24" y="5" rx="3" ry="3" width="52" height="6" />

      {/* prev button */}
      <circle cx="510" cy="40" r="15" />
      <rect x="495" y="65" rx="3" ry="3" width="25" height="6" />

      {/* next button */}
      <circle cx="580" cy="40" r="15" />
      <rect x="570" y="65" rx="3" ry="3" width="25" height="6" />

      {/* Diagram title */}
      <rect x="24" y="26" rx="3" ry="3" width="188" height="26" />

      {/* patient name */}
      <rect x="24" y="69" rx="3" ry="3" width="52" height="10" />

      {/* current traits */}
      <rect x="24" y="118" rx="3" ry="3" width="185" height="50" />
      <rect x="215" y="118" rx="3" ry="3" width="185" height="50" />
      <rect x="405" y="118" rx="3" ry="3" width="185" height="50" />

      <rect x="24" y="172" rx="3" ry="3" width="185" height="50" />
      <rect x="215" y="172" rx="3" ry="3" width="185" height="50" />
      <rect x="405" y="172" rx="3" ry="3" width="185" height="50" />

      <rect x="24" y="226" rx="3" ry="3" width="185" height="50" />
      <rect x="215" y="226" rx="3" ry="3" width="185" height="50" />
      <rect x="405" y="226" rx="3" ry="3" width="185" height="50" />

      {/* overview */}
      <rect x="24" y="305" rx="3" ry="3" width="280" height="150" />
      {/* lab findings */}
      <rect x="310" y="305" rx="3" ry="3" width="280" height="150" />
      {/* implications */}
      <rect x="24" y="460" rx="3" ry="3" width="280" height="150" />
      {/* diet and */}
      <rect x="310" y="460" rx="3" ry="3" width="280" height="150" />

      {/* implications */}
      <rect x="24" y="615" rx="3" ry="3" width="280" height="150" />
      {/* diet and */}
      <rect x="310" y="615" rx="3" ry="3" width="280" height="150" />

      {/* implications */}
      <rect x="24" y="770" rx="3" ry="3" width="280" height="150" />
      {/* diet and */}
      <rect x="310" y="770" rx="3" ry="3" width="280" height="150" />

    </ContentLoader>
  );
}

export default L_TraitPage;
