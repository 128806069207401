import * as prd_constant from "../actions/patientDashboard.js";

const initialState = {
    loading: true,
    error: false,
    reportData: null,
    geneticData: null,
    questionnaireData: null,
    recentQuestionnaireData: null,
    skipQuestionnaire: null,
    isGettingPatientPastReport: true,
    geneticUploadBy: "",
    pastReports: [],
    questionnaireInvited: ""
};


const patientDashboardScreenReducer = (state = initialState, action) => {
    switch (action.type) {
        case prd_constant.PATIENT_REPORT_DASHBOARD_REQUEST: {
            return { ...state, loading: true };
        }
        case prd_constant.PATIENT_REPORT_DASHBOARD_SUCCESS: {
            return { ...state, loading: false, reportData: action.payload.current_report_data, geneticData: action.payload.current_report_data.genetics, recentQuestionnaireData: action.payload.questionnaire_data, questionnaireData: action.payload.current_report_data.questionnaire, geneticUploadBy: action.payload.current_report_data.genetic_upload_by, questionnaireInvited: action.payload.current_report_data.invite_questionnaire };
        }
        case prd_constant.PATIENT_REPORT_DASHBOARD_FAILURE: {
            return { ...state, loading: false, error: true, reportData: null, geneticData: null, questionnaireData: null, recentQuestionnaireData: null };
        }
        case prd_constant.PATIENT_SKIP_QUESTIONNAIRE_REQUEST: {
            return { ...state, loading: true };
        }
        case prd_constant.PATIENT_SKIP_QUESTIONNAIRE_SUCCESS: {
            return { ...state, loading: false, skipQuestionnaire: action.payload.message };
        }
        case prd_constant.PATIENT_SKIP_QUESTIONNAIRE_FAILURE: {
            return { ...state, loading: false, error: true, skipQuestionnaire: null };
        }
        case prd_constant.PATIENT_PAST_REPORT_REQUEST: {
            return { ...state, isGettingPatientPastReport: true };
        }
        case prd_constant.PATIENT_PAST_REPORT_SUCCESS: {
            return { ...state, isGettingPatientPastReport: false, pastReports: action.payload.past_reports_data };
        }
        case prd_constant.PATIENT_PAST_REPORT_FAILURE: {
            return { ...state, isGettingPatientPastReport: false, error: true, pastReports: null };
        }

        default:
            return state;
    }
};

export default patientDashboardScreenReducer;
