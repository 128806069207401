import React from "react";
import { connect } from "react-redux";
import Popup from "reactjs-popup";

const PolygenicScore = ({ data, userType, t }) => {
  const { nutrients } = data;

  const pirData = (data?.ps_score != "" ?
    nutrients.map((item) => {
      return (
        <h1 className="pg-sub-heading">{item.name}</h1>
      )
    }) : "")

  const patientTextColor = { color: userType === "Patient" ? "var(--pg_primary__p)" : 'var(--pg_primary)' }
  return (
    <div className="">
      <hr className="my-5" />
      <div className="my-5">
        <div className="pg-heading my-2" style={patientTextColor}> {t('report_trait_recom_txt_1')} </div>
        <div className="pd-2">
          {data?.ps_score ? (
            <div className='info-fx'>
              <h1 className='pg-text__bold'>{t('report_trait_recom_txt_2')}</h1>
              <div className="pir-content">
                <span className="info-logo">
                  <i className="fas fa-info-circle fa-circle-info info-icon-blue i-icon"></i>
                  <div className="popup-content pir-box">
                    <h1 className="pg-sub-heading">{t('report_trait_recom_txt_3')}</h1>
                    <p>{data.pir_about}</p>
                    <svg data-testid="arrow" className="popup-arrow-2" viewBox="0 0 32 16"><path d="M16 0l16 16H0z" fill="currentcolor"></path></svg>
                  </div>
                </span>
              </div>
            </div>
          ) : ""}
          {pirData}
          <h1 className='pg-text'> {data.pir_description} </h1>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PolygenicScore);
