import { createAction } from "redux-api-middleware";

export const GET_WELCOME_REQUEST = "GET_WELCOME_REQUEST";
export const GET_WELCOME_SUCCESS = "GET_WELCOME_SUCCESS";
export const GET_WELCOME_FAILURE = "GET_WELCOME_FAILURE";
export const GET_QUESTIONS_REQUEST = "GET_QUESTIONS_REQUEST";
export const GET_QUESTIONS_SUCCESS = "GET_QUESTIONS_SUCCESS";
export const GET_QUESTIONS_FAILURE = "GET_QUESTIONS_FAILURE";
export const GET_QUESTION_REQUEST = "GET_QUESTION_REQUEST";
export const GET_QUESTION_SUCCESS = "GET_QUESTION_SUCCESS";
export const GET_QUESTION_FAILURE = "GET_QUESTION_FAILURE";
export const SUBMIT_ANSWER_REQUEST = "SUBMIT_ANSWER_REQUEST";
export const SUBMIT_ANSWER_SUCCESS = "SUBMIT_ANSWER_SUCCESS";
export const SUBMIT_ANSWER_FAILURE = "SUBMIT_ANSWER_FAILURE";
export const PATIENT_ANSWERS_REQUEST = "PATIENT_ANSWERS_REQUEST";
export const PATIENT_ANSWERS_SUCCESS = "PATIENT_ANSWERS_SUCCESS";
export const PATIENT_ANSWERS_FAILURE = "PATIENT_ANSWERS_FAILURE";
export const UPDATE_QUESTION_SESSION_REQUEST =
  "UPDATE_QUESTION_SESSION_REQUEST";
export const UPDATE_QUESTION_SESSION_SUCCESS =
  "UPDATE_QUESTION_SESSION_SUCCESS";
export const UPDATE_QUESTION_SESSION_FAILURE =
  "UPDATE_QUESTION_SESSION_FAILURE";
export const UPDATE_QUESRIONNAIRE_PROCESS = "UPDATE_QUESRIONNAIRE_PROCESS";
export const QUESTIONNAIRE_PROCESS_STATUS_REQUEST = "QUESTIONNAIRE_PROCESS_STATUS_REQUEST";
export const QUESTIONNAIRE_PROCESS_STATUS_SUCCESS = "QUESTIONNAIRE_PROCESS_STATUS_SUCCESS";
export const QUESTIONNAIRE_PROCESS_STATUS_FAILURE = "QUESTIONNAIRE_PROCESS_STATUS_FAILURE";

export const getWelcomeQuestion = (patientId) => {
  const QUESTION_BASE_URL = process.env.REACT_APP_QUESTIONNAIRE_DOMAIN;
  return createAction({
    // endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/web/api/send_snp`,
    endpoint: `${QUESTION_BASE_URL}/welcome?patient_id=${patientId}`,
    method: "GET",
    types: [GET_WELCOME_REQUEST, GET_WELCOME_SUCCESS, GET_WELCOME_FAILURE],
  });
};

export const getQuestion = (questionId) => {
  const QUESTION_BASE_URL = process.env.REACT_APP_QUESTIONNAIRE_DOMAIN;
  return createAction({
    // endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/web/api/send_snp`,
    endpoint: `${QUESTION_BASE_URL}/v1/question/${questionId}`,
    method: "GET",
    types: [GET_QUESTION_REQUEST, GET_QUESTION_SUCCESS, GET_QUESTION_FAILURE],
  });
};

export const endSession = (data) => {
  const QUESTION_BASE_URL = process.env.REACT_APP_QUESTIONNAIRE_DOMAIN;

  return createAction({
    endpoint: `${QUESTION_BASE_URL}/end_question_session`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    types: [
      SUBMIT_ANSWER_REQUEST,
      SUBMIT_ANSWER_SUCCESS,
      SUBMIT_ANSWER_FAILURE,
    ],
  });
};

export const submitAnswer = (data) => {
  const QUESTION_BASE_URL = process.env.REACT_APP_QUESTIONNAIRE_DOMAIN;

  return createAction({
    endpoint: `${QUESTION_BASE_URL}/v1/question/${data.question_id}`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    types: [
      SUBMIT_ANSWER_REQUEST,
      SUBMIT_ANSWER_SUCCESS,
      SUBMIT_ANSWER_FAILURE,
    ],
  });
};

export const getQuestions = () => {
  const QUESTION_BASE_URL = process.env.REACT_APP_QUESTIONNAIRE_DOMAIN;
  return createAction({
    // endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/web/api/send_snp`,
    endpoint: `${QUESTION_BASE_URL}/v1/question`,
    method: "GET",
    types: [
      GET_QUESTIONS_REQUEST,
      GET_QUESTIONS_SUCCESS,
      GET_QUESTIONS_FAILURE,
    ],
  });
};

export const patientAnswers = (
  token,
  currentPracticeID,
  currentPractice,
  patientId
) => {
  const protocol = process.env.REACT_APP_PROTOCOL;
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/patient/patient_answer_data?patient_id=${patientId}`,
    method: "GET",
    headers: {
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      PATIENT_ANSWERS_REQUEST,
      PATIENT_ANSWERS_SUCCESS,
      PATIENT_ANSWERS_FAILURE,
    ],
  });
};

export const updateQuestionSession = (
  token,
  currentPracticeID,
  currentPractice,
  data
) => {
  const protocol = process.env.REACT_APP_PROTOCOL;
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/patient/update_patient_question_session`,
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      UPDATE_QUESTION_SESSION_REQUEST,
      UPDATE_QUESTION_SESSION_SUCCESS,
      UPDATE_QUESTION_SESSION_FAILURE,
    ],
  });
};

export const updateQuestionnaireProcess = (data) => ({
  type: UPDATE_QUESRIONNAIRE_PROCESS,
  payload: data,
});

export const getQuestionnaireProcessStatus = (
  token,
  currentPracticeID,
  currentPractice,
  data
) => {
  const baseUrl = process.env.REACT_APP_PG3_APP_DOMAIN;
  const type = data.type;
  const recommendation_input_id = data.recommendation_input_id;
  return createAction({
    endpoint: `${baseUrl}/web/api/get_analyze_status?type=${type}&recommendation_input_id=${recommendation_input_id}`,
    method: "GET",
    headers: { Authorization: token, "Current-Practice": currentPracticeID },
    types: [
      QUESTIONNAIRE_PROCESS_STATUS_REQUEST,
      QUESTIONNAIRE_PROCESS_STATUS_SUCCESS,
      QUESTIONNAIRE_PROCESS_STATUS_FAILURE,
    ],
  });
};