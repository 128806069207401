import _ from "lodash";
import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";

function PgSelect({
  setSelectedItem,
  selectedItem,
  placeholder,
  options = [],
  error,
  id,
  label,
  required,
  showLabel,
  errorMessage,
  disclaimer,
  errorTextStart = "",
  errorLineHeight = {},
  displayKey = "label", // The key to display in the dropdown (e.g., 'label')
  valueKey = "value", // The key used for selecting value (optional if needed)
}) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const default_class = "";

  const handleSelect = (option) => {
    setSelectedItem(option); // Return the full selected object
  };

  return (
    <div className={default_class}>
      {showLabel && (
        <div>
          <div className="d-inline-block pg-text__bold textc-primary">
            {label}
          </div>
          {required && (
            <span className="textc-primary fst-italic">(Required)</span>
          )}
        </div>
      )}
      <div className="cursor-pointer">
        <Dropdown
          className="d-inline-block position-relative w-100"
          onToggle={() => setIsPopupOpen(!isPopupOpen)}
          id={id}
        >
          <Dropdown.Toggle
            as="div"
            bsPrefix="border-radius py-auto pg-text d-flex justify-content-between"
            style={{
              border: error ? "2px solid #f00" : "3px solid #acd4ff",
              borderRadius: "8px",
              height: 50,
              padding: "6px 10px",
              width: "100%",
            }}
          >
            <span
              id="selected-item"
              className="my-auto overflow-hidden"
              style={{
                color:
                  (_.isObject(selectedItem) && selectedItem[displayKey]) ||
                  selectedItem
                    ? "#000"
                    : "#888",
                height: "100%",
                overflow: "hidden",
                fontWeight: 400,
              }}
            >
              {_.isObject(selectedItem)
                ? selectedItem[displayKey]
                : selectedItem
                ? selectedItem
                : placeholder}
            </span>{" "}
            <i
              className="fa fa-chevron-down my-auto transition-all fs-6 text_primary"
              aria-hidden="true"
              style={{
                transform: isPopupOpen ? "rotate(-180deg)" : "rotate(0deg)",
              }}
            ></i>
          </Dropdown.Toggle>
          <Dropdown.Menu
            bsPrefix="dropdown-menu w-100 border-radius"
            style={{
              borderRadius: 8,
              background: "white",
              zIndex: 9999,
              opacity: 1,
              maxHeight: "300px",
              overflowY: "auto",
            }}
          >
            {options.map((option, i) => (
              <Dropdown.Item
                key={i}
                onClick={() => handleSelect(option)}
                className="pg-text text-dark"
                id={`select-option__${option[valueKey] || i}`}
              >
                {option[displayKey]}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        {disclaimer && (
          <p className="pg-text" style={{ marginTop: "15px" }}>
            {disclaimer}
          </p>
        )}
        {
          <div
            className={`text-danger mx-2 my-1 transition-all ${errorTextStart}`}
            style={
              error
                ? { opacity: 1, ...errorLineHeight }
                : { opacity: 0, ...errorLineHeight }
            }
          >
            <i className="fas fa-info-circle"></i> {errorMessage}
          </div>
        }
      </div>
    </div>
  );
}

export default PgSelect;
