import React from "react";

const TextArea = ({
  name,
  row,
  col,
  placeholder,
  label,
  showLabel,
  required,
  optional,
  isDisable,
  error,
  errorMessage,
  inputHandler,
  value, ...props
}) => {
  const style = {
    padding: 15,
    width: "100%",
    outline: "none",
    border: error ? "2px solid #f00" : "3px solid #acd4ff",
    borderRadius: "4px",
    minHeight: "160px",
    transition: "all .3s ease-in-out",
  };
  return (
    <div className="my-2">
      <div>
        {showLabel && (
          <div>
            <div className="d-inline-block pg-text__bold textc-primary">
              {label}
            </div>
            {required && (
              <span className="textc-primary fst-italic"> (Required)</span>
            )}
            {optional && !isDisable && (
              <span className="textc-primary fst-italic"> (Optional)</span>
            )}
          </div>
        )}
      </div>
      <div>
        <textarea
          name={name}
          rows={row}
          cols={col}
          placeholder={placeholder}
          style={style}
          onInput={inputHandler}
          value={value}
          {...props}
        ></textarea>
        <div
          className="text-danger mx-2 my-1 transition-all"
          style={error ? { opacity: 1 } : { opacity: 0 }}
        >
          <i className="fas fa-info-circle"></i> {errorMessage}
        </div>
      </div>
    </div>
  );
};

export default TextArea;
