import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PatientListItem from "./PatientListItem";
import { useTranslation } from "react-i18next";
export default function PatientListTable({
  patients,
  error,
  sortReport,
  sortName,
  sortPatientStatus,
  sortReportStatus,
  sort,
  updatePatientReport,
  canShowAPDialog,
  updatePatientList
}) {
  const { t, i18n } = useTranslation();

  return (
    <div className="container-fluids">
      <div
        className="row pg-text__bold px-2 py-2 mx-0"
        style={{ backgroundColor: "#acd4ff", borderRadius: "4px 4px 0px 0px" }}
      >
        <div className="col-md-3 col-lg-3 col-3 cursor-pointer" onClick={() => sort("name")}>
          {t('name')}
          {<span>
            {sortName !== "DESC" ? (
              <i
                className="fas fa-sort-up mx-1"
                style={{
                  verticalAlign: "sub",
                }}
              ></i>
            ) : (
              <i
                className="fas fa-sort-down mx-1"
                style={{
                  verticalAlign: "baseline",
                }}
              ></i>
            )}
          </span>}
        </div>
        <div className="col-2  cursor-pointer" onClick={() => sort("patient-status")}>
          {t('pracpl_patient_status')}  {<span>{sortPatientStatus !== "DESC" ? (
            <i
              className="fas fa-sort-up mx-1"
              style={{
                verticalAlign: "sub",
              }}
            ></i>
          ) : (
            <i
              className="fas fa-sort-down mx-1"
              style={{
                verticalAlign: "baseline",
              }}
            ></i>
          )}</span>}
        </div>
        <div className="col-2  cursor-pointer" onClick={() => sort("report")}>
          {t('report')}
          {<span>
            {sortReport !== "DESC" ? (
              <i
                className="fas fa-sort-up mx-1"
                style={{
                  verticalAlign: "sub",
                }}
              ></i>
            ) : (
              <i
                className="fas fa-sort-down mx-1"
                style={{
                  verticalAlign: "baseline",
                }}
              ></i>
            )}
          </span>}
        </div>
        {/* <div className="col-2  cursor-pointer" onClick={() => sort("report-status")}>
          {t('pracpl_report_status')}  {<span>
            {
              sortReportStatus !== "DESC" ? (
                <i
                  className="fas fa-sort-up mx-1"
                  style={{
                    verticalAlign: "sub",
                  }}
                ></i>
              ) : (
                <i
                  className="fas fa-sort-down mx-1"
                  style={{
                    verticalAlign: "baseline",
                  }}
                ></i>
              )
            }
          </span>}
        </div> */}
      </div>
      {error && (
        <div className="w-100 text-center my-3 text-danger">
          {t('pracpl_error')}
        </div>
      )}
      {patients?.length < 1 && !error && (
        <div className="w-100 text-center my-3 ">{t('pracpl_nodata')}</div>
      )}
      {patients?.length > 0 &&
        !error &&
        patients.map((patient, i) => (
          <PatientListItem
            patient={patient}
            key={i}
            updatePatientReport={updatePatientReport}
            expandPatientListItem={i === 0}
            updatePatientList={updatePatientList}
          />
        ))}
    </div>
  );
}