import React, { useState, useEffect, useMemo } from "react";
import { parse } from "query-string";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import _ from "lodash";


import { getSupplementalGenotype } from "../../store/actions/patientReport";
import GenotypeTable from "../../components/Report/Genotype/GenotypeTable";
import GenotypFilter from "../../components/Report/Genotype/GenotypFilter";
import { generatePDF } from "../../methods";
import L_PatientInfo from "../../components/LoadingEffectComponent/Report/L_PatientInfo";
import L_Genotype from "../../components/LoadingEffectComponent/Report/L_Genotype";
import { useNavigate } from "react-router-dom";

const parsed = parse(window.location.search);
function SupplementalGenotype({ history }) {

  // redux state variable

  const currentPracticeID = useSelector(state => state.auth.currentPracticeID);
  const currentPractice = useSelector(state => state.auth.currentPractice);
  const token = useSelector(state => state.auth.token);
  const genotype = useSelector(state => state.patientReport.genotypeReport);
  const patientInfo = useSelector(state => state.patientReport.patientDetail);
  const loading = useSelector(state => state.patientReport.isGettingGenotype);
  const currentReportId = useSelector(state => state.patientReport.currentReportId);

  // redux actions
  const dispatch = useDispatch();

  const getSupplementalGenotypeAction = (token, currentPracticeID, currentPractice, id) => dispatch(getSupplementalGenotype(token, currentPracticeID, currentPractice, id));

  const { t, i18n } = useTranslation();
  const [genotypeData, setGenotypeData] = useState([]);
  const [showGenotypeFilter, setShowGenotypeFilter] = useState(false);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
  const [filter, setFilter] = useState([]);
  const navigate = useNavigate();


  useEffect(() => {
    updateTable();
  }, []);

  const updateTable = () => {
    getSupplementalGenotypeAction(
      token,
      currentPracticeID,
      currentPractice,
      currentReportId
    ).then((response) => {
      if (!response.error) setGenotypeData(genotype);
    });
  };

  useEffect(() => {
    if (filter.length > 0)
      setGenotypeData((__data) => {
        return __data.filter((_data) => {
          return filter.includes(_data.gene);
        });
      });
  }, [filter]);

  return (
    <div className="container-xxl px-0 my-5 py-3">
      {loading ? (
        <L_Genotype />
      ) : (
        <div id="supplemental-genotype">
          {showGenotypeFilter && (
            <GenotypFilter
              showModal={showGenotypeFilter}
              t={t}
              onCancel={() => setShowGenotypeFilter(false)}
              data={genotype}
              updateTable={updateTable}
            />
          )}
          <div
            className="pg-link"
            onClick={() => navigate(-1)}
          >
            <i className="fas fa-chevron-left"></i> {t('pracrs_title')}
          </div>
          <div className="row my-3 mt-5 justify-content-between">
            <div className="col-10">
              <div className="pg-title">{t('genotype_title')}</div>
              <div className="pg-heading my-4">
                {patientInfo?.patient.full_name}
              </div>
              <div className="pg-text my-3" dangerouslySetInnerHTML={{ __html: t('genotype_line_1') }} />
            </div>
          </div>
          <hr className="my-2" />
          <div className="row mb-5 my-2">
            <div className="text-end my-3">
              <button
                className="btn btn-outline"
                onClick={() => setShowGenotypeFilter(true)}
              >
                {t('genotype_btn_1')}
              </button>
            </div>
            <div className="col-12">
              {!loading && (
                <GenotypeTable
                  genotype={genotype?.filter((gd) => !gd.hide) ?? []}
                  sample={true}
                  error={false}
                  t={t}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SupplementalGenotype;
