import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import toastr from 'react-hot-toast'
import { parse } from "query-string";
import _ from 'lodash'

import ActionButtons from '../ActionButtons'
import EditPractitionerModal from './EditPractitionerModal'
import PractitionerBatchFnModal from './PractitionerBatchFnModal'

import { resetLockout, resetPassword, archivePractitioners, restorePractitioners, approvePractitioners, sendRegistrationReminder, requestPractitionerCSV } from '../../../store/actions/adminPractitioner'
import { toggleLoading } from '../../../store/actions/ui'
import { isAllArrayElementHas, isAnyArrayElementHas } from '../../../methods'

function PractitionerBatchFn({
    filter = [],
    setSelectedPractitioner, selectedPractitioner, practitioners, updatePractitionerTable }) {

    const dispatch = useDispatch();
    const token = useSelector(state => state.auth.token);
    const masterAdmin = useSelector(state => state.auth.masterAdmin);
    const [showEditModal, setShowEditModal] = useState(false)
    const [showResetModal, setShowResetModal] = useState(false);
    const [showLockoutModal, setShowLockoutModal] = useState(false)
    const [showArchiveModal, setShowArchiveModal] = useState(false)
    const [showRestoreModal, setShowRestoreModal] = useState(false)
    const [showApproveModal, setShowApproveModal] = useState(false)
    const [showRegistrationReminderModal, setShowRegistrationReminderModal] = useState(false)

    const addParams = () => {
        const { region } = parse(window.location.href);
        console.log(region);
        let params = "?";
        if (region) params += ('region=' + region);
        if (filter.length > 0) params += '&account_type=' + filter;
        return params;
    }

    const onDownload = () => {
        dispatch(requestPractitionerCSV(token, addParams())).then(response => {
            if (!response.error) toastr.success(response.payload.message)
        });
    }
    const onRestore = () => {
        if (isAllArrayElementHas(practitioners, selectedPractitioner, 'aasm_state', "archived")) {
            dispatch(toggleLoading(true));
            dispatch(restorePractitioners(token, selectedPractitioner)).then(response => {
                dispatch(toggleLoading(false));
                if (!response.error) {
                    updatePractitionerTable();
                    setSelectedPractitioner([]);
                    toastr.success(response.payload.message)
                }
                setShowRestoreModal(false)
            });
        } else {
            setShowRestoreModal(false)
            toastr.error("Select only archived practitioner")
        }
    }
    const onArchive = () => {
        if (isAnyArrayElementHas(practitioners, selectedPractitioner, 'aasm_state', "archived")) {
            dispatch(toggleLoading(true));
            dispatch(archivePractitioners(token, selectedPractitioner)).then(response => {
                dispatch(toggleLoading(false));
                if (!response.error) {
                    toastr.success(response.payload.message);
                    setSelectedPractitioner([]);
                    updatePractitionerTable();
                }
                setShowArchiveModal(false)
            });
        } else {
            setShowArchiveModal(false)
            toastr.error("Select only active practitioner")
        }
    }
    const onLockout = () => {
        dispatch(toggleLoading(true));
        dispatch(resetLockout(token, selectedPractitioner)).then(response => {
            dispatch(toggleLoading(false));
            if (!response.error) {
                toastr.success(response.payload.message);
                setSelectedPractitioner([]);
                updatePractitionerTable();
            }
            setShowLockoutModal(false)
        });;
    }

    const onReset = () => {
        dispatch(toggleLoading(true));
        dispatch(resetPassword(token, selectedPractitioner)).then(response => {
            dispatch(toggleLoading(false));
            if (!response.error) {
                toastr.success(response.payload.message);
                setSelectedPractitioner([]);
                updatePractitionerTable();
            }
            setShowResetModal(false)
        });
    }

    const onApprove = () => {
        dispatch(toggleLoading(true));
        dispatch(approvePractitioners(token, selectedPractitioner)).then(response => {
            dispatch(toggleLoading(false));
            if (!response.error) {
                toastr.success(response.payload.message);
                setSelectedPractitioner([]);
                updatePractitionerTable();
            }
            setShowApproveModal(false)
        });
    }

    const onRegistrationReminder = () => {
        if (isAllArrayElementHas(practitioners, selectedPractitioner, 'aasm_state', "awaiting")) {
            dispatch(toggleLoading(true));
            dispatch(sendRegistrationReminder(token, selectedPractitioner)).then(response => {
                dispatch(toggleLoading(false));
                if (!response.error) {
                    toastr.success(response.payload.message);
                    setSelectedPractitioner([]);
                    updatePractitionerTable();
                }
                setShowRegistrationReminderModal(false)
            });
        } else {
            setShowRegistrationReminderModal(false)
            toastr.error("Select only awaiting practitioners")
        }

    }

    return (
        <div className="my-2 mt-4">
            {showEditModal && <EditPractitionerModal
                showModal={showEditModal}
                onCancel={() => setShowEditModal(false)}
                updatePractitionerTable={updatePractitionerTable}
                practitioner={practitioners[_.findIndex(practitioners, { id: selectedPractitioner[0] })] || {}}
            />}
            {showResetModal && <PractitionerBatchFnModal
                onCancel={() => setShowResetModal(false)}
                showModal={showResetModal}
                onYes={onReset}
            >
                <div className="pg-text__bold my-4">
                    Are you sure you want to reset the password for the selected practitioner?
                </div>
            </PractitionerBatchFnModal>}
            {showLockoutModal && <PractitionerBatchFnModal
                showModal={showLockoutModal}
                onCancel={() => setShowLockoutModal(false)}
                onYes={onLockout}
            >
                <div className="pg-text__bold my-4">
                    Are you sure to reset lockout for selected Practitioner ?
                </div>
            </PractitionerBatchFnModal>}
            {showArchiveModal && <PractitionerBatchFnModal
                showModal={showArchiveModal}
                onCancel={() => setShowArchiveModal(false)}
                onYes={onArchive}
            >
                <div className="pg-text__bold my-4">
                    Are you sure to archive selected Practitioner ?
                </div>
            </PractitionerBatchFnModal>}
            {showRestoreModal && <PractitionerBatchFnModal
                showModal={showRestoreModal}
                onCancel={() => setShowRestoreModal(false)}
                onYes={onRestore}
            >
                <div className="pg-text__bold my-4">
                    Are you sure to restore the selected archived Practitioners ?
                </div>
            </PractitionerBatchFnModal>}
            {showApproveModal && <PractitionerBatchFnModal
                showModal={showApproveModal}
                onCancel={() => setShowApproveModal(false)}
                onYes={onApprove}
            >
                <div className="pg-text__bold my-4">
                    Are you sure to approve the selected Practitioners ?
                </div>
            </PractitionerBatchFnModal>}
            {showRegistrationReminderModal && <PractitionerBatchFnModal
                showModal={showRegistrationReminderModal}
                onCancel={() => setShowRegistrationReminderModal(false)}
                onYes={onRegistrationReminder}
            >
                <div className="pg-text__bold my-4">
                    Are you sure to send registration emails to the selected Practitioners?
                </div>
            </PractitionerBatchFnModal>}
            <ActionButtons
                onArchive={() => setShowArchiveModal(true)}
                onDownload={onDownload}
                onEdit={() => setShowEditModal(true)}
                onLockout={() => setShowLockoutModal(true)}
                onReset={() => setShowResetModal(true)}
                onRestore={() => setShowRestoreModal(true)}
                onApprove={() => setShowApproveModal(true)}
                onRegistrationReminder={() => setShowRegistrationReminderModal(true)}
                disableEdit={selectedPractitioner.length !== 1}
                disableArchive={selectedPractitioner.length === 0 || !isAnyArrayElementHas(practitioners, selectedPractitioner, 'aasm_state', 'archived')}
                disableReset={selectedPractitioner.length === 0 || !isAnyArrayElementHas(practitioners, selectedPractitioner, 'aasm_state', 'pending') || !isAnyArrayElementHas(practitioners, selectedPractitioner, 'aasm_state', 'awaiting') || !isAnyArrayElementHas(practitioners, selectedPractitioner, 'aasm_state', 'archived')}
                disableLockout={selectedPractitioner.length === 0 || !isAllArrayElementHas(practitioners, selectedPractitioner, 'aasm_state', 'locked')}
                disableRestore={selectedPractitioner.length === 0 || !isAllArrayElementHas(practitioners, selectedPractitioner, 'aasm_state', 'archived')}
                disableApprove={selectedPractitioner.length === 0 || !isAllArrayElementHas(practitioners, selectedPractitioner, 'aasm_state', 'pending')}
                disableRegistrationReminder={selectedPractitioner.length === 0 || !isAllArrayElementHas(practitioners, selectedPractitioner, 'aasm_state', 'awaiting')}
            />
        </div>
    )
}

export default PractitionerBatchFn
