import { t } from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function IntervalDropdown({ question, onInput }) {
  const { t } = useTranslation();
  const answers = useSelector((state) => state.pg4.q_answers);
  const errors = useSelector((state) => state.pg4.q_errors);

  const handleFeetChange = (event) => {
    onInput({
      [question.question_id]: {
        question_id: question.question_id,
        answer: [event?.target?.value],
      },
    });
  };

  useEffect(() => {
    console.log(errors, "errors");
  }, [errors]);

  const options = useMemo(() => {
    if (question && question.options) {
      const { start, end, interval } = question.options;
      return Array.from(
        { length: (end - start) / interval + 1 },
        (_, index) => start + index * interval
      );
    } else {
      // Fallback option: If API response is not available, show options from 1 to 10 by default
      return Array.from({ length: 10 }, (_, index) => index + 1);
    }
  }, [question]);

  return (
    <div className="pg4-card" id={question.question_id}>
      <div className="pg-heading__sub textc-primary__p">
        {t(question.question_text)}
      </div>
      <div>{/* For example, 5' 7" is 67 inches. */}</div>
      <div className=" mt-3 fw-bold">
        <label htmlFor={question?.question_header}>
          {t(question?.question_header)}
        </label>
        <div className="d-flex flex-row">
          <select
            id={question?.question_header}
            value={answers?.[question?.question_id]?.answer[0] || ""}
            onChange={handleFeetChange}
            className="pg4-select my-2"
          >
            <option key={123} value="" disabled></option>
            {options.map((option) => (
              <option key={option} value={option}>
                {t(option)}
              </option>
            ))}
          </select>
          <div className="my-auto ms-2">{t(question.options?.unit)}</div>
        </div>
      </div>
      <div
        className="text-danger transition-all"
        style={{ opacity: errors[question?.question_id] ? 1 : 0 }}
      >
        <i className="fas fa-info-circle"></i> {t("pracand_genetic_error")}
      </div>
    </div>
  );
}

export default IntervalDropdown;
