import React, { Component, useEffect, useState, useRef } from "react";
import { useTable, usePagination, useSortBy, useRowSelect } from "react-table";
import { connect, useDispatch, useSelector } from "react-redux";
import { getStaffList } from "../../../store/actions/adminStaff";
import { useHistory, useLocation, useNavigate } from "react-router-dom";
import PractitionerTable from "./StaffTable";
import { parse } from "query-string";

function StaffList() {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedPractitioner, setSelectedPractitioner] = useState("");
  const [loading, setLoading] = useState(true);
  const token = useSelector(state => state.auth.token);
  // const practitioners=useSelector(state=>state.adminPractitioner.practitioners);
  const totalPractitioners = useSelector(state => state.adminPractitioner.total_count);

  const dispatch = useDispatch();
  const getStaffsAction = (token, url) => dispatch(getStaffList(token, url));

  const params = parse(location.search);
  const selectedRegion = params["region"]

  const [practitioners, setPractitioners] = useState([]);

  const updatePatientTable = (url = "per_page=10&page=1") => {
    getStaffsAction(token, (url + "&region=" + selectedRegion)).then((response) => {
      // console.log(response.payload.staffs)
      setPractitioners(response.payload.staffs)
      setLoading(false)
    });
  };

  return (
    <div className="container-fluid">
      <div className="container-xxl py-5 px-0">
        <div
          className="cursor-pointer textc-primary pg-link"
          onClick={() => navigate("/admin/dashboard")}
        >
          <i className="fas fa-chevron-left mr-2"></i> Dashboard
        </div>
        <div className=" my-4 pg-title">Staff Members</div>
        {/* <div className="pg-text">
        Choose a patient from the list to begin making supplement recommendations. This will not not include any supplement suggestions from the patient's report
        </div> */}

        <div>
          <PractitionerTable
            token={token}
            practitioners={practitioners}
            updatePatientTable={updatePatientTable}
            totalData={totalPractitioners}
            loading={loading}
            selectedRegion={selectedRegion}
          />
        </div>
      </div>
      {/* <div className="bgc-secondary text-center py-3">
        <button
          className="btn btn-primary text-light px-5 py-2 w-25"
          disabled={!selectedPatient}
          onClick={redirectToCatalog}
        >
          OK
        </button>
      </div> */}
    </div>
  );
}

export default StaffList
