import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useSelector, useDispatch } from 'react-redux';
import { clearCookie } from "../../methods";
import Cookies from "universal-cookie";
import { LOGOUT } from "../../store/actions/auth";
import { parse, parseUrl } from "query-string";
import _ from "lodash";
import { checkPg2UserToken } from "../../store/actions/Pg2User";
import jwt_decode from "jwt-decode";
import { Loading } from "../../components/Loading";
import { useLocation, useNavigate } from "react-router-dom";

const cookies = new Cookies();

function Pg2UserLandingPage() {
  const location = useLocation();

  // redux state variable

  const token = useSelector(state => state.auth.token);
  const practices = useSelector(state => state.auth.practices);
  // redux actions

  const dispatch = useDispatch();
  const logoutAction = () => dispatch({ type: LOGOUT });
  const checkPg2UserToken = (data) => dispatch(checkPg2UserToken(data));





  const BASE_URL = process.env.REACT_APP_BASE_DOMAIN; //REACT_APP_PG3_APP_DOMAIN
  const DOMAIN_FOR_COOKIE = process.env.REACT_APP_BASE_DOMAIN_FOR_COOKIE;
  const protocol = process.env.REACT_APP_PROTOCOL;
  const [PracticeToken, setPracticeToken] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    Pg2UserToken();
  }, []);

  let Pg2UserToken = async (e) => {
    const parsed = parse(window.location.search);
    if (_.isUndefined(parsed.token) || parsed.token.length < 1) {
      toast.error("Invalid URL");
      return navigate("/");
    }
    setPracticeToken(parsed.token);
    const data = { token: parsed.token };

    const response = await checkPg2UserToken(data);
    if (response.payload.token.length != 0) {
      const token = response.payload.token;
      const decodedData = jwt_decode(response.payload.token);
      toast.success("Logged in successfully");
      const practices = decodedData.practices;
      let termsAgreed = practices[0]?.terms_agreed;

      if (decodedData.user_type === "Patient") {
        const domain = practices.subdomain;
        const practiceId = practices.id;

        clearCookie();

        cookies.set("isAuthenticated", true, {
          path: "/",
          domain: DOMAIN_FOR_COOKIE,
        });
        cookies.set("token", token, { path: "/", domain: DOMAIN_FOR_COOKIE });
        cookies.set("p_id", practiceId, {
          path: "/",
          domain: DOMAIN_FOR_COOKIE,
        });
        cookies.set("practice", domain, {
          path: "/",
          domain: DOMAIN_FOR_COOKIE,
        });
        logoutAction();
        window.location = `${window.location.protocol}//${domain}${BASE_URL}/patient/dashboard`;
        if (decodedData.user.welcome_shown) {
          window.location = `${window.location.protocol}//${domain}${BASE_URL}/patient/dashboard`;
        } else {
          window.location = `${window.location.protocol}//${domain}${BASE_URL}/patient/dashboard`;
        }
      } else if (decodedData.user_type === "User") {
        const subdomain = window.location.host.split(".")[0];
        // if window.location.host.split('.').length < 2 which means no subdomain
        // in that case currentPractice below will be undefined sice there will no practice suddomain with hostname
        const currentPractice = _.find(practices, { subdomain });
        clearCookie();
        if (practices.length === 0) {
          toast.error("Invalid User", { progressBar: false });
        } else if (practices.length === 1) {
          const domain = practices[0].subdomain;
          const practiceId = practices[0].id;
          clearCookie();
          cookies.set("isAuthenticated", true, {
            path: "/",
            domain: DOMAIN_FOR_COOKIE,
          });
          cookies.set("token", token, { path: "/", domain: DOMAIN_FOR_COOKIE });
          cookies.set("p_id", practiceId, {
            path: "/",
            domain: DOMAIN_FOR_COOKIE,
          });
          cookies.set("practice", domain, {
            path: "/",
            domain: DOMAIN_FOR_COOKIE,
          });
          cookies.set("termsAgreed", termsAgreed, {
            path: "/",
            domain: DOMAIN_FOR_COOKIE,
          });
          logoutAction();
          let path = practices[0]?.post_login
            ? `/hcp/educational_1`
            : `/practice/dashboard`;
          window.location = `${window.location.protocol}//${domain}${BASE_URL}${path}`;
        }
      }
    } else {
      toast.error("Invalid Token");
    }
  };

  return (
    <div className="container">
      <Loading />
    </div>
  );
}

export default Pg2UserLandingPage;
