import { createAction } from "redux-api-middleware";
export const PRACTICE_GDPR_REQUEST = "PRACTICE_GDPR_REQUEST";
export const PRACTICE_GDPR_SUCCESS = "PRACTICE_GDPR_SUCCESS";
export const PRACTICE_GDPR_FAILURE = "PRACTICE_GDPR_FAILURE";

const protocol = process.env.REACT_APP_PROTOCOL;
const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;

export const getPracticeAllPatientGDPRLog = (token, currentPracticeID, currentPractice, url) => {
    return createAction({
        endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/Patient/user_gdpr_list?${url}`,
        method: "GET",
        headers: { "Content-Type": "application/json", Authorization: token, 'Current-Practice': currentPracticeID },
        types: [
            PRACTICE_GDPR_REQUEST,
            PRACTICE_GDPR_SUCCESS,
            PRACTICE_GDPR_FAILURE,
        ],
    });
}