import "../../styles/prsScore.css";
import { useEffect } from "react";
import { getPSlist } from "../../store/actions/psList";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import L_PRS_page from "../../components/LoadingEffectComponent/L_PRS_page";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
// import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PrsScorePatient = ({ userType, currentPracticeID, currentPractice }) => {

  const token = useSelector((state) => state.auth.token);
  const params = useParams();
  const reportId = params.report_id;
  const dispatch = useDispatch();
  const psListData = useSelector((state) => state.psList.ps_Data)
  const isError = useSelector((state) => state.psList.isError)
  const isLoading = useSelector((state) => state.psList.isGetting)
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();


  const patientTextColor = {
    color:
      userType === "Patient" ? "var(--pg_primary__p)" : "var(--pg_primary)",
  };
  const redirectToReport = () => {
    userType === "Patient"
      ? navigate("/patient/report_dashboard")
      : navigate(-1);
  };



  useEffect(() => {
    dispatch(
      getPSlist(token, currentPracticeID, currentPractice, userType, reportId)
    )
  }, [token, currentPracticeID, currentPractice, userType, reportId, dispatch]);
  return (
    <>
      {isLoading ? (
        <L_PRS_page />
      ) : (
        <div className="container-prs">
          <div
            className="pg-text pg-link mt-5"
            onClick={redirectToReport}
            style={patientTextColor}
          >
            {" "}
            <i className="fas fa-chevron-left"></i>{" "}
            {userType === "Patient" ? t('dashboard') : t('reports')}
          </div>
          <div className="grid">
            <div className=" bt-line align-self-end ">
              <h1 className="pg-heading mb-3">{t('prac_prs_score_content_1')}</h1>
            </div>

            <div className=" bt-line align-self-end ">
              <h1 className="pg-heading mb-3">
                {t('report_trait_recom_txt_1')}
              </h1>
            </div>

            {psListData.map((items) => {
              const { nutrients } = items;
              const pirData = (items?.ps_score != "" ?
                nutrients.map((item) => {
                  return (
                    <h1 className="pg-sub-heading">{item.name}</h1>
                  )
                }) : "")
              return (
                <>
                  <div className="pd bt-line prs">
                    <h1 className="pg-sub-heading">{items.trait}</h1>
                    {items?.ps_score != "" &&
                      <div>
                        <div className="info-fx ">
                          <h1 className="pg-text__bold__small ">
                            {t('your_poly_score')}
                          </h1>
                          <div className="">
                            <span className="info-logo">
                              <i className="fas fa-info-circle fa-circle-info info-icon-blue i-icon"></i>
                              <div className="popup-content ps-box">
                                <h1 className="pg-sub-heading">{t('about_score')}</h1>
                                <p>{items.ps_about}</p>
                                <svg data-testid="arrow" className="popup-arrow" viewBox="0 0 32 16"><path d="M16 0l16 16H0z" fill="currentcolor"></path></svg>
                              </div>
                            </span>
                          </div>
                        </div>
                        <h1 className="pg-sub-heading">{items.ps_score}</h1>
                      </div>}

                    <h1 className="pg-text">{items.ps_description}</h1>
                  </div>
                  <div className="pd mg-t bt-line">
                    {items?.ps_score != "" &&
                      <div className="info-fx">
                        <h1 className="pg-text__bold__small">
                        {t('report_trait_recom_txt_2')}
                        </h1>
                        <div className="pir-content">
                          <span className="info-logo">
                            <i className="fas fa-info-circle fa-circle-info info-icon-blue i-icon"></i>
                            <div className="popup-content pir-box">
                              <h1 className="pg-sub-heading">{t('report_trait_recom_txt_3')}</h1>
                              <p>{items.pir_about}</p>
                              <svg data-testid="arrow" className="popup-arrow-2" viewBox="0 0 32 16"><path d="M16 0l16 16H0z" fill="currentcolor"></path></svg>
                            </div>
                          </span>
                        </div>
                      </div>}
                    {pirData}
                    <h1 className="pg-text">{items.pir_description}</h1>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  userType: state.auth.userType,
  currentPracticeID: state.auth.currentPracticeID,
  currentPractice: state.auth.currentPractice,
});

export default connect(mapStateToProps, null)(PrsScorePatient);
