// MSQSection.jsx
import _ from "lodash";
import React, { useState, useRef, useEffect } from "react";
import L_MSQSections from "./L_MSQSections";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const MSQSections = ({
  onSelectSections,
  selectedSection,
  isLoading,
  questionsData,
}) => {
  const { t } = useTranslation();
  const sections = useSelector((state) => state.pg4.q_sections);
  const currentSection = useSelector((state) => state.pg4.q_current_section);

  return (
    <div className="bg_secondary px-1 py-2">
      {isLoading ? (
        <L_MSQSections />
      ) : (
        <>
          <div className="d-none d-lg-block">
            {_.isArray(sections) &&
              sections?.map((section, i) => {
                return (
                  <div
                    key={i}
                    onClick={
                      onSelectSections
                        ? () => onSelectSections(section)
                        : undefined
                    }
                    className={`msq-section__item ${
                      section.id <= currentSection?.section_id
                        ? "bg_tertiary"
                        : ""
                    }`}
                  >
                    <div className="me-2">{t(section.name)}</div>
                    <div className="checkbox-wrapper-18 my-auto">
                      <div className="round">
                        <input
                          disabled
                          type="checkbox"
                          id="checkbox-18"
                          checked={section.id < currentSection?.section_id}
                        />
                        <label for="checkbox-18"></label>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </>
      )}
    </div>
  );
};

export default MSQSections;
