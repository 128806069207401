import React from "react";
import { useTranslation } from "react-i18next";

export default function ShowSupplementRecomds({ content, navigateToScripts }) {
	
	const { t, i18n } = useTranslation();

	return (
		<div
			className="pq-card p-4 px-5"
			style={{
				backgroundColor: "#f6f6f6 !important",
				background: "#f6f6f6 !important",
				height: "390 !important",
			}}
		>
			<div className="pd-pg-heading text-center">
        { t('gene_analysis_th_6') }
			</div>
			<div className=" justify-content-between">
				<div className="my-4">
					<div className="pg-heading__sub">{content?.title}</div>
					<div className="pg-text my-2 py-2">
            { t('patient_dashboard_t1') }
					</div>
					<a
						className="pg-link text-color__p cursor-pointer pg-text d-block my-3 text-center"
						onClick={() => navigateToScripts()}
					>
						{ t('patient_dashboard_t2') } <i className="fas fa-chevron-right"></i>
					</a>
				</div>
			</div>
		</div>
	);
}