import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import PgMultiSelect from "../../../components/PgMultiSelect";
import _ from "lodash";
import toastr from "react-hot-toast";
import { validateEmail } from "../../../methods";
import { getAdminUser, editAdminUser } from "../../../store/actions/adminUser";
import TextInput from "../../TextInput";

function EditAdminModal({
  showModal,
  onCancel,
  adminId,
  updateAdminUserTable,
}) {
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();

  const [isValidForm, setIsValidForm] = useState(false);

  //form fields
  const [fName, setfName] = useState("");
  const [lName, setlName] = useState("");
  const [email, setEmail] = useState("");
  const [countries, setCountries] = useState([]);
  const [countryList, setCountryList] = useState([]);

  //for validation
  const [fNameError, setfNameError] = useState(false);
  const [lNameError, setlNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [countriesError, setCountriesError] = useState(false);

  useEffect(() => {
    dispatch(getAdminUser(token, adminId)).then((admin) => {
      if (!admin.error) {
        const { email, first_name, last_name, region } = admin.payload;
        setEmail(email || "");
        setlName(last_name || "");
        setfName(first_name || "");
        setCountries(region || "");
      } else {
        onclose();
      }
    });
  }, []);

  const onDone = () => {
    setfNameError(false);
    setlNameError(false);
    setEmailError(false);
    setCountriesError(false);

    if (email.trim().length === 0 || !validateEmail(email)) setEmailError(true);
    if (fName.trim().length === 0) setfNameError(true);
    if (lName.trim().length === 0) setlNameError(true);
    if (countries.length === 0) setCountriesError(true);
    if (
      email.trim().length !== 0 &&
      validateEmail(email) &&
      fName.trim().length !== 0 &&
      lName.trim().length !== 0 &&
      countries.length !== 0
    ) {
      handleDone();
    }
  };

  const handleDone = async () => {
    let data = {
      admin: {
        first_name: _.startCase(fName),
        last_name: lName,
        email: email.toLowerCase(),
        assigned_regions: countries,
      },
    };
    const response = await dispatch(editAdminUser(token, adminId, data));
    if (!response.error) {
      onCancel();
      updateAdminUserTable();
      toastr.success("Admin Updated");
    }
  };

  return (
    <Modal
      show={showModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={onCancel}
      contentClassName="border-radius"
      backdrop="static"
      className="edit-admin-modal"
    >
      <Modal.Body>
        <div
          style={{
            position: "absolute",
            right: 25,
            top: 15,
            fontSize: 20,
            cursor: "pointer",
            color: "var(--pg_primary)",
          }}
          onClick={onCancel}
        >
          <div className="fas fa-times"></div>
        </div>
        <div className="container">
          <div className="text-center pg-title">Edit Admin User</div>
          <div className="row">
            <div className="col-12">
              <TextInput
                label="First Name"
                showLabel={true}
                required={true}
                id="f-name__addadminuser"
                placeholder="Enter Admin First Name"
                value={fName}
                error={fNameError}
                type="text"
                errorMessage="First Name is required"
                inputHandler={(e) => setfName(e.target.value)}
              />

              <TextInput
                label="Last Name"
                showLabel={true}
                required={true}
                id="l-name__addadminuser"
                placeholder="Enter Admin Last Name"
                value={lName}
                error={lNameError}
                errorMessage="Last Name is required"
                inputHandler={(e) => setlName(e.target.value)}
              />

              <TextInput
                label="Email"
                showLabel={true}
                required={true}
                emailField={true}
                id="email-input__addadminuser"
                placeholder="Enter Admin Email"
                value={email}
                error={emailError}
                errorMessage="Enter valid Email"
                inputHandler={(e) => {
                  if (
                    e.target.value.length < 0 &&
                    !validateEmail(e.target.value)
                  ) {
                    setEmailError(true);
                  } else {
                    setEmailError(false);
                    setEmail(e.target.value);
                  }
                }}
              />

              <PgMultiSelect
                label="Assigned Regions"
                showLabel={true}
                required={true}
                id="country__addpractitioner"
                placeholder="Select regions"
                setSelectedItem={setCountries}
                existingSelections={countries}
                errorMessage="Enter at least one region"
                options={["Global", "US", "UK/EU", "CA"]}
                error={countries.length != 0 ? "" : countriesError}
              />

              <button
                className="btn btn-primary text-light px-5 my-1 mx-1  w-100"
                onClick={onDone}
              >
                UPDATE
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default EditAdminModal;
