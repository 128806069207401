import React, { Component, useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { connect, useSelector, useDispatch } from "react-redux";
import toastr from "react-hot-toast";
import _ from "lodash";

import PgSelect from "../../../components/PgSelect";
import TextInput from "../../../components/TextInput";
import { validateEmail } from "../../../methods";
import {
  addStaff,
  getStaffPermissions,
} from "../../../store/actions/adminStaff";
import { getCountries } from "../../../store/actions/adminUser";
import PgCheckbox from "../../../components/PgCheckbox";
import PgSelectSearch from "../../../components/PgSelectSearch";
import { getPractitionersList } from "../../../store/actions/adminPractitioner";
import { licenseNotMandatoryTypes, licenseNotNeededTypes, stateList, caStateList } from "../../../constant";

function AddStaff({
  token,
  showModal,
  onCancel,
  addStaffAction,
  updatePatientTable,
  getPractitionerTypesAction,
  practitionersTypeList,
  context,
  selectedRegion
}) {

  const regions = useSelector(state => state.auth.region)
  const dispatch = useDispatch();
  const [countryList, setCountryList] = useState([]);

  const [isValidForm, setIsValidForm] = useState(false);
  const [showState, setShowState] = useState(true);
  //form fields
  const [fName, setfName] = useState("");
  const [lName, setlName] = useState("");
  const [email, setEmail] = useState("");
  const [showHealthcareProviderType, setShowHealthcareProviderType] = useState(true);
  const [healthcareProviderType, setHealthcareProviderType] = useState("");
  const [otherHealthcareProviderType, setOtherHealthcareProviderType] =
    useState("");
  const [license, setLicense] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [userState, setUserState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [phone, setPhone] = useState("");
  const [fax, setFax] = useState("");
  const [enableECommerce, setEnableECommerce] = useState(false);
  const [practiceName, setPracticeName] = useState("");
  const [pureAccountNumber, setPureAccountNumber] = useState("")
  const [accountType, setAccountType] = useState("");
  const [showPractitioner, setShowPractitioner] = useState(false);
  const [practitionersList, setPractitionersList] = useState([]);

  //for validation
  const [fNameError, setfNameError] = useState(false);
  const [lNameError, setlNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [healthcareProviderTypeError, setHealthcareProviderTypeError] =
    useState("");
  const [
    otherHealthcareProviderTypeError,
    setOtherHealthcareProviderTypeError,
  ] = useState("");
  const [licenseError, setLicenseError] = useState(false);
  const [address1Error, setAddress1Error] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [zipcodeError, setZipcodeError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [disableLicense, setDisableLicense] = useState(false);
  const [requireLicense, setRequireLicense] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accountTypeError, setAccountTypeError] = useState('');
  const [practitionerName, setPractitionerName] = useState("");

  const [practititionerTypesList, setPractititionerTypesList] = useState([]);
  const [usPractititionerTypesList, setUsPractititionerTypesList] = useState([]);
  const [ukPractititionerTypesList, setUkPractititionerTypesList] = useState([]);
  const [caPractititionerTypesList, setCaPractititionerTypesList] = useState([]);
  const [practitionerTypeId, setPractitionerTypeId] = useState("");
  const [selectedPractitioner, setselectedPractitioner] = useState({});
  const [practitionerNameError, setPractitionerNameError] = useState(false);
  const [pactitionersNameList, setPactitionersNameList] = useState([]);

  const [optionalPermissions, setOptionalPermissions] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  useEffect(() => {
    dispatch(getCountries(token)).then(response => {
      if (!response.error) {
        var availableCountries = response.payload?.countries
        if ((regions.includes("UK/EU")) || (context === "practitioner-list" && selectedRegion !== "US" && selectedRegion !== "CA")) {
          setCountry("");
          _.remove(availableCountries, function (n) {
            return n === "United States";
          });
          _.remove(availableCountries, function (n) {
            return n === "Canada";
          });
        } else if ((regions.includes("CA")) || (context === "practitioner-list" && selectedRegion === "CA")){
          setCountry("");
          setCountry("Canada");
        }
        else if (context !== "practitioner-list") {          
          setCountry("");
          if (regions.length > 1) {
            setShowState(false);
          } else {
            const region = regions[0];
            if (region === "US") {
              setCountry("");
              setCountry("United States");
            } else if (region === "CA") {
              setCountry("");
              setCountry("Canada");
            }
          }
        }
        else {
          setCountry("United States")
        }
        setCountryList(availableCountries);
      }
    });
  }, []);

  useEffect(() => {
    if (country !== "" || context !== "dashboard" || (regions.length === 1 && regions[0] !== "Global"))
      setShowPractitioner(true)
  }, [country]);

  useEffect(() => {
    var selectedCountry;    
    if (country === "" && context === "practitioner-list" && selectedRegion !== "US" && selectedRegion !== "CA") {
      selectedCountry = "United Kingdom"      
    }
    else if (context === "dashboard" && (regions.length === 1 && regions[0] !== "Global")) {
      selectedCountry = (regions[0] === "US") ? "United States" : (regions[0] === "CA") ? "Canada" : "United Kingdom"      
    }
    else {
      selectedCountry = country      
    }    
    dispatch(getPractitionersList(token, selectedCountry)).then((response) => {
      if (!response.error) {
        if (context === "dashboard" && country === "" && (regions.length !== 1 || regions[0] === "Global")) {
          setPactitionersNameList([])
        }
        else {          
          setPractitionersList(response.payload.practitioners)
        }
      }
    });
  }, [country]);

  useEffect(() => {    
    if (country !== "") {
      setUserState("")
      setShowState(true)
      if (context !== "practitioner-list") {
        setHealthcareProviderType("")
        setOtherHealthcareProviderType("")
      }
      if (country === "United States") {
        setPractititionerTypesList(usPractititionerTypesList)
      } else if(country === "Canada"){
        setPractititionerTypesList(caPractititionerTypesList)
      } else {
        setPractititionerTypesList(ukPractititionerTypesList)
      }
      setShowHealthcareProviderType(true)
    }
  }, [country]);

  useEffect(() => {
    dispatch(getStaffPermissions(token)).then(response => {
      console.log("response.payload ---->", response.payload);
      if (!response.error) {
        setOptionalPermissions(response.payload?.optional_permissions);
      }
    });
  }, []);

  const onDone = () => {
    setfNameError(false);
    setlNameError(false);
    setEmailError(false);
    setHealthcareProviderTypeError(false);
    setLicenseError(false);
    setAddress1Error(false);
    setCityError(false);
    setStateError(false);
    setZipcodeError(false);
    setPhoneError(false);
    setCountryError(false)
    setOtherHealthcareProviderTypeError(false)
    setAccountTypeError(false);

    if (email.length === 0 || !validateEmail(email)) setEmailError(true);
    if (fName.length === 0) setfNameError(true);
    if (lName.length === 0) setlNameError(true);
    if (!selectedPractitioner?.id)
      setPractitionerNameError("Practitioner Name is not available");
    if (country.length === 0) setCountryError("Country is Required");
    if (userState.length === 0 && (regions.includes("US") || selectedRegion === "US" || country === "United States" )) setStateError("State is Required");
    if (userState.length === 0 && (regions.includes("CA") || selectedRegion === "CA" || country === "Canada" )) setStateError("Province/Territory is Required");
    if (phone.length === 0) setPhoneError(true);
    if (
      email.length !== 0 &&
      validateEmail(email) &&
      fName.length !== 0 &&
      lName.length !== 0 &&
      selectedPractitioner?.id &&
      country.length !== 0 &&
      (userState.length !== 0 || !(regions.includes("US") || selectedRegion === "US" || country === "United States" || regions.includes("CA") || selectedRegion === "CA" || country === "Canada")) &&
      phone.length !== 0
    ) {
      setIsValidForm(true);
    }
  };

  useEffect(() => {
    if (isValidForm) {
      handleDone();
    }
  }, [isValidForm]);

  const handleDone = async () => {
    let data = {
      staff: {
        first_name: _.startCase(fName),
        last_name: lName,
        full_name: `${_.startCase(fName)} ${lName}`,
        email: email.toLowerCase(),
        country: country,
        state: userState,
        phone_number: phone,
        practitioner_type_id: practitionerTypeId,
        staff_type: "Real",
        practice_id: selectedPractitioner?.id,
        access_roles: selectedPermissions
      },
      rialto: {
        pure_account_number: pureAccountNumber
      }
    };
    setLoading(true);
    const response = await addStaffAction(token, data);
    setLoading(false);
    if (response.error) {
      //toastr.error("Email already exists in system. Please choose another");
      setIsValidForm(false);
    } else {
      if (context === "practitioner-list") updatePatientTable();
      onCancel();
      toastr.success("New Staff Member Created");
    }
  };

  const getPractitionerTypeId = (type) => {
    const practitionerType = _.find(practitionersTypeList, [
      "practitioner_type",
      type,
    ]);
    setPractitionerTypeId(practitionerType.id);
  };

  const updateProviderType = (option) => {
    getPractitionerTypeId(option);
    setHealthcareProviderType(option);
    if (licenseNotNeededTypes.includes(option)) {
      setRequireLicense(false);
      setDisableLicense(true);
    } else if (licenseNotMandatoryTypes.includes(option)) {
      setRequireLicense(false);
      setDisableLicense(false);
    } else {
      setRequireLicense(true);
      setDisableLicense(false);
    }
  };

  const updateAccountType = (option) => {
    setAccountType(option);
  };

  const onPractitionerSelect = (pract) => {
    // setPractitionerId(pract?.id ?? "");
    // setPractitionerName(pract?.full_name ?? "");
    setselectedPractitioner(pract);
  };

  const loadPermissions = (value) => {
    setSelectedPermissions((selectedValue) => {
      let oldSelectedValue = [...selectedValue]
      if (oldSelectedValue.includes(value)) {
        return oldSelectedValue.filter((singleSelectedValue) => singleSelectedValue !== value)
      } else {
        return [...oldSelectedValue, value]
      }
    })
  }

  function OptionalPermission() {
    return (
      optionalPermissions.map((value, index) => {
        return (
          <PgCheckbox
            name="optional-permission"
            id={index}
            key={index}
            checked={selectedPermissions.includes(value)}
            onCheck={() => loadPermissions(value)}
            title={value}
          />
        )
      })
    )
  }

  return (
    <div>
      <Modal
        show={showModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={onCancel}
        contentClassName="border-radius"
        backdrop="static"
        className="add-practitioner-modal"
      >
        <Modal.Body>
          <div
            style={{
              position: "absolute",
              right: 25,
              top: 15,
              fontSize: 20,
              cursor: "pointer",
              color: "var(--pg_primary)",
            }}
            onClick={onCancel}
          >
            <div className="fas fa-times"></div>
          </div>
          <div className="container">
            <div className="text-center pg-title">Add Staff Member</div>
            <div className="row">
              <div className="col-12">
                <TextInput
                  label="First Name"
                  showLabel={true}
                  required={true}
                  id="f-name__addpractitioner"
                  placeholder="Enter Staff First Name"
                  value={fName}
                  error={fNameError}
                  type="text"
                  errorMessage="First Name is required"
                  inputHandler={(e) => {
                    setfName(e.target.value);
                  }}
                />

                <TextInput
                  label="Last Name"
                  showLabel={true}
                  required={true}
                  id="l-name__addpractitioner"
                  placeholder="Enter Staff Last Name"
                  value={lName}
                  error={lNameError}
                  errorMessage="Last Name is required"
                  inputHandler={(e) => {
                    setlName(e.target.value);
                  }}
                />

                <TextInput
                  label="Email"
                  showLabel={true}
                  required={true}
                  emailField={true}
                  id="email-input__addpractitioner"
                  placeholder="Enter Staff Email"
                  value={email}
                  error={emailError}
                  errorMessage="Enter valid Email"
                  inputHandler={(e) => {
                    if (
                      e.target.value.length < 0 &&
                      !validateEmail(e.target.value)
                    ) {
                      setEmailError(true);
                    } else {
                      setEmailError(false);
                      setEmail(e.target.value);
                    }
                  }}
                />
                {
                  (regions === "US" || selectedRegion === "US" || regions === "CA" || selectedRegion === "CA") ?
                    <TextInput
                      label="Country"
                      showLabel={true}
                      id="l-country__addpractitioner"
                      placeholder="Enter Country"
                      value={selectedRegion === "US" ? "United States" : "Canada"}
                      isDisable={true}
                      error={countryError}
                      inputHandler={(e) => {
                        setCountry(e.target.value);
                      }}
                    />
                    :
                    <PgSelect
                      label="Country"
                      showLabel={true}
                      required={true}
                      optional={false}
                      id="country__addpatient"
                      setSelectedItem={setCountry}
                      selectedItem={country}
                      placeholder="Enter Staff Country"
                      options={countryList}
                      error={countryError}
                    />
                }
                {showState ?
                  ((regions.includes("US") || selectedRegion === "US" || country === "United States" || regions.includes("CA") || selectedRegion === "CA" || country === "Canada") ?
                    <PgSelect
                      label={country === "Canada" ? "Province/Territory" : "State"}
                      showLabel={true}
                      required={true}
                      id="state__addpractitioner"
                      setSelectedItem={setUserState}
                      selectedItem={userState}
                      placeholder={country === "Canada" ? "Select Province/Territory" : "Enter State"}
                      options={country === "Canada" ? caStateList : stateList}
                      error={userState !== "" ? "" : stateError}
                    />
                    :
                    <TextInput
                      label="County"
                      showLabel={true}
                      optional={true}
                      id="state__addpractitioner"
                      placeholder="Enter County"
                      value={userState}
                      inputHandler={(e) => {
                        setUserState(e.target.value);
                      }}
                    />)
                  :
                  ""
                }

                {showPractitioner && <PgSelectSearch
                  label="Practitioner"
                  showLabel={true}
                  required={true}
                  optional={false}
                  errorMessage={practitionerNameError}
                  id="practitioner-name__addpatient"
                  onSelect={onPractitionerSelect}
                  selectedItem={selectedPractitioner}
                  placeholder="Enter Practitioner"
                  options={practitionersList}
                  objectKey="full_name"
                  objectKey2="email"
                  error={practitionerName !== "" ? "" : practitionerNameError}
                />}

                <TextInput
                  label="Phone Number"
                  showLabel={true}
                  required={true}
                  id="phone__addpractitioner"
                  placeholder="Enter Phone Number"
                  error={phoneError}
                  errorMessage="Phone Number is Required"
                  value={phone}
                  inputHandler={(e) => {
                    setPhone(e.target.value);
                  }}
                />
                <div className="d-inline-block pg-text__bold textc-primary">Optional Permissions</div>
                <OptionalPermission />
                <button
                  className="btn btn-primary text-light px-5 my-1 mx-1  w-100"
                  onClick={onDone}
                >
                  SAVE
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    practitionersTypeList: state.adminPractitioner.practitioner_types,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    addStaffAction: (token, data) =>
      dispatch(addStaff(token, data)),
    // getPractitionerTypesAction: (token) =>
    //   dispatch(getPractitionerTypes(token)),
  };
};

export default connect(mapStateToProps, mapDispatchtoProps)(AddStaff);
