import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useSelector, useDispatch } from 'react-redux';
import { getAdminMadeRequestStatus, setAdminMadeRequestStatus } from '../../store/actions/practiceDashboard';

function GDPRPracticeStatus() {
    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.token);
    const currentPractice = useSelector((state) => state.auth.currentPractice);
    const currentPracticeID = useSelector((state) => state.auth.currentPracticeID);
    const lang = useSelector((state) => state.auth.language);
    const getAdMadeRequestStatus = () => dispatch(getAdminMadeRequestStatus(token, currentPracticeID, currentPractice,))
    const setAdMadeRequestStatus = (pld, accept) => dispatch(setAdminMadeRequestStatus(token, currentPracticeID, currentPractice, pld, accept))

    const [showAdminEraseData, setShowAdminEraseData] = useState(false)
    const [adminEraseDataId, setAdminEraseDataId] = useState("")
    const [showAdminInactivateAccount, setAdminShowInactivateAccount] = useState(false)
    const [adminInactivateAccountId, setAdminInactivateAccountId] = useState("")

    const gdprRequestAPI = (requestType, isApprove, id) => {
      setAdMadeRequestStatus({ request_type: requestType, id: id }, isApprove).then(response => {
          if (!response.error) {
            if(isApprove){
              toast.success('Request approved successfully.')
            }
            else{
              toast.success('Request rejected successfully.')
            }
          }
      })
    }

    useEffect(() => {
      if (lang === "en_gb"){
        
        getAdMadeRequestStatus().then(response => {
          if (!response.error && !_.isEmpty(response.payload)) {
            var adminEraseData = _.filter(response.payload.gdpr_list, ['request_type', "Erase Data"])
            if(adminEraseData.length > 0) {
              setShowAdminEraseData(true)
              setAdminEraseDataId(adminEraseData[0].id)
            }
            var adminInactivate = _.filter(response.payload.gdpr_list, ['request_type', "Inactivate Account"])
            if(adminInactivate.length > 0) {
              setAdminShowInactivateAccount(true)
              setAdminInactivateAccountId(adminInactivate[0].id)
            }
          }
        })
      }      
    }, []);

    return (
        <div>
            {showAdminEraseData && <ActionPrompt
                show={showAdminEraseData}
                rejectLabel="Cancel"
                message={`A PureGenomics administrator has requested that your account be deleted from the PureGenomics system on your behalf. We recommend that you download your report if you wish to retain a copy of the PureGenomics record. Navigate to your report and from the top right of the page click on View and Download Report. You will have 30 days to download the report, after which your account and all of the data will be deleted. This action is not reversible. To approve this action click confirm or contact PureGenomics support if you believe this is an error.`}
                onReject={() => {
                  gdprRequestAPI("delete_data", false, adminEraseDataId);
                  setShowAdminEraseData(false);
                }}
                onAccept={() => { 
                  gdprRequestAPI("delete_data", true, adminEraseDataId)
                  setShowAdminEraseData(false);
                }}
            />}
            {showAdminInactivateAccount && <ActionPrompt
                show={showAdminInactivateAccount}
                rejectLabel="Cancel"
                message={`A PureGenomics administrator has requested to inactivate your practitioner (the data controller) PureGenomics account on your behalf. To approve this action click confirm. If you believe that this is an error, please contact PureGenomics support.`}
                onReject={() => { 
                  gdprRequestAPI("inactivate_account", false, adminInactivateAccountId);
                  setAdminShowInactivateAccount(false);
                }}
                onAccept={() => {
                  gdprRequestAPI("inactivate_account", true, adminInactivateAccountId)
                  setAdminShowInactivateAccount(false);
                }}
            />}
        </div>
    )
}

export default GDPRPracticeStatus

export const ActionPrompt = ({
    show, onAccept, onReject,
    message,
    acceptLabel = 'Confirm',
    rejectLabel = 'Go Back',
    persistant = 'static'
}) => {
    return (
        <Modal
            centered
            backdrop={persistant}
            show={show}
            onHide={onReject} bsPrefix="border-radius modal" contentClassName="border-radius" style={{ borderRadius: 8 }}>
            <div className="p-3">
                <div className="pg-text__bold">{message}</div>
                <div className="d-flex justify-content-end mt-4">
                    <button className="btn bg-sky-blue px-4 mx-1" onClick={onReject}>{rejectLabel}</button>
                    <button className="btn bgc-primary text-light px-5" onClick={onAccept}>{acceptLabel}</button>
                </div>
            </div>
        </Modal>)
}
