import React, { Component, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { Modal } from "react-bootstrap";
import { connect, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import { arrayDisplay } from "../../../methods";
import {
  getWelcomeQuestion,
} from "../../../store/actions/questions";
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
import { testAnswer } from "../../../constant";

function AnswersModal({
  patientId,
  showModal,
  onCancel,
  isTestPatient
}) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [patientAnswers, setPatientAnswers] = useState([]);
  const [conversationItems, setConversationItems] = useState([]);
  const [patientAnswer, setPatientAnswer] = useState("");
  const language = useSelector((state) => state.auth.language)

  useEffect(() => {
    if (isTestPatient) {
      const sessionData = (language === "en") ? testAnswer.session_us : (language === "ca" || language === "fr") ? testAnswer.session_ca : testAnswer.session_uk
      const sessionAnswers = JSON.parse(sessionData).questionnaire
      const newanswers = _.filter(sessionAnswers, function (o) {
        return parseInt(o.id) > 1.2;
      });
      setPatientAnswers(newanswers);
    }
    else {
      dispatch(getWelcomeQuestion(patientId)).then((res) => {
        if (res.error) {
          toast.error(t('pracpl_am_line_2'),
            {
              style: {
                fontSize: '16px',
              },
              duration: 15000
            })
          onCancel();
        }
        else {
          const sessionAnswers = JSON.parse(res.payload.session).questionnaire
          const newanswers = _.filter(sessionAnswers, function (o) {
            return parseInt(o.id) > 1.2;
          });
          setPatientAnswers(newanswers);
        }
      });
    }
  }, []);

  return (
    <Modal
      show={showModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={onCancel}
      contentClassName="border-radius"
      backdrop="static"
      className="add-practitioner-modal answers-modal"
    >
      <Modal.Body>
        <div
          style={{
            position: 'absolute',
            right: 25,
            top: 15,
            fontSize: 20,
            cursor: "pointer",
            color: 'var(--pg_primary)'
          }}
          onClick={onCancel}
        >
          <div className="fas fa-times"></div>
        </div>
        <div>
          <div className="text-center pg-title">
            {t('pracpl_am_line_1')}
          </div>
          {true && (
            <div>
              <div className="review-answers-wrapper">
                <div className="container-fluid">
                  {
                    <div>
                      {patientAnswers.map((item, index) => {
                        return (
                          <div className="row" index={index}>
                            <hr />
                            <div className="col-lg-9 col-12 pg-text__bold mb-3" dangerouslySetInnerHTML={{ __html: item.question_text }}
                            >
                            </div>
                            <div className="col-lg-3 col-12">
                              {arrayDisplay(item.answer_text)}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  }
                </div>
              </div>
            </div>
          )}
        </div>

      </Modal.Body>
    </Modal>
  );
}
export default AnswersModal;
