import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { getSupplementSuggestions } from "../../store/actions/patientReport";
import { useTranslation } from "react-i18next";
import _ from "lodash";

const SupplementSuggestion = ({patientId,reportId}) => {
  const { t, i18n } = useTranslation();
const navigate = useNavigate();

  // redux state variable
  const currentPracticeID = useSelector(state => state.auth.currentPracticeID);
  const currentPractice = useSelector(state => state.auth.currentPractice);
  const token = useSelector(state => state.auth.token);
  const appToken = useSelector(state => state.auth.appToken);
  const userType = useSelector(state => state.auth.userType);
  const email = useSelector(state => state.auth.email);
  const firstName = useSelector(state => state.auth.firstName);
  const lastName = useSelector(state => state.auth.lastName);
  const phoneNumber = useSelector(state => state.auth.phoneNumber);
  const currentUserId = useSelector(state => state.auth.currentUserId);
  const patientInfo = useSelector(state => state.patientReport.patientDetail);
  const supplementSuggestions = useSelector(state => state.patientReport.supplementSuggestions);

  const dispatch= useDispatch();

  // redux actions
  const getSupplementSuggestionsAction = (token,currentPracticeID,currentPractice,patient_id,snp_id) => dispatch(getSupplementSuggestions(token,currentPracticeID,currentPractice,patient_id,snp_id));

  const nagivateToSupplements = (e) => {
    const state = {
      path: `/tenants/${currentPracticeID}/patients/${patientId}/rialto_service`,
      reportId: reportId,
      browseCatalog: false,
    };
    navigate("/practice/supplement_catalog", { state });
  };

  const navigateToViewRecommededScript = (supplementSuggestions, userType) => {
    console.log(userType, "userTypeuserType");
    let path =
      userType == "Patient" &&
        supplementSuggestions.script_placed_status !== true
        ? "/supplement_suggestions"
        : "/view_recommended_script";

    const state = {
      path: path,
      reportId: reportId,
      browseCatalog: false,
      scriptId: supplementSuggestions.script_id,
      userType: userType,
    };
    userType == "Patient"
      ? navigate("/patient/supplement_catalog", { state })
      : navigate("/practice/supplement_catalog", { state });
  };

  useEffect(() => {
    getSupplementSuggestionsAction(token, currentPracticeID, currentPractice, {
      reportId,
      userType,
    });
  }, []);

  return (
    <>
      {!_.isEmpty(supplementSuggestions?.supplements) && supplementSuggestions?.supplements?.length > 0 && <div className="my-5">
        {(userType === "User" ||
          (userType === "Patient" && supplementSuggestions.script_available)) && (
            <div className="pg-text">
              <div className="pg-title py-3">
                {t('supplement')}{" "}
                {supplementSuggestions.script_available
                  ? t("recommendations")
                  : t("suggestions")}
              </div>
              <div className="py-1">
                {(supplementSuggestions.script_available && userType == "User")
                  ? t('supplement_line_1')
                  : (!supplementSuggestions.script_available && userType == "User")
                    ? t('supplement_line_2')
                    : t('supplement_line_3')}
              </div>
              <div className="row justify-content-center my-4">
                {supplementSuggestions?.supplements?.map((supplement) => (
                  <div className="col-11 col-md-5" key={supplement.title}>
                    <hr className="my-4" />
                    <div
                      className="pg-text__bold"
                      dangerouslySetInnerHTML={{ __html: supplement.title }}
                    />
                    <div dangerouslySetInnerHTML={{ __html: supplement.desc }} />
                  </div>
                ))}
                <span className="supplement_notes my-2">
                  {t('supplement_line_4')}
                </span>
              </div>
              <div className="text-center my-5">
                {(userType == "User" && supplementSuggestions.script_available) && (
                  <div>
                    {userType !== "User" &&
                      <p className="pg-note">{t('supplement_line_5')}</p>
                    }
                    {userType === "User" &&
                      <p className="pg-note">{t('supplement_line_6')}</p>
                    }
                    <button
                      className="btn btn-primary text-light"
                      onClick={() =>
                        navigateToViewRecommededScript(
                          supplementSuggestions,
                          userType
                        )
                      }
                    >
                      {userType == "User" ? t('supplement_btn_2') : t('supplement_btn_3')}
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
      </div>}
    </>
  );
};

export default SupplementSuggestion;
