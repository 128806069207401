import React, { useState } from "react";

export default function PasswordInput({
  inputHandler,
  placeholder,
  error,
  errorMessage,
  id,
  required,
  label,
  value,
  slim = false,
  showLabel = false,
}) {
  const [showPassword, setshowPassword] = useState(false);
  const style = {
    inputContainer: {
      width: "100%",
      border: error ? "2px solid #f00" : "3px solid #acd4ff",
      borderRadius: 4,
      overflow: "hidden",
      height: 50,
      transition: "all .3s ease-in-out",
    },
    input: {
      flexGrow: 1,
      padding: 15,
      outline: "none",
      border: "unset",
    },
    icon: {
      background: "transparent",
      padding: "13px 5px 15px 0",
      textAlign: "center",
      width: "12%",
      height: "100%",
    },
  };

  return (
    <div className={`my-${slim ? "2" : "4"}`}>
      {showLabel && (
        <div>
          <div className="d-inline-block pg-text__bold textc-primary">
            {label}
          </div>
          {required && (
            <span className="textc-primary fst-italic"> (Required)</span>
          )}
        </div>
      )}
      <div>
        <div className="w-90 d-flex" style={style.inputContainer}>
          <input
            placeholder={placeholder}
            type={showPassword ? "text" : "password"}
            onInput={inputHandler}
            style={style.input}
            id={id}
            value={value}
          />
          <div
            style={style.icon}
            className="cursor-pointer transition-all"
            onClick={() => setshowPassword(!showPassword)}
          >
            <i
              className={showPassword ? "fas fa-eye" : "fas fa-eye-slash"}
              style={{ color: "#000", fontSize: 16 }}
            ></i>
            {/* <img src={showPassword ? v_on : v_off} alt="Eye Icon"/> */}
          </div>
        </div>
        <div
          className="text-danger mx-2 my-1 transition-all"
          style={error ? { opacity: 1 } : { opacity: 0 }}
        >
          <i className="fas fa-info-circle"></i> {errorMessage}
        </div>
      </div>
    </div>
  );
}
