import React, { useState } from "react";
import { useSelector } from 'react-redux';
import _ from "lodash";
import { GeneAnalysisList } from "../../components/Report/GeneAnalysis/GeneAnalysisList";
// import TextInputWithIcon from "../../components/TextInputWithIcon";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";


function GeneAnalysisSummary({ history }) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  // redux state variable
  const patientInfo = useSelector(state => state.patientReport.patientDetail);
  const geneAnalysisList = useSelector(state => state.patientReport.traitCategories);

  const [expandAll, setExpandAll] = useState(false);

  return (
    <div className="container-fluid">
      <div className="container-xxl px-lg-0 px-0 my-4">
        <div
          className="pg-link"
          onClick={() =>
            navigate(`/practice/patient/report`)
          }
        >
          <i className="fas fa-chevron-left"></i> {t('pracrs_title')}
        </div>
        <div className="pg-title my-3 mt-5">{t('pracrs_line_6')}</div>
        <div className="pg-heading my-4">{patientInfo?.patient?.full_name}</div>
        <hr />
        <div className="">
          <div className="row justify-content-between">
            <div className="offset-3 col-6">
              {/* <TextInputWithIcon
                placeholder="Search gene analysis summary"
                Icon={
                  <i className="fa fa-search cursor-pointer text-secondary"></i>
                }
              /> */}
            </div>
            <div className="col-2  my-auto text-end">
              <div
                className="pg-link transition-all"
                onClick={() => setExpandAll(!expandAll)}
              >
                {expandAll ? "Collapse all" : "Expand all"}
                <i
                  className="fa fa-chevron-down ms-2 transition-all"
                  style={{
                    transform: expandAll ? "rotate(-180deg)" : "rotate(0deg)",
                  }}
                ></i>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <GeneAnalysisList traits={geneAnalysisList} expandAll={expandAll} />
      </div>
    </div>
  );
}
export default GeneAnalysisSummary;