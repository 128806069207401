import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import toastr from "react-hot-toast";
import PasswordInput from "../../PasswordInput ";
import { useTranslation } from "react-i18next";

function StaffBatchFnModal({
  practitioner,
  onCancel,
  showModal,
  children,
  onYes,
}) {
  const { t } = useTranslation();
  return (
    <div>
      <Modal
        show={showModal}
        size="lg"
        centered
        aria-labelledby="contained-modal-title-vcenter"
        onHide={onCancel}
        contentClassName="border-radius"
        backdrop="static"
        className="add-practitioner-modal"
      >
        <Modal.Body className="container">
          <div style={style.closeButton} onClick={onCancel}>
            <div className="fas fa-times"></div>
          </div>
          {children}
          <div className="d-flex justify-content-end">
            <div>
              <button className="btn btn-outline mx-2" onClick={onCancel}>
                {t("no")}
              </button>
              <button className="btn btn-primary" onClick={onYes}>
                {t("yes")}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default StaffBatchFnModal;

const style = {
  closeButton: {
    position: "absolute",
    right: 14,
    top: 10,
    fontSize: 20,
    cursor: "pointer",
  },
};
