import React from "react";
import VideoIcon from "../../../assets/videoIcon.png";

const TraitOverview = ({ data, video_url, userType, t }) => {
  const patientTextColor = { color: userType === "Patient" ? "var(--pg_primary__p)" : 'var(--pg_primary)' }
  return (
    <div className="">
      <hr className="my-5" />
      <div className="my-5">
        <div className="pg-heading my-2" style={patientTextColor}>{t('lifestyle_line_10')}</div>
        <div className="pg-text my-2">{data}</div>
        {
          video_url
          ? <div className="pg-link" onClick={() => video_url && window.open(video_url)} style={patientTextColor}>
              {t('trait_line_1')}
              <img src={VideoIcon} alt="" />
            </div>
          : ""
        }
      </div>
    </div>
  );
};


export default TraitOverview;
