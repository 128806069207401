import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import toastr from 'react-hot-toast'
import _ from 'lodash'

import ActionButtons from '../ActionButtons'
import PractitionerBatchFnModal from '../Practitioner/PractitionerBatchFnModal'
import { resetLockout, resetPassword, archivePatients, restorePatients, restorePatientsReport, archivePatientsReport, getPatient, editPatient, sendGeneticUploadReminder, sendRegistrationReminder } from '../../../store/actions/adminPatient'
import { toggleLoading } from '../../../store/actions/ui'
import EditPatientModal from './EditPatientModal'
import { isAllArrayElementHas, isAnyArrayElementHas } from '../../../methods'
const baseUrl = process.env.REACT_APP_PG3_APP_DOMAIN;

function PatientBatchFn({
    selectedPatients,
    patients,
    updatePatientTable,
    setselectedPatientList }) {
    const dispatch = useDispatch();
    const token = useSelector(state => state.auth.token);
    const [showEditModal, setShowEditModal] = useState(false)
    const [showResetModal, setShowResetModal] = useState(false);
    const [showLockoutModal, setShowLockoutModal] = useState(false)
    const [showArchiveModal, setShowArchiveModal] = useState(false)
    const [showRestoreModal, setShowRestoreModal] = useState(false)
    const [showReportArchiveModal, setShowReportArchiveModal] = useState(false)
    const [showReportRestoreModal, setShowReportRestoreModal] = useState(false)
    const [showGeneticUploadReminderModal, setShowGeneticUploadReminderModal] = useState(false)
    const [showRegistrationReminderModal, setShowRegistrationReminderModal] = useState(false)


    const onDownload = () => {
        fetch(
            `${baseUrl}/admin/patient/download_csv.csv`,
            {
                headers: {
                    "Content-Type": "application/csv",
                    Authorization: token,
                    "Current-Type": "AdminUser",
                }
            }
        ).then(response => response.blob())
            .then(text => {
                // console.dir(text)
                var url = window.URL.createObjectURL(new Blob([text]));
                var a = document.createElement('a');
                a.href = url;
                a.download = `${new Date().getTime()}.csv`;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();
            });
    }


    const onRestore = () => {
        if (isAllArrayElementHas(patients, selectedPatients, 'aasm_state', 'archived')) {
            dispatch(toggleLoading(true));
            dispatch(restorePatients(token, selectedPatients,)).then(response => {
                dispatch(toggleLoading(false));
                if (!response.error) {
                    setselectedPatientList([])
                    updatePatientTable()
                    toastr.success(response.payload.message);
                }
                setShowRestoreModal(false)
            });
        } else {
            toastr.error("Selecte only the archived patients.");
            setShowRestoreModal(false)
        }


    }

    const onArchive = () => {
        if (isAnyArrayElementHas(patients, selectedPatients, 'aasm_state', 'archived')) {
            dispatch(toggleLoading(true));
            dispatch(archivePatients(token, selectedPatients)).then(response => {
                dispatch(toggleLoading(false));
                if (!response.error) {
                    setselectedPatientList([])
                    updatePatientTable()
                    toastr.success(response.payload.message);;
                }
                setShowArchiveModal(false)
            });
        }
        else {
            setShowArchiveModal(false)
            toastr.error("Select only active patients");
        }
    }

    const onLockout = () => {
        dispatch(toggleLoading(true));
        dispatch(resetLockout(token, selectedPatients)).then(response => {
            dispatch(toggleLoading(false));
            if (!response.error) {
                setselectedPatientList([])
                updatePatientTable()
                toastr.success(response.payload.message);
            }
            setShowLockoutModal(false)
        });;
    }

    const onResetPassword = () => {
        dispatch(toggleLoading(true));
        dispatch(resetPassword(token, selectedPatients)).then(response => {
            dispatch(toggleLoading(false));
            if (!response.error) {
                setselectedPatientList([])
                toastr.success(response.payload.message);
            }
            setShowResetModal(false)
        });
    }

    const onReportArhive = () => {
        if (isAnyArrayElementHas(patients, selectedPatients, 'report_status', 'archived')) {
            dispatch(toggleLoading(true));
            dispatch(archivePatientsReport(token, selectedPatients)).then(response => {
                dispatch(toggleLoading(false));
                if (!response.error) {
                    setselectedPatientList([]);
                    updatePatientTable();
                    toastr.success(response.payload.message);
                }
                setShowReportArchiveModal(false)
            });
        } else {
            setShowReportArchiveModal(false)
            toastr.error("Select only active reports")
        }
    }

    const onReportRestore = () => {
        if (isAllArrayElementHas(patients, selectedPatients, 'report_status', 'archived')) {
            dispatch(toggleLoading(true));
            dispatch(restorePatientsReport(token, selectedPatients,)).then(response => {
                dispatch(toggleLoading(false));
                if (!response.error) {
                    setselectedPatientList([]);
                    updatePatientTable();
                    toastr.success(response.payload.message)
                }
                setShowReportRestoreModal(false)
            });
        } else {
            toastr.error("Select only archived reports")
            setShowReportRestoreModal(false)
        }
    }

    const onSendGeneticUploadReminder = () => {
        if (isAllArrayElementHas(patients, selectedPatients, 'report_status', 'patient no data')) {
            dispatch(toggleLoading(true));
            dispatch(sendGeneticUploadReminder(token, selectedPatients,)).then(response => {
                dispatch(toggleLoading(false));
                if (!response.error) {
                    setselectedPatientList([]);
                    updatePatientTable();
                    toastr.success(response.payload.message)
                }
                setShowGeneticUploadReminderModal(false)
            });
        } else {
            toastr.error("Select only patients who can upload genetic file and yet to upload")
            setShowGeneticUploadReminderModal(false)
        }
    }

    const onRegistrationReminder = () => {
        if (isAllArrayElementHas(patients, selectedPatients, 'aasm_state', 'pending')) {
            dispatch(toggleLoading(true));
            dispatch(sendRegistrationReminder(token, selectedPatients,)).then(response => {
                dispatch(toggleLoading(false));
                if (!response.error) {
                    setselectedPatientList([])
                    updatePatientTable();
                    toastr.success(response.payload.message);

                }
                setShowRegistrationReminderModal(false)
            });
        } else {
            toastr.error("Select only pending patients")
            setShowRegistrationReminderModal(false)
        }
    }



    return (
        <div className="my-2 mt-4">
            {showEditModal && <EditPatientModal
                showModal={showEditModal}
                onCancel={() => setShowEditModal(false)}
                // updatePractitionerTable={updatePractitionerTable}
                updatePatientTable={updatePatientTable}
                patientId={selectedPatients[0]}
            />}
            {showResetModal && <PractitionerBatchFnModal onCancel={() => setShowResetModal(false)}
                showModal={showResetModal}
                onYes={onResetPassword}
            >
                <div className="pg-text__bold my-4">
                    Are you sure you want to reset the password for the selected patient?
                </div>
            </PractitionerBatchFnModal>}
            {showLockoutModal && <PractitionerBatchFnModal
                showModal={showLockoutModal}
                onCancel={() => setShowLockoutModal(false)}
                onYes={onLockout}
            >
                <div className="pg-text__bold my-4">
                    Are you sure to reset lockout for selected Patients ?
                </div>
            </PractitionerBatchFnModal>}
            {showArchiveModal && <PractitionerBatchFnModal
                showModal={showArchiveModal}
                onCancel={() => setShowArchiveModal(false)}
                onYes={onArchive}
            >
                <div className="pg-text__bold my-4">
                    Are you sure to archive selected Patients ?
                </div>
            </PractitionerBatchFnModal>}
            {showRestoreModal && <PractitionerBatchFnModal
                showModal={showRestoreModal}
                onCancel={() => setShowRestoreModal(false)}
                onYes={onRestore}
            >
                <div className="pg-text__bold my-4">
                    Are you sure to restore the selected archived Patients ?
                </div>
            </PractitionerBatchFnModal>}
            {showReportArchiveModal && <PractitionerBatchFnModal
                showModal={showReportArchiveModal}
                onCancel={() => setShowReportArchiveModal(false)}
                onYes={onReportArhive}
            >
                <div className="pg-text__bold my-4">
                    Are you sure to archive the selected Patient's report?
                </div>
            </PractitionerBatchFnModal>}
            {showReportRestoreModal && <PractitionerBatchFnModal
                showModal={showReportRestoreModal}
                onCancel={() => setShowReportRestoreModal(false)}
                onYes={onReportRestore}
            >
                <div className="pg-text__bold my-4">
                    Are you sure to restore the selected Patient's report?
                </div>
            </PractitionerBatchFnModal>}
            {showGeneticUploadReminderModal && <PractitionerBatchFnModal
                showModal={showGeneticUploadReminderModal}
                onCancel={() => setShowGeneticUploadReminderModal(false)}
                onYes={onSendGeneticUploadReminder}
            >
                <div className="pg-text__bold my-4">
                    Are you sure to send genetic upload reminders to the selected Patients?
                </div>
            </PractitionerBatchFnModal>}
            {showRegistrationReminderModal && <PractitionerBatchFnModal
                showModal={showRegistrationReminderModal}
                onCancel={() => setShowRegistrationReminderModal(false)}
                onYes={onRegistrationReminder}
            >
                <div className="pg-text__bold my-4">
                    Are you sure to send registration emails to the selected Patients?
                </div>
            </PractitionerBatchFnModal>}

            <ActionButtons
                context="patient"
                onArchive={() => setShowArchiveModal(true)}
                onDownload={onDownload}
                onEdit={() => setShowEditModal(true)}
                onLockout={() => setShowLockoutModal(true)}
                onReset={() => setShowResetModal(true)}
                onRestore={() => setShowRestoreModal(true)}
                onReportArchive={() => setShowReportArchiveModal(true)}
                onReportRestore={() => setShowReportRestoreModal(true)}
                onSendGeneticUploadReminder={() => setShowGeneticUploadReminderModal(true)}
                onRegistrationReminder={() => setShowRegistrationReminderModal(true)}
                disableEdit={selectedPatients.length !== 1 || !isAnyArrayElementHas(patients, selectedPatients, 'aasm_state', 'archived')}
                disableArchive={selectedPatients.length === 0 || !isAnyArrayElementHas(patients, selectedPatients, 'aasm_state', 'archived')}
                disableReset={selectedPatients.length === 0 || !isAnyArrayElementHas(patients, selectedPatients, 'aasm_state', 'archived') || !isAnyArrayElementHas(patients, selectedPatients, 'aasm_state', 'pending')}
                disableLockout={selectedPatients.length === 0 || !isAnyArrayElementHas(patients, selectedPatients, 'aasm_state', 'archived') || !isAnyArrayElementHas(patients, selectedPatients, 'aasm_state', 'pending') || !isAllArrayElementHas(patients, selectedPatients, 'aasm_state', 'locked')}
                disableRestore={selectedPatients.length === 0 || !isAllArrayElementHas(patients, selectedPatients, 'aasm_state', 'archived')}
                disableReportArchive={selectedPatients.length === 0 || !isAnyArrayElementHas(patients, selectedPatients, 'aasm_state', 'archived') || !isAnyArrayElementHas(patients, selectedPatients, 'report_status', 'patient no data') || !isAnyArrayElementHas(patients, selectedPatients, 'report_status', 'practtitioner no data') || !isAnyArrayElementHas(patients, selectedPatients, 'report_status', 'archived')}
                disableReportRestore={selectedPatients.length === 0 || !isAnyArrayElementHas(patients, selectedPatients, 'aasm_state', 'archived') || !isAnyArrayElementHas(patients, selectedPatients, 'report_status', 'patient no data') || !isAnyArrayElementHas(patients, selectedPatients, 'report_status', 'practtitioner no data') || !isAllArrayElementHas(patients, selectedPatients, 'report_status', 'archived')}
                disableGeneticUploadReminder={selectedPatients.length === 0 || !(isAllArrayElementHas(patients, selectedPatients, 'report_status', 'patient no data') && isAnyArrayElementHas(patients, selectedPatients, 'aasm_state', 'archived'))}
                disableRegistrationReminder={selectedPatients.length === 0 || !(isAllArrayElementHas(patients, selectedPatients, 'aasm_state', 'pending'))}
            />
        </div>
    )
}

export default PatientBatchFn
