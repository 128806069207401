import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import warningIcon from "../../../assets/warning.png";
import ActionImage from "../../../components/ActionImage";
import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { updateTrait } from "../../../store/actions/traits";

function TraitCardDropDown({ traits, color, positionTop, traitCat }) {
  // console.log(traits)
  const navigate = useNavigate();
  const location = useLocation();

  const PopupBackground = styled.div`
    background: ${color}40;
    padding: 10px;
    border-radius: 0 0 8px 8px;
  `;

  // redux state variable
  const userType = useSelector((state) => state.auth.userType);
  const allTraits = useSelector((state) => state.trait.allTraits);

  // redux actions

  const dispatch = useDispatch();
  const updateTraitInfoAction = (data) => dispatch(updateTrait(data));

  const goToTraitPage = (traitCat, traitId) => {
    const index = _.findIndex(allTraits, { trait_id: traitId });

    updateTraitInfoAction({
      currentTraitCategory: traitCat,
      currentTraitId: traitId,
      currentTraitIndex: index,
      canPrevious: index !== 0,
      canNext: index !== allTraits.length - 1,
    });
    if (
      location.pathname !== "/practice/patient/report/trait" &&
      location.pathname !== "/patient/report/trait"
    )
      navigate(
        `${userType === "User" ? "/practice" : ""}/patient/report/trait`
      );
  };

  return (
    <div
      className="w-100 position-absolute shadow-lg bg-light text-dark"
      style={{ zIndex: "1", top: positionTop, left: 0 }}
    >
      <PopupBackground className="ps-4">
        {traits.categorization
          ? Object.entries(traits.items).map((traitSubCat) => {
              return (
                <>
                  <div className="pg-text__bold text-capitalize">
                    {_.startCase(traitSubCat[0])}
                  </div>
                  {traitSubCat[1].map((trait, i) => {
                    return (
                      <div
                        key={i}
                        style={{
                          borderBottom:
                            traitSubCat[1].length !== i + 1
                              ? ".5px solid #88888880"
                              : "",
                        }}
                        className="d-flex justify-content-between py-2 cp"
                        onClick={() => {
                          console.log("test", traitCat, trait.trait_id);
                          goToTraitPage(traitCat, trait.trait_id);
                        }}
                      >
                        <div className="text-capitalize text-start">
                          {trait.title}
                        </div>
                        <div className="my-auto">
                          <ActionImage action={trait.call_to_action} />
                          {/* {trait.isNew && <img src={NewBadgeIcon} alt="" />} */}
                          <i
                            className="fa fa-chevron-right"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                    );
                  })}
                </>
              );
            })
          : traits?.items?.map((trait, i) => {
              // console.log(traits)
              return (
                <div key={i}>
                  <div
                    style={{
                      borderBottom:
                        traits.items.length !== i + 1
                          ? ".5px solid #88888880"
                          : "",
                    }}
                    className="d-flex justify-content-between py-2 cp"
                    onClick={() => {
                      console.log("test", traitCat, trait.trait_id);
                      goToTraitPage(traitCat, trait.trait_id);
                    }}
                  >
                    <div className="text-capitalize text-start">
                      {trait.title}
                    </div>

                    <div className="d-flex   align-items-center">
                      {trait.is_ps && <h1 className="p-logo">P</h1>}
                      <ActionImage action={trait.call_to_action} />
                      {/* {trait.isNew && <img src={NewBadgeIcon} alt="" />} */}
                      <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>
              );
            })}
      </PopupBackground>
    </div>
  );
}

export default TraitCardDropDown;

function TraitCardDropDownListItem() {
  return <div></div>;
}
