import React from "react";
import { PgProgressBar } from "../Patient/PgProgressBar";
import { useTranslation } from "react-i18next";
import _ from "lodash";

function AfterUploadStarted({
  onTryAgain,
  error,
  status,
  uploadType,
  errorText,
  isInvited,
  qStatus,
  onCompleteQuest,
}) {
  let _status =
    status?.toLowerCase() === "processing"
      ? "analyzing"
      : status?.toLowerCase();
  _status =
    status?.toLowerCase() === "error" ? "uploading" : status?.toLowerCase();
  const { t, i18n } = useTranslation();
  return (
    <div className="container-xl">
      <div className="row justify-content-center my-5">
        <div className="col-12 col-lg-6 text-center">
          <span
            dangerouslySetInnerHTML={{ __html: t("patient_afstart_txt1") }}
          />
        </div>
        <div className="col-12 col-lg-12 border progress-status-wrapper">
          <div className="row pg-heading__sub text-center textc-primary progress-status-title">
            <div
              className="col-3 border py-2"
              style={{
                background:
                  _status === "uploading"
                    ? "#2470C2"
                    : _status === "importing" ||
                      _status === "analyzing" ||
                      _status === "complete"
                    ? "#e9f1f9"
                    : "#fff",
                color: _status === "uploading" ? "#fff" : "#2470C2",
              }}
            >
              {t("patient_afstart_txt2")}
            </div>
            <div
              className="col-3 border py-2"
              style={{
                background:
                  _status === "importing"
                    ? "#2470C2"
                    : _status === "importing" ||
                      _status === "analyzing" ||
                      _status === "complete"
                    ? "#e9f1f9"
                    : "#fff",
                color: _status === "importing" ? "#fff" : "#2470C2",
              }}
            >
              {t("patient_afstart_txt3")}
            </div>
            <div
              className="col-3 border py-2"
              style={{
                background:
                  _status === "analyzing"
                    ? "#2470C2"
                    : _status === "analyzing" || _status === "complete"
                    ? "#e9f1f9"
                    : "#fff",
                color: _status === "analyzing" ? "#fff" : "#2470C2",
              }}
            >
              {t("patient_afstart_txt4")}
            </div>
            <div
              className="col-3 border py-2"
              style={{
                backgroundColor: _status === "complete" ? "#2470C2" : "#fff",
                color: _status === "complete" ? "#fff" : "#2470C2",
              }}
            >
              {t("patient_snp_txt1")}
            </div>
          </div>
          {error ? (
            <div className="text-center">
              <div className="pg-text__bold text-center text-color__p my-lg-4 my-1 mt-lg-5">
                <span
                  dangerouslySetInnerHTML={{
                    __html: t("patient_afstart_txt5"),
                  }}
                />
                <div className="my-5" />
              </div>
              <button
                className="btn btn-primary__p w-50 mt-lg-4 mb-5"
                onClick={onTryAgain}
              >
                {t("try_again")}
              </button>
              <span dangerouslySetInnerHTML={{ __html: t("support_line") }} />
            </div>
          ) : (
            <div className="row p-3">
              <div className="pg-text__bold my-2">
                {t("patient_afstart_txt6")} {t(_.lowerCase(_status))}
              </div>
              <div className="col-12">
                <PgProgressBar status={_status} color="progress_bar__patient" />
                {_status !== "uploading" && (
                  <div className="pg-text__small my-2 text-center">
                    {t("patient_afstart_txt7")}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AfterUploadStarted;
