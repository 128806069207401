import _ from "lodash";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import {
  getPractitionerMadeRequestStatus,
  setPractitionerMadeRequestStatus,
  getAdminMadeRequestStatus,
} from "../../../store/actions/patientDashboard";
import { logoutAction } from "../../../methods/auth_methods";

function GDPRStatus() {
  const [showActionPrompt2, setshowActionPrompt2] = useState(false);
  const [showAdminActionPrompt, setShowAdminActionPrompt] = useState(false);
  const [requestId, setrequestId] = useState("");
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const currentPracticeID = useSelector(
    (state) => state.auth.currentPracticeID
  );
  const lang = useSelector((state) => state.auth.language);
  const getPracMadeRequestStatus = () =>
    dispatch(
      getPractitionerMadeRequestStatus(
        token,
        currentPracticeID,
        currentPractice
      )
    );
  const getAdMadeRequestStatus = () =>
    dispatch(
      getAdminMadeRequestStatus(token, currentPracticeID, currentPractice)
    );
  const setPracMadeRequestStatus = (pld, accept) =>
    dispatch(
      setPractitionerMadeRequestStatus(
        token,
        currentPracticeID,
        currentPractice,
        pld,
        accept
      )
    );
  const [adminRequestType, setAdminRequestType] = useState("");

  const [showAdminRequestData, setShowAdminRequestData] = useState(false);
  const [adminRequestDataId, setAdminRequestDataId] = useState("");
  const [showAdminEraseData, setShowAdminEraseData] = useState(false);
  const [adminEraseDataId, setAdminEraseDataId] = useState("");
  const [showAdminInactivateAccount, setAdminShowInactivateAccount] =
    useState(false);
  const [adminInactivateAccountId, setAdminInactivateAccountId] = useState("");

  // const gdprRequestAPIFn2 = () => {
  //     setPracMadeRequestStatus({ request_type: 'delete_data', id: requestId }, true).then(response => {
  //         setshowActionPrompt2(false);
  //         if (!response.error) {
  //             toast.success('Request approved successfully.')
  //         }
  //     })
  // }

  // const gdprRequestAPIFn3 = () => {
  //     setPracMadeRequestStatus({ request_type: 'delete_data', id: requestId }, false).then(response => {
  //         setshowActionPrompt2(false);
  //         if (!response.error) {
  //             toast.success('Request rejected successfully.')
  //         }
  //     })
  // }

  const gdprRequestAPI = (requestType, isApprove, id) => {
    setPracMadeRequestStatus(
      { request_type: requestType, id: id },
      isApprove
    ).then((response) => {
      if (!response.error) {
        if (isApprove) {
          toast.success("Request approved successfully.");
          if (response.payload.logout) {
            logoutAction();
          }
        } else {
          toast.success("Request rejected successfully.");
        }
      }
    });
  };

  useEffect(() => {
    if (lang === "en_gb") {
      getPracMadeRequestStatus().then((response) => {
        if (!response.error && !_.isEmpty(response.payload)) {
          setshowActionPrompt2(true);
          setrequestId(response.payload.id);
        }
      });
      getAdMadeRequestStatus().then((response) => {
        if (!response.error && !_.isEmpty(response.payload)) {
          var adminRequestData = _.filter(response.payload.gdpr_list, [
            "request_type",
            "Request Data",
          ]);
          if (adminRequestData.length > 0) {
            setShowAdminRequestData(true);
            setAdminRequestDataId(adminRequestData[0].id);
          }
          var adminEraseData = _.filter(response.payload.gdpr_list, [
            "request_type",
            "Erase Data",
          ]);
          if (adminEraseData.length > 0) {
            setShowAdminEraseData(true);
            setAdminEraseDataId(adminEraseData[0].id);
          }
          var adminInactivate = _.filter(response.payload.gdpr_list, [
            "request_type",
            "Inactivate Account",
          ]);
          if (adminInactivate.length > 0) {
            setAdminShowInactivateAccount(true);
            setAdminInactivateAccountId(adminInactivate[0].id);
          }
          // setShowAdminActionPrompt(true)
          // setrequestId(response.payload.gdpr_list[0].id);
          // setAdminRequestType(response.payload.gdpr_list[0].request_type)
        }
      });
    }
  }, []);

  return (
    <div>
      {showActionPrompt2 && (
        <ActionPrompt
          show={showActionPrompt2}
          rejectLabel="Cancel"
          message={`Your practitioner has requested that your account be deleted 
                          from the PureGenomics system. We recommend that you download 
                          your report if you wish to retain a copy of the PureGenomics 
                          record. Navigate to your report and from the top right of the 
                          page click on View and Download Report. You will have 30 days 
                          to download the report, after which your account and all of the 
                          data will be deleted. This action is not reversible. To approve 
                          this action click confirm or contact your practitioner if you believe
                           this is an error.`}
          onReject={() => {
            gdprRequestAPI("delete_data", false, requestId);
            setshowActionPrompt2(false);
          }}
          onAccept={() => {
            gdprRequestAPI("delete_data", true, requestId);
            setshowActionPrompt2(false);
          }}
        />
      )}
      {showAdminRequestData && (
        <ActionPrompt
          show={showAdminRequestData}
          rejectLabel="Cancel"
          message={`A PureGenomics administrator has requested, on behalf of your practitioner, a copy of your PureGenomics record in machine-readable format (CSV text format). To access the files please log in to your account. On the top right of the page click on the avatar and then choose Manage Data. On the Manage Data page you will see a Request Log. Once the status of the request is Completed, click on the Download link to download your files. To approve this action click confirm or contact your practitioner if you believe this is an error.`}
          onReject={() => {
            gdprRequestAPI("request_data", false, adminRequestDataId);
            setShowAdminRequestData(false);
          }}
          onAccept={() => {
            gdprRequestAPI("request_data", true, adminRequestDataId);
            setShowAdminRequestData(false);
          }}
        />
      )}
      {showAdminEraseData && (
        <ActionPrompt
          show={showAdminEraseData}
          rejectLabel="Cancel"
          message={`A PureGenomics administrator has requested, on behalf of your practitioner, that your account be deleted from the PureGenomics system. We recommend that you download your report if you wish to retain a copy of the PureGenomics record. Navigate to your report and from the top right of the page click on View and Download Report. You will have 30 days to download the report, after which your account and all of the data will be deleted. This action is not reversible. To approve this action click confirm or contact your practitioner if you believe this is an error.`}
          onReject={() => {
            gdprRequestAPI("delete_data", false, adminEraseDataId);
            setShowAdminEraseData(false);
          }}
          onAccept={() => {
            gdprRequestAPI("delete_data", true, adminEraseDataId);
            setShowAdminEraseData(false);
          }}
        />
      )}
      {showAdminInactivateAccount && (
        <ActionPrompt
          show={showAdminInactivateAccount}
          rejectLabel="Cancel"
          message={`A PureGenomics administrator has requested, on behalf of your practitioner (the data controller), to inactivate your PureGenomics account. To approve this action click confirm. If you believe that this is an error, please contact your practitioner.`}
          onReject={() => {
            gdprRequestAPI(
              "inactivate_account",
              false,
              adminInactivateAccountId
            );
            setAdminShowInactivateAccount(false);
          }}
          onAccept={() => {
            gdprRequestAPI(
              "inactivate_account",
              true,
              adminInactivateAccountId
            );
            setAdminShowInactivateAccount(false);
          }}
        />
      )}
    </div>
  );
}

export default GDPRStatus;

export const ActionPrompt = ({
  show,
  onAccept,
  onReject,
  message,
  acceptLabel = "Confirm",
  rejectLabel = "Go Back",
  persistant = "static",
}) => {
  return (
    <Modal
      centered
      backdrop={persistant}
      show={show}
      onHide={onReject}
      bsPrefix="border-radius modal"
      contentClassName="border-radius"
      style={{ borderRadius: 8 }}
    >
      <div className="p-3">
        <div className="pg-text__bold">{message}</div>
        <div className="d-flex justify-content-end mt-4">
          <button className="btn bg-sky-blue px-4 mx-1" onClick={onReject}>
            {rejectLabel}
          </button>
          <button
            className="btn bgc-primary text-light px-5"
            onClick={onAccept}
          >
            {acceptLabel}
          </button>
        </div>
      </div>
    </Modal>
  );
};
