export const range = (start, end) => {
  return Array.apply(0, Array(end - 1)).map((element, index) => index + start);
};
export const years = range(1900, new Date().getFullYear() + 1, 1);
export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const months_fr = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const getCurrentDate = (separator = " ") => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let newDate = new Date();
  let date = newDate.getDate();
  let month = monthNames[newDate.getMonth()];
  let year = newDate.getFullYear();
  var hours = newDate.getHours();
  var minutes = newDate.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return `${
    month < 10 ? `0${month}` : `${month}`
  }${separator}${date}, ${year} ${strTime}`;
};
