import React, { useState, useEffect } from "react";
import { birthYearRange } from "../../../methods";
import { useSelector } from "react-redux";

function NumberQuestion(props) {
  const numberQuestion = props.question;
  const showSpinner = props.showSpinner;
  const isEdit = props.isEdit;
  const answer = props.editAnswerText
  const [patientAnswer, setPatientAnswer] = useState("");
  const [showOK, setShowOK] = useState(false);
  const [answerPlaceholder, setAnswerPlaceholder] = useState("Type your answer here");
  const lang = useSelector((state) => state.auth.language)
  const language = (lang === undefined ? "en" : lang)

  useEffect(() => {
    if(isEdit){
      setPatientAnswer(answer[0])
      setShowOK(true)
    }
  }, []);

  const checkMaxLength = (e) => {
    if (e.target.value.length > e.target.maxLength) {
      e.target.value = e.target.value.slice(0, e.target.maxLength);
    }
  };

  const setAnswer = (value) => {
    setPatientAnswer(value);
    value.length > 0 ? setShowOK(true) : setShowOK(false);
  };

  const submitNumberAnswer = (index, question, answer) => {
    numberQuestion.answer = answer;
    props.addtextAnswer(index, question, answer);
    setShowOK(false);
  };

  return (
    <div>
      <div
        className="talk-bubble tri-right round border right-top"
        style={{ position: "relative" }}
      >
        <div className="talktext">
          <p className="pg-text">
            {
              ("question_" + language) in numberQuestion ? numberQuestion["question_" + language] : numberQuestion.question 
            }
          </p>
        </div>
      </div>
      <div>
        {!("answer" in numberQuestion) && (
          <div>
            <div className="question-support-text">
              <p>{numberQuestion["instructions_" + language]}</p>
            </div>
            <input
              type="number"
              placeholder={numberQuestion["placeholder_" + language]}
              value={patientAnswer}
              maxLength="4"
              onInput={checkMaxLength}
              onChange={(e) => setAnswer(e.target.value)}
              className="talk-bubble tri-right border round btm-left-in text-box"
            />
          </div>
        )}
        {showSpinner && (
          <div
            className="spinner-border textc-primary text-center mx-3 my-1 questionnaire-spinner"
            role="status"
            style={{ height: 15, width: 15 }}
          >
            {" "}
          </div>
        )}
        {!("answer" in numberQuestion) && (
          <div className="submit-wrapper">
            {showOK && ( <button
              className="submit talk-bubble border round btm-left-in"
              onClick={() =>
                submitNumberAnswer(props.index, numberQuestion, patientAnswer)
              }
              value="ok"
            >
              OK
            </button> )}
          </div>
        )}
        <div>
          {"answer" in numberQuestion && (
            <div className="talk-bubble tri-right border round btm-left-in answer-bubble">
              <div className="talktext">
                <p className="pg-text">{numberQuestion.answer}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default NumberQuestion;
