import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function TermsAndUseUK() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="my-5 py-5 ">
      <div className="container-xxl">
        <div className="pgs-title ff-v my-5">
          PureGenomics<sup>&reg;</sup> TERMS OF SERVICE
        </div>
        <div>
          <div className="pgs-heading fst-italic my-3">
            Last updated: March 2022
          </div>
          <div className="pgs-text">
            <p>
              Welcome to our UK web-based mobile application (<b>“App”</b>). We hope that you will enjoy your experience. The terms below govern your use of our App (these <b>“Terms”</b>).
            </p>
            <p>
              By using our App you accept and agree to comply with these Terms; if you do not agree, please do not use our App.
            </p>
            <p>
              In these Terms: <b>“we”, “us”, “our”</b> and <b>“PureGenomics<sup>&reg;</sup>”</b> mean Pure Encapsulations, LLC and its affiliates.
            </p>
            <p>
              Other terms also apply to your use of our App and they are incorporated herein by this reference. These include terms  which  will  apply  to  features  which  may  be  made  available  on  our  App  from  time  to  time.  You  agree  to comply with such terms.
            </p>
          </div>
        </div>
        <div className="row my-5">
          <div className="col-lg-4 border  bg-grey-1">
            <div className="pgs-text fw-bold p-2">
              Contents
            </div>
            <ul className="pgs-text ff-m">
              <li>
                <a href="#1">Privacy and Cookies</a>
              </li>
              <li>
                <a href="#2"> About the PureGenomics<sup>&reg;</sup> App</a>
              </li>
              <li>
                <a href="#3">Your Use of our App</a>
              </li>
              <li>
                <a href="#4">Intellectual Property</a>
              </li>
              <li>
                <a href="#5">Your User Content</a>
              </li>
              <li>
                <a href="#6">Accessibility</a>
              </li>
              <li>
                <a href="#7">Links</a>
              </li>
              <li>
                <a href="#8">Disclaimer</a>
              </li>
              <li>
                <a href="#9">Limitation of liability</a>
              </li>
              <li>
                <a href="#10"> Indemnity</a>
              </li>
              <li>
                <a href="#11">How to contact us</a>
              </li>
              <li>
                <a href="#12">Changes to these Terms</a>
              </li>
              <li>
                <a href="#13">Geographic Scope</a>
              </li>
              <li>
                <a href="#14">Governing Law, Dispute resolution</a>
              </li>
              <li>
                <a href="#15">General</a>
              </li>
            </ul>
          </div>
        </div>
        {/* 1 */}
        <div>
          <div className="pgs-heading__sub ff-m fw-bold my-2 mt-5 text-dark" id="1">
            Privacy and Cookies
          </div>
          <div className="pgs-text">
            <div className="">
              Our Privacy Notice applies to any personal data shared on our App. Find out more here <a
              className="cursor-pointer"
              onClick={() => navigate("/privacy")}
              target="_blank"
            >
               Privacy Policy.
            </a>. Our Cookies Policies applies to the use of cookies and similar technology on our App. Find out more here <a
              className="cursor-pointer"
              onClick={() => navigate("/cookies-notice")}
              target="_blank"
            >
               Cookies Notice
            </a>.
            </div>
            <div className="my-3">
              If anything is unclear to you or you wish to discuss these Terms or our App with PureGenomics<sup>&reg;</sup> please see <b>How to contact us</b> below.
            </div>
          </div>
        </div>
        {/* 2 */}
        <div>
          <div className="pgs-heading__sub ff-m fw-bold my-2 mt-5 text-dark" id="2">
            About the PureGenomics<sup>&reg;</sup> App
          </div>
          <div className="pgs-text">
            <div className="">
              The PureGenomics<sup>&reg;</sup> secure platform uses key information inputted by or on behalf of patients from existing DNA test results to create a report with personalised nutrition and lifestyle recommendations under guidance from their independent healthcare professional (<b>“HCP”</b>).
            </div>
            <div className="my-3">
              The PureGenomics<sup>&reg;</sup> App is only available to patients that have been invited to join the App by a HCP that has registered  with  the  App.   It  is  an  ongoing  condition  of  using  our  App  that  each  patient  account  is  linked  to  an active  HCP  account.  If  a  HCP  requests  their  account  be  deleted,  We  will  also  delete  any  associated  patient accounts. A HCP may also request the deletion of a linked patient’s account if they cease to work with the patient or for any other reason. If this situation arises, We will notify the patients and provide not less than 30 days to download your Personal Data after which both the HCP account and associated patient accounts will be deleted.
            </div>
            <div className="my-3">
              By  using  this  App  and  offering  this  App  to  your  patients,  each  HCP  represents  and  warrants  that  they  are  a healthcare professional duly permitted in the jurisdiction in which they practice to offer genetic counseling and nutritional supplement counseling.
            </div>
            <div className="my-3">
              In order to use the App, a patient is required to submit digitised DNA data from a recognised third party provider, namely a {" "}
              <a
                href="https://www.23andme.com/en-gb/"
                target="_blank"
                rel="noreferrer"
              >
                23andme.com/en-gb/
              </a>, or
              {" "}
              <a
                href="http://ancestry.co.uk/"
                target="_blank"
                rel="noreferrer"
              >
                Ancestry.co.uk
              </a>{" "}
              data file. PureGenomics<sup>&reg;</sup> is not affiliated with or endorsed by any genetic testing provider.
            </div>
            <div className="my-3">
              By  submitting  health  and  lifestyle  data  (including  DNA  data)  to  PureGenomics<sup>&reg;</sup>  to  use  our  genetic  based nutritional support services (the <b>“Services”</b>), you authorise PureGenomics<sup>&reg;</sup> to use or analyse such data to create reports  about  your  individual  traits,  characteristics,  predispositions,  or  health  conditions  in  order  to  offer nutritional  supplement,  dietary,  and  lifestyle  recommendations  (with  guidance  from  your  HCP);  to  share  your personal  data  and  associated  reports  or  recommendations  with  any  healthcare  provider  and/or  authorised representative   you   explicitly   designate;   and   to   use   your   aggregated   and   anonymized   data   to   improve PureGenomics<sup>&reg;</sup>’s product features and services, create new product features and services, and to comply with law enforcement requests.
            </div>
            <div className="my-3">
              As a condition of your use of the Services, you agree that the <span className="text-decoration-underline">PureGenomics<sup>&reg;</sup> App and the Services are provided for informational purposes only and are not meant to substitute for the advice provided by a physician or other medical professional or for reading relevant product information. You should not use the information contained herein for diagnosing or treating or preventing a health problem or disease, or prescribing any medication.</span> If you have or suspect that you have a medical problem, promptly contact your HCP. In an emergency, dial 999 or your local emergency number immediately.
            </div>
            <div className="my-3">
              PureGenomics<sup>&reg;</sup> is not engaged in rendering medical advice or services and the App is not a medical device. Your use of the App does not constitute or create a doctor-patient, or other healthcare professional relationship between PureGenomics<sup>&reg;</sup>  and  you.  Information  on  the  PureGenomics<sup>&reg;</sup>  website  and  app  is  based  on  scientific  studies (human, animal, or in vitro), clinical experience, or traditional usage as cited in each article and the use of any such  information  or  opinions  is  solely  at  your  own  risk.  For  many  of  the  conditions  discussed,  treatment  with prescription or over-the-counter medication is available. Consult your physician, nutritionally oriented health care professional, and/or pharmacist for any health problem and before using any supplements or before making any changes in prescribed medications. Never disregard professional medical advice or delay in seeking it because of something you have read on this App.
            </div>
            <div className="my-3">
              We cannot guarantee, predict or foresee individual results or health effects arising out of the use of the App or our Services. The results reported may not necessarily occur in all individuals.
            </div>
            <div className="my-3">
              If you use the Services as a patient, note that you will not acquire rights to any research or products developed by PureGenomics<sup>&reg;</sup> through the use of your anonymized DNA data by virtue of submission of your DNA data to PureGenomics<sup>&reg;</sup>. Notwithstanding any other provision in these Terms, you retain an ownership right over your personal data (including any DNA data), subject to the rights granted to PureGenomics<sup>&reg;</sup> by these Terms and our Privacy  Policy.  You  may  request  the  destruction  of  your  personal  data  at  any  time  by  contacting  us  (see  our Privacy  Policy)  or  requesting  deletion  via  your  account  portal.  Further  details  as  to  your  rights  with  respect  to your personal data are set out in our Privacy Policy
            </div>
          </div>
        </div>
        {/* 3 */}
        <div>
          <div className="pgs-heading__sub ff-m fw-bold my-2 mt-5 text-dark" id="3">
            Your Use of our App
          </div>
          <div className="pgs-text">
            <div className="">
              In order to use our App and submit your content, including your DNA data you must be at least 18 years of age.
            </div>
            <div className="my-3">
              To use the Services, you must create an account and specify a password according to our password policy. Only registered HCPs and their patients may create an account. A patient may only register for an account once they have been sent an email invite upon the request of their HCP.
            </div>
            <div className="my-3">
              The  process  of  registration  for  an  account  requires  explicit  consent  to  these  terms  and  conditions,  as  well  as provision of the following:
              <ul>
                <li>
                  HCPs - your email address, mobile phone number, practice name, healthcare professional type, licence number (as applicable) and address
                </li>
                <li>
                  Patients  -  your  email  address,  mobile  phone  number,  age,  gender,  health  and  lifestyle  data  including DNA data, and the name of your HCP. In addition, you may provide optional data including completion of a questionnaire and lab result information.
                </li>
              </ul>
            </div>
            <div className="my-3">
              We  are  not  obliged  to  accept  any  account  registrations  and  may  decline  the  creation  of  any  accounts  that  we believe violate these Terms and Conditions.
            </div>
            <div className="my-3">
              If you choose to register on our App you must do so accurately and honestly and update us of any changes. The Services and recommendations provided through the App may not be appropriate if you input incorrect, inaccurate or untrue information into the App. We may grant, withhold or change your username, password or other access information at any time at our sole discretion.
            </div>
            <div className="my-3">
              You must keep your password and other access information secret. HCPs must not access patient accounts on the patient's behalf. If you think someone has discovered your access information or accessed your account, you must tell us immediately, please see <b>How to contact us</b> below.
            </div>
            <div className="my-3">
              In  using  our  App,  you  may  contribute  material  to  it  such  as  profile  information,  data  (including  DNA  data), questions, messages, images, files or other materials that you create, post or otherwise send to the App   (<b>“User Content”</b>).
            </div>
            <div className="my-3">
              Your use of our App and your User Content must be lawful and comply with these Terms.
            </div>
            <div className="my-3">
              Your use of our App and your User Content must not:
              <ol type="a">
                <li>
                  harm,  harass  or  hurt  other  people  or  breach  another  person’s  rights,  including  privacy  (such  as  uploading private information without the concerned individual’s consent), image and likeness;
                </li>
                <li>
                  misuse or infringe any intellectual property rights, or contain anything confidential or proprietary to you or any other person or entity, or that you do not have the right to disclose generally to the public;
                </li>
                <li>
                  be unlawful, defamatory (including towards PureGenomics<sup>&reg;</sup>), obscene, vulgar, threatening, abusive, racist or xenophobic,  promote  or  incite  intolerance,  hatred  or  violence,  or  otherwise  offensive,  objectionable  or inappropriate;
                </li>
                <li>
                  include commercial or other advertising or promotions;
                </li>
                <li>
                  be false, fraudulent, misleading or malicious, impersonate any other person or misrepresent your identity or any other information about you;
                </li>
                <li>
                  distribute virus, worm or other harmful computer code, test the vulnerability of our App, lead to security issues, damage, disable or overburden our App, or otherwise jeopardize, disrupt, overload, harm, impair or interfere with the functioning or integrity of our App or systems or networks connected to our App or with any other person’s use of our App.
                </li>
              </ol>
            </div>
            <div className="my-3">
              You must not nor try to: (a) make mass, automated or systematic extractions from our App or use it within, or to create,  another  site,  service  or  database,  or  try  to  re-sell  it  or  re-distribute  it;  (b)  decompile,  reverse  engineer, disassemble or otherwise reduce the code used in any software on our App into a readable form; or (c) use any deep-link, page-scrape, spider robot, crawl, index, Internet agent or other automatic device, program, algorithm or any similar or equivalent methodology to use, access, copy, acquire information, generate impressions, input information, store information, search, generate searches or monitor any portion of our App or Content.
            </div>
            <div className="my-3">
              You  may  not  use  any  content  or  any  feature  on  the  Services  in  any  manner  that  is  or  could  be  interpreted  as competitive  with  PureGenomics<sup>&reg;</sup>.  You  may  not  use  the  Services  if  you  represent  an  insurance  company  or employer seeking information about the health status of a person or employee.
            </div>
            <div className="my-3">
              Please  note  that  PureGenomics<sup>&reg;</sup>  may  immediately  and  without  prior  notice  remove  any  of  your  User  Content and/or suspend or terminate your use of our App if we believe that you have not complied with these Terms. We may, but are not obliged, to monitor and review as well as edit, remove or refuse to post any of your User Content at our sole discretion at any time.
            </div>
            <div className="my-3">
              In any event, you are responsible for and should take all reasonable care when creating and providing your User Content. You promise that you will comply with these Terms. You may have legal liability to us and third parties if  you  do  not.  We  may  but  are  not  obliged  to  report  relevant  breaches  of  these  Terms  to  regulatory  and  law enforcement bodies.
            </div>
            <div className="my-3">
              Let us know immediately if you have any complaints about any User Content, see <b>How to contact us</b> below.
            </div>
          </div>
        </div>

        {/* 4 */}
        <div>
          <div className="pgs-heading__sub ff-m fw-bold my-2 mt-5 text-dark" id="4">
            Intellectual Property
          </div>
          <div className="pgs-text">
            <div className="">
              All content on our App, including without limitation, text, photographs, graphics, artwork, trademarks (including Trademarks), brands, logos, videos, sound, music, user interfaces, and code (collectively, <b>“Content”</b>), as well as look-and-feel of the Content, is owned by us or our licensors and is protected by copyright, trademark, patent, trade dress, sui generis/database rights and other intellectual property rights.
            </div>
            <div className="my-3">
              The trademarks, logos, characters and service marks displayed on our App or used as a domain name (collectively <b>“Trademarks”</b>) belong to Pure Encapsulations, its parent company, and/or third parties who have authorized their use.
            </div>
            <div className="my-3">
              PureGenomics<sup>&reg;</sup> and Pure Encapsulations® is our registered trademark.
            </div>
            <div className="my-3">
              Nothing on our App and these Terms shall be construed as granting any license or right to use any Trademark. You are advised that PureGenomics<sup>&reg;</sup> and its affiliates will vigorously enforce its intellectual property rights to the fullest extent of the law.
            </div>
            <div className="my-3">
              You  may  access  and  reproduce  extracts  from  our  App  solely  for  your  own  private  and  non-commercial  use  or internal business use provided that in all cases you respect the integrity of the Content and you retain (and do not remove) any copyright notice on such Content (e.g.© 2022 PureGenomics<sup>&reg;</sup>. All rights reserved.) as well as the trademark notice below:
            </div>
            <div className="my-3 fw-bold">
              ® Reg. Trademark of Société des Produits Nestlé S.A. All rights reserved.
            </div>
            <div className="my-3">
              No  part  of  our  App  and  no  Content  may  be  used,  copied,  reproduced,  republished,  uploaded,  posted,  publicly displayed,  broadcasted,  published,  encoded,  translated,  transmitted  or  distributed  in  any  way  to  any  other computer, server, website or any other medium for commercial purposes, without PureGenomics<sup>&reg;</sup>’s express prior written consent.
            </div>
            <div className="my-3">
              All other rights are reserved. Except as expressly provided in these Terms, any use of our App, Trademarks or Content is strictly prohibited. You must obtain our prior permission for any other use of our App or the Content, including any commercial use, which we may grant, withhold or condition at our sole discretion.
            </div>
          </div>
        </div>

        {/*5*/}
        <div>
          <div className="pgs-heading__sub ff-m fw-bold my-2 mt-5 text-dark" id="5">
            Your User Content
          </div>
          <div className="pgs-text">
            <div className="">
              In order to submit your content, you must be at least 18 years of age.
            </div>
            <div className="my-3">
              You  are  solely  responsible  for  and  retain  ownership  of  all  User  Content  (defined  above).  You  represent  and warrant  that  the  User  Content  is  original  to  you  and  that  no  other  party  has  any  rights  to  such  information  or material.
            </div>
            <div className="my-3">
              Our use of the User Content shall be governed by the PureGenomics<sup>&reg;</sup> <a
              className="cursor-pointer"
              onClick={() => navigate("/privacy")}
              target="_blank"
            >
               Privacy Policy.
            </a> We are not in any way liable for your User Content. You are solely responsible for backing-up your personal User Content. We assume  no  liability  for  the  deletion,  corruption  or  failure  to  store  any  User  Content  maintained  or  transmitted through your use of the app, other than the liability required by applicable law.
            </div>
            <div className="my-3">
              By using the App, you give us permission to use your anonymised information to improve the service for all users.
            </div>
            <div className="my-3">
              In order to enable us to operate and improve the App and to process and manage your User Content in line with our Privacy Policy, you hereby grant PureGenomics<sup>&reg;</sup> a worldwide, non-exclusive, fully-paid, royalty-free licence to use, host, store, reproduce, modify, create derivative works, communicate and distribute your User Content in line  with  our  Privacy  Policy,  without  any  further  notice  to  you  and  without  any  compensation  or  obligation  to you.
            </div>
          </div>
        </div>

        {/*6*/}
        <div>
          <div className="pgs-heading__sub ff-m fw-bold my-2 mt-5 text-dark" id="6">
            Accessibility
          </div>
          <div className="pgs-text">
            <div className="">
              PureGenomics<sup>&reg;</sup>  is  committed  to  making  our  App  as  accessible  as  possible  for  all  users  including  those  with disabilities. Your browsing experience may improve by altering your software and device settings (e.g. to alter text size) or installing additional assistive technologies.
            </div>
          </div>
        </div>

        {/*7*/}
        <div>
          <div className="pgs-heading__sub ff-m fw-bold my-2 mt-5 text-dark" id="7">
            Links
          </div>
          <div className="pgs-text">
            <div className="">
              If you operate a third party platform and wish to link to our App, you may include a hyperlink on your own website to the home page of our App (using the plain text name of our App); provided that you do so in a manner compliant with these Terms, and you do not: (a) suggest in any way that you are affiliated with or endorsed or sponsored by PureGenomics<sup>&reg;</sup>, (b) display the hyperlink or our App in any way that is disparaging to PureGenomics<sup>&reg;</sup>, (c) deep-link to any other page of our App, or (d) frame our App or any Content, or otherwise cause our App or Content to appear in a window with any other material. We may withdraw any linking permission at any time at our sole discretion.  You  shall  not  use  PureGenomics<sup>&reg;</sup>’s  name,  logo,  slogans,  trademarks  or  any  other  words  or  codes identifying  PureGenomics<sup>&reg;</sup>  as  a  hyperlink  button,  in  a  metatag  or  in  any  manner  without  PureGenomics<sup>&reg;</sup>'s express written consent.
            </div>
          </div>
        </div>

        {/*8*/}
        <div>
          <div className="pgs-heading__sub ff-m fw-bold my-2 mt-5 text-dark" id="8">
            Disclaimer
          </div>
          <div className="pgs-text">
            <div className="">
              We may change all or any part of our App including by adding, removing or altering any Content, including User Content, at any time at our sole discretion. We may give prior notice to you but do not have to do so.
            </div>
            <div className="my-3">
              Our App will not be available at all times. We may modify, suspend or withdraw operation of or access to our App at any time at our sole discretion whether on a temporary or permanent basis including for maintenance and other technical reasons. We may give prior notice to you but do not have to do so.  As noted in Section 2 above, a HCP may also in their discretion decide to discontinue a patient account on the App
            </div>
            <div className="my-3">
              Like all software-based services our App cannot be promised to be free from errors or bugs. We shall have no responsibility to maintain the Content and our App or to supply any corrections, updates, or releases in connection therewith.
            </div>
            <div className="my-3">
              We strongly recommend that you take care to verify the suitability and compatibility of your electronic device prior to use of our App including reasonable precautions to protect yourself against security issues. If you choose to download or copy material from our App you do so at your own risk. We assume no liability for any damage or  virus  which  could  affect  your  electronic  equipment  or  other  property  by  reason  of  your  access  to,  use  or downloading of any material from our websites or for any illegal intrusion or intervention in our IT systems.
            </div>
            <div className="my-3">
              Our  App  is  a  dynamic  environment  and  inaccuracies  and  errors  may  occur  from  time  to  time.  We  are  not responsible  for  (and  do  not  necessarily  agree  with  and  endorse)  your  or  any  other  User  Content  on  our  App (including  any  recommendations  or  content  provided  by  HCPs)  and  you  should  apply  particular  caution  when using it. Always take specialist advice when needed. Any reliance upon materials on our App shall be at your own risk.
            </div>
            <div className="my-3">
              Our App is not a storage service and you should keep copies of all your User Content.
            </div>
            <div className="my-3">
              Our App and its Content is delivered to you on an “as is” and “as available” basis. To the fullest extent permitted by law, we make no representations or warranties of any kind, express, implied, statutory or otherwise, including but  not  limited  to  any  warranties  of  accuracy,  merchantability  and  fitness  for  a  particular  purpose,  non-infringement,  any  warranties  that  our  App  or  Content  will  be  complete,  accurate,  reliable,  timely,  error-free, uninterrupted, secure or free from viruses, or that any advice or opinion obtained from PureGenomics<sup>&reg;</sup> through our App is accurate or to be relied upon, and we exclude all conditions, warranties, representations and terms of any kind that may apply to our App or Content and your use of or reliance on it.
            </div>
          </div>
        </div>

        {/*9*/}
        <div>
          <div className="pgs-heading__sub ff-m fw-bold my-2 mt-5 text-dark" id="9">
            Limitation of liability
          </div>
          <div className="pgs-text">
            <div className="">
              Nothing in these Terms excludes or limits our liability in connection with our App and your use of or reliance on it  where  this  is  unlawful  to  do  so  including  for  fraud,  fraudulent  misrepresentation  or  death  or  personal  injury caused by our negligence. Nothing in these Terms affects your statutory rights as a consumer.
            </div>
            <div className="my-3">
              If  you  are  using  our  App  as  a  healthcare  professional  for  business  purposes,  you  acknowledge  that  we  are providing  it  for  free  and  on  the  basis  that,  except  as  set  out  in  the  first  paragraph  above:  (a)  we  exclude  all conditions, warranties, representations and terms of any kind that may apply to our App and your use of or reliance on it to the fullest extent permitted by law, other than those that are expressly set out in these Terms; and (b) we shall  have  no  liability  to  you  for  any  loss,  damage  or  costs  of  any  kind,  howsoever  arising  (including  but  not limited to negligence), whether direct or indirect, under or in connection with our App and your use of or reliance on it.
            </div>
            <div className="my-3">
              If you are using our App as an individual, except as set out in the first paragraph above, we shall have no liability for loss, damage or costs of any kind, howsoever arising (including but not limited to negligence), whether direct or indirect, under or in connection with our App or the Content and your use of or reliance on our App or the Content.
            </div>
            <div className="my-3">
              Without prejudice to the foregoing, PureGenomics<sup>&reg;</sup> expressly disclaims any and all liability for: (i) User Content, including  User  Content  created  by  HCPs;  and  (ii)  your  use  of  the  App  for  any  medical  purposes  or  any  other purpose that is against the intended use of the App. We do not warrant that the App or our Services can be used to achieve any particular results or health effects.
            </div>
            <div className="my-3">
              Except where prohibited by law, in no event will we be liable to you for any indirect, consequential, exemplary, special,  incidental  or  punitive  damages,  costs  or  losses  whatsoever,  including  without  limitation  lost  data,  lost profits or business interruption.
            </div>
            <div className="my-3">
              To the maximum extent permitted by applicable law, you expressly waive all claims against PureGenomics<sup>&reg;</sup>, its officers, directors, employees, suppliers and providers that may arise from your use or access of our App.
            </div>
          </div>
        </div>

        {/*10*/}
        <div>
          <div className="pgs-heading__sub ff-m fw-bold my-2 mt-5 text-dark" id="10">
            Indemnity
          </div>
          <div className="pgs-text">
            <div className="">
              You agree to indemnify and hold PureGenomics<sup>&reg;</sup>, its officers, directors, shareholders, predecessors, successors in  interest,  employees,  agents,  subsidiaries  and  affiliates,  harmless  from  any  demands,  loss,  damages,  liability, claims or expenses (including attorneys’ fees), made against PureGenomics<sup>&reg;</sup> by any third party due to or arising out of or in connection with your use of our App or your breach of these Terms.
            </div>
          </div>
        </div>

        {/*11*/}
        <div>
          <div className="pgs-heading__sub ff-m fw-bold my-2 mt-5 text-dark" id="11">
            How to contact us
          </div>
          <div className="pgs-text">
            <div className="">
              This App is operated by Pure Encapsulations LLC, 490 Boston Post Road, Sudbury, MA 01776 USA, a limited liability company.
            </div>
            <div className="my-3">
              If  you  have  any  question  or  comment  regarding  our  App,  please  feel  free  to  contact  us  by  (i)  email  at support@pure-encapsulations.co.uk, (ii) phone at +44 2080 049974; or (iii) regular mail at the above address.
            </div>
          </div>
        </div>

        {/*12*/}
        <div>
          <div className="pgs-heading__sub ff-m fw-bold my-2 mt-5 text-dark" id="12">
            Changes to these Terms
          </div>
          <div className="pgs-text">
            <div className="">
              We reserve the right to make changes to these Terms at any time at our sole discretion. We may give prior notice to you but do not have to do so. You are responsible for regularly reviewing these Terms. Your continued use of our App following the posting of changes to these Terms and such terms will mean that you accept and agree to the changes.
            </div>
          </div>
        </div>

        {/*13*/}
        <div>
          <div className="pgs-heading__sub ff-m fw-bold my-2 mt-5 text-dark" id="13">
            Geographic Scope
          </div>
          <div className="pgs-text">
            <div className="">
              This  App  is  for  users  in  UK  and  Ireland  only  and  may  not  be  appropriate  for  use  elsewhere.  PureGenomics<sup>&reg;</sup> makes no representation that the Services and content on our App are appropriate or available in other locations. If you access our App from another territory, you are responsible for compliance with any applicable laws.
            </div>
          </div>
        </div>

        {/*14*/}
        <div>
          <div className="pgs-heading__sub ff-m fw-bold my-2 mt-5 text-dark" id="14">
            Governing Law, Dispute resolution
          </div>
          <div className="pgs-text">
            <div className="">
              You and PureGenomics<sup>&reg;</sup> agree that any claim or dispute relating to our App shall be governed by the laws of England and Wales, without regard to its conflicts of laws provisions, and subject to the exclusive jurisdiction of the courts of England.
            </div>
          </div>
        </div>

        {/*15*/}
        <div>
          <div className="pgs-heading__sub ff-m fw-bold my-2 mt-5 text-dark" id="15">
            General
          </div>
          <div className="pgs-text">
            <div className="">
              These  Terms  constitute  the  entire  agreement  between  you  and  PureGenomics<sup>&reg;</sup>  with  regard  to  your  use  of  our App and Content, and supersede any and all other written or oral agreements or understandings previously existing between you and PureGenomics<sup>&reg;</sup> with respect to such use. If any of the provisions of these Terms are held by a court to be void or unenforceable, such provisions shall be modified or deleted to the extent necessary and replaced with a valid provision that best embodies the intent of these Terms, so that these Terms shall remain in full force and effect. Any failure or delay by PureGenomics<sup>&reg;</sup> in enforcing (in whole or in part) any provision of these Terms will not be interpreted as a waiver of PureGenomics<sup>&reg;</sup>’s rights or remedies. These Terms shall not be interpreted or construed to confer any rights or remedies on any third parties.
            </div>
          </div>
        </div>
        <div>
          <div className="pgs-text py-4">
            <div className="">
            Copyright©2022 Pure Encapsulations, LLC. All rights reserved.
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default TermsAndUseUK;
