import React, { Component, useEffect, useState, useRef } from "react";
import { useTable, usePagination, useSortBy, useRowSelect } from "react-table";
import { connect, useSelector, useDispatch } from "react-redux";
import { getPractitioners } from "../../../store/actions/adminPractitioner";
import { getAdminUsers } from "../../../store/actions/adminUser";
import { useNavigate } from "react-router-dom";
import AdminUserTable from "./AdminUserTable";

function AdminUserList() {
const navigate = useNavigate();
  const token = useSelector(state => state.auth.token)
  const masterAdmin = useSelector(state => state.auth.masterAdmin)
  const adminUsers = useSelector(state => state.adminUser.admin_users)
  const totalAdminUsers = useSelector(state => state.adminUser.total_count)
  const loading = useSelector(state => state.adminUser.loading_admin_users);
  const dispatch = useDispatch()
  const [selectedPractitioner, setSelectedPractitioner] = useState("");

  const updateAdminUserTable = (url = "per_page=10&page=1") => {
    dispatch(getAdminUsers(token, url));
  };

  return (
    <div className="container-fluid">
      <div className="container-xxl py-5 px-0">
        <div
          className="cursor-pointer textc-primary pg-link"
          onClick={() =>navigate("/admin/dashboard")}

        >
          <i className="fas fa-chevron-left mr-2"></i> Dashboard
        </div>
        <div className=" my-4 pg-title">Admin Users</div>
        <div>
          <AdminUserTable
            token={token}
            masterAdmin={masterAdmin}
            adminUsers={adminUsers}
            updateAdminUserTable={updateAdminUserTable}
            totalData={totalAdminUsers}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
}

export default AdminUserList;
