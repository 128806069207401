import React, { Component, useEffect, useState, useRef } from "react";
import { useTable, usePagination, useSortBy, useRowSelect } from "react-table";
import { useSelector, useDispatch } from 'react-redux';
import { getPractitioners } from "../../../store/actions/adminPractitioner";
import { useNavigate, useLocation } from "react-router-dom";
import PractitionerTable from "./PractitionerTable";
import { parse } from "query-string";

function PractitionerList({ }) {
  const navigate = useNavigate();
  const location = useLocation();

  // redux state variable

  const token = useSelector(state => state.auth.token);
  const practitioners = useSelector(state => state.adminPractitioner.practitioners) || [];

  const totalPractitioners = useSelector(state => state.adminPractitioner.total_count);
  const loading = useSelector(state => state.adminPractitioner.loading_practitioners);

  // redux actions

  const dispatch = useDispatch();
  const getPractitionersAction = (token, url) => dispatch(getPractitioners(token, url));

  const [selectedPractitioner, setSelectedPractitioner] = useState("");

  const params = parse(location.search);
  const selectedRegion = params["region"]

  const updatePatientTable = (url = "per_page=10&page=1") => {
    let updatedUrl = params["migrated"] ? (url + "&migrated=" + params["migrated"]) : url
    getPractitionersAction(token, (updatedUrl + "&region=" + selectedRegion));
  };

  return (
    <div className="container-fluid">
      <div className="container-xxl py-5 px-0">
        <div
          className="cursor-pointer textc-primary pg-link"
          onClick={() => navigate("/admin/dashboard")}
        >
          <i className="fas fa-chevron-left mr-2"></i> Dashboard
        </div>
        <div className=" my-4 pg-title">Practitioners</div>
        {/* <div className="pg-text">
        Choose a patient from the list to begin making supplement recommendations. This will not not include any supplement suggestions from the patient's report
        </div> */}

        <div>
          <PractitionerTable
            token={token}
            practitioners={practitioners}
            updatePatientTable={updatePatientTable}
            totalData={totalPractitioners}
            loading={loading}
            selectedRegion={selectedRegion}
          />
        </div>
      </div>
      {/* <div className="bgc-secondary text-center py-3">
        <button
          className="btn btn-primary text-light px-5 py-2 w-25"
          disabled={!selectedPatient}
          onClick={redirectToCatalog}
        >
          OK
        </button>
      </div> */}
    </div>
  );
}


export default PractitionerList;
