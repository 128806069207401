import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const InputWrapper = styled.div`
  margin-bottom: 18px;
`;

const Label = styled.label`
  display: inline-block;
  font-weight: bold;
  color: var(--primary);
  font-size: 18px;
`;

const RequiredSpan = styled.span`
  color: var(--primary);
  font-style: italic;
`;

const StyledInput = styled.input`
  padding: 15px;
  width: 100%;
  outline: none;
  border: ${(props) => (props.error ? "2px solid #f00" : "3px solid #acd4ff")};
  border-radius: 4px;
  height: 50px;
  transition: all 0.3s ease-in-out;

  &:focus {
    border: 2px solid #007bff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
`;

const ErrorMessage = styled.div`
  color: #f00;
  margin-top: 8px;
  opacity: ${(props) => (props.show ? 1 : 0)};
  max-height: ${(props) => (props.show ? "24px" : "0")};
  transition: opacity 0.3s ease-in-out, max-height 0.3s ease-in-out;
`;

const TextInput = React.forwardRef(function TextInput(
  {
    type,
    placeholder,
    error,
    errorMessage,
    isDisable,
    id,
    required,
    label,
    showLabel,
    optional,
    emailField,
    errors,
    ...rest
  },
  ref
) {
  const { t } = useTranslation();

  return (
    <InputWrapper>
      {showLabel && (
        <div>
          <Label htmlFor={id}>{label}</Label>
          {required && <RequiredSpan> ({t("required")})</RequiredSpan>}
          {optional && !isDisable && (
            <RequiredSpan> ({t("optional")})</RequiredSpan>
          )}
        </div>
      )}
      <div>
        <StyledInput
          ref={ref}
          placeholder={placeholder}
          type={type}
          disabled={isDisable}
          id={id}
          className={emailField && "email-input"}
          aria-invalid={errors && errors[id] ? "true" : "false"}
          aria-describedby={errors && errors[id] ? `${id}-error` : undefined}
          error={errors && errors[id]}
          {...rest} // Spread the rest of the props to ensure register works
        />
        <ErrorMessage id={`${id}-error`} show={error && errorMessage !== ""}>
          <i className="fas fa-info-circle"></i> {errorMessage}
        </ErrorMessage>
      </div>
    </InputWrapper>
  );
});

export default TextInput;
