import React, { useState, useEffect, useMemo } from "react";
import { useTable, usePagination, useSortBy, useRowSelect } from "react-table";
import { Accordion, useAccordionToggle } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

import TableFooter from "../TableFooter";
import TextInputWithIcon from "../../../components/TextInputWithIcon";
import PgCheckbox from "../../../components/PgCheckbox";
import FilterIcon from "../../../assets/filterIcon.png";
import PatientFilterComponent from "../../../components/Admin/PatientFilterComponent";
import AddPatient from "./AddPatient";
import PatientBatchFn from '../../../components/Admin/Patient/PatientBatchFn';
import L_PatientList from '../../../components/LoadingEffectComponent/L_PatientList'
import PatientInfoModal from "./PatientInfoModal";

export const PatientTable = ({
  patients,
  error,
  totalData,
  updatePatientTable,
  token,
  createdBy,
  practiceID,
  loading,
  selectedRegion
}) => {
const navigate = useNavigate();

  const [selectedPatientList, setselectedPatientList] = useState([])
  const [controlledPageCount, setControlledPageCount] = useState(1);
  const [sPageIndex, setSPageIndex] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [showFilterOption, setShowFilterOption] = useState(false);
  const [filterPending, setFilterPending] = useState(false);
  const [filterActive, setFilterActive] = useState(false);
  const [filterNoData, setFilterNoData] = useState(false)
  const [filterReportUploaded, setFilterReportUploaded] = useState(false)
  const [filterReportArchived, setFilterReportArchived] = useState(false)
  const [filterArchived, setFilterArchived] = useState(false);
  const [userStatus, setUserStatus] = useState([])
  const [reportStatus, setReportStatus] = useState([])
  const [showAddPractitionerModal, setShowAddPractitionerModal] = useState(false)
  const [recentURL, setRecentURL] = useState("")
  const [createdById, setCreatedById] = useState(createdBy)
  const [practiceId, setPracticeId] = useState(practiceID)
  const [showPatientInfoModal, setShowPatientInfoModal] = useState(false);
  const [patientValue, setPatientValue] = useState("");
  const currentUserId = useSelector((state) => state.auth.currentUserId);



  const clearFilter = () => {
    setFilterPending(false);
    //setFilterAwaiting(false);
    setFilterActive(false);
    //setFilterLocked(false);
    setFilterArchived(false);
    setFilterNoData(false)
    setFilterReportUploaded(false)
    setFilterReportArchived(false)
    setUserStatus([])
    setReportStatus([])
  };

  const closeAddPractitionerModal = () => {
    setShowAddPractitionerModal(false)
  }

  const onSelectAll = () => {
    setselectedPatientList(spl => {
      if (spl.length === patients.length)
        return [];
      else
        return patients.map(xx => xx.id);
    })
  }

  const onSelectPatient = (row) => {
    setselectedPatientList(spl => {
      if (spl.includes(row.id))
        return spl.filter(_id => _id !== row.id)
      else
        return [...spl, row.id]
    })
  }

  const [sortName, setsortName] = useState("")
  const [sortEmail, setsortEmail] = useState("")
  const [sortStatus, setsortStatus] = useState(false)
  const [sortrStatus, setsortrStatus] = useState(false);
  const [sortMember, setsortMember] = useState(false);
  const [sortLM, setsortLM] = useState(false);
  const [sortLLI, setsortLLI] = useState(false);
  const [sortAssigned, setsortAssigned] = useState(false);

  const resetSort = (field) => {
    setsortAssigned(field === "assigned" ? sortAssigned : "");
    setsortLLI(field === "last." ? sortLLI : "");
    setsortLM(field === "last" ? sortLM : "");
    setsortMember(field === "member" ? sortMember : "");
    setsortrStatus(field === "report" ? sortrStatus : "");
    setsortStatus(field === "status" ? sortStatus : "");
    setsortEmail(field === "email" ? sortEmail : "");
    setsortName(field === "name" ? sortName : "");
  }
  const manualSort = (e) => {
    const field = e.target.innerText.split(' ')[0]?.toLowerCase();
    resetSort(field)
    console.log(field);
    if (field === "email") {
      setsortEmail(sortEmail === "" ? 'DESC' : sortEmail === "DESC" ? "ASC" : "DESC")
      updatePatientTable(`${recentURL}&sort_key=email&sort_value=${sortEmail === "" ? 'DESC' : sortEmail === "DESC" ? "ASC" : "DESC"}`)
    }
    if (field === "name") {
      setsortName(sortName === "" ? 'DESC' : sortName === "DESC" ? "ASC" : "DESC")
      updatePatientTable(`${recentURL}&sort_key=pat_lname&sort_value=${sortName === "" ? 'DESC' : sortName === "DESC" ? "ASC" : "DESC"}`)
    }
    if (field === "status") {
      setsortStatus(sortStatus === "" ? 'DESC' : sortStatus === "DESC" ? "ASC" : "DESC")
      updatePatientTable(`${recentURL}&sort_key=aasm_state&sort_value=${sortStatus === "" ? 'DESC' : sortStatus === "DESC" ? "ASC" : "DESC"}`)
    }
    if (field === "report") {
      setsortrStatus(sortrStatus === "" ? 'DESC' : sortrStatus === "DESC" ? "ASC" : "DESC")
      updatePatientTable(`${recentURL}&sort_key=report_status&sort_value=${sortrStatus === "" ? 'DESC' : sortrStatus === "DESC" ? "ASC" : "DESC"}`)
    }
    if (field === "member") {
      setsortMember(sortMember === "" ? 'DESC' : sortMember === "DESC" ? "ASC" : "DESC")
      updatePatientTable(`${recentURL}&sort_key=created_at&sort_value=${sortMember === "" ? 'DESC' : sortMember === "DESC" ? "ASC" : "DESC"}`)
    }
    if (field === "last") {
      setsortLM(sortLM === "" ? 'DESC' : sortLM === "DESC" ? "ASC" : "DESC")
      updatePatientTable(`${recentURL}&sort_key=updated_at&sort_value=${sortLM === "" ? 'DESC' : sortLM === "DESC" ? "ASC" : "DESC"}`)
    }
    if (field === "last.") {
      setsortLLI(sortLLI === "" ? 'DESC' : sortLLI === "DESC" ? "ASC" : "DESC")
      updatePatientTable(`${recentURL}&sort_key=current_sign_in_at&sort_value=${sortLLI === "" ? 'DESC' : sortLLI === "DESC" ? "ASC" : "DESC"}`)
    }
    if (field === "assigned") {
      setsortAssigned(sortAssigned === "" ? 'DESC' : sortAssigned === "DESC" ? "ASC" : "DESC")
      updatePatientTable(`${recentURL}&sort_key=practitioner_lname&sort_value=${sortAssigned === "" ? 'DESC' : sortAssigned === "DESC" ? "ASC" : "DESC"}`)
    }
  }

  useEffect(() => {
    gotoPage(0);
  }, [searchText])



  const continueSort = (url) => {
    // console.log(url);
    if (sortEmail) {
      updatePatientTable(`${url}&sort_key=email&sort_value=${sortEmail}`)
    }
    else if (sortName) {
      updatePatientTable(`${url}&sort_key=pat_lname&sort_value=${sortName}`)
    }
    else if (sortStatus) {
      updatePatientTable(`${url}&sort_key=aasm_state&sort_value=${sortStatus}`)
    }
    else if (sortrStatus) {
      updatePatientTable(`${url}&sort_key=report_status&sort_value=${sortrStatus}`)
    }
    else if (sortMember) {
      updatePatientTable(`${url}&sort_key=created_at&sort_value=${sortMember}`)
    }
    else if (sortLM) {
      updatePatientTable(`${url}&sort_key=updated_at&sort_value=${sortLM}`)
    }
    else if (sortLLI) {
      updatePatientTable(`${url}&sort_key=current_sign_in_at&sort_value=${sortLLI}`)
    }
    else if (sortAssigned) {
      updatePatientTable(`${url}&sort_key=practitioner_lname&sort_value=${sortAssigned}`)
    } else
      updatePatientTable(url);
  }
  const column = [
    {
      Header: <div className="position-relative practitioner-batch-checkbox">
        <PgCheckbox
          checked={selectedPatientList.length > 0 && selectedPatientList.length === patients.length}
          onCheck={onSelectAll}
        />
      </div>,
      accessor: "id",
      sortable: false,
      sortType: () => { },
      filterable: false,
      Cell: (value) => {
        const { row } = value;
        return (
          <div className="position-relative practitioner-batch-checkbox">
            <PgCheckbox
              checked={selectedPatientList.includes(row.values.id)}
              onCheck={(e) => onSelectPatient(row.values)} />
          </div>
        );
      },
    },
    {
      Header:  `Name ${sortName === "DESC" ? ' ↓' : ' ↑'}`,
      accessor: "full_name",
      Cell: (value) => {
        var first_name = value?.row?.original?.pat_fname.substr(0, 1) + "***"
        var last_name = value?.row?.original?.pat_lname.substr(0, 1) + "***"
        return (
          <div className="pg-link" onClick={() => {
            setShowPatientInfoModal(true)
            setPatientValue(value)
          }}>
            {first_name + " "+ last_name}
          </div>
        );
      },
    },
    {
      Header:  `Email ${sortEmail === "DESC" ? ' ↓' : ' ↑'}`,
      accessor: "email",
      Cell: (value) => {
        var email = (value?.row?.original.email).split('@');
        return (
          <div className="pg-link" onClick={() => {
            setShowPatientInfoModal(true)
            setPatientValue(value)
          }}>
            {email[0].substr(0, 1) + '****@'+email[1]}
          </div>
        );
      },
    },
    { Header: `Status ${sortStatus === "DESC" ? ' ↓' : '↑'}`, accessor: "aasm_state" },
    { Header: `Report Status ${sortrStatus === "DESC" ? ' ↓' : '↑'}`, accessor: "report_status" },
    { Header: `Member Since ${sortMember === "DESC" ? ' ↓' : '↑'}`, accessor: "member_since" },
    { Header: `Last Modified ${sortLM === "DESC" ? ' ↓' : '↑'}`, accessor: "last_modified" },
    { Header: `Last. Logged In ${sortLLI === "DESC" ? ' ↓' : '↑'}`, accessor: "last_login" },
    {
      Header: `Assigned Practitioner ${sortAssigned ? ' ↓' : '↑'}`, accessor: "assigned_practitioner", Cell: (_value) => {
        const { value } = _value;
        return (
          <div className="pg-link" onClick={() => {
            setCreatedById(_value.row.original.created_by)
            setPracticeId(_value.row.original.practice_id)
          }}>
            {value}
          </div>
        );
      },
    },
  ];

  const columns = useMemo(() => column, [selectedPatientList, patients]);
  const data = useMemo(() => patients, [patients]);


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    canNextPage,
    previousPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    setPageSize,
    gotoPage,
    state: { pageIndex, pageSize },
    prepareRow,
  } = useTable(
    {
      columns,
      data,

      initialState: {
        pageIndex: sPageIndex,
        // pageSize: 3
        pageSize: 100
      },
      manualPagination: true,
      pageCount: controlledPageCount,
      autoResetSortBy: false,
      // autoResetPage: true,
      autoResetPage: false,
      disableSortRemove: true,
      defaultCanSort: true,
    },
    useSortBy,
    usePagination,
    useRowSelect,

  );

  useEffect(() => {
    setControlledPageCount(Math.ceil(totalData / pageSize));
  });

  useEffect(() => {
    let url = `per_page=${pageSize}&page=${pageIndex + 1}`;
    if (searchText.trim().length > 1) url += `&search=${searchText}`;
    if (userStatus.length > 0) url += `&aasm_state=${userStatus}`;
    if (reportStatus.length > 0) url += `&report_status=${reportStatus}`;
    if (createdById !== '') url += `&created_by=${createdById}&practice_id=${practiceId}&practitioner=${createdById}`;
    setRecentURL(url);
    setselectedPatientList([]);
    const delayDebounceFn = setTimeout(() => {
      continueSort(url);
    }, 1000)
    return () => clearTimeout(delayDebounceFn)
  }, [pageSize, pageIndex, searchText, filterPending, filterActive, filterArchived, filterNoData, filterReportUploaded, filterReportArchived, createdById]);

  const __updatePatientTable = () => {
    updatePatientTable(recentURL)
  }

  const updateSearchText = (e) => {
    console.log('e.targ', e.target.value);
    _.debounce(() => setSearchText(e.target.value), 2000, { maxWait: 10000 })()
  }
  return (
    <div className=" practitioner-table">
      <div className="mt-5 d-flex justify-content-start">
        <div className="d-flex justify-content-between" style={{ width: "22%" }}>
          <Accordion>
            <div
              className="w-100 d-flex justify-content-between px-1 px-xl-0"
            >
              <div
                className="d-flex justify-content-between  mt-2"
                style={{
                  borderBottom: showFilterOption && "0.5px solid #808080",
                }}
              >
                <CustomToggle
                  showFilterOption={showFilterOption}
                  setShowFilterOption={setShowFilterOption}
                  eventKey="0"
                />
                {(filterPending ||
                  filterActive ||
                  filterNoData ||
                  filterReportUploaded ||
                  filterReportArchived ||
                  filterArchived) &&
                  showFilterOption && (
                    <div>
                      <span
                        className="pg-link mt-2"
                        onClick={clearFilter}
                        id="reset-filter__patientlist"
                      >
                        <i
                          className="fa fa-times-circle textc-primary me-2"
                          aria-hidden="true"
                        ></i>
                        Reset
                      </span>
                    </div>
                  )}
              </div>
            </div>
            <div>
              <Accordion.Collapse eventKey="0" as="div">
                <PatientFilterComponent
                  filterPending={filterPending}
                  filterActive={filterActive}
                  filterArchived={filterArchived}
                  filterNoData={filterNoData}
                  filterReportArchived={filterReportArchived}
                  filterReportUploaded={filterReportUploaded}
                  setFilterPending={setFilterPending}
                  setFilterActive={setFilterActive}
                  setFilterArchived={setFilterArchived}
                  setFilterNoData={setFilterNoData}
                  setFilterReportArchived={setFilterReportArchived}
                  setFilterReportUploaded={setFilterReportUploaded}
                  userStatus={userStatus}
                  setUserStatus={setUserStatus}
                  reportStatus={reportStatus}
                  setReportStatus={setReportStatus}
                />
              </Accordion.Collapse>
            </div>
          </Accordion>
        </div>
        <TextInputWithIcon
          label="Practitioner Name"
          placeholder="Search Patients"
          value={searchText}
          // inputHandler={(e) => _.debounce(() => setSearchText(e.target.value),250)}
          // inputHandler={updateSearchText}
          inputHandler={(e) => setSearchText(e.target.value)}

          Icon={
            <i className="fa fa-search text-secondary" aria-hidden="true"></i>
          }
        />
        <div className="justify-content-end">
          <button
            id="add-patient-btn__patientlist"
            className="btn btn-outline px-4 ms-2"
            onClick={() => setShowAddPractitionerModal(true)}
          >
            <i className="fas fa-plus mx-1"></i> Add new Patient
          </button>
        </div>
      </div>

      <div className="my-3">
        {
          loading
            ? <L_PatientList />
            : <>
              <PatientBatchFn
                selectedPatients={selectedPatientList}
                setselectedPatientList={setselectedPatientList}
                patients={patients}
                updatePatientTable={__updatePatientTable}
              />

              <table {...getTableProps()} className="pg-table admin-patient-table my-2">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())} onClick={manualSort}>
                          {column.render("Header")}
                        </th >
                      ))}
                    </tr>
                  ))}
                </thead>
                {error && (
                  <tbody>
                    <tr>
                      <td colSpan="8">
                        <div className="w-100 text-center">
                          Unable to fetch patients list
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )}
                {patients.length < 1 && !error && (
                  <tbody>
                    <tr>
                      <td colSpan="8">
                        <div className="w-100 text-center">No Patient Found</div>
                      </td>
                    </tr>
                  </tbody>
                )}
                {patients.length > 0 && !error && (
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
              <TableFooter
                pageSize={pageSize}
                canNextPage={canNextPage}
                canPreviousPage={canPreviousPage}
                nextPage={nextPage}
                previousPage={previousPage}
                setPageSize={setPageSize}
                pageIndex={pageIndex}
                pageOptions={pageOptions}
              />
            </>
        }
      </div>


      {
        showAddPractitionerModal &&
        <AddPatient
          showModal={true}
          context="patient-list"
          selectedRegion={selectedRegion}
          onCancel={closeAddPractitionerModal}
          updatePatientTable={__updatePatientTable}
        />
      }
      {showPatientInfoModal && (
        <PatientInfoModal
          showModal={showPatientInfoModal}
          onCancel={() => setShowPatientInfoModal(false)}
          rowData={patientValue?.row?.original}
          currentUserId={currentUserId}
        />
      )}
    </div>
  );
};

export default PatientTable;

function CustomToggle({
  children,
  eventKey,
  setShowFilterOption,
  showFilterOption,
}) {
  const decoratedOnClick = useAccordionToggle(eventKey, () => { });

  return (
    <span
      className="font-weight-bold cursor-pointer my-2"
      onClick={(e) => {
        decoratedOnClick(e);
        setShowFilterOption(!showFilterOption);
      }}
    >
      <span className="pg-link">
        <img src={FilterIcon} alt="" className="me-2 mt-1" />
        Filter
      </span>
    </span>
  );
}
