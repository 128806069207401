import React, { useRef, useState } from 'react'
import IframeResizer from 'iframe-resizer-react'

export default function LoadIframe(props) {

  const iframeRef = useRef(null)

  return (
    <>
      <IframeResizer
        checkOrigin={false}
        forwardRef={iframeRef}
        heightCalculationMethod="bodyScroll"
        inPageLinks
        src={props.src}
        style={{ width: '1px', minWidth: '100%', marginTop: "25px", minHeight: "100vh" }}
      />
    </>
  )
}