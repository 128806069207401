import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Popup from "reactjs-popup";
import _ from "lodash";
import "reactjs-popup/dist/index.css";

import { getPatientDetailInReport } from "../../store/actions/patientReport";
import { sendInvites } from "../../store/actions/patientList";
import L_PatientInfo from "../../components/LoadingEffectComponent/Report/L_PatientInfo";
import { updateLabUpload } from "../../store/actions/labUpload";
import AnswersModal from "../Practice/PatientList/AnswersModal";
import LabReviewModal from "../Practice/Lab/LabReviewModal";
import PgInfoTooltip from "../../components/PgInfoTooltip";

const PatientInfo = ({ patientId, currentPatientId, t }) => {
  console.log(patientId);
  // redux state variable
  const currentPracticeID = useSelector(
    (state) => state.auth.currentPracticeID
  );
  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const token = useSelector((state) => state.auth.token);
  const userType = useSelector((state) => state.auth.userType);
  const patient = useSelector((state) => state.patientReport.patientDetail);
  const loading = useSelector(
    (state) => state.patientReport.isGettingPatientDetail
  );
  const currentReportId = useSelector(
    (state) => state.patientReport.currentReportId
  );
  const labFileStatus = useSelector((state) => state.labUpload.fileStatus);

  const dispatch = useDispatch();
  // redux actions
  const updateLabFileStatus = (data) => dispatch(updateLabUpload(data));
  const sendInvitesAction = (currentPracticeID, currentPractice, token, id) =>
    dispatch(sendInvites(currentPracticeID, currentPractice, token, id));
  const getPatientDetailInReportAction = (
    token,
    currentPracticeID,
    currentPractice,
    data
  ) =>
    dispatch(
      getPatientDetailInReport(token, currentPracticeID, currentPractice, data)
    );

  const navigate = useNavigate();
  const [showAnswersModal, setShowAnswersModal] = useState(false);
  const [showLabReviewModal, setShowLabReviewModal] = useState(false);
  const [patientDetails, setPatientDetails] = useState(<div></div>);
  const patientTextColor = {
    color:
      userType === "Patient" ? "var(--pg_primary__p)" : "var(--pg_primary)",
  };
  const [isTestPatient, setIsTestPatient] = useState(false);

  const sendQuestionnaireInvite = () => {
    sendInvitesAction(
      currentPracticeID,
      currentPractice,
      token,
      currentPatientId
    ).then((response) => {
      if (!response.error)
        // toast.error("Unable to send invites!");
        // else
        toast.success("Invites sent successfully");
    });
  };

  useEffect(() => {
    getPatientDetailInReportAction(token, currentPracticeID, currentPractice, {
      patientId: patientId,
      reportId: currentReportId,
    }).then((response) => {
      if (response.payload?.patient?.patient_type === "test")
        setIsTestPatient(true);
      setPatientDetails(() => {
        if (response.payload?.info_from_q) {
          let questionnaire_info = response.payload?.info_from_q;
          let info_array = questionnaire_info.split("</br>");
          let patient_age = info_array.shift();
          let date_of_birth = response.payload?.dob;
          if (date_of_birth == null) {
            date_of_birth = "Jul 1, 2000";
          }
          return (
            <div>
              <Popup
                on="hover"
                trigger={(open) => (
                  <span className="cursor-pointer">{patient_age}</span>
                )}
                position="top center"
                closeOnDocumentClick
                className="cp"
              >
                <div style={{ padding: "12px" }}>
                  {t("pracand_dob")}: {date_of_birth}
                </div>
              </Popup>

              <div
                dangerouslySetInnerHTML={{
                  __html: info_array.join("<br/>"),
                }}
              />
            </div>
          );
        }
      });
    });
  }, []);

  useEffect(() => {}, [patientDetails]);

  const findPatientIndex = (filestatus, patientId) => {
    return _.findIndex(filestatus, { patientId: patientId });
  };

  const goToLabUpload = (patientId, patientName, anotherFile) => {
    if (anotherFile) {
      const patientIndex = findPatientIndex(labFileStatus, patientId);
      if (patientIndex >= 0) {
        let c_fileStatus = [...labFileStatus];
        c_fileStatus = c_fileStatus.filter(
          (file) => file.patientId !== patientId
        );
        updateLabFileStatus({ fileStatus: c_fileStatus });
      }
    }
    navigate("/practice/patient/lab", { state: { patientName } });
  };

  const __showLabReportReview = () => {};
  return (
    <div className="py-5 bgc-secondary mx-0 px-0">
      {showLabReviewModal && (
        <LabReviewModal
          modalData={{
            documentIds: patient?.patient?.lab_document_ids,
            documentId: patient?.patient?.lab_session_id,
            patientId: patient?.patient?.id,
            completed: true,
            from: "report",
          }}
          showModal={showLabReviewModal}
          onCancel={() => setShowLabReviewModal(false)}
        />
      )}
      <div className="container-fluid">
        <div className="container-xxl px-0">
          {loading ? (
            <L_PatientInfo />
          ) : (
            <div className="row pg-text px-0">
              {showAnswersModal && (
                <AnswersModal
                  patientId={patientId}
                  showModal={showAnswersModal}
                  onCancel={() => setShowAnswersModal(false)}
                  isTestPatient={isTestPatient}
                />
              )}
              <div className="col-12 col-md-4 my-md-0 my-3">
                <div className="pg-heading" style={patientTextColor}>
                  {patient?.patient?.full_name}
                </div>
                {userType === "Patient" && (
                  <div className="pg-text__bold" style={patientTextColor}>
                    {t("practitioner")}: {patient?.user_name}
                  </div>
                )}
                {!_.isEmpty(patient?.info_from_q)
                  ? patientDetails
                  : // <div
                    // 	dangerouslySetInnerHTML={{ __html: patient?.info_from_q }}
                    // />
                    patient.patient?.age && (
                      <div>
                        {`${patient.patient?.gender}, `}
                        {`${patient?.patient?.age}${t("pracrs_line_2")}`}
                      </div>
                    )}
              </div>
              <div className="col-12 col-lg-2 my-lg-0 my-3">
                <div className="pg-text__bold">
                  {" "}
                  {t("pracrs_line_1")} <br />
                </div>
                {patient.genetic_vendor ? (
                  <div
                    dangerouslySetInnerHTML={{ __html: patient.genetic_vendor }}
                  />
                ) : (
                  t("pracpl_line_5")
                )}
              </div>
              {/* <div className="col-12 col-lg-3 my-lg-0 my-3">
								<div className="pg-text__bold">{t("questionnaire")} </div>
								{
									(patient.question_status === "completed" || patient?.patient?.patient_type === "test") &&
									<div
										className="pg-link"
										onClick={() => setShowAnswersModal(true)}
									>
										{t('pracpl_btn_9')}{" "}
										<i
											className="fa fa-chevron-right ms-1"
											aria-hidden="true"
										></i>
									</div>
								}
								<div style={{ textTransform: "capitalize" }}>
									{
										(patient.question_status !== "completed" &&
											patient?.patient?.patient_type !== "test") &&
										<div>
											{patient.question_status
												? patient.question_status
												: t('pracpl_line_5')}
										</div>
									}
								</div>
							</div> */}
              {/* <div className="col-12 col-lg-3 my-lg-0 my-3">
								<div className="pg-text__bold">
									{t('lab_results')} <br />
								</div>
								{patient.lab_status && patient.lab_status === "Completed" ? (
									<div className="pg-link" onClick={() => setShowLabReviewModal(!showLabReviewModal)}>
										{t('pracpl_btn_12')}{" "}
										<i
											className="fa fa-chevron-right ms-1"
											aria-hidden="true"
										></i>
									</div>
								) : (
									// <div>{patient.lab_status}</div>
									<div>
										{t('pracpl_line_5')}
									</div>
								)}

							</div> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PatientInfo;
