import ContentLoader from 'react-content-loader';

function L_GDPRActivitiesTile() {
    return (
        <ContentLoader
          speed={2}
          width="100%"
          height="20%"
          viewBox="0 0 600 710"
          backgroundColor="#9e9e9e52"
          foregroundColor="#18368f80"
        >
          {/* trait header */}
          <rect x="160" y="5" rx="3" ry="3" width="280" height="50" />
    
          <rect x="0" y="90" rx="3" ry="3" width="180" height="20" />
          <rect x="520" y="90" rx="3" ry="3" width="100" height="20" />
    
          <rect x="0" y="140" rx="3" ry="3" width="320" height="15" />
          <rect x="0" y="190" rx="3" ry="3" width="320" height="15" />
          <rect x="0" y="240" rx="3" ry="3" width="320" height="15" />
          <rect x="0" y="290" rx="3" ry="3" width="320" height="15" />
          <rect x="0" y="340" rx="3" ry="3" width="320" height="15" />
          <rect x="0" y="390" rx="3" ry="3" width="320" height="15" />

    
          <rect x="500" y="140" rx="3" ry="3" width="100" height="15" />
          <rect x="500" y="190" rx="3" ry="3" width="100" height="15" />
          <rect x="500" y="240" rx="3" ry="3" width="100" height="15" />
          <rect x="500" y="290" rx="3" ry="3" width="100" height="15" />
          <rect x="500" y="340" rx="3" ry="3" width="100" height="15" />
          <rect x="500" y="390" rx="3" ry="3" width="100" height="15" />
    
    
        </ContentLoader>
      );
}

export default L_GDPRActivitiesTile