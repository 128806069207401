import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ExternalSitePrompt from "./ExternalSitePrompt";

function HealthDatNotice() {
  const { t, i18n } = useTranslation();
  const [showExternalSiteModal, setShowExternalSiteModal] = useState(false);
  const [externalSiteURL, setExternalSiteURL] = useState("");
  return (
    <div className="my-lg-5 py-5">
      {showExternalSiteModal && (
        <ExternalSitePrompt
          showModal={showExternalSiteModal}
          externalSiteLink={externalSiteURL}
          onCancel={() => setShowExternalSiteModal(false)}
        />
      )}
      <div className="container-xxl p-lg-5 px-4 pgs-text">
        <div className="pgs-title pgs-title__xl ff-v py-4">
          Health Data Notice for Washington & Nevada Residents
        </div>
        <div className="pgs-heading__sub ff-m fw-bold text-dark fst-italic d-block my-4">
          Last Updated: April 23, 2024
        </div>

        <p className="pgs-text my-2">
          This Health Data Notice (“Health Data Notice”) governs the PureInsight™ online
          platform (“Site”), genetic analysis services, and related
          services (collectively, the “Services”) owned and operated by Pure Encapsulations,
          a Nestlé Health Science U.S. brand, or its affiliates and subsidiaries (referred to
          herein as “Pure Encapsulations,” “we,” “us,” or “our” as applicable). This Health
          Data Notice supplements our <a href="/privacy">Privacy Policy</a> and applies to “Consumer Health Data”
          subject to Washington and Nevada privacy laws (collectively, “health data privacy laws”).
        </p>

        <div className="my-4">
          <h2 className="pgs-heading__sub ff-m fw-bold text-dark">CONSUMER HEALTH DATA WE COLLECT</h2>
          <p>We collect the following categories of Consumer Health Data:</p>
          <ul>
            <li>Information about your health-related conditions, symptoms, status, diagnoses, testing, or treatments, including prescription information.</li>
            <li>Information related to your nutrition and dietary supplement intake, which may also include information concerning your dietary preferences.</li>
            <li>Information about your bodily functions, vital signs, reproductive or sexual health, weight and fitness-related goals/activities, and exercise habits/goals.</li>
            <li>Information that could identify your attempt to seek health related services.</li>
            <li>Your genetic data if you use our genetic analysis services and upload your raw genetic data file.</li>
            <li>Other information that may be used to infer or derive data related to the above or other health information.</li>
          </ul>
        </div>

        <div className="my-5">
          <h2 className="pgs-heading__sub ff-m fw-bold text-dark my-2">SOURCES OF CONSUMER HEALTH DATA</h2>
          <p>
            As described further in the “How We Collect Personal Information” section of the <a href="/privacy">Privacy Policy</a>, we collect Consumer Health Data directly from you, from your interactions with our Services, from third parties, including Affiliates (defined below), and from publicly available sources.
          </p>
        </div>

        <div className="my-5">
          <h2 className="pgs-heading__sub ff-m fw-bold text-dark my-2">WHY WE COLLECT AND USE CONSUMER HEALTH DATA</h2>
          <p>We may use the categories of Consumer Health Data described above for various purposes:</p>

          <div>
            <p>
              <b>Communicating with You.</b> We use Consumer Health Data to respond to your questions and comments when you communicate with us through links or pages in the Services, such as the “Contact Us” feature.
            </p>

            <p>
              <b>Processing Your Transactions.</b> If you purchase products from us on or through the Services, we use your Consumer Health Data to process your purchase, confirm your order, and deliver the items to you.
            </p>

            <p>
              <b>Administering the Services.</b>
              We use your Consumer Health Data to administer the Services and carry out any other business activities.
            </p>

            <p>
              <b>Providing You with Personalized Recommendations.</b> If you choose to complete assessments in the Services, we will use your Consumer Health Data to send you personalized product recommendations and other information that may be of interest to you.
            </p>

            <p>
              <b>Performing Analyses.</b> We use your Consumer Health Data to perform business analyses or for other purposes designed to improve the quality of our business and the Services we offer.
            </p>

            <p>
              <b>Preventing and Detecting Fraud and Other Crimes.</b> We use your Consumer Health Data to help prevent and detect fraud and other crimes that might be committed using or against the Services. We may use your Consumer Health Data to investigate possible violations of and enforce our contracts.
            </p>

            <p>
              <b>Managing and Operating Our IT Systems.</b> We use your Consumer Health Data to help us in troubleshooting, testing, maintaining, and protecting our IT systems, including our Sites.
            </p>

            <p>
              <b>Complying with Legal and Regulatory Obligations.</b> We use your Consumer Health Data to comply with our legal and regulatory obligations, which arise from time to time. We may also use your Consumer Health Data to protect our or others’ rights, privacy, safety or property, and/or that of our affiliates. We do this to protect our business assets and to comply with our legal obligations.
            </p>

            <p>
              <b>Creating Aggregate or Deidentified Information.</b> We use Consumer Health Data you provide to us regarding the Services to provide product feedback to our customers in deidentified or aggregated form. We may also use deidentified or aggregate Consumer Health Data to analyze general trends in the use of the Services. We will take reasonable steps to maintain and use the information in deidentified form and will not to attempt to reidentify this information. We may provide deidentified or aggregated information to third parties, our affiliates, or our service providers.
            </p>

            <p>
              <b>As Otherwise Permitted by Law or as We May Notify You.</b> We may also use Consumer Health Data you provide to us for other purposes as disclosed at the time you provide your information or otherwise with your consent.
            </p>
          </div>
        </div>

        <div className="my-5">
          <h2 className="pgs-heading__sub ff-m fw-bold text-dark ">WITH WHOM DO WE SHARE CONSUMER HEALTH DATA</h2>
          <p>As defined by health data privacy laws, we share Consumer Health Data with the categories of third parties listed below.</p>
          <div>
            <p>
              <b>
                Healthcare Providers.</b>
              When you register for an account on the Site following an invitation from your healthcare provider, we will share your Consumer Health Data including your genetic data file and any questionnaire responses, as applicable, with your provider.
            </p>

            <p>
              <b>Competent Governmental and Public Authorities.</b> We may share your Consumer Health Data with governmental authorities if we believe disclosure is necessary or appropriate to prevent physical harm or financial loss or in connection with an investigation of suspected or actual illegal activity or a violation of our Terms and Conditions (posted at the footer of our Site) or other contracts.
            </p>

            <p>
              <b>
                Third Parties Pursuant to Legal Process.</b> We may share your Consumer Health Data to the extent necessary to respond to subpoenas, court orders, or other legal process; in response to a request for cooperation from law enforcement or a government agency; or to otherwise comply with our other legal and regulatory obligations.
            </p>

            <p>
              <b>
                Other Third Parties.</b> We may share your Consumer Health Data when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, including to allow us to pursue available remedies and limit the damage we may sustain. To the extent we share your Consumer Health Data with other third parties, we will do so in accordance with health data privacy laws.
            </p>
          </div>
        </div>

        <div className="my-5">
          <h2 className="pgs-heading__sub ff-m fw-bold text-dark ">HOW TO EXERCISE YOUR RIGHTS</h2>
          <p>Health data privacy laws provide covered individuals with certain rights to access, delete, or withdraw consent relating to their Consumer Health Data, subject to certain exceptions.</p>
          <p className="break-all">
            If you would like to exercise the rights outlined above, please contact us by email at <a href="mailto:pureinsight@pureencapsulations.com">pureinsight@pureencapsulations.com</a> or
            by phone at <b>1-800-753-2277</b>. If you send a request by email, please write
            “Washington Consumer Health Data Request” or “Nevada Consumer Health Data Request”
            in the subject line and state which rights you seek to exercise in the body of your message.
            We may take steps to verify your identity before responding to your request by asking you a
            series of questions about your previous interactions with us.
          </p>
          <p className="break-all">
            If your request to exercise a right under health data privacy laws is denied,
            you may appeal that decision by emailing us at <a href="mailto:NHScLegal@us.nestle.com">NHScLegal@us.nestle.com</a>.
            If your appeal is unsuccessful, you can raise a concern or lodge a complaint
            with the Nevada or Washington State Attorney General, as applicable.
            NV (<a href="https://ag.nv.gov/Complaints/File_Complaint/" target="_blank">https://ag.nv.gov/Complaints/File_Complaint/</a> );
            WA (<a href="https://www.atg.wa.gov/file-complaint" target="_blank">https://www.atg.wa.gov/file-complaint</a> ).
          </p>
        </div>

        <div className="my-5">
          <h2 className="pgs-heading__sub ff-m fw-bold text-dark ">REVISIONS TO THIS HEALTH DATA NOTICE</h2>
          <p>We reserve the right, at our sole discretion, to change, modify, add, remove, or otherwise revise portions of this Health Data Notice at any time. When we do, we will post the change(s) on our Services. Your continued use of our products and Services following the posting of changes to these terms means you accept these changes. If we change this Health Data Notice in a material or substantive way, we will provide appropriate notice to you.</p>
        </div>

        <div className="my-5">
          <h2 className="pgs-heading__sub ff-m fw-bold text-dark ">HOW TO CONTACT US</h2>
          <p>If you have any questions or concerns, you may contact us by email at <a
            href="mailto:pureinsight@pureencapsulations.com">pureinsight@pureencapsulations.com</a>,
            by calling <b>1-800-753-2277</b>, or by mail to Pure Encapsulations, Nestlé Health Science,
            Attn: Legal, 1007 US Highway 202/206, Building JR2, Bridgewater, NJ 08807</p>
        </div>
      </div>
    </div>
  );
}

export default HealthDatNotice;