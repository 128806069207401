import CustomBadgePill from "../CustomBadgePill";
import PgCheckbox from "../PgCheckbox";
import { useTranslation } from "react-i18next";

export default function FilterComponent({
  pracTypeFilter,
  setpracTypeFilter,
  userStatus,
  setUserStatus,
}) {
  const { t, i18n } = useTranslation();
  const setStatusFilter = (status) => {
    if (status === "test" || status === "standard")
      setpracTypeFilter((opft) => {
        return opft.includes(status)
          ? opft.filter((sopft) => sopft !== status)
          : [...opft, status];
      });
    else
      setUserStatus((ous) => {
        return ous.includes(status)
          ? ous.filter((sus) => sus !== status)
          : [...ous, status];
      });
  };
  return (
    <div className="row px-3 ">
      <div className="col-12 d-flex py-5">
        <div
          className="d-inline-block"
          style={{ width: i18n.language === "fr" ? "235px" : "auto" }}
        >
          <PgCheckbox
            title={t("staff_members_table_filter_txt_1")}
            id="f-pending__practitionertlist"
            checked={userStatus.includes("pending")}
            onCheck={() => setStatusFilter("pending")}
          />
        </div>
        <div
          className="d-inline-block"
          style={{ width: i18n.language === "fr" ? "235px" : "auto" }}
        >
          <PgCheckbox
            title={t("staff_members_table_filter_txt_2")}
            id="f-active__practitionertlist"
            checked={userStatus.includes("active")}
            onCheck={() => setStatusFilter("active")}
          />
        </div>
        <div
          className="d-inline-block"
          style={{ width: i18n.language === "fr" ? "235px" : "auto" }}
        >
          <PgCheckbox
            title={t("staff_members_table_filter_txt_3")}
            id="f-archived__practitionertlist"
            checked={userStatus.includes("archived")}
            onCheck={() => setStatusFilter("archived")}
          />
        </div>
      </div>
    </div>
  );
}
