import * as pd_constant from "../actions/practiceDashboard";

const initialState = {
    loading: true,
    error: false,
    content:null,
    supplement_recommendations: null,
    patient_list: [],
    staffs: [],
    vd_path: ""
};


const practiceDasboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case pd_constant.PRACTICE_DASHBOARD_REQUEST: {
            return { ...state, loading: true };
        }
        case pd_constant.PRACTICE_DASHBOARD_SUCCESS: {
            return { ...state, loading: false ,content:action.payload};
        }        
        case pd_constant.PRACTICE_WHATSNEW_SUCCESS: {
            return { ...state, loading: false };
        }
        case pd_constant.PRACTICE_DASHBOARD_FAILURE: {
            return { ...state, loading: true, error: true };
        }
        case pd_constant.PRACTICE_ACTIVITY_SUCCESS: {
            return { ...state, supplement_recommendations:action.payload};
        }
        case pd_constant.PRACTICE_ACTIVITY_FAILURE: {
            return { ...state, error: false, supplement_recommendations: null };
        }
        case pd_constant.PRACTICE_MANAGE_DATA_REQUEST: {
            return { ...state, loading: true };
        }
        case pd_constant.PRACTICE_MANAGE_DATA_SUCCESS: {
            return { ...state };
        }
        case pd_constant.PRACTICE_MANAGE_DATA_FAILURE: {
            return { ...state, error: true};
        }
        case pd_constant.PRACTICE_GDPR_REQUEST_LOG_REQUEST: {
            return { ...state, loading: true };
        }
        case pd_constant.PRACTICE_GDPR_REQUEST_LOG_SUCCESS: {
            return { ...state};
        }
        case pd_constant.PRACTICE_GDPR_REQUEST_LOG_FAILURE: {
            return { ...state, error: true };
        }
        case pd_constant.PRACTICE_GET_PATIENT_LIST_REQUEST: {
            return { ...state, loading: true };
        }
        case pd_constant.PRACTICE_GET_PATIENT_LIST_SUCCESS: {
            return { ...state, patient_list: action.payload.patient_list } ;
        }
        case pd_constant.PRACTICE_GET_PATIENT_LIST_FAILURE: {
            return { ...state, error: true };
        }
        case pd_constant.PRACTICE_DELETE_PATIENTS_REQUEST: {
            return { ...state, loading: true };
        }
        case pd_constant.PRACTICE_DELETE_PATIENTS_SUCCESS: {
            return { ...state } ;
        }
        case pd_constant.PRACTICE_DELETE_PATIENTS_FAILURE: {
            return { ...state, error: true };
        }
        case pd_constant.RIALTO_TERMS_SUCCESS: {
            return {...state, vd_path: "/pure_vd"}
        }
        case pd_constant.PRACTICE_GET_PATIENT_LIST_REQUEST: {
            return { ...state, loading: true };
        }
        case pd_constant.PRACTICE_GET_PATIENT_LIST_SUCCESS: {
            return { ...state, staffs: action.payload.staffs, loading: false } ;
        }
        case pd_constant.PRACTICE_GET_PATIENT_LIST_FAILURE: {
            return { ...state, error: true, loading: true};
        }
        
        default:
            return state;
    }
};

export default practiceDasboardReducer;
