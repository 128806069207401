import { Modal } from 'react-bootstrap'
import _ from 'lodash'

function TraitDiagramModel({ show, onClose, diagramArray = [], isUrl, userType }) {
    const patientTextColor = { color: userType === "Patient" ? "var(--pg_primary__p)" : 'var(--pg_primary)' }
    return (
        <Modal
            centered
            size="xl"
            show={show}
            onHide={onClose}
            bsPrefix="modal" contentClassName="border-radius">
            {/* <Modal.Header >
            </Modal.Header> */}
            <div className="d-flex justify-content-end w-100 " style={patientTextColor}>
                <i className="far fa-times-circle cursor-pointer m-3 fs-2" onClick={onClose}></i>
            </div>
            <div className="p-3">
                {_.isArray(diagramArray) && diagramArray?.length > 0 ?
                    diagramArray.map(diagram => {
                        return isUrl ?
                            <div className="text-center ">
                                <img src={diagram} alt="" style={{ maxWidth: '100%' }} />
                            </div>
                            :
                            <div className="text-center ">
                                <img src={`data:image/png;base64,${diagram}`} alt="" style={{ maxWidth: '100%' }} />
                            </div>
                    })
                    : <div className="w-100 text-center pg-text__bold">
                        No Data
                    </div>
                }
            </div>
        </Modal>
    )
}

export default TraitDiagramModel
