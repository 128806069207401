import React, { useState } from "react";
import TextInput from "../TextInput";
import TextArea from "../TextArea";
import { PgRadioButton } from "../PgRadioButton"
import { validateEmail } from "../../methods";
import { contactUsForm } from "../../store/actions/auth";
import { useDispatch } from "react-redux";
import Toaster from "react-hot-toast";
import { Spinner } from "react-bootstrap";
import MapDesktop from "../../assets/map-desktop.jpg";
import MapMobile from "../../assets/map-mobile.jpg";
import { useTranslation } from "react-i18next";
import MapCanadaDesktop from "../../assets/pure-canada-desk.png"
import MapCanadaMobile from "../../assets/pure-canada-mobile.png"
import ButtonWithLoader from "../ButtonWithLoader";
import AccoutCreated from "../../assets/static/created.svg";

const Map = ({ text }) => <div>{text}</div>;

function ContactUs() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [formSubmitted, setformSubmitted] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [userType, setUserType] = useState("");
  const [otherUserType, setOtherUserType] = useState("");
  const [message, setMessage] = useState(t('contact_support_txt'));
  const [inputNameError, setInputNameError] = useState(false);
  const [inputNumberError, setInputNumberError] = useState(false);
  const [inputEmailError, setInputEmailError] = useState(false);
  const [inputMessageError, setInputMessageError] = useState(false);
  const [userTypeError, setUserTypeError] = useState(false);
  const [otherUserTypeError, setOtherUserTypeError] = useState(false);
  const [loading, setLoading] = useState(false);


  let handleSubmit = (e) => {
    e.preventDefault();
    setInputNameError(false);
    setInputNumberError(false);
    setInputEmailError(false);
    setUserTypeError(false);
    setInputMessageError(false);
    setOtherUserTypeError(false);
    setLoading(false);

    if (name.length === 0) setInputNameError(true);
    if (phone.length === 0) setInputNumberError(true);
    if (email.length === 0 || !validateEmail(email)) setInputEmailError(true);
    if (message.length === 0) setInputMessageError(true);
    if (userType.length === 0) setUserTypeError(true);
    if (userType === "Other" && otherUserType.length === 0) setOtherUserTypeError(true);
    if (
      email.length !== 0 &&
      validateEmail(email) &&
      name.length !== 0 &&
      phone.length !== 0 &&
      message.length !== 0 &&
      userType.length !== 0 &&
      (userType === "Other" ? otherUserType.length !== 0 : true)
    ) {
      submitForm();
    }
  };

  // redux actions
  const contactUsFormAction = (data) => dispatch(contactUsForm(data));

  const submitForm = () => {
    const data = {
      contact_details: {
        email: email,
        name: name,
        phone: phone,
        message: message,
        user_type: userType,
        other_user_type: otherUserType
      }
    };
    setLoading(true);
    contactUsFormAction(data).then((res) => {
      setLoading(false);
      if (!res.error) {
        setformSubmitted(true);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        Toaster.success(t('contact_msg_1'));
      }
    });
  };

  return (
    <div className="my-5">
      {!formSubmitted ? <div className="container-xxl">
        <div className="mx-1 mx-lg-5">
          <div className="pgs-title ff-v my-2 my-lg-3">{t('footer_link_4')}</div>
          <div className="pgs-text">
            <span dangerouslySetInnerHTML={{ __html: t('contact_address') }} />
          </div>

          <div className="pgs-heading text-center mt-5 textc-primary">
            {t('contact_map_title')}
          </div>
          <br />
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <a href={t('map_location')} target="_blank">
                <img
                  src={t('contact_map_url_d')}
                  alt="Map of US location"
                  className="map map--mobile w-100 d-none d-lg-block"
                />
                <img
                  src={t('contact_map_url_m')}
                  alt="Map of US location"
                  className="map map--desktop w-100 d-lg-none"
                />
              </a>
            </div>
          </div>

          <br />

          <div className="row justify-content-center mt-5">
            <div className="col-12 col-lg-6">
              <form onSubmit={handleSubmit}>
                <TextInput
                  type="text"
                  label={t('name')}
                  placeholder={t('contact_name_ph')}
                  inputHandler={(e) => setName(e.target.value)}
                  error={inputNameError}
                  errorMessage={t('contact_name_req')}
                  id="name-input"
                  showLabel
                  required
                />
                <TextInput
                  type="number"
                  label={t('prac_register_phone')}
                  placeholder={t('prac_reg_phone')}
                  inputHandler={(e) => setPhone(e.target.value)}
                  error={inputNumberError}
                  errorMessage={t('contact_pn_req')}
                  id="number-input"
                  showLabel
                  required
                />
                <TextInput
                  type="email"
                  label={t('pracand_email')}
                  emailField={true}
                  placeholder={t('contact_emal_ph')}
                  inputHandler={(e) => setEmail(e.target.value.toLowerCase())}
                  error={inputEmailError}
                  errorMessage={t('contact_emal_req')}
                  id="email-input"
                  showLabel
                  required
                />
                <div className="d-inline-block pg-text__bold textc-primary">
                  {t('contact_radio_ph')}
                </div>
                <div style={{ marginLeft: "5%" }}>
                  <PgRadioButton
                    id="radio-patient_type"
                    name="userType"
                    value="patient"
                    title={t('patient')}
                    onCheck={() => {
                      setUserType("Patient");
                      setOtherUserType("");
                    }}
                  />
                  <PgRadioButton
                    id="radio-user_type"
                    name="userType"
                    value="practitioner"
                    title={t('practitioner')}
                    onCheck={() => {
                      setUserType("Practitioner");
                      setOtherUserType("");
                    }}
                  />
                  <PgRadioButton
                    id="radio-other_type"
                    name="userType"
                    value="other"
                    title={t('contact_radio_other')}
                    onCheck={() => {
                      setUserType("Other");
                    }}
                  />
                </div>
                <div
                  className="text-danger my-1 transition-all mx-1"
                  style={userTypeError ? { opacity: 1 } : { opacity: 0 }}
                >
                  <i className="fas fa-info-circle pr-2"></i> {t('pracand_genetic_error')}
                </div>
                {userType === "Other" && (
                  <TextInput
                    label={t('contact_other_lb')}
                    showLabel={true}
                    id="radio-other_type_name"
                    placeholder={t('contact_other_ph')}
                    error={otherUserTypeError}
                    errorMessage={t('contact_other_err')}
                    required
                    inputHandler={(e) => {
                      setOtherUserType(e.target.value);
                    }}
                  />
                )}
                <TextArea
                  label={t('contact_msg_lb')}
                  placeholder={t('contact_msg_ph')}
                  inputHandler={(e) => setMessage(e.target.value)}
                  error={inputMessageError}
                  value={message}
                  errorMessage={t('contact_msg_err')}
                  row={5}
                  col={50}
                  showLabel
                  required
                  className="pg-text__small"
                />
                <ButtonWithLoader
                  className="btn btn-primary w-100"
                  type="submit"
                  loading={loading}
                  spinnerClass="text-white"
                >
                  {t('contact_btn_txt')}
                </ButtonWithLoader>
              </form>
            </div>
          </div>
        </div>
      </div> :

        <div className="row justify-content-center">
          <div className="col-12 col-lg-8">
            <div className="text-center">
              <img src={AccoutCreated} height="160" width="160" />
            </div>
            {/* <span dangerouslySetInnerHTML={{ __html: t('prac_register_message') }}></span> */}
            <div className="my-3">
              <div className="pgs-title text-center">
                Form Submitted!
              </div>
              <div>
                <div className="pgs-heading__sub text-center my-4">
                  Thank you for contacting us. We will be in touch shortly.
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default ContactUs;