import React from 'react'
import Header from '../components/Practice/Header'
import { Outlet, useLocation } from 'react-router-dom';
export default function PracticeLayout(props) {
    const location = useLocation()
    return (
        <>
            <Header />
            <div className="mt-5 pt-5">
                <Outlet />
            </div>
        </>
    )
}
