import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import FilePicker from "../../../components/Patient/FilePicker";

const AfterLabFilesSelected = ({
  labData,
  removeSelectedFile,
  onStartUpload,
  addAnotherFile,
}) => {
  const {t}=useTranslation();
  return (
    <div className="row justify-content-center">
      <div className="col-12 col-md-6 col-lg-4 py-5">
        <div className="pg-heading ">
          {t('lab_and_genetic_line_7')} {labData.files.length} {t('lab_and_genetic_line_8')}
        </div>
        <div className="pg-text my-5">
          {[...labData.files].map((file, i) => {
            return (
              <div key={i}>
                <hr />
                <div
                  className="d-flex justify-content-between my-3 px-1"
                  key={i}
                >
                  <div className="pg-text">
                    <i className="fas fa-file-alt me-3 text-secondary" />
                    {file.name}
                  </div>
                  <i
                    className="fa fa-times-circle text-secondary my-auto"
                    aria-hidden="true"
                    onClick={() => removeSelectedFile(i)}
                  ></i>
                </div>
              </div>
            );
          })}
          <hr />
        </div>
        <div className="my-5">
          <FilePicker
          onChange={addAnotherFile}
          label={t('lab_and_genetic_btn_1')}
          type={2}
          acceptFiles=".pdf"
          />
          <span style={onlyAccepts}>{t('lab_and_genetic_line_6')}</span>
        </div>
        <div className="my-4">
          <button
            className="btn btn-primary w-100"
            onClick={onStartUpload}
          >
            {t('pracpl_btn_7')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AfterLabFilesSelected;

const onlyAccepts = {
  marginTop: 30,
  color: "#888888",
  fontFamily: "Mulish",
  fontSize: 12,
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "left",
};
