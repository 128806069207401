import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

function TextQuestion(props) {
  const textQuestion = props.question;
  const showSpinner = props.showSpinner;
  const isEdit = props.isEdit;
  const answer = props.editAnswerText
  const [patientAnswer, setPatientAnswer] = useState("");
  const [showOK, setShowOK] = useState(false);
  const lang = useSelector((state) => state.auth.language)
  const language = (lang === undefined ? "en" : lang)
  

  useEffect(() => {
    if(isEdit){
      setPatientAnswer(answer[0])
      setShowOK(true)
    }
  }, []);

  const setAnswer = (value) => {
    setPatientAnswer(value);
    value.length > 1 ? setShowOK(true) : setShowOK(false);
  };

  const submitTextAnswer = (index, question, answer) => {
    textQuestion.answer = answer;
    props.addtextAnswer(index, question, answer);
    setShowOK(false);
  };

  return (
    <div>
      <div
        className="talk-bubble tri-right round border right-top"
        style={{ position: "relative" }}
      >
        <div className="talktext">
          <p className="pg-text">
            {
              ("question_" + language) in textQuestion ? textQuestion["question_" + language] : textQuestion.question 
            }
          </p>
        </div>
      </div>
      <div>
        {!("answer" in textQuestion) && (
          <div>
            <div className="question-support-text">
              <p>{textQuestion["instructions_" + language]}</p>
            </div>
            <input
              type="text"
              placeholder="Type your answer here"
              value={patientAnswer}
              onChange={(e) => setAnswer(e.target.value)}
              className="talk-bubble tri-right border round btm-left-in text-box"
            />
          </div>
        )}
        {showSpinner && (
          <div
            className="spinner-border textc-primary text-center mx-3 my-1 questionnaire-spinner"
            role="status"
            style={{ height: 15, width: 15 }}
          >
            {" "}
          </div>
        )}
        {!("answer" in textQuestion) && (
          <div className="submit-wrapper" style={{ position: "relative" }}>
            {showOK && ( <button
              className="submit talk-bubble border round btm-left-in"
              onClick={() =>
                submitTextAnswer(props.index, textQuestion, patientAnswer)
              }
              value="ok"
            >
              OK
            </button>)}
          </div>
        )}
        <div>
          {"answer" in textQuestion && (
            <div className="talk-bubble tri-right border round btm-left-in answer-bubble">
              <div className="talktext">
                <p className="pg-text">{textQuestion.answer}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TextQuestion;
