import * as pl_constant from "../actions/patientList.js";

const initialState = {
  loading: true,
  error: false,
  pageSize: 10,
  currentPage: 0,
  pageCount: 1,
  totalData: null,
  patient: [],
  isGettingPatientPastReport: true,
  patientPastReport: [],
  isGettingPatientListForCatalog: false,
  patientListForCatalog: [],
  totalCatalogData: null,
  isGettingPatientDetail: true,
  patientDetails: ''
};

const patientListReducer = (state = initialState, action) => {
  switch (action.type) {
    case pl_constant.UPDATE_PATIENT_LIST: {
      return { ...state, ...action.payload };
    }

    case pl_constant.PATIENT_LIST_REQUEST: {
      return { ...state, loading: true };
    }

    case pl_constant.PATIENT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        patient: action.payload.patients,
        totalData: action.payload.total,
        error: false,
      };
    }
    case pl_constant.PATIENT_LIST_FAILURE: {
      return { ...state, loading: false, error: true };
    }

    // for past report
    case pl_constant.GET_PATIENT_PAST_REPORT_REQUEST: {
      return { ...state, isGettingPatientPastReport: true, patientPastReport: [] };
    }
    case pl_constant.GET_PATIENT_PAST_REPORT_SUCCESS: {
      return {
        ...state,
        isGettingPatientPastReport: false,
        patientPastReport: action.payload.past_reports_data,
      };
    }
    case pl_constant.GET_PATIENT_PAST_REPORT_FAILURE: {
      return { ...state, isGettingPatientPastReport: false };
    }

    // for catalog
    case pl_constant.PATIENT_LIST_FOR_CATALOG_REQUEST: {
      return { ...state, isGettingPatientListForCatalog: true };
    }
    case pl_constant.PATIENT_LIST_FOR_CATALOG_SUCCESS: {
      return {
        ...state,
        isGettingPatientListForCatalog: false,
        patientListForCatalog: action.payload.patients,
        totalCatalogData: action.payload.total,
      };
    }
    case pl_constant.PATIENT_LIST_FOR_CATALOG_FAILURE: {
      return { ...state, isGettingPatientListForCatalog: false, error: true };
    }

    // email resend
    case pl_constant.RESEND_INVITE_REQUEST: {
      return { ...state, };
    }
    case pl_constant.RESEND_INVITE_SUCCESS: {
      return { ...state, };
    }
    case pl_constant.RESEND_INVITE_FAILURE: {
      return { ...state, };
    }

    //patient detail
    case pl_constant.GET_PATIENT_DETAILS_REQUEST: {
      return { ...state, isGettingPatientDetail: true, patientDetail: '' };
    }
    case pl_constant.GET_PATIENT_DETAILS_SUCCESS: {
      return {
        ...state,
        isGettingPatientDetail: false,
        patientDetail: action.payload.patient,
      };
    }
    case pl_constant.GET_PATIENT_DETAILS_FAILURE: {
      return { ...state, isGettingPatientDetail: false };
    }

    //edit patient
    case pl_constant.EDIT_PATIENT_REQUEST: {
      return { ...state };
    }
    case pl_constant.EDIT_PATIENT_SUCCESS: {
      return { ...state };
    }
    case pl_constant.EDIT_PATIENT_FAILURE: {
      return { ...state };
    }

    case pl_constant.SET_PAGE_SIZE: {
      return { ...state, pageSize: action.count };
    }
    default:
      return state;
  }
};

export default patientListReducer;
