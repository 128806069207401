import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NordicSection } from "./PG4/NordicSection";
import ExternalSitePrompt from "../StaticPages/ExternalSitePrompt";
import useLanguageConditions from "../../hooks/useLanguageConditions";

export function Ancestryand23andMe({ userType }) {
  const { t, i18n } = useTranslation();
  const { isCA, isUK, isUS } = useLanguageConditions();

  const [patientConsent, setPatientConsent] = useState(false);
  const enableNordic = process.env.REACT_APP_ENABLE_NORDIC;

  const [showExternalSiteModal, setShowExternalSiteModal] = useState(false);
  const [externalSiteURL, setExternalSiteURL] = useState("");

  return (
    <div className="container-fluid px-0">
      {showExternalSiteModal && (
        <ExternalSitePrompt
          showModal={showExternalSiteModal}
          externalSiteLink={externalSiteURL}
          onCancel={() => setShowExternalSiteModal(false)}
        />
      )}

      {enableNordic === "true" && (isCA || isUK) && (
        <div
          className="row my-5 tet"
          style={{ borderTop: "1px solid #bdbcbc" }}
        >
          <div className="col-12 col-lg-6 my-5 my-lg-0">
            <div
              className="pg-heading mt-5 mb-3"
              style={{ color: userType === "Patient" ? "#2470C2" : "" }}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: t("patient_snp_nordic_h1"),
                }}
              />
            </div>
            <div className="row">
              <div className="col-12 col-lg-9">
                <div
                  class="pg-text my-4"
                  dangerouslySetInnerHTML={{
                    __html: t("patient_snp_nordic_t2"),
                  }}
                />
              </div>
              <div className="col-12 col-lg-7 mt-5">
                <button
                  type="button"
                  className="btn btn-secondary textc-primary w-100"
                  onClick={() => {
                    window.open(process.env.REACT_APP_NORDIC_URL, "_blank");
                  }}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("patient_snp_nordic_btn_txt_1"),
                    }}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="row my-5">
        <div className="col-12 col-lg-6 my-5 my-lg-0">
          {!isUK && <hr />}
          <div
            className="pg-heading mt-5 mb-3"
            style={{ color: userType === "Patient" ? "#2470C2" : "" }}
          >
            <span dangerouslySetInnerHTML={{ __html: t("23_and_me_spr") }} />
          </div>
          <div className="row">
            <div className="col-12 col-lg-9">
              {userType === "User" && i18n.language === "en" ? (
                <span>
                  <div className="pg-text my-4">
                    If your patient is not a 23andMe<sup>&reg;</sup> customer
                    and wishes to purchase a kit, send them a link to visit
                    www.23andMe.com to purchase a kit.
                  </div>
                </span>
              ) : (
                <span
                  dangerouslySetInnerHTML={{ __html: t("patient_anc_txt1") }}
                />
              )}
            </div>
            <div className="col-12 col-lg-6">
              <button
                type="button"
                className="btn btn-secondary textc-primary w-100"
                onClick={() => {
                  setExternalSiteURL(t("23andMe_init_url"));
                  setShowExternalSiteModal(true);
                }}
              >
                <span
                  dangerouslySetInnerHTML={{ __html: t("patient_anc_txt2") }}
                />
              </button>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 my-5 my-lg-0">
          {!isUK && <hr />}
          <div
            className="pg-heading mt-5 mb-3"
            style={{ color: userType === "Patient" ? "#2470C2" : "" }}
          >
            <span dangerouslySetInnerHTML={{ __html: t("ancestry_spr") }} />
          </div>

          <div className="row">
            <div className="col-12 col-lg-9">
              {userType === "User" && i18n.language === "en" ? (
                <span>
                  <div className="pg-text my-4">
                    If your patient is not an Ancestry<sup>&reg;</sup> customer
                    and wishes to purchase a kit, send them a link to visit
                    www.ancestry.com to purchase a kit.
                  </div>
                </span>
              ) : (
                <span
                  dangerouslySetInnerHTML={{ __html: t("patient_anc_txt3") }}
                />
              )}
            </div>
            <div className="col-12 col-lg-6">
              <button
                type="button"
                className="btn btn-secondary textc-primary w-100"
                onClick={() => {
                  setExternalSiteURL(t("ancestry_init_url"));
                  setShowExternalSiteModal(true);
                }}
              >
                <span
                  dangerouslySetInnerHTML={{ __html: t("patient_anc_txt4") }}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      {patientConsent && (
        <NordicSection
          patientConsent={patientConsent}
          setPatientConsent={setPatientConsent}
        />
      )}
    </div>
  );
}
