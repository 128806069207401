import React from "react";
import Tab from "../../../assets/static/dpg_9.png";
import Insights from "../../../assets/02_icon_insights.svg";
import Credibility from "../../../assets/03_icon_credibility.svg";
import Education from "../../../assets/04_icon_education.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// rebranding
import HTGS_Image1 from '../../../assets/pg4/rebranding/HTGS_Image1.jpg'


function HowToGetStarted() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="container-xxl mt-5 pt-lg-5 px-0 px-md-2">
      <div className="row justify-content-around mt-5">
        <div className="col-lg-6 col-11">
          <div className="pgs-title pgs-title__xl my-3">
            <span dangerouslySetInnerHTML={{ __html: t('get_started_main_header') }} />
          </div>
          <div className="d-md-block d-lg-none">
            <img className="img-fluid" src={i18n.language === "en_gb" ? Tab : HTGS_Image1} alt=" with Laptop" />
          </div>
          <div className="pgs-heading ff-m d-block mt-4 textc-primary fw-normal">
            <span dangerouslySetInnerHTML={{ __html: t('getstartedPrac_h2') }} />
          </div>
          <ol type="1" className="mt-4">
            <li className="pgs-text">
              <span dangerouslySetInnerHTML={{ __html: t('getstartedPrac_l1') }} />
            </li>
            <li className="pgs-text">
              {t('get_started_list_2')}
            </li>
          </ol>
          <button
            className="d-none d-lg-block btn btn-primary mt-4 w-75 p-4"
            onClick={() => navigate("/hcp/registration")}
          >
            {t('get_started_today_btn')}
          </button>
          <button
            className="d-md-block d-lg-none btn btn-primary mt-4 w-100 p-4"
            onClick={() => navigate("/hcp/registration")}
          >
            {t('get_started_today_btn')}
          </button>

          <div className="mt-5 mb-5 pt-5 ">
            <div className="pgs-heading textc-primary fw-bold">
              <span dangerouslySetInnerHTML={{ __html: t('getstartedPrac_row2_h1') }} /> :
            </div>
          </div>
        </div>
        <div className="d-none d-lg-block col-lg-6 mb-5">
          <img className="img-fluid w-100" src={i18n.language === "en_gb" ? Tab : HTGS_Image1 } alt="Women with laptop" />
        </div>
      </div>

      {/* section 2 */}
      <div className="row justify-content-lg-around mt-3 justify-content-center">
        <div className="col-11 col-lg-3">
          <img src={Insights} alt="insights" className="mb-3" />
          <span dangerouslySetInnerHTML={{ __html: t('get_started_section_1') }} />
        </div>
        <div className="col-11 col-lg-3">
          <img src={Credibility} alt="credibility" className="mb-3" />
          <span dangerouslySetInnerHTML={{ __html: t('get_started_section_2') }} />
        </div>
        <div className="col-11 col-lg-3">
          <img src={Education} alt="education" className="mb-3" />
          <div className="mb-2 mb-lg-4 pgs-heading__sub ff-m fw-bold d-block">
            {t('get_started_section_3')}
          </div>
          <div className="pgs-text mb-5">
            <span dangerouslySetInnerHTML={{ __html: t('getstartedPrac_row2_l3') }} />
          </div>
        </div>
      </div>
      <div className="m-5 p-2"></div>
    </div>
  );
}

export default HowToGetStarted;
