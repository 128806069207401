import React from "react"
import ContentLoader from "react-content-loader"

const L_MSQQuestions = (props) => (
    <div>
        <div className="pg4-card">
            <ContentLoader
                speed={2}
                width={"100%"}
                height={180}
                // viewBox={`0 0 600 200`}
                backgroundColor="#f5f5f5"
                foregroundColor="#2470c210"
                {...props}
            >
                <rect x="0" y="0" rx="12" ry="12" width="90%" height="40px" />
                <rect x="0" y="60" rx="12" ry="12" width="40%" height="30px" />
                <rect x="0" y="120" rx="12" ry="12" width="50px" height="40px" />
            </ContentLoader>
        </div>
        <div className="pg4-card">
            <ContentLoader
                speed={2}
                width={"100%"}
                height={180}
                // viewBox={`0 0 600 200`}
                backgroundColor="#f5f5f5"
                foregroundColor="#2470c210"
                {...props}
            >
                <rect x="0" y="0" rx="12" ry="12" width="90%" height="40px" />
                <rect x="0" y="60" rx="12" ry="12" width="40%" height="30px" />
                <rect x="0" y="120" rx="12" ry="12" width="50px" height="40px" />
                <rect x="80" y="120" rx="12" ry="12" width="50px" height="40px" />
            </ContentLoader>
        </div>
        <div className="pg4-card">
            <ContentLoader
                speed={2}
                width={"100%"}
                height={410}
                // viewBox={`0 0 600 200`}
                backgroundColor="#f5f5f5"
                foregroundColor="#2470c210"
                {...props}
            >
                <rect x="0" y="0" rx="12" ry="12" width="90%" height="40px" />
                <rect x="0" y="60" rx="12" ry="12" width="40%" height="30px" />

                <rect x="0" y="120" rx="12" ry="12" width="30%" height="30px" />
                <rect x="0" y="160" rx="12" ry="12" width="85%" height="40px" />

                <rect x="0" y="220" rx="12" ry="12" width="30%" height="30px" />
                <rect x="0" y="260" rx="12" ry="12" width="85%" height="40px" />

                <rect x="0" y="320" rx="12" ry="12" width="30%" height="30px" />
                <rect x="0" y="360" rx="12" ry="12" width="85%" height="40px" />
            </ContentLoader>
        </div>
    </div>
)

export default L_MSQQuestions

