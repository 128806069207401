import React from "react";

const Implications = ({ data, userType, t }) => {
  const patientTextColor = { color: userType === "Patient" ? "var(--pg_primary__p)" : 'var(--pg_primary)' }
  return (
    <div className="">
      <hr className="my-5" />
      <div className="my-5">
        <div className="pg-heading my-2" style={patientTextColor}>{t('gene_analysis_th_3')}</div>
        <div className="pg-text my-2">{data}</div>
      </div>
    </div>
  );
};


export default Implications;
