import ContentLoader from "react-content-loader";
function L_EditPractitioner() {
    return (
        <ContentLoader
            speed={2}
            width="100%"
            // height="20%"
            viewBox="0 0 600 900"
            backgroundColor="#9e9e9e52"
            foregroundColor="#18368f80"
        >
            <rect x="14" y="20" rx="3" ry="3" width="160" height="20" />
            <rect x="14" y="50" rx="3" ry="3" width="550" height="40" />

            <rect x="14" y="130" rx="3" ry="3" width="160" height="20" />
            <rect x="14" y="160" rx="3" ry="3" width="550" height="40" />

            <rect x="14" y="240" rx="3" ry="3" width="160" height="20" />
            <rect x="14" y="270" rx="3" ry="3" width="550" height="40" />

            <rect x="14" y="350" rx="3" ry="3" width="160" height="20" />
            <rect x="14" y="380" rx="3" ry="3" width="550" height="40" />

            <rect x="14" y="460" rx="3" ry="3" width="160" height="20" />
            <rect x="14" y="490" rx="3" ry="3" width="550" height="40" />

            <rect x="14" y="560" rx="3" ry="3" width="160" height="20" />
            <rect x="14" y="590" rx="3" ry="3" width="550" height="40" />

            <rect x="14" y="660" rx="3" ry="3" width="160" height="20" />
            <rect x="14" y="690" rx="3" ry="3" width="550" height="40" />
            
            <rect x="14" y="760" rx="3" ry="3" width="160" height="20" />
            <rect x="14" y="790" rx="3" ry="3" width="550" height="40" />

        </ContentLoader>
    )
}

export default L_EditPractitioner
