export const ColorsCircle = ({ focusAreas = [], data = [] }) => {
  const circles = focusAreas
    ?.filter((fa) => {
      return data?.includes(fa.key);
    })
    ?.map((afa, index) => {
      return <span key={index} className={`circle circle-${afa.key}`}></span>;
    });

  return <div>{circles.length > 0 ? circles : ""}</div>;
};
