import Popup from "reactjs-popup";
import i_blue from "../assets/i_blue.png";
import { ReactComponent as InfoIcon } from "../assets/info.svg";

const PgInfoTooltip = ({ children, position = "left center", ...props }) => {
  return (
    <Popup
      on="hover"
      trigger={(open) => (
        <InfoIcon
          stroke="var(--primary)"
          height={30}
          width={30}
          className="cursor-pointer"
          style={{
            marginLeft: "4px",
            cursor: "pointer",
          }}
        />
      )}
      className={props.className}
      contentStyle={{
        height: "fit-content",
        width: "200px",
        ...(props.style && props.style),
      }}
      position={position}
      closeOnDocumentClick
    >
      <div style={props.style}>{children}</div>
    </Popup>
  );
};

export default PgInfoTooltip;
