import ContentLoader from "react-content-loader";
function L_PG4Dashboard(props) {
    return (
        <div>
            <div className="container">
                <ContentLoader
                    speed={2}
                    width="100%"
                    height="20%"
                    viewBox="0 0 600 180"
                    backgroundColor="#f5f5f5"
                    foregroundColor="#2470c210"
                    {...props}
                >
                    {/* trait header */}
                    <rect x="0" y="5" rx="3" ry="3" width="200" height="20" />
                    <rect x="0" y="35" rx="3" ry="3" width="400" height="30" />
                    <rect x="0" y="80" rx="3" ry="3" width="600" height="80" />
                </ContentLoader>
            </div>
            <div className="px-lg-0 dashboard-card__container bg_tertiary py-4" >
                <div className="container">
                    <ContentLoader
                        speed={2}
                        width="100%"
                        height="20%"
                        viewBox="0 0 600 340"
                        backgroundColor="#f5f5f5"
                        foregroundColor="#2470c210"
                        {...props}
                    >
                        {/* trait header */}
                        <rect x="0" y="5" rx="3" ry="3" width="150" height="30" />
                        {/* <div className="row"> */}
                        {/* <div className="col-12 col-md-6"> */}
                        <rect x="0" y="50" rx="3" ry="3" width="45%" height="250" />
                        {/* </div> */}
                        {/* <div className="col-12 col-md-6"> */}
                        <rect x="300" y="50" rx="3" ry="3" width="45%" height="250" />
                        {/* </div> */}
                        {/* </div> */}
                    </ContentLoader>
                </div>
            </div>
            <div className="px-lg-0 py-4" >
                <div className="container">
                    <ContentLoader
                        speed={2}
                        width="100%"
                        height="20%"
                        viewBox="0 0 600 740"
                        backgroundColor="#f5f5f5"
                        foregroundColor="#2470c210"
                        {...props}
                    >
                        {/* trait header */}
                        <rect x="0" y="5" rx="3" ry="3" width="150" height="30" />
                        {/* <div className="row"> */}
                        {/* <div className="col-12 col-md-6"> */}
                        <rect x="0" y="50" rx="3" ry="3" width="45%" height="250" />
                        {/* </div> */}
                        {/* <div className="col-12 col-md-6"> */}
                        <rect x="300" y="50" rx="3" ry="3" width="45%" height="250" />
                        {/* </div> */}
                        {/* </div> */}

                        <rect x="0" y="320" rx="3" ry="3" width="45%" height="350" />
                        {/* </div> */}
                        {/* <div className="col-12 col-md-6"> */}
                        <rect x="300" y="320" rx="3" ry="3" width="45%" height="350" />
                        {/* </div> */}
                        {/* </div> */}
                    </ContentLoader>
                </div>
            </div>
        </div>
    );
}

export default L_PG4Dashboard;
