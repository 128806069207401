import * as psList from "../actions/psList";

const initialState = {
    isGetting:false,
    isError:false,
    ps_Data:[]
};

const psListReducer = (state = initialState, action) => {
  switch (action.type) {
    case psList.GET_PS_LIST_TRAIT_REQUEST: {
      return {
        ...state,
        isGetting:true,
      };
    }
    case psList.GET_PS_LIST_TRAIT_SUCCESS: {
      return {
        ...state,
        isGetting: false,
        ps_Data: action.payload,
      };
    }
    case psList.GET_PS_LIST_TRAIT_FAILURE: {
      return {
         ...state,
         isGetting: false,
         isError:true


        };
    }

    default:
      return state;
  }
};

export default psListReducer;
