import React from "react";
import KTSImage_1 from "../../../assets/static/kts_1.jpg";
import KTSImage_2 from "../../../assets/static/kts_2.webp";
import KTSImage_3 from "../../../assets/static/kts_3.webp";
import KTSImage_4 from "../../../assets/static/kts_4.jpg";
import KTSImage_5 from "../../../assets/static/kts_5.webp";
import KTSImage_6 from "../../../assets/static/kts_6.jpg";
import KTSImage_7 from "../../../assets/static/kts_7.webp";
import LaptopImage from "../../../assets/static/dpg_4.png";
import IpadImage from "../../../assets/static/dpg_9.png";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";


function KnowTheScience() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  return (
    <div className="my-5">
      <div className="container-xxl my-5 px-md-1 px-0">
        {/* section1 start */}
        <div className="row justify-content-lg-around justify-content-center my-5 py-lg-5 py-1">
          <div className="col-11 col-lg-5 px-lg-5 order-last order-lg-first">
            <span dangerouslySetInnerHTML={{ __html: t('know_science_header_1') }} />
          </div>
          <div className="col-12 col-lg-5 my-auto px-0">
            <div className="d-lg-none my-2 px-4">
              <span dangerouslySetInnerHTML={{ __html: t('know_science_dcode_section') }} />
            </div>
            <div className="px-md-5 px-lg-0">
              <img
                src={KTSImage_1}
                alt="Know the science image"
                className="w-100 img-fluid my-2 my-lg-0"
              />
            </div>
          </div>
        </div>
        {/* section2 start */}
        <div className="row justify-content-lg-around justify-content-center my-5 py-lg-5">
          <div className="col-12 col-lg-5 my-auto px-0">
            <img
              src={KTSImage_2}
              alt="Know the science image"
              className="w-100 my-4 my-lg-0"
            />
          </div>
          <div className="col-11 col-lg-5 px-lg-5 my-auto">
            <span dangerouslySetInnerHTML={{ __html: t('know_science_header_2') }} />
          </div>
        </div>

        {/* section3 start */}
        <div className="row justify-content-lg-around justify-content-center my-5 py-lg-5 py-1">
          <div className="col-12 col-lg-5 my-auto order-lg-last px-4">
            <img
              src={KTSImage_3}
              alt="Know the science image"
              className="w-100"
            />
          </div>
          <div className="col-11 col-lg-5 px-lg-1">
            <div className="pgs-title">
            <span dangerouslySetInnerHTML={{ __html: t('know_science_header_3') }} />
            </div>
            <div className="pgs-text my-3">
              <span dangerouslySetInnerHTML={{ __html: t('know_row3_p1') }} />
            </div>
          </div>
        </div>
        {/* section4 start */}
        <div className="row justify-content-lg-around justify-content-center my-5 py-2 py-lg-5">
          <div className="col-12 col-lg-5 my-auto  order-lg-last px-0">
            <img
              src={KTSImage_4}
              alt="Know the science image"
              className="w-100"
            />
            <div className="fst-italic mx-lg-3 my-3 lh-lg fw-normal pgs-text px-4 px-lg-0">
            { t('know_science_image_text_1') }
            </div>
          </div>
          <div className="col-11 col-lg-5">
            <span dangerouslySetInnerHTML={{ __html: t('know_science_section_4') }} />
          </div>
        </div>
        <div className="row justify-content-center my-lg-5">
          <div className="col-11 col-lg-11">
            <div className="pgs-heading textc-primary fw-bold">
              { t('know_science_header_5') }
            </div>
            <div className="pgs-text my-3">
              <div className="pgs-text my-2">
                { t('know_science_paragraph_5') }
              </div>

              <div className="pgs-text my-3">
                <span dangerouslySetInnerHTML={{ __html: t('know_row5_p2') }} />
              </div>
            </div>
            <div className="row justify-content-between my-2">
              <div className="col-12 col-lg-4 order-lg-last">
                <img
                  src={KTSImage_5}
                  alt="Know the science image"
                  className="w-100 my-4 my-lg-3"
                />
              </div>
              <div className="col-12 col-lg-6 pgs-text">
                <ul>
                  <span dangerouslySetInnerHTML={{ __html: t('know_science_trait_list') }} />
                </ul>
              </div>
            </div>
            <div className="pgs-text my-3">
              {t('know_science_trait_list_paragraph_1')}
            </div>
          </div>
        </div>
        {/* section5 start */}
        <div className="row justify-content-lg-around justify-content-center my-5 py-lg-5 py-2">
          <div className="col-12 col-lg-5 my-auto order-lg-last px-0">
            <img
              src={KTSImage_6}
              alt="Know the science image"
              className="w-100"
            />
          </div>
          <div className="col-11 col-lg-5 py-3 py-lg-0 ">
            <div className="pgs-title py-2">
              <span dangerouslySetInnerHTML={{ __html: t('know_science_header_6') }} />
            </div>
            <div className="pgs-text my-1 my-lg-3">
              <span dangerouslySetInnerHTML={{ __html: t('know_row6_p1') }} />
            </div>
          </div>
        </div>
        {/* section6 start */}
        <div className="text-lg-center my-5 px-4 mx-lg-4 px-lg-5">
          <span dangerouslySetInnerHTML={{ __html: t('know_science_section_1') }} />
        </div>
        {/* section7 start */}
        <div className="row justify-content-lg-around justify-content-center my-5 py-lg-5 py-2">
          <div className="col-12 col-lg-6 my-auto px-0">
            <img
              src={LaptopImage}
              alt="Know the science image"
              className="w-100"
            />
          </div>
          <div className="col-11 col-lg-6 px-lg-5 my-auto">
            <div className="pgs-title">
              <span dangerouslySetInnerHTML={{ __html: t('know_science_section_header_2') }} />
            </div>
            <div className="pgs-text my-3">
              <span dangerouslySetInnerHTML={{ __html: t('know_row8_p1') }} />
            </div>
            <div className="pgs-text my-3">
              {" "}
              { t('know_science_section_paragraph_2') }
            </div>
          </div>
        </div>
        {/* section8 start */}
        <div className="row justify-content-center justify-content-lg-around my-5 py-5">
          <div className="col-11 col-lg-7 order-lg-last ">
            <div className="pgs-heading text-center">
              {t('know_science_result_table') }
            </div>
            <div className="my-5">
              <table className="pg-table pgs-table w-100 d-none d-lg-block">
                <thead>
                  <tr dangerouslySetInnerHTML={{ __html: t('know_science_thead_1') }} />
                </thead>
                <tbody>
                  <tr dangerouslySetInnerHTML={{ __html: t('know_science_tdata_1') }} />
                </tbody>
              </table>
            </div>
            <div>
              <table className="pg-table w-100 text-center table-bordered d-lg-none">
                <tbody dangerouslySetInnerHTML={{ __html: t('know_science_trow_1') }} />
              </table>
            </div>
              <span dangerouslySetInnerHTML={{ __html: t('know_science_plain_txt_1') }} />
          </div>
          <div className="col-11 col-lg-4 my-auto">
            <div className="pgs-title">
              <span dangerouslySetInnerHTML={{ __html: t('know_science_plain_txt_2') }} />
            </div>
            <div className="pgs-text">
              <span dangerouslySetInnerHTML={{ __html: t('know_row9_p1') }} />
            </div>
          </div>
        </div>
        <div className="row justify-content-lg-around justify-content-center my-5">
          <div className="col-11 col-lg-6">
            <div className="my-auto px-4 py-4">
              <img
                src={KTSImage_7}
                alt="SNP variant example"
                className="w-100 h-75"
              />
            </div>
          </div>
          <div className="col-11 col-lg-5">
            <div className="pgs-text">
              { t('know_science_plain_txt_3') }
            </div>
            <a
              className="pgs-text pgs-heading__sub text-decoration-underline fw-bold textc-primary cp"
              href={ t('prac_sample_report_url') }
              download
            >
              { t('know_science_view_report_link') }
            </a>
          </div>
        </div>
        <div>
          <div className="row">
            <div className="col-11 col-lg-6">
              <span dangerouslySetInnerHTML={{ __html: t('know_science_plain_txt_4') }} />
              <div className="pgs-text my-3">
                <span dangerouslySetInnerHTML={{ __html: t('know_row10_p4') }} />
              </div>
            </div>
          </div>
        </div>
        {/* section8 start */}
        <div className="row justify-content-around my-5 py-5">
          <div className="col-12 col-lg-6 order-lg-last p-0">
            <img src={IpadImage} alt="Ipad Image" className="w-100" />
          </div>
          <div className="col-11 col-lg-5 my-auto">
            <div className="pgs-title my-3">
              <span dangerouslySetInnerHTML={{ __html: t('know_science_plain_txt_5') }} />
            </div>
            <div className="pgs-text my-lg-2 my-4">
              <span dangerouslySetInnerHTML={{ __html: t('know_row11_p1') }} />
            </div>
            <button
              className="btn btn-primary"
              onClick={() => navigate("/hcp/getting-started")}
            >
              { t('know_science_plain_txt_6') }
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default KnowTheScience;
