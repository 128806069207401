import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import L_TraitPage from "../../components/LoadingEffectComponent/Report/L_TraitPage";
import CurrentTraitCategory from "../../components/Report/TraitPage/CurrentTraitCategory";
import TraitOverview from "../../components/Report/TraitPage/TraitOverview";
import PolygenicScore from "../../components/Report/TraitPage/PolygenicScore";
import PersonalizedRecommendations from "../../components/Report/TraitPage/PersonalizedRecommendations";
import Implications from "../../components/Report/TraitPage/Implications";
import LabFindings from "../../components/Report/TraitPage/LabFindings";
import ActionImage from "../../components/ActionImage";
import { getTraitInfo, updateTrait } from "../../store/actions/traits";
import AffectedSnips from "../../components/Report/TraitPage/AffectedSnips";
import QuestionnnaireFindings from "../../components/Report/TraitPage/QuestionnnaireFindings";
import DietAndLifeStyleReccommendations from "../../components/Report/TraitPage/DietAndLifeStyleReccommendations";
import SupplementRecommendationInTraits from "../../components/Report/TraitPage/SupplementRecommendationInTraits";
import { propTypes } from "react-bootstrap/esm/Image";

export const TraitsPage = ({ history }) => {
  const location = useLocation();
  const currentPracticeID = useSelector(
    (state) => state.auth.currentPracticeID
  );
  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const token = useSelector((state) => state.auth.token);
  const patientInfo = useSelector((state) => state.patientReport.patientDetail);
  const traits = useSelector((state) => state.patientReport.traitCategories);
  const currentReportId = useSelector(
    (state) => state.patientReport.currentReportId
  );
  const loading = useSelector((state) => state.trait.isGettingTraitInfo);
  const traitInfo = useSelector((state) => state.trait.traitInfo);
  const currentTraitId = useSelector((state) => state.trait.currentTraitId);
  const currentTraitCategory = useSelector(
    (state) => state.trait.currentTraitCategory
  );
  const currentTraitIndex = useSelector(
    (state) => state.trait.currentTraitIndex
  );
  const canNext = useSelector((state) => state.trait.canNext);
  const canPrevious = useSelector((state) => state.trait.canPrevious);
  const userType = useSelector((state) => state.auth.userType);
  const allTraits = useSelector((state) => state.trait.allTraits);
  const supplementSuggestions = useSelector(
    (state) => state.patientReport.supplementSuggestions
  );

  // redux actions
  const dispatch = useDispatch();

  const getTraitInfoAction = (
    token,
    currentPracticeID,
    currentPractice,
    userType,
    data
  ) =>
    dispatch(
      getTraitInfo(token, currentPracticeID, currentPractice, userType, data)
    );
  const updateTraitInfoAction = (data) => dispatch(updateTrait(data));

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const patientTextColor = {
    color:
      userType === "Patient" ? "var(--pg_primary__p)" : "var(--pg_primary)",
  };

  useEffect(() => {
    if (
      _.isNull(currentReportId) ||
      _.isNull(currentTraitCategory) ||
      _.isNull(currentTraitId)
    ) {
      navigate(-1);
    } else {
      getTraitInfoAction(token, currentPracticeID, currentPractice, userType, {
        reportId: currentReportId,
        traitId: currentTraitId,
      }).then((response) => {
        if (response.error) {
          toast.error("Trait is not available");
          navigate(-1);
        }
      });
    }
  }, [currentTraitCategory, currentTraitId]);

  const onNext = () => {
    if (currentTraitIndex + 1 !== allTraits.length)
      updateTraitInfoAction({
        canNext: currentTraitIndex + 2 !== allTraits.length,
        canPrevious: currentTraitIndex >= 0,
        currentTraitId: allTraits[currentTraitIndex + 1].trait_id,
        currentTraitIndex: currentTraitIndex + 1,
      });
  };

  const onPrev = () => {
    if (currentTraitIndex >= 1)
      updateTraitInfoAction({
        canNext: currentTraitIndex + 2 !== allTraits.traitsLength,
        canPrevious: currentTraitIndex > 1,
        currentTraitId: allTraits[currentTraitIndex - 1].trait_id,
        currentTraitIndex: currentTraitIndex - 1,
      });
  };

  const redirectToReport = () => {
    // userType === "Patient"
    //   ? navigate("/patient/report")
    //   : navigate("/practice/patient/report");
    navigate(-1);
  };

  return (
    <div className="container-xxl px-0 my-3 mb-5">
      <Helmet>
        <title>
          {t("puregenomics_1")} - {t("traits_txt")}
        </title>
      </Helmet>
      {loading ? (
        <L_TraitPage />
      ) : (
        <div className="container-fluid">
          <div
            className="pg-link my-5"
            onClick={redirectToReport}
            style={patientTextColor}
          >
            <i className="fas fa-chevron-left"></i> {t("pracrs_title")}
          </div>
          <div className="container-xxl px-0 mx-0" id="trait-page">
            <div className="row justify-content-between">
              <div className="col-12 col-md-12 col-lg-10 order-last order-lg-first col-lg-2">
                <div className="d-flex">
                  <div className="pg-title my-2" style={patientTextColor}>
                    {traitInfo.title}
                  </div>
                  <div className="my-auto ms-2 mt-4">
                    {traitInfo.patient_result && (
                      <div className="d-inline-block">
                        <ActionImage action={traitInfo.patient_result} />
                        {(traitInfo.patient_result === "consider-action" ||
                          traitInfo.patient_result === "enhanced-benefit") && (
                          <>
                            <span>{t(traitInfo.patient_result)}</span>
                            {traitInfo.is_ps && (
                              <div className="d-flex mt-2">
                                <span className="p-logo">P</span>
                                <span>{t("poly_score")}</span>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-2 my-4 my-lg-0 px-lg-0 px-3">
                <div className="d-flex my-auto justify-content-between">
                  <RoundedNavigationButton
                    isDisabled={canPrevious}
                    name={t("previous")}
                    onClick={onPrev}
                    userType={userType}
                    icon={
                      <i
                        className="fa fa-chevron-left text-light"
                        aria-hidden="true"
                      ></i>
                    }
                  />
                  <RoundedNavigationButton
                    userType={userType}
                    isDisabled={canNext}
                    name={t("patient_nxt_txt")}
                    onClick={onNext}
                    icon={
                      <i
                        className="fa fa-chevron-right  text-light my-auto"
                        aria-hidden="true"
                      ></i>
                    }
                  />
                </div>
              </div>
            </div>
            <div className="pg-heading my-4 my-lg-0" style={patientTextColor}>
              {patientInfo?.patient?.full_name}
            </div>
            <div className="row justify-content-center">
              {traits.length > 0 && (
                <CurrentTraitCategory
                  traits={traits}
                  currentTrait={currentTraitCategory}
                />
              )}
            </div>
            <div className="row g-md-3 g-1 justify-content-center">
              <div className="col-md-6 col-12">
                {traitInfo.is_ps && (
                  <PolygenicScore
                    data={traitInfo.ps}
                    userType={userType}
                    t={t}
                  />
                )}

                {traitInfo.is_ps && (
                  <PersonalizedRecommendations
                    data={traitInfo.ps}
                    video_url={traitInfo.video_url}
                    userType={userType}
                    t={t}
                  />
                )}
                <TraitOverview
                  data={traitInfo.overview}
                  video_url={traitInfo.video_url}
                  userType={userType}
                  t={t}
                />
                <Implications
                  data={traitInfo.implications}
                  userType={userType}
                  t={t}
                />
                <AffectedSnips
                  data={traitInfo.affected_snips}
                  patientResult={traitInfo.patient_result}
                  userType={userType}
                  t={t}
                />
              </div>
              <div className="col-md-6 col-12">
                {userType === "User" && (
                  <LabFindings
                    labResultFindings={traitInfo.lab_results}
                    userType={userType}
                    t={t}
                  />
                )}
                {traitInfo.is_questionnaire && (
                  <QuestionnnaireFindings
                    data={traitInfo.questionnaire_findings}
                    userType={userType}
                    t={t}
                  />
                )}
                <DietAndLifeStyleReccommendations
                  data={traitInfo.diet_lifestyle_recommendations}
                  userType={userType}
                  t={t}
                />
                {userType === "User" &&
                  traitInfo?.supplement_recommendations?.recommended?.length >
                    0 && (
                    <SupplementRecommendationInTraits
                      currentPracticeID={currentPracticeID}
                      patientId={patientInfo?.patient?.id}
                      userType={userType}
                      data={traitInfo.supplement_recommendations}
                      supplementSuggestions={supplementSuggestions}
                      currentReportId={currentReportId}
                      t={t}
                    />
                  )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TraitsPage;

function RoundedNavigationButton({
  name,
  icon,
  isDisabled,
  onClick,
  userType,
}) {
  const patientTextColor = {
    color:
      userType === "Patient" ? "var(--pg_primary__p)" : "var(--pg_primary)",
  };
  const patientBGColor = {
    background:
      userType === "Patient" ? "var(--pg_primary__p)" : "var(--pg_primary)",
  };
  return (
    <div
      className={`d-flex flex-column align-items-center ${
        isDisabled
          ? "cursor-pointer pg-link"
          : "cursor-not-allowed pg-link text-secondary"
      }`}
      onClick={onClick}
    >
      <button
        className={`border-0 ${
          isDisabled
            ? "position-relative transition-all"
            : "bg-secondary position-relative cursor-not-allowed"
        }`}
        style={{
          borderRadius: "50%",
          outline: "none",
          aspectRatio: "1/1",
          height: 50,
          width: 50,
          ...patientBGColor,
          ...patientTextColor,
        }}
      >
        <span
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          {icon}
        </span>
      </button>
      <div className="mt-1">{name}</div>
    </div>
  );
}
