import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as PgLogo } from "../assets/PG_Logo.svg";
import { ReactComponent as PiLogo } from "../assets/PureInsight_Logo_RGB_u1.svg";
import NavButtons from "../components/NavButtons";
import { toggleSidebar } from "../methods/sidenav";
import { useSelector } from "react-redux";
import { Outlet } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import NavigationBar from "../components/HeaderUS";

export default function LoginLayout(props) {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate();
  const mfaEnabled = useSelector((state) => state.auth.mfaEnabled);
  return (
    <>
      {i18n.language !== "en" ? <>
        <nav className="navbar p-1">
          <div className="container-fluid p-md-3 p-1 px-3 mx-lg-5">
            <div className="container-xl d-flex justify-content-between">
              {/* menu button */}
              <div className="icon-one d-md-none">
                <div
                  className="hamburger hamburger-one"
                  onClick={toggleSidebar}
                ></div>
              </div>

              {/* Logo */}
              <div
                className="m-auto m-md-0 cursor-pointer pg-logo"
                onClick={() => navigate("/")}
              >
                {/* <PgLogo fontSize="100px" /> */}
                {i18n.language == "en_gb" ? <PgLogo fontSize="100px" /> : <PiLogo fontSize="100px" />}
              </div>
              {/* {mfaEnabled && <button className="btn btn-outline">Logout</button>} */}
            </div>
          </div>
        </nav>
        <NavButtons show="all" />
      </> :
        <div className="pt-5 mt-5">
          <NavigationBar />
        </div>
      }
      <Outlet />
    </>
  );
}
